export const adjustCookieLink = (text: string | undefined) => {
  return text ? text.replace('target="_blank"', 'target="_self"') : '';
};

export const manageKeyboardNavigation = (cookieStore: any) => {
  const cookiePopin = document.getElementById('fr-consent-banner');

  if (cookiePopin) {
    cookiePopin.setAttribute('tabindex', '0');
    cookiePopin.focus();
  }

  const wrongEssentialItem: NodeList = document.querySelectorAll('.fr-consent-service');
  if (wrongEssentialItem.length === 0) return;

  document
    .getElementsByClassName('fr-consent-service__title')[0]
    .getElementsByTagName('a')[0]
    .setAttribute('tabindex', '1');

  document.getElementById('fr-consent-modal-button-accept-all')?.setAttribute('tabindex', '2');
  document.getElementById('fr-consent-modal-button-refuse-all')?.setAttribute('tabindex', '2');

  wrongEssentialItem.forEach(element => {
    element.childNodes.forEach(child => {
      if (child.childNodes.length > 0) {
        child.childNodes.forEach(subChild => {
          if ((subChild as Element).textContent === cookieStore.cookies_reseaux_sociaux_titre) {
            attributeTabIndex(subChild.parentNode as Element);
            return;
          }
        });
      }
    });
  });
};

const attributeTabIndex = (element: Element) => {
  const radioTabIndexStart = 20;
  let radioTabIndex = radioTabIndexStart;
  element.querySelectorAll('.fr-radio-group').forEach((radioBloc: any) => {
    radioBloc.querySelectorAll('input').forEach((inputRadio: any) => {
      if (radioTabIndex === radioTabIndexStart + 2) {
        element
          .getElementsByClassName('fr-consent-service__collapse-btn')[0]
          .setAttribute('tabindex', (radioTabIndex++).toString());
      }
      inputRadio.setAttribute('tabindex', (radioTabIndex++).toString());
    });
  });

  document.getElementById('fr-consent-modal-button-confirm')?.setAttribute('tabindex', '50');
};
