import { cmsSlice } from './cmsSlice';

const cmsErreurAuth = cmsSlice.injectEndpoints({
  endpoints: build => ({
    getErreurAuthCmsPage: build.query<any, { qs?: string; locale: string }>({
      query: ({ locale }) => ({
        url: `/erreur-auth?locale=${locale}&populate=*`,
      }),
    }),
  }),
});

export const { useGetErreurAuthCmsPageQuery } = cmsErreurAuth;
