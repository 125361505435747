import { FC, useEffect } from 'react';
import { Store } from 'redux';
import { Provider } from 'react-redux';
import { Persistor } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { AuthProvider } from 'react-oidc-context';
import { BrowserRouter } from 'react-router-dom';

import Root from './Root';
import Loader from 'components/Atoms/Loader/Loader';
import AppRoutes from 'routes/Routes';
import { keycloakConfig } from 'services/authentication';

import 'App.scss';

interface Props {
  store: Store;
  persistor: Persistor;
}

const App: FC<Props> = ({ store, persistor }) => {
  const scrollTo = (e: CustomEvent<number>) => {
    window.scrollTo({
      top: e.detail,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('startScroll', scrollTo as EventListener);

    return () => {
      window.removeEventListener('startScroll', scrollTo as EventListener);
    };
  }, []);

  return (
    <Provider store={store}>
      <PersistGate loading={<Loader />} persistor={persistor}>
        <BrowserRouter>
          <AuthProvider {...keycloakConfig}>
            <Root persistor={persistor}>
              <AppRoutes />
            </Root>
          </AuthProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
};
export default App;
