import Card from '@codegouvfr/react-dsfr/Card';
import { FC } from 'react';

import PartContainer from 'components/Templates/PartContainer/PartContainer';
import TwoSlotElement from 'components/Templates/TwoSlotElement/TwoSlotElement';
import { CardContentHtmlParserFunction } from 'components/Atoms/HtmlParsers/CardContentHtmlParser';

interface TwoCardContainerProps {
  className: string;
  title: string;
  horizontal: boolean;
  withBorder?: boolean;
  cards: {
    key: string;
    title: string;
    description?: string;
    image: {
      src: string;
      alt: string;
    };
    link: {
      href: string;
      title?: string;
    };
  }[];
}

const TwoCardContainer: FC<TwoCardContainerProps> = ({
  className,
  title,
  horizontal,
  cards,
  withBorder = true,
}) => {
  return (
    <PartContainer title={title} className={`${className} horizontal-card`}>
      {cards.map((card, index) => {
        return (
          <TwoSlotElement key={card.key} className={className}>
            <Card
              className={`${!withBorder ? 'fr-card--no-border' : ''} ${
                index === 0 ? 'align-img-top' : ''
              }`}
              title={card.title}
              desc={CardContentHtmlParserFunction(card.description ? card.description : '')}
              imageAlt={card.image.alt}
              enlargeLink
              horizontal={horizontal}
              imageUrl={card.image.src}
              linkProps={{
                href: card.link?.href,
              }}
            />
          </TwoSlotElement>
        );
      })}
    </PartContainer>
  );
};

export default TwoCardContainer;
