/* eslint-disable max-lines */
/* eslint-disable max-params */

import { CustomAlertProps } from 'components/Atoms/CustomAlert/CustomAlert';
import { cmsEnrichedText } from 'components/Atoms/HtmlParsers/PageContentHtmlParser';
import { RFNG_CAT_MAPPING } from 'constants/upload';
import { DemandeRfBloc2 } from 'models/types/cms/demandeRF/type';
import { FilesField, UploadFilesParameters } from 'models/types/app/uploadFiles';

interface AlertsMapping {
  alert_top: CustomAlertProps;
}

export class familyMapping {
  static getGeneralMapping() {
    function getAlertsFields(bloc2Cms: DemandeRfBloc2): AlertsMapping {
      return {
        alert_top: {
          title: bloc2Cms.bulle_alerte_principale.titre,
          description: bloc2Cms.bulle_alerte_principale.contenu
            ? cmsEnrichedText(bloc2Cms.bulle_alerte_principale.contenu)
            : '',
          severity: 'warning',
          closable: false,
        },
      };
    }

    function getFilesFields(
      bloc2Cms: DemandeRfBloc2,
      uploadFilesParameters: UploadFilesParameters,
      errorCms: any,
    ): FilesField {
      return {
        lettre_explication_rf_files: {
          upload: {
            id: 'infos-fichier-lettre_explication_rf',
            categorie: RFNG_CAT_MAPPING.LETTRE_EXPLICATIVE_RF_PARTIEL,
            label: bloc2Cms.fichier_lettre_explication_rf_partiel.titre_fichier ?? '',
            hint: bloc2Cms.fichier_lettre_explication_rf_partiel.infos_fichier
              ? cmsEnrichedText(bloc2Cms.fichier_lettre_explication_rf_partiel.infos_fichier)
              : '',
            state: 'default',
            stateRelatedMessage: errorCms.erreur_generique_fichier,
            required: false,
            ...uploadFilesParameters,
            name: 'lettre_explication_rf_files',
            resource: 'conjoint',
            resourceId: undefined,
            helpbox: {
              closable: false,
              description: bloc2Cms.fichier_lettre_explication_rf_partiel.bulle_aide_fichier_contenu
                ? cmsEnrichedText(
                    bloc2Cms.fichier_lettre_explication_rf_partiel.bulle_aide_fichier_contenu,
                  )
                : '',
              severity: 'info',
              title: bloc2Cms.fichier_lettre_explication_rf_partiel.bulle_aide_fichier_titre,
            },
            infobulleContent: bloc2Cms.infobulle_fichier_lettre_explication_rf_partiel,
          },
        },
      };
    }

    return {
      getAlertsFields,
      getFilesFields,
    };
  }
}
