/* eslint-disable max-lines */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Notification } from 'models/types/app/toast';
import { STATUS } from 'constants/formulaire';

export type FormsState = Readonly<{
  submitForm: {
    whoAreYou: boolean;
    family: {
      family: boolean;
      enfant: boolean;
      conjoint: boolean;
      union: boolean;
    };
    accomodation: boolean;
    ressources: boolean;
    commitments: boolean;
  };
  notifications: Notification[];
}>;

const initialState: FormsState = {
  submitForm: {
    whoAreYou: false,
    family: {
      family: false,
      enfant: false,
      conjoint: false,
      union: false,
    },
    accomodation: false,
    ressources: false,
    commitments: false,
  },
  notifications: [],
};

const formsSlice = createSlice({
  name: 'Forms',
  initialState,
  reducers: {
    submitForm: (state, action: PayloadAction<string>) => {
      switch (action.payload) {
        case 'identite':
          state.submitForm.whoAreYou = !state.submitForm.whoAreYou;
          break;
        case 'family':
          state.submitForm.family.family = !state.submitForm.family.family;
          break;
        case 'conjoint':
          state.submitForm.family.conjoint = !state.submitForm.family.conjoint;
          break;
        case 'enfant':
          state.submitForm.family.enfant = !state.submitForm.family.enfant;
          break;
        case 'union':
          state.submitForm.family.union = !state.submitForm.family.union;
          break;
        case 'accomodation':
          state.submitForm.accomodation = !state.submitForm.accomodation;
          break;
        case 'ressources':
          state.submitForm.ressources = !state.submitForm.ressources;
          break;
      }
    },
    createError: (state, action: PayloadAction<string>) => {
      const tmpNotificationsState = [...state.notifications];
      let errorAlreadyExist = false;
      state.notifications.forEach(element => {
        if (element.content === action.payload) {
          errorAlreadyExist = true;
        }
      });

      if (!errorAlreadyExist) {
        tmpNotificationsState.push({
          state: 'in',
          status: STATUS.ERROR,
          content: action.payload,
        });
      }

      state.notifications = tmpNotificationsState;
    },
    disableError: (state, action: PayloadAction<string>) => {
      const tmpNotificationsState = [...state.notifications];
      const errorIndex = tmpNotificationsState.findIndex(
        el => el.content === action.payload && el.status === STATUS.ERROR,
      );

      if (errorIndex !== undefined) {
        tmpNotificationsState[errorIndex] = {
          state: 'out',
          status: STATUS.ERROR,
          content: action.payload,
        };
      }

      state.notifications = tmpNotificationsState;
    },
    clearError: (state, action: PayloadAction<string>) => {
      const tmpNotificationsState = [...state.notifications];
      const errorIndex = tmpNotificationsState.findIndex(
        el => el.content === action.payload && el.status === STATUS.ERROR,
      );
      if (errorIndex !== undefined) {
        tmpNotificationsState.splice(errorIndex, 1);
      }
      state.notifications = tmpNotificationsState;
    },
    createSuccess: (state, action: PayloadAction<string>) => {
      const tmpNotificationsState = [...state.notifications];
      let successAlreadyExist = false;
      state.notifications.forEach(element => {
        if (element.content === action.payload) {
          successAlreadyExist = true;
        }
      });

      if (!successAlreadyExist) {
        tmpNotificationsState.push({
          state: 'in',
          status: STATUS.VALID,
          content: action.payload,
        });
      }

      state.notifications = tmpNotificationsState;
    },
    disableSuccess: (state, action: PayloadAction<string>) => {
      const tmpNotificationsState = [...state.notifications];
      const successIndex = tmpNotificationsState.findIndex(
        el => el.content === action.payload && el.status === STATUS.VALID,
      );

      if (successIndex !== undefined) {
        tmpNotificationsState[successIndex] = {
          state: 'out',
          status: STATUS.VALID,
          content: action.payload,
        };
      }

      state.notifications = tmpNotificationsState;
    },
    clearSuccess: (state, action: PayloadAction<string>) => {
      const tmpNotificationsState = [...state.notifications];
      const successIndex = tmpNotificationsState.findIndex(
        el => el.content === action.payload && el.status === STATUS.VALID,
      );

      if (successIndex !== undefined) {
        tmpNotificationsState.splice(successIndex, 1);
      }

      state.notifications = tmpNotificationsState;
    },
  },
});

export const {
  submitForm,
  createError,
  disableError,
  clearError,
  createSuccess,
  disableSuccess,
  clearSuccess,
} = formsSlice.actions;
export default formsSlice.reducer;
