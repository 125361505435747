import { UploadFilesParameters } from 'models/types/app/uploadFiles';
import { delEnfantDocuments, setEnfantDocuments } from 'redux/demandeRf';

export const getUploadFilesParameters = (
  demandeId: string,
  id: string | undefined,
  formControl: {
    control: any;
    setValue: any;
    getValues: any;
    trigger: any;
    errors: any;
    watch: any;
  },
  cmsContentUtilitaire: any,
): UploadFilesParameters => {
  const { control, setValue, getValues, trigger, errors, watch } = formControl;
  return {
    demandeId: demandeId,
    formInfos: {
      key: 'enfant',
      id: id,
      deleteFunction: delEnfantDocuments,
      setFunction: setEnfantDocuments,
    },
    formControl: {
      control: control,
      setValue: setValue,
      getValues: getValues,
      trigger: trigger,
      errors: errors,
      watch: watch,
    },
    demandeRFUtilitairecms: cmsContentUtilitaire,
    formToSubmit: 'enfant',
  };
};
