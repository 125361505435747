import { CreationCompteCmsPage } from 'models/types/cms/creationDeCompte/type';
import { cmsSlice } from './cmsSlice';

const cmsAccountCreation = cmsSlice.injectEndpoints({
  endpoints: build => ({
    getAccountCreationCmsPage: build.query<CreationCompteCmsPage, { qs: string; locale: string }>({
      query: ({ qs, locale }) => ({
        url: `/creation-compte?locale=${locale}&${qs}`,
      }),
    }),
  }),
});

export const { useGetAccountCreationCmsPageQuery } = cmsAccountCreation;
