import qs from 'qs';
import { TitleSubTitleQuery } from '../common.query';

export const bloc5Query = qs.stringify({
  populate: {
    fields: ['button_infobulle_en_savoir_plus'],
  },
});

export const bloc5FormQuery = qs.stringify({
  populate: {
    fields: ['titre_principal'],
    numero_portable: TitleSubTitleQuery,
    adresse_email: TitleSubTitleQuery,
    numero_fixe: TitleSubTitleQuery,
  },
});
