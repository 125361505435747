import { CardProps } from '@codegouvfr/react-dsfr/Card';
import { FC } from 'react';

type SimpleCardProps = Omit<CardProps, 'title' | 'linkProps'> & {
  withBorder?: boolean;
};

const SimpleCard: FC<SimpleCardProps> = ({ desc, className, imageUrl }) => {
  return (
    <div className="simplecard">
      <div className={'fr-card ' + className}>
        <div className="fr-card__body">
          <div className="fr-card__content">
            <p className="fr-card__desc">{desc}</p>
            <div className="fr-card__start" />
          </div>
        </div>
        {imageUrl && (
          <div className="fr-card__header ratio-25">
            <div className="fr-card__img">
              <img className="fr-responsive-img" src={imageUrl} aria-hidden={true} alt="" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SimpleCard;
