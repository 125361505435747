import { Response } from 'miragejs';

export function directionTerritoriales(schema: any, request: any) {
  const codePostal = request.queryParams.codePostal;
  const result = schema.db['direction-territoriale'].where({
    codePostal: codePostal,
  });
  return new Response(result.length > 0 ? 200 : 404, {}, { total: null, contenu: result });
}

export function versements(schema: any) {
  const result = schema.db.versements;
  return new Response(result.length > 0 ? 200 : 404, {}, { total: null, contenu: result });
}

export function user(schema: any) {
  const result = schema.db.user;
  return new Response(result.length > 0 ? 200 : 404, {}, { ...result[0] });
}

export function hebergements(schema: any) {
  const result = schema.db.hebergements;
  return new Response(result.length > 0 ? 200 : 404, {}, { total: null, contenu: result });
}

export function documentsAsile(schema: any) {
  const result = schema.db.documentsAsile;
  return new Response(result.length > 0 ? 200 : 400, {}, { ...result[0] });
}

export function derniereAsile(schema: any) {
  const result = schema.db.derniereAsile;
  return new Response(result.length > 0 ? 200 : 400, {}, { ...result[0] });
}

export function documentsRf(schema: any) {
  const result = schema.db.documentsRf;
  return new Response(result.length > 0 ? 200 : 400, {}, { ...result[0] });
}

export function documentsRri(schema: any) {
  const result = schema.db.documentsRri;
  return new Response(result.length > 0 ? 200 : 400, {}, { ...result[0] });
}

export function listePays(schema: any) {
  const result = schema.db.listePays;
  return new Response(result.length > 0 ? 200 : 400, {}, { ...result[0] });
}

export function listeNationalites(schema: any) {
  const result = schema.db.listeNationalites;
  return new Response(result.length > 0 ? 200 : 400, {}, { ...result[0] });
}

export function suiviRf(schema: any, request: any) {
  const dossierId = request.params.dossierId;
  const result = schema.db.suiviRves.findBy({ numeroDossier: dossierId });
  return new Response(result ? 200 : 404, {}, { total: 1, contenu: result });
}

export function listRfSummary(schema: any) {
  const result = schema.db.listRfSummary;
  return new Response(result.length > 0 ? 200 : 400, {}, { ...result[0] });
}

export function submitRf() {
  //const result = schema.db.hebergements;
  //return new Response(result.length > 0 ? 200 : 404, {}, { total: null, contenu: result });
  return new Response(200, {}, {});
}

export function creationRf() {
  const creation = { demande_creation: false };
  return new Response(200, {}, { ...creation });
}

export function demandesRf(schema: any) {
  const result = schema.db.demandeRves;
  return new Response(result ? 200 : 404, {}, result);
}

export function demandeRf(schema: any, request: any) {
  const demandeId = request.params.demandeId;
  const result = schema.db.demandeRves.findBy({ id: demandeId });
  return new Response(result ? 200 : 404, {}, { ...result });
}

export function allocation(schema: any) {
  const result = schema.db.allocation;
  return new Response(result ? 200 : 404, {}, { ...result });
}
