import { Path } from 'react-hook-form';
import { InferType } from 'yup';

export const handleArrayHydratation = (key: string, formDemand: any, fieldArrayMapping: any) => {
  fieldArrayMapping[key as keyof typeof fieldArrayMapping].replace(formDemand[key]);
};

export const handleFieldHydratation = (
  key: string,
  formDemand: any,
  schema: any,
  setValue: any,
  isForce = false,
) => {
  type FormulaireValueType = InferType<typeof schema>;
  setValue(key as Path<FormulaireValueType>, formDemand[key], {
    shouldDirty: !!formDemand[key],
    shouldTouch: formDemand[key] ? !isForce : false,
    shouldValidate: !!formDemand[key],
  });
};

export const handleNestedFieldHydratation = (
  key: string,
  value: any,
  schema: any,
  setValue: any,
) => {
  type FormulaireValueType = InferType<typeof schema>;

  setValue(key as Path<FormulaireValueType>, value, {
    shouldDirty: value ? true : false,
    shouldTouch: value ? true : false,
    shouldValidate: value ? true : false,
  });
};

export const addressFieldNeedHydratation = (address: any) => {
  if (
    address?.houseNumber?.replace(/\s/g, '') !== '' ||
    address?.street?.replace(/\s/g, '') !== '' ||
    address?.postCode?.replace(/\s/g, '') !== '' ||
    address?.city.replace(/\s/g, '') !== '' ||
    (address?.cityCode?.replace(/\s/g, '') !== '' && address?.cityCode !== undefined) ||
    address?.label.replace(/\s/g, '') !== '' ||
    address.value.replace(/\s/g, '') !== ''
  ) {
    return true;
  } else {
    return false;
  }
};
