import {
  ArticlesCollectionElement,
  ProceduresCollectionElement,
  ServicesCollectionElement,
} from 'models/types/app/collections';
import { LocationSearchResponse } from 'models/types/cms/location/types';
import { OnlineServicesCmsDto } from 'models/types/cms/servicesEnLigne/types';
import { ArticleCmsDto } from 'models/types/cms/articles/types';
import { ProcedureCmsDto, ProcedureElementsCmsDto } from 'models/types/cms/procedures/types';
import { LayoutCmsDto } from 'models/types/cms/layout/types';
import { footerData, headerMenu, headerData } from 'models/types/app/pages';

import { CMS_BASE_URL } from 'services/cmsClient';

export const formatLayoutDataFromApiToApp = (
  layout: LayoutCmsDto,
): {
  header: headerData;
  menu: headerMenu;
  footer: footerData;
  blocs: {
    articles: {
      titre: string;
      bouton_voir_plus: string;
    } | null;
    procedures: {
      titre: string;
      sous_titre: string;
      texte_lien: string;
    } | null;
    services: {
      titre: string;
      sous_titre: string;
      texte_lien: string;
    } | null;
  };
} => {
  return {
    header: {
      logoAlt: layout.data.attributes.title, //Strapi
      title: layout.data.attributes.title,
      login: layout.data.attributes.texte_bouton_connecter,
      logout: layout.data.attributes.texte_bouton_deconnexion,
    },
    menu: {
      accueil: layout.data.attributes.menu_accueil,
      suivi: layout.data.attributes.menu_suivi,
      compte: layout.data.attributes.menu_compte,
      documents: layout.data.attributes.menu_documents,
      localisation: layout.data.attributes.menu_localisation,
      informations_generales: layout.data.attributes.menu_informations_generales,
      services_en_ligne: layout.data.attributes.menu_services_en_ligne,
      procedures: layout.data.attributes.menu_procedures,
      articles: layout.data.attributes.menu_articles,
      deconnexion: layout.data.attributes.texte_bouton_deconnexion,
      ma_demande_rf: layout.data.attributes.menu_ma_demande_rf,
      ma_demande_asile: layout.data.attributes.menu_ma_demande_asile,
      ma_demande_retour: layout.data.attributes.menu_ma_demande_retour,
      ma_demande_reinsertion: layout.data.attributes.menu_ma_demande_reinsertion,
      label_reinsertion_aide_1: layout.data.attributes.menu_label_reinsertion_aide_1,
      label_reinsertion_aide_2: layout.data.attributes.menu_label_reinsertion_aide_2,
      label_reinsertion_aide_3: layout.data.attributes.menu_label_reinsertion_aide_3,
      accueil_et_integration: layout.data.attributes.menu_accueil_et_integration,
      fermer_menu: layout.data.attributes.menu_fermer_menu,
      plan_du_site: layout.data.attributes.menu_plan_du_site,
      titre: layout.data.attributes.titre,
      sous_titre: layout.data.attributes.sous_titre,
      ofiiLogo: layout.data.attributes.ofiiLogo,
      tableau_de_bord: layout.data.attributes.menu_tableau_de_bord,
    },
    footer: {
      accessibilite: layout.data.attributes.footer_accessibilite,
      accessibilite_taux_conformite: layout.data.attributes.footer_accessibilite_taux_conformite,
      contact: layout.data.attributes.footer_contact,
      cookies: layout.data.attributes.footer_cookies,
      copyright: layout.data.attributes.footer_copyright,
      lien_facebook: layout.data.attributes.footer_lien_facebook,
      title_lien_facebook: layout.data.attributes.footer_title_lien_facebook,
      lien_twitter: layout.data.attributes.footer_lien_twitter,
      title_lien_twitter: layout.data.attributes.footer_title_lien_twitter,
      lien_youtube: layout.data.attributes.footer_lien_youtube,
      title_lien_youtube: layout.data.attributes.footer_title_lien_youtube,
      liens_partenaires: layout.data.attributes.footer_liens_partenaires,
      mentions_legales: layout.data.attributes.footer_mentions_legales,
      mentions_licence: layout.data.attributes.footer_mentions_licence,
      mentions_licence_label_lien: layout.data.attributes.footer_mentions_licence_label_lien,
      mentions_licence_lien: layout.data.attributes.footer_mentions_licence_lien,
      nous_suivre: layout.data.attributes.footer_nous_suivre,
      sur_les_reseaux: layout.data.attributes.footer_sur_les_reseaux,
      politique_confidentialite: layout.data.attributes.footer_politique_confidentialite,
      menu_plan_du_site: layout.data.attributes.menu_plan_du_site,
      titre_principal: layout.data.attributes.titre,
    },
    blocs: {
      articles: {
        titre: layout.data.attributes.articles.titre,
        bouton_voir_plus: layout.data.attributes.articles.bouton_voir_plus,
      },
      procedures: {
        titre: layout.data.attributes.procedures.titre,
        sous_titre: layout.data.attributes.procedures.sous_titre,
        texte_lien: layout.data.attributes.procedures.texte_lien,
      },
      services: {
        titre: layout.data.attributes.services_en_ligne.titre,
        sous_titre: layout.data.attributes.services_en_ligne.sous_titre,
        texte_lien: layout.data.attributes.services_en_ligne.texte_lien,
      },
    },
  };
};

export const formatServicesDataFromApiToApp = (
  data: OnlineServicesCmsDto[],
): ServicesCollectionElement[] => {
  const services = data.map((el: OnlineServicesCmsDto) => {
    return {
      key: el.id.toString(),
      title: el.attributes.titre,
      description: el.attributes.description,
      image: {
        src: CMS_BASE_URL + el?.attributes.image.data?.attributes.url,
        alt: el.attributes.image.data?.attributes.alternativeText || '',
      },
      link: {
        href: el.attributes.lien,
      },
    };
  });

  return services;
};

export const formatArticlesDataFromApiToApp = (
  data: ArticleCmsDto[],
): ArticlesCollectionElement[] => {
  const articles = data.map((el: ArticleCmsDto) => {
    return {
      key: el.id.toString(),
      id: el.attributes.lien_article,
      title: el.attributes.titre,
      badgeTitle: el.attributes.categories_article.data
        ? el.attributes.categories_article.data.attributes.nom
        : undefined,
      image: {
        src:
          el.attributes.image_principale_petite.data &&
          el.attributes.image_principale_petite.data.attributes.url
            ? CMS_BASE_URL + el.attributes.image_principale_petite.data.attributes.url
            : undefined,
        alt:
          el.attributes.image_principale_petite.data &&
          el.attributes.image_principale_petite.data.attributes.alternativeText
            ? el.attributes.image_principale_petite.data.attributes.alternativeText
            : ' ',
      },
      description: el.attributes.description,
      link: {
        href: `/articles/${el.attributes.lien_article}`,
      },
      badgeType: undefined,
      contenu: el.attributes.contenu,
      position: el.attributes.position,
    };
  });

  return articles && articles.sort((a, b) => (a.position || 0) - (b.position || 0));
};

export const formatProceduresDataFromApiToApp = (
  res: ProcedureCmsDto,
): ProceduresCollectionElement[] => {
  const procedures = res.data.map((el: ProcedureElementsCmsDto) => {
    return {
      key: el.id.toString(),
      id: el.attributes.lien_procedure,
      title: el.attributes.titre,
      image: {
        src:
          el.attributes.image_principale.data && el.attributes.image_principale.data?.attributes.url
            ? CMS_BASE_URL + el.attributes.image_principale.data?.attributes.url
            : '',
        alt: el.attributes.image_principale.data?.attributes.alternativeText || '',
      },
      link: {
        href: `les-procedures/${el.attributes.lien_procedure}`,
      },
      contenu: el.attributes.contenu,
    };
  });
  return procedures;
};

export const formatLocationSearchResultFromApiToApp = (
  data: LocationSearchResponse,
): LocationSearchResponse => {
  return {
    ...data,
  };
};
