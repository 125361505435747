import { Demande, RFDemandeSummary } from 'models/types/api/rf';
import { DemandeAsile } from 'models/types/api/demandeAsile';
import { RRIRetour, RRIReinsertion } from 'models/types/api/rri';

export type TableauDeBordProcedure =
  | RFDemandeSummary
  | DemandeAsile
  | RRIRetour
  | RRIReinsertion
  | Demande;

export enum ProcedureDemandeType {
  ASILE = 'ASILE',
  REGROUPEMENT_FAMILIAL = 'REGROUPEMENT_FAMILIAL',
  RETOUR = 'RETOUR',
  REINSERTION = 'REINSERTION',
  DEMANDE_RF = 'DEMANDE_RF',
}

export enum ProcedureEtapeStatut {
  TERMINE = 'termine',
  REFUSE = 'refuse',
  EN_ATTENTE_USAGER = 'en_attente_usager',
  AJOURNE = 'ajourne',
  A_VENIR = 'a_venir',
  EN_COURS = 'en_cours',
}
