import React, { FC } from 'react';
import { fr } from '@codegouvfr/react-dsfr';
import { useStyles } from 'tss-react/dsfr';

import { API_DATE_FORMAT, DISPLAY_DATE_DDMMYYYY } from 'constants/time';
import { RFDemande } from 'models/types/api/rf';
import { RFRecapCmsComponent } from 'models/types/cms/espaceRF/types';
import { formatNullableDate } from 'services/date';

type Props = {
  demande: RFDemande;
  cmsRecap: RFRecapCmsComponent;
};
const Recap: FC<Props> = ({ demande, cmsRecap }) => {
  const { cx } = useStyles();
  return (
    <>
      <div className="fr-mb-md-2w fr-mb-1w">
        <h3>{cmsRecap.titre}</h3>
      </div>
      <div className="fr-grid-row container">
        <div className="fr-col-12 fr-mt-3w fr-mt-md-5w fr-grid-row separateur">
          <div className={cx(fr.cx('fr-col-3'), 'icon')}>
            <span className={fr.cx('fr-icon-building-line')} aria-hidden={true} />
          </div>
          <div className="fr-col-9 fr-grid-row">
            <span className="title fr-col-12 fr-mb-1w">
              {cmsRecap.label_direction_territoriale}
            </span>
            <span className="text fr-col-12">{demande.directionTerritoriale}</span>
          </div>
        </div>

        <div className="fr-col-12 fr-mt-3w fr-mt-md-5w fr-grid-row separateur">
          <div className={cx(fr.cx('fr-col-3'), 'icon')}>
            <span className={fr.cx('fr-icon-home-4-line')} aria-hidden={true} />
          </div>
          <div className="fr-col-9 fr-grid-row">
            <span className="title fr-col-12 fr-mb-1w">{cmsRecap.label_adresse}</span>
            <span className="text fr-col-12">{demande.adresseDemandeur}</span>
          </div>
        </div>

        <div className="fr-col-12 fr-mt-3w fr-mt-md-5w fr-grid-row separateur">
          <div className={cx(fr.cx('fr-col-3'), 'icon')}>
            <span className={fr.cx('fr-icon-home-4-line')} aria-hidden={true} />
          </div>
          <div className="fr-col-9 fr-grid-row">
            <span className="title fr-col-12 fr-mb-1w">{cmsRecap.label_adresse_enquete}</span>
            <span className="text fr-col-12">{demande.adresseEnqueteDemandeur}</span>
          </div>
        </div>

        <div className="fr-col-12 fr-mt-3w fr-mt-md-5w fr-grid-row separateur">
          <div className={cx(fr.cx('fr-col-3'), 'icon')}>
            <span className={fr.cx('fr-icon-calendar-line')} aria-hidden={true} />
          </div>
          <div className="fr-col-9 fr-grid-row">
            <span className="title fr-col-12 fr-mb-1w">
              {cmsRecap.label_date_validite_titre_sejour}
            </span>
            <span className="text fr-col-12">
              <>
                {cmsRecap.label_date_validite_titre_sejour_debut}
                {' : '}
                {formatNullableDate(
                  demande.titreSejourValiditeDateDebut,
                  API_DATE_FORMAT,
                  DISPLAY_DATE_DDMMYYYY,
                )}
              </>
            </span>
            <span className="text fr-col-12">
              <>
                {cmsRecap.label_date_validite_titre_sejour_fin}
                {' : '}
                {formatNullableDate(
                  demande.titreSejourValiditeDateFin,
                  API_DATE_FORMAT,
                  DISPLAY_DATE_DDMMYYYY,
                )}
              </>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Recap;
