import { FC } from 'react';
import { useSelector } from 'react-redux';
import Badge from '@codegouvfr/react-dsfr/Badge';
import { Table } from '@codegouvfr/react-dsfr/Table';
import Highlight from '@codegouvfr/react-dsfr/Highlight';

import {
  useGetAllocationQuery,
  useGetAttributaireQuery,
  useGetHebergementsQuery,
  useGetVersementsQuery,
} from 'redux/rtk/api/apiDna';
import { getCurrentLocale } from 'redux/locale/selectors';
import { useGetAllocationCmsQuery } from 'redux/rtk/cms/cmsAsile';
import { useGetGlobalUtilitaireCmsQuery } from 'redux/rtk/cms/cmsUtilitaire';

import { PageContentHtmlParserFunction } from 'components/Atoms/HtmlParsers/PageContentHtmlParser';
import AppBreadcrumb from 'components/Atoms/Breadcrumb/Breadcrumb';
import Loader from 'components/Atoms/Loader/Loader';

import DownloadButton from './DownloadButton';
import { monAllocationQuery } from './monAllocation.query';

import './MonAllocation.scss';
import { convertDate } from 'helpers/app/dateHelper';

const MonAllocation: FC<any> = () => {
  const lang = useSelector(getCurrentLocale) as string;

  const { cmsAllocation } = useGetAllocationCmsQuery(
    {
      locale: lang !== '' ? lang : 'fr',
      qs: monAllocationQuery,
    },
    {
      selectFromResult: ({ data: res }) => ({
        cmsAllocation: {
          dernier_versement: res?.data.attributes.dernier_versement,
          historique_versements: res?.data.attributes.historique_versements,
          moyen_de_paiement: res?.data.attributes.moyen_de_paiement,
          titre_page: res?.data.attributes.titre_page,
          famille_composition: res?.data.attributes.famille_composition,
        },
      }),
    },
  );

  const { data: allocationData, isLoading: isLoadingAllocation } = useGetAllocationQuery();

  const {
    data: versementsData,
    error: versementsError,
    isLoading: isVersementsLoading,
  } = useGetVersementsQuery(undefined);

  const {
    data: hebergementsData,
    error: hebergementsError,
    isLoading: isHebergementsLoading,
  } = useGetHebergementsQuery();

  const {
    data: attributaireData,
    error: attributaireError,
    isLoading: isAttributaireLoading,
  } = useGetAttributaireQuery();

  const {
    data: utilitaireData,
    isLoading: isUtilitaireLoading,
    error: utilitaireError,
  } = useGetGlobalUtilitaireCmsQuery({
    locale: lang !== '' ? lang : 'fr',
  });

  //const { data: userData, error: userError, isLoading: isUserLoading } = useGetUserQuery();
  const versements = versementsData?.contenu.filter(el => el.statut === 'paye');
  const dernierVersement = versements && versements[0];
  const versementsElements = versements
    ? versements.map(el => {
        return [
          convertDate(el.dateVersement),
          <Badge className="fr-badge--success">{el.montant + ' €'}</Badge>,
        ];
      })
    : [];

  if (versementsError || hebergementsError || attributaireError || utilitaireError) {
    console.log('une erreur est survenu:', versementsError, hebergementsError, attributaireError);
  }

  if (
    isVersementsLoading ||
    isHebergementsLoading ||
    isAttributaireLoading ||
    isLoadingAllocation ||
    isUtilitaireLoading
  ) {
    return <Loader />;
  }

  return (
    <main id="mon-allocation">
      <div className="fr-container">
        <AppBreadcrumb />
        <h1 className="fr-mt-3w fr-mt-md-5w fr-mb-5w">
          {cmsAllocation && cmsAllocation.titre_page}
        </h1>
        <div className="fr-grid-row fr-grid-row--gutters fr-mb-5w fr-p-2w">
          <div className="fr-col">
            {cmsAllocation && cmsAllocation.dernier_versement && (
              <>
                <h2 className="fr-h3">{cmsAllocation.dernier_versement.titre}</h2>
                <div className="fr-container fr-mb-3w payments-card">
                  <div className="fr-col">
                    {dernierVersement && (
                      <div className="fr-grid-row payment-card__content">
                        <div className="fr-col payments-card__left-part">
                          <span className="payments-card__title">
                            {dernierVersement.statut === 'paye'
                              ? '+ ' + dernierVersement.montant + ' '
                              : '---- '}
                            €
                          </span>
                          <Badge
                            severity={dernierVersement.statut === 'paye' ? 'success' : 'error'}
                          >
                            {dernierVersement.statut === 'paye'
                              ? cmsAllocation.dernier_versement?.verse?.toUpperCase()
                              : cmsAllocation.dernier_versement?.erreur?.toUpperCase()}
                          </Badge>
                        </div>
                        <hr />
                        <div className="fr-col payments-card__right-part">
                          <p className="payments-card__line">
                            <span className="bolder">
                              {cmsAllocation.dernier_versement.periode}
                            </span>{' '}
                            : {dernierVersement.periode}
                          </p>
                          <p className="payments-card__line">
                            <span className="bolder">{utilitaireData.data.attributes.date}</span> :{' '}
                            {convertDate(dernierVersement.dateVersement)}
                          </p>
                          <p className="payments-card__line">
                            <span className="bolder">
                              {cmsAllocation.dernier_versement.nombre_jours}
                            </span>{' '}
                            : {dernierVersement.nbrJourCalcule}{' '}
                            {utilitaireData.data.attributes.jour}s
                          </p>
                        </div>
                      </div>
                    )}
                    {attributaireData && attributaireData.contenu[0] && (
                      <span>
                        {cmsAllocation.dernier_versement.attributaire} :{' '}
                        {attributaireData.contenu[0].nom} {attributaireData.contenu[0].prenom}
                      </span>
                    )}
                  </div>
                </div>
                <Table
                  className="fr-mb-3w"
                  caption={cmsAllocation.historique_versements.titre}
                  bordered
                  fixed
                  data={versementsElements}
                  headers={[
                    cmsAllocation.historique_versements.date,
                    cmsAllocation.historique_versements.montant,
                  ]}
                />
                {allocationData && allocationData.contenu && allocationData.contenu[0] && (
                  <>
                    <DownloadButton
                      allocationData={allocationData.contenu[0]}
                      cmsAllocation={cmsAllocation}
                    />
                  </>
                )}
              </>
            )}
          </div>
          <div className="fr-col">
            {cmsAllocation && cmsAllocation.moyen_de_paiement && (
              <>
                <h2 className="fr-h3">{cmsAllocation.moyen_de_paiement.carte.titre}</h2>
                <div className="fr-container  payment-column">
                  <div className="fr-grid-row row-illustration">
                    <img className="fr-mt-5w" src="/img/carte_ADA.png" />
                  </div>
                  <hr />
                  <Highlight className="fr-mt-3w">
                    <h3 className="fr-h4">{cmsAllocation.moyen_de_paiement.informations}</h3>
                    {PageContentHtmlParserFunction(
                      cmsAllocation.moyen_de_paiement.note_explicative,
                    )}
                  </Highlight>

                  <hr />
                  <div className="fr-grid-row  fr-mb-4w fr-mt-4w fr-pl-4w fr-pr-4w">
                    <span className="column-icon fr-icon-home-4-line" aria-hidden="true"></span>
                    <div className="fr-col">
                      {hebergementsData && (
                        <p>
                          {hebergementsData.contenu[0].statutHebergement === 'Domicilie'
                            ? cmsAllocation.moyen_de_paiement.domicilie_depuis
                            : cmsAllocation.moyen_de_paiement.heberge_depuis}{' '}
                          {convertDate(hebergementsData.contenu[0].dateHebergement)}
                        </p>
                      )}
                    </div>
                  </div>
                  <hr />
                  <div className="fr-grid-row  fr-mb-4w fr-mt-4w fr-pl-4w fr-pr-4w">
                    <span className="column-icon fr-icon-parent-line" aria-hidden="true"></span>
                    <div className="fr-col">
                      {dernierVersement && (
                        <p>
                          {cmsAllocation?.famille_composition
                            .replace('{nombreMajeurs}', dernierVersement?.nombreMajeurs)
                            .replace('{nombreMineurs}', dernierVersement?.nombreMineurs)
                            .replace(
                              '{dateCompositionFamiliale}',
                              convertDate(dernierVersement?.dateCompositionFamiliale),
                            )}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </main>
  );
};

export default MonAllocation;
