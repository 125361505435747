/* eslint-disable react-hooks/rules-of-hooks */
import { useGetSuiviRFCmsQuery, useGetTraductionRFCmsQuery } from 'redux/rtk/cms/cmsSuiviRF';

import {
  suiviRfQuery,
  traductionApiRfQuery,
  traductionApiRf1Query,
  traductionApiRf2Query,
  traductionApiRf3Query,
  traductionApiRf4Query,
  traductionApiRf5Query,
  traductionApiRf6Query,
  traductionApiRf7Query,
  traductionApiRf8Query,
  traductionApiRf9Query,
  traductionApiRf10Query,
  traductionApiRf11Query,
} from './cmsQueries';

export const getCmsContent = (lang: string) => {
  const {
    data: suiviRFcms,
    isLoading: suiviRFCmsIsLoading,
    isError: suiviRFCmsIsError,
  } = useGetSuiviRFCmsQuery({
    locale: lang !== '' ? lang : 'fr',
    qs: suiviRfQuery,
  });
  const {
    data: traductionRFCms,
    isLoading: traductionRFCmsIsLoading,
    isError: traductionRFCmsIsError,
  } = useGetTraductionRFCmsQuery({
    locale: lang !== '' ? lang : 'fr',
    qs: traductionApiRfQuery,
  });
  const {
    data: traductionRFCms1,
    isLoading: traductionRFCmsIsLoading1,
    isError: traductionRFCmsIsError1,
  } = useGetTraductionRFCmsQuery({
    locale: lang !== '' ? lang : 'fr',
    qs: traductionApiRf1Query,
  });
  const {
    data: traductionRFCms2,
    isLoading: traductionRFCmsIsLoading2,
    isError: traductionRFCmsIsError2,
  } = useGetTraductionRFCmsQuery({
    locale: lang !== '' ? lang : 'fr',
    qs: traductionApiRf2Query,
  });
  const {
    data: traductionRFCms3,
    isLoading: traductionRFCmsIsLoading3,
    isError: traductionRFCmsIsError3,
  } = useGetTraductionRFCmsQuery({
    locale: lang !== '' ? lang : 'fr',
    qs: traductionApiRf3Query,
  });
  const {
    data: traductionRFCms4,
    isLoading: traductionRFCmsIsLoading4,
    isError: traductionRFCmsIsError4,
  } = useGetTraductionRFCmsQuery({
    locale: lang !== '' ? lang : 'fr',
    qs: traductionApiRf4Query,
  });
  const {
    data: traductionRFCms5,
    isLoading: traductionRFCmsIsLoading5,
    isError: traductionRFCmsIsError5,
  } = useGetTraductionRFCmsQuery({
    locale: lang !== '' ? lang : 'fr',
    qs: traductionApiRf5Query,
  });
  const {
    data: traductionRFCms6,
    isLoading: traductionRFCmsIsLoading6,
    isError: traductionRFCmsIsError6,
  } = useGetTraductionRFCmsQuery({
    locale: lang !== '' ? lang : 'fr',
    qs: traductionApiRf6Query,
  });
  const {
    data: traductionRFCms7,
    isLoading: traductionRFCmsIsLoading7,
    isError: traductionRFCmsIsError7,
  } = useGetTraductionRFCmsQuery({
    locale: lang !== '' ? lang : 'fr',
    qs: traductionApiRf7Query,
  });
  const {
    data: traductionRFCms8,
    isLoading: traductionRFCmsIsLoading8,
    isError: traductionRFCmsIsError8,
  } = useGetTraductionRFCmsQuery({
    locale: lang !== '' ? lang : 'fr',
    qs: traductionApiRf8Query,
  });
  const {
    data: traductionRFCms9,
    isLoading: traductionRFCmsIsLoading9,
    isError: traductionRFCmsIsError9,
  } = useGetTraductionRFCmsQuery({
    locale: lang !== '' ? lang : 'fr',
    qs: traductionApiRf9Query,
  });
  const {
    data: traductionRFCms10,
    isLoading: traductionRFCmsIsLoading10,
    isError: traductionRFCmsIsError10,
  } = useGetTraductionRFCmsQuery({
    locale: lang !== '' ? lang : 'fr',
    qs: traductionApiRf10Query,
  });
  const {
    data: traductionRFCms11,
    isLoading: traductionRFCmsIsLoading11,
    isError: traductionRFCmsIsError11,
  } = useGetTraductionRFCmsQuery({
    locale: lang !== '' ? lang : 'fr',
    qs: traductionApiRf11Query,
  });

  const isloading =
    suiviRFCmsIsLoading ||
    traductionRFCmsIsLoading ||
    traductionRFCmsIsLoading1 ||
    traductionRFCmsIsLoading2 ||
    traductionRFCmsIsLoading3 ||
    traductionRFCmsIsLoading4 ||
    traductionRFCmsIsLoading5 ||
    traductionRFCmsIsLoading6 ||
    traductionRFCmsIsLoading7 ||
    traductionRFCmsIsLoading8 ||
    traductionRFCmsIsLoading9 ||
    traductionRFCmsIsLoading10 ||
    traductionRFCmsIsLoading11;
  const haveError =
    suiviRFCmsIsError ||
    traductionRFCmsIsError ||
    traductionRFCmsIsError1 ||
    traductionRFCmsIsError2 ||
    traductionRFCmsIsError3 ||
    traductionRFCmsIsError4 ||
    traductionRFCmsIsError5 ||
    traductionRFCmsIsError6 ||
    traductionRFCmsIsError7 ||
    traductionRFCmsIsError8 ||
    traductionRFCmsIsError9 ||
    traductionRFCmsIsError10 ||
    traductionRFCmsIsError11;
  return {
    suiviRFCms: suiviRFcms?.data?.attributes ?? suiviRFcms,
    traductionRFCms: {
      ...(traductionRFCms?.data?.attributes ?? traductionRFCms),
      clef_statuts: {
        ...traductionRFCms1?.data?.attributes?.clef_statuts,
        ...traductionRFCms2?.data?.attributes?.clef_statuts,
        ...traductionRFCms3?.data?.attributes?.clef_statuts,
        ...traductionRFCms4?.data?.attributes?.clef_statuts,
        ...traductionRFCms5?.data?.attributes?.clef_statuts,
        ...traductionRFCms6?.data?.attributes?.clef_statuts,
        ...traductionRFCms7?.data?.attributes?.clef_statuts,
        ...traductionRFCms8?.data?.attributes?.clef_statuts,
        ...traductionRFCms9?.data?.attributes?.clef_statuts,
        ...traductionRFCms10?.data?.attributes?.clef_statuts,
        ...traductionRFCms11?.data?.attributes?.clef_statuts,
      },
    },
    isCmsLoading: isloading,
    isCmsOnError: haveError,
  };
};
