import { Fragment } from 'react';
import { Button } from '@codegouvfr/react-dsfr/Button';
import { useSelector } from 'react-redux';

import CardWithoutLink from 'components/Molecules/CardWithoutLink/CardWithoutLink';
import { LOCATION_URL } from 'constants/location';
import { LocationSearchResponseElement } from 'models/types/cms/location/types';
import { getLocationPage } from 'redux/pages/selectors';

interface Props {
  el: LocationSearchResponseElement;
}

const ResultLocation = ({ el }: Props) => {
  const locationStore = useSelector(getLocationPage);
  const computeSchedulesDisplay = (schedules: {
    jour: string;
    ouvertMatin: string | null;
    fermeMatin: string | null;
    ouvertAprem: string | null;
    fermeAprem: string | null;
  }) => {
    let value = ``;
    if (!schedules.ouvertMatin && !schedules.ouvertAprem) {
      value += locationStore.closed;
    }

    if (schedules.ouvertMatin) {
      value += `${schedules.ouvertMatin + ' - ' + schedules.fermeMatin}${
        schedules.ouvertAprem ? ' / ' : ''
      }`;
    }

    if (schedules.ouvertAprem) {
      value += `${schedules.ouvertAprem + ' - ' + schedules.fermeAprem}`;
    }

    return value;
  };

  return (
    <>
      <div className="fr-col-lg-4 fr-mb-2w fr-mb-md-7w">
        <CardWithoutLink
          title={locationStore.titre_coordonnees}
          desc={
            <>
              {el.telephoneDT}
              <br />
              <Button
                priority="secondary"
                iconId="fr-icon-phone-fill"
                iconPosition="right"
                className="fr-mt-1w fr-mb-4w"
                linkProps={{
                  href: `tel:${el.telephoneDT.replace(/\s/g, '')}`,
                }}
              >
                {locationStore.call}
              </Button>
              <br />
              {el.nomDT}
              <br />
              {el.adresseDT}
              <br />
              <a
                className="map-link"
                title={locationStore.dt_sur_la_carte}
                href={`${LOCATION_URL}/${encodeURIComponent(el.adresseDT)}`}
                rel="noopener noreferrer"
                target="_blank"
              >
                {locationStore.map_link}
              </a>
            </>
          }
        />
      </div>
      <div className="fr-col-lg-4 fr-mb-6w fr-mb-md-7w">
        <CardWithoutLink
          title={locationStore.horaires}
          desc={
            <div className="fr-col">
              {el.horairesDT.map(
                (day: {
                  jour: string;
                  ouvertMatin: string | null;
                  fermeMatin: string | null;
                  ouvertAprem: string | null;
                  fermeAprem: string | null;
                }) => {
                  return (
                    <div className="fr-grid-row" key={day.jour}>
                      <div className="fr-col-4 capitalize">{day.jour}</div>
                      <div className="fr-col">{computeSchedulesDisplay(day)}</div>
                    </div>
                  );
                },
              )}
            </div>
          }
        />
      </div>
    </>
  );
};

export default ResultLocation;
