export const searchPaysByCode = (
  listePays: any,
  code: string | null | undefined,
  lang: string,
): string => {
  const search = listePays?.find(({ value }: any) => value === code);
  let langCheck = lang;
  if (lang === '') {
    langCheck = 'fr';
  }
  if (search) {
    return search.label[langCheck];
  }

  return '';
};
