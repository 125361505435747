import { FC } from 'react';
import technicalErrorSvgUrl from '@codegouvfr/react-dsfr/dsfr/artwork/pictograms/system/technical-error.svg';
import ovoidSvgUrl from '@codegouvfr/react-dsfr/dsfr/artwork/background/ovoid.svg';
import { useSelector } from 'react-redux';

import Loader from 'components/Atoms/Loader/Loader';
import { ACCUEIL } from 'routes/path';
import { useGetErreurAuthCmsPageQuery } from 'redux/rtk/cms/cmsErreurAuth';
import { getCurrentLocale } from 'redux/locale/selectors';

const AuthError: FC = () => {
  const lang = useSelector(getCurrentLocale) as string;
  const { isLoading: isLoadingCmsErreurData } = useGetErreurAuthCmsPageQuery({
    qs: 'populate=*',
    locale: lang !== '' ? lang : 'fr',
  });
  const { cmsErreur } = useGetErreurAuthCmsPageQuery(
    {
      qs: 'populate=*',
      locale: lang !== '' ? lang : 'fr',
    },
    {
      selectFromResult: ({ data }) => ({
        cmsErreur: data?.data.attributes,
      }),
    },
  );

  if (!cmsErreur || isLoadingCmsErreurData) return <Loader />;

  return (
    <main role="main" id="content">
      <div className="fr-container">
        <div className="fr-my-7w fr-mt-md-12w fr-mb-md-10w fr-grid-row fr-grid-row--gutters fr-grid-row--center">
          <div className="fr-py-0 fr-col-12 fr-col-md-6">
            <h1>{cmsErreur.titre}</h1>
            <p className="fr-text--lead fr-mb-3w">{cmsErreur.description}</p>{' '}
            <p className="fr-text--sm fr-mb-5w"> {cmsErreur.more_info} </p>
            <a className="fr-link fr-icon-arrow-right-line fr-link--icon-right" href={ACCUEIL}>
              {cmsErreur.texte_lien}
            </a>
          </div>
          <div className="fr-col-12 fr-col-md-3 fr-col-offset-md-1 fr-px-6w fr-px-md-0 fr-py-0">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="fr-responsive-img fr-artwork"
              aria-hidden="true"
              width="160px"
              height="200px"
              viewBox="0 0 160 200"
            >
              <use className="fr-artwork-motif" href={`${ovoidSvgUrl}#artwork-motif`}></use>
              <use
                className="fr-artwork-background"
                href={`${ovoidSvgUrl}#artwork-background`}
              ></use>
              <g transform="translate(40, 60)">
                <use
                  className="fr-artwork-decorative"
                  href={`${technicalErrorSvgUrl}#artwork-decorative`}
                ></use>
                <use
                  className="fr-artwork-minor"
                  href={`${technicalErrorSvgUrl}#artwork-minor`}
                ></use>
                <use
                  className="fr-artwork-motif"
                  href={`${technicalErrorSvgUrl}#artwork-motif`}
                ></use>
                <use
                  className="fr-artwork-major"
                  href={`${technicalErrorSvgUrl}#artwork-major`}
                ></use>
              </g>
            </svg>
          </div>
        </div>
      </div>
    </main>
  );
};
export default AuthError;
