import React from 'react';
import { useWatch } from 'react-hook-form';

import UploadFiles from 'components/Atoms/UploadFiles/UploadFiles';
import CustomCheckboxContainer from 'components/Molecules/Forms/CustomCheckboxContainer/CustomCheckboxContainer';

type EnfantActeNaissanceProps = {
  fieldArrayMapping: any;
  filesMapping: any;
  isNew: string;
  formFieldsMapping: any;
  control: any;
  lang: string;
};

const EnfantActeNaissance: React.FC<EnfantActeNaissanceProps> = ({
  fieldArrayMapping,
  filesMapping,
  isNew,
  formFieldsMapping,
  control,
  lang,
}) => {
  const acteNaissanceEnfantTraduit = useWatch({
    control,
    name: 'acte_naissance_enfant_traduit',
  });

  return (
    <>
      <div className="container fr-my-6v">
        <hr />
      </div>
      <UploadFiles
        {...filesMapping.acte_naissance_enfant_files.upload}
        fieldArray={fieldArrayMapping.acte_naissance_enfant_files}
        isNew={isNew}
        lang={lang}
      />
      <CustomCheckboxContainer {...formFieldsMapping.acte_naissance_enfant_traduit} large />
      {acteNaissanceEnfantTraduit && (
        <UploadFiles
          {...filesMapping.acte_naissance_enfant_traduit_files.upload}
          fieldArray={fieldArrayMapping.acte_naissance_enfant_traduit_files}
          isNew={isNew}
          lang={lang}
        />
      )}
    </>
  );
};

export default EnfantActeNaissance;
