import React, { ReactNode, useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Persistor } from 'redux-persist';
import { MatomoProvider } from '@jonkoops/matomo-tracker-react';

import Loader from 'components/Atoms/Loader/Loader';
import { RegisteredLocales } from 'constants/locales';
import { flattenMessages, onError } from 'services/i18n/intl';
import { getMatomoCookiesAcceptance } from 'redux/cookies/selectors';
import { getCurrentLocale } from 'redux/locale/selectors';
import { setLayout, setPagesTitle } from 'redux/pages';
import { setKeycloakStatus } from 'redux/keycloak';
import { useGetAliveQuery } from 'redux/rtk/keycloak/keycloakSlice';
import { useGetLayoutCmsPageQuery, useGetPageTitleQuery } from 'redux/rtk/cms/cmsCommon';
import { KeycloakStatus } from 'services/authentication';
import { initializeMatomo } from 'services/tracking/matomo';
import enMessages from 'translations/en.json';
import frMessages from 'translations/fr.json';

const locales = {
  [RegisteredLocales.FR]: flattenMessages(frMessages),
  [RegisteredLocales.EN]: flattenMessages(enMessages),
};

interface Props {
  children: ReactNode;
  persistor: Persistor;
}

const Root: React.FunctionComponent<Props> = ({ children }) => {
  const currentLocale = useSelector(getCurrentLocale);
  const areMatomoCookiesAccepted = useSelector(getMatomoCookiesAcceptance);
  const dispatch = useDispatch();

  const {
    data: commonCms,
    error: commonCmsError,
    isLoading: isCommonCmsLoading,
  } = useGetLayoutCmsPageQuery({
    locale: currentLocale ? currentLocale : 'fr',
  });

  const {
    data: pageTitleCms,
    error: pageTitleCmsError,
    isLoading: isPageTitleCmsLoading,
  } = useGetPageTitleQuery({
    locale: currentLocale ? currentLocale : 'fr',
  });
  const matomo = initializeMatomo(!areMatomoCookiesAccepted);
  const { data: kcData, error: kcError, isLoading: isKcLoading } = useGetAliveQuery();
  useEffect(() => {
    if (commonCms) {
      dispatch(setLayout(commonCms));
    }
  }, [dispatch, commonCms]);

  useEffect(() => {
    if (pageTitleCms) {
      dispatch(setPagesTitle(pageTitleCms));
    }
  }, [dispatch, pageTitleCms]);

  useEffect(() => {
    if (kcError) {
      dispatch(setKeycloakStatus(KeycloakStatus.DOWN));
    } else if (kcData) {
      dispatch(setKeycloakStatus(KeycloakStatus.UP));
    }
  }, [dispatch, kcData, kcError]);

  if (isCommonCmsLoading || isKcLoading || isPageTitleCmsLoading) {
    return <Loader />;
  }

  if (commonCmsError || pageTitleCmsError) {
    return <div>Redirect App error</div>;
  }
  if (!commonCms) {
    return null;
  }

  return (
    <MatomoProvider value={matomo}>
      <IntlProvider locale={currentLocale} messages={locales[currentLocale]} onError={onError}>
        {children}
      </IntlProvider>
    </MatomoProvider>
  );
};

export default Root;
