const filterNonObjectKeys = (obj: any) => {
  for (const key in obj) {
    if (typeof obj[key] === 'object') {
      if (obj[key].message) {
        delete obj[key];
      }
    }
  }
  return obj;
};

export const handleObjectErrors = (errors: any, control: any, mustManageObjectError: boolean) => {
  if (typeof errors !== 'object') return;
  if (Object.keys(errors).length === 0 && !mustManageObjectError) return;

  const objectErrors = filterNonObjectKeys({ ...errors });

  Object.keys(objectErrors).forEach(key => {
    Object.keys(objectErrors[key]).forEach(element => {
      control.setError(key, {
        type: objectErrors[key][element].type,
        message: objectErrors[key][element].message,
      });
    });
  });
};
