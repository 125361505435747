import React from 'react';

import { getRfCmsContents } from 'pages/DemandeRF/Helpers/Cms/getRfCmsContents';
import { ListCMSWidgetDocumentsInfoInfobulle } from 'helpers/cms/componentsHelper';

interface InfobulleCallOutDocumentsInfosProps {
  lang: string;
}

const InfobulleCallOutDocumentsInfos: React.FC<InfobulleCallOutDocumentsInfosProps> = ({
  lang,
}) => {
  const { globalCms } = getRfCmsContents(lang);
  return (
    <div>
      {ListCMSWidgetDocumentsInfoInfobulle(
        globalCms.bulle_comment_deposer_document_a_faire_pas_faire,
        globalCms.infobulle_encart_comment_envoyer_un_document,
        globalCms.texte_attention_format_iphone_heic,
      )}
    </div>
  );
};

export default InfobulleCallOutDocumentsInfos;
