import React from 'react';
import { useWatch } from 'react-hook-form';

import CommonRowContainer from 'components/Molecules/Forms/CommonRowContainer/CommonRowContainer';
import SelectInputContainer from 'components/Molecules/Forms/SelectInputContainer/SelectInputContainer';
import UploadFiles from 'components/Atoms/UploadFiles/UploadFiles';
import CustomCheckboxContainer from 'components/Molecules/Forms/CustomCheckboxContainer/CustomCheckboxContainer';
import { LIEN_AUTRE_PARENT } from 'constants/regroupementFamiliale';
import { getRfCmsContents } from 'pages/DemandeRF/Helpers/Cms/getRfCmsContents';

type EnfantLienAutreParentProps = {
  lang: string;
  fieldArrayMapping: any;
  filesMapping: any;
  isNew: string;
  formFieldsMapping: any;
  control: any;
};

const EnfantLienAutreParent: React.FC<EnfantLienAutreParentProps> = ({
  lang,
  fieldArrayMapping,
  filesMapping,
  isNew,
  formFieldsMapping,
  control,
}) => {
  const { bloc2Cms, globalCms } = getRfCmsContents(lang);
  const lienSecondParent = useWatch({
    control,
    name: 'lien_second_parent',
  });
  const jugementDroitGardeTraduit = useWatch({
    control,
    name: 'jugement_droit_garde_enfants_traduit',
  });
  const autreParentAutorisationVenueFranceTraduit = useWatch({
    control,
    name: 'autre_parent_autorisation_venue_france_traduit',
  });
  const declarationDisparitionAutreParentTraduit = useWatch({
    control,
    name: 'declaration_disparition_autre_parent_traduit',
  });
  const acteDecesTraduit = useWatch({
    control,
    name: 'acte_deces_traduit',
  });

  return (
    <>
      <div className="container fr-my-6v">
        <hr />
      </div>
      <CommonRowContainer
        legend={bloc2Cms.votre_lien_avec_second_parent}
        infobulleContent={bloc2Cms.infobulle_selecteur_lien_autre_parent}
        infobulleCustomModal
        lang={lang}
        children={[
          <SelectInputContainer
            {...formFieldsMapping.lien_second_parent}
            customClass="fr-col-12 fr-mt-4v fr-mt-lg-0v fr-mt-md-0v fr-col-sm-10"
            defaultValue={globalCms.choisir_option_defaut}
          />,
        ]}
      />
      {lienSecondParent === LIEN_AUTRE_PARENT.CONJOINT_ACTUEL && (
        <UploadFiles
          {...filesMapping.livret_famille_files.upload}
          fieldArray={fieldArrayMapping.livret_famille_files}
          isNew={isNew}
          lang={lang}
          className="fr-mt-2w"
        />
      )}
      {(lienSecondParent === LIEN_AUTRE_PARENT.SEPARE ||
        lienSecondParent === LIEN_AUTRE_PARENT.DIVORCE) && (
        <>
          <UploadFiles
            {...filesMapping.jugement_droit_garde_enfants_files.upload}
            fieldArray={fieldArrayMapping.jugement_droit_garde_enfants_files}
            isNew={isNew}
            lang={lang}
            className="fr-mt-2w"
          />
          <CustomCheckboxContainer
            {...formFieldsMapping.jugement_droit_garde_enfants_traduit}
            large
          />
          {jugementDroitGardeTraduit && (
            <UploadFiles
              {...filesMapping.jugement_droit_garde_enfants_traduit_files.upload}
              fieldArray={fieldArrayMapping.jugement_droit_garde_enfants_traduit_files}
              isNew={isNew}
              lang={lang}
              className="fr-mt-2w"
            />
          )}
          <UploadFiles
            {...filesMapping.autre_parent_autorisation_venue_france_files.upload}
            fieldArray={fieldArrayMapping.autre_parent_autorisation_venue_france_files}
            isNew={isNew}
            lang={lang}
            className="fr-mt-2w"
          />
          <CustomCheckboxContainer
            {...formFieldsMapping.autre_parent_autorisation_venue_france_traduit}
            large
          />
          {autreParentAutorisationVenueFranceTraduit && (
            <UploadFiles
              {...filesMapping.autre_parent_autorisation_venue_france_traduit_files.upload}
              fieldArray={fieldArrayMapping.autre_parent_autorisation_venue_france_traduit_files}
              isNew={isNew}
              lang={lang}
              className="fr-mt-2w"
            />
          )}
        </>
      )}
      {lienSecondParent === LIEN_AUTRE_PARENT.DECEDE && (
        <>
          <UploadFiles
            {...filesMapping.acte_deces_files.upload}
            fieldArray={fieldArrayMapping.acte_deces_files}
            isNew={isNew}
            lang={lang}
            className="fr-mt-2w"
          />
          <CustomCheckboxContainer {...formFieldsMapping.acte_deces_traduit} large />
          {acteDecesTraduit && (
            <UploadFiles
              {...filesMapping.acte_deces_traduit_files.upload}
              fieldArray={fieldArrayMapping.acte_deces_traduit_files}
              isNew={isNew}
              lang={lang}
              className="fr-mt-2w"
            />
          )}
        </>
      )}
      {lienSecondParent === LIEN_AUTRE_PARENT.DISPARU && (
        <>
          <UploadFiles
            {...filesMapping.declaration_disparition_autre_parent_files.upload}
            fieldArray={fieldArrayMapping.declaration_disparition_autre_parent_files}
            isNew={isNew}
            lang={lang}
            className="fr-mt-2w"
          />
          <CustomCheckboxContainer
            {...formFieldsMapping.declaration_disparition_autre_parent_traduit}
            large
          />
          {declarationDisparitionAutreParentTraduit && (
            <UploadFiles
              {...filesMapping.declaration_disparition_autre_parent_traduit_files.upload}
              fieldArray={fieldArrayMapping.declaration_disparition_autre_parent_traduit_files}
              isNew={isNew}
              lang={lang}
              className="fr-mt-2w"
            />
          )}
        </>
      )}
    </>
  );
};

export default EnfantLienAutreParent;
