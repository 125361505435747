import { User } from 'models/types/app/user';

export const formatUser = (data: any): User => {
  return {
    numEtranger: data.identifiant,
    nom_naissance: data.nom_naissance,
    nom_usage: data.nom_usage,
    prenom: data.prenom,
    sexe: data.sexe,
    date_naissance: data.date_naissance,
    pays_naissance: data.pays_naissance,
    nationalite: data.nationalite,
    situation_familiale: data.situation_familiale,
    adresse_mail: data.adresse_mail,
    telephone: data.telephone,
    date_entree_en_france: data.date_entree_en_france,
    date_entree_france: data.date_entree_france,
  };
};
