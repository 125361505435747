/* eslint-disable max-lines */
import { useDispatch, useSelector } from 'react-redux';
import { FC, useEffect, useState } from 'react';
import { useFieldArray, useWatch } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';

import { useUpdDemandeMutation } from 'redux/rtk/api/apiRf';
import {
  getDemandeHasLoad,
  getDemandeId,
  getListActivities,
  getRessourcesFormToSubmit,
  getRessourcesStore,
} from 'redux/demandeRf/selectors';
import { createError, createSuccess, submitForm } from 'redux/forms';

import { Ressource } from 'models/types/api/rf';
import { RessourcesDemandeForm } from 'models/types/app/demandeRf';
//import { DemandeRfErrors } from 'models/types/cms/demandeRF/type';

import { mapBoolFormToServer } from 'helpers/api/formSubmission';
import {
  handleArrayHydratation,
  handleFieldHydratation,
  handleNestedFieldHydratation,
} from 'helpers/api/FormHydratation';

import UploadFiles from 'components/Atoms/UploadFiles/UploadFiles';
import FormLayout from 'components/Molecules/DemandeRf/FormLayout/FormLayout';
import CommonRowContainer from 'components/Molecules/Forms/CommonRowContainer/CommonRowContainer';
import RadioButtonContainer from 'components/Molecules/Forms/RadioButtonContainer/RadioButtonContainer';
import CallOutDocumentsInfos from 'components/Molecules/DemandeRf/CallOutDocumentsInfos/CallOutDocumentsInfos';

import GetResourcesUseForm from './Resources.useForm';
import { ResourcesMapping } from './Resources.mapping';
import { GetUploadFilesParameters, reduxToFormMapping } from './Resources.helper';
import { getResourcesValidationSchema } from './Resources.validationSchema';
import { getDocumentsByCategory } from '../Accomodation/Accomodation.helper';

import { getRfCmsContents } from 'pages/DemandeRF/Helpers/Cms/getRfCmsContents';
import ResourcesSituation from './ResourcesSituation/ResourcesSituation';
import {
  setActivites,
  setRessourcesUntouched,
  setRessourcesValidation,
  updResourcesField,
  updResourcesStore,
} from 'redux/demandeRf';
import './Resources.scss';
import { useResourcesFormIsTouch } from 'pages/DemandeRF/DemandeRF.utils';
import { FormComponentsEnrichedText } from 'components/Atoms/HtmlParsers/FormComponentHtmlParser';
import Infobulle from 'components/Molecules/Infobulle/Infobulle';
import { createModal } from '@codegouvfr/react-dsfr/Modal';
import { DevTool } from '@hookform/devtools';

type ResourcesProps = {
  lang: string;
  saveFormProgress: boolean;
  setSaveFormProgress: React.Dispatch<React.SetStateAction<boolean>>;
  scrollToTablist: () => void;
};

const infobulleDocumentsModal = createModal({
  id: 'document-modal-resources',
  isOpenedByDefault: false,
});

const Resources: FC<ResourcesProps> = ({
  lang,
  saveFormProgress,
  setSaveFormProgress,
  scrollToTablist,
}) => {
  const { bloc4Cms, utilitaireCms, globalCms, errorCms } = getRfCmsContents(lang);

  const ressourcesFormToSubmit = useSelector(getRessourcesFormToSubmit);
  const resourcesDebug: any = {
    all: false,
    resourcesSituation: false,
    loadingData: {
      apiData: false,
      cmsData: false,
      formDataSetting: false,
      formValuesAfterLoad: false,
    },
    changeData: {
      effects: false,
      apiDataUpdate: false,
    },
    submitData: {
      formValues: false,
      sentData: false,
    },
  };

  const [demandeForm, setDemandeForm] = useState<RessourcesDemandeForm | null>(null);

  const demandeId = useSelector(getDemandeId);
  const demandeRessources = useSelector(getRessourcesStore);
  const demandeHasLoad = useSelector(getDemandeHasLoad);
  const activities = useSelector(getListActivities);
  const dispatch = useDispatch();

  const {
    // handleSubmit,
    // getValues,
    register,
    formState: { errors, isValid, touchedFields, dirtyFields },
    getValues,
    setValue,
    trigger,
    watch,
    control,
    handleSubmit,
  } = GetResourcesUseForm(errorCms, bloc4Cms);

  const formIsTouched = useResourcesFormIsTouch(
    touchedFields,
    Object.keys(touchedFields).length > 1,
  );

  useEffect(() => {
    errors && console.log(errors);
  }, [errors]);

  const uploadFilesParameter = GetUploadFilesParameters(
    demandeId ? demandeId : '',
    {
      control,
      setValue,
      getValues,
      trigger,
      errors,
      watch,
    },
    utilitaireCms,
  );

  const fieldArrayMapping = {
    activities: useFieldArray({
      control,
      name: 'activities',
    }),
    ress_dernier_avis_imposition_files: useFieldArray({
      control,
      name: 'ress_dernier_avis_imposition_files',
    }),
    ress_justificatif_vers_presta_social_algerien_files: useFieldArray({
      control,
      name: 'ress_justificatif_vers_presta_social_algerien_files',
    }),
    ress_btp_justificatif_conges_payes_files: useFieldArray({
      control,
      name: 'ress_btp_justificatif_conges_payes_files',
    }),
    ress_secu_social_indemnite_jour_files: useFieldArray({
      control,
      name: 'ress_secu_social_indemnite_jour_files',
    }),
    ress_decision_attribution_aah_asi_files: useFieldArray({
      control,
      name: 'ress_decision_attribution_aah_asi_files',
    }),
    ress_attestation_paiement_aah_asi_files: useFieldArray({
      control,
      name: 'ress_attestation_paiement_aah_asi_files',
    }),
    ress_autre_document_origine_revenu_files: useFieldArray({
      control,
      name: 'ress_autre_document_origine_revenu_files',
    }),
    ress_pension_alimentaires_ou_decision_justice_files: useFieldArray({
      control,
      name: 'ress_pension_alimentaires_ou_decision_justice_files',
    }),
    ress_releve_compte_origine_revenus_files: useFieldArray({
      control,
      name: 'ress_releve_compte_origine_revenus_files',
    }),
  };

  const filesMapping = ResourcesMapping.getGeneralMapping().get_files_fields(
    bloc4Cms,
    uploadFilesParameter,
    lang,
    //fieldArrayMapping,
  );

  const formFieldsMapping = ResourcesMapping.getGeneralMapping().getformFields(
    { bloc4Cms, globalCms },
    {
      setValue,
      control,
      trigger,
    },
    lang,
  );

  const [updateDemande, { isLoading, isError, data, isSuccess }] = useUpdDemandeMutation();
  if (resourcesDebug.all || resourcesDebug.changeData.apiDataUpdate) {
    console.log(isLoading, isError, data);
  }

  const watchTravailDansBtp = watch('travail_dans_btp');
  const watchArretTravail12DernierMois = watch('arret_travail_12_dernier_mois');
  const watchBeneficiaireAahOuAsi = watch('beneficiaire_aah_ou_asi');
  const watchAutresRessources = watch('autres_ressources');
  const watchPensionAlimentaire = watch('pension_alimentaire');
  const watchDeclarerConjointRessources = watch('declarer_conjoint_ressources');

  const watchRessources = useWatch({
    control,
    name: 'activities',
  });

  const watchNouvelleActivite = useWatch({
    control,
    name: 'nouvelle_activite',
  });

  const controlledFields = fieldArrayMapping.activities.fields.map((field, index) => {
    if (watchRessources) {
      return {
        ...field,
        ...watchRessources[index],
      };
    }

    return {
      ...field,
    };
  });

  const setValuesFromDemand = (formDemand: any) => {
    const onDemandeSetValues: any = {};
    for (const key in formDemand) {
      if (Array.isArray(formDemand[key]) && key !== 'activities') {
        if (formDemand[key].length > 0) {
          handleArrayHydratation(key, formDemand, fieldArrayMapping);
          if (resourcesDebug.all || resourcesDebug.loadingData.formDataSetting) {
            onDemandeSetValues[key] = formDemand[key];
          }
        }
      } else if (Array.isArray(formDemand[key]) && key === 'activities') {
        formDemand[key].forEach((el: any, index: number) => {
          for (const objectKey in el) {
            const currentKey = `activities.${index}.${objectKey}`;
            handleNestedFieldHydratation(
              currentKey,
              el[objectKey],
              getResourcesValidationSchema(errorCms, bloc4Cms),
              setValue,
            );
            if (resourcesDebug.all || resourcesDebug.loadingData.formDataSetting) {
              onDemandeSetValues[key] = formDemand[key];
            }
            // }
          }
        });
      } else if (formDemand[key] !== null && formDemand[key] !== undefined) {
        handleFieldHydratation(
          key,
          formDemand,
          getResourcesValidationSchema(errorCms, bloc4Cms),
          setValue,
        );
        if (resourcesDebug.all || resourcesDebug.loadingData.formDataSetting) {
          onDemandeSetValues[key] = formDemand[key];
        }
      }
    }
    if (resourcesDebug.all || resourcesDebug.submitData.formValues) {
      console.log('setValuesFromDemand: result ', onDemandeSetValues);
    }
  };

  const onSubmitNoValidationAfterUpload = () => {
    const values = control._formValues;
    delete values.validation;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (resourcesDebug.all || resourcesDebug.submitData.formValues) {
      console.log('onSubmitNoValidationAfterUpload: Submit ', values);
    }
    onSubmit(values);
  };

  const onSubmit = async (values: any) => {
    // if (values[HONEYPOT_NAME] !== '') {
    //   return;
    // }

    if (resourcesDebug.all || resourcesDebug.submitData.formValues) {
      console.log('onSubmit: parameters ', values);
      console.log('onSubmit: formValues ', getValues());
      console.log('onSubmit: formErrors ', errors);
      console.log('onSubmit: fieldArrays ', fieldArrayMapping);
    }
    const demandeForm = {
      id: null,
      travailDansBtp: mapBoolFormToServer(values.travail_dans_btp),
      arretTravail12DernierMois: mapBoolFormToServer(values.arret_travail_12_dernier_mois),
      beneficiaireAahOuAsi: mapBoolFormToServer(values.beneficiaire_aah_ou_asi),
      autresRessources: mapBoolFormToServer(values.autres_ressources),
      pensionAlimentaire: mapBoolFormToServer(values.pension_alimentaire),
      declarerConjointRessources: mapBoolFormToServer(values.declarer_conjoint_ressources),
      demandeResources: values.activities
        ?.filter((el: any) => el.activite !== '')
        .map(
          (item: { uuid: string | null | undefined; activite: any; estActuel: any; sort: any }) => {
            let documents: any = [];

            const resource = activities.find(activitie => activitie.id === item.uuid);
            if (resource) {
              documents = resource.documents;
            }
            return {
              id: item.uuid,
              activite: item.activite,
              estActuel: item.estActuel ? item.estActuel : false,
              documents: documents,
              sort: item.sort,
            };
          },
        ),
    };
    let response: any;
    let isError = false;

    if (resourcesDebug.all || resourcesDebug.submitData.sentData) {
      console.log('onSubmit: call body ', demandeForm);
      console.log('onSubmit: call response ', response);
    }

    try {
      const parameters = {
        body: demandeForm,
        demandeId: demandeId,
        isValidation: values.validation,
        etape: 'vos-ressources',
      };

      response = updateDemande(parameters);
      setSaveFormProgress(false);

      if (resourcesDebug.all || resourcesDebug.submitData.sentData) {
        console.log('onSubmit: call body ', demandeForm);
        console.log('onSubmit: call response ', response);
        console.log('onSubmit: isError ', isError);
      }
    } catch (e: any) {
      isError = true;
      if (e.response.status === 400) {
        dispatch(createError(e.response.body.title));
      }
    }
    if (!isError) {
      if (values.validation === true) {
        dispatch(createSuccess(utilitaireCms.toast_etape_valide));
      } else {
        dispatch(createSuccess(utilitaireCms.toast_demande_enregistre));
      }
    }
  };

  useEffect(() => {
    dispatch(setRessourcesUntouched(!formIsTouched));
  }, [formIsTouched]);

  useEffect(() => {
    dispatch(setRessourcesValidation(isValid));
  }, [isValid]);

  useEffect(() => {
    if (demandeHasLoad) {
      if (activities.length === 0) {
        const uuid = uuidv4();
        fieldArrayMapping.activities.append({
          activite: '',
          uuid: uuid,
          isNew: 'oui',
          estActuel: true,
          ress_artisa_bilan_comptable_resultat_attestation_revenu_files: [],
          ress_artisa_extrait_inscription_metiers_files: [],
          ress_autent_declaration_activite_cfe_files: [],
          ress_autent_livre_recettes_attestation_revenu_files: [],
          ress_commer_bilan_comptable_resultat_attestation_revenu_files: [],
          ress_commer_extrait_inscription_rcs_files: [],
          ress_dememp_releve_situation_droits_versements_pe_files: [],
          ress_invali_attribution_pension_retraite_invalidite_files: [],
          ress_invali_avis_versement_retraite_invalide_files: [],
          ress_prolib_bilan_comptable_resultat_attestation_revenu_files: [],
          ress_prolib_extrait_inscription_siren_files: [],
          ress_retrai_attribution_pension_retraite_invalidite_files: [],
          ress_retrai_avis_versement_retraite_invalide_files: [],
          ress_salcdd_bulletin_salaire_files: [],
          ress_salcdd_certificat_travail_files: [],
          ress_salcdd_contrat_attestation_travail_files: [],
          ress_salcdi_bulletin_salaire_files: [],
          ress_salcdi_certificat_travail_files: [],
          ress_salcdi_contrat_attestation_travail_files: [],
          ress_salint_certificat_travail_files: [],
          ress_salint_contrat_attestation_travail_files: [],
          sort: 1,
        });
        const newRessource = {
          activite: '',
          id: uuid,
          estActuel: true,
          documents: [],
          isNew: 'oui',
          sort: 1,
        };
        dispatch(setActivites(newRessource));
      } else {
        fieldArrayMapping.activities.replace(reduxToFormMapping(activities));
      }

      const arr: Ressource[] = [];

      setDemandeForm({
        activities: activities.length > 0 ? reduxToFormMapping(activities) : arr,
        travail_dans_btp:
          demandeRessources?.travailDansBtp !== null
            ? demandeRessources?.travailDansBtp
              ? 'oui'
              : 'non'
            : undefined,
        arret_travail_12_dernier_mois:
          demandeRessources?.arretTravail12DernierMois !== null
            ? demandeRessources?.arretTravail12DernierMois
              ? 'oui'
              : 'non'
            : undefined,
        beneficiaire_aah_ou_asi:
          demandeRessources?.beneficiaireAahOuAsi !== null
            ? demandeRessources?.beneficiaireAahOuAsi
              ? 'oui'
              : 'non'
            : undefined,
        autres_ressources:
          demandeRessources?.autresRessources !== null
            ? demandeRessources?.autresRessources
              ? 'oui'
              : 'non'
            : undefined,
        pension_alimentaire:
          demandeRessources?.pensionAlimentaire !== null
            ? demandeRessources?.pensionAlimentaire
              ? 'oui'
              : 'non'
            : undefined,
        declarer_conjoint_ressources:
          demandeRessources?.declarerConjointRessources !== null
            ? demandeRessources?.declarerConjointRessources
              ? 'oui'
              : 'non'
            : undefined,
        nouvelle_activite:
          activities?.length > 1
            ? 'oui'
            : activities?.length > 0 &&
              activities[0]?.isNew === undefined &&
              activities[0]?.activite !== ''
            ? 'non'
            : undefined,
        ress_dernier_avis_imposition_files: demandeRessources.documents
          ? getDocumentsByCategory('ress_dernier_avis_imposition', demandeRessources.documents)
          : [],
        ress_justificatif_vers_presta_social_algerien_files: demandeRessources.documents
          ? getDocumentsByCategory(
              'ress_justificatif_vers_presta_social_algerien',
              demandeRessources.documents,
            )
          : [],
        ress_btp_justificatif_conges_payes_files: getDocumentsByCategory(
          'ress_btp_justificatif_conges_payes',
          demandeRessources.documents,
        ),
        ress_secu_social_indemnite_jour_files: getDocumentsByCategory(
          'ress_secu_social_indemnite_jour',
          demandeRessources.documents,
        ),
        ress_decision_attribution_aah_asi_files: getDocumentsByCategory(
          'ress_decision_attribution_aah_asi',
          demandeRessources.documents,
        ),
        ress_attestation_paiement_aah_asi_files: getDocumentsByCategory(
          'ress_attestation_paiement_aah_asi',
          demandeRessources.documents,
        ),
        ress_autre_document_origine_revenu_files: getDocumentsByCategory(
          'ress_autre_document_origine_revenu',
          demandeRessources.documents,
        ),
        ress_pension_alimentaires_ou_decision_justice_files: getDocumentsByCategory(
          'ress_pension_alimentaires_ou_decision_justice',
          demandeRessources.documents,
        ),
        ress_releve_compte_origine_revenus_files: getDocumentsByCategory(
          'ress_ressources_du_conjoint',
          demandeRessources.documents,
        ),
      });
    }
  }, [demandeRessources]);

  useEffect(() => {
    if (watchTravailDansBtp !== '' && watchTravailDansBtp !== demandeRessources.travailDansBtp) {
      dispatch(
        updResourcesField({
          key: 'travailDansBtp',
          value: watchTravailDansBtp === 'oui' ? true : false,
        }),
      );
    }

    if (
      watchArretTravail12DernierMois !== '' &&
      watchArretTravail12DernierMois !== demandeRessources.arretTravail12DernierMois
    ) {
      dispatch(
        updResourcesField({
          key: 'arretTravail12DernierMois',
          value: watchArretTravail12DernierMois === 'oui' ? true : false,
        }),
      );
    }

    if (
      watchBeneficiaireAahOuAsi !== '' &&
      watchBeneficiaireAahOuAsi !== demandeRessources.beneficiaireAahOuAsi
    ) {
      dispatch(
        updResourcesField({
          key: 'beneficiaireAahOuAsi',
          value: watchBeneficiaireAahOuAsi === 'oui' ? true : false,
        }),
      );
    }

    if (
      watchAutresRessources !== '' &&
      watchAutresRessources !== demandeRessources.autresRessources
    ) {
      dispatch(
        updResourcesField({
          key: 'autresRessources',
          value: watchAutresRessources === 'oui' ? true : false,
        }),
      );
    }

    if (
      watchPensionAlimentaire !== '' &&
      watchPensionAlimentaire !== demandeRessources.pensionAlimentaire
    ) {
      dispatch(
        updResourcesField({
          key: 'pensionAlimentaire',
          value: watchPensionAlimentaire === 'oui' ? true : false,
        }),
      );
    }

    if (
      watchDeclarerConjointRessources !== '' &&
      watchDeclarerConjointRessources !== demandeRessources.declarerConjointRessources
    ) {
      dispatch(
        updResourcesField({
          key: 'declarerConjointRessources',
          value: watchDeclarerConjointRessources === 'oui' ? true : false,
        }),
      );
    }
  }, [
    watchTravailDansBtp,
    watchArretTravail12DernierMois,
    watchBeneficiaireAahOuAsi,
    watchAutresRessources,
    watchPensionAlimentaire,
    watchDeclarerConjointRessources,
  ]);

  useEffect(() => {
    if (demandeForm) {
      setValuesFromDemand(demandeForm);
    }
  }, [demandeForm]);

  useEffect(() => {
    if (watchNouvelleActivite === 'oui' && activities.length === 1) {
      const uuid = uuidv4();
      fieldArrayMapping.activities.append({
        activite: '',
        uuid: uuid,
        isNew: 'oui',
        estActuel: false,
        sort: activities.length + 1,
      });
      const newRessource = {
        activite: '',
        id: uuid,
        estActuel: false,
        documents: [],
        isNew: 'oui',
        sort: activities.length + 1,
      };
      dispatch(setActivites(newRessource));
    }
  }, [watchNouvelleActivite]);

  useEffect(() => {
    if (ressourcesFormToSubmit) {
      onSubmitNoValidationAfterUpload();
      dispatch(submitForm('ressources'));
    }
  }, [ressourcesFormToSubmit]);

  useEffect(() => {
    if (isSuccess && data) {
      dispatch(updResourcesStore(data));
    }
  }, [isSuccess, data]);

  useEffect(() => {
    if (saveFormProgress) {
      onSubmit({ ...control._formValues, validation: false });
    }
  }, [saveFormProgress]);

  return (
    <>
      <DevTool control={control} placement="top-right" />
      <FormLayout
        formIdentifier="resources-form"
        title={bloc4Cms?.titre_principal}
        requiredInfos={true}
        control={control}
        lang={lang}
        onSubmit={onSubmit}
        handleSubmit={handleSubmit}
        trigger={trigger}
        isValid={isValid}
        scrollToTablist={scrollToTablist}
        errors={errors}
      >
        <CommonRowContainer className="fr-grid-row fr-col-12 fr-mt-6v fr-mt-lg-2v fr-mt-md-2v">
          <CallOutDocumentsInfos lang={lang} infobulleDocumentsModal={infobulleDocumentsModal} />
          <></>
        </CommonRowContainer>

        <CommonRowContainer
          className="fr-grid-row fr-col-12 fr-mt-8v"
          children={[
            <UploadFiles
              {...filesMapping.ress_dernier_avis_imposition_files.upload}
              fieldArray={fieldArrayMapping.ress_dernier_avis_imposition_files}
            />,
          ]}
        />
        {controlledFields &&
          controlledFields.map((field, index) => (
            <ResourcesSituation
              index={index}
              key={`activity-${field.id}`}
              dispatch={dispatch}
              demandeId={demandeId}
              cms={{ bloc4Cms, globalCms, utilitaireCms }}
              formControls={{
                control,
                register,
                trigger,
                setValue,
                getValues,
                errors,
                watch,
                dirtyFields,
              }}
              filesControls={{
                field: field,
                append: fieldArrayMapping.activities.append,
                remove: fieldArrayMapping.activities.remove,
                total: fieldArrayMapping.activities.fields.length,
              }}
              resourcesDebug={resourcesDebug}
              lang={lang}
            />
          ))}

        <div className="container fr-mt-8v fr-mb-8v">
          <hr />
        </div>
        <CommonRowContainer
          legend={bloc4Cms.travail_dans_btp}
          lang={lang}
          infobulleContent={bloc4Cms.infobulle_titre_avez_vous_travailler_btp}
          prefixClassName="radio-group-container"
        >
          <RadioButtonContainer
            large={true}
            {...formFieldsMapping.travail_dans_btp}
            orientation="horizontal"
            className="fr-col-12 fr-mt-0v fr-mt-lg-0v fr-mt-md-0v fr-col-lg-12 fr-col-md-12"
          />
          <></>
        </CommonRowContainer>
        {watchTravailDansBtp === 'oui' && (
          <div>
            <CommonRowContainer
              className="fr-grid-row fr-col-12 fr-mt-8v"
              children={[
                <UploadFiles
                  {...filesMapping.ress_btp_justificatif_conges_payes_files.upload}
                  fieldArray={fieldArrayMapping.ress_btp_justificatif_conges_payes_files}
                />,
              ]}
            />
          </div>
        )}
        <div className="container fr-mt-8v fr-mb-8v">
          <hr />
        </div>
        <CommonRowContainer
          legend={bloc4Cms.arret_travail_annee_passee}
          lang={lang}
          infobulleContent={bloc4Cms.infobulle_arret_travail_derniers_mois}
          prefixClassName="radio-group-container"
        >
          <RadioButtonContainer
            large={true}
            {...formFieldsMapping.arret_travail_annee_passee}
            orientation="horizontal"
            className="fr-col-12 fr-mt-0v fr-mt-lg-0v fr-mt-md-0v fr-col-lg-12 fr-col-md-12"
          />
          <></>
        </CommonRowContainer>
        {watchArretTravail12DernierMois === 'oui' && (
          <div>
            <CommonRowContainer
              className="fr-grid-row fr-col-12 fr-mt-8v"
              children={[
                <UploadFiles
                  {...filesMapping.ress_secu_social_indemnite_jour_files.upload}
                  fieldArray={fieldArrayMapping.ress_secu_social_indemnite_jour_files}
                />,
              ]}
            />
          </div>
        )}
        <div className="container fr-mt-8v fr-mb-8v">
          <hr />
        </div>
        <>
          <div className="fr-col-12 fr-mt-md-4w fr-mt-3w">
            <legend className="aah-group flex-element radio-group-container">
              {FormComponentsEnrichedText(bloc4Cms.titre_beneficiaire_aah_asi)}
              <Infobulle
                cmsContent={bloc4Cms.infobulle_etes_vous_beneficiaire_aah_asi}
                infobulleName={(Math.random() + 1).toString(36).substring(7)}
                lang={lang}
              />
            </legend>
          </div>
          <div className="fr-grid-row fr-col-12 fr-mt-0v">
            <RadioButtonContainer
              large={true}
              {...formFieldsMapping.titre_beneficiaire_aah_asi}
              orientation="horizontal"
              className="fr-col-12 fr-mt-0v fr-mt-lg-0v fr-mt-md-0v fr-col-lg-12 fr-col-md-12"
            />
          </div>
        </>

        {watchBeneficiaireAahOuAsi === 'oui' && (
          <div>
            <CommonRowContainer
              className="fr-grid-row fr-col-12 fr-mt-8v"
              children={[
                <UploadFiles
                  {...filesMapping.ress_decision_attribution_aah_asi_files.upload}
                  fieldArray={fieldArrayMapping.ress_decision_attribution_aah_asi_files}
                />,
              ]}
            />
            <CommonRowContainer
              className="fr-grid-row fr-col-12 fr-mt-8v"
              children={[
                <UploadFiles
                  {...filesMapping.ress_attestation_paiement_aah_asi_files.upload}
                  fieldArray={fieldArrayMapping.ress_attestation_paiement_aah_asi_files}
                />,
              ]}
            />
          </div>
        )}
        <div className="container fr-mt-8v fr-mb-8v">
          <hr />
        </div>
        <CommonRowContainer
          legend={bloc4Cms.autres_ressources}
          lang={lang}
          infobulleContent={bloc4Cms.infobulle_autres_sources_revenus}
          prefixClassName="radio-group-container"
        >
          <RadioButtonContainer
            large={true}
            {...formFieldsMapping.autres_ressources}
            orientation="horizontal"
            className="fr-col-12 fr-mt-0v fr-mt-lg-0v fr-mt-md-0v fr-col-lg-12 fr-col-md-12"
          />
          <></>
        </CommonRowContainer>
        {watchAutresRessources === 'oui' && (
          <div>
            <CommonRowContainer
              className="fr-grid-row fr-col-12 fr-mt-8v"
              children={[
                <UploadFiles
                  {...filesMapping.ress_autre_document_origine_revenu_files.upload}
                  fieldArray={fieldArrayMapping.ress_autre_document_origine_revenu_files}
                />,
              ]}
            />
            <CommonRowContainer
              className="fr-grid-row fr-col-12 fr-mt-8v"
              children={[
                <UploadFiles
                  {...filesMapping.ress_justificatif_vers_presta_social_algerien_files.upload}
                  fieldArray={fieldArrayMapping.ress_justificatif_vers_presta_social_algerien_files}
                />,
              ]}
            />
            <CommonRowContainer
              legend={bloc4Cms.pension_alimentaire}
              lang={lang}
              infobulleContent={bloc4Cms.infobulle_fichier_pension_alimentaire}
              prefixClassName="radio-group-container"
            >
              <RadioButtonContainer
                large={true}
                {...formFieldsMapping.pension_alimentaire}
                orientation="horizontal"
                className="fr-col-12 fr-mt-0v fr-mt-lg-0v fr-mt-md-0v fr-col-lg-12 fr-col-md-12"
              />
              <></>
            </CommonRowContainer>
            {watchPensionAlimentaire === 'oui' && (
              <div>
                <CommonRowContainer
                  className="fr-grid-row fr-col-12 fr-mt-8v"
                  children={[
                    <UploadFiles
                      {...filesMapping.ress_pension_alimentaires_ou_decision_justice_files.upload}
                      fieldArray={
                        fieldArrayMapping.ress_pension_alimentaires_ou_decision_justice_files
                      }
                    />,
                  ]}
                />
              </div>
            )}
          </div>
        )}
        <div className="container fr-mt-8v fr-mb-8v">
          <hr />
        </div>
        <CommonRowContainer
          className="fr-grid-row"
          legend={bloc4Cms.declarer_conjoint_ressources}
          lang={lang}
          infobulleContent={bloc4Cms.infobulle_souhait_declarer_conjoint}
          prefixClassName="radio-group-container"
        >
          <RadioButtonContainer
            large={true}
            {...formFieldsMapping.declarer_conjoint_ressources}
            orientation="horizontal"
            className="fr-col-12 fr-mt-0v fr-mt-lg-0v fr-mt-md-0v fr-col-lg-12 fr-col-md-12"
          />
          <></>
        </CommonRowContainer>
        {watchDeclarerConjointRessources === 'oui' && (
          <div>
            <CommonRowContainer
              className="fr-grid-row fr-col-12 fr-mt-8v"
              children={[
                <UploadFiles
                  {...filesMapping.ress_releve_compte_origine_revenus_files.upload}
                  fieldArray={fieldArrayMapping.ress_releve_compte_origine_revenus_files}
                />,
              ]}
            />
          </div>
        )}
      </FormLayout>
    </>
  );
};

export default Resources;
