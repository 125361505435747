import { createInstance } from '@jonkoops/matomo-tracker-react';

import getEnv from 'helpers/app/envHelper';

export const initializeMatomo = (disabled: boolean) => {
  // createInstance will throw an error if urlBase gets a empty string ''. So we pass undefined to
  // avoid an error when REACT_APP_MATOMO_URL is not there. Tracking will not work, but no error
  // will be thrown and the app will be working
  return createInstance({
    urlBase: String(getEnv('REACT_APP_MATOMO_URL') || undefined),
    siteId: Number(getEnv('REACT_APP_MATOMO_SITE_ID')),
    linkTracking: false,
    disabled: disabled,
  });
};
