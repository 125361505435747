import React, { FC } from 'react';
import { CallOut } from '@codegouvfr/react-dsfr/CallOut';

import { EspaceRFCmsPage } from 'models/types/cms/espaceRF/types';

type Props = {
  suiviRFCms: EspaceRFCmsPage;
};
const DossierCloture: FC<Props> = ({ suiviRFCms }) => {
  return (
    <CallOut
      iconId="fr-icon-info-line"
      title={suiviRFCms.label_dossier_cloture}
      className="fr-my-md-5w fr-mt-3w fr-mb-5w"
    >
      {suiviRFCms.txt_dossier_cloture}
    </CallOut>
  );
};

export default DossierCloture;
