import * as yup from 'yup';
import { LocationCms } from 'models/types/cms/location/types';

export const isCodePostalValid = (codePostal: string): boolean => /^[0-9]{2,5}$/i.test(codePostal);

export const getValidationSchema = (locationPageCms: LocationCms | undefined) => {
  return yup.object({
    zipCode: yup
      .number()
      .typeError(locationPageCms?.code_postal_invalide ?? '')
      .required()
      .test('isValidPostalCode', locationPageCms?.code_postal_invalide ?? '', (value: any) =>
        isCodePostalValid(value),
      ),
  });
};
