import React, { useState } from 'react';
import { useController } from 'react-hook-form';

import AutocompleteFromAPI from 'components/Molecules/Forms/AutocompleteContainer/AutocompleteFromAPI';
import AutocompleteFromBANO from 'components/Molecules/Forms/AutocompleteContainer/AutocompleteFromBANO';
import { computeIsValidDsfr } from 'helpers/app/ComponentsStatusHelper';

interface AutocompleteContainerProps {
  label: string | React.ReactNode;
  hintText?: string;
  stateRelatedMessage?: string;
  name: string;
  control: any;
  customClass?: string;
  payload?: any;
  lang?: string;
  source: 'API' | 'BANO';
  searchType?: 'municipality' | undefined;
  disabled?: boolean;
}

const AutocompleteContainer: React.FC<AutocompleteContainerProps> = ({
  label,
  hintText,
  stateRelatedMessage,
  name,
  control,
  customClass,
  payload,
  lang,
  source,
  searchType,
  disabled,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { field, fieldState } = useController({
    name,
    control,
  });

  if (lang !== 'fr' && lang !== 'en') {
    lang = 'fr';
  }

  return (
    <div
      className={
        customClass
          ? customClass
          : 'fr-col-12 fr-mt-4v fr-mt-lg-0v fr-mt-md-0v fr-col-lg-4 fr-col-md-4'
      }
    >
      <div className="autocomplete-container">
        {source === 'API' ? (
          <AutocompleteFromAPI
            label={label}
            hintText={hintText}
            stateRelatedMessage={fieldState.error?.message ?? stateRelatedMessage}
            name={name}
            state={computeIsValidDsfr(fieldState.isTouched, fieldState.isDirty, fieldState.error)}
            field={field}
            control={control}
            payload={payload}
            lang={lang}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
          />
        ) : (
          <AutocompleteFromBANO
            label={label}
            hintText={hintText}
            stateRelatedMessage={fieldState.error?.message ?? stateRelatedMessage}
            name={name}
            state={computeIsValidDsfr(fieldState.isTouched, fieldState.isDirty, fieldState.error)}
            field={field}
            control={control}
            lang={lang}
            searchType={searchType}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            disabled={disabled}
          />
        )}
      </div>
    </div>
  );
};

export default AutocompleteContainer;
