import { cmsSlice } from './cmsSlice';

const cmsErreurService = cmsSlice.injectEndpoints({
  endpoints: build => ({
    getErreurServiceCmsPage: build.query<any, { qs?: string; locale: string }>({
      query: ({ locale }) => ({
        url: `/erreur-service?locale=${locale}&populate=*`,
      }),
    }),
  }),
});

export const { useGetErreurServiceCmsPageQuery } = cmsErreurService;
