import React from 'react';
import Input from '@codegouvfr/react-dsfr/Input';

import { unauthaurizedInput } from 'helpers/app/fieldsHelper';
import {
  EMAIL_AUTHORIZED_CHARS,
  ONLY_CHARS_DIGITS_AND_SPACES_REGEX,
  ONLY_DIGITS_REGEX,
} from 'constants/regex';

interface TextInputProps {
  label: string | React.ReactNode;
  hintText?: string;
  stateRelatedMessage?: string;
  name: string;
  state: string;
  control: any;
  field: any;
  onChange?: (e: React.ChangeEvent<HTMLInputElement> | string) => void;
  ref?: any;
  type: string;
  phoneSettings?: boolean;
  emailSettings?: boolean;
}

const TextInput: React.FC<TextInputProps> = React.forwardRef(
  (
    {
      label,
      hintText,
      stateRelatedMessage,
      control,
      name,
      state,
      field,
      onChange,
      type,
      phoneSettings,
      emailSettings,
    },
    ref,
  ) => {
    return hintText ? (
      <Input
        label={label}
        hintText={hintText}
        stateRelatedMessage={stateRelatedMessage}
        state={state}
        control={control}
        name={name}
        {...field}
        nativeInputProps={{
          type: type,
          value: field.value,
          onChange: e => {
            field.onChange(e.target.value);
            if (onChange && e.target.value !== undefined) {
              onChange(e.target.value);
            }
          },
          ref: ref,
          onKeyDown: e => {
            if (phoneSettings && !ONLY_DIGITS_REGEX.test(e.key) && unauthaurizedInput(e.key)) {
              e.preventDefault();
            }
            if (
              !phoneSettings &&
              !emailSettings &&
              !ONLY_CHARS_DIGITS_AND_SPACES_REGEX.test(e.key)
            ) {
              e.preventDefault();
            }
            if (emailSettings && !EMAIL_AUTHORIZED_CHARS.test(e.key)) {
              e.preventDefault();
            }
          },
        }}
      />
    ) : (
      <Input
        label={label}
        stateRelatedMessage={stateRelatedMessage}
        state={state}
        control={control}
        name={name}
        {...field}
        nativeInputProps={{
          type: type,
          value: field.value,
          onChange: e => {
            field.onChange(e.target.value);
            if (onChange && e.target.value !== undefined) {
              onChange(e.target.value);
            }
          },
          ref: ref,
          onKeyDown: e => {
            if (phoneSettings && !ONLY_DIGITS_REGEX.test(e.key) && unauthaurizedInput(e.key)) {
              e.preventDefault();
            }
            if (
              !phoneSettings &&
              !emailSettings &&
              !ONLY_CHARS_DIGITS_AND_SPACES_REGEX.test(e.key)
            ) {
              e.preventDefault();
            }
            if (emailSettings && !EMAIL_AUTHORIZED_CHARS.test(e.key)) {
              e.preventDefault();
            }
          },
        }}
      />
    );
  },
);

export default TextInput;
