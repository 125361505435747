/* eslint-disable complexity */
import React from 'react';
import { Routes, Route, useNavigate, Navigate } from 'react-router-dom';
import { useAuth } from 'react-oidc-context';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import { useSelector } from 'react-redux';
import { startReactDsfr } from '@codegouvfr/react-dsfr/spa';

import Timeout from 'components/Molecules/Timeout/Timeout';
import MainLayout from 'layouts/MainLayout';
import Home from 'pages/Home/Home';
import Procedures from 'pages/Procedures/Procedures';
import Services from 'pages/Services/Services';
import News from 'pages/News/News';
import Location from 'pages/Location/Location';
import Maintenance from 'pages/Maintenance/Maintenance';
import Contact from 'pages/Contact/Contact';
import CreationCompte from 'pages/CreationCompte/CreationCompte';
import Dashboard from 'pages/Dashboard/Dashboard';
import DemandeRF from 'pages/DemandeRF/DemandeRF';
import SiteMap from 'pages/SiteMap/SiteMap';
import Compte from 'pages/Compte/Compte';
import Documents from 'pages/Documents/Documents';
import AuthError from 'pages/Errors/AuthError';
import Error from 'pages/Errors/Error';
import EspaceRF from 'pages/EspaceRF/EspaceRF';
import EspaceAsile from 'pages/EspaceAsile/EspaceAsile';
import MonAllocation from 'pages/MonAllocation/MonAllocation';
import { getCurrentLocale } from 'redux/locale/selectors';
import useFetchMaintenance from 'services/useFetchMaintenance';

import {
  ACCUEIL,
  ACCUEIL_DIRECT,
  PROCEDURES,
  SERVICES_EN_LIGNE,
  ACCUEIL_ET_INTEGRATION,
  ARTICLES,
  LOCALISATION,
  PROCEDURE,
  ARTICLE,
  CREATION_DE_COMPTE,
  MENTION_LEGALES,
  ACCESSIBILITY,
  RGPD,
  COOKIE,
  TABLEAU_DE_BORD,
  CONTACT,
  PLAN_DU_SITE,
  MAINTENANCE,
  COMPTE,
  ASILE,
  ALLOCATION,
  DEMANDE_RF,
  DOCUMENTS,
  ERROR_AUTH,
  SUIVI_RF,
  ERROR,
  ERROR_SERVICE,
} from './path';
import AppRoute from './AppRoute';
import ServiceError from '../pages/Errors/ServiceError';
import AccueilEtIntegration from 'pages/CmsPage/Pages/AccueilEtIntegration';
import MentionLegales from 'pages/CmsPage/Pages/MentionLegales';
import Procedure from 'pages/CmsPage/Pages/Procedures';
import Accessibilite from 'pages/CmsPage/Pages/Accessibilite';
import Rgpd from 'pages/CmsPage/Pages/Rgpd';
import Cookie from 'pages/CmsPage/Pages/Cookie';
import NewsCms from 'pages/CmsPage/Pages/NewsCms';

interface JwtPayloadHipe extends JwtPayload {
  preferred_username: string;
}

const AppRoutes = () => {
  const lang = useSelector(getCurrentLocale) as string;
  const maintenanceState = useFetchMaintenance(lang);
  const navigate = useNavigate();

  startReactDsfr({
    defaultColorScheme: 'light',
    useLang: () => lang,
  });

  if (
    window.location.pathname.includes(MAINTENANCE) &&
    maintenanceState &&
    !(maintenanceState.data.attributes.mc || maintenanceState.data.attributes.mec)
  ) {
    navigate('/');
  }

  if (
    maintenanceState &&
    (maintenanceState.data.attributes.mc || maintenanceState.data.attributes.mec) &&
    !window.location.pathname.includes(MAINTENANCE)
  ) {
    navigate('/' + MAINTENANCE);
  }

  return (
    <>
      <Timeout lang={lang} />
      <Routes>
        <Route path="/">
          {maintenanceState &&
            (maintenanceState.data.attributes.mc || maintenanceState.data.attributes.mec) && (
              <Route
                path={MAINTENANCE}
                element={<Maintenance payload={maintenanceState.data.attributes} />}
              />
            )}
          <Route element={<MainLayout />}>
            {[ACCUEIL, ACCUEIL_DIRECT].map(path => (
              <Route
                path={path}
                element={
                  <AppRoute>
                    {path === ACCUEIL_DIRECT ? <Navigate to={ACCUEIL} replace={true} /> : <Home />}
                  </AppRoute>
                }
              />
            ))}
            <Route
              path={PROCEDURES}
              element={
                <AppRoute>
                  <Procedures />
                </AppRoute>
              }
            />
            <Route
              path={PROCEDURE}
              element={
                <AppRoute>
                  <Procedure />
                </AppRoute>
              }
            />
            <Route
              path={SERVICES_EN_LIGNE}
              element={
                <AppRoute>
                  <Services />
                </AppRoute>
              }
            />
            <Route
              path={ACCUEIL_ET_INTEGRATION}
              element={
                <AppRoute>
                  <AccueilEtIntegration />
                </AppRoute>
              }
            />
            <Route
              path={ARTICLES}
              element={
                <AppRoute>
                  <News />
                </AppRoute>
              }
            />
            <Route
              path={SERVICES_EN_LIGNE}
              element={
                <AppRoute>
                  <Services />
                </AppRoute>
              }
            />
            <Route
              path={ARTICLE}
              element={
                <AppRoute>
                  <NewsCms />
                </AppRoute>
              }
            />
            <Route
              path={LOCALISATION}
              element={
                <AppRoute>
                  <Location />
                </AppRoute>
              }
            />
            <Route
              path={CREATION_DE_COMPTE}
              element={
                <AppRoute>
                  <CreationCompte />
                </AppRoute>
              }
            />
            <Route
              path={MENTION_LEGALES}
              element={
                <AppRoute>
                  <MentionLegales />
                </AppRoute>
              }
            />
            <Route
              path={ACCESSIBILITY}
              element={
                <AppRoute>
                  <Accessibilite />
                </AppRoute>
              }
            />
            <Route
              path={RGPD}
              element={
                <AppRoute>
                  <Rgpd />
                </AppRoute>
              }
            />
            <Route
              path={COOKIE}
              element={
                <AppRoute>
                  <Cookie />
                </AppRoute>
              }
            />
            <Route
              path={CONTACT}
              element={
                <AppRoute>
                  <Contact />
                </AppRoute>
              }
            />
            <Route
              path={PLAN_DU_SITE}
              element={
                <AppRoute>
                  <SiteMap />
                </AppRoute>
              }
            />
            <Route
              path={ERROR_AUTH}
              element={
                <AppRoute>
                  <AuthError />
                </AppRoute>
              }
            />
            <Route
              path={ERROR_SERVICE}
              element={
                <AppRoute>
                  <ServiceError />
                </AppRoute>
              }
            />
            <Route
              path={ERROR}
              element={
                <AppRoute>
                  <Error />
                </AppRoute>
              }
            />
            <Route
              path={TABLEAU_DE_BORD}
              element={
                <AppRoute>
                  <RequireAuth>
                    <Dashboard />
                  </RequireAuth>
                </AppRoute>
              }
            />
            <Route
              path={COMPTE}
              element={
                <AppRoute>
                  <RequireAuth>
                    <Compte />
                  </RequireAuth>
                </AppRoute>
              }
            />
            <Route
              path={ASILE}
              element={
                <AppRoute>
                  <RequireAuth>
                    <EspaceAsile />
                  </RequireAuth>
                </AppRoute>
              }
            />
            <Route
              path={ALLOCATION}
              element={
                <AppRoute>
                  <RequireAuth>
                    <MonAllocation />
                  </RequireAuth>
                </AppRoute>
              }
            />
            <Route
              path={DEMANDE_RF + '/:demandeId'}
              element={
                <AppRoute>
                  <RequireAuth>
                    <DemandeRF />
                  </RequireAuth>
                </AppRoute>
              }
            />
            <Route
              path={SUIVI_RF}
              element={
                <AppRoute>
                  <RequireAuth>
                    <EspaceRF />
                  </RequireAuth>
                </AppRoute>
              }
            />
            <Route
              path={DOCUMENTS}
              element={
                <AppRoute>
                  <RequireAuth>
                    <Documents />
                  </RequireAuth>
                </AppRoute>
              }
            />
            <Route path="*" element={<Error />} />
          </Route>
        </Route>
      </Routes>
    </>
  );
};

export default AppRoutes;

function RequireAuth({ children }: { children: React.JSX.Element }) {
  const auth = useAuth();
  if (!auth.isAuthenticated) {
    auth.signinRedirect();
  }
  const decoded = jwtDecode<JwtPayloadHipe>(auth.user?.access_token as string);
  const regex = /\d{9,11}$/g;

  if (!decoded.preferred_username || !regex.test(decoded.preferred_username)) {
    void auth.signoutRedirect({
      post_logout_redirect_uri: window.location.origin + '/',
    });
  }

  return children;
}
