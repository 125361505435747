import { CMS_BASE_URL } from 'services/cmsClient';

function httpGet(lang: string) {
  const xmlHttp = new XMLHttpRequest();
  xmlHttp.open('GET', CMS_BASE_URL + '/api/cookie?locale=' + lang + '&populate=*', false);
  xmlHttp.send(null);
  return JSON.parse(xmlHttp.responseText);
}

export function ConsentCookieList(lang: string) {
  const cms = httpGet(lang);

  return {
    social: {
      title: cms.data.attributes.cookies_reseaux_sociaux_titre,
      description: cms.data.attributes.cookies_reseaux_sociaux_description,
      subFinalities: {
        facebook: 'Facebook',
        twitter: 'X (Twitter)',
        youtube: 'Youtube',
      },
    },
  };
}
