import React from 'react';

import { FAMILY_TYPE } from 'constants/regroupementFamiliale';
import { Conjoint, Enfant } from 'models/types/api/rf';
import { getRfCmsContents } from 'pages/DemandeRF/Helpers/Cms/getRfCmsContents';

interface BeneficiaireTileAddressProps<T> {
  beneficiaire: T;
  onSetAddress: () => void;
  type: 'conjoint' | 'enfant';
  lang: string;
}

const BeneficiaireTileAddress: React.FC<BeneficiaireTileAddressProps<Conjoint | Enfant>> = ({
  beneficiaire,
  onSetAddress,
  type,
  lang,
}) => {
  const { bloc2Cms } = getRfCmsContents(lang);
  const getTitle = (): string => {
    if (type === FAMILY_TYPE.CONJOINT) {
      return beneficiaire.adresse ? bloc2Cms.adresse_conjoint : bloc2Cms.adresse_vit_conjoint;
    } else {
      return beneficiaire.adresse
        ? bloc2Cms.adresse_de_votre_enfant
        : bloc2Cms.quelle_adresse_vit_enfant;
    }
  };
  return (
    <div className="fr-mt-4v">
      <div className="fr-tile fr-enlarge-link beneficiaire-adresse" onClick={onSetAddress}>
        <div className="fr-tile__body">
          <h3 className="fr-tile__title">{getTitle()}</h3>
          {beneficiaire.adresse ? (
            <>
              <p className="fr-tile__desc">
                {beneficiaire.adresse?.adresse?.numero}&nbsp;
                {beneficiaire.adresse?.adresse?.adresse1}
                <br />
                {beneficiaire.adresse?.adresse?.codePostal}&nbsp;-&nbsp;
                {beneficiaire.adresse?.adresse?.ville}
                &nbsp;-&nbsp;{beneficiaire.adresse?.adresse?.province}
                <br />
                {beneficiaire.adresse?.adresse?.email}
                <br />
                {beneficiaire.adresse?.adresse?.telephone}
              </p>
              <div className="fr-tile__footer">
                <button type="button" className="fr-icon-edit-line" />
              </div>
            </>
          ) : (
            <div className="fr-tile__footer">
              <button type="button" className="fr-icon-arrow-right-line" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BeneficiaireTileAddress;
