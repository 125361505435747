import React, { FC } from 'react';
import { Accordion } from '@codegouvfr/react-dsfr/Accordion';
import { fr } from '@codegouvfr/react-dsfr';
import { useStyles } from 'tss-react/dsfr';

import { API_DATE_FORMAT, DISPLAY_DATE_DDMMYYYY } from 'constants/time';
import { RFBeneficiare } from 'models/types/api/rf';
import { RFBeneficairesCmsComponent } from 'models/types/cms/espaceRF/types';
import { formatNullableDate } from 'services/date';

type Props = {
  beneficiaires: RFBeneficiare[];
  cmsBeneficiaires: RFBeneficairesCmsComponent;
};
const Beneficiaires: FC<Props> = ({ beneficiaires, cmsBeneficiaires }) => {
  const { cx } = useStyles();
  return (
    <>
      <h3>{cmsBeneficiaires.titre}</h3>
      <div className="fr-grid-row fr-grid-row--gutters beneficiaire-demande">
        {beneficiaires.map(beneficiaire => (
          <div
            className="fr-col-12 fr-col-md-6"
            key={beneficiaire.nomBeneficiaire + beneficiaire.prenomBeneficiaire}
          >
            <Accordion
              label={`${beneficiaire.nomBeneficiaire}  ${beneficiaire.prenomBeneficiaire}`}
              defaultExpanded
            >
              <div className="fr-grid-row fr-mb-2w fr-mb-md-3w">
                <div className={cx(fr.cx('fr-col-1'), 'icon')}>
                  <span className={fr.cx('fr-icon-team-line')} aria-hidden={true} />
                </div>
                <div className="fr-col-11 text">
                  {cmsBeneficiaires.label_lien_parente} :{' '}
                  <span className="title">
                    {beneficiaire.lienFamilial !== null &&
                    cmsBeneficiaires[beneficiaire.lienFamilial]
                      ? cmsBeneficiaires[beneficiaire.lienFamilial]
                      : beneficiaire.lienFamilial}
                  </span>
                </div>
              </div>
              <div className="fr-grid-row fr-mb-2w fr-mb-md-3w">
                <div className={cx(fr.cx('fr-col-1'), 'icon')}>
                  <span className={fr.cx('fr-icon-calendar-line')} aria-hidden={true} />
                </div>
                <div className="fr-col-11">
                  {cmsBeneficiaires.label_date_naissance} :{' '}
                  <span className="title">
                    {formatNullableDate(
                      beneficiaire.dateNaissance,
                      API_DATE_FORMAT,
                      DISPLAY_DATE_DDMMYYYY,
                    )}
                  </span>
                </div>
              </div>
              <div className="fr-grid-row">
                <div className={cx(fr.cx('fr-col-1'), 'icon')}>
                  <span className={fr.cx('fr-icon-map-pin-2-line')} aria-hidden={true} />
                </div>
                <div className="fr-col-11 text">
                  {cmsBeneficiaires.label_pays_residence} :{' '}
                  <span className="title">{beneficiaire.paysResidence}</span>
                </div>
              </div>
            </Accordion>
          </div>
        ))}
      </div>
    </>
  );
};

export default Beneficiaires;
