/* eslint-disable complexity */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactNode, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Button from '@codegouvfr/react-dsfr/Button';

import { getRfCmsContents } from 'pages/DemandeRF/Helpers/Cms/getRfCmsContents';
import { handleObjectErrors } from 'helpers/app/UseFormHelper';
import { createError } from 'redux/forms';

import '../DemandeRf.scss';
import './FormLayout.style.scss';

interface FormLayoutProps {
  formIdentifier: string;
  title: string;
  children: ReactNode;
  requiredInfos?: boolean;
  onSubmit?: any;
  control?: any;
  lang: string;
  handleSubmit: any;
  trigger: any;
  scrollToTablist: () => void;
  isValid: boolean;
  errors?: any;
  isLastStep?: boolean;
  buttonValidationName?: string;
}

const FormLayout: React.FC<FormLayoutProps> = ({
  formIdentifier,
  title,
  children,
  requiredInfos,
  onSubmit,
  control,
  lang,
  trigger,
  isValid,
  isLastStep = false,
  buttonValidationName,
  errors,
  scrollToTablist,
}) => {
  //const [selectedTab, setSelectedTab] = useState<number | null | undefined>(0);
  const [mustManageObjectError, setMustManageObjectError] = useState(true);
  const dispatch = useDispatch();

  const { globalCms, utilitaireCms, errorCms } = getRfCmsContents(lang);

  const onSubmitWithValidation = () => {
    onSubmit({ ...control._formValues, validation: true });
  };

  const currentSelectedTab = () => {
    const tablist = document.querySelector('[role="tablist"]');
    const tabs = tablist?.getElementsByTagName('button');
    let selected = null;
    if (tabs) {
      for (let i = 0; i < tabs.length; i++) {
        if (tabs[i].getAttribute('aria-selected') === 'true') {
          selected = i;
        }
      }
      return selected;
    }
    return null;
  };

  const handleTabChange = (action: string) => {
    const tablist = document.querySelector('[role="tablist"]');
    const tabs = tablist?.getElementsByTagName('button');

    if (tabs) {
      const selected = currentSelectedTab();
      if (action === 'next' && selected !== null && selected < 4) {
        const onSelect = selected + 1;
        tabs[onSelect].setAttribute('aria-selected', 'true');
        //setSelectedTab(onSelect);
      }

      if (action === 'prev' && selected !== null && selected > 0 && selected !== 1) {
        const onSelect = selected - 1;
        tabs[onSelect].setAttribute('aria-selected', 'true');
        //setSelectedTab(onSelect);
      }

      if (action === 'prev' && selected !== null && selected === 1) {
        tabs[0].click();
        //setSelectedTab(0);
      }
    }
  };

  useEffect(() => {
    handleObjectErrors(errors, control, mustManageObjectError);
    setMustManageObjectError(false);
  }, [errors]);

  return (
    <form id={formIdentifier} className="fr-form">
      <div className="fr-container--fluid">
        <h4>{title}</h4>
        {requiredInfos && (
          <div className="fr-grid-row">
            <p className="required-infos">{utilitaireCms.message_general_champs_obligatoire}</p>
          </div>
        )}
        {children}
      </div>

      <div className="fr-grid-row form-action-row">
        <div className="fr-col actions-column">
          {formIdentifier !== 'who-are-you-form' && (
            <div className="fr-grid-row fr-grid-row--left back-link-container">
              <a
                className="fr-link fr-icon-arrow-left-line fr-link--icon-left back-link"
                href="#"
                onClick={e => {
                  e.preventDefault();
                  handleTabChange('prev');
                  scrollToTablist();
                }}
              >
                {globalCms.texte_bouton_retour}
              </a>
            </div>
          )}
        </div>

        <div className="fr-col fr-pt-2w fr-pb-2w">
          <div className="fr-grid-row fr-grid-row--right">
            <Button
              className="mobile-large-button next-button"
              iconId="fr-icon-arrow-right-line"
              iconPosition="right"
              priority="primary"
              type="button"
              onClick={async e => {
                e.preventDefault();
                trigger();

                if (isValid) {
                  await onSubmitWithValidation();
                  if (!isLastStep) {
                    handleTabChange('next');
                    scrollToTablist();
                  }
                } else {
                  console.log(errors);
                  const form: HTMLFormElement = document.getElementById(
                    formIdentifier,
                  ) as HTMLFormElement;

                  const list: any = form.querySelectorAll('input, select');

                  if (list[0].type === 'file') {
                    const element: any = form.querySelector(
                      `label.custom-label[for="${list[0].name}"]`,
                    );
                    dispatch(createError(errorCms.erreur_generique_fichier));
                    element.focus();
                  } else {
                    dispatch(createError(errorCms.erreur_validation_cases));
                    list[0].focus();
                  }
                }
              }}
            >
              {buttonValidationName ? buttonValidationName : globalCms.texte_bouton_suivant}
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default FormLayout;
