import qs from 'qs';

export const CleStatutsFieldQueryObject = {
  fields: [
    'statut',
    'explication_titre',
    'explication_description',
    'sous_statut',
    'en_attente_details',
  ],
};

export const suiviRfQuery = qs.stringify({
  populate: '*',
});
export const traductionApiRfQuery = qs.stringify({
  populate: {
    fields: ['demande_rf_en_cours'],
    nom_etapes: {
      populate: '*',
    },
    statut_etapes: {
      populate: '*',
    },
    lien_familial: {
      populate: '*',
    },
    statuts_enquetes: {
      populate: '*',
    },
    motifs_enquete_ressources: {
      populate: '*',
    },
  },
});

export const traductionApiRf1Query = qs.stringify({
  populate: {
    clef_statuts: {
      populate: {
        receptionne__receptionne_document_recu: CleStatutsFieldQueryObject,
        receptionne__receptionne: CleStatutsFieldQueryObject,
      },
    },
  },
});

export const traductionApiRf2Query = qs.stringify({
  populate: {
    clef_statuts: {
      populate: {
        attente_decision_prefecture__attente_decision_prefecture: CleStatutsFieldQueryObject,
        decision__decision_attente_retour_prefecture: CleStatutsFieldQueryObject,
      },
    },
  },
});

export const traductionApiRf3Query = qs.stringify({
  populate: {
    clef_statuts: {
      populate: {
        pas_etape__dossier_cloture__acquisition_nationnalite_francaise: CleStatutsFieldQueryObject,
        pas_etape__dossier_cloture__demenagement_departement: CleStatutsFieldQueryObject,
      },
    },
  },
});

export const traductionApiRf4Query = qs.stringify({
  populate: {
    clef_statuts: {
      populate: {
        receptionne__receptionne_attente_retour_prefecture: CleStatutsFieldQueryObject,
        receptionne__receptionne_attente_documents_complementaires: CleStatutsFieldQueryObject,
      },
    },
  },
});

export const traductionApiRf5Query = qs.stringify({
  populate: {
    clef_statuts: {
      populate: {
        en_cours_instruction__en_cours_instruction: CleStatutsFieldQueryObject,
      },
    },
  },
});

export const traductionApiRf6Query = qs.stringify({
  populate: {
    clef_statuts: {
      populate: {
        decision__favorable: CleStatutsFieldQueryObject,
        decision__favorable_attente_paiement: CleStatutsFieldQueryObject,
      },
    },
  },
});
export const traductionApiRf7Query = qs.stringify({
  populate: {
    clef_statuts: {
      populate: {
        decision__favorable_relance_paiement: CleStatutsFieldQueryObject,
        decision__defavorable: CleStatutsFieldQueryObject,
      },
    },
  },
});
export const traductionApiRf8Query = qs.stringify({
  populate: {
    clef_statuts: {
      populate: {
        pas_etape__dossier_cloture__demande_interresse: CleStatutsFieldQueryObject,
      },
    },
  },
});

export const traductionApiRf9Query = qs.stringify({
  populate: {
    clef_statuts: {
      populate: {
        pas_etape__dossier_cloture__dt_non_competente: CleStatutsFieldQueryObject,
        pas_etape__dossier_cloture__dossier_incomplet: CleStatutsFieldQueryObject,
      },
    },
  },
});
export const traductionApiRf10Query = qs.stringify({
  populate: {
    clef_statuts: {
      populate: {
        pas_etape__dossier_cloture: CleStatutsFieldQueryObject,
        pas_etape__dossier_cloture__dossier_irrecevable: CleStatutsFieldQueryObject,
      },
    },
  },
});
export const traductionApiRf11Query = qs.stringify({
  populate: {
    clef_statuts: {
      populate: {
        portail__en_cours_de_depot: CleStatutsFieldQueryObject,
        portail__receptionne: CleStatutsFieldQueryObject,
      },
    },
  },
});
