export const generateServicesSection = (
  cms: any,
): {
  key: string;
  title: string;
  description: string;
  image: {
    src: string;
    alt: string;
  };
  link: {
    href: string;
    title?: string;
  };
}[] => {
  return [
    {
      key: 'bloc_1',
      title: cms.services.bloc_1.titre,
      description: cms.services.bloc_1.description,
      image: {
        src: 'dsfr/artwork/pictograms/digital/internet.svg',
        alt: '',
      },
      link: {
        href: 'les-services-en-ligne',
      },
    },
    {
      key: 'bloc_2',
      title: cms.services.bloc_2.titre,
      description: cms.services.bloc_2.description,
      image: {
        src: 'dsfr/artwork/pictograms/digital/calendar.svg',
        alt: '',
      },
      link: {
        href: 'les-procedures',
      },
    },
    {
      key: 'bloc_3',
      title: cms.services.bloc_3.titre,
      description: cms.services.bloc_3.description,
      image: {
        src: 'dsfr/artwork/pictograms/digital/search.svg',
        alt: '',
      },
      link: {
        href: 'article',
      },
    },
  ];
};

export const generateAccueilEtIntegrationSection = (cms: any) => {
  return [
    {
      key: 'parcours',
      title: cms.integration.bloc_1.titre,
      description: cms.integration.bloc_1.description,
      image: {
        src: 'img/Visuel_Parcours.png', //Strapi
        alt: '',
      },
      link: {
        href: cms.integration.bloc_1.lien,
      },
    },
    {
      key: 'apprendre',
      title: cms.integration.bloc_2.titre,
      description: cms.integration.bloc_2.description,
      image: {
        src: 'img/Visuel_ApprendreFrançais.png', //Strapi
        alt: '',
      },
      link: {
        href: cms.integration.bloc_2.lien,
      },
    },
  ];
};

export const generateSection = (
  elements: {
    key: string;
    title: string;
    badgeTitle?: string | undefined;
    badgeType?: string | undefined;
    description?: string | undefined;
    image: {
      src?: string | undefined;
      alt?: string | undefined;
    };
    link: {
      href: string;
      title?: string | undefined;
    };
  }[],
): {
  key: string;
  title: string;
  badgeTitle?: string;
  badgeType?: string;
  description?: string;
  image: {
    src?: string;
    alt?: string;
  };
  link: {
    href: string;
    title?: string;
  };
}[] => {
  return elements.map(el => {
    return {
      key: el.key,
      title: el.title,
      badgeTitle: el.badgeTitle,
      badgeType: el.badgeType,
      description: el.description,
      image: el.image,
      link: {
        href: el.link.href,
        title: el.link.title,
      },
    };
  });
};
