/* eslint-disable complexity */
import React from 'react';

import { Rappel, RappelType } from 'constants/rappels';
import CustomAlert from 'components/Atoms/CustomAlert/CustomAlert';
import { RappelsCms } from 'models/types/api/rappels';

interface Props {
  rappel: Rappel;
  rappelCms: RappelsCms;
}

const RappelsCard: React.FC<Props> = ({ rappel, rappelCms }) => {
  if (!rappel || !rappel.description) return null;
  const titleMessage =
    rappel.type === RappelType.INFORMATION ? rappelCms.rappel_information : rappelCms.rappel_urgent;

  let descriptionRappel = '';
  if (typeof rappel.description === 'object') {
    descriptionRappel = rappelCms.texte_rappel_changement_statut_rf.replace(
      '{numeroDossier}',
      rappel.description.numeroDossier || '',
    );
    descriptionRappel = descriptionRappel.replace('{statut}', rappel.description.statut || '');
  } else {
    descriptionRappel = rappel.description;
  }

  return (
    <CustomAlert
      title={titleMessage}
      severity={rappel.type}
      description={descriptionRappel}
      customClass="fr-mb-4v"
    />
  );
};

export default RappelsCard;
