import { cmsSlice } from './cmsSlice';

const cmsDemandeRF = cmsSlice.injectEndpoints({
  endpoints: build => ({
    getErrorCms: build.query<any, { qs: string; locale: string }>({
      query: ({ qs, locale }) => ({
        url: `/rf-erreur?locale=${locale}&${qs}`,
      }),
    }),
    getGlobalCms: build.query<any, { qs: string; locale: string }>({
      query: ({ qs, locale }) => ({
        url: `/rf-global?locale=${locale}&${qs}`,
      }),
    }),
    getUtilitaireCms: build.query<any, { qs: string; locale: string }>({
      query: ({ qs, locale }) => ({
        url: `/rf-utilitaire?locale=${locale}&${qs}`,
      }),
    }),
    getBloc1Cms: build.query<any, { qs: string; locale: string }>({
      query: ({ qs, locale }) => ({
        url: `/rf-bloc1?locale=${locale}&${qs}`,
      }),
    }),
    getBloc1FormCms: build.query<any, { qs: string; locale: string }>({
      query: ({ qs, locale }) => ({
        url: `/rf-bloc1-form?locale=${locale}&${qs}`,
      }),
    }),
    getBloc2Cms: build.query<any, { qs: string; locale: string }>({
      query: ({ qs, locale }) => ({
        url: `/rf-bloc2?locale=${locale}&${qs}`,
      }),
    }),
    getBloc2FormCms: build.query<any, { qs: string; locale: string }>({
      query: ({ qs, locale }) => ({
        url: `/rf-bloc2-form?locale=${locale}&${qs}`,
      }),
    }),
    getBloc3Cms: build.query<any, { qs: string; locale: string }>({
      query: ({ qs, locale }) => ({
        url: `/rf-bloc3?locale=${locale}&${qs}`,
      }),
    }),
    getBloc3FormCms: build.query<any, { qs: string; locale: string }>({
      query: ({ qs, locale }) => ({
        url: `/rf-bloc3-form?locale=${locale}&${qs}`,
      }),
    }),
    getBloc4Cms: build.query<any, { qs: string; locale: string }>({
      query: ({ qs, locale }) => ({
        url: `/rf-bloc4?locale=${locale}&${qs}`,
      }),
    }),
    getBloc4FormCms: build.query<any, { qs: string; locale: string }>({
      query: ({ qs, locale }) => ({
        url: `/rf-bloc4-form?locale=${locale}&${qs}`,
      }),
    }),
    getBloc5Cms: build.query<any, { qs: string; locale: string }>({
      query: ({ qs, locale }) => ({
        url: `/rf-bloc5?locale=${locale}&${qs}`,
      }),
    }),
    getBloc5FormCms: build.query<any, { qs: string; locale: string }>({
      query: ({ qs, locale }) => ({
        url: `/rf-bloc5-form?locale=${locale}&${qs}`,
      }),
    }),
    getRecapitulatifCms: build.query<any, { qs: string; locale: string }>({
      query: ({ qs, locale }) => ({
        url: `/rf-recapitulatif?locale=${locale}&${qs}`,
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetErrorCmsQuery,
  useGetGlobalCmsQuery,
  useGetUtilitaireCmsQuery,
  useGetRecapitulatifCmsQuery,
  useGetBloc1CmsQuery,
  useGetBloc1FormCmsQuery,
  useGetBloc2CmsQuery,
  useGetBloc2FormCmsQuery,
  useGetBloc3CmsQuery,
  useGetBloc3FormCmsQuery,
  useGetBloc4CmsQuery,
  useGetBloc4FormCmsQuery,
  useGetBloc5CmsQuery,
  useGetBloc5FormCmsQuery,
} = cmsDemandeRF;
