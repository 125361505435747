export const MILLISECONDS_IN_A_DAY = 1000 * 3600 * 24;

export const API_DATE_FORMAT = 'yyyy-MM-dd';
export const DISPLAY_DATE_DDMMYYYY = 'dd/MM/yyyy';
export const DISPLAY_DATE_YYYY = 'yyyy';
export const DISPLAY_DATE_FORMAT = 'dd/MM/yyyy';

export enum Jours {
  LUNDI = 'lundi',
  MARDI = 'mardi',
  MERCREDI = 'mercredi',
  JEUDI = 'jeudi',
  VENDREDI = 'vendredi',
  SAMEDI = 'samedi',
  DIMANCHE = 'dimanche',
}
