import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { getCommitmentsValidationSchema } from './Commitments.validationSchema';
import { CommitmentsDefaultValues } from './Commitments.defaultValues';
import {
  DemandeRfBloc5,
  DemandeRfErrors,
  DemandeRfUtilitaire,
} from 'models/types/cms/demandeRF/type';

const GetCommitmentsUseForm = (
  bloc5Cms: DemandeRfBloc5,
  erreursRfCms: DemandeRfErrors,
  utilitaireCms: DemandeRfUtilitaire,
) => {
  const CommitmentsValidationSchema = useMemo(
    () => getCommitmentsValidationSchema(bloc5Cms, erreursRfCms, utilitaireCms),
    [],
  );

  const {
    handleSubmit,
    getValues,
    setValue,
    getFieldState,
    trigger,
    watch,
    formState: { isValid, errors, touchedFields },
    control,
  } = useForm({
    resolver: yupResolver(CommitmentsValidationSchema),
    defaultValues: {
      ...CommitmentsDefaultValues,
    },
    mode: 'onChange',
  });

  return {
    handleSubmit,
    getValues,
    setValue,
    getFieldState,
    trigger,
    watch,
    formState: { isValid, errors, touchedFields },
    control,
  };
};

export default GetCommitmentsUseForm;
