import { cmsEnrichedText } from 'components/Atoms/HtmlParsers/PageContentHtmlParser';
import { RFNG_CAT_MAPPING } from 'constants/upload';
import { UNION_TYPE } from 'constants/regroupementFamiliale';
import { DemandeRfBloc2, DemandeRfGeneral } from 'models/types/cms/demandeRF/type';
import { FilesField, UploadFilesParameters } from 'models/types/app/uploadFiles';
import { CustomAlertProps } from 'components/Atoms/CustomAlert/CustomAlert';

interface AlertsMapping {
  alert_marie: CustomAlertProps;
}

export default class unionMapping {
  static getGeneralMapping() {
    function getAlertsFields(bloc2Cms: DemandeRfBloc2): AlertsMapping {
      return {
        alert_marie: {
          title: bloc2Cms.bulle_alerte_mariage_civils_seul.titre,
          description: bloc2Cms.bulle_alerte_mariage_civils_seul.contenu
            ? cmsEnrichedText(bloc2Cms.bulle_alerte_mariage_civils_seul.contenu)
            : '',
          severity: 'warning',
          closable: false,
        },
      };
    }

    function getFormFields(
      cms: {
        bloc2Cms: DemandeRfBloc2;
        globalCms: DemandeRfGeneral;
      },
      formControls: {
        setValue: any;
        getValues: any;
        control: any;
        trigger: any;
      },
    ) {
      const { bloc2Cms, globalCms } = cms;
      const { setValue, control, trigger, getValues } = formControls;
      return {
        union_precedente: {
          name: 'union_precedente',
          label: bloc2Cms.union_precedente,
          control: control,
          options: [
            {
              label: bloc2Cms.marie,
              value: UNION_TYPE.MARIE,
            },
            {
              label: bloc2Cms.pacse,
              value: UNION_TYPE.PACSE,
            },
          ],
        },
        union_debut: {
          control: control,
          name: 'union_debut',
          label: bloc2Cms.date_debut,
          hintText: globalCms.annee.sous_titre,
          required: true,
          type: 'number',
        },
        union_fin: {
          control: control,
          name: 'union_fin',
          label: bloc2Cms.date_fin,
          hintText: globalCms.annee.sous_titre,
          required: true,
          type: 'number',
        },
        union_jugement_divorce_traduit: {
          name: 'union_jugement_divorce_traduit',
          key: 'union_jugement_divorce_traduit',
          options: [
            {
              label: globalCms.document_langue_origine,
              id: 'union_jugement_divorce_traduit',
              hintText: globalCms.sous_titre_case_document_langue_origine,
              nativeInputProps: {
                name: 'union_jugement_divorce_traduit',
                onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                  setValue('union_jugement_divorce_traduit', e.target.checked);
                  trigger();
                },
                checked: getValues('union_jugement_divorce_traduit'),
              },
            },
          ],
          control: control,
        },
      };
    }

    function getFilesFields(
      bloc2Cms: DemandeRfBloc2,
      uploadFilesParameters: UploadFilesParameters,
      errorCms: any,
    ): FilesField {
      return {
        union_jugement_divorce_files: {
          upload: {
            id: 'infos-union-jugement-divorce',
            categorie: RFNG_CAT_MAPPING.UNION_JUGEMENT_DIVORCE,
            label: bloc2Cms.fichier_union_jugement_divorce.titre_fichier ?? '',
            hint: bloc2Cms.fichier_union_jugement_divorce.infos_fichier
              ? cmsEnrichedText(bloc2Cms.fichier_union_jugement_divorce.infos_fichier)
              : '',
            state: 'default',
            stateRelatedMessage: errorCms.erreur_generique_fichier,
            required: true,
            ...uploadFilesParameters,
            name: 'union_jugement_divorce_files',
            resource: 'union',
            resourceId: undefined,
            helpbox: {
              closable: false,
              description: bloc2Cms.fichier_union_jugement_divorce.bulle_aide_fichier_contenu
                ? cmsEnrichedText(
                    bloc2Cms.fichier_union_jugement_divorce.bulle_aide_fichier_contenu,
                  )
                : '',
              severity: 'info',
              title: bloc2Cms.fichier_union_jugement_divorce.bulle_aide_fichier_titre,
            },
            infobulleContent: bloc2Cms.infobulle_union_fichier_acte_divorce_fr,
            infobulleCustomModal: true,
          },
        },
        union_jugement_divorce_traduit_files: {
          upload: {
            id: 'infos-union-jugement-divorce-traduit',
            categorie: RFNG_CAT_MAPPING.UNION_JUGEMENT_DIVORCE_TRAD,
            label: bloc2Cms.fichier_union_jugement_divorce_traduit.titre_fichier ?? '',
            hint: bloc2Cms.fichier_union_jugement_divorce_traduit.infos_fichier
              ? cmsEnrichedText(bloc2Cms.fichier_union_jugement_divorce_traduit.infos_fichier)
              : '',
            state: 'default',
            stateRelatedMessage: errorCms.erreur_generique_fichier,
            required: true,
            ...uploadFilesParameters,
            name: 'union_jugement_divorce_traduit_files',
            resource: 'union',
            resourceId: undefined,
            helpbox: {
              closable: false,
              description: bloc2Cms.fichier_union_jugement_divorce_traduit
                .bulle_aide_fichier_contenu
                ? cmsEnrichedText(
                    bloc2Cms.fichier_union_jugement_divorce_traduit.bulle_aide_fichier_contenu,
                  )
                : '',
              severity: 'info',
              title: bloc2Cms.fichier_union_jugement_divorce_traduit.bulle_aide_fichier_titre,
            },
          },
        },
        attestetation_dissolution_pacs_files: {
          upload: {
            id: 'infos-attestetation-dissolution-pacs',
            categorie: RFNG_CAT_MAPPING.ATTESTATION_DISSOLUTION_PACS,
            label: bloc2Cms.fichier_attestation_dissolution_pacs.titre_fichier ?? '',
            hint: bloc2Cms.fichier_attestation_dissolution_pacs.infos_fichier
              ? cmsEnrichedText(bloc2Cms.fichier_attestation_dissolution_pacs.infos_fichier)
              : '',
            state: 'default',
            stateRelatedMessage: errorCms.erreur_generique_fichier,
            required: true,
            ...uploadFilesParameters,
            name: 'attestetation_dissolution_pacs_files',
            resource: 'union',
            resourceId: undefined,
            helpbox: {
              closable: false,
              description: bloc2Cms.fichier_attestation_dissolution_pacs.bulle_aide_fichier_contenu
                ? cmsEnrichedText(
                    bloc2Cms.fichier_attestation_dissolution_pacs.bulle_aide_fichier_contenu,
                  )
                : '',
              severity: 'info',
              title: bloc2Cms.fichier_attestation_dissolution_pacs.bulle_aide_fichier_titre,
            },
            infobulleContent: bloc2Cms.infobulle_union_fichier_attestation_dissolution_pacs,
            infobulleCustomModal: true,
          },
        },
      };
    }

    return { getAlertsFields, getFormFields, getFilesFields };
  }
}
