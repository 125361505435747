export const directionTerritoriales = [
  {
    codePostal: '75000',
    nomDT: 'DT Paris dsqdqsd',
    adresseDT: '83 rue de Patay 75013 Paris',
    telephoneDT: 'XX WW XX XX',
    horairesDT: [
      {
        jour: 'lundi',
        ouvertMatin: '9h',
        fermeMatin: '12h',
        ouvertAprem: '13h30',
        fermeAprem: '16h',
      },
      {
        jour: 'mardi',
        ouvertMatin: '9h',
        fermeMatin: '12h',
        ouvertAprem: '13h30',
        fermeAprem: '16h',
      },
      {
        jour: 'mercredi',
        ouvertMatin: '9h',
        fermeMatin: '12h',
        ouvertAprem: '13h30',
        fermeAprem: '16h',
      },
      {
        jour: 'jeudi',
        ouvertMatin: '9h',
        fermeMatin: '12h',
        ouvertAprem: '13h30',
        fermeAprem: '16h',
      },
      {
        jour: 'vendredi',
        ouvertMatin: '9h',
        fermeMatin: '12h',
        ouvertAprem: '13h30',
        fermeAprem: '16h',
      },
      {
        jour: 'samedi',
        ouvertMatin: null,
        fermeMatin: null,
        ouvertAprem: null,
        fermeAprem: null,
      },
      {
        jour: 'dimanche',
        ouvertMatin: null,
        fermeMatin: null,
        ouvertAprem: null,
        fermeAprem: null,
      },
    ],
  },
  {
    codePostal: '38000',
    nomDT: 'DT Grenoble',
    adresseDT: '76 rue des Alli\u00e9s 38103 Grenoble',
    telephoneDT: '04 76 40 95 45',
    horairesDT: [
      {
        jour: 'lundi',
        ouvertMatin: '9h',
        fermeMatin: '12h',
        ouvertAprem: '13h30',
        fermeAprem: '16h',
      },
      {
        jour: 'mardi',
        ouvertMatin: '9h',
        fermeMatin: '12h',
        ouvertAprem: '13h30',
        fermeAprem: '16h',
      },
      {
        jour: 'mercredi',
        ouvertMatin: '9h',
        fermeMatin: '12h',
        ouvertAprem: '13h30',
        fermeAprem: '16h',
      },
      {
        jour: 'jeudi',
        ouvertMatin: '9h',
        fermeMatin: '12h',
        ouvertAprem: '13h30',
        fermeAprem: '16h',
      },
      {
        jour: 'vendredi',
        ouvertMatin: '9h',
        fermeMatin: '12h',
        ouvertAprem: '13h30',
        fermeAprem: '16h',
      },
      {
        jour: 'samedi',
        ouvertMatin: null,
        fermeMatin: null,
        ouvertAprem: null,
        fermeAprem: null,
      },
      {
        jour: 'dimanche',
        ouvertMatin: null,
        fermeMatin: null,
        ouvertAprem: null,
        fermeAprem: null,
      },
    ],
  },
];

export const versements = [
  {
    montant: null,
    periode: 'Juillet 2021',
    nbrJourCalcule: '31',
    statut: 'erreur',
    dateVersement: '2021-07-29',
    nombreMajeurs: 2,
    nombreMineurs: 0,
    dateCompositionFamiliale: '2021-06-14',
    statutHebergement: 'heberge',
    dateStatutHebergement: '2021-06-14',
  },
  {
    montant: 329,
    periode: 'Juin 2021',
    nbrJourCalcule: '30',
    statut: 'paye',
    dateVersement: '2021-06-28',
    nombreMajeurs: 2,
    nombreMineurs: 0,
    dateCompositionFamiliale: '2021-06-18',
    statutHebergement: 'domicilie',
    dateStatutHebergement: '2021-06-18',
  },
  {
    montant: 201.8,
    periode: 'Mai 2021',
    nbrJourCalcule: '14',
    statut: 'paye',
    dateVersement: '2021-05-30',
    nombreMajeurs: 2,
    nombreMineurs: 0,
    dateCompositionFamiliale: '2021-05-18',
    statutHebergement: 'domicilie',
    dateStatutHebergement: '2021-05-18',
  },

  {
    montant: 426,
    periode: 'Avril 2021',
    nbrJourCalcule: '30',
    statut: 'paye',
    dateVersement: '2021-04-28',
    nombreMajeurs: 2,
    nombreMineurs: 0,
    dateCompositionFamiliale: '2021-03-18',
    statutHebergement: 'domicilie',
    dateStatutHebergement: '2021-03-18',
  },
  {
    montant: 198.8,
    periode: 'Mars 2021',
    nbrJourCalcule: '14',
    statut: 'paye',
    dateVersement: '2021-03-30',
    nombreMajeurs: 2,
    nombreMineurs: 0,
    dateCompositionFamiliale: '2021-03-18',
    statutHebergement: 'domicilie',
    dateStatutHebergement: '2021-03-18',
  },
];

export const user = {
  identifiant: 7503134045,
  nom_naissance: 'TEST HIPE',
  nom_usage: 'DET',
  prenom: 'Hugo',
  sexe: 'M',
  date_naissance: '1979-01-01',
  pays_naissance: 'MAROC',
  nationalite: 'marocaine',
  situation_familiale: 'MARIE',
  adresse_mail: 'hugo.benoist@kaora-partners.com',
  telephone: '',
  date_entree_en_france: '',
  date_entree_france: '',
  compo_famille_conjoint: null,
  compo_famille_nbre_enfant: null,
  compo_famille_enfants: null,
};

export const hebergements = {
  nomStructure: 'FTDA boulevard Ney',
  adresseStructure: '92 BOULEVARD NEY 75018 PARIS',
  telStructure: '02 45 68 92 75',
  statutHebergement: 'Domicilie',
  dateHebergement: '2020-03-18',
};
