import { RFNG_CAT_MAPPING } from 'constants/upload';
import { FamilyDemandeForm } from 'models/types/app/demandeRf';
import { getDocumentsByCategory } from 'pages/DemandeRF/Helpers/common';

export const familyApiToAppFormatter = (documents: any) => {
  const family: FamilyDemandeForm = {
    lettre_explication_rf_files: getDocumentsByCategory(
      RFNG_CAT_MAPPING.LETTRE_EXPLICATIVE_RF_PARTIEL,
      documents,
    ),
  };

  return family;
};
