import React from 'react';

import { RadioButtons } from '@codegouvfr/react-dsfr/RadioButtons';
import { FormComponentsEnrichedText } from 'components/Atoms/HtmlParsers/FormComponentHtmlParser';

export type radioOptions = {
  label: string;
  nativeInputProps: {
    checked?: boolean;
    onChange: () => void;
    value?: string;
    hintText?: string;
  };
  value?: string;
  id?: string;
};

export interface RadioButtonProps {
  label: string;
  hintText?: string;
  options: radioOptions[];
  stateRelatedMessage?: string;
  orientation?: 'horizontal' | 'vertical';
  name: string;
  control: any;
  state: string;
  field: any;
}

const RadioButton: React.FC<RadioButtonProps> = ({
  label,
  hintText,
  name,
  options,
  stateRelatedMessage,
  orientation = 'horizontal',
  state,
  field,
}) => {
  if (field.value.length >= 1) {
    options.map(option => {
      if (option.nativeInputProps.value === field.value) {
        option.nativeInputProps.checked = true;
      }
      return option;
    });
  }
  return (
    <RadioButtons
      legend={FormComponentsEnrichedText(label)}
      hintText={hintText}
      name={name}
      stateRelatedMessage={stateRelatedMessage}
      orientation={orientation}
      options={options}
      state={state}
      {...field}
    />
  );
};

export default RadioButton;
