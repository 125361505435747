import { PagesState } from './pageState.model';

const imageState = {
  id: 1,
  balise_alt: '',
  image: {
    data: {
      id: 1,
      attributes: {
        ext: '',
        url: '',
        hash: '',
        mime: '',
        name: '',
        size: 1,
        width: 1,
        height: 1,
        formats: null,
        createdAt: '',
        updatedAt: '',
        provider: '',
        alternativeText: '',
        caption: '',
        previewUrl: '',
        provider_metadata: '',
      },
    },
  },
};

export const initialState: PagesState = {
  shared: {
    titre: null,
    header: null,
    menu: null,
    footer: null,
    articles: null,
    procedures: null,
    services: null,
    blocs: {
      articles: null,
      procedures: null,
      services: null,
    },
  },
  accueil: {
    titre: null,
    banner: null,
    services: null,
    integration: null,
  },
  procedures: {
    titre: null,
    actualites: null,
    integration: null,
  },
  services: {
    titre: null,
    actualites: null,
  },
  articles: {
    titre: null,
  },
  location: {
    titre: null,
    description_form: null,
    description_whithout_form: null,
    zipCode_label: null,
    results: null,
    button: null,
    map_link: null,
    call: null,
    closed: null,
    errors: {
      dt: undefined,
      zipCode: null,
      title: null,
      other: null,
    },
    erreur_direction_territoriale_non_trouvee: undefined,
    description_sans_formulaire: undefined,
    non_rattache_dt: undefined,
    titre_coordonnees: undefined,
    dt_sur_la_carte: undefined,
    horaires: undefined,
  },
  accueil_et_integration: {
    titre: null,
    id: null,
    contenu: null,
  },
  creation_de_compte: {
    id: null,
    titre: null,
    sous_titre: null,
    disclaimer: null,
    label_prenom_1: '',
    label_prenom_2: '',
    label_prenom_3: '',
    label_nom_1: '',
    label_nom_2: '',
    label_nom_3: '',
    label_identifiant: '',
    label_identifiant_info_bulle_titre: null,
    label_identifiant_info_bulle_texte: null,
    label_email: '',
    label_email_info_bulle_titre: null,
    label_email_info_bulle_texte: null,
    erreur_champ_obligatoire: null,
    erreur_champ_invalide: null,
    erreur_taille_maximum: null,
    bouton_envoi_formulaire: null,
    label_pays_naissance: '',
    label_nationalite: '',
    label_date_naissance: '',
    label_annee: '',
    label_mois: '',
    label_jour: '',
    sous_titre_champs: null,
    bouton_ajouter_prenom: null,
    bouton_ajouter_nom: null,
    erreur_date: null,
    erreur_email: null,
    erreur_longueur_numero_etranger: null,
    resultat_compte_cree: null,
    resultat_compte_existant: null,
    resultat_erreur_generique: null,
    titre_date_naissance: null,
    infobulle_texte_identifiant_image_tj_avant_2011: '',
    infobulle_texte_identifiant_image_tj_avant_2020: '',
    infobulle_texte_identifiant_image_tj_apres_2020: '',
    infobulle_texte_identifiant_image_visa: '',
    infobulle_identifiant_image_tj_apres_2020: imageState,
    infobulle_identifiant_image_tj_avant_2020: imageState,
    infobulle_identifiant_image_tj_avant_2011: imageState,
    infobulle_identifiant_image_visa: imageState,
    infobulle_date_naissance: [],
    infobulle_nationalite: [],
    infobulle_pays_naissance: [],
    infobulle_prenom: [],
    infobulle_nom: [],
    infobulle_bulle_aide: [],
    vous_etes_nee_le: null,
    titre_etape_1: null,
    titre_etape_2: null,
    format_attendu_prenom: '',
    format_attendu_nom: '',
    format_attendu_identifiant: '',
    format_attendu_date: '',
    format_attendu_email: '',
    titre_bulle_aide: '',
    contenu_bulle_aide: '',
    txt_bouton_bulle_aide: '',
    bouton_precedent: '',
    bouton_suivant: '',
    sous_titre_etape_1: '',
    sous_titre_sub_etape_1: '',
    sous_titre_etape_2: '',
    erreur_dois_etre_majeur: '',
    erreur_date_futur: '',
    bouton_retour_accueil: '',
    bouton_retour_page_login: '',
    message_success_sous_bulle_aide: '',
    bouton_infobulle_arialabel: '',
    titre_infobulle_identifiant: '',
    titre_creation_success: '',
    titre_creation_error: '',
  },
  mentions: {
    id: null,
    titre: null,
    description_page: null,
  },
  rgpd: {
    id: null,
    titre: null,
    description_page: null,
  },
  accessibilite: {
    id: null,
    titre: null,
    description_page: null,
  },
  cookie: {
    id: null,
    description_page: undefined,
    personnalisation_description: undefined,
    cookies_essentiels_titre: undefined,
    cookies_essentiels_description: undefined,
    cookies_reseaux_sociaux_titre: undefined,
    cookies_reseaux_sociaux_description: undefined,
    lien_cookie_matomo: undefined,
    lien_cookie_facebook: undefined,
    lien_cookie_twitter: undefined,
    preference_tous_services: undefined,
    bouton_tout_accepter: undefined,
    alt_bouton_tout_accepter: undefined,
    bouton_tout_refuser: '',
    alt_bouton_tout_refuser: undefined,
    accepter: undefined,
    refuser: undefined,
    confirmer_choix: undefined,
    a_propos_des_cookies_sur: '',
    bouton_personnaliser: undefined,
    personnalisation_titre: undefined,
    paneau_gestion_cookies: undefined,
    titre: undefined,
    donnees_personnelles: undefined,
    donnee_personnelles_et_cookies: undefined,
    url_site: undefined,
  },
  compte: {
    titre: null,
    contact_detail_labels: {
      titre: null,
      email: null,
      phone: null,
    },
    identity_labels: {
      titre: null,
      identifier: null,
      birthname: null,
      firstname: null,
      lastname: null,
      birth_state: null,
      birthdate: null,
      date_of_entry: null,
      family_situation: null,
      gender: null,
      nationality: null,
    },
    password_change_label: {
      titre: null,
      button_text: null,
      description: null,
    },
  },
  dashboard: {
    titre: null,
    content_modale_confirmation_envoi_demande_rf: null,
    text_demande_soumise: null,
    titre_modale_confirmation_envoi_demande_rf: null,
    bouton_retour_modale: null,
    bouton_je_donne_mon_avis_alt: null,
    bouton_je_donne_mon_avis_lien: null,
    bouton_je_donne_mon_avis_lien_image: null,
    procedures_en_cours: {
      titre: null,
      texte_aucune_procedure: null,
      titre_en_cours: null,
      titre_termines: null,
    },
    procedures: {
      titre_bloc: null,
      titre: null,
      description: null,
      texte_lien: null,
      illustration: null,
      illustration_alt: null,
    },
    services_en_ligne: {
      titre_bloc: null,
      titre: null,
      description: null,
      texte_lien: null,
      illustration: null,
      illustration_alt: null,
    },
  },
  titrePages: {
    accessibilite: null,
    accueil: null,
    accueil_et_integration: null,
    accueil_non_connecte: null,
    allocation: null,
    article: null,
    articles: null,
    asile: null,
    bagages: null,
    compte: null,
    contact: null,
    cookies: null,
    creation_compte: null,
    documents: null,
    erreur: null,
    erreur_auth: null,
    expression_besoin: null,
    localisation: null,
    mentions_legales: null,
    plan_site: null,
    procedure: null,
    procedures: null,
    regroupement_familial: null,
    reinsertion: null,
    retour: null,
    rgpd: null,
    services_en_ligne: null,
    tableau_de_bord: null,
    voir_fil_ariane: null,
  },
  documents: {
    titre: null,
  },
  erreur_auth: {
    titre: null,
  },
};
