import * as yup from 'yup';

import { STATUT_DOCUMENT } from 'constants/documents/regroupementFamilial';
import { DocumentReformat } from 'models/types/api/rf';
import { DemandeRfErrors } from 'models/types/cms/demandeRF/type';
import { explicitFieldError } from 'services/regroupementFamiliale';

import { noDigitCharacters } from './regexHelper';

export class FormHelper {
  errorsRfCms: DemandeRfErrors;

  constructor(errorsRfCms: DemandeRfErrors) {
    this.errorsRfCms = errorsRfCms;
  }

  nameFieldSchema() {
    return yup
      .string()
      .max(32, this.errorsRfCms.caracteres_speciaux_interdits)
      .matches(noDigitCharacters, this.errorsRfCms.caracteres_speciaux_interdits)
      .defined();
  }

  stringSchema(required = false, errorMessage?: string) {
    return required
      ? yup
          .string()
          .required(
            errorMessage
              ? explicitFieldError(errorMessage, this.errorsRfCms)
              : this.errorsRfCms.champ_requis,
          )
      : yup.string().notRequired();
  }

  conditionalStringSchema(key: string, matchedValue: string, errorMessage?: string) {
    return yup.string().when(key, ([value]) => {
      return value === matchedValue
        ? this.stringSchema(true, errorMessage)
        : this.stringSchema(false);
    });
  }

  requiredBoolSchema(errorMessage?: string) {
    return yup
      .boolean()
      .required(
        errorMessage
          ? explicitFieldError(errorMessage, this.errorsRfCms)
          : this.errorsRfCms.champ_requis,
      );
  }

  rangeNumberSchema(min: number, max: number, required = false) {
    const schema = yup.number().min(min).max(max);
    return required ? schema.required(this.errorsRfCms.champ_requis) : schema;
  }

  requiredFileSchema() {
    return yup
      .array()
      .of(
        yup.object({
          id: yup.string().nullable().notRequired(),
          document: yup
            .object({
              id: yup.string().required().nullable(),
              idRfng: yup.string().required().nullable(),
              categorie: yup.string().required().nullable(),
              statut: yup.string().required().nullable(),
              nomFichier: yup.string().required().nullable(),
              nomFichierPublic: yup.string().required().nullable(),
              options: yup
                .object({
                  en_francais: yup.boolean().required(),
                })
                .required()
                .nullable(),
            })
            .required()
            .nullable(),
        }),
      )
      .min(1, this.errorsRfCms.erreur_generique_fichier)
      .required(this.errorsRfCms.erreur_generique_fichier)
      .test(
        'error_file_is_not_set',
        this.errorsRfCms.erreur_generique_fichier,
        (files: DocumentReformat[]) => {
          if (files) {
            return files?.length > 0;
          }
          return false;
        },
      )
      .test(
        'error_file_invalid',
        this.errorsRfCms.erreur_generique_fichier,
        (files: DocumentReformat[]) => {
          if (files && files[0]) {
            return files[0].hasOwnProperty('document') && files[0].document?.id !== null;
          }
          return false;
        },
      )
      .test(
        'error_file_infected',
        this.errorsRfCms.erreur_generique_fichier_infected,
        (files: DocumentReformat[]) => {
          if (files) {
            if (files?.length > 0) {
              return !files.some(item => item.document?.statut === STATUT_DOCUMENT.INFECTED);
            }
          }
          return true;
        },
      );
  }

  notRequiredFileSchema() {
    return yup
      .array()
      .of(
        yup.object({
          id: yup.string().notRequired().nullable(),
          document: yup
            .object({
              id: yup.string().notRequired().nullable(),
              categorie: yup.string().notRequired().nullable(),
              statut: yup.string().notRequired().nullable(),
              nomFichier: yup.string().notRequired().nullable(),
              nomFichierPublic: yup.string().notRequired().nullable(),
              options: yup
                .object({
                  en_francais: yup.boolean().notRequired(),
                })
                .notRequired()
                .nullable(),
            })
            .notRequired()
            .nullable(),
        }),
      )
      .notRequired();
  }

  isOnPast(date: Date) {
    const now = Date.now();

    if (date.getTime() > now) {
      return false;
    }

    return true;
  }

  isOnFutur(date: Date) {
    const now = Date.now();

    if (date.getTime() < now) {
      return false;
    }

    return true;
  }

  isMinor(date: Date) {
    const age = Math.floor((new Date().valueOf() - date.getTime()).valueOf() / 3.15576e10);
    return age <= 17 && age >= 0;
  }
}
