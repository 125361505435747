import { Factory } from 'miragejs';

export const documentRf = Factory.extend({
  type: 'pdf',
  titre: '20240215-160942-cerf_demande_rf-65ce2936eae67',
  poids: '132.68Ko',
  codeDocument: '682058',
  categorie: 'CERFA_portail',
  typeDocument: 1,
});
