import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { DemandeRfErrors, DemandeRfUtilitaire } from 'models/types/cms/demandeRF/type';

import { getConjointValidationSchema } from '../helpers/conjointValidationSchema';
import { conjointDefaultValues } from '../helpers/conjointDefaultValues';

const ConjointUseForm = (
  erreursRfCms: DemandeRfErrors,
  utilitaireCms: DemandeRfUtilitaire,
  nationalitesNonEligibles: Array<string>,
) => {
  const conjointValidationSchema = useMemo(
    () => getConjointValidationSchema(erreursRfCms, utilitaireCms, nationalitesNonEligibles),
    [erreursRfCms, nationalitesNonEligibles],
  );

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    getFieldState,
    trigger,
    watch,
    formState,
    control,
  } = useForm({
    resolver: yupResolver(conjointValidationSchema),
    defaultValues: {
      ...conjointDefaultValues,
    },
    mode: 'all',
  });

  return {
    handleSubmit,
    getValues,
    setValue,
    getFieldState,
    trigger,
    watch,
    formState,
    control,
    register,
  };
};

export default ConjointUseForm;
