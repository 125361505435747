export const CommitmentsDefaultValues = {
  certifie_info_exacts: undefined,
  changements_a_ladministration: undefined,
  certifie_non_polygamie: undefined,
  article_code_entree_sejour: undefined,
  certifie_avoir_lu_ensemble_engagement: undefined,
  acceptation_rgpd: undefined,
  lettre_explication_rf_files: [],
  num_portable: undefined,
  email: undefined,
  num_fix: undefined,
  checkbox_num_fixe: false,
  validation: 'true',
};
