/* eslint-disable max-params */
import * as yup from 'yup';

import { FormHelper } from 'helpers/app/FormHelper';
import { DemandeRfErrors } from 'models/types/cms/demandeRF/type';
import { HONEYPOT_NAME } from 'constants/formulaire';

export const getFamilyValidationSchema = (errorsRfCms: DemandeRfErrors) => {
  const formHelper = new FormHelper(errorsRfCms);
  return yup.object({
    lettre_explication_rf_files: formHelper.notRequiredFileSchema(),
    [HONEYPOT_NAME]: yup.string(),
  });
};
