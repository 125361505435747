/* eslint-disable max-lines */
import React from 'react';

import CustomAlert from 'components/Atoms/CustomAlert/CustomAlert';
import CustomCheckboxContainer from 'components/Molecules/Forms/CustomCheckboxContainer/CustomCheckboxContainer';
import CommonRowContainer from 'components/Molecules/Forms/CommonRowContainer/CommonRowContainer';
import DateInputContainer from 'components/Molecules/Forms/DateInputContainer/DateInputContainer';
import RadioButtonContainer from 'components/Molecules/Forms/RadioButtonContainer/RadioButtonContainer';
import UploadFiles from 'components/Atoms/UploadFiles/UploadFiles';
import { getRfCmsContents } from 'pages/DemandeRF/Helpers/Cms/getRfCmsContents';

import { WhoAreYouMapping } from '../WhoAreYou.mapping';

type MatrimonialSituationProps = {
  WatcherMatrimonialSituation: string | undefined | null;
  lang: string;
  fieldArrayMapping: any;
  uploadFilesParameters: any;
  formControls: {
    setValue: any;
    control: any;
  };
  dispatch: React.Dispatch<any>;
  watch: any;
};

const MatrimonialSituation: React.FC<MatrimonialSituationProps> = ({
  WatcherMatrimonialSituation,
  lang,
  fieldArrayMapping,
  uploadFilesParameters,
  formControls: { setValue, control },
  dispatch,
  watch,
}) => {
  if (!WatcherMatrimonialSituation) {
    return null;
  }

  const watchMariagePlaceValue = watch('place_of_mariage');
  const watchFrenchDeathCertificate = watch('french_death_certificate');
  const watchFrenchMariageCertificate = watch('french_mariage_certificate');
  const watchFrenchDivorcePapers = watch('french_divorce_papers');
  const { bloc1Cms, globalCms, errorCms } = getRfCmsContents(lang);

  return (
    <>
      {
        {
          marie: (
            <>
              <div className="fr-col-12 fr-mt-4v fr-mt-md-8v fr-mt-lg-8v">
                <CustomAlert
                  {...WhoAreYouMapping.getGeneralMapping().alerts_fields(bloc1Cms).alert_maried}
                />
              </div>
              <CommonRowContainer
                children={[
                  <DateInputContainer
                    {...WhoAreYouMapping.getGeneralMapping().form_fields(bloc1Cms, control)
                      .date_start_of_mariage}
                  />,
                ]}
              />
              <CommonRowContainer
                children={[
                  <RadioButtonContainer
                    {...WhoAreYouMapping.getGeneralMapping().radio_place_of_mariage(
                      bloc1Cms,
                      globalCms,
                      { setValue, control },
                      dispatch,
                    )}
                  />,
                ]}
              />
              {watchMariagePlaceValue === 'etranger' ? (
                <>
                  <CommonRowContainer
                    className="fr-grid-row fr-col-12 fr-mt-6v fr-mt-lg-8v fr-mt-md-8v"
                    children={[
                      <UploadFiles
                        {...WhoAreYouMapping.getFileMapping().acte_mariage_files(
                          bloc1Cms,
                          uploadFilesParameters,
                          errorCms,
                          lang,
                        ).upload}
                        fieldArray={fieldArrayMapping.acte_mariage_files}
                        //fieldHasError={getFieldState('acte_mariage_files').error}
                      />,
                    ]}
                  />

                  <CustomCheckboxContainer
                    {...WhoAreYouMapping.getGeneralMapping().checkbox_mapping(
                      bloc1Cms,
                      globalCms,
                      {
                        control,
                        setValue,
                      },
                      dispatch,
                    ).french_mariage_certificate}
                  />
                  {watchFrenchMariageCertificate && (
                    <>
                      <CommonRowContainer
                        className="fr-grid-row fr-col-12 fr-mt-6v fr-mt-lg-8v fr-mt-md-8v"
                        children={[
                          <UploadFiles
                            {...WhoAreYouMapping.getFileMapping().acte_mariage_traduits_files(
                              bloc1Cms,
                              uploadFilesParameters,
                              errorCms,
                              lang,
                            ).upload}
                            fieldArray={fieldArrayMapping.acte_mariage_traduits_files}
                            //fieldHasError={getFieldState('acte_mariage_traduits_files').error}
                          />,
                        ]}
                      />
                    </>
                  )}
                </>
              ) : (
                <CommonRowContainer
                  className="fr-grid-row fr-col-12 fr-mt-6v fr-mt-lg-8v fr-mt-md-8v"
                  children={[
                    <UploadFiles
                      {...WhoAreYouMapping.getFileMapping().acte_mariage_files(
                        bloc1Cms,
                        uploadFilesParameters,
                        errorCms,
                        lang,
                      ).upload}
                      fieldArray={fieldArrayMapping.acte_mariage_files}
                    />,
                  ]}
                />
              )}
            </>
          ),
          concubin: (
            <>
              <CommonRowContainer
                children={[
                  <DateInputContainer
                    {...WhoAreYouMapping.getGeneralMapping().form_fields(bloc1Cms, control)
                      .date_start_of_concubinage}
                  />,
                ]}
              />
            </>
          ),
          pacse: (
            <>
              <CommonRowContainer
                children={[
                  <DateInputContainer
                    {...WhoAreYouMapping.getGeneralMapping().form_fields(bloc1Cms, control)
                      .date_start_of_pacs}
                  />,
                ]}
              />

              <CommonRowContainer
                className="fr-grid-row fr-col-12 fr-mt-6v fr-mt-lg-8v fr-mt-md-8v"
                children={[
                  <UploadFiles
                    {...WhoAreYouMapping.getFileMapping().convention_pacs_files(
                      bloc1Cms,
                      uploadFilesParameters,
                      errorCms,
                      lang,
                    ).upload}
                    fieldArray={fieldArrayMapping.convention_pacs_files}
                    //fieldHasError={getFieldState('convention_pacs_files').error}
                  />,
                ]}
              />
            </>
          ),
          veuf: (
            <>
              <CommonRowContainer
                className="fr-grid-row fr-col-12 fr-mt-6v fr-mt-lg-8v fr-mt-md-8v"
                children={[
                  <UploadFiles
                    {...WhoAreYouMapping.getFileMapping().acte_deces_conjoint_files(
                      bloc1Cms,
                      uploadFilesParameters,
                      errorCms,
                      lang,
                    ).upload}
                    fieldArray={fieldArrayMapping.acte_deces_conjoint_files}
                    //fieldHasError={getFieldState('acte_deces_conjoint_files').error}
                  />,
                ]}
              />
              <CustomCheckboxContainer
                {...WhoAreYouMapping.getGeneralMapping().checkbox_mapping(
                  bloc1Cms,
                  globalCms,
                  {
                    control,
                    setValue,
                  },
                  dispatch,
                ).french_death_certificate}
              />
              {watchFrenchDeathCertificate && (
                <>
                  <CommonRowContainer
                    className="fr-grid-row fr-col-12 fr-mt-6v fr-mt-lg-8v fr-mt-md-8v"
                    children={[
                      <UploadFiles
                        {...WhoAreYouMapping.getFileMapping().acte_deces_conjoint_traduits_files(
                          bloc1Cms,
                          uploadFilesParameters,
                          errorCms,
                          lang,
                        ).upload}
                        fieldArray={fieldArrayMapping.acte_deces_traduits_files}
                        //fieldHasError={getFieldState('divorce_ruling_traduit_files').error}
                      />,
                    ]}
                  />
                </>
              )}
            </>
          ),
          divorce: (
            <>
              <CommonRowContainer
                children={[
                  <DateInputContainer
                    {...WhoAreYouMapping.getGeneralMapping().form_fields(bloc1Cms, control)
                      .date_start_of_divorce}
                  />,
                ]}
              />
              <CommonRowContainer
                className="fr-grid-row fr-col-12 fr-mt-6v fr-mt-lg-8v fr-mt-md-8v"
                children={[
                  <UploadFiles
                    {...WhoAreYouMapping.getFileMapping().divorce_ruling_files(
                      bloc1Cms,
                      uploadFilesParameters,
                      errorCms,
                      lang,
                    ).upload}
                    fieldArray={fieldArrayMapping.divorce_ruling_files}
                    //fieldHasError={getFieldState('divorce_ruling_files').error}
                  />,
                ]}
              />

              <CustomCheckboxContainer
                {...WhoAreYouMapping.getGeneralMapping().checkbox_mapping(
                  bloc1Cms,
                  globalCms,
                  {
                    control,
                    setValue,
                  },
                  dispatch,
                ).french_divorce_papers}
              />
              {watchFrenchDivorcePapers && (
                <>
                  <CommonRowContainer
                    className="fr-grid-row fr-col-12 fr-mt-6v fr-mt-lg-8v fr-mt-md-8v"
                    children={[
                      <UploadFiles
                        {...WhoAreYouMapping.getFileMapping().divorce_ruling_traduit_files(
                          bloc1Cms,
                          uploadFilesParameters,
                          errorCms,
                          lang,
                        ).upload}
                        fieldArray={fieldArrayMapping.divorce_ruling_traduit_files}
                        //fieldHasError={getFieldState('divorce_ruling_traduit_files').error}
                      />,
                    ]}
                  />
                </>
              )}
            </>
          ),
        }[WatcherMatrimonialSituation]
      }
    </>
  );
};

export default MatrimonialSituation;
