import React from 'react';

import CommonRowContainer from 'components/Molecules/Forms/CommonRowContainer/CommonRowContainer';
import RadioButtonContainer from 'components/Molecules/Forms/RadioButtonContainer/RadioButtonContainer';
import TextInputContainer from 'components/Molecules/Forms/TextInputContainer/TextInputContainer';
import { getRfCmsContents } from 'pages/DemandeRF/Helpers/Cms/getRfCmsContents';

type EnfantInfoNomsProps = {
  lang: string;
  formFieldsMapping: any;
};
const EnfantInfoNoms: React.FC<EnfantInfoNomsProps> = ({ lang, formFieldsMapping }) => {
  const { bloc2Cms } = getRfCmsContents(lang);
  return (
    <>
      <CommonRowContainer
        legend={bloc2Cms.genre_enfant}
        children={[<RadioButtonContainer {...formFieldsMapping.sexe_enfant} large />]}
      />
      <CommonRowContainer
        legend={bloc2Cms.enfant_sappel}
        lang={lang}
        children={[
          <TextInputContainer
            customClass="fr-col-12 fr-mt-4v fr-mt-lg-0v fr-mt-md-0v fr-col-sm-10"
            {...formFieldsMapping.nom_naissance_enfant}
          />,
          <TextInputContainer
            customClass="fr-col-12 fr-mt-4v fr-mt-lg-0v fr-mt-md-0v fr-col-sm-10"
            {...formFieldsMapping.nom_usage_enfant}
          />,
        ]}
      />
      <CommonRowContainer
        lang={lang}
        children={[
          <TextInputContainer
            customClass="fr-col-12 fr-mt-4v fr-mt-lg-0v fr-mt-md-0v fr-col-sm-10"
            {...formFieldsMapping.prenoms_enfant}
          />,
        ]}
      />
    </>
  );
};

export default EnfantInfoNoms;
