import qs from 'qs';

import { InfobulleQuery } from 'pages/DemandeRF/Queries/common.query';

export const creationCompteQuery = qs.stringify({
  populate: {
    infobulle_bulle_aide: InfobulleQuery,
    infobulle_nationalite: InfobulleQuery,
    infobulle_pays_naissance: InfobulleQuery,
    infobulle_prenom: InfobulleQuery,
    infobulle_nom: InfobulleQuery,
    infobulle_date_naissance: InfobulleQuery,
    infobulle_identifiant_image_tj_avant_2011: InfobulleQuery,
    infobulle_identifiant_image_tj_apres_2020: InfobulleQuery,
    infobulle_identifiant_image_tj_avant_2020: InfobulleQuery,
    infobulle_identifiant_image_visa: InfobulleQuery,
  },
});
