import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { getCurrentLocale } from 'redux/locale/selectors';
import { setProcedures } from 'redux/pages';
import { getProcedures } from 'redux/pages/selectors';
import { useGetProceduresCmsCollectionQuery } from 'redux/rtk/cms/cmsCommon';

import Loader from 'components/Atoms/Loader/Loader';
import {
  proceduresQuery,
  proceduresSecondQuery,
  proceduresThirdQuery,
} from 'pages/Home/Home.query';

import CmsPage from '../CmsPage';

const Procedure: FC<any> = ({}) => {
  const lang = useSelector(getCurrentLocale) as string;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const proceduresStore = useSelector(getProcedures);

  const {
    data: proceduresCms1,
    error: proceduresCmsError1,
    isLoading: isProceduresCmsLoading1,
  } = useGetProceduresCmsCollectionQuery({
    qs: proceduresQuery,
    locale: lang !== '' ? lang : 'fr',
  });

  const {
    data: proceduresCms2,
    error: proceduresCmsError2,
    isLoading: isProceduresCmsLoading2,
  } = useGetProceduresCmsCollectionQuery({
    qs: proceduresSecondQuery,
    locale: lang !== '' ? lang : 'fr',
  });

  const {
    data: proceduresCms3,
    error: proceduresCmsError3,
    isLoading: isProceduresCmsLoading3,
  } = useGetProceduresCmsCollectionQuery({
    qs: proceduresThirdQuery,
    locale: lang !== '' ? lang : 'fr',
  });

  useEffect(() => {
    if (
      !proceduresCmsError1 &&
      !proceduresCmsError2 &&
      !proceduresCmsError3 &&
      !isProceduresCmsLoading1 &&
      !isProceduresCmsLoading2 &&
      !isProceduresCmsLoading3
    ) {
      const proceduresCms = { data: [] } as any;

      // eslint-disable-next-line
      proceduresCms1.data.forEach((_: any, index: number) => {
        proceduresCms.data[index] = {
          attributes: {
            ...proceduresCms1.data[index].attributes,
            image_principale: proceduresCms1.data[index].attributes.image_principale,
            //contenu: proceduresCms2.data[index].attributes.contenu,
            contenu: mergeDataFromContenu(
              proceduresCms2.data[index].attributes.contenu,
              proceduresCms3.data[index].attributes.contenu,
            ),
          },
          id: proceduresCms1.data[index].id,
        };
      });
      dispatch(setProcedures(proceduresCms));
    }
  }, [
    proceduresCmsError1,
    proceduresCmsError2,
    proceduresCmsError3,
    isProceduresCmsLoading1,
    isProceduresCmsLoading2,
    isProceduresCmsLoading3,
  ]);

  const mergeDataFromContenu = (contenu2: any[], contenu3: any[]) => {
    const contenu: any[] = [];
    contenu2.forEach((el, index) => {
      contenu[index] = el;
      if (el.__component === 'widget-page-contenu.groupe-accordeon') {
        contenu[index] = contenu3[index];
      }
    });
    return contenu;
  };

  if (isProceduresCmsLoading1 || isProceduresCmsLoading2) {
    return <Loader />;
  }

  if (proceduresCmsError1 || proceduresCmsError2) {
    navigate('/erreur');
  }

  return <CmsPage type="procedure" store={proceduresStore} lang={lang} />;
};
//dispatch(setProcedures(cms));
export default Procedure;
