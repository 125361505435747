/* eslint-disable max-lines */
/* eslint-disable max-params */

import { AccomodationsValue } from 'models/types/app/mappings';
import { FieldArrayList } from 'models/types/app/uploadFiles';
import {
  DemandeRfBloc3,
  DemandeRfGeneral,
  DemandeRfUtilitaire,
} from 'models/types/cms/demandeRF/type';
import { AccomodationDefaultValues } from './Accomodation.defaultValues';

export class AccomodationMapping {
  static getGeneralMapping() {
    function alerts_fields() {
      //warningBox_logement
      return {};
    }

    function current_form_fields(
      bloc3Cms: DemandeRfBloc3,
      demandeUtilitaire: DemandeRfUtilitaire,
      globalCms: DemandeRfGeneral,
      adresse_not_found: boolean,
      logementPrincipal: any,
      control: any,
    ): AccomodationsValue {
      return {
        nom_hebergeur: {
          name: 'nom_hebergeur',
          label: bloc3Cms.nom_hebergeur,
          control,
          required: true,
        },
        adresse_postale: {
          name: 'adresse_postale',
          label: demandeUtilitaire.adresse_postale.titre,
          hintText: demandeUtilitaire.adresse_postale.sous_titre,
          required: adresse_not_found ? false : true,
          control,
        },
        adresse_non_trouvee: {
          name: 'adresse_non_trouvee',
          label: demandeUtilitaire.adresse_non_trouvee,
          control,
        },
        complement_adresse: {
          name: 'complement_adresse',
          label: demandeUtilitaire.complement_adresse.titre,
          hintText: demandeUtilitaire.complement_adresse.sous_titre,
          required: false,
          control,
        },
        numero_de_voie: {
          name: 'numero_de_voie',
          label: demandeUtilitaire.numero_voie,
          required: false,
          control,
        },
        code_postal: {
          name: 'code_postal',
          label: demandeUtilitaire.code_postal,
          required: true,
          control,
        },
        nom_de_rue: {
          name: 'nom_de_rue',
          label: demandeUtilitaire.nom_rue,
          required: true,
          control,
        },
        ville_ou_commune: {
          name: 'ville_ou_commune',
          label: demandeUtilitaire.ville_commune,
          required: true,
          control,
        },
        numero_digicode: {
          name: 'numero_digicode',
          label: demandeUtilitaire.numero_digicode,
          hintText: demandeUtilitaire.champs_facultatif,
          required: false,
          control,
        },
        nom_interphone: {
          name: 'nom_interphone',
          label: demandeUtilitaire.nom_interphone,
          hintText: demandeUtilitaire.champs_facultatif,
          required: false,
          control,
        },
        situation_logement: {
          name: 'situation_logement',
          label: bloc3Cms.nature_resident,
          defaultValue: logementPrincipal?.situation
            ? logementPrincipal.situation
            : globalCms.choisir_option_defaut,
          options: [
            {
              label: demandeUtilitaire.locataire,
              value: 'locataire',
            },
            {
              label: demandeUtilitaire.sous_locataire,
              value: 'sous_locataire',
            },
            {
              label: demandeUtilitaire.heberge_gratuitement,
              value: 'gratuit',
            },
            {
              label: demandeUtilitaire.proprietaire,
              value: 'proprietaire',
            },
          ],
          required: false,
          control,
        },
        type_hebergeur: {
          name: 'type_hebergeur',
          label: bloc3Cms.titre_selecteur_hebergeur,
          defaultValue: globalCms.choisir_option_defaut,
          options: [
            {
              label: demandeUtilitaire.un_particulier,
              value: 'particulier',
            },
            {
              label: demandeUtilitaire.votre_employeur,
              value: 'employeur',
            },
            {
              label: demandeUtilitaire.autre,
              value: 'autre',
            },
          ],
          control,
        },
      };
    }

    function get_files_fields(bloc3Cms: DemandeRfBloc3, fieldArrayMapping: FieldArrayList) {
      return {
        current: {
          bail_complet_files: {
            upload: {
              name: 'bail_complet_files',
              categorie: 'loge_bail_complet',
              fieldArray: fieldArrayMapping.bail_complet_files,
              label: bloc3Cms.fichiers_bail_complet.titre_fichier ?? '',
              hint: bloc3Cms.fichiers_bail_complet.infos_fichier ?? '',
              helpbox: {
                title: bloc3Cms.fichiers_bail_complet.bulle_aide_fichier_titre,
                description: bloc3Cms.fichiers_bail_complet.bulle_aide_fichier_contenu,
                closable: false,
                severity: 'info',
              },
              state: 'default',
              stateRelatedMessage: "Text de validation / d'explication de l'erreur",
              // required: true,

              resource: 'demande',
              resourceId: undefined,
              defaultValue: AccomodationDefaultValues.bail_complet_files,
              infobulleContent: bloc3Cms.infobulle_fichier_bail_complet,
            },
          },
          quittance_loyer_files: {
            upload: {
              name: 'quittance_loyer_files',
              categorie: 'loge_quittance_loyer',
              fieldArray: fieldArrayMapping.quittance_loyer_files,
              label: bloc3Cms.fichiers_derniere_quittance_loyer.titre_fichier ?? '',
              hint: bloc3Cms.fichiers_derniere_quittance_loyer.infos_fichier ?? '',
              helpbox: {
                title: bloc3Cms.fichiers_derniere_quittance_loyer.bulle_aide_fichier_titre ?? '',
                description:
                  bloc3Cms.fichiers_derniere_quittance_loyer.bulle_aide_fichier_contenu ?? '',
                closable: false,
                severity: 'info',
              },
              state: 'default',
              stateRelatedMessage: "Text de validation / d'explication de l'erreur",
              // required: true,

              resource: 'demande',
              resourceId: undefined,
              defaultValue: AccomodationDefaultValues.quittance_loyer_files,
              infobulleContent: bloc3Cms.infobulle_fichier_derniere_quittante_loyer,
            },
          },
          attestation_domicile_employeur_files: {
            upload: {
              name: 'attestation_domicile_employeur_files',
              categorie: 'loge_attestation_domicile_employeur',
              fieldArray: fieldArrayMapping.attestation_domicile_employeur_files,
              label: bloc3Cms.fichiers_attestation_domicile_employeur.titre_fichier ?? '',
              hint: bloc3Cms.fichiers_attestation_domicile_employeur.infos_fichier ?? '',
              helpbox: {
                title:
                  bloc3Cms.fichiers_attestation_domicile_employeur.bulle_aide_fichier_titre ?? '',
                description:
                  bloc3Cms.fichiers_attestation_domicile_employeur.bulle_aide_fichier_contenu ?? '',
                closable: false,
                severity: 'info',
              },
              state: 'default',
              stateRelatedMessage: "Text de validation / d'explication de l'erreur",
              // required: true,

              resource: 'demande',
              resourceId: undefined,
              infobulleContent: bloc3Cms.infobulle_fichier_attestation_employer_accord_logement,
            },
          },
          attestation_mise_disposition_logement_files: {
            upload: {
              name: 'attestation_mise_disposition_location_files',
              categorie: 'loge_attestation_mise_disposition_logement',
              fieldArray: fieldArrayMapping.attestation_mise_disposition_logement_files,
              label: bloc3Cms.fichiers_attestation_mise_a_disposition_logement.titre_fichier ?? '',
              hint: bloc3Cms.fichiers_attestation_mise_a_disposition_logement.infos_fichier ?? '',
              helpbox: {
                title:
                  bloc3Cms.fichiers_attestation_mise_a_disposition_logement
                    .bulle_aide_fichier_titre ?? '',
                description:
                  bloc3Cms.fichiers_attestation_mise_a_disposition_logement
                    .bulle_aide_fichier_contenu ?? '',
                closable: false,
                severity: 'info',
              },
              state: 'default',
              stateRelatedMessage: "Text de validation / d'explication de l'erreur",
              // required: true,

              resource: 'demande',
              resourceId: undefined,
              infobulleContent: bloc3Cms.infobulle_fichier_attestation_mise_a_dispo_logement,
            },
          },
          bail_quittance_hebergeant_files: {
            upload: {
              name: 'bail_quittance_hebergeant_files',
              categorie: 'loge_bail_quittance_hebergeant',
              fieldArray: fieldArrayMapping.bail_quittance_hebergeant_files,
              label: bloc3Cms.fichiers_bail_nom_hebergeant.titre_fichier ?? '',
              hint: bloc3Cms.fichiers_bail_nom_hebergeant.infos_fichier ?? '',
              helpbox: {
                title: bloc3Cms.fichiers_bail_nom_hebergeant.bulle_aide_fichier_titre ?? '',
                description: bloc3Cms.fichiers_bail_nom_hebergeant.bulle_aide_fichier_contenu ?? '',
                closable: false,
                severity: 'info',
              },
              state: 'default',
              stateRelatedMessage: "Text de validation / d'explication de l'erreur",
              // required: true,

              resource: 'demande',
              resourceId: undefined,
              infobulleContent: bloc3Cms.infobulle_fichier_bail_complet,
            },
          },
          engagement_sous_location_files: {
            upload: {
              name: 'engagement_sous_location_files',
              categorie: 'loge_engagement_sous_location',
              fieldArray: fieldArrayMapping.engagement_sous_location_files,
              label: bloc3Cms.fichiers_engagement_sous_location.titre_fichier ?? '',
              hint: bloc3Cms.fichiers_engagement_sous_location.infos_fichier ?? '',
              helpbox: {
                title: bloc3Cms.fichiers_engagement_sous_location.bulle_aide_fichier_titre ?? '',
                description:
                  bloc3Cms.fichiers_engagement_sous_location.bulle_aide_fichier_contenu ?? '',
                closable: false,
                severity: 'info',
              },
              state: 'default',
              stateRelatedMessage: "Text de validation / d'explication de l'erreur",
              // required: true,

              resource: 'demande',
              resourceId: undefined,
              infobulleContent: bloc3Cms.infobulle_fichier_engagement_sous_location,
            },
          },
          justificatif_domicile_files: {
            upload: {
              name: 'justificatif_domicile_files',
              categorie: 'loge_justificatif_domicile',
              fieldArray: fieldArrayMapping.justificatif_domicile_files,
              label: bloc3Cms.fichiers_justificatif_domicile_moins_trois_mois.titre_fichier ?? '',
              hint: bloc3Cms.fichiers_justificatif_domicile_moins_trois_mois.infos_fichier ?? '',
              helpbox: {
                title:
                  bloc3Cms.fichiers_justificatif_domicile_moins_trois_mois
                    .bulle_aide_fichier_titre ?? '',
                description:
                  bloc3Cms.fichiers_justificatif_domicile_moins_trois_mois
                    .bulle_aide_fichier_contenu ?? '',
                closable: false,
                severity: 'info',
              },
              state: 'default',
              stateRelatedMessage: "Text de validation / d'explication de l'erreur",
              // required: true,
              resource: 'demande',
              resourceId: undefined,
              defaultValue: AccomodationDefaultValues.justificatif_domicile_files,
              infobulleContent: bloc3Cms.infobulle_fichier_justif_domicile_moins_trois_mois,
            },
          },
          justificatif_hebergeant_files: {
            upload: {
              name: 'justificatif_hebergeant_files',
              categorie: 'loge_justificatif_hebergeant',
              fieldArray: fieldArrayMapping.justificatif_hebergeant_files,
              label: bloc3Cms.fichiers_justificatif_identite_hebergeant.titre_fichier ?? '',
              hint: bloc3Cms.fichiers_justificatif_identite_hebergeant.infos_fichier ?? '',
              helpbox: {
                title:
                  bloc3Cms.fichiers_justificatif_identite_hebergeant.bulle_aide_fichier_titre ?? '',
                description:
                  bloc3Cms.fichiers_justificatif_identite_hebergeant.bulle_aide_fichier_contenu ??
                  '',
                closable: false,
                severity: 'info',
              },
              state: 'default',
              stateRelatedMessage: "Text de validation / d'explication de l'erreur",
              // required: true,

              resource: 'demande',
              resourceId: undefined,
              infobulleContent: bloc3Cms.infobulle_fichier_justif_identite_hebergeant,
            },
          },
          attestation_domicile_hebergeant_files: {
            upload: {
              name: 'attestation_domicile_hebergeant_files',
              categorie: 'loge_attestation_domicile_hebergeant',
              fieldArray: fieldArrayMapping.attestation_domicile_hebergeant_files,
              label: bloc3Cms.fichiers_attestation_domicile_hebergeant.titre_fichier ?? '',
              hint: bloc3Cms.fichiers_attestation_domicile_hebergeant.infos_fichier ?? '',
              helpbox: {
                title:
                  bloc3Cms.fichiers_attestation_domicile_hebergeant.bulle_aide_fichier_titre ?? '',
                description:
                  bloc3Cms.fichiers_attestation_domicile_hebergeant.bulle_aide_fichier_contenu ??
                  '',
                closable: false,
                severity: 'info',
              },
              state: 'default',
              stateRelatedMessage: "Text de validation / d'explication de l'erreur",
              // required: true,

              resource: 'demande',
              resourceId: undefined,
              infobulleContent: bloc3Cms.infobulle_fichier_attestation_domicile_par_hebergeant,
            },
          },
          attestation_hebergement_employeur_files: {
            upload: {
              name: 'attestation_hebergement_employeur_files',
              categorie: 'loge_attestation_domicile_employeur',
              fieldArray: fieldArrayMapping.attestation_hebergement_employeur_files,
              label: bloc3Cms.fichiers_attestation_hebergement_employeur.titre_fichier ?? '',
              hint: bloc3Cms.fichiers_attestation_hebergement_employeur.infos_fichier ?? '',
              helpbox: {
                title:
                  bloc3Cms.fichiers_attestation_hebergement_employeur.bulle_aide_fichier_titre ??
                  '',
                description:
                  bloc3Cms.fichiers_attestation_hebergement_employeur.bulle_aide_fichier_contenu ??
                  '',
                closable: false,
                severity: 'info',
              },
              state: 'default',
              stateRelatedMessage: "Text de validation / d'explication de l'erreur",
              // required: true,

              resource: 'demande',
              resourceId: undefined,
              infobulleContent: bloc3Cms.infobulle_fichier_attestation_employer_accord_logement,
            },
          },
          acte_propriete_files: {
            upload: {
              name: 'acte_propriete_files',
              categorie: 'loge_acte_propriete',
              fieldArray: fieldArrayMapping.acte_propriete_files,
              label: bloc3Cms.fichiers_acte_propriete.titre_fichier ?? '',
              hint: bloc3Cms.fichiers_acte_propriete.infos_fichier ?? '',
              helpbox: {
                title: bloc3Cms.fichiers_acte_propriete.bulle_aide_fichier_titre ?? '',
                description: bloc3Cms.fichiers_acte_propriete.bulle_aide_fichier_contenu ?? '',
                closable: false,
                severity: 'info',
              },
              state: 'default',
              stateRelatedMessage: "Text de validation / d'explication de l'erreur",
              // required: true,

              resource: 'demande',
              resourceId: undefined,
              infobulleContent: bloc3Cms.infobulle_fichier_acte_propriete,
            },
          },
        },
        autre: {
          // bail_complet_autre_files: {
          //   upload: {
          //     name: 'futur_bail_complet_files',
          //     categorie: 'loge_bail_complet',
          //     fieldArray: fieldArrayMapping.bail_complet_autre_files,
          //     label: bloc3Cms.fichiers_bail_complet_hebergeant.titre_fichier ?? '',
          //     hint: bloc3Cms.fichiers_bail_complet_hebergeant.infos_fichier ?? '',
          //     helpbox: {
          //       title: bloc3Cms.fichiers_bail_complet_hebergeant.bulle_aide_fichier_titre ?? '',
          //       description:
          //         bloc3Cms.fichiers_bail_complet_hebergeant.bulle_aide_fichier_contenu ?? '',
          //       closable: false,
          //       severity: 'info',
          //     },
          //     state: 'default',
          //     stateRelatedMessage: "Text de validation / d'explication de l'erreur",
          //     // required: true,

          //     resource: 'demande',
          //     resourceId: undefined,
          //   },
          // },
          // quittance_loyer_autre_files: {
          //   upload: {
          //     name: '',
          //     categorie: '',
          //     fieldArray: fieldArrayMapping,
          //     label: bloc3Cms, //. .titre_fichier ?? '',
          //     hint: bloc3Cms, //. .infos_fichier ?? '',
          //     helpbox: {
          //       title: bloc3Cms, //. .bulle_aide_fichier_titre ?? '',
          //       description: bloc3Cms, //. .bulle_aide_fichier_contenu ?? '',
          //       closable: false,
          //       severity: 'info',
          //     },
          //     state: 'default',
          //     stateRelatedMessage: "Text de validation / d'explication de l'erreur",
          //     // required: true,
          //
          //     resource: 'demande',
          //     resourceId: undefined,
          //   },
          // },
          justificatif_acquisition_futur_logement_files: {
            upload: {
              name: 'justificatif_acquisition_futur_logement_autre_files',
              categorie: 'loge_justificatif_acquisition_futur_logement',
              fieldArray: fieldArrayMapping.justificatif_acquisition_futur_logement_autre_files,
              label: bloc3Cms.fichiers_justificatif_acquisition_futur_logement.titre_fichier ?? '',
              hint: bloc3Cms.fichiers_justificatif_acquisition_futur_logement.infos_fichier ?? '',
              helpbox: {
                title:
                  bloc3Cms.fichiers_justificatif_acquisition_futur_logement
                    .bulle_aide_fichier_titre ?? '',
                description:
                  bloc3Cms.fichiers_justificatif_acquisition_futur_logement
                    .bulle_aide_fichier_contenu ?? '',
                closable: false,
                severity: 'info',
              },
              state: 'default',
              stateRelatedMessage: "Text de validation / d'explication de l'erreur",
              // required: true,

              resource: 'demande',
              resourceId: undefined,
              infobulleContent: bloc3Cms.infobulle_fichier_justificatif_acquisition_logement_futur,
            },
          },
          promesse_location_files: {
            upload: {
              name: 'promesse_location_autre_files',
              categorie: 'loge_promesse_location',
              fieldArray: fieldArrayMapping.promesse_location_autre_files,
              label: bloc3Cms.fichiers_promesse_location.titre_fichier ?? '',
              hint: bloc3Cms.fichiers_promesse_location.infos_fichier ?? '',
              helpbox: {
                title: bloc3Cms.fichiers_promesse_location.bulle_aide_fichier_titre ?? '',
                description: bloc3Cms.fichiers_promesse_location.bulle_aide_fichier_contenu ?? '',
                closable: false,
                severity: 'info',
              },
              state: 'default',
              stateRelatedMessage: "Text de validation / d'explication de l'erreur",
              // required: true,
              resource: 'demande',
              resourceId: undefined,
              infobulleContent: bloc3Cms.infobulle_fichier_promesse_location,
            },
          },
          // attestation_domicile_employeur_autre_files: {
          //   upload: {
          //     name: 'attestation_domicile_employeur_autre_files',
          //     categorie: 'loge_attestation_domicile_employeur',
          //     fieldArray: fieldArrayMapping.attestation_domicile_employeur_autre_files,
          //     label: bloc3Cms.fichiers_attestation_domicile_employeur.titre_fichier ?? '',
          //     hint: bloc3Cms.fichiers_attestation_domicile_employeur.infos_fichier ?? '',
          //     helpbox: {
          //       title:
          //         bloc3Cms.fichiers_attestation_domicile_employeur.bulle_aide_fichier_titre ?? '',
          //       description:
          //         bloc3Cms.fichiers_attestation_domicile_employeur.bulle_aide_fichier_contenu ?? '',
          //       closable: false,
          //       severity: 'info',
          //     },
          //     state: 'default',
          //     stateRelatedMessage: "Text de validation / d'explication de l'erreur",
          //     // required: true,

          //     resource: 'demande',
          //     resourceId: undefined,
          //   },
          // },
          attestation_mise_disposition_logement_autre_files: {
            upload: {
              name: 'attestation_mise_disposition_logement_autre_files',
              categorie: 'loge_attestation_mise_disposition_logement',
              fieldArray: fieldArrayMapping.attestation_mise_disposition_logement_autre_files,
              label: bloc3Cms.fichiers_attestation_mise_a_disposition_logement.titre_fichier ?? '',
              hint: bloc3Cms.fichiers_attestation_mise_a_disposition_logement.infos_fichier ?? '',
              helpbox: {
                title:
                  bloc3Cms.fichiers_attestation_mise_a_disposition_logement
                    .bulle_aide_fichier_titre ?? '',
                description:
                  bloc3Cms.fichiers_attestation_mise_a_disposition_logement
                    .bulle_aide_fichier_contenu ?? '',
                closable: false,
                severity: 'info',
              },
              state: 'default',
              stateRelatedMessage: "Text de validation / d'explication de l'erreur",
              // required: true,

              resource: 'demande',
              resourceId: undefined,
              infobulleContent: bloc3Cms.infobulle_fichier_attestation_mise_a_dispo_logement,
            },
          },
          bail_quittance_hebergeant_autre_files: {
            upload: {
              name: 'bail_quittance_hebergeant_autre_files',
              categorie: 'loge_bail_quittance_hebergeant',
              fieldArray: fieldArrayMapping.bail_quittance_hebergeant_autre_files,
              label: bloc3Cms.fichiers_bail_nom_hebergeant.titre_fichier ?? '',
              hint: bloc3Cms.fichiers_bail_nom_hebergeant.infos_fichier ?? '',
              helpbox: {
                title: bloc3Cms.fichiers_bail_nom_hebergeant.bulle_aide_fichier_titre ?? '',
                description: bloc3Cms.fichiers_bail_nom_hebergeant.bulle_aide_fichier_contenu ?? '',
                closable: false,
                severity: 'info',
              },
              state: 'default',
              stateRelatedMessage: "Text de validation / d'explication de l'erreur",
              // required: true,

              resource: 'demande',
              resourceId: undefined,
              infobulleContent: bloc3Cms.infobulle_fichier_bail_complet,
            },
          },
          engagement_sous_location_autre_files: {
            upload: {
              name: 'engagement_sous_location_autre_files',
              categorie: 'loge_engagement_sous_location',
              fieldArray: fieldArrayMapping.engagement_sous_location_autre_files,
              label: bloc3Cms.fichiers_engagement_sous_location.titre_fichier ?? '',
              hint: bloc3Cms.fichiers_engagement_sous_location.infos_fichier ?? '',
              helpbox: {
                title: bloc3Cms.fichiers_engagement_sous_location.bulle_aide_fichier_titre ?? '',
                description:
                  bloc3Cms.fichiers_engagement_sous_location.bulle_aide_fichier_contenu ?? '',
                closable: false,
                severity: 'info',
              },
              state: 'default',
              stateRelatedMessage: "Text de validation / d'explication de l'erreur",
              // required: true,

              resource: 'demande',
              resourceId: undefined,
              infobulleContent: bloc3Cms.infobulle_fichier_engagement_sous_location,
            },
          },
          // justificatif_acquisition_futur_logement_autre_files: {
          //   upload: {
          //     name: 'justificatif_acquisition_futur_logement_autre_files',
          //     categorie: 'loge_justificatif_acquisition_futur_logement',
          //     fieldArray: fieldArrayMapping.justificatif_acquisition_futur_logement_autre_files,
          //     label: bloc3Cms.fichiers_justificatif_acquisition_futur_logement.titre_fichier ?? '',
          //     hint: bloc3Cms.fichiers_justificatif_acquisition_futur_logement.infos_fichier ?? '',
          //     helpbox: {
          //       title:
          //         bloc3Cms.fichiers_justificatif_acquisition_futur_logement
          //           .bulle_aide_fichier_titre ?? '',
          //       description:
          //         bloc3Cms.fichiers_justificatif_acquisition_futur_logement
          //           .bulle_aide_fichier_contenu ?? '',
          //       closable: false,
          //       severity: 'info',
          //     },
          //     state: 'default',
          //     stateRelatedMessage: "Text de validation / d'explication de l'erreur",
          //     // required: true,

          //     resource: 'demande',
          //     resourceId: undefined,
          //     infobulleContent: bloc3Cms.infobulle_fichier_justificatif_acquisition_logement_futur,
          //   },
          // },
          // justificatif_domicile_autre_files: {
          //   upload: {
          //     name: 'justificatif_domicile_autre_files',
          //     categorie: 'loge_justificatif_acquisition_futur_logement',
          //     fieldArray: fieldArrayMapping.justificatif_domicile_autre_files,
          //     label: bloc3Cms.fichiers_justificatif_domicile_moins_trois_mois.titre_fichier ?? '',
          //     hint: bloc3Cms.fichiers_justificatif_domicile_moins_trois_mois.infos_fichier ?? '',
          //     helpbox: {
          //       title:
          //         bloc3Cms.fichiers_justificatif_domicile_moins_trois_mois
          //           .bulle_aide_fichier_titre ?? '',
          //       description:
          //         bloc3Cms.fichiers_justificatif_domicile_moins_trois_mois
          //           .bulle_aide_fichier_contenu ?? '',
          //       closable: false,
          //       severity: 'info',
          //     },
          //     state: 'default',
          //     stateRelatedMessage: "Text de validation / d'explication de l'erreur",
          //     // required: true,

          //     resource: 'demande',
          //     resourceId: undefined,
          //   },
          // },
          justificatif_hebergeant_autre_files: {
            upload: {
              name: 'justificatif_hebergeant_autre_files',
              categorie: 'loge_justificatif_hebergeant',
              fieldArray: fieldArrayMapping.justificatif_hebergeant_autre_files,
              label: bloc3Cms.fichiers_justificatif_identite_hebergeant.titre_fichier ?? '',
              hint: bloc3Cms.fichiers_justificatif_identite_hebergeant.infos_fichier ?? '',
              helpbox: {
                title:
                  bloc3Cms.fichiers_justificatif_identite_hebergeant.bulle_aide_fichier_titre ?? '',
                description:
                  bloc3Cms.fichiers_justificatif_identite_hebergeant.bulle_aide_fichier_contenu ??
                  '',
                closable: false,
                severity: 'info',
              },
              state: 'default',
              stateRelatedMessage: "Text de validation / d'explication de l'erreur",
              // required: true,

              resource: 'demande',
              resourceId: undefined,
              infobulleContent: bloc3Cms.infobulle_fichier_justif_identite_hebergeant,
            },
          },
          promesse_location_autre_files: {
            upload: {
              name: 'promesse_location_autre_files',
              categorie: 'loge_promesse_location',
              fieldArray: fieldArrayMapping.promesse_location_autre_files,
              label: bloc3Cms.fichiers_promesse_location.titre_fichier ?? '',
              hint: bloc3Cms.fichiers_promesse_location.infos_fichier ?? '',
              helpbox: {
                title: bloc3Cms.fichiers_promesse_location.bulle_aide_fichier_titre ?? '',
                description: bloc3Cms.fichiers_promesse_location.bulle_aide_fichier_contenu ?? '',
                closable: false,
                severity: 'info',
              },
              state: 'default',
              stateRelatedMessage: "Text de validation / d'explication de l'erreur",
              // required: true,

              resource: 'demande',
              resourceId: undefined,
              infobulleContent: bloc3Cms.infobulle_fichier_promesse_location,
            },
          },
          attestation_domicile_hebergeant_autre_files: {
            upload: {
              name: 'attestation_domicile_hebergeant_autre_files',
              categorie: 'loge_attestation_domicile_hebergeant',
              fieldArray: fieldArrayMapping.attestation_domicile_hebergeant_autre_files,
              label: bloc3Cms.fichiers_attestation_domicile_hebergeant.titre_fichier ?? '',
              hint: bloc3Cms.fichiers_attestation_domicile_hebergeant.infos_fichier ?? '',
              helpbox: {
                title:
                  bloc3Cms.fichiers_attestation_domicile_hebergeant.bulle_aide_fichier_titre ?? '',
                description:
                  bloc3Cms.fichiers_attestation_domicile_hebergeant.bulle_aide_fichier_contenu ??
                  '',
                closable: false,
                severity: 'info',
              },
              state: 'default',
              stateRelatedMessage: "Text de validation / d'explication de l'erreur",
              // required: true,

              resource: 'demande',
              resourceId: undefined,
              infobulleContent: bloc3Cms.infobulle_fichier_attestation_domicile_par_hebergeant,
            },
          },
          attestation_hebergement_employeur_autre_files: {
            upload: {
              name: 'attestation_hebergement_employeur_autre_files',
              categorie: 'loge_attestation_domicile_employeur',
              fieldArray: fieldArrayMapping.attestation_hebergement_employeur_autre_files,
              label: bloc3Cms.fichiers_attestation_hebergement_employeur.titre_fichier ?? '',
              hint: bloc3Cms.fichiers_attestation_hebergement_employeur.infos_fichier ?? '',
              helpbox: {
                title:
                  bloc3Cms.fichiers_attestation_hebergement_employeur.bulle_aide_fichier_titre ??
                  '',
                description:
                  bloc3Cms.fichiers_attestation_hebergement_employeur.bulle_aide_fichier_contenu ??
                  '',
                closable: false,
                severity: 'info',
              },
              state: 'default',
              stateRelatedMessage: "Text de validation / d'explication de l'erreur",
              // required: true,

              resource: 'demande',
              resourceId: undefined,
              infobulleContent: bloc3Cms.infobulle_fichier_attestation_employer_accord_logement,
            },
          },
          // acte_propriete_autre_files: {
          //   upload: {
          //     name: 'acte_futur_propriete_files',
          //     categorie: 'loge_acte_propriete',
          //     fieldArray: fieldArrayMapping.acte_propriete_autre_files,
          //     label: bloc3Cms.fichiers_justificatif_identite_hebergeant.titre_fichier ?? '',
          //     hint: bloc3Cms.fichiers_justificatif_identite_hebergeant.infos_fichier ?? '',
          //     helpbox: {
          //       title:
          //         bloc3Cms.fichiers_justificatif_identite_hebergeant.bulle_aide_fichier_titre ?? '',
          //       description:
          //         bloc3Cms.fichiers_justificatif_identite_hebergeant.bulle_aide_fichier_contenu ??
          //         '',
          //       closable: false,
          //       severity: 'info',
          //     },
          //     state: 'default',
          //     stateRelatedMessage: "Text de validation / d'explication de l'erreur",
          //     // required: true,

          //     resource: 'demande',
          //     resourceId: undefined,
          //   },
          // },
        },
      };
    }

    function futur_form_fields(
      bloc3Cms: DemandeRfBloc3,
      demandeUtilitaire: DemandeRfUtilitaire,
      adresse_not_found: boolean,
      logementAutre: any,
      control: any,
    ): AccomodationsValue {
      return {
        nom_hebergeur: {
          name: 'nom_futur_hebergeur',
          label: bloc3Cms.nom_hebergeur,
          control,
          required: true,
        },
        adresse_postale: {
          name: 'adresse_postale_futur_logement',
          label: demandeUtilitaire.adresse_postale.titre,
          hintText: demandeUtilitaire.adresse_postale.sous_titre,
          required: adresse_not_found ? false : true,
          control,
        },
        adresse_non_trouvee: {
          name: 'adresse_futur_logement_non_trouvee',
          label: demandeUtilitaire.adresse_non_trouvee,
          control,
        },
        complement_adresse: {
          name: 'complement_adresse_futur_logement',
          label: demandeUtilitaire.complement_adresse.titre,
          hintText: demandeUtilitaire.complement_adresse.sous_titre,
          required: false,
          control,
        },
        numero_de_voie: {
          name: 'numero_de_voie_futur_logement',
          label: demandeUtilitaire.numero_voie,
          required: false,
          control,
        },
        code_postal: {
          name: 'code_postal_futur_logement',
          label: demandeUtilitaire.code_postal,
          required: true,
          control,
        },
        nom_de_rue: {
          name: 'nom_de_rue_futur_logement',
          label: demandeUtilitaire.nom_rue,
          required: true,
          control,
        },
        ville_ou_commune: {
          name: 'ville_ou_commune_futur_logement',
          label: demandeUtilitaire.ville_commune,
          required: true,
          control,
        },
        numero_digicode: {
          name: 'numero_digicode_futur_logement',
          label: demandeUtilitaire.numero_digicode,
          hintText: demandeUtilitaire.champs_facultatif,
          required: false,
          control,
        },
        nom_interphone: {
          name: 'nom_interphone_futur_logement',
          label: demandeUtilitaire.nom_interphone,
          hintText: demandeUtilitaire.champs_facultatif,
          required: false,
          control,
        },
        situation_logement: {
          name: 'situation_futur_logement',
          label: bloc3Cms.nature_resident,
          defaultValue: logementAutre?.situation
            ? logementAutre.situation
            : demandeUtilitaire.choisir_option,
          options: [
            {
              label: demandeUtilitaire.locataire,
              value: 'locataire',
            },
            {
              label: demandeUtilitaire.sous_locataire,
              value: 'sous_locataire',
            },
            {
              label: demandeUtilitaire.heberge_gratuitement,
              value: 'gratuit',
            },
            {
              label: demandeUtilitaire.proprietaire,
              value: 'proprietaire',
            },
          ],
          required: false,
          control,
        },
        type_hebergeur: {
          name: 'type_futur_hebergement',
          label: bloc3Cms.titre_selecteur_hebergeur,
          defaultValue: demandeUtilitaire.choisir_option,
          options: [
            {
              label: demandeUtilitaire.un_particulier,
              value: 'particulier',
            },
            {
              label: demandeUtilitaire.votre_employeur,
              value: 'employeur',
            },
            {
              label: demandeUtilitaire.autre,
              value: 'autre',
            },
          ],
          control,
        },
      };
    }

    function family_accomodation_field(
      bloc3Cms: DemandeRfBloc3,
      setLogementValue: React.Dispatch<React.SetStateAction<'oui' | 'non' | undefined>>,
      logementActuelValue: string | undefined,
      control: any,
      trigger: any,
    ) {
      return {
        label: bloc3Cms.famille_loge_a_cette_adresse,
        name: 'logement_famille',
        options: [
          {
            label: bloc3Cms.oui_loge,
            nativeInputProps: {
              value: 'oui',
              checked: logementActuelValue === 'oui',
              onChange: () => {
                setLogementValue('oui');
                trigger('logement_famille');
              },
            },
          },
          {
            label: bloc3Cms.non_pas_loge,
            nativeInputProps: {
              value: 'non',
              checked: logementActuelValue === 'non',
              onChange: () => {
                setLogementValue('non');
                trigger('logement_famille');
              },
            },
          },
        ],
        control,
      };
    }

    function entree_futur_logement(bloc3Cms: DemandeRfBloc3, control: any) {
      return {
        name: 'entree_futur_logement',
        label: bloc3Cms.sous_titre_date_disponibilite_logement_futur,
        control,
        required: true,
      };
    }

    return {
      current_form_fields,
      get_files_fields,
      futur_form_fields,
      alerts_fields,
      family_accomodation_field,
      entree_futur_logement,
    };
  }
}
