import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import { getCurrentLocale } from 'redux/locale/selectors';
import Loader from 'components/Atoms/Loader/Loader';
import { useGetRgpdCmsPageQuery } from 'redux/rtk/cms/cmsRgpd';
import { rgpdQuery } from 'pages/Home/Home.query';
import { setRgpdPage } from 'redux/pages';
import { getRgpdPage } from 'redux/pages/selectors';

import CmsPage from 'pages/CmsPage/CmsPage';

const Rgpd: FC = () => {
  const lang = useSelector(getCurrentLocale) as string;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const rgpdStore = useSelector(getRgpdPage);

  const {
    data: rgpdCms,
    error: rgpdCmsError,
    isLoading: isRgpdCmsLoading,
  } = useGetRgpdCmsPageQuery({
    qs: rgpdQuery,
    locale: lang !== '' ? lang : 'fr',
  });

  useEffect(() => {
    if (!isRgpdCmsLoading && !rgpdCmsError) {
      dispatch(setRgpdPage(rgpdCms.data));
    }
  }, [rgpdCms, rgpdCmsError, isRgpdCmsLoading, dispatch]);

  if (isRgpdCmsLoading) {
    return <Loader />;
  }

  if (rgpdCmsError) {
    navigate('/erreur');
  }

  return <CmsPage type="rgpd" store={rgpdStore} lang={lang} />;
};

export default Rgpd;
