import React, { FC, useEffect, useState } from 'react';
import Button from '@codegouvfr/react-dsfr/Button';

import CustomModal from 'components/Molecules/CustomModal/CustomModal';
import WidgetFactory from 'helpers/cms/WidgetPageContenu/WidgetFactory';
import { WidgetBaseElement, WidgetPageContenu } from 'models/types/cms/widgetPageContenu/types';
import { getRfCmsContents } from 'pages/DemandeRF/Helpers/Cms/getRfCmsContents';

export type InfobulleProps = {
  cmsContent: any | string;
  infobulleName: string;
  lang: string;
  btnTitle?: string;
};

const InfobulleForCustomModal: FC<InfobulleProps> = ({
  cmsContent,
  infobulleName,
  lang,
  btnTitle,
}) => {
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const handleOpenModal = () => {
    setModalOpen(true);
  };
  const handleCloseModal = () => {
    setModalOpen(false);
  };
  const [infobulleTitleValue, setInfobulleTitleValue] = useState<string>('');
  const { utilitaireCms } = getRfCmsContents(lang);

  const filteredCmsContent = cmsContent.filter(
    (widget: WidgetBaseElement) => !(widget.__component === WidgetPageContenu.TITRE_INFOBULLE),
  );

  useEffect(() => {
    const infobulleTitle = cmsContent.find(
      (widget: WidgetBaseElement) => widget.__component === WidgetPageContenu.TITRE_INFOBULLE,
    );

    if (infobulleTitle) {
      setInfobulleTitleValue(infobulleTitle.titre_infobulle);
    }
  }, [cmsContent]);

  return (
    <>
      <CustomModal
        id={infobulleName}
        hasCloseBtn={true}
        isOpen={isModalOpen}
        onClose={handleCloseModal}
      >
        <h5>{infobulleTitleValue}</h5>
        {filteredCmsContent.map((widget: WidgetBaseElement) => (
          <WidgetFactory key={`${widget.__component}-${widget.id}`} widget={widget} />
        ))}
        <div className="fr-pb-6w"></div>
      </CustomModal>
      <Button
        type="button"
        iconId="fr-icon-question-line"
        priority="tertiary no outline"
        title={btnTitle ?? utilitaireCms.clic_pour_en_savoir_plus}
        aria-label={btnTitle ?? utilitaireCms.clic_pour_en_savoir_plus}
        onClick={() => handleOpenModal()}
        className="custom-btn-icon-padding"
      />
    </>
  );
};

export default InfobulleForCustomModal;
