import { CHOIX } from 'constants/global';
import { LIEN_PARENTE, RESIDENCE_LIEU } from 'constants/regroupementFamiliale';
import { mapBoolFormToServer } from 'helpers/api/formSubmission';
import { Conjoint } from 'models/types/api/rf';
import { ConjointDemandeForm } from 'models/types/app/demandeRf';

export const conjointAppToApiFormatter = (
  data: ConjointDemandeForm,
  id: string | null,
  documents: any,
  isValid: boolean,
) => {
  const conjoint: Conjoint = {
    id: id,
    rfDemande: mapBoolFormToServer(data.rf_demande),
    vitEnFrance:
      data.conjoint_etranger !== undefined
        ? data.conjoint_etranger === RESIDENCE_LIEU.FRANCE
        : null,
    lienParente: LIEN_PARENTE.EPOUX,
    titreSejourFr:
      data.titre_sejour_fr_conjoint !== undefined
        ? data.titre_sejour_fr_conjoint === CHOIX.OUI
        : null,
    dejaMarie:
      data.mariage_precedent_conjoint !== undefined
        ? data.mariage_precedent_conjoint === CHOIX.OUI
        : null,
    divorceTrad:
      data.conjoint_jugement_divorce_traduit !== undefined
        ? data.conjoint_jugement_divorce_traduit
        : null,
    etatCivil: {
      nom: data.nom_naissance_conjoint !== undefined ? data.nom_naissance_conjoint : null,
      prenoms: data.prenoms_conjoint !== undefined ? data.prenoms_conjoint : null,
      sexe: data.sexe_conjoint !== undefined ? data.sexe_conjoint : null,
      nomUsage: data.nom_usage_conjoint ? data.nom_usage_conjoint : null,
      dateNaissance: data.date_naissance_conjoint ? data.date_naissance_conjoint : null,
      paysNaissance: data.pays_naissance_conjoint ? data.pays_naissance_conjoint : null,
      villeNaissance: data.ville_naissance_conjoint ? data.ville_naissance_conjoint : null,
      nationalite: data.nationalite_conjoint ? data.nationalite_conjoint : null,
      acteNaissanceTrad:
        data.acte_naissance_conjoint_traduit !== undefined
          ? data.acte_naissance_conjoint_traduit
          : null,
    },
    documents: documents,
    estValide: isValid,
  };

  return conjoint;
};
