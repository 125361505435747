/* eslint-disable max-params */
/* eslint-disable max-lines */
import { FC, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler } from 'react-hook-form';
import * as yup from 'yup';
import { fr } from '@codegouvfr/react-dsfr';
import Alert from '@codegouvfr/react-dsfr/Alert';

import { Input as CustomInput } from 'components/Atoms/Input';
import { Select as CustomSelect } from 'components/Atoms/Select';
import { TextArea as CustomTextArea } from 'components/Atoms/TextArea';
import { PageContentHtmlParserFunction } from 'components/Atoms/HtmlParsers/PageContentHtmlParser';
import { OBJET_DEMANDE } from 'constants/contact';
import { ContactPage } from 'models/types/cms/contact/types';
import { ACCUEIL, TABLEAU_DE_BORD } from 'routes/path';

import { getValidationSchema } from '../validationContact.schema';

const characterLimit = 300;

interface ContactFormProps {
  errorCms: any;
  cmsContact: ContactPage;
  cmsUsager: any;
  dataUser: any;
  newContact: any;
  isSuccess: any;
  auth: any;
  lang: string;
}

/* eslint-disable complexity */
const ContactForm: FC<ContactFormProps> = ({
  errorCms,
  cmsContact,
  cmsUsager,
  dataUser,
  newContact,
  isSuccess,
  auth,
  lang,
}) => {
  const formId = 'contact-1813';
  type ContactValues = yup.InferType<typeof validationSchema>;

  const validationSchema = useMemo(
    () => getValidationSchema(errorCms, cmsContact, characterLimit),
    [],
  );

  const { reset, handleSubmit, register, control, setValue, watch } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      adresse_mail: '',
      nom: '',
      prenom: '',
      civilite: '',
      objet_demande: '',
      objet_demande_detail: '',
      detail: '',
      numero_etranger: '',
    },
    mode: 'onChange',
  });

  const watchObjetDemande = watch('objet_demande', '');
  const watchDetail = watch('detail', '');

  const onSubmit: SubmitHandler<ContactValues> = async (data: ContactValues) => {
    try {
      const objetDemande =
        data.objet_demande === OBJET_DEMANDE.ACCESS_PORTAIL
          ? cmsContact.formulaire_contact.label_objet_demande_access_portail
          : data.objet_demande === OBJET_DEMANDE.RF
          ? cmsContact.formulaire_contact.label_objet_demande_rf
          : cmsContact.formulaire_contact.label_objet_demande_asile;
      await newContact({ ...data, objet_demande: objetDemande });
    } catch (e) {
      console.log('error', e);
    }
  };

  useEffect(() => {
    if (watchDetail && watchDetail.length <= characterLimit) {
      setValue('detail', watchDetail);
    }
  }, [watchDetail]);

  useEffect(() => {
    if (auth.isAuthenticated) {
      if (dataUser) {
        reset({
          adresse_mail: dataUser.adresse_mail,
          nom: dataUser.nom_naissance,
          prenom: dataUser.prenom,
          civilite: dataUser.sexe,
          detail: '',
          numero_etranger: dataUser.identifiant,
          objet_demande: '',
          objet_demande_detail: '',
        });
      }
    }
  }, [auth.isAuthenticated, reset, dataUser]);

  return (
    <main role="main" id="contact">
      <div className="fr-container fr-mt-6w fr-mt-md-5w fr-mb-5w fr-mb-md-2w">
        <div className="fr-grid-row fr-grid-row--gutters fr-grid-row--center">
          <div className="fr-col-12 fr-col-md-10 fr-col-lg-8">
            {isSuccess ? (
              <>
                <h1>{cmsContact.formulaire_contact.message_succes_titre}</h1>
              </>
            ) : (
              <>
                <h1>{cmsContact.formulaire_contact.titre}</h1>
                {cmsContact.description && <p className="description">{cmsContact.description}</p>}
              </>
            )}
          </div>
        </div>
      </div>
      <div className="fr-container fr-container--fluid fr-mb-md-14v">
        <div className="fr-grid-row fr-grid-row-gutters fr-grid-row--center">
          <div className="fr-col-12 fr-col-md-10 fr-col-lg-8">
            <div className="fr-container fr-background-alt--grey fr-px-0 ">
              <div className="fr-grid-row fr-grid-row-gutters fr-grid-row--center">
                <div className="fr-col-12 fr-col-md-10">
                  {isSuccess ? (
                    <div
                      className="fr-p-2w fr-p-md-5w container-success"
                      style={{
                        background: fr.colors.decisions.background.default.grey.hover,
                      }}
                    >
                      <Alert
                        description={PageContentHtmlParserFunction(
                          cmsContact.formulaire_contact.message_succes_final || '',
                        )}
                        severity="success"
                        title=""
                      />
                      <a
                        className="fr-link fr-icon-arrow-right-line fr-link--icon-right"
                        href={auth.isAuthenticated ? TABLEAU_DE_BORD : ACCUEIL}
                      >
                        {cmsContact.formulaire_contact.bouton_retour_accueil}
                      </a>
                    </div>
                  ) : (
                    <form
                      className="fr-p-2w fr-p-md-5w"
                      id={formId}
                      onSubmit={handleSubmit(onSubmit)}
                      style={{
                        background: fr.colors.decisions.background.default.grey.hover,
                      }}
                    >
                      <input
                        type="hidden"
                        {...register('locale')}
                        defaultValue={lang !== '' ? lang : 'fr'}
                      />
                      <fieldset
                        className="fr-mb-0 fr-fieldset"
                        id="login-1797-fieldset"
                        aria-labelledby="login-1797-fieldset-legend login-1797-fieldset-messages"
                      >
                        <div className="fr-fieldset__element">
                          <span className="fr-hint-text">
                            {cmsContact.formulaire_contact.avertissement_champs_obligatoires}
                          </span>
                        </div>
                        <div className="fr-container--fluid">
                          <div className="fr-grid-row ">
                            <div className="fr-col-12 fr-col-md-6">
                              <div className="fr-fieldset__element">
                                <div className="fr-input-group">
                                  <CustomSelect
                                    id="civilite"
                                    name="civilite"
                                    label={cmsContact.formulaire_contact.label_civilite}
                                    options={[
                                      {
                                        label: cmsUsager.civilite.homme
                                          ? cmsUsager.civilite.homme
                                          : '',
                                        value: cmsUsager.civilite.homme
                                          ? cmsUsager.civilite.homme
                                          : '',
                                      },
                                      {
                                        label: cmsUsager.civilite.femme
                                          ? cmsUsager.civilite.femme
                                          : '',
                                        value: cmsUsager.civilite.femme
                                          ? cmsUsager.civilite.femme
                                          : '',
                                      },
                                    ]}
                                    control={control}
                                    defaultValue={''}
                                    nativeSelectProps={{}}
                                    children={null}
                                    hint={cmsContact.formulaire_contact.hint_civilite}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="fr-col-12 fr-col-md-6">
                              <div className="fr-fieldset__element">
                                <CustomInput
                                  id="prenom"
                                  name="prenom"
                                  label={cmsContact.formulaire_contact.label_prenom}
                                  defaultValue=""
                                  control={control}
                                  nativeInputProps={{}}
                                  hintText={cmsContact.formulaire_contact.hint_prenom}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="fr-grid-row ">
                            <div className="fr-col-12 fr-col-md-6">
                              <div className="fr-fieldset__element">
                                <CustomInput
                                  id="nom"
                                  name="nom"
                                  label={cmsContact.formulaire_contact.label_nom}
                                  defaultValue=""
                                  control={control}
                                  nativeInputProps={{}}
                                  hintText={cmsContact.formulaire_contact.hint_nom}
                                />
                              </div>
                            </div>
                            <div className="fr-col-12 fr-col-md-6">
                              <div className="fr-fieldset__element">
                                <CustomInput
                                  id="adresse_mail"
                                  name="adresse_mail"
                                  label={cmsContact.formulaire_contact.label_email}
                                  defaultValue=""
                                  control={control}
                                  nativeInputProps={{}}
                                  hintText={cmsContact.formulaire_contact.hint_email}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="fr-grid-row ">
                            <div className="fr-col-12 fr-col-sm-6">
                              <div className="fr-fieldset__element">
                                <CustomInput
                                  id="numero_etranger"
                                  name="numero_etranger"
                                  label={cmsContact.formulaire_contact.label_numero_etranger}
                                  defaultValue=""
                                  hintText={cmsContact.formulaire_contact.hint_numero_etranger}
                                  control={control}
                                  nativeInputProps={{}}
                                  lang={lang}
                                  infobulle={cmsContact.infobulle_numero_etranger}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="fr-grid-row ">
                            <div className="fr-col-12 fr-col-md-12">
                              <div className="fr-fieldset__element">
                                <div className="fr-input-group">
                                  <CustomSelect
                                    id="objet_demande"
                                    name="objet_demande"
                                    label={cmsContact.formulaire_contact.label_objet_demande}
                                    options={[
                                      {
                                        label: cmsContact.formulaire_contact
                                          .label_objet_demande_access_portail
                                          ? cmsContact.formulaire_contact
                                              .label_objet_demande_access_portail
                                          : '',
                                        value: OBJET_DEMANDE.ACCESS_PORTAIL,
                                      },
                                      {
                                        label: cmsContact.formulaire_contact.label_objet_demande_rf
                                          ? cmsContact.formulaire_contact.label_objet_demande_rf
                                          : '',
                                        value: OBJET_DEMANDE.RF,
                                      },
                                      {
                                        label: cmsContact.formulaire_contact
                                          .label_objet_demande_asile
                                          ? cmsContact.formulaire_contact.label_objet_demande_asile
                                          : '',
                                        value: OBJET_DEMANDE.DEMANDE_ASILE,
                                      },
                                    ]}
                                    control={control}
                                    defaultValue=""
                                    nativeSelectProps={{}}
                                    children={null}
                                  />
                                </div>
                              </div>
                            </div>
                            {watchObjetDemande !== '' && (
                              <div className="fr-col-12 fr-col-md-12">
                                <div className="fr-fieldset__element">
                                  <div className="fr-input-group">
                                    <CustomSelect
                                      id="objet_demande_detail"
                                      name="objet_demande_detail"
                                      label="Préciser le problème rencontré"
                                      options={
                                        watchObjetDemande === OBJET_DEMANDE.ACCESS_PORTAIL
                                          ? cmsContact.formulaire_contact
                                              .choix_objet_demande_access_portail
                                            ? cmsContact.formulaire_contact.choix_objet_demande_access_portail.map(
                                                (objet: { texte: string }) => {
                                                  return {
                                                    label: objet.texte,
                                                    value: objet.texte,
                                                  };
                                                },
                                              )
                                            : []
                                          : watchObjetDemande === OBJET_DEMANDE.RF
                                          ? cmsContact.formulaire_contact.choix_objet_demande_rf
                                            ? cmsContact.formulaire_contact.choix_objet_demande_rf.map(
                                                (objet: { texte: string }) => {
                                                  return {
                                                    label: objet.texte,
                                                    value: objet.texte,
                                                  };
                                                },
                                              )
                                            : []
                                          : cmsContact.formulaire_contact.choix_objet_demande_asile
                                          ? cmsContact.formulaire_contact.choix_objet_demande_asile.map(
                                              (objet: { texte: string }) => {
                                                return {
                                                  label: objet.texte,
                                                  value: objet.texte,
                                                };
                                              },
                                            )
                                          : []
                                      }
                                      control={control}
                                      defaultValue=""
                                      nativeSelectProps={{}}
                                      children={null}
                                    />
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                          <div className="fr-grid-row ">
                            <div className="fr-col">
                              <div className="fr-fieldset__element">
                                <div className="fr-input-group">
                                  <CustomTextArea
                                    id="detail"
                                    name="detail"
                                    label={cmsContact.formulaire_contact.label_detail}
                                    defaultValue=""
                                    control={control}
                                    textArea={true}
                                    rows={3}
                                    limit={characterLimit}
                                    hintText={
                                      cmsContact.formulaire_contact.champ_detail_valeur_par_defaut
                                    }
                                  />
                                  <div className="textarea-counter">
                                    {watchDetail ? watchDetail.length : 0}/{characterLimit}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="fr-fieldset__element">
                            <ul className="fr-btns-group fr-btns-group--inline-md">
                              <li>
                                <button className="fr-btn" type="submit">
                                  {cmsContact.formulaire_contact.texte_bouton_formulaire}
                                </button>
                              </li>
                            </ul>
                          </div>
                          <div className="fr-grid-row ">
                            <div className="fr-px-2v fr-col">
                              {PageContentHtmlParserFunction(
                                cmsContact.formulaire_contact.avertissement_protection_donnees ||
                                  '',
                              )}
                            </div>
                          </div>
                        </div>
                        <div
                          className="fr-messages-group"
                          id="login-1797-fieldset-messages"
                          aria-live="assertive"
                        ></div>
                      </fieldset>
                    </form>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};
export default ContactForm;
