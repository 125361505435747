import React from 'react';
import ReactDOM from 'react-dom/client';

import App from 'App';
import 'index.css';
import reportWebVitals from 'reportWebVitals';
import buildStore from 'redux/store';
import { makeServer } from 'mockServer/server';
import getEnv from 'helpers/app/envHelper';

if (getEnv('NODE_ENV') === 'development' && getEnv('REACT_APP_MOCK_SERVER') === 'true') {
  makeServer({ environment: 'development' });
}

const { store, persistor } = buildStore();
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <App store={store} persistor={persistor} />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
