import React, { FC } from 'react';
import Button from '@codegouvfr/react-dsfr/Button';
import { useAuth } from 'react-oidc-context';

import CustomAlert from 'components/Atoms/CustomAlert/CustomAlert';
import { AccountCreationPage } from 'models/types/app/pages';

interface ResultsProps {
  success: 'success' | 'error';
  keycloakAlive: boolean;
  accountCreationPage: AccountCreationPage;
}

const Results: FC<ResultsProps> = ({ success, keycloakAlive, accountCreationPage }) => {
  const auth = useAuth();
  return (
    <div className="form-bg fr-py-10v fr-px-4v fr-py-md-14v fr-px-md-13w">
      <div className="fr-col-12">
        {success === 'success' ? (
          <CustomAlert
            small={true}
            severity={success}
            description={accountCreationPage.resultat_compte_cree ?? ''}
          />
        ) : (
          <CustomAlert
            small={true}
            severity={success}
            description={accountCreationPage.resultat_erreur_generique ?? ''}
          />
        )}
      </div>

      {success === 'success' && (
        <p className="fr-text--xs fr-mt-6v">
          {accountCreationPage.message_success_sous_bulle_aide}
        </p>
      )}

      <div className="fr-mt-6v controls-buttons">
        {keycloakAlive && (
          <Button
            className="return-to-login-button"
            type="button"
            onClick={() => void auth.signinRedirect()}
            priority="secondary"
          >
            {accountCreationPage.bouton_retour_page_login}
          </Button>
        )}
      </div>
    </div>
  );
};
export default Results;
