import { FC, useEffect, useState } from 'react';

import TextInputContainer from 'components/Molecules/Forms/TextInputContainer/TextInputContainer';
import CommonRowContainer from 'components/Molecules/Forms/CommonRowContainer/CommonRowContainer';
import CustomCheckboxContainer from 'components/Molecules/Forms/CustomCheckboxContainer/CustomCheckboxContainer';
import { getRfCmsContents } from 'pages/DemandeRF/Helpers/Cms/getRfCmsContents';
import { AccomodationsValue } from 'models/types/app/mappings';
import AutocompleteContainer from 'components/Molecules/Forms/AutocompleteContainer/AutocompleteContainer';

type AddressPanelProps = {
  accomodationMapping: AccomodationsValue;

  control: any;
  errors: any;
  lang: string;
  setValue: any;
  isFutur?: boolean;
  watch: any;
  trigger: any;
  debugAccomodation: any;
};

const AddressPanel: FC<AddressPanelProps> = ({
  accomodationMapping,
  control,
  isFutur = false,
  lang,
  setValue,
  watch,
  debugAccomodation,
  // trigger,
}) => {
  const [adresseNotFound, setAdresseNotFound] = useState(false);
  const adresseNotFoundWatcher = watch(accomodationMapping.adresse_non_trouvee.name);

  const {
    bloc3Cms,
    // errorCms,
  } = getRfCmsContents(lang);

  useEffect(() => {
    setValue(accomodationMapping.adresse_non_trouvee.name, adresseNotFound);
    if (
      debugAccomodation.all ||
      (isFutur
        ? debugAccomodation.futurAccomodationAdresse
        : debugAccomodation.currentAccomodationAdresse)
    ) {
      console.log('AdressePanelEffect - adresseNotFound', adresseNotFound);
    }
  }, [adresseNotFound]);

  useEffect(() => {
    if (adresseNotFound != adresseNotFoundWatcher) {
      setAdresseNotFound(adresseNotFoundWatcher);
    }

    if (
      debugAccomodation.all ||
      (isFutur
        ? debugAccomodation.futurAccomodationAdresse
        : debugAccomodation.currentAccomodationAdresse)
    ) {
      console.log('AdressePanelEffect - adresseNotFoundWatcher', adresseNotFoundWatcher);
    }
  }, [adresseNotFoundWatcher]);

  //Todo infobulle futur
  return (
    <>
      <CommonRowContainer
        legend={isFutur ? bloc3Cms.famille_habitera_au : bloc3Cms.vous_habitez_au}
        lang={lang}
        infobulleContent={
          isFutur ? bloc3Cms.infobulle_vous_habitez_au_futur : bloc3Cms.infobulle_vous_habitez_au
        }
      >
        <AutocompleteContainer
          {...accomodationMapping.adresse_postale}
          stateRelatedMessage="test"
          lang="fr"
          source="BANO"
          disabled={adresseNotFound}
        />
        {!adresseNotFound && <TextInputContainer {...accomodationMapping.complement_adresse} />}
      </CommonRowContainer>

      <CustomCheckboxContainer
        control={control}
        name={accomodationMapping.adresse_non_trouvee.name}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setAdresseNotFound(e.target.checked)}
        options={[
          {
            label: accomodationMapping.adresse_non_trouvee.label,

            nativeInputProps: {
              name: accomodationMapping.adresse_non_trouvee.name,
              checked: adresseNotFound,
            },
          },
        ]}
      />
      {adresseNotFound && (
        <>
          <CommonRowContainer>
            <TextInputContainer {...accomodationMapping.numero_de_voie} />
            <TextInputContainer {...accomodationMapping.nom_de_rue} />
          </CommonRowContainer>
          <CommonRowContainer>
            <TextInputContainer {...accomodationMapping.code_postal} />
            <AutocompleteContainer
              {...accomodationMapping.ville_ou_commune}
              stateRelatedMessage="test"
              lang="fr"
              source="BANO"
              searchType="municipality"
            />
          </CommonRowContainer>
          <TextInputContainer {...accomodationMapping.complement_adresse} />
        </>
      )}
      <CommonRowContainer>
        <TextInputContainer {...accomodationMapping.numero_digicode} />
        <TextInputContainer {...accomodationMapping.nom_interphone} />
      </CommonRowContainer>
    </>
  );
};

export default AddressPanel;
