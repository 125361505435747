import qs from 'qs';
import { FileInfosQuery, HelpBoxQuery, InfobulleQuery } from '../common.query';

export const bloc4Query = qs.stringify({
  populate: {
    button_infobulle_en_savoir_plus: InfobulleQuery,
    fichier_dernier_avis_impot: FileInfosQuery,
    fichier_justificatif_presta_social_algerien: FileInfosQuery,
    fichier_btp_justif_conges_payes: FileInfosQuery,
  },
});

export const bloc4SecondQuery = qs.stringify({
  populate: {
    fichier_secu_social_indemnite_jour: FileInfosQuery,
    fichier_justif_attribution_aah_asi: FileInfosQuery,
    fichier_justif_paiement_aah_asi: FileInfosQuery,
  },
});

export const bloc4ActivitiesFirstPartQuery = qs.stringify({
  populate: {
    fichier_releve_compte_origine_revenus: FileInfosQuery,
    fichier_justif_pension_alimentaire: FileInfosQuery,
    fichier_contrat_attestation_travail: FileInfosQuery,
  },
});

export const bloc4ActivitiesSecondPartQuery = qs.stringify({
  populate: {
    fichier_bulletin_salaire_cdi: FileInfosQuery,
    fichier_certificat_travail_cdi: FileInfosQuery,
    fichier_contrat_attestation_travail_interim: FileInfosQuery,
  },
});

export const bloc4ActivitiesThirdPartQuery = qs.stringify({
  populate: {
    fichier_certificat_travail_interim: FileInfosQuery,
    fichier_contrat_attestation_travail_cdd: FileInfosQuery,
    fichier_certificat_travail_cdd: FileInfosQuery,
  },
});

export const bloc4InfobulleFirstQuery = qs.stringify({
  populate: {
    fichier_bulletin_salaire_cdd: FileInfosQuery,
    fichier_artisan_extrait_inscription_metier: FileInfosQuery,
    fichier_artisan_bilan_comptable: FileInfosQuery,
  },
});

export const bloc4InfobulleSecondQuery = qs.stringify({
  populate: {
    fichier_chomage_releve_situation_droits: FileInfosQuery,
    fichier_liberal_extrait_inscription_siren: FileInfosQuery,
    fichier_liberal_bilan_comptable: FileInfosQuery,
  },
});

export const bloc4InfobulleThirdQuery = qs.stringify({
  populate: {
    fichier_commercant_extrait_inscription_rcs: FileInfosQuery,
    fichier_commercant_bilan_comptable: FileInfosQuery,
    fichier_autoent_declaration_activite: FileInfosQuery,
  },
});

export const bloc4InfobulleFourthQuery = qs.stringify({
  populate: {
    fichier_autoent_livre_recettes: FileInfosQuery,
    fichier_invalide_attribution_pension: FileInfosQuery,
    fichier_invalide_avis_versement_pension: FileInfosQuery,
  },
});

export const bloc4InfobulleFifthQuery = qs.stringify({
  populate: {
    fichier_retraite_invalidite_attribution_pension: FileInfosQuery,
    fichier_retraite_invalidite_avis_versement_pension: FileInfosQuery,
    infobulle_titre_autres_profession_a_declarer: InfobulleQuery,
    infobulle_titre_avez_vous_travailler_btp: InfobulleQuery,
    infobulle_arret_travail_derniers_mois: InfobulleQuery,
    infobulle_etes_vous_beneficiaire_aah_asi: InfobulleQuery,
    infobulle_autres_sources_revenus: InfobulleQuery,
    infobulle_souhait_declarer_conjoint: InfobulleQuery,
  },
});

export const bloc4InfobulleSixthQuery = qs.stringify({
  populate: {
    infobulle_fichier_dernier_avis_impot: InfobulleQuery,
    infobulle_fichier_derniere_declaration_revenus: InfobulleQuery,
    infobulle_titre_quelle_situation_professionnelle: InfobulleQuery,
    infobulle_fichier_votre_contrat_travail: InfobulleQuery,
    infobulle_fichier_bulletins_salaire_cdi: InfobulleQuery,
    infobulle_fichier_attestation_travail_employeur: InfobulleQuery,
    infobulle_fichier_certificat_travail: InfobulleQuery,
    infobulle_fichier_bulletins_salaire_cdd: InfobulleQuery,
    infobulle_fichier_releve_situation_et_versements_demandeur_emploi: InfobulleQuery,
    infobulle_fichier_artisan_extrait_repertoire_metier: InfobulleQuery,
    infobulle_fichier_artisan_bilan: InfobulleQuery,
    infobulle_fichier_commercant_registre_commerce_rcs: InfobulleQuery,
    infobulle_fichier_commercant_bilan: InfobulleQuery,
    infobulle_fichier_liberal_extrait_sirene: InfobulleQuery,
  },
});

export const bloc4InfobulleSeventhQuery = qs.stringify({
  populate: {
    infobulle_fichier_liberal_bilan: InfobulleQuery,
    infobulle_fichier_autoent_cfe: InfobulleQuery,
    infobulle_fichier_autoent_livre_recette: InfobulleQuery,
    infobulle_fichier_invalide_decision_attribution: InfobulleQuery,
    infobulle_fichier_invalide_avis_versement: InfobulleQuery,
    infobulle_fichier_btp_justificatif_versement_conges_payes: InfobulleQuery,
    infobulle_fichier_justificatif_versement_are_secu_arret_travail: InfobulleQuery,
    infobulle_fichier_justificatif_autres_ressources_ou_ressource_conjoint: InfobulleQuery,
    infobulle_fichier_versement_prestations_sociales: InfobulleQuery,
    infobulle_fichier_pension_alimentaire: InfobulleQuery,
    infobulle_fichier_retraite_invalide_decision_attribution: InfobulleQuery,
    infobulle_fichier_attestation_paiement_aah_asi: InfobulleQuery,
    principale_helpbox_top: HelpBoxQuery,
  },
});

export const bloc4FormQuery = qs.stringify({
  populate: {
    fields: ['titre_principal'],
  },
});
