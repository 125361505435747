import qs from 'qs';
import { FileInfosQuery, HelpBoxQuery, InfobulleQuery, TitleSubTitleQuery } from '../common.query';

export const bloc2Query = qs.stringify({
  populate: {
    fields: ['button_infobulle_en_savoir_plus'],
    fichier_lettre_explication_rf_partiel: FileInfosQuery,
    fichier_acte_naissance_conjoint: FileInfosQuery,
  },
});

export const bloc2SecondQuery = qs.stringify({
  populate: {
    fichier_kafala_enfant: FileInfosQuery,
    fichier_kafala_enfant_traduit: FileInfosQuery,
    infobulle_base_qui_est_votre_conjoint: InfobulleQuery,
  },
});

export const bloc2ThirdQuery = qs.stringify({
  populate: {
    infobulle_conjoint_fichier_acte_naissance: InfobulleQuery,
    infobulle_conjoint_fichier_acte_divorce_fr: InfobulleQuery,
    infobulle_conjoint_fichier_titre_identite_conjoint: InfobulleQuery,
  },
});

export const bloc2FourthQuery = qs.stringify({
  populate: {
    infobulle_enfant_fichier_autorisation_autre_parent_fr: InfobulleQuery,
    infobulle_enfant_fichier_acte_deces_autre_parent_original: InfobulleQuery,
    infobulle_enfant_fichier_livret_famille: InfobulleQuery,
  },
});

export const bloc2FifthQuery = qs.stringify({
  populate: {
    fichier_acte_naissance_enfant: FileInfosQuery,
    fichier_acte_naissance_enfant_traduit: FileInfosQuery,
    fichier_jugement_adoption: FileInfosQuery,
  },
});

export const bloc2SixthQuery = qs.stringify({
  populate: {
    fichier_lettre_autorisation_autre_parent: FileInfosQuery,
    fichier_lettre_autorisation_autre_parent_traduit: FileInfosQuery,
    fichier_acte_deces_autre_parent: FileInfosQuery,
  },
});

export const bloc2SeventhQuery = qs.stringify({
  populate: {
    fichier_attestation_dissolution_pacs: FileInfosQuery,
    fichier_union_jugement_divorce: FileInfosQuery,
  },
});

export const bloc2EighthQuery = qs.stringify({
  populate: {
    fichier_acte_deces_autre_parent_traduit: FileInfosQuery,
    fichier_lettre_disparition_autre_parent: FileInfosQuery,
  },
});

export const bloc2NinethQuery = qs.stringify({
  populate: {
    fichier_jugement_adoption_enfant_traduit: FileInfosQuery,
    fichier_livret_famille: FileInfosQuery,
  },
});

export const bloc2TenthQuery = qs.stringify({
  populate: {
    infobulle_selecteur_lien_avec_vous: InfobulleQuery,
    infobulle_selecteur_lien_autre_parent: InfobulleQuery,
    infobulle_union_fichier_acte_divorce_fr: InfobulleQuery,
    infobulle_union_fichier_attestation_dissolution_pacs_fr: InfobulleQuery,
  },
});

export const bloc2EleventhQuery = qs.stringify({
  populate: {
    infobulle_enfant_fichier_acte_naissance_fr: InfobulleQuery,
    infobulle_enfant_fichier_kafala_fr: InfobulleQuery,
    infobulle_enfant_fichier_adoption_original: InfobulleQuery,
    infobulle_enfant_fichier_jugement_autorite_parentale_original: InfobulleQuery,
  },
});

export const bloc2TwelthQuery = qs.stringify({
  populate: {
    infobulle_base_qui_sont_vos_enfants: InfobulleQuery,
    infobulle_fichier_lettre_explication_rf_partiel: InfobulleQuery,
    infobulle_base_avez_vous_ete_marie: InfobulleQuery,
    infobulle_fichier_declaration_abandon_ou_disparu_tribunal: InfobulleQuery,
  },
});

export const bloc2TherteenthQuery = qs.stringify({
  populate: {
    fichier_acte_naissance_conjoint_traduit: FileInfosQuery,
    fichier_titre_identite_conjoint: FileInfosQuery,
  },
});

export const bloc2FourteethQuery = qs.stringify({
  populate: {
    fichier_union_jugement_divorce_traduit: FileInfosQuery,
    fichier_conjoint_jugement_divorce_traduit: FileInfosQuery,
  },
});

export const bloc2FifteenthQuery = qs.stringify({
  populate: {
    fichier_lettre_disparition_autre_parent_traduit: FileInfosQuery,
    fichier_conjoint_jugement_divorce: FileInfosQuery,
  },
});

export const bloc2SixteenthQuery = qs.stringify({
  populate: {
    fichier_jugement_droit_garde: FileInfosQuery,
    fichier_jugement_droit_garde_traduit: FileInfosQuery,
  },
});
export const bloc2SeventeenthQuery = qs.stringify({
  populate: {
    fichier_conjoint_titre_sejour: FileInfosQuery,
    infobulle_conjoint_fichier_titre_sejour: InfobulleQuery,
  },
});

export const bloc2FormQuery = qs.stringify({
  populate: {
    fields: ['titre_principal'],
    bulle_alerte_principale: HelpBoxQuery,
    bulle_alerte_mariage_civils_seul: HelpBoxQuery,
    bulle_aide_age: HelpBoxQuery,
    bulle_aide_nationalite_fr: HelpBoxQuery,
    bulle_aide_nationalite_eu: HelpBoxQuery,
    bulle_aide_enfant_majeur: HelpBoxQuery,
  },
});

export const bloc2FormSecondQuery = qs.stringify({
  populate: {
    bulle_aide_enfant_vit_france: HelpBoxQuery,
    bulle_aide_enfant_francais: HelpBoxQuery,
    bulle_aide_enfant_eur: HelpBoxQuery,
  },
});

export const bloc2FormThridQuery = qs.stringify({
  populate: {
    bulle_aide_ineligible_conjoint_francais: HelpBoxQuery,
    champs_nationalite_enfant: TitleSubTitleQuery,
    complement_adresse: TitleSubTitleQuery,
    adresse_email: TitleSubTitleQuery,
    numero_telephone: TitleSubTitleQuery,
    principale_helpbox_top: HelpBoxQuery,
  },
});
