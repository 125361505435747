import * as yup from 'yup';

import { FormHelper } from 'helpers/app/FormHelper';
import { DemandeRfErrors } from 'models/types/cms/demandeRF/type';

export const getValidationSchema = (
  errorsRfCms: DemandeRfErrors,
  cmsContact: any,
  detailLimit: number,
) => {
  const formHelper = new FormHelper(errorsRfCms);

  return yup
    .object()
    .shape({
      civilite: yup.string().required(errorsRfCms?.champ_requis).defined(errorsRfCms?.champ_requis),
      prenom: yup.string().required(errorsRfCms?.champ_requis).defined(errorsRfCms?.champ_requis),
      nom: yup.string().required(errorsRfCms?.champ_requis).defined(errorsRfCms?.champ_requis),
      adresse_mail: formHelper.stringSchema(true).email(errorsRfCms?.erreur_email),
      detail: yup
        .string()
        .notRequired()
        //.required(errorsRfCms?.champ_requis)
        .max(detailLimit, cmsContact?.formulaire_contact.champ_detail_valeur_par_defaut) // A corriger avec longueur max
        //.defined(errorsRfCms?.champ_requis)
        .default(''),
      numero_etranger: formHelper.stringSchema(),
      objet_demande: formHelper.stringSchema(true),
      objet_demande_detail: formHelper.stringSchema(true),
      locale: yup.string().required(errorsRfCms?.champ_requis).defined(errorsRfCms?.champ_requis),
    }) // Pas de honeypot pour un formulaire de contact ?
    .defined();
};
