import { cmsEnrichedText } from 'components/Atoms/HtmlParsers/PageContentHtmlParser';
import { CHOIX } from 'constants/global';
import { RFNG_CAT_MAPPING } from 'constants/upload';
import { FILIATION, LIEN_AUTRE_PARENT, RESIDENCE_LIEU } from 'constants/regroupementFamiliale';
import { GENRE_API } from 'constants/genre';
import { DemandeRfBloc2, DemandeRfGeneral } from 'models/types/cms/demandeRF/type';
import { FilesField, UploadFilesParameters } from 'models/types/app/uploadFiles';

export default class enfantMapping {
  static getGeneralMapping() {
    function getFormFields(
      cms: {
        bloc2Cms: DemandeRfBloc2;
        globalCms: DemandeRfGeneral;
      },
      formControls: {
        setValue: any;
        getValues: any;
        control: any;
        trigger: any;
      },
      lang?: string,
    ) {
      const { bloc2Cms, globalCms } = cms;
      const { setValue, control, trigger, getValues } = formControls;
      return {
        rf_demande: {
          name: 'rf_demande',
          label: bloc2Cms.sous_titre_souhait_rf_enfant,
          control: control,
          options: [
            {
              label: globalCms.oui,
              nativeInputProps: {
                value: CHOIX.OUI,
                checked: control._formValues.rf_demande === CHOIX.OUI,
                onChange: () => {
                  setValue('rf_demande', CHOIX.OUI);
                  trigger('rf_demande');
                },
              },
            },
            {
              label: globalCms.non,
              nativeInputProps: {
                value: CHOIX.NON,
                checked: control._formValues.rf_demande === CHOIX.NON,
                onChange: () => {
                  setValue('rf_demande', CHOIX.NON);
                  trigger('rf_demande');
                },
              },
            },
          ],
        },
        enfant_etranger: {
          name: 'enfant_etranger',
          label: bloc2Cms.label_lieu_residence,
          control: control,
          options: [
            {
              label: globalCms.a_letranger,
              nativeInputProps: {
                value: RESIDENCE_LIEU.ETRANGER,
                checked: control._formValues.enfant_etranger === RESIDENCE_LIEU.ETRANGER,
                onChange: () => {
                  setValue('enfant_etranger', RESIDENCE_LIEU.ETRANGER);
                  trigger('enfant_etranger');
                },
              },
            },
            {
              label: globalCms.en_france,
              nativeInputProps: {
                value: RESIDENCE_LIEU.FRANCE,
                checked: control._formValues.enfant_etranger === RESIDENCE_LIEU.FRANCE,
                onChange: () => {
                  setValue('enfant_etranger', RESIDENCE_LIEU.FRANCE);
                  trigger('enfant_etranger');
                },
              },
            },
          ],
        },
        nom_naissance_enfant: {
          name: 'nom_naissance_enfant',
          control: control,
          label: bloc2Cms.nom_naissance_conjoint,
          hintText: bloc2Cms.regardez_titre_identite_enfant,
          required: true,
        },
        nom_usage_enfant: {
          name: 'nom_usage_enfant',
          control: control,
          label: bloc2Cms.nom_usage_conjoint,
          hintText: globalCms.info_non_obligatoire,
          required: false,
        },
        prenoms_enfant: {
          name: 'prenoms_enfant',
          control: control,
          label: bloc2Cms.prenom_conjoint,
          hintText: bloc2Cms.regardez_titre_identite_enfant,
          required: true,
        },
        sexe_enfant: {
          name: 'sexe_enfant',
          label: bloc2Cms.label_sexe,
          orientation: 'horizontal',
          control: control,
          options: [
            {
              label: globalCms.femme,
              nativeInputProps: {
                value: GENRE_API.FEMME,
                checked: control._formValues.sexe_enfant === GENRE_API.FEMME,
                onChange: () => {
                  setValue('sexe_enfant', GENRE_API.FEMME);
                  trigger('sexe_enfant');
                },
              },
            },
            {
              label: globalCms.homme,
              nativeInputProps: {
                value: GENRE_API.HOMME,
                checked: control._formValues.sexe_enfant === GENRE_API.HOMME,
                onChange: () => {
                  setValue('sexe_enfant', GENRE_API.HOMME);
                  trigger('sexe_enfant');
                },
              },
            },
          ],
        },
        date_naissance_enfant: {
          control: control,
          name: 'date_naissance_enfant',
          label: bloc2Cms.date_naissance_enfant,
          hintText: bloc2Cms.regardez_titre_identite_enfant,
          required: true,
        },
        pays_naissance_enfant: {
          name: 'pays_naissance_enfant',
          control: control,
          label: bloc2Cms.pays_naissance_enfant,
          required: true,
          lang: lang,
        },
        ville_naissance_enfant: {
          name: 'ville_naissance_enfant',
          control: control,
          label: bloc2Cms.ville_naissance_enfant,
          required: true,
        },
        nationalite_enfant: {
          name: 'nationalite_enfant',
          control: control,
          label: bloc2Cms.champs_nationalite_enfant.titre,
          hintText: bloc2Cms.champs_nationalite_enfant.sous_titre,
          required: true,
          lang: lang,
        },
        acte_naissance_enfant_traduit: {
          name: 'acte_naissance_enfant_traduit',
          key: 'acte_naissance_enfant_traduit',
          options: [
            {
              label: globalCms.document_langue_origine,
              id: 'acte_naissance_enfant_traduit',
              hintText: globalCms.sous_titre_case_document_langue_origine,
              nativeInputProps: {
                name: 'acte_naissance_enfant_traduit',
                onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                  setValue('acte_naissance_enfant_traduit', e.target.checked);
                  trigger();
                },
                checked: getValues('acte_naissance_enfant_traduit'),
              },
            },
          ],
          control: control,
        },
        filiation: {
          name: 'filiation',
          label: bloc2Cms.titre_champ_selecteur_lien_avec_enfant,
          control: control,
          options: [
            {
              label: globalCms.option_filiation_naturelle,
              value: FILIATION.LEGITIME,
            },
            {
              label: globalCms.option_filiation_adoption,
              value: FILIATION.ADOPTIVE,
            },
            {
              label: globalCms.option_filiation_kafala,
              value: FILIATION.KAFALA,
            },
          ],
        },
        kafala_algerien_traduit: {
          name: 'kafala_algerien_traduit',
          key: 'kafala_algerien_traduit',
          options: [
            {
              label: globalCms.document_langue_origine,
              id: 'kafala_algerien_traduit',
              hintText: globalCms.sous_titre_case_document_langue_origine,
              nativeInputProps: {
                name: 'kafala_algerien_traduit',
                onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                  setValue('kafala_algerien_traduit', e.target.checked);
                  trigger();
                },
                checked: getValues('kafala_algerien_traduit'),
              },
            },
          ],
          control: control,
        },
        lien_second_parent: {
          name: 'lien_second_parent',
          label: bloc2Cms.titre_selecteur_lien_autre_parent,
          control: control,
          options: [
            {
              label: globalCms.option_second_parent_conjoint_actuel,
              value: LIEN_AUTRE_PARENT.CONJOINT_ACTUEL,
            },
            {
              label: globalCms.option_second_parent_divorce,
              value: LIEN_AUTRE_PARENT.DIVORCE,
            },
            {
              label: globalCms.option_second_parent_separe,
              value: LIEN_AUTRE_PARENT.SEPARE,
            },
            {
              label: globalCms.option_second_parent_deces,
              value: LIEN_AUTRE_PARENT.DECEDE,
            },
            {
              label: globalCms.option_second_parent_disparu,
              value: LIEN_AUTRE_PARENT.DISPARU,
            },
            {
              label: globalCms.option_second_parent_pas_autre,
              value: LIEN_AUTRE_PARENT.AUCUN,
            },
          ],
        },
        jugement_adoption_traduit: {
          name: 'jugement_adoption_traduit',
          key: 'jugement_adoption_traduit',
          options: [
            {
              label: globalCms.document_langue_origine,
              id: 'jugement_adoption_traduit',
              hintText: globalCms.sous_titre_case_document_langue_origine,
              nativeInputProps: {
                name: 'jugement_adoption_traduit',
                onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                  setValue('jugement_adoption_traduit', e.target.checked);
                  trigger();
                },
                checked: getValues('jugement_adoption_traduit'),
              },
            },
          ],
          control: control,
        },
        jugement_droit_garde_enfants_traduit: {
          name: 'jugement_droit_garde_enfants_traduit',
          key: 'jugement_droit_garde_enfants_traduit',
          options: [
            {
              label: globalCms.document_langue_origine,
              id: 'jugement_droit_garde_enfants_traduit',
              hintText: globalCms.sous_titre_case_document_langue_origine,
              nativeInputProps: {
                name: 'jugement_droit_garde_enfants_traduit',
                onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                  setValue('jugement_droit_garde_enfants_traduit', e.target.checked);
                  trigger();
                },
                checked: getValues('jugement_droit_garde_enfants_traduit'),
              },
            },
          ],
          control: control,
        },
        autre_parent_autorisation_venue_france_traduit: {
          name: 'autre_parent_autorisation_venue_france_traduit',
          key: 'autre_parent_autorisation_venue_france_traduit',
          options: [
            {
              label: globalCms.document_langue_origine,
              id: 'autre_parent_autorisation_venue_france_traduit',
              hintText: globalCms.sous_titre_case_document_langue_origine,
              nativeInputProps: {
                name: 'autre_parent_autorisation_venue_france_traduit',
                onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                  setValue('autre_parent_autorisation_venue_france_traduit', e.target.checked);
                  trigger();
                },
                checked: getValues('autre_parent_autorisation_venue_france_traduit'),
              },
            },
          ],
          control: control,
        },
        acte_deces_traduit: {
          name: 'acte_deces_traduit',
          key: 'acte_deces_traduit',
          options: [
            {
              label: globalCms.document_langue_origine,
              id: 'acte_deces_traduit',
              hintText: globalCms.sous_titre_case_document_langue_origine,
              nativeInputProps: {
                name: 'acte_deces_traduit',
                onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                  setValue('acte_deces_traduit', e.target.checked);
                  trigger();
                },
                checked: getValues('acte_deces_traduit'),
              },
            },
          ],
          control: control,
        },
        declaration_disparition_autre_parent_traduit: {
          name: 'declaration_disparition_autre_parent_traduit',
          key: 'declaration_disparition_autre_parent_traduit',
          options: [
            {
              label: globalCms.document_langue_origine,
              id: 'declaration_disparition_autre_parent_traduit',
              hintText: globalCms.sous_titre_case_document_langue_origine,
              nativeInputProps: {
                name: 'declaration_disparition_autre_parent_traduit',
                onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                  setValue('declaration_disparition_autre_parent_traduit', e.target.checked);
                  trigger();
                },
                checked: getValues('declaration_disparition_autre_parent_traduit'),
              },
            },
          ],
          control: control,
        },
      };
    }

    function getFilesFields(
      bloc2Cms: DemandeRfBloc2,
      uploadFilesParameters: UploadFilesParameters,
      errorCms: any,
    ): FilesField {
      return {
        acte_naissance_enfant_files: {
          upload: {
            id: 'infos-acte-naissance-enfant',
            categorie: RFNG_CAT_MAPPING.ACTE_NAISSANCE_ENFANT,
            label: bloc2Cms.fichier_acte_naissance_enfant.titre_fichier ?? '',
            hint: bloc2Cms.fichier_acte_naissance_enfant.infos_fichier
              ? cmsEnrichedText(bloc2Cms.fichier_acte_naissance_enfant.infos_fichier)
              : '',
            state: 'default',
            stateRelatedMessage: errorCms.erreur_generique_fichier,
            required: true,
            ...uploadFilesParameters,
            name: 'acte_naissance_enfant_files',
            resource: 'enfant',
            resourceId: undefined,
            helpbox: {
              closable: false,
              description: bloc2Cms.fichier_acte_naissance_enfant.bulle_aide_fichier_contenu
                ? cmsEnrichedText(bloc2Cms.fichier_acte_naissance_enfant.bulle_aide_fichier_contenu)
                : '',
              severity: 'info',
              title: bloc2Cms.fichier_acte_naissance_enfant.bulle_aide_fichier_titre,
            },
            infobulleContent: bloc2Cms.infobulle_enfant_fichier_acte_naissance_fr,
            infobulleCustomModal: true,
          },
        },
        acte_naissance_enfant_traduit_files: {
          upload: {
            id: 'infos-acte-naissance-enfant-traduit',
            categorie: RFNG_CAT_MAPPING.ACTE_NAISSANCE_ENFANT_TRAD,
            label: bloc2Cms.fichier_acte_naissance_enfant_traduit.titre_fichier ?? '',
            hint: bloc2Cms.fichier_acte_naissance_enfant_traduit.infos_fichier
              ? cmsEnrichedText(bloc2Cms.fichier_acte_naissance_enfant_traduit.infos_fichier)
              : '',
            state: 'default',
            stateRelatedMessage: errorCms.erreur_generique_fichier,
            required: true,
            ...uploadFilesParameters,
            name: 'acte_naissance_enfant_traduit_files',
            resource: 'enfant',
            resourceId: undefined,
            helpbox: {
              closable: false,
              description: bloc2Cms.fichier_acte_naissance_enfant_traduit.bulle_aide_fichier_contenu
                ? cmsEnrichedText(
                    bloc2Cms.fichier_acte_naissance_enfant_traduit.bulle_aide_fichier_contenu,
                  )
                : '',
              severity: 'info',
              title: bloc2Cms.fichier_acte_naissance_enfant_traduit.bulle_aide_fichier_titre,
            },
          },
        },
        kafala_algerien_files: {
          upload: {
            id: 'infos-kafala-algerien',
            categorie: RFNG_CAT_MAPPING.KAFALA_ALGERIEN,
            label: bloc2Cms.fichier_kafala_enfant.titre_fichier ?? '',
            hint: bloc2Cms.fichier_kafala_enfant.infos_fichier
              ? cmsEnrichedText(bloc2Cms.fichier_kafala_enfant.infos_fichier)
              : '',

            state: 'default',
            stateRelatedMessage: errorCms.erreur_generique_fichier,
            required: true,
            ...uploadFilesParameters,
            name: 'kafala_algerien_files',
            resource: 'enfant',
            resourceId: undefined,
            helpbox: {
              closable: false,
              description: bloc2Cms.fichier_kafala_enfant.bulle_aide_fichier_contenu
                ? cmsEnrichedText(bloc2Cms.fichier_kafala_enfant.bulle_aide_fichier_contenu)
                : '',
              severity: 'info',
              title: bloc2Cms.fichier_kafala_enfant.bulle_aide_fichier_titre,
            },
            infobulleContent: bloc2Cms.infobulle_enfant_fichier_kafala_fr,
            infobulleCustomModal: true,
          },
        },
        kafala_algerien_traduit_files: {
          upload: {
            id: 'infos-kafala-algerien-traduit',
            categorie: RFNG_CAT_MAPPING.KAFALA_ALGERIEN_TRAD,
            label: bloc2Cms.fichier_kafala_enfant_traduit.titre_fichier ?? '',
            hint: bloc2Cms.fichier_kafala_enfant_traduit.infos_fichier
              ? cmsEnrichedText(bloc2Cms.fichier_kafala_enfant_traduit.infos_fichier)
              : '',
            state: 'default',
            stateRelatedMessage: errorCms.erreur_generique_fichier,
            required: true,
            ...uploadFilesParameters,
            name: 'kafala_algerien_traduit_files',
            resource: 'enfant',
            resourceId: undefined,
            helpbox: {
              closable: false,
              description: bloc2Cms.fichier_kafala_enfant_traduit.bulle_aide_fichier_contenu
                ? cmsEnrichedText(bloc2Cms.fichier_kafala_enfant_traduit.bulle_aide_fichier_contenu)
                : '',
              severity: 'info',
              title: bloc2Cms.fichier_kafala_enfant_traduit.bulle_aide_fichier_titre,
            },
          },
        },
        jugement_adoption_files: {
          upload: {
            id: 'infos-jugement-adoption',
            categorie: RFNG_CAT_MAPPING.JUGEMENT_ADOPTION,
            label: bloc2Cms.fichier_jugement_adoption.titre_fichier ?? '',
            hint: bloc2Cms.fichier_jugement_adoption.infos_fichier
              ? cmsEnrichedText(bloc2Cms.fichier_jugement_adoption.infos_fichier)
              : '',

            state: 'default',
            stateRelatedMessage: errorCms.erreur_generique_fichier,
            required: true,
            ...uploadFilesParameters,
            name: 'jugement_adoption_files',
            resource: 'enfant',
            resourceId: undefined,
            helpbox: {
              closable: false,
              description: bloc2Cms.fichier_jugement_adoption.bulle_aide_fichier_contenu
                ? cmsEnrichedText(bloc2Cms.fichier_jugement_adoption.bulle_aide_fichier_contenu)
                : '',
              severity: 'info',
              title: bloc2Cms.fichier_jugement_adoption.bulle_aide_fichier_titre,
            },
            infobulleContent: bloc2Cms.infobulle_enfant_fichier_adoption_original,
            infobulleCustomModal: true,
          },
        },
        jugement_adoption_traduit_files: {
          upload: {
            id: 'infos-jugement-adoption-traduit',
            categorie: RFNG_CAT_MAPPING.JUGEMENT_ADOPTION_TRAD,
            label: bloc2Cms.fichier_jugement_adoption_enfant_traduit.titre_fichier ?? '',
            hint: bloc2Cms.fichier_jugement_adoption_enfant_traduit.infos_fichier
              ? cmsEnrichedText(bloc2Cms.fichier_jugement_adoption_enfant_traduit.infos_fichier)
              : '',
            state: 'default',
            stateRelatedMessage: errorCms.erreur_generique_fichier,
            required: true,
            ...uploadFilesParameters,
            name: 'jugement_adoption_traduit_files',
            resource: 'enfant',
            resourceId: undefined,
            helpbox: {
              closable: false,
              description: bloc2Cms.fichier_jugement_adoption_enfant_traduit
                .bulle_aide_fichier_contenu
                ? cmsEnrichedText(
                    bloc2Cms.fichier_jugement_adoption_enfant_traduit.bulle_aide_fichier_contenu,
                  )
                : '',
              severity: 'info',
              title: bloc2Cms.fichier_jugement_adoption_enfant_traduit.bulle_aide_fichier_titre,
            },
          },
        },
        livret_famille_files: {
          upload: {
            id: 'infos-livret-famille',
            categorie: RFNG_CAT_MAPPING.LIVRET_FAMILLE,
            label: bloc2Cms.fichier_livret_famille.titre_fichier ?? '',
            hint: bloc2Cms.fichier_livret_famille.infos_fichier
              ? cmsEnrichedText(bloc2Cms.fichier_livret_famille.infos_fichier)
              : '',
            state: 'default',
            stateRelatedMessage: errorCms.erreur_generique_fichier,
            required: true,
            ...uploadFilesParameters,
            name: 'livret_famille_files',
            resource: 'enfant',
            resourceId: undefined,
            helpbox: {
              closable: false,
              description: bloc2Cms.fichier_livret_famille.bulle_aide_fichier_contenu
                ? cmsEnrichedText(bloc2Cms.fichier_livret_famille.bulle_aide_fichier_contenu)
                : '',
              severity: 'info',
              title: bloc2Cms.fichier_livret_famille.bulle_aide_fichier_titre,
            },
            infobulleContent: bloc2Cms.infobulle_enfant_fichier_livret_famille,
            infobulleCustomModal: true,
          },
        },
        jugement_droit_garde_enfants_files: {
          upload: {
            id: 'infos-droit-garde-enfants',
            categorie: RFNG_CAT_MAPPING.JUGEMENT_AUTORITE_PARENTALE,
            label: bloc2Cms.fichier_jugement_droit_garde.titre_fichier ?? '',
            hint: bloc2Cms.fichier_jugement_droit_garde.infos_fichier
              ? cmsEnrichedText(bloc2Cms.fichier_jugement_droit_garde.infos_fichier)
              : '',
            state: 'default',
            stateRelatedMessage: errorCms.erreur_generique_fichier,
            required: true,
            ...uploadFilesParameters,
            name: 'jugement_droit_garde_enfants_files',
            resource: 'enfant',
            resourceId: undefined,
            helpbox: {
              closable: false,
              description: bloc2Cms.fichier_jugement_droit_garde.bulle_aide_fichier_contenu
                ? cmsEnrichedText(bloc2Cms.fichier_jugement_droit_garde.bulle_aide_fichier_contenu)
                : '',
              severity: 'info',
              title: bloc2Cms.fichier_jugement_droit_garde.bulle_aide_fichier_titre,
            },
            infobulleContent:
              bloc2Cms.infobulle_enfant_fichier_jugement_autorite_parentale_original,
            infobulleCustomModal: true,
          },
        },
        jugement_droit_garde_enfants_traduit_files: {
          upload: {
            id: 'infos-droit-garde-enfants-traduit',
            categorie: RFNG_CAT_MAPPING.JUGEMENT_AUTORITE_PARENTALE_TRAD,
            label: bloc2Cms.fichier_jugement_droit_garde_traduit.titre_fichier ?? '',
            hint: bloc2Cms.fichier_jugement_droit_garde_traduit.infos_fichier
              ? cmsEnrichedText(bloc2Cms.fichier_jugement_droit_garde_traduit.infos_fichier)
              : '',
            state: 'default',
            stateRelatedMessage: errorCms.erreur_generique_fichier,
            required: true,
            ...uploadFilesParameters,
            name: 'jugement_droit_garde_traduit_files',
            resource: 'enfant',
            resourceId: undefined,
            helpbox: {
              closable: false,
              description: bloc2Cms.fichier_jugement_droit_garde_traduit.bulle_aide_fichier_contenu
                ? cmsEnrichedText(
                    bloc2Cms.fichier_jugement_droit_garde_traduit.bulle_aide_fichier_contenu,
                  )
                : '',
              severity: 'info',
              title: bloc2Cms.fichier_jugement_droit_garde_traduit.bulle_aide_fichier_titre,
            },
          },
        },
        autre_parent_autorisation_venue_france_files: {
          upload: {
            id: 'infos-autre-parent-autorisation-venue-france',
            categorie: RFNG_CAT_MAPPING.AUTRE_PARENT_AUTORISATION_VENUE_FRANCE,
            label: bloc2Cms.fichier_lettre_autorisation_autre_parent.titre_fichier ?? '',
            hint: bloc2Cms.fichier_lettre_autorisation_autre_parent.infos_fichier
              ? cmsEnrichedText(bloc2Cms.fichier_lettre_autorisation_autre_parent.infos_fichier)
              : '',
            state: 'default',
            stateRelatedMessage: errorCms.erreur_generique_fichier,
            required: true,
            ...uploadFilesParameters,
            name: 'autre_parent_autorisation_venue_france_files',
            resource: 'enfant',
            resourceId: undefined,
            helpbox: {
              closable: false,
              description: bloc2Cms.fichier_lettre_autorisation_autre_parent
                .bulle_aide_fichier_contenu
                ? cmsEnrichedText(
                    bloc2Cms.fichier_lettre_autorisation_autre_parent.bulle_aide_fichier_contenu,
                  )
                : '',
              severity: 'info',
              title: bloc2Cms.fichier_lettre_autorisation_autre_parent.bulle_aide_fichier_titre,
            },
            infobulleContent: bloc2Cms.infobulle_enfant_fichier_autorisation_autre_parent_fr,
            infobulleCustomModal: true,
          },
        },
        autre_parent_autorisation_venue_france_traduit_files: {
          upload: {
            id: 'infos-autre-parent-autorisation-venue-france-traduit',
            categorie: RFNG_CAT_MAPPING.AUTRE_PARENT_AUTORISATION_VENUE_FRANCE_TRAD,
            label: bloc2Cms.fichier_lettre_autorisation_autre_parent_traduit.titre_fichier ?? '',
            hint: bloc2Cms.fichier_lettre_autorisation_autre_parent_traduit.infos_fichier
              ? cmsEnrichedText(
                  bloc2Cms.fichier_lettre_autorisation_autre_parent_traduit.infos_fichier,
                )
              : '',
            state: 'default',
            stateRelatedMessage: errorCms.erreur_generique_fichier,
            required: true,
            ...uploadFilesParameters,
            name: 'autre_parent_autorisation_venue_france_traduit_files',
            resource: 'enfant',
            resourceId: undefined,
            helpbox: {
              closable: false,
              description: bloc2Cms.fichier_lettre_autorisation_autre_parent_traduit
                .bulle_aide_fichier_contenu
                ? cmsEnrichedText(
                    bloc2Cms.fichier_lettre_autorisation_autre_parent_traduit
                      .bulle_aide_fichier_contenu,
                  )
                : '',
              severity: 'info',
              title:
                bloc2Cms.fichier_lettre_autorisation_autre_parent_traduit.bulle_aide_fichier_titre,
            },
          },
        },
        acte_deces_files: {
          upload: {
            id: 'infos-acte-deces',
            categorie: RFNG_CAT_MAPPING.ACTE_DECES_AUTRE_PARENT,
            label: bloc2Cms.fichier_acte_deces_autre_parent.titre_fichier ?? '',
            hint: bloc2Cms.fichier_acte_deces_autre_parent.infos_fichier
              ? cmsEnrichedText(bloc2Cms.fichier_acte_deces_autre_parent.infos_fichier)
              : '',
            state: 'default',
            stateRelatedMessage: errorCms.erreur_generique_fichier,
            required: true,
            ...uploadFilesParameters,
            name: 'acte_deces_files',
            resource: 'enfant',
            resourceId: undefined,
            helpbox: {
              closable: false,
              description: bloc2Cms.fichier_acte_deces_autre_parent.bulle_aide_fichier_contenu
                ? cmsEnrichedText(
                    bloc2Cms.fichier_acte_deces_autre_parent.bulle_aide_fichier_contenu,
                  )
                : '',
              severity: 'info',
              title: bloc2Cms.fichier_acte_deces_autre_parent.bulle_aide_fichier_titre,
            },
            infobulleContent: bloc2Cms.infobulle_enfant_fichier_acte_deces_autre_parent_original,
            infobulleCustomModal: true,
          },
        },
        acte_deces_traduit_files: {
          upload: {
            id: 'infos-acte-deces-traduit',
            categorie: RFNG_CAT_MAPPING.ACTE_DECES_AUTRE_PARENT_TRAD,
            label: bloc2Cms.fichier_acte_deces_autre_parent_traduit.titre_fichier ?? '',
            hint: bloc2Cms.fichier_acte_deces_autre_parent_traduit.infos_fichier
              ? cmsEnrichedText(bloc2Cms.fichier_acte_deces_autre_parent_traduit.infos_fichier)
              : '',
            state: 'default',
            stateRelatedMessage: errorCms.erreur_generique_fichier,
            required: true,
            ...uploadFilesParameters,
            name: 'acte_deces_traduit_files',
            resource: 'enfant',
            resourceId: undefined,
            helpbox: {
              closable: false,
              description: bloc2Cms.fichier_acte_deces_autre_parent_traduit
                .bulle_aide_fichier_contenu
                ? cmsEnrichedText(
                    bloc2Cms.fichier_acte_deces_autre_parent_traduit.bulle_aide_fichier_contenu,
                  )
                : '',
              severity: 'info',
              title: bloc2Cms.fichier_acte_deces_autre_parent_traduit.bulle_aide_fichier_titre,
            },
          },
        },
        declaration_disparition_autre_parent_files: {
          upload: {
            id: 'infos-declaration-disparition-autre-parent',
            categorie: RFNG_CAT_MAPPING.DECLARATION_ABANDON_ENFANT,
            label: bloc2Cms.fichier_lettre_disparition_autre_parent.titre_fichier ?? '',
            hint: bloc2Cms.fichier_lettre_disparition_autre_parent.infos_fichier
              ? cmsEnrichedText(bloc2Cms.fichier_lettre_disparition_autre_parent.infos_fichier)
              : '',
            state: 'default',
            stateRelatedMessage: errorCms.erreur_generique_fichier,
            required: true,
            ...uploadFilesParameters,
            name: 'declaration_disparition_autre_parent_files',
            resource: 'enfant',
            resourceId: undefined,
            helpbox: {
              closable: false,
              description: bloc2Cms.fichier_lettre_disparition_autre_parent
                .bulle_aide_fichier_contenu
                ? cmsEnrichedText(
                    bloc2Cms.fichier_lettre_disparition_autre_parent.bulle_aide_fichier_contenu,
                  )
                : '',
              severity: 'info',
              title: bloc2Cms.fichier_lettre_disparition_autre_parent.bulle_aide_fichier_titre,
            },
            infobulleContent: bloc2Cms.infobulle_fichier_declaration_abandon_ou_disparu_tribunal,
            infobulleCustomModal: true,
          },
        },
        declaration_disparition_autre_parent_traduit_files: {
          upload: {
            id: 'infos-declaration-disparition-autre-parent-traduit',
            categorie: RFNG_CAT_MAPPING.DECLARATION_ABANDON_ENFANT_TRAD,
            label: bloc2Cms.fichier_lettre_disparition_autre_parent_traduit.titre_fichier ?? '',
            hint: bloc2Cms.fichier_lettre_disparition_autre_parent_traduit.infos_fichier
              ? cmsEnrichedText(
                  bloc2Cms.fichier_lettre_disparition_autre_parent_traduit.infos_fichier,
                )
              : '',
            state: 'default',
            stateRelatedMessage: errorCms.erreur_generique_fichier,
            required: true,
            ...uploadFilesParameters,
            name: 'declaration_disparition_autre_parent_traduit_files',
            resource: 'enfant',
            resourceId: undefined,
            helpbox: {
              closable: false,
              description: bloc2Cms.fichier_lettre_disparition_autre_parent_traduit
                .bulle_aide_fichier_contenu
                ? cmsEnrichedText(
                    bloc2Cms.fichier_lettre_disparition_autre_parent_traduit
                      .bulle_aide_fichier_contenu,
                  )
                : '',
              severity: 'info',
              title:
                bloc2Cms.fichier_lettre_disparition_autre_parent_traduit.bulle_aide_fichier_titre,
            },
          },
        },
      };
    }

    return { getFormFields, getFilesFields };
  }
}
