import React from 'react';

import { ProcedureDemandeType, TableauDeBordProcedure } from 'models/types/api/dashboard';
import { ProceduresCmsComponent } from 'models/types/cms/dashboard/type';
import DemandeRFProcedure from 'components/Molecules/UserProcedures/components/DemandeRFProcedure/DemandeRFProcedure';
import SuiviRFProcedure from 'components/Molecules/UserProcedures/components/SuiviRFProcedure/SuiviRFProcedure';
import AsileProcedure from 'components/Molecules/UserProcedures/components/AsileProcedure/AsileProcedure';

interface Props {
  cmsComponent: ProceduresCmsComponent;
  cmsRFTraductionAPI: any;
  tableauDeBordProcedureList: any[];
}

const ProcedureList: React.FC<Props> = ({
  cmsComponent,
  cmsRFTraductionAPI,
  tableauDeBordProcedureList,
}): React.ReactNode => {
  const getProcedureBloc = (procedure: TableauDeBordProcedure) => {
    switch (procedure.type) {
      case ProcedureDemandeType.ASILE:
        return (
          <AsileProcedure
            key="DemandeAsile"
            cmsComponent={cmsComponent.procedure_asile}
            derniereDemandeAsile={procedure}
          />
        );
      case ProcedureDemandeType.REGROUPEMENT_FAMILIAL:
        return (
          cmsRFTraductionAPI && (
            <SuiviRFProcedure
              key={procedure.path}
              cmsComponent={cmsComponent.procedure_regroupement_familial}
              demandeRF={procedure}
              cmsTraductionApi={cmsRFTraductionAPI}
            />
          )
        );
      // case ProcedureDemandeType.RETOUR:
      //   return (
      // <RetourProcedure
      //   key="RRIRetour"
      //   cmsComponent={cmsComponent.procedure_retour}
      //   procedureRetour={procedure}
      // />
      // );
      // case ProcedureDemandeType.REINSERTION:
      //   return (
      // <ReinsertionProcedure
      //   key={procedure.path}
      //   reinsertion={procedure}
      //   cmsComponent={cmsComponent.procedure_reinsertion}
      // />
      // );
      case ProcedureDemandeType.DEMANDE_RF:
        return (
          cmsRFTraductionAPI && (
            <DemandeRFProcedure
              key={procedure.id}
              cmsComponent={cmsComponent.procedure_regroupement_familial}
              demandeRF={procedure}
              cmsTraductionApi={cmsRFTraductionAPI}
            />
          )
        );
    }
  };

  return <>{tableauDeBordProcedureList.map(getProcedureBloc)}</>;
};

export default ProcedureList;
