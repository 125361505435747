import { useIdleTimer } from 'react-idle-timer';
import { useState } from 'react';

type IdleProps = {
  onIdle: () => void;
  idleTime: number;
  disabled?: boolean;
};

function useIdle({ onIdle, idleTime, disabled = true }: IdleProps) {
  const [isIdle, setIsIdle] = useState<boolean | null>(null);

  //handles what happens when the user is idle
  const handleOnIdle = () => {
    setIsIdle(true); //set the state to true
    onIdle(); //then call onIdle function
  };

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * idleTime,
    onIdle: handleOnIdle,
    debounce: 500,
    disabled: disabled,
  });
  return {
    getRemainingTime,
    getLastActiveTime,
    isIdle,
  };
}

export default useIdle;
