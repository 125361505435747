import React, { FC } from 'react';
import { TileProps } from '@codegouvfr/react-dsfr/Tile';
import { Highlight } from '@codegouvfr/react-dsfr/Highlight';

type ProcedureTileProps = TileProps & {
  badgeText?: string;
  badgeColor?: 'success' | 'info' | 'warning' | 'error';
  highlightContent?: string | React.ReactNode;
};

const ProcedureTile: FC<ProcedureTileProps> = ({
  imageUrl,
  title,
  desc,
  badgeText,
  badgeColor,
  highlightContent,
  linkProps,
}) => {
  return (
    <div className="procedure-tile fr-mt-3w">
      <div className="fr-tile fr-tile--sm fr-tile--horizontal fr-enlarge-link" id="tile-6661">
        <div className="fr-tile__body">
          <div className="fr-tile__content">
            <h3 className="fr-tile__title">
              <a href={linkProps ? linkProps.href : '#'}>{title}</a>
            </h3>
            {desc && <p className="fr-tile__detail">{desc}</p>}
            {badgeText && (
              <div className="fr-tile__start">
                <p className={`fr-badge fr-badge--sm fr-badge--${badgeColor}`}>{badgeText}</p>
              </div>
            )}
            {highlightContent && (
              <div className="fr-tile__detail">
                <Highlight>{highlightContent}</Highlight>
              </div>
            )}
          </div>
        </div>
        {imageUrl && (
          <div className="fr-tile__img">
            <img className="fr-responsive-img" src={imageUrl} alt="" data-fr-js-ratio="true" />
          </div>
        )}
      </div>
    </div>
  );
};

export default ProcedureTile;
