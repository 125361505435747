import qs from 'qs';
import { TitleSubTitleQuery } from '../common.query';

export const utilitaireQuery = qs.stringify({
  populate: {
    fields: ['carte_de_sejour_temporaire'],
    adresse_postale: TitleSubTitleQuery,
    complement_adresse: TitleSubTitleQuery,
  },
});
