import { FC } from 'react';
import { Control, Controller } from 'react-hook-form';
import Input, { InputProps } from '@codegouvfr/react-dsfr/Input';

import { computeFieldStatus } from 'helpers/app/fieldsHelper';
//import Infobulle from '../Infobulle/Infobulle';
import Infobulle from 'components/Molecules/Infobulle/Infobulle';
import { WidgetGenericObject } from 'models/types/cms/widgetPageContenu/types';

export type CommonInputProps = InputProps & {
  name: string;
  control: Control<any>;
  defaultValue: string;
  id: string;
  required?: boolean;
  infobulle?: WidgetGenericObject[] | null;
  lang?: string;
};

const CommonInput: FC<CommonInputProps> = props => {
  return (
    <Controller
      name={props.name}
      control={props.control}
      defaultValue={props.defaultValue}
      render={({
        field: { onChange, onBlur, value, name, ref },
        fieldState: { isTouched, isDirty, error },
      }) => {
        return (
          <Input
            hintText={props.hintText}
            disabled={props.disabled}
            state={computeFieldStatus(isTouched, isDirty, error)}
            stateRelatedMessage={error?.message}
            label={
              <>
                {props.label}
                {props.infobulle && props.lang !== undefined && (
                  <Infobulle
                    cmsContent={props.infobulle}
                    infobulleName={(Math.random() + 1).toString(36).substring(7)}
                    lang={props.lang}
                  />
                )}
              </>
            }
            nativeInputProps={{
              id: props.id,
              name: name,
              type: props?.nativeInputProps?.type || 'text',
              onChange: onChange,
              onBlur: onBlur,
              value: value || '',
              ref: ref,
              min: props?.nativeInputProps?.min,
              max: props?.nativeInputProps?.max,
              autoComplete: props?.nativeInputProps?.autoComplete,
            }}
          />
        );
      }}
    />
  );
};

export default CommonInput;
