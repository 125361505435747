import React, { useState } from 'react';

import Autocomplete from 'components/Atoms/Forms/Autocomplete/Autocomplete';

import { useOnSearch } from './AutocompleteContainer.utils';

interface AutocompleteContainerProps {
  label: string | React.ReactNode;
  hintText?: string;
  stateRelatedMessage?: string;
  state: string;
  name: string;
  control: any;
  payload: any;
  field: any;
  lang: string;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isOpen: boolean;
}

const AutocompleteContainer: React.FC<AutocompleteContainerProps> = ({
  label,
  hintText,
  stateRelatedMessage,
  state,
  name,
  control,
  payload,
  field,
  lang,
  setIsOpen,
  isOpen,
}) => {
  payload = payload.map((option: any) => {
    return {
      label: option.label[lang],
      value: option.value,
    };
  });

  const [val, setVal] = useState('');
  const [isUntouched, setIsUntouched] = useState(true);
  const options = useOnSearch(payload, val);

  const findLabel = (value?: string): string => {
    const option = payload.find((option: any) => option.value === value)?.label;
    if (option) {
      return option;
    }
    return '';
  };

  const onValueChange = (option: any, inputValue: string) => {
    if (isUntouched) {
      setIsUntouched(false);
    }

    setVal(inputValue);
    field.onChange(option.value);
  };

  if (val === '' && field.value && field.value !== '' && isUntouched) {
    setVal(findLabel(field.value));
  }

  return (
    <Autocomplete
      label={label}
      hintText={hintText}
      stateRelatedMessage={stateRelatedMessage}
      name={name}
      state={state}
      field={field}
      control={control}
      onValueChange={onValueChange}
      labelIsDisplayedValue={true}
      value={val}
      options={options}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    />
  );
};

export default AutocompleteContainer;
