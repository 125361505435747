import React from 'react';

import { isFeatureActive } from 'services/featureToggling/isFeatureActive';
import { Features } from 'services/featureToggling/features';
import { RFDemandeSummary } from 'models/types/api/rf';
import { RFCmsTraductionApi } from 'models/types/cms/rf/types';
import ProcedureTile from 'components/Molecules/ProcedureTile/ProcedureTile';
import { ProcedureRFCmsComponent } from 'models/types/cms/dashboard/type';
import { getStatus } from 'components/Molecules/UserProcedures/UserProcedures.helpers';

interface Props {
  cmsComponent: ProcedureRFCmsComponent;
  demandeRF: RFDemandeSummary;
  cmsTraductionApi: RFCmsTraductionApi;
}

const SuiviRFProcedure: React.FC<Props> = ({ cmsComponent, demandeRF, cmsTraductionApi }) => {
  if (!demandeRF.clefStatut && !demandeRF.statut) return null;

  return (
    <>
      {isFeatureActive(Features.REGROUPEMENT_FAMILIAL) && (
        <>
          <ProcedureTile
            imageUrl="/img/dashboard/vignette-rf.png"
            title={cmsTraductionApi.demande_rf_en_cours}
            {...getStatus(demandeRF, cmsTraductionApi)}
            linkProps={{
              href: demandeRF.path,
            }}
            highlightContent={
              <div className="numero-dossier-highlight">
                <span className="numero-dossier-highlight-title">
                  {cmsComponent.numero_dossier}&nbsp;:
                </span>
                <br />
                <span className="className='numero-dossier-highlight-number'">
                  {demandeRF.numeroDossier}
                </span>
              </div>
            }
          />
        </>
      )}
    </>
  );
};

export default SuiviRFProcedure;
