import React, { useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { createModal } from '@codegouvfr/react-dsfr/Modal';

import { useGetDashboardCmsQuery } from 'redux/rtk/cms/cmsDashboard';
import { ERROR_SERVICE } from 'routes/path';
import useIdle from 'services/useIdleTimer';

const timeoutModal = createModal({
  id: 'timeout-modal',
  isOpenedByDefault: false,
});

const CMS_TERM_REPLACED_BY_TIME = '((XX))';

type Props = {
  lang: string;
};
const Timeout: React.FC<Props> = ({ lang }) => {
  const [showModal, setShowModal] = useState(false);
  const [remainingTime, setRemainingTime] = useState(0);
  const auth = useAuth();
  const { data: dashboardCms, isLoading: isDashboardCmsLoading } = useGetDashboardCmsQuery({
    locale: lang !== '' ? lang : 'fr',
  });

  const handleIdle = () => {
    setShowModal(true);
    setRemainingTime(45); //temps restant en secondes
  };

  const { isIdle } = useIdle({
    onIdle: handleIdle,
    idleTime: 60, // 0.3 mn => 18s, 60 mn
    disabled: !auth.isAuthenticated,
  });

  useEffect(() => {
    return auth.events.addAccessTokenExpiring(() => {
      /** se déclenche 1 mn avant token expired */
      void auth.signoutRedirect({
        post_logout_redirect_uri: window.location.origin + ERROR_SERVICE,
      });
    });
  }, [auth.events]);

  useEffect(() => {
    let interval: any = undefined;
    if (isIdle && showModal) {
      interval = setInterval(() => {
        setRemainingTime(
          prevRemainingTime => (prevRemainingTime > 0 ? prevRemainingTime - 1 : 0), //réduction d'1
        );
      }, 1000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [isIdle, showModal]);

  useEffect(() => {
    if (remainingTime === 0 && showModal) {
      setShowModal(false);
      void auth.signoutRedirect({
        post_logout_redirect_uri: window.location.origin + '/',
      });
    }
  }, [remainingTime, showModal]);

  useEffect(() => {
    if (isIdle && showModal) {
      timeoutModal.open();
    }
  }, [isIdle, showModal]);

  const handleLogOut = () => {
    setShowModal(false);
    timeoutModal.close();
    void auth.signoutRedirect({
      post_logout_redirect_uri: window.location.origin + '/',
    });
  };

  const handleStayLoggedIn = () => {
    auth.signinSilent();
    setShowModal(false);
    timeoutModal.close();
  };

  function millisToMinutesAndSeconds(millis: number) {
    const minutes = Math.floor(millis / 60000);
    const seconds = ((millis % 60000) / 1000).toFixed(0);
    return minutes + ':' + (Number(seconds) < 10 ? '0' : '') + seconds;
  }

  function addTimeInTextContent() {
    const content = dashboardCms?.data.attributes.contenu_modal_expiration_session;
    if (!content) return null;
    return content.replace(
      CMS_TERM_REPLACED_BY_TIME,
      millisToMinutesAndSeconds(remainingTime * 1000),
    );
  }

  if (isDashboardCmsLoading) return null;

  return (
    <>
      <timeoutModal.Component
        title={dashboardCms?.data.attributes.titre_modal_expiration_session}
        iconId="fr-icon-timer-line"
        buttons={[
          {
            doClosesModal: true,
            onClick: () => handleLogOut(),
            children: dashboardCms?.data.attributes.se_deconnecter_modal_expiration_session,
          },
          {
            iconId: 'ri-check-line',
            onClick: () => handleStayLoggedIn(),
            children: dashboardCms?.data.attributes.rester_connecte_modal_expiration_session,
          },
        ]}
      >
        <p>{addTimeInTextContent()}</p>
      </timeoutModal.Component>
    </>
  );
};

export default Timeout;
