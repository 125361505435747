import { buildRouteForNavigation, generateNavigationArray } from 'helpers/app/layoutHelper';
import { FC } from 'react';
import { useSelector } from 'react-redux';

import { getCurrentLocale } from 'redux/locale/selectors';
import { getFooter, getMenu, getPagesTitles } from 'redux/pages/selectors';
import { ACCESSIBILITY, CONTACT, COOKIE, MENTION_LEGALES, RGPD } from 'routes/path';

import './SiteMap.scss';
import AppBreadcrumb from 'components/Atoms/Breadcrumb/Breadcrumb';

const SiteMap: FC<any> = () => {
  const menuData = useSelector(getMenu);
  const footerData = useSelector(getFooter);
  const titleData = useSelector(getPagesTitles);

  const lang = useSelector(getCurrentLocale) as string;

  const LinksArray = [
    ...generateNavigationArray(
      { path: '', currentLanguage: lang },
      menuData ? menuData : undefined,
    ),
    {
      linkProps: {
        href: buildRouteForNavigation(lang, CONTACT),
        target: '_self',
      },
      text: footerData ? footerData.contact : '',
    },
    {
      linkProps: {
        href: buildRouteForNavigation(lang, MENTION_LEGALES),
        target: '_self',
      },
      text: footerData ? footerData.mentions_legales : '',
    },
    {
      linkProps: {
        href: buildRouteForNavigation(lang, ACCESSIBILITY),
        target: '_self',
      },
      text: titleData ? titleData.accessibilite : '',
    },
    {
      linkProps: {
        href: buildRouteForNavigation(lang, COOKIE),
        target: '_self',
      },
      text: footerData ? footerData.cookies : '',
    },
    {
      linkProps: {
        href: buildRouteForNavigation(lang, RGPD),
        target: '_self',
      },
      text: footerData ? footerData.politique_confidentialite : '',
    },
  ];
  const generateLink = (elements: any) => {
    return elements.map((el: any) => {
      return el.linkProps ? (
        <li>
          <a href={el.linkProps.href} target={el.linkProps.target}>
            {el.text}
          </a>
        </li>
      ) : (
        <li>
          {el.text}
          <ul>{generateLink(el.menuLinks)}</ul>
        </li>
      );
    });
  };

  return (
    <div className="fr-container fr-mb-5w fr-mb-md-9w sitemap">
      <AppBreadcrumb />
      <h1 className="fr-mt-8w fr-mb-8w">{menuData?.plan_du_site}</h1>
      <ul className="fr-ml-1w">{generateLink(LinksArray)}</ul>
    </div>
  );
};

export default SiteMap;
