import { useMemo } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import { DemandeRfErrors } from 'models/types/cms/demandeRF/type';

import { getFamilyValidationSchema } from '../helpers/familyValidationSchema';
import { familyDefaultValues } from '../helpers/familyDefaultValues';

const FamilyUseForm = (erreursRfCms: DemandeRfErrors) => {
  const familyValidationSchema = useMemo(() => getFamilyValidationSchema(erreursRfCms), []);

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    getFieldState,
    trigger,
    watch,
    formState: { isValid, errors, touchedFields },
    control,
  } = useForm({
    resolver: yupResolver(familyValidationSchema),
    defaultValues: {
      ...familyDefaultValues,
    },
    mode: 'onChange',
  });

  return {
    handleSubmit,
    getValues,
    setValue,
    getFieldState,
    trigger,
    watch,
    formState: { isValid, errors, touchedFields },
    control,
    register,
  };
};

export default FamilyUseForm;
