import React from 'react';
import { useController } from 'react-hook-form';

import RadioButton, { radioOptions } from 'components/Atoms/Forms/RadioButton/RadioButton';
import { computeIsValidDsfr } from 'helpers/app/ComponentsStatusHelper';

export interface RadioButtonContainerProps {
  label: string;
  hintText?: string;
  options: radioOptions[];
  orientation?: 'horizontal' | 'vertical';
  stateRelatedMessage?: string;
  name: string;
  control: any;
  large?: boolean;
  className?: string;
}

const RadioButtonContainer: React.FC<RadioButtonContainerProps> = ({
  label,
  hintText,
  options,
  orientation,
  stateRelatedMessage,
  name,
  control,
  large,
  className,
}) => {
  const { field, fieldState } = useController({
    name,
    control,
  });

  const radioGroupClassName = className
    ? className
    : `fr-col-12 fr-mt-4v fr-mt-lg-0v fr-mt-md-0v ${
        large ? 'fr-col-lg-12 fr-col-md-12' : 'fr-col-lg-4 fr-col-md-4'
      }`;
  return (
    <div className={radioGroupClassName}>
      <RadioButton
        label={label}
        hintText={hintText}
        options={options}
        orientation={orientation}
        stateRelatedMessage={
          fieldState.error?.message?.replace('<p>', '').replace('</p>', '') ?? stateRelatedMessage
        }
        name={name}
        control={control}
        state={computeIsValidDsfr(fieldState.isTouched, fieldState.isDirty, fieldState.error)}
        field={field}
      />
    </div>
  );
};

export default RadioButtonContainer;
