export const listRfSummary = {
  total: 5,
  contenu: [
    {
      numeroDossier: '43024000000045',
      dateStatut: '2024-02-13',
      clefStatut: 'decision__favorable',
      statut: 'favorable',
      typeDepot: 'portail',
      idProcedure: null,
      postalStatut: null,
      newDemande: false,
    },
    {
      numeroDossier: '43024000000041',
      dateStatut: '2024-02-13',
      clefStatut: 'decision__decision_attente_retour_prefecture',
      statut: 'decision_attente_retour_prefecture',
      typeDepot: 'portail',
      idProcedure: null,
      postalStatut: null,
      newDemande: false,
    },
    {
      numeroDossier: '76024000000005',
      dateStatut: '2024-06-03',
      clefStatut: 'portail__receptionne',
      statut: 'receptionne',
      typeDepot: 'portail',
      idProcedure: null,
      postalStatut: null,
      newDemande: false,
    },
    {
      numeroDossier: '92024000000122',
      dateStatut: '2024-05-02',
      clefStatut: 'portail__receptionne',
      statut: 'receptionne',
      typeDepot: 'portail',
      idProcedure: null,
      postalStatut: null,
      newDemande: false,
    },
    {
      numeroDossier: '59023000000067',
      dateStatut: '2024-04-02',
      clefStatut: 'en_cours_instruction__en_cours_instruction',
      statut: 'en_cours_instruction',
      typeDepot: 'portail',
      idProcedure: '1',
      postalStatut: null,
      newDemande: false,
    },
    {
      numeroDossier: '25023000000011',
      dateStatut: '2024-04-02',
      clefStatut: 'attente_decision_prefecture__attente_decision_prefecture',
      statut: 'attente_decision_prefecture',
      typeDepot: 'portail',
      idProcedure: '3',
      postalStatut: null,
      newDemande: false,
    },
    {
      numeroDossier: '80023000000013',
      dateStatut: '2023-05-04',
      clefStatut: 'portail__receptionne',
      statut: 'receptionne',
      typeDepot: 'portail',
      idProcedure: null,
      postalStatut: null,
      newDemande: false,
    },
  ],
};
export const listRfSummary2 = {
  total: 20,
  contenu: [
    {
      numeroDossier: '95023000000351',
      dateStatut: '2024-02-22',
      clefStatut: 'decision__defavorable',
      statut: 'defavorable',
      typeDepot: 'portail',
      idProcedure: '1',
      postalStatut: null,
      newDemande: true,
    },
    {
      numeroDossier: '78024000000108',
      dateStatut: '2024-04-02',
      clefStatut: 'portail__en_cours_de_depot',
      statut: 'en_cours_de_depot',
      typeDepot: 'portail',
      idProcedure: null,
      postalStatut: null,
      newDemande: false,
    },
    {
      numeroDossier: '75023000000811',
      dateStatut: '2023-12-08',
      clefStatut: 'portail__receptionne',
      statut: 'receptionne',
      typeDepot: 'postal',
      idProcedure: null,
      postalStatut: 'en_cours',
      newDemande: false,
    },
    {
      numeroDossier: '06021000000024',
      dateStatut: '2023-12-08',
      clefStatut: 'decision__favorable',
      statut: 'favorable',
      typeDepot: 'postal',
      idProcedure: '3',
      postalStatut: 'dossier_cloture',
      newDemande: true,
    },
    {
      numeroDossier: '25023000000013',
      dateStatut: '2023-11-10',
      clefStatut: 'decision__defavorable',
      statut: 'defavorable',
      typeDepot: 'portail',
      idProcedure: '1',
      postalStatut: 'dossier_cloture',
      newDemande: true,
    },
    {
      numeroDossier: '25023000000012',
      dateStatut: '2023-11-10',
      clefStatut: 'pas_etape__dossier_cloture__dossier_irrecevable',
      statut: 'dossier_cloture',
      typeDepot: 'postal',
      idProcedure: null,
      postalStatut: 'dossier_cloture',
      newDemande: true,
    },
    {
      numeroDossier: '11021000000008',
      dateStatut: '2023-11-09',
      clefStatut: 'en_cours_instruction__en_cours_instruction',
      statut: 'en_cours_instruction',
      typeDepot: 'postal',
      idProcedure: '3',
      postalStatut: 'en_cours',
      newDemande: false,
    },
    {
      numeroDossier: '03022000000002',
      dateStatut: '2023-11-09',
      clefStatut: 'decision__favorable_relance_paiement',
      statut: 'favorable_relance_paiement',
      typeDepot: 'postal',
      idProcedure: '3',
      postalStatut: 'en_cours',
      newDemande: false,
    },
    {
      numeroDossier: '61023000000008',
      dateStatut: '2023-10-13',
      clefStatut: 'pas_etape__dossier_cloture__dossier_incomplet',
      statut: 'dossier_cloture',
      typeDepot: 'portail',
      idProcedure: '1',
      postalStatut: 'dossier_cloture',
      newDemande: true,
    },
    {
      numeroDossier: '06021000000016',
      dateStatut: '2023-10-12',
      clefStatut: 'en_cours_instruction__en_cours_instruction',
      statut: 'en_cours_instruction',
      typeDepot: 'postal',
      idProcedure: '3',
      postalStatut: 'en_cours',
      newDemande: false,
    },
    {
      numeroDossier: '92023000000119',
      dateStatut: '2023-10-02',
      clefStatut: 'receptionne__receptionne',
      statut: 'receptionne',
      typeDepot: 'postal',
      idProcedure: null,
      postalStatut: 'en_cours',
      newDemande: false,
    },
    {
      numeroDossier: '31023000000107',
      dateStatut: '2023-09-21',
      clefStatut: 'portail__receptionne',
      statut: 'receptionne',
      typeDepot: 'portail',
      idProcedure: null,
      postalStatut: null,
      newDemande: false,
    },
    {
      numeroDossier: '31023000000106',
      dateStatut: '2023-07-25',
      clefStatut: 'portail__receptionne',
      statut: 'receptionne',
      typeDepot: 'portail',
      idProcedure: null,
      postalStatut: null,
      newDemande: false,
    },
    {
      numeroDossier: '84022000000015',
      dateStatut: '2022-10-07',
      clefStatut: 'decision__decision_attente_retour_prefecture',
      statut: 'decision_attente_retour_prefecture',
      typeDepot: 'postal',
      idProcedure: null,
      postalStatut: 'en_cours',
      newDemande: false,
    },
    {
      numeroDossier: '52022000000003',
      dateStatut: '2022-09-23',
      clefStatut: 'en_cours_instruction__en_cours_instruction',
      statut: 'en_cours_instruction',
      typeDepot: 'postal',
      idProcedure: '1',
      postalStatut: 'en_cours',
      newDemande: false,
    },
    {
      numeroDossier: '23022000000004',
      dateStatut: '2022-07-22',
      clefStatut: 'pas_etape__dossier_cloture__demenagement_departement',
      statut: 'dossier_cloture',
      typeDepot: 'postal',
      idProcedure: null,
      postalStatut: 'dossier_cloture',
      newDemande: true,
    },
    {
      numeroDossier: '75022000000575',
      dateStatut: '2022-06-13',
      clefStatut: 'pas_etape__dossier_cloture__demande_interresse',
      statut: 'dossier_cloture',
      typeDepot: 'postal',
      idProcedure: null,
      postalStatut: 'dossier_cloture',
      newDemande: true,
    },
    {
      numeroDossier: '39021000000003',
      dateStatut: '2021-04-16',
      clefStatut: 'attente_decision_prefecture__attente_decision_prefecture',
      statut: 'attente_decision_prefecture',
      typeDepot: 'postal',
      idProcedure: '1',
      postalStatut: 'en_cours',
      newDemande: false,
    },
    {
      numeroDossier: '51021000000007',
      dateStatut: '2021-03-12',
      clefStatut: 'decision__favorable',
      statut: 'favorable',
      typeDepot: 'postal',
      idProcedure: '1',
      postalStatut: 'en_cours',
      newDemande: false,
    },
    {
      numeroDossier: '74020000000022',
      dateStatut: '2021-02-22',
      clefStatut: 'en_cours_instruction__en_cours_instruction',
      statut: 'en_cours_instruction',
      typeDepot: 'postal',
      idProcedure: '1',
      postalStatut: 'en_cours',
      newDemande: false,
    },
    {
      numeroDossier: '95020000000089',
      dateStatut: '2020-10-05',
      clefStatut: 'en_cours_instruction__en_cours_instruction',
      statut: 'en_cours_instruction',
      typeDepot: 'postal',
      idProcedure: '1',
      postalStatut: 'en_cours',
      newDemande: false,
    },
  ],
};
