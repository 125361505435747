import React, { ReactNode } from 'react';

import { Checkbox } from '@codegouvfr/react-dsfr/Checkbox';
import { FormComponentsEnrichedText } from 'components/Atoms/HtmlParsers/FormComponentHtmlParser';

export type CustomCheckboxOptions = {
  label: string | ReactNode;
  hintText?: string;
  nativeInputProps: {
    value?: string | boolean;
    name?: string;
    checked?: boolean;
    onChange?: any;
  };
  id?: string;
};

interface CustomCheckboxProps {
  options: CustomCheckboxOptions[];
  legend?: string;
  hintText?: string;
  state: any;
  field: any;
  onChange?: any;
}

const CustomCheckbox: React.FC<CustomCheckboxProps> = ({
  options,
  hintText,
  legend,
  state,
  field,
  onChange,
}) => {
  if (typeof field.value === 'boolean') {
    options.map(option => {
      if (option.nativeInputProps.value === field.value) {
        option.nativeInputProps.checked = field.value;
      }
      return option;
    });
  }
  return (
    <Checkbox
      {...field}
      onChange={onChange}
      hintText={hintText ? FormComponentsEnrichedText(hintText) : ''}
      legend={legend ? FormComponentsEnrichedText(legend) : ''}
      options={options}
      value={field.value}
      checked={field.value}
      state={state}
    />
  );
};

export default CustomCheckbox;
