export const user = {
  identifiant: 7503134045,
  nom_naissance: 'TEST HIPE',
  nom_usage: 'DET',
  prenom: 'Hugo',
  sexe: 'M',
  date_naissance: '1979-01-01',
  pays_naissance: 'MAROC',
  nationalite: 'marocaine',
  situation_familiale: 'MARIE',
  adresse_mail: 'hugo.benoist@kaora-partners.com',
  telephone: '',
  date_entree_en_france: '',
  date_entree_france: '',
  compo_famille_conjoint: null,
  compo_famille_nbre_enfant: null,
  compo_famille_enfants: null,
};
