/* eslint-disable complexity */
import * as yup from 'yup';

import { validNumeroEtranger } from 'helpers/app/regexHelper';
import { getAgeisMinor } from 'pages/DemandeRF/Helpers/common';
import { isDateInThePast } from 'helpers/app/ComponentsFormHelpers';
import { AccountCreationPage } from 'models/types/app/pages';

export const HONEYPOT_NAME = 'usercode';

export const removeAccents = (value: string) => {
  return value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};

const validCharacters =
  /^[a-zA-ZáàâäåãæçéèêëíìîïñóòôöõøœšúùûüýÿžÁÀÂÄÅÃÆÇÉÈÊËÍÌÎÏÑÓÒÔÖÕØŒŠÚÙÛÜÝŸŽ0-9]{1}[a-zA-ZáàâäåãæçéèêëíìîïñóòôöõøœšúùûüýÿžÁÀÂÄÅÃÆÇÉÈÊËÍÌÎÏÑÓÒÔÖÕØŒŠÚÙÛÜÝŸŽ0-9 '-]*[a-zA-ZáàâäåãæçéèêëíìîïñóòôöõøœšúùûüýÿžÁÀÂÄÅÃÆÇÉÈÊËÍÌÎÏÑÓÒÔÖÕØŒŠÚÙÛÜÝŸŽ0-9]{1}$/;

export const getValidationSchema = (AccountCreationPage: AccountCreationPage) => {
  return yup
    .object()
    .shape(
      {
        prenom: yup
          .string()
          .test(
            'charscount',
            AccountCreationPage.erreur_champ_invalide ?? '',
            (value: any) =>
              value.match(validCharacters)?.length === value.length ||
              value === '' ||
              (value && value.length > 1),
          )
          .test('invalid', AccountCreationPage.erreur_champ_invalide ?? '', (value: any) =>
            value.match(validCharacters),
          )
          .min(1, AccountCreationPage.erreur_champ_obligatoire ?? '')
          .max(44, AccountCreationPage.erreur_taille_maximum ?? '')
          .required(AccountCreationPage.erreur_champ_obligatoire ?? '')
          .transform(value => (!value ? '' : removeAccents(value))),
        prenom2: yup
          .string()
          .default('')
          .when('prenom2', {
            is: (value: string) => value === '',
            then: () => yup.string().notRequired(),
            otherwise: () =>
              yup
                .string()
                .test(
                  'invalid',
                  AccountCreationPage.erreur_champ_invalide ?? '',
                  (value: any) =>
                    value.match(validCharacters)?.length === value.length ||
                    value === '' ||
                    (value && value.length > 1),
                )
                .test('invalid', AccountCreationPage.erreur_champ_invalide ?? '', (value: any) =>
                  value.match(validCharacters),
                )
                .max(44, AccountCreationPage.erreur_taille_maximum ?? '')
                .transform(value => (!value ? '' : removeAccents(value))),
          }),
        prenom3: yup
          .string()
          .default('')
          .when('prenom3', {
            is: (value: string) => value === '',
            then: () => yup.string().notRequired(),
            otherwise: () =>
              yup
                .string()
                .test(
                  'invalid',
                  AccountCreationPage.erreur_champ_invalide ?? '',
                  (value: any) =>
                    value.match(validCharacters)?.length === value.length ||
                    value === '' ||
                    (value && value.length > 1),
                )
                .test('invalid', AccountCreationPage.erreur_champ_invalide ?? '', (value: any) =>
                  value.match(validCharacters),
                )
                .max(44, AccountCreationPage.erreur_taille_maximum ?? '')
                .transform(value => (!value ? '' : removeAccents(value))),
          }),
        nom_naissance: yup
          .string()
          .test(
            'invalid',
            AccountCreationPage.erreur_champ_invalide ?? '',
            (value: any) =>
              value.match(validCharacters)?.length === value.length ||
              value === '' ||
              (value && value.length > 1),
          )
          .test('invalid', AccountCreationPage.erreur_champ_invalide ?? '', (value: any) =>
            value.match(validCharacters),
          )
          .min(1, AccountCreationPage.erreur_champ_obligatoire ?? '')
          .max(44, AccountCreationPage.erreur_taille_maximum ?? '')
          .required(AccountCreationPage.erreur_champ_obligatoire ?? '')
          .transform(value => (!value ? '' : removeAccents(value))),
        date_naissance: yup
          .string()
          .required(AccountCreationPage.erreur_date ?? '')
          .test('dateMustBeInPast', AccountCreationPage.erreur_date_futur ?? '', value => {
            return isDateInThePast(value);
          })
          .test('youMustBeMajor', AccountCreationPage.erreur_dois_etre_majeur ?? '', value => {
            return !getAgeisMinor(value);
          }),
        nationalite: yup.string().required(AccountCreationPage.erreur_champ_obligatoire ?? ''),
        pays_naissance: yup.string().required(AccountCreationPage.erreur_champ_obligatoire ?? ''),
        identifiant: yup
          .string()
          .required(AccountCreationPage.erreur_champ_obligatoire ?? '')
          .matches(validNumeroEtranger, AccountCreationPage.erreur_champ_invalide ?? '')
          .min(9, AccountCreationPage?.erreur_longueur_numero_etranger ?? '')
          .max(11, AccountCreationPage.erreur_longueur_numero_etranger ?? ''),
        adresse_mail: yup
          .string()
          .required(AccountCreationPage.erreur_champ_obligatoire ?? '')
          .email(AccountCreationPage.erreur_email ?? ''),
      },
      [
        ['prenom', ''],
        ['prenom2', 'prenom2'],
        ['prenom3', 'prenom3'],
        ['nom_naissance', ''],
        ['date_naissance', ''],
        ['nationalite', ''],
        ['pays_naissance', ''],
        ['identifiant', ''],
        ['adresse_mail', ''],
      ],
    )
    .defined();
};
