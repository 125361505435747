export const mapBoolFormToServer = (str: string | undefined): boolean | null => {
  if (str !== undefined && str !== '') {
    return str === 'oui';
  }
  return null;
};

export const mapDateServerToForm = (
  dateStr: string | null | undefined,
): Array<number | undefined> => {
  if (dateStr !== undefined && dateStr !== null) {
    const arr = dateStr.split('-');
    return [parseInt(arr[0]), parseInt(arr[1]), parseInt(arr[2])];
  }
  return [undefined, undefined, undefined];
};

export const mapFileIsInFrenchToForm = (data: boolean | null): boolean => {
  if (data !== null) {
    if (data) {
      return true;
    }
    return false;
  }

  return false;
};

export const manageDateValue = (date: string | undefined): string | null => {
  if (date !== undefined && date !== '' && date.length === 10) {
    return date;
  }
  return null;
};

export const mapSituationMatrimonialDateFormToServer = (
  situationMatrimonial: string | undefined,
  situationDate: {
    dateMarie: string | null;
    datePacse: string | null;
    dateDivorce: string | null;
    dateConcubinage: string | null;
  },
): string | null => {
  if (situationMatrimonial === undefined) return null;
  if (situationMatrimonial === 'marie') {
    return situationDate.dateMarie ? situationDate.dateMarie : null;
  }

  if (situationMatrimonial === 'pacse') {
    return situationDate.datePacse ? situationDate.datePacse : null;
  }

  if (situationMatrimonial === 'divorce') {
    return situationDate.dateDivorce ? situationDate.dateDivorce : null;
  }

  if (situationMatrimonial === 'concubin') {
    return situationDate.dateConcubinage ? situationDate.dateConcubinage : null;
  }

  return null;
};

export const mapSituationMatrimonialOriginalFormToServer = (
  situationMatrimonial: string | undefined,
  mariageOriginal: boolean | undefined | null,
  divorceOriginal: boolean | undefined | null,
  deathOriginal: boolean | undefined | null,
): boolean | undefined => {
  if (situationMatrimonial === undefined) return undefined;
  switch (situationMatrimonial) {
    case 'marie':
      return mariageOriginal !== null ? mariageOriginal : undefined;
    case 'divorce':
      return divorceOriginal !== null ? divorceOriginal : undefined;
    case 'veuf':
      return deathOriginal !== null ? deathOriginal : undefined;
  }

  return undefined;
};

export const mapMarieEnFranceFormToServer = (str: string | undefined): boolean | null => {
  if (str !== undefined) {
    return str === 'en_france';
  }
  return null;
};

export const mapMarieEnFranceServerToFrom = (
  marieEnFrance: boolean | null | undefined,
): string | undefined => {
  if (marieEnFrance !== null && marieEnFrance !== undefined) {
    return marieEnFrance ? 'en_france' : 'etranger';
  }
  return undefined;
};

export const getDocumentsByCategory = (category: string, documents: any[]) => {
  if (Array.isArray(documents)) {
    const documentsByCategories =
      documents.filter(
        obj =>
          obj.categorie === category &&
          obj.statut &&
          !['deleted_pending', 'deleted'].includes(obj.statut),
      ).length > 0
        ? documents.filter(
            obj =>
              obj.categorie === category &&
              obj.statut &&
              !['deleted_pending', 'deleted'].includes(obj.statut),
          )
        : [];
    return documentsByCategories.map(doc => ({ id: null, document: doc }));
  } else {
    return [];
  }
};
