import AppBreadcrumb from 'components/Atoms/Breadcrumb/Breadcrumb';
import { FC } from 'react';

const CollectionPageLayout: FC<any> = ({ className, title, children }) => {
  return (
    <div className={`fr-container fr-mb-5w`}>
      <AppBreadcrumb />
      <h1 className="fr-mt-6w fr-mt-md-7w fr-mb-5w">{title}</h1>
      <div className={`fr-grid-row fr-grid-row--gutters ${className}-page`}>{children}</div>
    </div>
  );
};

export default CollectionPageLayout;
