export const versements = [
  {
    montant: 316.2,
    periode: 'Juillet 2021',
    nbrJourCalcule: '31',
    statut: 'paye',
    dateVersement: '2021-07-29',
    nombreMajeurs: 2,
    nombreMineurs: 0,
    dateCompositionFamiliale: '2021-06-14',
    statutHebergement: 'heberge',
    dateStatutHebergement: '2021-06-14',
  },
  {
    montant: 329,
    periode: 'Juin 2021',
    nbrJourCalcule: '30',
    statut: 'paye',
    dateVersement: '2021-06-28',
    nombreMajeurs: 2,
    nombreMineurs: 0,
    dateCompositionFamiliale: '2021-06-18',
    statutHebergement: 'domicilie',
    dateStatutHebergement: '2021-06-18',
  },
  {
    montant: 201.8,
    periode: 'Mai 2021',
    nbrJourCalcule: '14',
    statut: 'paye',
    dateVersement: '2021-05-30',
    nombreMajeurs: 2,
    nombreMineurs: 0,
    dateCompositionFamiliale: '2021-05-18',
    statutHebergement: 'domicilie',
    dateStatutHebergement: '2021-05-18',
  },

  {
    montant: 426,
    periode: 'Avril 2021',
    nbrJourCalcule: '30',
    statut: 'paye',
    dateVersement: '2021-04-28',
    nombreMajeurs: 2,
    nombreMineurs: 0,
    dateCompositionFamiliale: '2021-03-18',
    statutHebergement: 'domicilie',
    dateStatutHebergement: '2021-03-18',
  },
  {
    montant: 198.8,
    periode: 'Mars 2021',
    nbrJourCalcule: '14',
    statut: 'paye',
    dateVersement: '2021-03-30',
    nombreMajeurs: 2,
    nombreMineurs: 0,
    dateCompositionFamiliale: '2021-03-18',
    statutHebergement: 'domicilie',
    dateStatutHebergement: '2021-03-18',
  },
];
