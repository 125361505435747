import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { KeycloakStatus } from 'services/authentication';

export type KeycloakState = Readonly<{
  keycloakStatus: KeycloakStatus;
  keycloakStatusNotificationSent: boolean;
}>;

const initialState: KeycloakState = {
  keycloakStatus: KeycloakStatus.UNKNOWN,
  keycloakStatusNotificationSent: false,
};

const keycloakSlice = createSlice({
  name: 'Keycloak',
  initialState,
  reducers: {
    setKeycloakStatus: (state, action: PayloadAction<KeycloakStatus>) => {
      state.keycloakStatus = action.payload;
    },
    setKeycloakStatusNotificationSent: (state, action: PayloadAction<boolean>) => {
      state.keycloakStatusNotificationSent = action.payload;
    },
  },
});

export const { setKeycloakStatus, setKeycloakStatusNotificationSent } = keycloakSlice.actions;
export default keycloakSlice.reducer;
