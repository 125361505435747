export enum GENRE {
  HOMME = 'homme',
  FEMME = 'femme',
  NEUTRE = 'neutre',
}

export enum GENRE_API {
  HOMME = 'M',
  FEMME = 'F',
}

export enum GENRE_BINAIRE {
  HOMME = 'homme',
  FEMME = 'femme',
}

export enum GENRE_CMS {
  HOMME = 'homme',
  FEMME = 'femme',
  NEUTRE = 'neutre',
}

export enum CIVILITE_CMS {
  HOMME = 'homme',
  FEMME = 'femme',
}

export const GENRE_TO_TITLE = {
  [GENRE.HOMME]: 'Mr.',
  [GENRE.FEMME]: 'Mme.',
  [GENRE.NEUTRE]: 'Mx.',
};

export const GENRE_TO_CIVILITE_CMS: { [key in GENRE_BINAIRE]: CIVILITE_CMS } = {
  [GENRE.HOMME]: CIVILITE_CMS.HOMME,
  [GENRE.FEMME]: CIVILITE_CMS.FEMME,
};

export const GENRE_TO_CMS: { [key in GENRE]: GENRE_CMS } = {
  [GENRE.HOMME]: GENRE_CMS.HOMME,
  [GENRE.FEMME]: GENRE_CMS.FEMME,
  [GENRE.NEUTRE]: GENRE_CMS.NEUTRE,
};

export const DNA_TO_GENRE: { [key: string]: GENRE } = {
  masculin: GENRE.HOMME,
  feminin: GENRE.FEMME,
  neutre: GENRE.NEUTRE,
};

export const ANEF_TO_GENRE: { [key: string]: GENRE } = {
  M: GENRE.HOMME,
  F: GENRE.FEMME,
  A_DETERMINER: GENRE.NEUTRE,
};
