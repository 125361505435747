export const RessourcesDefaultValues = {
  ressources: [],
  nouvelle_activite: '',
  ress_dernier_avis_imposition_files: [],
  ress_justificatif_vers_presta_social_algerien_files: [],
  travail_dans_btp: '',
  ress_btp_justificatif_conges_payes_files: [],
  arret_travail_12_dernier_mois: '',
  ress_secu_social_indemnite_jour_files: [],
  beneficiaire_aah_ou_asi: '',
  ress_decision_attribution_aah_asi_files: [],
  ress_attestation_paiement_aah_asi_files: [],
  autres_ressources: '',
  ress_autre_document_origine_revenu_files: [],
  pension_alimentaire: '',
  ress_pension_alimentaires_ou_decision_justice_files: [],
  declarer_conjoint_ressources: '',
  ress_releve_compte_origine_revenus_files: [],
  validation: 'true',
};
