import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  formatArticlesDataFromApiToApp,
  formatLayoutDataFromApiToApp,
  formatProceduresDataFromApiToApp,
  formatServicesDataFromApiToApp,
} from 'helpers/api/apiToAppFormatter';
import {
  formatAccountCreationPage,
  formatAccueilEtIntegrationPage,
  formatAccueilFromApiToApp,
  formatArticlesPageDataFromApiToApp,
  formatLocationPageFromApi,
  formatMentionsPage,
  formatRgpdPage,
  formatCookiePage,
  formatAccessibilitePage,
  formatProcedurePage,
  formatAccountPage,
  formatDashboardPage,
} from 'helpers/api/pagesFormatter';
import { ArticlesCmsPage, ArticleCmsDto } from 'models/types/cms/articles/types';
import { AccueilEtIntegrationCmsPage } from 'models/types/cms/accueilEtIntegration/type';

import { initialState } from './initialState';
import { ProcedureCmsDto } from 'models/types/cms/procedures/types';
import { OnlineServicesCmsDto } from 'models/types/cms/servicesEnLigne/types';
import { LayoutCmsDto } from 'models/types/cms/layout/types';
import { AccueilCmsDto } from 'models/types/cms/accueil/types';
import { LocationCmsDto } from 'models/types/cms/location/types';
import { CreationCompteCmsPage } from 'models/types/cms/creationDeCompte/type';
import { MentionsCmsPage } from 'models/types/cms/mentions/types';
import { RgpdCmsPage } from 'models/types/cms/rgpd/types';
import { CookieCmsPage } from 'models/types/cms/cookie/types';
import { AccessibiliteCmsPage } from 'models/types/cms/accessibilite/types';
import { CompteCmsPage } from 'models/types/cms/compte/type';
import { DashboardCmsPage } from 'models/types/cms/dashboard/type';

const pagesSlice = createSlice({
  name: 'Pages',
  initialState,
  reducers: {
    setLayout: (state, action: PayloadAction<LayoutCmsDto>) => {
      const layoutValues = formatLayoutDataFromApiToApp(action.payload);

      state.shared.menu = layoutValues.menu;
      state.shared.header = layoutValues.header;
      state.shared.footer = layoutValues.footer;
      state.shared.blocs = layoutValues.blocs;
    },
    setPagesTitle: (state, action: PayloadAction<any>) => {
      state.accueil.titre = action.payload.data.attributes.accueil;
      state.procedures.titre = action.payload.data.attributes.procedures;
      state.services.titre = action.payload.data.attributes.services_en_ligne;
      state.articles.titre = action.payload.data.attributes.articles;
      state.location.titre = action.payload.data.attributes.localisation;
      state.accueil_et_integration.titre = action.payload.data.attributes.accueil_et_integration;
      state.accueil_et_integration.titre = action.payload.data.attributes.accueil_et_integration;
      state.creation_de_compte.titre = action.payload.data.attributes.creation_compte;
      state.compte.titre = action.payload.data.attributes.compte;
      state.dashboard.titre = action.payload.data.attributes.tableau_de_bord;
      state.documents.titre = action.payload.data.attributes.documents;
      state.erreur_auth.titre = action.payload.data.attributes.erreur_auth;

      state.titrePages = {
        accessibilite: action.payload.data.attributes.accessibilite,
        accueil: action.payload.data.attributes.accueil,
        accueil_et_integration: action.payload.data.attributes.accueil_et_integration,
        accueil_non_connecte: action.payload.data.attributes.accueil_non_connecte,
        allocation: action.payload.data.attributes.allocation,
        article: action.payload.data.attributes.article,
        articles: action.payload.data.attributes.articles,
        asile: action.payload.data.attributes.asile,
        bagages: action.payload.data.attributes.bagages,
        compte: action.payload.data.attributes.compte,
        contact: action.payload.data.attributes.contact,
        cookies: action.payload.data.attributes.cookies,
        creation_compte: action.payload.data.attributes.creation_compte,
        documents: action.payload.data.attributes.documents,
        erreur: action.payload.data.attributes.erreur,
        erreur_auth: action.payload.data.attributes.erreur_auth,
        expression_besoin: action.payload.data.attributes.expression_besoin,
        localisation: action.payload.data.attributes.localisation,
        mentions_legales: action.payload.data.attributes.mentions_legales,
        plan_site: action.payload.data.attributes.plan_site,
        procedure: action.payload.data.attributes.procedure,
        procedures: action.payload.data.attributes.procedures,
        regroupement_familial: action.payload.data.attributes.regroupement_familial,
        reinsertion: action.payload.data.attributes.reinsertion,
        retour: action.payload.data.attributes.retour,
        rgpd: action.payload.data.attributes.rgpd,
        services_en_ligne: action.payload.data.attributes.services_en_ligne,
        tableau_de_bord: action.payload.data.attributes.tableau_de_bord,
        voir_fil_ariane: action.payload.data.attributes.voir_fil_ariane,
      };
    },
    setArticles: (state, action: PayloadAction<ArticleCmsDto[]>) => {
      state.shared.articles = formatArticlesDataFromApiToApp(action.payload);
    },
    setProcedures: (state, action: PayloadAction<ProcedureCmsDto>) => {
      state.shared.procedures = formatProceduresDataFromApiToApp(action.payload);
    },
    setServices: (state, action: PayloadAction<OnlineServicesCmsDto[]>) => {
      state.shared.services = formatServicesDataFromApiToApp(action.payload);
    },
    setAccueil: (state, action: PayloadAction<AccueilCmsDto>) => {
      state.accueil = formatAccueilFromApiToApp(action.payload);
    },
    setAccueilEtIntegrationPage: (state, action: PayloadAction<AccueilEtIntegrationCmsPage>) => {
      const tmpAccueilIntegrationPage = formatAccueilEtIntegrationPage(action.payload);
      tmpAccueilIntegrationPage.titre = state.accueil_et_integration.titre;
      state.accueil_et_integration = tmpAccueilIntegrationPage;
    },
    setProcedurePage: (state, action: PayloadAction<any>) => {
      const tmpProcedures = formatProcedurePage(action.payload);
      tmpProcedures.titre = state.procedures.titre;
      state.procedures = tmpProcedures;
    },
    setArticlesPage: (state, action: PayloadAction<ArticlesCmsPage>) => {
      const tmpArticles = formatArticlesPageDataFromApiToApp(action.payload);
      tmpArticles.titre = state.articles.titre;
      state.articles = tmpArticles;
    },
    setLocationPage: (state, action: PayloadAction<LocationCmsDto>) => {
      const tmpLocation = formatLocationPageFromApi(action.payload);
      tmpLocation.titre = state.location.titre;
      state.location = tmpLocation;
    },
    setMentionsPage: (state, action: PayloadAction<MentionsCmsPage>) => {
      state.mentions = formatMentionsPage(action.payload);
    },
    setRgpdPage: (state, action: PayloadAction<RgpdCmsPage>) => {
      state.rgpd = formatRgpdPage(action.payload);
    },
    setCookiePage: (state, action: PayloadAction<CookieCmsPage>) => {
      state.cookie = formatCookiePage(action.payload);
    },
    setAccessibilitePage: (state, action: PayloadAction<AccessibiliteCmsPage>) => {
      state.accessibilite = formatAccessibilitePage(action.payload);
    },
    setCreationDeCompte: (state, action: PayloadAction<CreationCompteCmsPage>) => {
      state.creation_de_compte = formatAccountCreationPage(action.payload);
    },
    setAccountPage: (state, action: PayloadAction<CompteCmsPage>) => {
      const tmpAccount = formatAccountPage(action.payload);
      tmpAccount.titre = state.compte.titre;
      state.compte = tmpAccount;
    },
    setDashboardPage: (state, action: PayloadAction<DashboardCmsPage>) => {
      const tmpDashboard = formatDashboardPage(action.payload);
      tmpDashboard.titre = state.dashboard.titre;
      state.dashboard = tmpDashboard;
    },
  },
});

export const {
  setLayout,
  setAccueil,
  setArticles,
  setProcedures,
  setServices,
  setArticlesPage,
  setLocationPage,
  setAccueilEtIntegrationPage,
  setCreationDeCompte,
  setMentionsPage,
  setRgpdPage,
  setCookiePage,
  setAccessibilitePage,
  setProcedurePage,
  setPagesTitle,
  setAccountPage,
  setDashboardPage,
} = pagesSlice.actions;

export default pagesSlice.reducer;
