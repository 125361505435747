import React from 'react';
import { format } from 'date-fns';

import { STATUT_OPC } from 'constants/demandeAsile';
import { shouldDisplayEspaceAsile } from 'redux/asile/selectors';
import { ProcedureAsileCmsComponent } from 'models/types/cms/dashboard/type';
import { DemandeAsile } from 'models/types/api/demandeAsile';
import ProcedureTile from 'components/Molecules/ProcedureTile/ProcedureTile';
import { DISPLAY_DATE_FORMAT } from 'constants/time';

interface Props {
  cmsComponent: ProcedureAsileCmsComponent;
  derniereDemandeAsile: DemandeAsile;
}

const AsileProcedure: React.FC<Props> = ({ cmsComponent, derniereDemandeAsile }) => {
  const getDescription = () => {
    switch (derniereDemandeAsile.statutOPC) {
      case STATUT_OPC.ACCEPTEE:
        return cmsComponent.description_acceptee;
      case STATUT_OPC.REFUSEE:
        return cmsComponent.description_refusee;
      case STATUT_OPC.REFUS_DE_SIGNER:
        return cmsComponent.description_refus_de_signer;
    }
  };

  const getDate = () => {
    if (derniereDemandeAsile.dateSignatureOPC) {
      return format(new Date(derniereDemandeAsile.dateSignatureOPC), DISPLAY_DATE_FORMAT);
    }
    return '';
  };

  return (
    <ProcedureTile
      imageUrl="/img/dashboard/vignette-asile.png"
      title={cmsComponent.titre}
      desc={
        <div className="asile-procedure-desc">
          <span className="description-asile">{getDescription()}&nbsp;</span>
          <span className="date-asile">{getDate()}</span>
        </div>
      }
      linkProps={{
        href: shouldDisplayEspaceAsile(derniereDemandeAsile.statutOPC)
          ? derniereDemandeAsile.path
          : '#',
      }}
    />
  );
};

export default AsileProcedure;
