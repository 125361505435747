import { createServer, Model, RestSerializer } from 'miragejs';

import uuidManager from 'mockServer/uuidManager';
import { API_BASE_URL } from 'constants/api';
import { CMS_BASE_URL } from 'services/cmsClient';
import { API_ADRESS_GOV } from 'redux/rtk/address/addressSlice';
import getEnv from 'helpers/app/envHelper';

import { directionTerritoriales as dataDirectionTerritoriales } from './data/directionTerritoriales';
import { user as dataUser } from './data/users';
import { hebergements as dataHebergements } from './data/hebergements';
import { versements as dataVersements } from './data/versements';
import { listRfSummary as dataListRfSummary } from './data/listRfSummary';
import { derniereAsile as dataDerniereAsile } from './data/derniereAsile';
import { titresPage as cmsTitresPage } from './data/cms/titresPage';
import { allocation as dataAllocation } from './data/allocation';
import { dataDocumentsAsile, dataDocumentsRf, dataDocumentsRri } from './data/documents';
import {
  directionTerritoriales as routeFuncDirectionTerritoriales,
  versements as routeFuncVersements,
  user as routeFuncUser,
  hebergements as routeFuncHebergements,
  documentsAsile as routeFuncDocumentsAsile,
  documentsRf as routeFuncDocumentsRf,
  documentsRri as routeFuncDocumentsRri,
  derniereAsile as routeFuncDerniereAsile,
  listRfSummary as routeFuncListRfSummary,
  suiviRf as routeFuncSuiviRf,
  submitRf as routeFuncSubmitRf,
  creationRf as routeFuncCreateRf,
  demandeRf as routeFuncDemandeRf,
  demandesRf as routeFuncDemandesRf,
  allocation as routeFuncAllocation,
} from './routes/index';
import { titresPage as routeFuncCmsTitresPage } from './routes/cms';
import { suiviRf } from './factories/suiviRf/suiviRf';
import { documentRf } from './factories/suiviRf/document/documents';

import { etape1_portail__receptionne_receptionne } from './factories/suiviRf/etape1/etape1_portail__receptionne_receptionne';
import { etape1_portail__en_cours_de_depot_en_cours_de_depot } from './factories/suiviRf/etape1/etape1_portail__en_cours_de_depot_en_cours_de_depot';
import { etape1_receptionne__receptionne_receptionne } from './factories/suiviRf/etape1/etape1_receptionne__receptionne_receptionne';
import { etape1_receptionne__receptionne_document_recu_receptionne } from './factories/suiviRf/etape1/etape1_receptionne__receptionne_document_recu_receptionne';
import { etape1_receptionne__receptionne_attente_documents_complementaires } from './factories/suiviRf/etape1/etape1_receptionne__receptionne_attente_documents_complementaires';
import { etape1_receptionne_dossier_cloture } from './factories/suiviRf/etape1/etape1_receptionne_dossier_cloture';
import { etape2_en_cours_instruction__en_cours_instruction } from './factories/suiviRf/etape2/etape2_en_cours_instruction__en_cours_instruction';
import { etape2_annule } from './factories/suiviRf/etape2/etape2_annule';
import { etape3_attente_decision_prefecture__attente_decision_prefecture } from './factories/suiviRf/etape3/etape3_attente_decision_prefecture__attente_decision_prefecture';
import { etape4_decision__favorable } from './factories/suiviRf/etape4/etape4_decision__favorable';
import { etape4_decision__decision_attente_retour_prefecture } from './factories/suiviRf/etape4/etape4_decision__decision_attente_retour_prefecture';
import { etape4_decision__defavorable } from './factories/suiviRf/etape4/etape4_decision__defavorable';
import { etape4_decision__favorable_attente_paiement } from './factories/suiviRf/etape4/etape4_decision__favorable_attente_paiement';
import { etape4_decision__favorable_relance_paiement } from './factories/suiviRf/etape4/etape4_decision__favorable_relance_paiement';
import { etape4_decision__decision_favorable_prefecture_sans_instruction } from './factories/suiviRf/etape4/etape4_decision__decision_favorable_prefecture_sans_instruction';
import { pas_etape__dossier_cloture__dossier_irrecevable } from './factories/suiviRf/etape1/etape1_pas_etape__dossier_cloture__dossier_irrecevable';
import { demandeRf } from './factories/demandeRf/demandeRf';

const ApplicationSerializer = RestSerializer.extend({
  root: false,
});

export function makeServer({ environment = 'test' }) {
  return createServer({
    identityManagers: {
      application: uuidManager,
    },
    environment,
    serializers: {
      application: ApplicationSerializer,
    },
    models: {
      'direction-territoriale': Model.extend({}),
      versements: Model.extend({}),
      suiviRf: Model,
      demandeRf: Model,
      documentRf: Model,
    },
    factories: {
      suiviRf: suiviRf,
      demandeRf: demandeRf,
      documentRf: documentRf,
    },
    seeds(server) {
      server.db.loadData({
        'direction-territoriale': dataDirectionTerritoriales,
        listRfSummary: dataListRfSummary,
        versements: dataVersements,
        user: dataUser,
        hebergements: dataHebergements,
        documentsAsile: dataDocumentsAsile,
        derniereAsile: dataDerniereAsile,
        documentsRf: dataDocumentsRf,
        documentsRri: dataDocumentsRri,
        cmsTitresPage: cmsTitresPage,
        allocation: dataAllocation,
      });
      //server.createList('suiviRf', 1);
      server.create('suiviRf');
      server.createList('documentRf', 5);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      server.create('suiviRf', etape1_portail__receptionne_receptionne);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      server.create('suiviRf', etape1_portail__en_cours_de_depot_en_cours_de_depot);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      server.create('suiviRf', etape1_receptionne__receptionne_receptionne);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      server.create('suiviRf', etape1_receptionne__receptionne_document_recu_receptionne);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      server.create('suiviRf', etape1_receptionne__receptionne_attente_documents_complementaires);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      server.create('suiviRf', etape1_receptionne_dossier_cloture);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      server.create('suiviRf', pas_etape__dossier_cloture__dossier_irrecevable);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      server.create('suiviRf', etape2_en_cours_instruction__en_cours_instruction);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      server.create('suiviRf', etape2_annule);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      server.create('suiviRf', etape3_attente_decision_prefecture__attente_decision_prefecture);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      server.create('suiviRf', etape4_decision__favorable);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      server.create('suiviRf', etape4_decision__decision_attente_retour_prefecture);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      server.create('suiviRf', etape4_decision__defavorable);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      server.create('suiviRf', etape4_decision__favorable_attente_paiement);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      server.create('suiviRf', etape4_decision__favorable_relance_paiement);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      server.create('suiviRf', etape4_decision__decision_favorable_prefecture_sans_instruction);

      server.create('demandeRf');
    },
    routes() {
      this.urlPrefix = API_BASE_URL;
      this.namespace = 'api';
      this.timing = 2000;
      this.get('/direction-territoriale', routeFuncDirectionTerritoriales);
      this.get('/private/versements', routeFuncVersements);
      this.get('/private/usagers', routeFuncUser);
      this.get('/private/hebergements', routeFuncHebergements);
      this.get('/private/documents-asile', routeFuncDocumentsAsile);
      this.get('/private/demandes-asile/derniere', routeFuncDerniereAsile);
      this.get('/private/documents-rf', routeFuncDocumentsRf);
      this.get('/private/rri/documents', routeFuncDocumentsRri);
      this.get('/private/regroupement-familial/liste-demande-summary', routeFuncListRfSummary);
      this.get('/private/regroupement-familial/demandes/:dossierId', routeFuncSuiviRf);
      this.get('/private/regroupement-familial/preparation-demandes', routeFuncDemandesRf);
      this.get('/private/regroupement-familial/preparation-demandes/creation', routeFuncCreateRf);
      this.get(
        '/private/regroupement-familial/preparation-demandes/:demandeId',
        routeFuncDemandeRf,
      );
      this.get(`${CMS_BASE_URL}/api/titres-page`, routeFuncCmsTitresPage);
      this.get('/private/allocations', routeFuncAllocation);

      this.put(
        '/private/regroupement-familial/preparation-demandes/:dossierId/soumettre',
        routeFuncSubmitRf,
      );

      /* Urls ne passant pas par le serveur de mock */
      this.passthrough(`${API_ADRESS_GOV}/**`);
      this.passthrough(`${CMS_BASE_URL}/**`);
      this.passthrough(`${getEnv('REACT_APP_MATOMO_URL')}/**`);
      this.passthrough(`${getEnv('REACT_APP_KEYCLOAK_URL')}**`);
      this.passthrough('https://static.zdassets.com/**');
    },
  });
}
