/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import { cmsEnrichedText } from 'components/Atoms/HtmlParsers/PageContentHtmlParser';
import { CMS_BASE_URL } from 'services/cmsClient';
import {
  WidgetBaseElement,
  ImageWithAlt,
  WidgetParagraphDto,
  WidgetPageContenu,
} from 'models/types/cms/widgetPageContenu/types';

interface Props {
  widget: WidgetBaseElement;
}

const WidgetFactory: React.FC<Props> = ({ widget }) => {
  switch (widget.__component) {
    case WidgetPageContenu.IMAGEWITHALT:
      const imageWithAlt = widget as unknown as ImageWithAlt;
      return (
        <img
          key={widget.id}
          src={`${CMS_BASE_URL}${imageWithAlt.image.data.attributes.url}`}
          alt={imageWithAlt.balise_alt ? imageWithAlt.balise_alt : ''}
          className="infobulle-img"
        />
      );
    case WidgetPageContenu.PARAGRAPHE:
      const paragraphe = widget as unknown as WidgetParagraphDto;
      return <p key={widget.id}>{cmsEnrichedText(paragraphe.contenu)}</p>;
    default:
      return null;
  }
};

export default WidgetFactory;
