export enum SITUATION_LOGEMENT {
  GRATUIT = 'gratuit',
  PROPRIETAIRE = 'proprietaire',
  LOCATAIRE = 'locataire',
  SOUS_LOCATAIRE = 'sous_locataire',
}

export enum TYPE_HEBERGEUR {
  EMPLOYEUR = 'employeur',
  AUTRE = 'autre',
  PARTICULIER = 'particulier',
}
