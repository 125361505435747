import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@codegouvfr/react-dsfr/Button';
import MainNavigation from '@codegouvfr/react-dsfr/MainNavigation';
import { useAuth } from 'react-oidc-context';

import { RegisteredLocales } from 'constants/locales';
import { useGetUserQuery } from 'redux/rtk/api/apiUser';
import { setCurrentLocale } from 'redux/locale';
import { getCurrentLocale } from 'redux/locale/selectors';

import { CustomHeaderProps } from './CustomHeader.type';

const Header: FC<CustomHeaderProps> = ({
  brandTop,
  serviceTitle,
  navigation,
  operatorLogo,
  homeLinkProps,
  quickAccessItems,
  languageItems,
  closeWording,
}) => {
  const currentLanguage = useSelector(getCurrentLocale) as string;

  const [skip, setSkip] = useState<boolean>(true);
  const getCurrentLanguageElement =
    currentLanguage && currentLanguage !== '' && currentLanguage.length === 2
      ? languageItems.find(el => el.lang === currentLanguage)
      : languageItems.find(el => el.lang === 'fr');

  const dispatch = useDispatch();
  const { data: dataUser } = useGetUserQuery(undefined, {
    skip: skip,
  });
  const auth = useAuth();
  useEffect(() => {
    if (auth.isAuthenticated) {
      setSkip(false);
    }
  }, [auth.isAuthenticated, setSkip]);

  return (
    <header role="banner" className="fr-header">
      <div className="fr-header__body">
        <div className="fr-container">
          <div className="fr-header__body-row">
            <div className="fr-header__brand fr-enlarge-link">
              <div className="fr-header__brand-top">
                <div className="fr-header__logo fr-mr-3w">
                  <p className="fr-logo">{brandTop}</p>
                </div>
                <div className="fr-header__operator fr-mr-3w">
                  <img
                    className="fr-responsive-img"
                    src={operatorLogo.imgUrl}
                    alt={operatorLogo.alt}
                  />
                </div>
                <div className="fr-header__navbar">
                  <button
                    className="fr-btn--menu fr-btn"
                    data-fr-opened="false"
                    aria-controls="modal-491"
                    aria-haspopup="menu"
                    id="button-492"
                    title="Menu"
                  >
                    Menu
                  </button>
                </div>
              </div>
              <div className="fr-header__service">
                <a href={homeLinkProps.href} title={homeLinkProps.title}>
                  <p className="fr-header__service-title">{serviceTitle}</p>
                </a>
              </div>
            </div>
            <div className="fr-header__tools">
              <div className="fr-header__tools-links">
                <ul className="fr-btns-group">
                  {dataUser && (
                    <li>
                      <span className="fr-btn fr-icon-user-line fr-pt-2v">
                        {dataUser.prenom} {dataUser.nom_naissance}
                      </span>
                    </li>
                  )}
                  {quickAccessItems &&
                    quickAccessItems.map(({ iconId, text, linkProps }) => (
                      <li key={text}>
                        {linkProps.href ? (
                          <a href={linkProps.href} title={text} className={'fr-btn ' + iconId}>
                            {text}
                          </a>
                        ) : (
                          <Button
                            onClick={linkProps.onClick}
                            priority="tertiary no outline"
                            iconId="fr-icon-lock-line"
                            className="connexion-button-desktop"
                          >
                            {text}
                          </Button>
                        )}
                      </li>
                    ))}
                </ul>
                <nav role="navigation" className="fr-translate fr-nav">
                  <div className="fr-nav__item">
                    <button
                      className="fr-translate__btn fr-btn fr-btn--tertiary"
                      aria-controls="translate-516"
                      aria-expanded="false"
                      title="Sélectionner une langue"
                    >
                      {getCurrentLanguageElement?.title.split('-')[0]}
                      <span className="fr-hidden-lg">
                        {' '}
                        - {getCurrentLanguageElement?.title.split('-')[1]}
                      </span>
                    </button>
                    <div className="fr-collapse fr-translate__menu fr-menu" id="translate-516">
                      <ul className="fr-menu__list">
                        {languageItems.map(lang => {
                          const commonProps = {
                            className: 'fr-translate__language fr-nav__link',
                            hrefLang: lang.hrefLang,
                            lang: lang.lang,
                            href: lang.href,
                            onClick: () => {
                              dispatch(setCurrentLocale(lang.lang as RegisteredLocales));
                            },
                          };

                          if (
                            getCurrentLanguageElement &&
                            getCurrentLanguageElement.lang === lang.lang
                          ) {
                            return (
                              <li key={lang.lang}>
                                <a
                                  {...commonProps}
                                  aria-current={
                                    getCurrentLanguageElement &&
                                    getCurrentLanguageElement.lang === lang.lang
                                  }
                                >
                                  {lang.title}
                                </a>
                              </li>
                            );
                          } else {
                            return (
                              <li key={lang.lang}>
                                <a {...commonProps}>{lang.title}</a>
                              </li>
                            );
                          }
                        })}
                      </ul>
                    </div>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="fr-header__menu fr-modal" id="modal-491" aria-labelledby="button-492">
        <div className="fr-container">
          <button className="fr-btn--close fr-btn" aria-controls="modal-491" title="Fermer">
            {closeWording}
          </button>
          <div className="fr-header__menu-links">
            <ul className="fr-btns-group">
              {dataUser && (
                <li>
                  <span className="fr-btn fr-icon-user-lin">
                    {dataUser.prenom} {dataUser.nom_naissance}
                  </span>
                </li>
              )}
              {quickAccessItems &&
                quickAccessItems.map(({ iconId, text, linkProps }) => (
                  <li key={text}>
                    {linkProps.href ? (
                      <a href={linkProps.href} title={text} className={'fr-btn ' + iconId}>
                        {text}
                      </a>
                    ) : (
                      <Button
                        onClick={linkProps.onClick}
                        priority="tertiary no outline"
                        iconId="fr-icon-lock-line"
                        className="connexion-button-mobile"
                      >
                        {text}
                      </Button>
                    )}
                  </li>
                ))}
            </ul>
            <nav role="navigation" className="fr-translate fr-nav">
              <div className="fr-nav__item">
                <button
                  className="fr-translate__btn fr-btn fr-btn--tertiary"
                  aria-controls="translate-517"
                  aria-expanded="false"
                  title="Sélectionner une langue"
                >
                  {getCurrentLanguageElement?.title.split('-')[0]}
                  <span className="fr-hidden-lg">
                    {' '}
                    - {getCurrentLanguageElement?.title.split('-')[1]}
                  </span>
                </button>
                <div className="fr-collapse fr-translate__menu fr-menu" id="translate-517">
                  <ul className="fr-menu__list">
                    {languageItems.map(lang => {
                      if (
                        getCurrentLanguageElement &&
                        getCurrentLanguageElement.lang === lang.lang
                      ) {
                        return (
                          <li key={lang.lang}>
                            <a
                              className="fr-translate__language fr-nav__link"
                              hrefLang={lang.hrefLang}
                              lang={lang.lang}
                              href={lang.href}
                              aria-current={
                                getCurrentLanguageElement &&
                                getCurrentLanguageElement.lang === lang.lang
                              }
                              onClick={() => {
                                dispatch(setCurrentLocale(lang.lang as RegisteredLocales));
                              }}
                            >
                              {lang.title}
                            </a>
                          </li>
                        );
                      } else {
                        return (
                          <li key={lang.lang}>
                            <a
                              className="fr-translate__language fr-nav__link"
                              hrefLang={lang.hrefLang}
                              lang={lang.lang}
                              href={lang.href}
                              onClick={() => {
                                dispatch(setCurrentLocale(lang.lang as RegisteredLocales));
                              }}
                            >
                              {lang.title}
                            </a>
                          </li>
                        );
                      }
                    })}
                  </ul>
                </div>
              </div>
            </nav>
          </div>

          <MainNavigation items={navigation} />
        </div>
      </div>
    </header>
  );
};

export default Header;
