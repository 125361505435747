import React, { FC } from 'react';

import { AccountCreationPage } from 'models/types/app/pages';
import Loader from 'components/Atoms/Loader/Loader';
import { CMS_BASE_URL } from 'services/cmsClient';

interface InfobulleIdentifiantProps {
  accountCreationPage: AccountCreationPage;
}

const InfobulleIdentifiant: FC<InfobulleIdentifiantProps> = ({ accountCreationPage }) => {
  if (!accountCreationPage) {
    return <Loader />;
  }

  return (
    <>
      <div className="fr-col-12 fr-container--fluid infobulle-identifiant-content">
        <div className="fr-col-12 infobulle-identifiant-row">
          <div className="fr-col-12 fr-sm-col-6 fr-mt-0 infobulle-identifiant-col">
            <img
              src={`${CMS_BASE_URL}${accountCreationPage.infobulle_identifiant_image_tj_apres_2020.image.data.attributes.url}`}
              alt={accountCreationPage.infobulle_identifiant_image_tj_avant_2011.balise_alt ?? ''}
            />
            <div>{accountCreationPage.infobulle_texte_identifiant_image_tj_apres_2020}</div>
          </div>
          <div className="fr-col-12 fr-sm-col-6 fr-mt-8v fr-mt-md-0 infobulle-identifiant-col">
            <img
              src={`${CMS_BASE_URL}${accountCreationPage.infobulle_identifiant_image_tj_avant_2020.image.data.attributes.url}`}
              alt={accountCreationPage.infobulle_identifiant_image_tj_avant_2011.balise_alt ?? ''}
            />
            <div>{accountCreationPage.infobulle_texte_identifiant_image_tj_avant_2020}</div>
          </div>
        </div>

        <div className="fr-col-12 fr-mt-4v fr-mt-md-8v infobulle-identifiant-row">
          <div className="fr-col-12 fr-sm-col-6 fr-mt-8v fr-mt-md-0 infobulle-identifiant-col">
            <img
              src={`${CMS_BASE_URL}${accountCreationPage.infobulle_identifiant_image_tj_avant_2011.image.data.attributes.url}`}
              alt={accountCreationPage.infobulle_identifiant_image_tj_avant_2011.balise_alt ?? ''}
            />
            <div>{accountCreationPage.infobulle_texte_identifiant_image_tj_avant_2011}</div>
          </div>
          <div className="fr-col-12 fr-sm-col-6 fr-mt-8v fr-mt-md-0 infobulle-identifiant-col">
            <img
              src={`${CMS_BASE_URL}${accountCreationPage.infobulle_identifiant_image_visa.image.data.attributes.url}`}
              alt={accountCreationPage.infobulle_identifiant_image_tj_avant_2011.balise_alt ?? ''}
            />
            <div>{accountCreationPage.infobulle_texte_identifiant_image_visa}</div>
          </div>
        </div>
      </div>
    </>
  );
};
export default InfobulleIdentifiant;
