export const titresPage = {
  data: {
    id: 1,
    attributes: {
      createdAt: '2023-08-09T01:12:13.251Z',
      updatedAt: '2023-09-08T13:03:22.352Z',
      publishedAt: '2023-08-09T01:12:14.851Z',
      locale: 'fr',
      accueil: 'Accueil',
      tableau_de_bord: 'Tableau de bord',
      compte: 'Mon compte',
      documents: 'Mes documents',
      localisation: 'Où nous trouver ?',
      contact: 'Formulaire de contact',
      mentions_legales: 'Mentions légales',
      accessibilite: 'Accessibilité',
      cookies: 'Cookies',
      rgpd: 'RGPD : tout savoir',
      accueil_non_connecte: 'Le portail des étrangers',
      erreur: 'Erreur',
      asile: 'Espace asile',
      regroupement_familial: 'Ma demande de regroupement familial',
      services_en_ligne: 'Mes services disponibles en ligne',
      articles: 'Tous les articles',
      procedures: 'Mes démarches',
      allocation: 'Mon allocation',
      article: 'Article',
      procedure: 'Démarche',
      retour: 'Mon espace Retour',
      expression_besoin: 'Expression du besoin',
      creation_compte: 'Créez votre compte',
      bagages: 'Mes valises',
      reinsertion: 'Réinsertion',
      accueil_et_integration: 'Accueil et Intégration',
      plan_site: 'Plan du site',
      voir_fil_ariane: 'Voir le fil d’Ariane',
      localizations: {
        data: [
          {
            id: 2,
            attributes: {
              createdAt: '2023-09-08T13:03:22.339Z',
              updatedAt: '2023-09-12T08:12:01.608Z',
              publishedAt: '2023-09-12T08:12:01.602Z',
              locale: 'en',
              accueil: 'Home',
              tableau_de_bord: 'Dashboard',
              compte: 'My account',
              documents: 'My documents',
              localisation: 'Find us',
              contact: ' Contact form',
              mentions_legales: 'Legal notice',
              accessibilite: 'Accessibility',
              cookies: 'Cookie',
              rgpd: 'GDPR : all you need to know',
              accueil_non_connecte: ' The foreigners portal',
              erreur: 'Error',
              asile: 'Asylum space',
              regroupement_familial: 'My application for family reunification',
              services_en_ligne: 'My services available online',
              articles: 'All articles',
              procedures: 'The procedures',
              allocation: 'My allocation',
              article: 'Article',
              procedure: 'Procedures',
              retour: 'My return space',
              expression_besoin: 'Expression of need',
              creation_compte: 'Create your account',
              bagages: 'My luggage',
              reinsertion: 'Reinsertion',
              accueil_et_integration: 'Welcome and Integration',
              plan_site: 'Site map',
              voir_fil_ariane: 'See the breadcrumb trail',
            },
          },
        ],
      },
      erreur_auth: "Erreur d'authentification",
    },
  },
  meta: {},
};
