import { Beneficiaire } from 'models/types/api/rf';
import { AddressForm } from 'models/types/app/demandeRf';

export const addressAppToApiFormatter = (data: AddressForm, id: string | null) => {
  const adresseId = data.choix_adresse
    ? data.choix_adresse !== 'autre_adresse'
      ? data.choix_adresse
      : null
    : null;

  const beneficiaire: Omit<Beneficiaire, 'documents'> = {
    id: id,
    adresse: {
      id: adresseId,
      adresse: {
        numero: data.numero_de_voie ? data.numero_de_voie : null,
        codePostal: data.code_postal ? data.code_postal : null,
        pays: data.pays ? data.pays : null,
        ville: data.localite ? data.localite : null,
        adresse1: data.nom_de_rue ? data.nom_de_rue : null,
        adresse2: data.complement_adresse ? data.complement_adresse : null,
        digicode: null,
        interphone: null,
        bano: null,
        email: data.adresse_email ? data.adresse_email : null,
        telephone: data.telephone_adresse ? data.telephone_adresse : null,
        province: data.province ? data.province : null,
      },
    },
  };

  return beneficiaire;
};
