import React from 'react';
import { useWatch } from 'react-hook-form';
import CallOut from '@codegouvfr/react-dsfr/CallOut';

import DateInputContainer from 'components/Molecules/Forms/DateInputContainer/DateInputContainer';
import CommonRowContainer from 'components/Molecules/Forms/CommonRowContainer/CommonRowContainer';
import AutocompleteContainer from 'components/Molecules/Forms/AutocompleteContainer/AutocompleteContainer';
import TextInputContainer from 'components/Molecules/Forms/TextInputContainer/TextInputContainer';
import Loader from 'components/Atoms/Loader/Loader';
import { getRfCmsContents } from 'pages/DemandeRF/Helpers/Cms/getRfCmsContents';
import {
  useGetUsagerListeNationalitesQuery,
  useGetUsagerListePaysQuery,
} from 'redux/rtk/api/apiCommon';

import EnfantActeNaissance from './EnfantActeNaissance';

type EnfantInfoNaissanceProps = {
  lang: string;
  fieldArrayMapping: any;
  filesMapping: any;
  isNew: string;
  formFieldsMapping: any;
  control: any;
  formState: any;
  nationalitesNonEligibles: Array<string>;
};

const EnfantInfoNaissance: React.FC<EnfantInfoNaissanceProps> = ({
  lang,
  fieldArrayMapping,
  filesMapping,
  isNew,
  formFieldsMapping,
  control,
  formState,
  nationalitesNonEligibles,
}) => {
  const {
    data: listePays,
    isError: errorPays,
    isFetching: loadingPays,
  } = useGetUsagerListePaysQuery();

  const {
    data: listeNationalite,
    isError: errorNationalite,
    isFetching: loadingNationalite,
  } = useGetUsagerListeNationalitesQuery();

  const nationaliteEnfant = useWatch({
    control,
    name: 'nationalite_enfant',
  });

  if (errorPays || errorNationalite) {
    return <div>Redirect page error</div>;
  }

  if (loadingPays || loadingNationalite) {
    return <Loader />;
  }
  const { bloc2Cms } = getRfCmsContents(lang);

  return (
    <>
      <CommonRowContainer
        legend={bloc2Cms.titre_date_naissance_enfant}
        lang={lang}
        children={[
          <DateInputContainer
            customClass="fr-col-12 fr-mt-4v fr-mt-lg-0v fr-mt-md-0v fr-col-sm-10"
            {...formFieldsMapping.date_naissance_enfant}
          />,
        ]}
      />
      {formState.errors.date_naissance_enfant &&
      formState.errors.date_naissance_enfant.type === 'mustBeMinor' ? (
        <CallOut
          iconId="fr-icon-info-line"
          title={bloc2Cms.bulle_aide_enfant_majeur.titre}
          className="callout-alert fr-mt-2w"
        >
          {bloc2Cms.bulle_aide_enfant_majeur.contenu}
        </CallOut>
      ) : (
        <>
          <CommonRowContainer
            children={[
              <AutocompleteContainer
                {...formFieldsMapping.pays_naissance_enfant}
                payload={listePays}
                source="API"
                customClass="fr-col-12 fr-mt-4v fr-mt-lg-0v fr-mt-md-0v fr-col-sm-10"
              />,

              <TextInputContainer
                customClass="fr-col-12 fr-mt-4v fr-mt-lg-0v fr-mt-md-0v fr-col-sm-10"
                {...formFieldsMapping.ville_naissance_enfant}
              />,
            ]}
          />
          <CommonRowContainer
            legend={bloc2Cms.titre_nationalite}
            infobulleContent={bloc2Cms.infobulle_vous_etes_de_nationalite}
            infobulleCustomModal
            lang={lang}
            children={[
              <AutocompleteContainer
                {...formFieldsMapping.nationalite_enfant}
                payload={listeNationalite}
                source="API"
                customClass="fr-col-12 fr-mt-4v fr-mt-lg-0v fr-mt-md-0v fr-col-sm-10"
              />,
            ]}
          />
          {nationaliteEnfant && nationalitesNonEligibles.includes(nationaliteEnfant) ? (
            <>
              {nationaliteEnfant === 'FRA' ? (
                <CallOut
                  iconId="fr-icon-info-line"
                  title={bloc2Cms.bulle_aide_enfant_francais.titre}
                  className="callout-alert fr-mt-2w"
                >
                  {bloc2Cms.bulle_aide_enfant_francais.contenu}
                </CallOut>
              ) : (
                <CallOut
                  iconId="fr-icon-info-line"
                  title={bloc2Cms.bulle_aide_enfant_eur.titre}
                  className="callout-alert fr-mt-2w"
                >
                  {bloc2Cms.bulle_aide_enfant_eur.contenu}
                </CallOut>
              )}
            </>
          ) : (
            <EnfantActeNaissance
              fieldArrayMapping={fieldArrayMapping}
              filesMapping={filesMapping}
              isNew={isNew}
              formFieldsMapping={formFieldsMapping}
              control={control}
              lang={lang}
            />
          )}
        </>
      )}
    </>
  );
};

export default EnfantInfoNaissance;
