import React from 'react';
import { useController } from 'react-hook-form';

import SelectInput from 'components/Atoms/Forms/SelectInput/SelectInput';
import { computeIsValidDsfr } from 'helpers/app/ComponentsStatusHelper';

interface SelectInputContainerProps {
  label: string | React.ReactNode;
  hint?: string;
  required?: boolean;
  defaultValue: string;
  options: any;
  name: string;
  control: any;
  customOnChange?: (value: any) => void;
  customClass?: string;
}

const SelectInputContainer: React.FC<SelectInputContainerProps> = ({
  label,
  hint,
  required,
  defaultValue,
  options,
  name,
  control,
  customOnChange,
  customClass,
}) => {
  const { field, fieldState } = useController({
    name,
    control,
  });

  return (
    <>
      <div
        className={
          customClass
            ? customClass
            : 'fr-col-12 fr-mt-4v fr-mt-lg-0v fr-mt-md-0v fr-col-lg-4 fr-col-md-4'
        }
      >
        <SelectInput
          label={required ? label + '*' : label}
          hint={hint}
          options={options}
          defaultValue={defaultValue}
          name={name}
          control={control}
          stateRelatedMessage={fieldState?.error?.message}
          state={computeIsValidDsfr(fieldState.isTouched, fieldState.isDirty, fieldState.error)}
          customOnChange={customOnChange}
          field={field}
        />
      </div>
    </>
  );
};

export default SelectInputContainer;
