import React, { useState } from 'react';
import { Select } from '@codegouvfr/react-dsfr/Select';

interface SelectInputProps {
  label: string | React.ReactNode;
  hint?: string;
  defaultValue: string;
  customOnChange?: any;
  options: { label: string; value: string }[];
  name: string;
  control: any;
  state: string;
  field: any;
  stateRelatedMessage?: string;
}

const SelectInput: React.FC<SelectInputProps> = ({
  label,
  hint,
  defaultValue,
  options,
  control,
  name,
  state,
  field,
  customOnChange,
  stateRelatedMessage,
}) => {
  const [preselection, setPreselection] = useState<string>('');
  const handleChange = (valueChoose: any) => {
    setPreselection(valueChoose);
  };

  return (
    <Select
      hint={hint}
      label={label}
      nativeSelectProps={{
        onChange: event => {
          handleChange(event.target.value);
          if (customOnChange) {
            customOnChange(event.target.value);
          }
        },
        value: field.value,
        onBlur: field.onBlur,
        preselection,
      }}
      stateRelatedMessage={stateRelatedMessage}
      state={state}
      name={name}
      control={control}
      {...field}
      type="button"
      role="combobox"
      aria-haspopup="listbox"
      aria-controls="options"
      aria-activedescendant={preselection}
      aria-labelledby={label}
      aria-autocomplete="none"
    >
      <React.Fragment key=".0">
        <option disabled hidden selected value="">
          {defaultValue}
        </option>
        {options.map((option: { label: string; value: string }) => {
          return (
            <option
              id={option.value}
              value={option.value}
              role="option"
              aria-selected={preselection === option.value}
              selected={preselection === option.value}
            >
              {option.label}
            </option>
          );
        })}
      </React.Fragment>
    </Select>
  );
};

export default SelectInput;
