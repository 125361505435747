import { FC } from 'react';
import { useSelector } from 'react-redux';

import {
  getFacebookCookiesAcceptance,
  getTwitterCookiesAcceptance,
  getYoutubeCookiesAcceptance,
} from 'redux/cookies/selectors';

export type SocialbannerProps = {
  lien_twitter: string;
  lien_facebook: string;
  lien_youtube: string;
  nous_suivre: string;
  sur_les_reseaux: string;
  title_lien_facebook: string;
  title_lien_twitter: string;
  title_lien_youtube: string;
};

const Socialbanner: FC<SocialbannerProps> = ({
  lien_twitter,
  lien_facebook,
  lien_youtube,
  nous_suivre,
  sur_les_reseaux,
  title_lien_facebook,
  title_lien_twitter,
  title_lien_youtube,
}) => {
  const areFacebookCookiesAccepted = useSelector(getFacebookCookiesAcceptance);
  const areTwitterCookiesAccepted = useSelector(getTwitterCookiesAcceptance);
  const areYoutubeCookiesAccepted = useSelector(getYoutubeCookiesAcceptance);

  return (
    <div className="fr-follow">
      <div className="fr-container">
        <div className="fr-grid-row">
          <div className="fr-col-12">
            <div className="fr-follow__social">
              <h2 className="fr-h5">
                {nous_suivre}
                <br /> {sur_les_reseaux}
              </h2>
              <ul className="fr-btns-group">
                <li>
                  <a
                    className={
                      areFacebookCookiesAccepted
                        ? 'fr-btn--facebook fr-btn'
                        : 'fr-btn--facebook fr-btn link-disabled'
                    }
                    href={areFacebookCookiesAccepted ? lien_facebook : '#'}
                    target="_blank"
                    title={title_lien_facebook}
                    rel="noreferrer"
                  >
                    facebook
                  </a>
                </li>
                <li>
                  <a
                    className={
                      areTwitterCookiesAccepted
                        ? 'fr-btn--twitter-x fr-btn'
                        : 'fr-btn--twitter-x fr-btn link-disabled'
                    }
                    href={areTwitterCookiesAccepted ? lien_twitter : '#'}
                    target="_blank"
                    title={title_lien_twitter}
                    rel="noreferrer"
                  >
                    twitter-x
                  </a>
                </li>
                <li>
                  <a
                    className={
                      areYoutubeCookiesAccepted
                        ? 'fr-btn--youtube fr-btn'
                        : 'fr-btn--youtube fr-btn link-disabled'
                    }
                    href={areYoutubeCookiesAccepted ? lien_youtube : '#'}
                    target="_blank"
                    title={title_lien_youtube}
                    rel="noreferrer"
                  >
                    youtube
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Socialbanner;
