/* eslint-disable complexity */
import { InferType } from 'yup';
import { FC, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useAuth } from 'react-oidc-context';
import { DevTool } from '@hookform/devtools';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stepper } from '@codegouvfr/react-dsfr/Stepper';

import { useGetAccountCreationCmsPageQuery } from 'redux/rtk/cms/cmsAccountCreation';
import { setCreationDeCompte } from 'redux/pages';
import { getAccountCreationPage } from 'redux/pages/selectors';
import Loader from 'components/Atoms/Loader/Loader';
import { useNewUserMutation } from 'redux/rtk/api/apiUser';
import { CreateUserResponseStatusCode } from 'models/types/app/creationCompteStatus';
import { isKeycloakAlive } from 'redux/keycloak/selectors';
import { getValidationSchema } from './CreationCompte.schema';
import { TABLEAU_DE_BORD } from 'routes/path';
import { getCurrentLocale } from 'redux/locale/selectors';

import { creationCompteQuery } from './CreationCompte.query';
import FormulaireStep1 from './Forms/FormulaireStep1';
import FormulaireStep2 from './Forms/FormulaireStep2';
import Results from './Results/Results';
import './CreationCompte.scss';

export type FormulaireCreationCompteValues = InferType<ReturnType<typeof getValidationSchema>>;

const CreationCompte: FC = () => {
  const formId = 'creation-compte-form';
  const lang = useSelector(getCurrentLocale) as string;
  const [step, setStep] = useState<number>(1);
  const [errorCodeStatus, setErrorCodeStatus] = useState<CreateUserResponseStatusCode>();
  const auth = useAuth();
  const navigate = useNavigate();

  if (auth.isAuthenticated) {
    navigate(TABLEAU_DE_BORD);
  }

  const keycloakAlive = useSelector(isKeycloakAlive);
  const [newUser, { isSuccess, isError }] = useNewUserMutation();

  const dispatch = useDispatch();
  const {
    data: accountCreationCms,
    error: accountCreationCmsError,
    isLoading: isAccountCreationCmsLoading,
  } = useGetAccountCreationCmsPageQuery({
    qs: creationCompteQuery,
    locale: lang !== '' ? lang : 'fr',
  });

  const AccountCreationPage = useSelector(getAccountCreationPage);

  const validationSchema = useMemo(
    () => getValidationSchema(AccountCreationPage),
    [AccountCreationPage],
  );

  const { handleSubmit, getFieldState, trigger, control } = useForm<FormulaireCreationCompteValues>(
    {
      resolver: yupResolver(validationSchema),
      defaultValues: {
        prenom: '',
        prenom2: '',
        prenom3: '',
        nom_naissance: '',
        identifiant: '',
        adresse_mail: '',
        date_naissance: '',
        pays_naissance: '',
        nationalite: '',
      },
      mode: 'onChange',
    },
  );
  useEffect(() => {
    if (accountCreationCms) {
      dispatch(setCreationDeCompte(accountCreationCms));
    }
  }, [accountCreationCms, dispatch]);

  const onSubmit: SubmitHandler<FormulaireCreationCompteValues> = async (
    values: FormulaireCreationCompteValues,
  ) => {
    try {
      await newUser({
        nom: [values.nom_naissance],
        prenoms: [values.prenom, values.prenom2, values.prenom3].filter(Boolean) as string[],
        numero_agdref: values.identifiant,
        adresse_mail: values.adresse_mail,
        pays_naissance: values.pays_naissance,
        nationalite: values.nationalite,
        date_naissance: values.date_naissance,
      });
    } catch (e: any) {
      console.log('error', e, e.response.status);
      setErrorCodeStatus(e.response.status);
    }
  };

  const resultatComponentProps = useMemo<any>(() => {
    return errorCodeStatus === 201 ? 'success' : 'error';
  }, [errorCodeStatus]);

  useEffect(() => {
    if (isSuccess) {
      setErrorCodeStatus(201);
    } else if (isError) {
      setErrorCodeStatus(400);
    }
  }, [isSuccess, isError, newUser]);

  if (isAccountCreationCmsLoading) {
    return <Loader />;
  }

  if (accountCreationCmsError) {
    return <div>Redirect page error</div>;
  }

  if (!accountCreationCms) {
    return null;
  }

  return (
    <>
      <DevTool control={control} />
      <main>
        <div
          id="creation-compte"
          className="fr-container fr-col-12 fr-col-md-9 fr-col-lg-6 fr-mt-7v fr-mt-md-7v fr-mb-8v"
        >
          <div className="fr-grid-row fr-grid-row--gutters fr-grid-row--center">
            <div className="fr-col-12">
              {resultatComponentProps && errorCodeStatus ? (
                <>
                  <h2 className="fr-mt-4v fr-mt-md-9v fr-mb-10v fr-mb-md-14v">
                    {resultatComponentProps === 'success'
                      ? AccountCreationPage.titre_creation_success
                      : AccountCreationPage.titre_creation_error}
                  </h2>
                  <Results
                    success={resultatComponentProps}
                    keycloakAlive={keycloakAlive}
                    accountCreationPage={AccountCreationPage}
                  />
                </>
              ) : (
                <>
                  <a className="fr-link fr-icon-arrow-left-line fr-link--icon-left " href="/">
                    {AccountCreationPage.bouton_retour_accueil}
                  </a>
                  <h2 className="fr-mt-10v fr-mt-md-14v">{AccountCreationPage.titre}</h2>
                  <p className="fr-mb-10v fr-mb-md-14v">{AccountCreationPage.sous_titre}</p>
                  <div className="form-bg fr-py-10v fr-px-4v fr-py-md-14v fr-px-md-13w">
                    <div className="fr-col-12">
                      <Stepper
                        currentStep={step}
                        nextTitle={AccountCreationPage.titre_etape_2}
                        stepCount={2}
                        title={
                          step === 2
                            ? AccountCreationPage.titre_etape_2
                            : AccountCreationPage.titre_etape_1
                        }
                      />
                    </div>
                    <div className="separator"></div>
                    <form className="fr-col-12" id={formId} onSubmit={handleSubmit(onSubmit)}>
                      <div className={`form-step1 ${step === 2 ? 'hidden' : ''}`}>
                        <FormulaireStep1
                          setStep={setStep}
                          control={control}
                          trigger={trigger}
                          accountCreationPage={AccountCreationPage}
                          getFieldState={getFieldState}
                        />
                      </div>
                      <div className={`form-step2 fr-col-12 ${step === 1 ? 'hidden' : ''}`}>
                        <FormulaireStep2
                          setStep={setStep}
                          control={control}
                          getFieldState={getFieldState}
                          accountCreationPage={AccountCreationPage}
                          lang={lang}
                        />
                      </div>
                    </form>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </main>
    </>
  );
};
export default CreationCompte;
