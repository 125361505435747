import * as yup from 'yup';

import { FormHelper } from 'helpers/app/FormHelper';
import { NO_SPECIAL_CHAR_REGEX } from 'helpers/app/regexHelper';
import {
  DemandeRfBloc3,
  DemandeRfErrors,
  DemandeRfUtilitaire,
} from 'models/types/cms/demandeRF/type';
import { SITUATION_LOGEMENT, TYPE_HEBERGEUR } from 'constants/accomodation';

export const getAccomodationValidationSchema = (
  errorsRfCms: DemandeRfErrors,
  bloc3Cms: DemandeRfBloc3,
  demandeUtilitaire: DemandeRfUtilitaire,
) => {
  const formHelper = new FormHelper(errorsRfCms);

  return yup
    .object()
    .shape({
      situation_logement: formHelper.stringSchema(true, bloc3Cms.nature_resident),
      adresse_postale: yup
        .object()
        .typeError(errorsRfCms.adresse_postale_invalide)
        .shape({
          houseNumber: formHelper.stringSchema(false),
          street: formHelper.stringSchema(false),
          city: formHelper.stringSchema(false),
          postCode: formHelper.stringSchema(false),
          cityCode: formHelper.stringSchema(false),
          value: formHelper.stringSchema(false),
          label: formHelper.stringSchema(false),
        })
        .when('adresse_non_trouvee', ([adresseNonTrouvee]) => {
          return adresseNonTrouvee === false
            ? yup
                .object()
                .typeError(errorsRfCms.adresse_postale_invalide)
                .shape({
                  houseNumber: formHelper.stringSchema(false),
                  street: formHelper.stringSchema(false),
                  city: formHelper.stringSchema(false),
                  postCode: formHelper.stringSchema(false),
                  cityCode: yup.string().required(errorsRfCms.adresse_postale_invalide),
                  value: formHelper.stringSchema(false),
                  label: formHelper.stringSchema(false),
                })
            : yup
                .object()
                .typeError(errorsRfCms.adresse_postale_invalide)
                .shape({
                  houseNumber: formHelper.stringSchema(false),
                  street: formHelper.stringSchema(false),
                  city: formHelper.stringSchema(false),
                  postCode: formHelper.stringSchema(false),
                  cityCode: formHelper.stringSchema(false),
                  value: formHelper.stringSchema(false),
                  label: formHelper.stringSchema(false),
                });
        }),
      ville_ou_commune: yup
        .object()
        .typeError(errorsRfCms.adresse_postale_invalide)
        .shape({
          houseNumber: formHelper.stringSchema(false),
          street: formHelper.stringSchema(false),
          city: formHelper.stringSchema(false),
          postCode: formHelper.stringSchema(false),
          cityCode: formHelper.stringSchema(false),
          value: formHelper.stringSchema(false),
          label: formHelper.stringSchema(false),
        })
        .when('adresse_non_trouvee', ([adresseNonTrouvee]) => {
          return adresseNonTrouvee
            ? yup
                .object()
                .typeError(errorsRfCms.adresse_postale_invalide)
                .shape({
                  houseNumber: formHelper.stringSchema(false),
                  street: formHelper.stringSchema(false),
                  city: formHelper.stringSchema(false),
                  postCode: formHelper.stringSchema(false),
                  cityCode: formHelper.stringSchema(true),
                  value: formHelper.stringSchema(false),
                  label: formHelper.stringSchema(false),
                })
            : // todo field error
              yup
                .object()
                .typeError('Veuillez saisir une adresse valide')
                .shape({
                  houseNumber: formHelper.stringSchema(false),
                  street: formHelper.stringSchema(false),
                  city: formHelper.stringSchema(false),
                  postCode: formHelper.stringSchema(false),
                  cityCode: formHelper.stringSchema(false),
                  value: formHelper.stringSchema(false),
                  label: formHelper.stringSchema(false),
                });
        }),
      complement_adresse: formHelper.stringSchema(false),
      numero_digicode: formHelper.stringSchema(false),
      nom_interphone: formHelper.stringSchema(false),
      logement_famille: formHelper.stringSchema(true, bloc3Cms.famille_loge_a_cette_adresse),
      adresse_non_trouvee: yup.boolean().notRequired(),
      numero_de_voie: formHelper.stringSchema(false),
      nom_de_rue: yup.string().when('adresse_non_trouvee', ([adresseNonTrouvee]) => {
        return adresseNonTrouvee
          ? formHelper.stringSchema(true, demandeUtilitaire.nom_rue)
          : formHelper.stringSchema(false);
      }),
      code_postal: yup
        .string()
        .matches(NO_SPECIAL_CHAR_REGEX, errorsRfCms.caracteres_speciaux_interdits)
        .when('adresse_non_trouvee', ([adresseNonTrouvee]) => {
          return adresseNonTrouvee
            ? formHelper.stringSchema(true, demandeUtilitaire.code_postal)
            : formHelper.stringSchema(false);
        }),
      situation_futur_logement: yup.string().when('logement_famille', ([logementFamille]) => {
        return logementFamille === 'non'
          ? formHelper.stringSchema(true, bloc3Cms.nature_resident)
          : formHelper.stringSchema(false);
      }),
      entree_futur_logement: yup.string().when('logement_famille', ([logementFamille]) => {
        return logementFamille === 'non'
          ? formHelper.stringSchema(true, bloc3Cms.date_dispo_logement)
          : formHelper.stringSchema(false);
      }), //formHelper.stringSchema(true),
      adresse_futur_logement_non_trouvee: yup.boolean().notRequired(),
      adresse_postale_futur_logement: yup
        .object()
        .typeError('Veuillez saisir une adresse valide')
        .shape({
          houseNumber: formHelper.stringSchema(false),
          street: formHelper.stringSchema(false),
          city: formHelper.stringSchema(false),
          postCode: formHelper.stringSchema(false),
          cityCode: formHelper.stringSchema(false),
          value: formHelper.stringSchema(false),
          label: formHelper.stringSchema(false),
        })
        .when(
          ['adresse_futur_logement_non_trouvee', 'logement_famille'],
          ([adresseFuturLogementNonTrouvee, logementFamille]) => {
            return adresseFuturLogementNonTrouvee === false && logementFamille === 'non'
              ? yup
                  .object()
                  .typeError('Veuillez saisir une adresse valide')
                  .shape({
                    houseNumber: formHelper.stringSchema(false),
                    street: formHelper.stringSchema(false),
                    city: formHelper.stringSchema(false),
                    postCode: formHelper.stringSchema(false),
                    cityCode: formHelper.stringSchema(true),
                    value: formHelper.stringSchema(false),
                    label: formHelper.stringSchema(false),
                  })
              : yup.object().shape({
                  houseNumber: formHelper.stringSchema(false),
                  street: formHelper.stringSchema(false),
                  city: formHelper.stringSchema(false),
                  postCode: formHelper.stringSchema(false),
                  cityCode: formHelper.stringSchema(false),
                  value: formHelper.stringSchema(false),
                  label: formHelper.stringSchema(false),
                });
          },
        ),
      complement_adresse_futur_logement: formHelper.stringSchema(false),
      numero_digicode_futur_logement: formHelper.stringSchema(false),
      nom_interphone_futur_logement: yup
        .string()
        .when(
          ['logement_famille', 'adresse_futur_logement_non_trouvee'],
          ([logementFamille, adresseFuturLogementNonTrouvee]) => {
            if (logementFamille === 'non') {
              if (adresseFuturLogementNonTrouvee) {
                return formHelper.stringSchema(false);
              }
              return formHelper.stringSchema(false);
            }
            return formHelper.stringSchema(false);
          },
        ),
      numero_de_voie_futur_logement: formHelper.stringSchema(false),
      nom_de_rue_futur_logement: yup
        .string()
        .when(
          ['logement_famille', 'adresse_futur_logement_non_trouvee'],
          ([logementFamille, adresseFuturLogementNonTrouvee]) => {
            if (logementFamille === 'non') {
              if (adresseFuturLogementNonTrouvee) {
                return formHelper.stringSchema(true, demandeUtilitaire.nom_rue);
              }
              return formHelper.stringSchema(false);
            }
            return formHelper.stringSchema(false);
          },
        ),
      code_postal_futur_logement: yup
        .string()
        .matches(NO_SPECIAL_CHAR_REGEX, errorsRfCms.caracteres_speciaux_interdits)
        .when(
          ['logement_famille', 'adresse_futur_logement_non_trouvee'],
          ([logementFamille, adresseFuturLogementNonTrouvee]) => {
            if (logementFamille === 'non') {
              if (adresseFuturLogementNonTrouvee) {
                return formHelper.stringSchema(true, demandeUtilitaire.code_postal);
              }
              return formHelper.stringSchema(false);
            }
            return formHelper.stringSchema(false);
          },
        ),
      ville_ou_commune_futur_logement: yup
        .object()
        .typeError('Veuillez saisir une adresse valide')
        .shape({
          houseNumber: formHelper.stringSchema(false),
          street: formHelper.stringSchema(false),
          city: formHelper.stringSchema(false),
          postCode: formHelper.stringSchema(false),
          cityCode: formHelper.stringSchema(false),
          value: formHelper.stringSchema(false),
          label: formHelper.stringSchema(false),
        })
        .when(
          ['logement_famille', 'adresse_futur_logement_non_trouvee'],
          ([logementFamille, adresseFuturLogementNonTrouvee]) => {
            return logementFamille === 'non' && adresseFuturLogementNonTrouvee
              ? yup
                  .object()
                  .typeError('Veuillez saisir une adresse valide')
                  .shape({
                    houseNumber: formHelper.stringSchema(false),
                    street: formHelper.stringSchema(false),
                    city: formHelper.stringSchema(false),
                    postCode: formHelper.stringSchema(false),
                    cityCode: formHelper.stringSchema(true),
                    value: formHelper.stringSchema(false),
                    label: formHelper.stringSchema(false),
                  })
              : yup
                  .object()
                  .typeError('Veuillez saisir une adresse valide')
                  .shape({
                    houseNumber: formHelper.stringSchema(false),
                    street: formHelper.stringSchema(false),
                    city: formHelper.stringSchema(false),
                    postCode: formHelper.stringSchema(false),
                    cityCode: formHelper.stringSchema(false),
                    value: formHelper.stringSchema(false),
                    label: formHelper.stringSchema(false),
                  });
          },
        ),
      bail_complet_files: yup.array().when('situation_logement', ([situationLogement]) => {
        if (situationLogement === SITUATION_LOGEMENT.LOCATAIRE) {
          return formHelper.requiredFileSchema();
        }
        return formHelper.notRequiredFileSchema();
      }),
      quittance_loyer_files: yup.array().when('situation_logement', ([situationLogement]) => {
        if (situationLogement === SITUATION_LOGEMENT.LOCATAIRE) {
          return formHelper.requiredFileSchema();
        }
        return formHelper.notRequiredFileSchema();
      }),
      justificatif_domicile_files: yup
        .array()
        .when(['situation_logement', 'type_hebergeur'], ([situationLogement, typeHebergeur]) => {
          if (
            situationLogement === SITUATION_LOGEMENT.LOCATAIRE ||
            situationLogement === SITUATION_LOGEMENT.SOUS_LOCATAIRE ||
            situationLogement === SITUATION_LOGEMENT.PROPRIETAIRE
          ) {
            return formHelper.requiredFileSchema();
          } else if (
            situationLogement === SITUATION_LOGEMENT.GRATUIT &&
            (typeHebergeur === TYPE_HEBERGEUR.EMPLOYEUR || typeHebergeur === TYPE_HEBERGEUR.AUTRE)
          ) {
            return formHelper.requiredFileSchema();
          }
          return formHelper.notRequiredFileSchema();
        }),
      engagement_sous_location_files: yup
        .array()
        .when('situation_logement', ([situationLogement]) => {
          return situationLogement === SITUATION_LOGEMENT.SOUS_LOCATAIRE
            ? formHelper.requiredFileSchema()
            : formHelper.notRequiredFileSchema();
        }),
      justificatif_hebergeant_files: yup
        .array()
        .when(['situation_logement', 'type_hebergeur'], ([situationLogement, typeHebergeur]) => {
          return situationLogement === SITUATION_LOGEMENT.GRATUIT &&
            typeHebergeur === TYPE_HEBERGEUR.PARTICULIER
            ? formHelper.requiredFileSchema()
            : formHelper.notRequiredFileSchema();
        }),
      attestation_domicile_hebergeant_files: yup
        .array()
        .when(['situation_logement', 'type_hebergeur'], ([situationLogement, typeHebergeur]) => {
          return situationLogement === SITUATION_LOGEMENT.GRATUIT &&
            typeHebergeur === TYPE_HEBERGEUR.PARTICULIER
            ? formHelper.requiredFileSchema()
            : formHelper.notRequiredFileSchema();
        }),
      attestation_mise_disposition_logement_files: yup.array().notRequired(),
      attestation_domicile_employeur_files: yup
        .array()
        .when(['situation_logement', 'type_hebergeur'], ([situationLogement, typeHebergeur]) => {
          return situationLogement === SITUATION_LOGEMENT.GRATUIT &&
            typeHebergeur === TYPE_HEBERGEUR.EMPLOYEUR
            ? formHelper.requiredFileSchema()
            : formHelper.notRequiredFileSchema();
        }),
      acte_propriete_files: yup.array().when('situation_logement', ([situationLogement]) => {
        return situationLogement === SITUATION_LOGEMENT.PROPRIETAIRE
          ? formHelper.requiredFileSchema()
          : formHelper.notRequiredFileSchema();
      }),
      bail_quittance_hebergeant_files: yup
        .array()
        .when('situation_logement', ([situationLogement]) => {
          return situationLogement === SITUATION_LOGEMENT.GRATUIT
            ? formHelper.requiredFileSchema()
            : formHelper.notRequiredFileSchema();
        }),
      justificatif_acquisition_futur_logement_files: yup.array().notRequired(),
      promesse_location_files: yup.array().notRequired(),
      attestation_hebergement_employeur_files: yup.array().notRequired(),
      promesse_location_autre_files: yup
        .array()
        .when(
          ['logement_famille', 'situation_futur_logement'],
          ([logementFamille, situationFuturLogement]) => {
            return logementFamille === 'non' &&
              situationFuturLogement === SITUATION_LOGEMENT.LOCATAIRE
              ? formHelper.requiredFileSchema()
              : formHelper.notRequiredFileSchema();
          },
        ),
      attestation_mise_disposition_logement_autre_files: yup
        .array()
        .when(
          ['logement_famille', 'situation_futur_logement', 'type_futur_hebergement'],
          ([logementFamille, situationFuturLogement, typeFuturHebergement]) => {
            if (logementFamille === 'non') {
              if (
                situationFuturLogement === SITUATION_LOGEMENT.LOCATAIRE ||
                situationFuturLogement === SITUATION_LOGEMENT.SOUS_LOCATAIRE ||
                situationFuturLogement === SITUATION_LOGEMENT.PROPRIETAIRE
              ) {
                return formHelper.requiredFileSchema();
              } else if (situationFuturLogement === SITUATION_LOGEMENT.GRATUIT) {
                if (
                  typeFuturHebergement === TYPE_HEBERGEUR.EMPLOYEUR ||
                  typeFuturHebergement === TYPE_HEBERGEUR.AUTRE
                ) {
                  return formHelper.requiredFileSchema();
                }
              }
            }
            return formHelper.notRequiredFileSchema();
          },
        ),
      engagement_sous_location_autre_files: yup
        .array()
        .when(
          ['logement_famille', 'situation_futur_logement'],
          ([logementFamille, situationFuturLogement]) => {
            return logementFamille === 'non' &&
              situationFuturLogement === SITUATION_LOGEMENT.SOUS_LOCATAIRE
              ? formHelper.requiredFileSchema()
              : formHelper.notRequiredFileSchema();
          },
        ),
      justificatif_hebergeant_autre_files: yup
        .array()
        .when(
          ['situation_futur_logement', 'type_futur_hebergement'],
          ([situationFuturLogement, typeFuturHebergement]) => {
            return situationFuturLogement === SITUATION_LOGEMENT.GRATUIT &&
              typeFuturHebergement === TYPE_HEBERGEUR.PARTICULIER
              ? formHelper.requiredFileSchema()
              : formHelper.notRequiredFileSchema();
          },
        ),
      attestation_domicile_hebergeant_autre_files: yup
        .array()
        .when(
          ['situation_futur_logement', 'type_futur_hebergement'],
          ([situationFuturLogement, typeFuturHebergement]) => {
            return situationFuturLogement === SITUATION_LOGEMENT.GRATUIT &&
              typeFuturHebergement === TYPE_HEBERGEUR.PARTICULIER
              ? formHelper.requiredFileSchema()
              : formHelper.notRequiredFileSchema();
          },
        ),
      justificatif_acquisition_futur_logement_autre_files: yup
        .array()
        .when(
          ['logement_famille', 'situation_futur_logement'],
          ([logementFamille, situationFuturLogement]) => {
            return logementFamille === 'non' &&
              situationFuturLogement === SITUATION_LOGEMENT.PROPRIETAIRE
              ? formHelper.requiredFileSchema()
              : formHelper.notRequiredFileSchema();
          },
        ),
      bail_quittance_hebergeant_autre_files: yup
        .array()
        .when(
          ['situation_futur_logement', 'type_futur_hebergement'],
          ([situationFuturLogement, typeFuturHebergement]) => {
            return situationFuturLogement === SITUATION_LOGEMENT.GRATUIT &&
              typeFuturHebergement === TYPE_HEBERGEUR.PARTICULIER
              ? formHelper.requiredFileSchema()
              : formHelper.notRequiredFileSchema();
          },
        ),
      quittance_loyer_autre_files: yup.array().notRequired(),
      attestation_domicile_employeur_autre_files: yup.array().notRequired(),
      bail_complet_autre_files: yup.array().notRequired(),
      justificatif_domicile_autre_files: yup.array().notRequired(),
      attestation_hebergement_employeur_autre_files: yup.array().notRequired(),
      acte_propriete_autre_files: yup.array().notRequired(),
      type_hebergeur: yup.string().when('situation_logement', ([situationLogement]) => {
        return situationLogement === SITUATION_LOGEMENT.GRATUIT
          ? formHelper.stringSchema(true, bloc3Cms.titre_selecteur_hebergeur)
          : formHelper.stringSchema(false);
      }),
      nom_hebergeur: yup
        .string()
        .when(['situation_logement', 'type_hebergeur'], ([situationLogement, typeHebergeur]) => {
          return situationLogement === SITUATION_LOGEMENT.GRATUIT &&
            typeHebergeur !== TYPE_HEBERGEUR.EMPLOYEUR
            ? formHelper.stringSchema(true, bloc3Cms.nom_hebergeur)
            : formHelper.stringSchema(false);
        }),
      type_futur_hebergement: yup
        .string()
        .when('situation_futur_logement', ([situationLogement]) => {
          return situationLogement === SITUATION_LOGEMENT.GRATUIT
            ? formHelper.stringSchema(true, bloc3Cms.nom_hebergeur)
            : formHelper.stringSchema(false);
        }),
      nom_futur_hebergeur: yup
        .string()
        .when(
          ['situation_futur_logement', 'type_futur_hebergement'],
          ([situationFuturLogement, typeFuturHebergement]) => {
            return situationFuturLogement === SITUATION_LOGEMENT.GRATUIT &&
              typeFuturHebergement === TYPE_HEBERGEUR.EMPLOYEUR
              ? formHelper.stringSchema(true, bloc3Cms.nom_hebergeur)
              : formHelper.stringSchema(false);
          },
        ),
      validation: yup.string().notRequired(),
      //      [HONEYPOT_NAME]: yup.string(),
    })
    .defined();
};
