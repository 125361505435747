import React, { FC } from 'react';
import Badge from '@codegouvfr/react-dsfr/Badge';

import { RFEnqueteLogement, RFEnqueteRessources, RFEnqueteStatut } from 'models/types/api/rf';
import { RFDetailsInstructionCmsComponent } from 'models/types/cms/espaceRF/types';

type Props = {
  enqueteLogement: RFEnqueteLogement | null;
  enqueteRessource: RFEnqueteRessources | null;
  cmsDetailsInstruction: RFDetailsInstructionCmsComponent;
  traductionRFCms: any;
};

const badgeStyle = (status: string | undefined) => {
  //"info" | "success" | "error" | "warning"
  switch (status) {
    case RFEnqueteStatut.TERMINEE:
      return 'success';
    default:
      return 'new';
  }
};
const DetailsInstruction: FC<Props> = ({
  enqueteLogement,
  enqueteRessource,
  traductionRFCms,
  cmsDetailsInstruction,
}) => {
  return (
    <>
      <div className="fr-mb-md-2w fr-mb-1w">
        <h3>{cmsDetailsInstruction.titre}</h3>
        <div className="enquete-container">
          <div className="fr-p-3w enquete">
            <Badge
              severity={badgeStyle(enqueteLogement?.statut)}
              className="fr-mr-3v"
              noIcon={enqueteLogement?.statut !== RFEnqueteStatut.TERMINEE}
            >
              {enqueteLogement?.statut !== RFEnqueteStatut.TERMINEE && (
                <i className="fr-icon-timer-line"></i>
              )}
              {enqueteLogement?.statut
                ? traductionRFCms.statuts_enquetes[enqueteLogement?.statut]
                : ''}
            </Badge>
            <span>{cmsDetailsInstruction.enquete_logement}</span>
          </div>
          <div className="fr-p-3w enquete">
            <Badge
              severity={badgeStyle(enqueteRessource?.statut)}
              noIcon={enqueteRessource?.statut !== RFEnqueteStatut.TERMINEE}
              className="fr-mr-3v "
            >
              {enqueteRessource?.statut !== RFEnqueteStatut.TERMINEE && (
                <i className="fr-icon-timer-line"></i>
              )}
              {enqueteRessource?.statut
                ? traductionRFCms.statuts_enquetes[enqueteRessource?.statut]
                : ''}
            </Badge>
            <span>{cmsDetailsInstruction.enquete_ressources}</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default DetailsInstruction;
