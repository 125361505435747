import React, { useEffect, useState } from 'react';
import Input from '@codegouvfr/react-dsfr/Input';

import '../Forms.scss';

const detectClickOutside = (event: MouseEvent, target: string) => {
  const element = document.getElementById(target);
  if (element && !element.contains(event.target as Node)) {
    return true;
  } else {
    return false;
  }
};

interface AutocompleteProps {
  label: string | React.ReactNode;
  hintText?: string;
  stateRelatedMessage?: string;
  name: string;
  state: string;
  control: any;
  field: any;
  onValueChange: (value: any, inputValue: string) => void;
  value: string;
  options: any[];
  labelIsDisplayedValue: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isOpen: boolean;
  disabled?: boolean;
}

const Autocomplete: React.FC<AutocompleteProps> = ({
  label,
  hintText,
  stateRelatedMessage,
  control,
  name,
  state,
  field,
  onValueChange,
  value,
  options,
  labelIsDisplayedValue,
  isOpen,
  setIsOpen,
  disabled,
}) => {
  const [preselection, setPreselection] = useState<number | null>(null);
  const [isDeleteCrossVisible, setIsDeleteCrossVisible] = useState<boolean>(false);
  const uniqueKeyProps = (Math.random() + 1).toString(36).substring(7);

  const reTargetChangeButton = !isOpen && state === 'error';

  const deleteInputContent = () => {
    onValueChange('', '');
    setPreselection(null);
  };

  const onTyping = (value: string) => {
    onValueChange('', value);
    if (!isOpen && options.length > 0) {
      setIsOpen(true);
    }
  };

  const onOptionClick = (option: { label: string; value: string }) => {
    onValueChange(option, labelIsDisplayedValue && option.label ? option.label : option.value);
    setPreselection(null);
    setIsOpen(false);
  };

  const onBlur = (option: { label: string; value: string }) => {
    onValueChange(option, labelIsDisplayedValue && option.label ? option.label : option.value);
    setPreselection(null);
    setIsOpen(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    setIsOpen(!detectClickOutside(event, 'options'));
  };

  const onArrowDownPress = () => {
    if ((preselection || preselection === 0) && preselection + 1 < options.length) {
      setPreselection(preselection + 1);
    } else {
      setPreselection(0);
    }
  };

  const onArrowUpPress = () => {
    if (!!preselection && preselection - 1 >= 0) {
      setPreselection(preselection - 1);
    } else {
      setPreselection(options.length - 1);
    }
  };

  const onEnterPress = () => {
    if (preselection || preselection === 0) {
      onValueChange(
        options[preselection],
        labelIsDisplayedValue ? options[preselection].label : options[preselection].value,
      );
      setPreselection(null);
      setIsOpen(false);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        onArrowDownPress();
        break;
      case 'ArrowUp':
        e.preventDefault();
        onArrowUpPress();
        break;
      case 'Enter':
        e.preventDefault();
        onEnterPress();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if ((!value || value === '') && isOpen) {
      setIsOpen(false);
    }
    if (value && value.length > 0) {
      setIsDeleteCrossVisible(true);
    } else {
      setIsDeleteCrossVisible(false);
    }
  }, [value]);

  useEffect(() => {
    if (preselection !== null && options[preselection]?.value) {
      const anchor = document.getElementById(options[preselection].value);
      const offset = anchor && anchor.offsetTop !== undefined ? anchor.offsetTop : null;

      if (offset !== null) {
        document.getElementById('scroll-list')?.scrollTo({
          top: offset - 120,
          behavior: 'smooth',
        });
      }
    }
  }, [preselection]);

  useEffect(() => {
    if (!preselection && preselection !== 0) {
      setPreselection(0);
    }

    if (isOpen) {
      document.addEventListener('click', handleClickOutside);
    } else {
      document.removeEventListener('click', handleClickOutside);
    }

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isOpen]);

  const getSelected = () => {
    let activeDescendant;
    if (options?.length > 0 && preselection !== null && options[preselection]) {
      activeDescendant = options[preselection].value;
    }

    return activeDescendant ? activeDescendant : null;
  };

  useEffect(() => {
    if (disabled) {
      deleteInputContent();
    }
  }, [disabled]);

  return (
    <>
      <div className="autocomplete-container">
        <Input
          className={isOpen ? 'absolute-state-message' : ''}
          type="text"
          role="combobox"
          aria-haspopup="listbox"
          aria-controls="options"
          aria-expanded={isOpen}
          aria-activedescendant={getSelected()}
          aria-autocomplete="list"
          label={label}
          hintText={hintText ?? ''}
          stateRelatedMessage={stateRelatedMessage}
          state={state}
          control={control}
          name={name}
          {...field}
          nativeInputProps={{ value: value }}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => onTyping(e.target.value)}
          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => handleKeyDown(e)}
          onBlur={() => {
            if (isOpen && preselection !== null) {
              onBlur(options[preselection]);
            }
            field.onBlur();
          }}
          disabled={disabled}
        />
        {isDeleteCrossVisible && (
          <button
            className={`fr-icon-close-circle-fill ${
              reTargetChangeButton ? 'btn-delete-error' : 'btn-delete'
            }`}
            data-fr-js-button-actionee="true"
            onClick={() => {
              deleteInputContent();
            }}
            type="button"
          >
            {' '}
          </button>
        )}
      </div>
      {isOpen && (
        <div
          className="scroll-list"
          onMouseDown={e => {
            e.preventDefault();
          }}
          key={uniqueKeyProps}
        >
          <ul
            tabIndex={-1}
            id="options"
            role="listbox"
            key={uniqueKeyProps + '-listbox'}
            aria-activedescendant={
              preselection && options && options[preselection]?.value
                ? options[preselection].value
                : undefined
            }
          >
            {options.map((option: any, index: number) => (
              <>
                <li
                  className={preselection === index ? 'selected' : ''}
                  key={uniqueKeyProps + option.id}
                  id={option.value}
                  role="option"
                  aria-selected={value === option.value}
                  onClick={() => onOptionClick(option)}
                  onMouseEnter={() => setPreselection(index)}
                >
                  {option.label}
                </li>
              </>
            ))}
          </ul>
        </div>
      )}
    </>
  );
};

export default Autocomplete;
