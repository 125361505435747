import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  DemandeRfBloc2,
  DemandeRfErrors,
  DemandeRfUtilitaire,
} from 'models/types/cms/demandeRF/type';

import { getAddressValidationSchema } from '../helpers/addressValidationSchema';
import { addressDefaultValues } from '../helpers/addressDefaultValues';

export type CmsErrorProvider = {
  maxLengthErrorMessage: string;
  noDigitInvalidFormat: string;
  emailInvalidFormat: string;
  requiredFieldError: string;
  requiredFileError: string;
  fileGenericError: string;
  numeroEtrangerlengthError: string;
  numeroEtrangerFormatError: string;
  noSpecialCharError: string;
};

const AddressUseForm = (
  erreursRfCms: DemandeRfErrors,
  utilitaireCms: DemandeRfUtilitaire,
  bloc2Cms: DemandeRfBloc2,
) => {
  const addressValidationSchema = useMemo(
    () => getAddressValidationSchema(erreursRfCms, utilitaireCms, bloc2Cms),
    [],
  );

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    getFieldState,
    trigger,
    reset,
    watch,
    formState,
    control,
  } = useForm({
    resolver: yupResolver(addressValidationSchema),
    defaultValues: {
      ...addressDefaultValues,
    },
    mode: 'all',
  });

  return {
    handleSubmit,
    getValues,
    setValue,
    getFieldState,
    trigger,
    watch,
    formState,
    control,
    register,
    reset,
  };
};

export default AddressUseForm;
