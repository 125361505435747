import { cmsSlice } from './cmsSlice';

const cmsAccessibilite = cmsSlice.injectEndpoints({
  endpoints: build => ({
    getAccessibiliteCmsPage: build.query<any, { locale: string }>({
      query: ({ locale }) => ({ url: `/accessibilite?locale=${locale}&populate=*` }),
    }),
  }),
});

export const { useGetAccessibiliteCmsPageQuery } = cmsAccessibilite;
