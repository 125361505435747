import { delRessourcesDocuments, setRessourcesDocuments } from 'redux/demandeRf';

import { UploadFilesParameters } from 'models/types/app/uploadFiles';
import { Ressource, Document } from 'models/types/api/rf';

import { getDocumentsByCategory } from '../Accomodation/Accomodation.helper';

export const GetUploadFilesParameters = (
  demandeId: string,
  formControl: {
    control: any;
    setValue: any;
    getValues: any;
    trigger: any;
    errors: any;
    watch: any;
  },

  cmsContentUtilitaire: any,
): UploadFilesParameters => {
  const { control, setValue, getValues, trigger, errors, watch } = formControl;
  return {
    demandeId: demandeId,
    formInfos: {
      id: demandeId,
      key: 'ressources',
      deleteFunction: delRessourcesDocuments,
      setFunction: setRessourcesDocuments,
    },
    formControl: {
      control: control,
      setValue: setValue,
      getValues: getValues,
      trigger: trigger,
      errors: errors,
      watch: watch,
    },
    demandeRFUtilitairecms: cmsContentUtilitaire,
    formToSubmit: 'ressources',
  };
};

export const reduxToFormMapping = (ressources: Ressource[]): Ressource[] => {
  return ressources.map((item: Ressource) => ({
    uuid: item.id,
    activite: item.activite,
    isNew: item.isNew,
    estActuel: item.estActuel ? item.estActuel : false,
    sort: item.sort,
    ress_salcdi_contrat_attestation_travail_files: item.documents
      ? getDocumentsByCategory('ress_salcdi_contrat_attestation_travail', item.documents)
      : ([] as Document[]),
    ress_salcdi_bulletin_salaire_files: item.documents
      ? getDocumentsByCategory('ress_salcdi_bulletin_salaire', item.documents)
      : ([] as Document[]),
    ress_salcdi_certificat_travail_files: item.documents
      ? getDocumentsByCategory('ress_salcdi_certificat_travail', item.documents)
      : ([] as Document[]),
    ress_salint_contrat_attestation_travail_files: item.documents
      ? getDocumentsByCategory('ress_salint_contrat_attestation_travail', item.documents)
      : ([] as Document[]),
    ress_salint_certificat_travail_files: item.documents
      ? getDocumentsByCategory('ress_salint_certificat_travail', item.documents)
      : ([] as Document[]),
    ress_salcdd_contrat_attestation_travail_files: item.documents
      ? getDocumentsByCategory('ress_salcdd_contrat_attestation_travail', item.documents)
      : ([] as Document[]),
    ress_salcdd_certificat_travail_files: item.documents
      ? getDocumentsByCategory('ress_salcdd_certificat_travail', item.documents)
      : ([] as Document[]),
    ress_salcdd_bulletin_salaire_files: item.documents
      ? getDocumentsByCategory('ress_salcdd_bulletin_salaire', item.documents)
      : ([] as Document[]),
    ress_artisa_extrait_inscription_metiers_files: item.documents
      ? getDocumentsByCategory('ress_artisa_extrait_inscription_metiers', item.documents)
      : ([] as Document[]),
    ress_artisa_bilan_comptable_resultat_attestation_revenu_files: item.documents
      ? getDocumentsByCategory(
          'ress_artisa_bilan_comptable_resultat_attestation_revenu',
          item.documents,
        )
      : ([] as Document[]),
    ress_dememp_releve_situation_droits_versements_pe_files: item.documents
      ? getDocumentsByCategory('ress_dememp_releve_situation_droits_versements_pe', item.documents)
      : ([] as Document[]),
    ress_prolib_extrait_inscription_siren_files: item.documents
      ? getDocumentsByCategory('ress_prolib_extrait_inscription_siren', item.documents)
      : ([] as Document[]),
    ress_prolib_bilan_comptable_resultat_attestation_revenu_files: item.documents
      ? getDocumentsByCategory(
          'ress_prolib_bilan_comptable_resultat_attestation_revenu',
          item.documents,
        )
      : ([] as Document[]),
    ress_commer_extrait_inscription_rcs_files: item.documents
      ? getDocumentsByCategory('ress_commer_extrait_inscription_rcs', item.documents)
      : ([] as Document[]),
    ress_commer_bilan_comptable_resultat_attestation_revenu_files: item.documents
      ? getDocumentsByCategory(
          'ress_commer_bilan_comptable_resultat_attestation_revenu',
          item.documents,
        )
      : ([] as Document[]),
    ress_autent_declaration_activite_cfe_files: item.documents
      ? getDocumentsByCategory('ress_autent_declaration_activite_cfe', item.documents)
      : ([] as Document[]),
    ress_autent_livre_recettes_attestation_revenu_files: item.documents
      ? getDocumentsByCategory('ress_autent_livre_recettes_attestation_revenu', item.documents)
      : ([] as Document[]),
    ress_invali_attribution_pension_retraite_invalidite_files: item.documents
      ? getDocumentsByCategory(
          'ress_invali_attribution_pension_retraite_invalidite',
          item.documents,
        )
      : ([] as Document[]),
    ress_invali_avis_versement_retraite_invalide_files: item.documents
      ? getDocumentsByCategory('ress_invali_avis_versement_retraite_invalide', item.documents)
      : ([] as Document[]),
    ress_retrai_attribution_pension_retraite_invalidite_files: item.documents
      ? getDocumentsByCategory(
          'ress_retrai_attribution_pension_retraite_invalidite',
          item.documents,
        )
      : ([] as Document[]),
    ress_retrai_avis_versement_retraite_invalide_files: item.documents
      ? getDocumentsByCategory('ress_retrai_avis_versement_retraite_invalide', item.documents)
      : ([] as Document[]),
  }));
};
