import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';

import { setCookies } from 'redux/cookies';
import { useConsent } from './ConsentManagement';
import { getCookiesAcceptance } from 'redux/cookies/selectors';

export function ConsentWrapper() {
  const cookiesAcceptanceInitialValues = useSelector(getCookiesAcceptance);
  const [cookiesAcceptance, setCookiesAcceptance] = useState(cookiesAcceptanceInitialValues);

  const dispatch = useDispatch();

  useConsent({
    consentCallback: async ({ finalityConsent }) => {
      setCookiesAcceptance({
        mixpanelCookiesAccepted: true,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        twitterCookiesAccepted: finalityConsent.social.twitter,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        facebookCookiesAccepted: finalityConsent.social.facebook,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        youtubeCookiesAccepted: finalityConsent.social.youtube,
      });
    },
  });

  useEffect(() => {
    dispatch(setCookies(cookiesAcceptance));
  }, [cookiesAcceptance, dispatch]);

  return <></>;
}
