declare global {
  interface Window {
    _env_: { [key: string]: string };
  }
}

const getEnv = (variableName: string): string | undefined => {
  // eslint-disable-next-line no-restricted-syntax
  if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test') {
    // eslint-disable-next-line no-restricted-syntax
    return process.env[variableName];
  } else {
    return window._env_[variableName];
  }
};

export default getEnv;
