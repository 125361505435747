/* eslint-disable complexity */
import { ReactNode } from 'react';
import { AuthContextProps } from 'react-oidc-context';

import { footerData, headerData, headerMenu } from 'models/types/app/pages';
import {
  ACCUEIL,
  PROCEDURES,
  SERVICES_EN_LIGNE,
  ACCUEIL_ET_INTEGRATION,
  ARTICLES,
  LOCALISATION,
  ACCESSIBILITY,
  CONTACT,
  RGPD,
  MENTION_LEGALES,
  PLAN_DU_SITE,
  TABLEAU_DE_BORD,
  COMPTE,
  ERROR_AUTH,
  DOCUMENTS,
} from 'routes/path';
import { CustomHeaderProps } from 'components/Molecules/CustomHeader/CustomHeader.type';

export const brand: ReactNode = (
  <>
    République
    <br />
    Française
  </>
);

function generateEtalab(
  sentence: string | undefined,
  linkText: string | undefined,
  href: string | undefined,
): ReactNode {
  return (
    <>
      <span>{sentence}</span>&nbsp;
      <a href={href}>{linkText}</a>
    </>
  );
}

export const getCurrentPath = (path: string): string => {
  if (path.split('/')[1].length === 2) {
    return path.split('/').slice(2).join('/') !== ''
      ? '/' + path.split('/').slice(2).join('/')
      : '';
  } else if (path === '/') {
    return '';
  } else {
    return path;
  }
};

export const getCurrentLogButton = (
  auth: AuthContextProps,
  lang: string,
  cmsHeader?: headerData,
): {
  iconId: string;
  linkProps: {
    href?: string;
    onClick?: () => void;
  };
  text: string;
} => {
  if (auth.isAuthenticated) {
    return {
      iconId: 'fr-icon-bank-fill',
      linkProps: {
        onClick: () =>
          void auth.signoutRedirect({
            post_logout_redirect_uri: window.location.origin + '/',
          }),
      },
      text: cmsHeader?.logout ? cmsHeader.logout : '',
    };
  } else {
    return {
      iconId: 'fr-icon-lock-line',
      linkProps: {
        onClick: () =>
          void auth.signinRedirect({
            redirect_uri: window.location.origin + TABLEAU_DE_BORD,
            ui_locales: lang,
          }),
      },
      text: cmsHeader?.login ? cmsHeader?.login : '',
    };
  }
};

export const checkIfIsActive = (path: string, linkUrl: string, currentLanguage: string) => {
  if (currentLanguage === '') {
    return path === linkUrl;
  } else {
    return linkUrl === '/'
      ? path === `/${currentLanguage}`
      : path === `/${currentLanguage}${linkUrl}`;
  }
};

export const buildRouteForNavigation = (currentLanguage: string, path: string) => {
  const currentPath = path !== '/' ? path : '/';
  return currentLanguage !== '' ? `${currentPath}` : path;
};

export const generateNavigationArray = (
  routeInfos: { path: string; currentLanguage: string },
  data?: headerMenu,
  isAuth?: boolean,
) => {
  const nav = [
    {
      isActive: checkIfIsActive(routeInfos.path, PROCEDURES, routeInfos.currentLanguage),
      linkProps: {
        href: buildRouteForNavigation(routeInfos.currentLanguage, PROCEDURES),
        target: '_self',
      },
      text: data ? data.procedures : '',
    },
    {
      isActive:
        checkIfIsActive(routeInfos.path, SERVICES_EN_LIGNE, routeInfos.currentLanguage) ||
        checkIfIsActive(routeInfos.path, ACCUEIL_ET_INTEGRATION, routeInfos.currentLanguage) ||
        checkIfIsActive(routeInfos.path, ARTICLES, routeInfos.currentLanguage),
      menuLinks: [
        {
          isActive: checkIfIsActive(routeInfos.path, SERVICES_EN_LIGNE, routeInfos.currentLanguage),
          linkProps: {
            href: buildRouteForNavigation(routeInfos.currentLanguage, SERVICES_EN_LIGNE),
          },
          text: data ? data.services_en_ligne : '',
        },
        {
          isActive: checkIfIsActive(
            routeInfos.path,
            ACCUEIL_ET_INTEGRATION,
            routeInfos.currentLanguage,
          ),
          linkProps: {
            href: buildRouteForNavigation(routeInfos.currentLanguage, ACCUEIL_ET_INTEGRATION),
          },
          text: data ? data.accueil_et_integration : '',
        },
        {
          isActive: checkIfIsActive(routeInfos.path, ARTICLES, routeInfos.currentLanguage),
          linkProps: {
            href: buildRouteForNavigation(routeInfos.currentLanguage, ARTICLES),
          },
          text: data ? data.articles : '',
        },
      ],
      text: data ? data.informations_generales : '',
    },
    {
      isActive: checkIfIsActive(routeInfos.path, LOCALISATION, routeInfos.currentLanguage),
      linkProps: {
        href: buildRouteForNavigation(routeInfos.currentLanguage, LOCALISATION),
        target: '_self',
      },
      text: data ? data.localisation : '',
    },
  ];

  if (isAuth) {
    nav.unshift(
      {
        isActive: checkIfIsActive(routeInfos.path, ACCUEIL, routeInfos.currentLanguage),
        linkProps: {
          href: buildRouteForNavigation(routeInfos.currentLanguage, ACCUEIL),
          target: '_self',
        },
        text: data ? data.accueil : '',
      },
      {
        isActive: checkIfIsActive(routeInfos.path, TABLEAU_DE_BORD, routeInfos.currentLanguage),
        linkProps: {
          href: buildRouteForNavigation(routeInfos.currentLanguage, TABLEAU_DE_BORD),
          target: '_self',
        },
        text: data ? data.tableau_de_bord : '',
      },
      {
        isActive: checkIfIsActive(routeInfos.path, COMPTE, routeInfos.currentLanguage),
        linkProps: {
          href: buildRouteForNavigation(routeInfos.currentLanguage, COMPTE),
          target: '_self',
        },
        text: data ? data.compte : '',
      },
      {
        isActive: checkIfIsActive(routeInfos.path, DOCUMENTS, routeInfos.currentLanguage),
        linkProps: {
          href: buildRouteForNavigation(routeInfos.currentLanguage, DOCUMENTS),
          target: '_self',
        },
        text: data ? data.documents : '',
      },
    );
  } else {
    nav.unshift({
      isActive: checkIfIsActive(routeInfos.path, ACCUEIL, routeInfos.currentLanguage),
      linkProps: {
        href: buildRouteForNavigation(routeInfos.currentLanguage, ACCUEIL),
        target: '_self',
      },
      text: data ? data.accueil : '',
    });
  }

  return nav;
};

export const generateHeaderData = (
  routeInfos: { path: string; currentLanguage: string },
  ConnexionInfos: { auth: AuthContextProps; keycloakAlive: boolean },
  data?: headerMenu,
  cmsHeader?: headerData,
): Omit<CustomHeaderProps, 'setLang'> => {
  const currentPath: string = getCurrentPath(routeInfos.path);
  const logButtonData: {
    iconId: string;
    linkProps: {
      href?: string;
      onClick?: () => void;
    };
    text: string;
  }[] = [];

  if (ConnexionInfos.keycloakAlive) {
    logButtonData.push(
      getCurrentLogButton(ConnexionInfos.auth, routeInfos.currentLanguage, cmsHeader),
    );
  } else {
    logButtonData.push({
      iconId: 'fr-icon-lock-line',
      linkProps: {
        href: ERROR_AUTH,
      },
      text: cmsHeader ? cmsHeader.login : '',
    });
  }
  return {
    brandTop: brand,
    serviceTitle: data?.titre ? data?.titre : '',
    homeLinkProps: {
      href: ACCUEIL,
      title: data?.sous_titre ? data?.sous_titre : '',
    },
    operatorLogo: {
      alt: 'Ofii - Logo',
      imgUrl: '/img/ofii.svg',
      orientation: 'horizontal',
    },
    quickAccessItems: logButtonData,
    closeWording: data?.fermer_menu,
    languageItems: [
      {
        hrefLang: 'fr',
        lang: 'fr',
        href: `${currentPath}`,
        title: 'FR - Français',
      },
      {
        hrefLang: 'en',
        lang: 'en',
        href: `${currentPath}`,
        title: 'EN - English',
      },
    ],
    navigation: generateNavigationArray(routeInfos, data, ConnexionInfos.auth.isAuthenticated),
  };
};

export const generateFooterPartnersArray = (
  partners:
    | {
        id: number;
        lien: string;
        nom: string;
      }[]
    | undefined,
) => {
  if (partners) {
    return partners.map(partner => {
      return {
        linkProps: {
          href: partner.lien,
        },
        text: partner.nom,
      };
    });
  } else {
    return [];
  }
};

export const generateFooterData = (routeInfos: { currentLanguage: string }, data?: footerData) => {
  return {
    brandTop: brand,
    accessibility: data?.accessibilite_taux_conformite ? data.accessibilite_taux_conformite : '',
    contactLinkProps: {
      href: buildRouteForNavigation(routeInfos.currentLanguage, CONTACT),
      title: data?.contact ? data.contact : '',
    },
    homeLinkProps: {
      href: buildRouteForNavigation(routeInfos.currentLanguage, ACCUEIL),
      title: data?.titre_principal ? data.titre_principal : '',
    },
    personalDataLinkProps: {
      href: buildRouteForNavigation(routeInfos.currentLanguage, RGPD),
      title: data?.politique_confidentialite ? data.politique_confidentialite : '',
    },
    termsLinkProps: {
      href: buildRouteForNavigation(routeInfos.currentLanguage, MENTION_LEGALES),
      title: data?.mentions_legales ? data.mentions_legales : '',
    },
    websiteMapLinkProps: {
      href: buildRouteForNavigation(routeInfos.currentLanguage, PLAN_DU_SITE),
      title: data?.menu_plan_du_site ? data.menu_plan_du_site : '',
    },
    cookiesManagementLinkProps: {
      href: buildRouteForNavigation(routeInfos.currentLanguage, RGPD),
      title: data?.cookies ? data.cookies : '',
    },
    accessibilityLinkProps: {
      href: buildRouteForNavigation(routeInfos.currentLanguage, ACCESSIBILITY),
      title: data?.accessibilite ? data.accessibilite : '',
    },
    license: generateEtalab(
      data?.mentions_licence,
      data?.mentions_licence_label_lien,
      data?.mentions_licence_lien,
    ),
    partnerWebsitesLink: generateFooterPartnersArray(data?.liens_partenaires),
  };
};

export const generateSocialBannerData = (data?: footerData) => {
  return {
    lien_twitter: data?.lien_twitter ? data.lien_twitter : '',
    lien_facebook: data?.lien_facebook ? data.lien_facebook : '',
    lien_youtube: data?.lien_youtube ? data.lien_youtube : '',
    nous_suivre: data?.nous_suivre ? data.nous_suivre : '',
    sur_les_reseaux: data?.sur_les_reseaux ? data.sur_les_reseaux : '',
    title_lien_facebook: data?.title_lien_facebook ? data.title_lien_facebook : '',
    title_lien_twitter: data?.title_lien_twitter ? data.title_lien_twitter : '',
    title_lien_youtube: data?.title_lien_youtube ? data.title_lien_youtube : '',
    titre_principal: data?.titre_principal ? data.titre_principal : '',
    menu_plan_du_site: data?.menu_plan_du_site ? data.menu_plan_du_site : '',
  };
};
