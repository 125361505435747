export const etape1_receptionne_dossier_cloture = {
  beneficiaires: [
    {
      lienFamilial: 'epoux',
      prenomBeneficiaire: 'CONJOINT',
      nomBeneficiaire: 'TEST MAIL',
      dateNaissance: '2000-01-12',
      nationalite: 'Algérienne',
      paysResidence: 'Algérie',
    },
  ],
  numeroDossier: '47023000000017',
  adresseDemandeur: 'Rue 34430 ST JEAN DE VEDAS',
  adresseEnqueteDemandeur: null,
  directionTerritoriale: 'DT Montpellier',
  titreSejourValiditeDateDebut: null,
  titreSejourValiditeDateFin: '2024-01-01',
  clefStatut: 'pas_etape__dossier_cloture__dossier_incomplet',
  statut: 'dossier_cloture',
  dateStatut: '2024-02-19',
  numeroAgdref: '7703023982',
  responsableStatut: null,
  documentsManquant: [],
  enqueteLogement: null,
  enqueteRessources: null,
  etapes: [
    {
      statut: 'dossier_cloture',
      nom: 'receptionnee',
      numero: 1,
      date: '2024-02-19',
    },
    {
      statut: 'a_venir',
      nom: 'en_cours_instruction',
      numero: 2,
      date: null,
    },
    {
      statut: 'a_venir',
      nom: 'en_attente_decision',
      numero: 3,
      date: null,
    },
    {
      statut: 'a_venir',
      nom: 'decision',
      numero: 4,
      date: null,
    },
  ],
  dateEnvoiConsulat: null,
  typeDepot: 'portail',
  idProcedure: null,
  postalStatut: null,
  newDemande: true,
  shouldDisplayEnquete: false,
};
