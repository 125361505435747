import { Identity } from 'models/types/api/rf';

export type IdentityState = Readonly<Identity>;

export const initialState: IdentityState = {
  isValid: false,
  isUntouched: true,
  documents: [],
  etatCivil: {
    acteNaissanceTrad: false,
    dateNaissance: null,
    nationalite: null,
    nom: null,
    nomUsage: null,
    paysNaissance: null,
    prenoms: null,
    sexe: '',
    villeNaissance: null,
  },
  situationMatrimonial: null,
  situationMatrimonialDate: null,
  situationMatrimonialTrad: null,
  titreDeSejour: null,
  titreDeSejourAutre: null,
  titreDeSejourFin: null,
  titreDeSejourMention: null,
  titreDeSejourMentionAutre: null,
  titreDeSejourRenouvellement: null,
  marieEnFrance: null,
};
