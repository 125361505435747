/* eslint-disable max-lines */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import FormLayout from 'components/Molecules/DemandeRf/FormLayout/FormLayout';
import CustomDownloadContainer from 'components/Molecules/Forms/CustomDownloadContainer/CustomDownloadContainer';
import CommonRowContainer from 'components/Molecules/Forms/CommonRowContainer/CommonRowContainer';
import TextInputContainer from 'components/Molecules/Forms/TextInputContainer/TextInputContainer';
import CustomCheckboxContainer from 'components/Molecules/Forms/CustomCheckboxContainer/CustomCheckboxContainer';
import Loader from 'components/Atoms/Loader/Loader';
import { handleFieldHydratation } from 'helpers/api/FormHydratation';
import { getErrorMessage } from 'helpers/api/rtkErrorHelpers';
import { useFormTouched } from 'pages/DemandeRF/DemandeRF.utils';
import 'pages/DemandeRF/DemandeRF.scss';
import { getRfCmsContents } from 'pages/DemandeRF/Helpers/Cms/getRfCmsContents';
import { useSubmitDemandeMutation, useUpdDemandeMutation } from 'redux/rtk/api/apiRf';
import { createError, createSuccess } from 'redux/forms';
import { getCommitmentStore, getDemandeId } from 'redux/demandeRf/selectors';
import {
  setCommitmentsEmail,
  setCommitmentsUntouched,
  setCommitmentsValidation,
  setDemandeSubmitted,
  setTelFixe,
  setTelPortable,
} from 'redux/demandeRf';
import { TABLEAU_DE_BORD } from 'routes/path';

import GetCommitmentsUseForm from './Commitments.useForm';
import { CommitmentsMapping } from './Commitments.mapping';
import { getCommitmentsValidationSchema } from './Commitments.validationSchema';

type CommitmentsProps = {
  lang: string;
  saveFormProgress: boolean;
  setSaveFormProgress: React.Dispatch<React.SetStateAction<boolean>>;
  scrollToTablist: () => void;
  tabsStatus: {
    IdentityStatus: string;
    FamilyStatus: string;
    AccomodationStatus: string;
    ResourcesStatus: string;
    CommitmentStatus: string;
  };
};

const Commitments: React.FC<CommitmentsProps> = ({
  lang,
  saveFormProgress,
  setSaveFormProgress,
  scrollToTablist,
  tabsStatus,
}) => {
  const { bloc5Cms, utilitaireCms, errorCms } = getRfCmsContents(lang);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const commitmentsDebug: any = {
    all: false,
    loadingData: {
      apiData: false,
      cmsData: false,
      formDataSetting: false,
      formValuesAfterLoad: false,
    },
    changeData: {
      effects: false,
      apiDataUpdate: false,
    },
    submitData: {
      formValues: false,
      sentData: false,
    },
  };

  const [updateDemande, { isLoading, isError, data }] = useUpdDemandeMutation();
  const [
    submitDemande,
    {
      isLoading: submitIsLoading,
      isError: submitIsError,
      isSuccess: submitIsSuccess,
      error: submitError,
    },
  ] = useSubmitDemandeMutation();
  if (commitmentsDebug.all || commitmentsDebug.changeData.apiDataUpdate) {
    console.log(isLoading, isError, data);
  }
  const [demandeForm, setDemandeForm] = useState<object | null>(null);
  const demandeId = useSelector(getDemandeId);
  const demandeCommitment = useSelector(getCommitmentStore);

  const {
    formState: { errors, isValid, touchedFields },
    getValues,
    setValue,
    trigger,
    watch,
    control,
    handleSubmit,
  } = GetCommitmentsUseForm(bloc5Cms, errorCms, utilitaireCms);

  const formIsTouched = useFormTouched(touchedFields);

  const formMapping = CommitmentsMapping.getGeneralMapping().getFormFields(bloc5Cms, dispatch, {
    setValue,
    control,
    trigger,
    getValues,
  });

  const watchCheckboxNumFixe = watch('checkbox_num_fixe');
  const isAllTabsValid = Object.values(tabsStatus).every(status => status === 'valid');

  const setValuesFromDemand = (formDemand: any) => {
    for (const key in formDemand) {
      if (formDemand[key] !== null && formDemand[key] !== undefined) {
        handleFieldHydratation(
          key,
          formDemand,
          getCommitmentsValidationSchema(bloc5Cms, errorCms, utilitaireCms),
          setValue,
          key === 'email' || key === 'num_portable',
        );
      }
    }
  };

  const onSubmit = async (values: any) => {
    const newDemande = {
      id: null,
      engagementCertifieRenseignementsExact: values.certifie_info_exacts,
      engagementChangementSituation: values.changements_a_ladministration,
      engagementDeclareRfNonPolygamie: values.certifie_non_polygamie,
      engagementArt4211: values.article_code_entree_sejour,
      engagementSignatureDocument: values.certifie_avoir_lu_ensemble_engagement,
      engagementRgpd: values.acceptation_rgpd,
      usager: {
        id: null,
        email: values.email,
        telPortable: values.num_portable ? values.num_portable : null,
        telFixe: values.num_fix ? values.num_fix : null,
      },
    };

    let response: any;
    let isError = false;

    try {
      const parameters = {
        body: newDemande,
        demandeId: demandeId,
        isValidation: values.validation,
        etape: 'fin',
      };

      if (values.validation === true) {
        await submitDemande(parameters).unwrap();
      } else {
        await updateDemande(parameters).unwrap();
      }
      setSaveFormProgress(false);

      if (commitmentsDebug.all || commitmentsDebug.submitData.sentData) {
        console.log('onSubmit: call body ', demandeForm);
        console.log('onSubmit: call response ', response);
        console.log('onSubmit: isError ', isError);
      }
    } catch (e: any) {
      isError = true;
      dispatch(createError(getErrorMessage(e)));
    }

    if (!isError) {
      if (values.validation === true) {
        if (isAllTabsValid) {
          dispatch(createSuccess(utilitaireCms.toast_demande_valide));
        } else {
          dispatch(createError(errorCms.erreur_validation_cases));
        }
      } else {
        dispatch(createSuccess(utilitaireCms.toast_demande_enregistre));
      }
    }
  };

  useEffect(() => {
    dispatch(setCommitmentsUntouched(!formIsTouched));
  }, [formIsTouched]);

  useEffect(() => {
    if (submitIsSuccess) {
      dispatch(setDemandeSubmitted(true));
      navigate(TABLEAU_DE_BORD);
    }
  }, [submitIsSuccess]);

  useEffect(() => {
    dispatch(setCommitmentsValidation(isValid));
  }, [isValid]);

  useEffect(() => {
    setDemandeForm({
      certifie_info_exacts: demandeCommitment?.engagementCertifieRenseignementsExact,
      changements_a_ladministration: demandeCommitment?.engagementChangementSituation,
      certifie_non_polygamie: demandeCommitment?.engagementDeclareRfNonPolygamie,
      article_code_entree_sejour: demandeCommitment?.engagementArt4211,
      certifie_avoir_lu_ensemble_engagement: demandeCommitment?.engagementSignatureDocument,
      acceptation_rgpd: demandeCommitment?.engagementRgpd,
      email: demandeCommitment?.email,
      num_portable: demandeCommitment?.telPortable,
      checkbox_num_fixe: demandeCommitment?.haveNumFix ? true : false,
      num_fix: demandeCommitment?.telFixe,
    });
  }, [demandeCommitment]);

  useEffect(() => {
    if (demandeForm) {
      setValuesFromDemand(demandeForm);
    }
  }, [demandeForm]);

  useEffect(() => {
    if (saveFormProgress) {
      onSubmit({ ...control._formValues, validation: false });
    }
  }, [saveFormProgress]);

  if (submitIsError) {
    console.error('Erreur lors de la soumission du formulaire', getErrorMessage(submitError));
    //return <div>Erreur</div>;
  }
  if (submitIsLoading) {
    return <Loader />;
  }

  return (
    <FormLayout
      formIdentifier="commitments-form"
      title={bloc5Cms.titre_principal}
      lang={lang}
      control={control}
      onSubmit={onSubmit}
      handleSubmit={handleSubmit}
      trigger={trigger}
      isValid={isValid}
      errors={errors}
      buttonValidationName={bloc5Cms.btn_envoyer_demande}
      scrollToTablist={scrollToTablist}
      requiredInfos={true}
    >
      <CustomCheckboxContainer large={true} {...formMapping.certifie_info_exacts} />
      <CustomCheckboxContainer large={true} {...formMapping.changements_a_ladministration} />
      <CustomCheckboxContainer large={true} {...formMapping.certifie_non_polygamie} />
      <CustomCheckboxContainer large={true} {...formMapping.article_code_entree_sejour} />
      <CustomCheckboxContainer
        large={true}
        {...formMapping.certifie_avoir_lu_ensemble_engagement}
      />
      <CustomCheckboxContainer large={true} {...formMapping.acceptation_rgpd} />
      <CustomDownloadContainer {...formMapping.download_rgpd} />
      <div className="container fr-mt-8v fr-mb-8v">
        <hr />
      </div>
      <CommonRowContainer>
        <div className="fr-col-12 fr-col-lg-4 fr-col-md-4 commitments-form-container">
          <TextInputContainer
            {...formMapping.num_portable}
            onChange={e => {
              if (typeof e === 'string') {
                dispatch(setTelPortable(e));
              }
            }}
          />
          <CustomCheckboxContainer {...formMapping.have_num_fix} />
          {watchCheckboxNumFixe && (
            <TextInputContainer
              {...formMapping.num_fix}
              onChange={e => {
                if (typeof e === 'string') {
                  dispatch(setTelFixe(e));
                }
              }}
            />
          )}
        </div>
        <TextInputContainer
          {...formMapping.email}
          onChange={e => {
            if (typeof e === 'string') {
              dispatch(setCommitmentsEmail(e));
            }
          }}
        />
      </CommonRowContainer>
    </FormLayout>
  );
};

export default Commitments;
