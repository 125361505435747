import { DocumentOptions, DocumentReformat } from '../types/api/rf';

export class DocumentReformatClass implements DocumentReformat {
  id: string | null;
  document: {
    id: string | null;
    idRfng: string | null;
    nomFichier: string | null;
    nomFichierPublic: string | null;
    statut: string | null;
    categorie: string;
    options: DocumentOptions | null;
  };

  constructor(categorie: string) {
    this.id = null;
    this.document = {
      id: null,
      idRfng: null,
      nomFichier: null,
      nomFichierPublic: null,
      statut: null,
      categorie: categorie,
      options: null,
    };
  }
}
