import qs from 'qs';

export const monAllocationQuery = qs.stringify({
  populate: {
    moyen_de_paiement: {
      populate: '*',
    },
    dernier_versement: {
      populate: '*',
    },
    historique_versements: {
      populate: '*',
    },
  },
});
