// Need to use the React-specific entry point to import createApi
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import getEnv from 'helpers/app/envHelper';
import { Geocode } from 'models/types/api/geocode';

export const API_ADRESS_GOV = getEnv('REACT_APP_API_ADRESS_GOV') || '';

type Params = {
  type: string | undefined;
  text: string;
};
export const addressSlice = createApi({
  reducerPath: 'address',
  baseQuery: fetchBaseQuery({
    baseUrl: API_ADRESS_GOV,
  }),
  endpoints: builder => ({
    getAddress: builder.query<Geocode, Params>({
      query: ({ type, text }) => ({
        url: `/search/?q=${text}${type ? '&type=' + type : ''}`,
      }),
    }),
  }),
});

export const { useGetAddressQuery } = addressSlice;
