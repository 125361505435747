import React, { FC, ReactNode } from 'react';
import { RegisteredLinkProps } from '@codegouvfr/react-dsfr/link';
import { useSelector } from 'react-redux';

import { getCookiePage } from 'redux/pages/selectors';
import { manageKeyboardNavigation } from 'components/Molecules/ConsentManagement/ManageState.helper';

export type CustomFooterProps = {
  className?: string;
  brandTop: ReactNode;
  accessibility: string;
  contentDescription?: ReactNode;
  contactLinkProps: RegisteredLinkProps;
  websiteMapLinkProps: RegisteredLinkProps;
  accessibilityLinkProps: RegisteredLinkProps;
  termsLinkProps: RegisteredLinkProps;
  personalDataLinkProps: RegisteredLinkProps;
  homeLinkProps: RegisteredLinkProps & {
    title: string;
  };
  bottomItems?: ReactNode[];
  partnersLogos?: {
    alt: string;
    href: string;
    imgUrl: string;
  };
  operatorLogo?: {
    orientation: 'horizontal' | 'vertical';
    imgUrl: string;
    alt: string;
  };
  license: ReactNode;
  partnerWebsitesLink: {
    linkProps: {
      href: string;
    };
    text: string;
  }[];
};

const Footer: FC<CustomFooterProps> = ({
  brandTop,
  accessibility,
  homeLinkProps,
  contactLinkProps,
  websiteMapLinkProps,
  accessibilityLinkProps,
  termsLinkProps,
  personalDataLinkProps,
  license,
  partnerWebsitesLink,
  bottomItems,
}) => {
  let licenseAjusted = license;
  const cookieStore = useSelector(getCookiePage);
  manageKeyboardNavigation(cookieStore);

  if (license && React.isValidElement(license) && React.isValidElement(license)) {
    licenseAjusted = React.cloneElement(license as React.ReactElement, {
      children: React.Children.map(license.props.children, (child: any) => {
        if (child.type === 'a') {
          return React.cloneElement(child, {
            target: '_blank',
          });
        }
        return child;
      }),
    });
  }

  return (
    <>
      <footer className="fr-footer" role="contentinfo" id="footer">
        <div className="fr-container fr-pb-6v">
          <div className="fr-footer__body">
            <div className="fr-footer__brand fr-enlarge-link">
              <p className="fr-logo">
                <a
                  className="fr-footer__brand-link"
                  href={homeLinkProps.href}
                  title={homeLinkProps.title}
                  aria-label={homeLinkProps.title}
                >
                  {brandTop}
                </a>
              </p>
            </div>
            <div className="fr-footer__content">
              <ul className="fr-footer__content-list">
                {partnerWebsitesLink?.map(({ text, linkProps }) => (
                  <li className="fr-footer__content-item" key={text}>
                    <a
                      className="fr-footer__content-link"
                      target="_blank"
                      href={linkProps.href}
                      rel="noreferrer"
                    >
                      {text}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="fr-footer__bottom">
            <ul className="fr-footer__bottom-list">
              <li className="fr-footer__bottom-item">
                <a className="fr-footer__bottom-link" href={contactLinkProps.href}>
                  {contactLinkProps.title}
                </a>
              </li>
              <li className="fr-footer__bottom-item">
                <a className="fr-footer__bottom-link" href={termsLinkProps.href}>
                  {termsLinkProps.title}
                </a>
              </li>
              <li className="fr-footer__bottom-item">
                <a className="fr-footer__bottom-link" href={accessibilityLinkProps.href}>
                  {accessibilityLinkProps.title} {accessibility}
                </a>
              </li>
              <li className="fr-footer__bottom-item">
                {Array.isArray(bottomItems) && bottomItems?.map((item: any) => item)}
              </li>
              <li className="fr-footer__bottom-item">
                <a className="fr-footer__bottom-link" href={personalDataLinkProps.href}>
                  {personalDataLinkProps.title}
                </a>
              </li>
              <li className="fr-footer__bottom-item">
                <a className="fr-footer__bottom-link" href={websiteMapLinkProps.href}>
                  {websiteMapLinkProps.title}
                </a>
              </li>
            </ul>
            <div className="fr-footer__bottom-copy">{licenseAjusted}</div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
