/* eslint-disable max-params */
import * as yup from 'yup';

import { HONEYPOT_NAME } from 'constants/formulaire';
import { CHOIX } from 'constants/global';
import { FormHelper } from 'helpers/app/FormHelper';
import { isDateInThePast } from 'helpers/app/ComponentsFormHelpers';
import { DemandeRfErrors, DemandeRfUtilitaire } from 'models/types/cms/demandeRF/type';
import { getAgeisMinor } from 'pages/DemandeRF/Helpers/common';

export const getConjointValidationSchema = (
  errorsRfCms: DemandeRfErrors,
  utilitaireCms: DemandeRfUtilitaire,
  nationalitesNonEligibles: any,
) => {
  const formHelper = new FormHelper(errorsRfCms);
  return yup.object().shape({
    rf_demande: formHelper.stringSchema(true),
    conjoint_etranger: formHelper.stringSchema(true),
    sexe_conjoint: formHelper.stringSchema(true),
    nom_naissance_conjoint: formHelper.stringSchema(true),
    nom_usage_conjoint: formHelper.stringSchema(),
    prenoms_conjoint: formHelper.stringSchema(true),
    acte_naissance_conjoint_files: yup.array().when('rf_demande', {
      is: (value: string) => value === CHOIX.OUI,
      then: () => formHelper.requiredFileSchema(),
      otherwise: () => formHelper.notRequiredFileSchema(),
    }),
    acte_naissance_conjoint_traduit: yup.boolean(),
    acte_naissance_conjoint_traduit_files: yup.array().when('acte_naissance_conjoint_traduit', {
      is: true,
      then: () => formHelper.requiredFileSchema(),
      otherwise: () => formHelper.notRequiredFileSchema(),
    }),
    pays_naissance_conjoint: yup
      .string()
      .notRequired()
      .when('rf_demande', {
        is: (value: string) => value === CHOIX.OUI,
        then: () => formHelper.stringSchema(true),
      }),
    ville_naissance_conjoint: yup
      .string()
      .notRequired()
      .when('rf_demande', {
        is: (value: string) => value === CHOIX.OUI,
        then: () => formHelper.stringSchema(true),
      }),
    nationalite_conjoint: yup.string().when('rf_demande', {
      is: (value: string) => value === CHOIX.OUI,
      then: schema =>
        schema
          .required(errorsRfCms.champ_requis)
          .notOneOf(nationalitesNonEligibles, errorsRfCms.non_eligible_rf),
      otherwise: () => formHelper.stringSchema(),
    }),
    date_naissance_conjoint: yup.string().when('rf_demande', {
      is: (value: string) => value === CHOIX.OUI,
      then: () => {
        const res = formHelper
          .stringSchema(true)
          .test('dateMustBeInPast', utilitaireCms.pas_de_date_posterieure, value => {
            return !!(value && isDateInThePast(value));
          })
          .test('mustBeMajor', errorsRfCms.non_eligible_rf, value => {
            return !!(value && !getAgeisMinor(value));
          });
        return res;
      },
      otherwise: schema => schema.notRequired(),
    }),
    titre_sejour_fr_conjoint: yup.string().notRequired(),
    conjoint_titre_identite_files: yup.array().when('rf_demande', {
      is: (value: string) => value !== CHOIX.OUI,
      then: () => formHelper.requiredFileSchema(),
      otherwise: () => formHelper.notRequiredFileSchema(),
    }),
    conjoint_titre_sejour_files: yup.array().when('titre_sejour_fr_conjoint', {
      is: (value: string) => value === CHOIX.OUI,
      then: () => formHelper.requiredFileSchema(),
      otherwise: () => formHelper.notRequiredFileSchema(),
    }),
    mariage_precedent_conjoint: yup.string().notRequired(),
    conjoint_jugement_divorce_files: yup.array().when('mariage_precedent_conjoint', {
      is: (value: string) => value === CHOIX.OUI,
      then: () => formHelper.requiredFileSchema(),
      otherwise: () => formHelper.notRequiredFileSchema(),
    }),
    conjoint_jugement_divorce_traduit: yup.boolean(),
    conjoint_jugement_divorce_traduit_files: yup.array().when('conjoint_jugement_divorce_traduit', {
      is: true,
      then: () => formHelper.requiredFileSchema(),
      otherwise: () => formHelper.notRequiredFileSchema(),
    }),

    [HONEYPOT_NAME]: yup.string(),
  });
};
