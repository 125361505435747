export const allocation = {
  total: null,
  contenu: [
    {
      statutVersement: 'paye',
      dateVersement: '2020-09-29',
      dateSignatureOPC: '2020-03-18',
      attestationVersements: {
        type: 'pdf',
        titre: "Attestation de versement de l'allocation pour demandeur d'asile",
        categorie: 'attestation_versement_allocation_demandeur_asile',
        codeDocument: '7703023985-attestation_versement_allocation_demandeur_asile-pdf',
      },
    },
  ],
};
