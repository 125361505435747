import { cmsSlice } from './cmsSlice';

const cmsErreur = cmsSlice.injectEndpoints({
  endpoints: build => ({
    getErreurCmsPage: build.query<any, { qs?: string; locale: string }>({
      query: ({ locale }) => ({
        url: `/erreur?locale=${locale}&populate=*`,
      }),
    }),
  }),
});

export const { useGetErreurCmsPageQuery } = cmsErreur;
