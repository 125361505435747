import React, { FC, useEffect, useState } from 'react';
import { fr } from '@codegouvfr/react-dsfr';
import { useStyles } from 'tss-react/dsfr';
import Card from '@codegouvfr/react-dsfr/Card';
import { skipToken } from '@reduxjs/toolkit/dist/query/react';

import { downloadFile } from 'helpers/api/download';
import Loader from 'components/Atoms/Loader/Loader';
import { ProcedureType } from 'constants/procedure';
import { DocumentRF } from 'models/types/api/documents';
import { DocumentsCmsComponent } from 'models/types/cms/types';
import { AppDocument } from 'models/types/api/entities';
import { useGetDocumentRfQuery } from 'redux/rtk/api/apiDocuments';
import { DOCUMENTS } from 'routes/path';

type Props = {
  documents: DocumentRF[];
  cmsDocuments: { rf_documents: DocumentsCmsComponent; documents_non_disponibles: string };
  isError: boolean;
};
const Documents: FC<Props> = ({ documents, cmsDocuments, isError }) => {
  const [documentToDownload, setDocumentToDownload] = useState<AppDocument | null>(null);
  const {
    data: documentRF,
    isLoading: isLoadingDocumentRF,
    isError: isErrorDocumentRF,
    error: errorDocumentRF,
  } = useGetDocumentRfQuery(
    documentToDownload && documentToDownload.procedure === ProcedureType.REGROUPEMENT_FAMILIAL
      ? documentToDownload.codeDocument
      : skipToken,
  );
  const { cx } = useStyles();

  useEffect(() => {
    if (documentRF && documentToDownload) {
      downloadFile(
        documentRF.contenu[0].document,
        documentToDownload.titre,
        documentToDownload.type,
      );
    }
  }, [documentRF, documentToDownload]);

  if (isErrorDocumentRF && errorDocumentRF) {
    console.error(errorDocumentRF);
    return <div>error </div>;
  }

  return (
    <>
      <div className={cx(fr.cx('fr-mb-2w'), 'documents-title')}>
        <h3>{cmsDocuments.rf_documents.titre}</h3>
        <a href={DOCUMENTS} className="fr-link fr-icon-arrow-right-line fr-link--icon-right">
          {cmsDocuments.rf_documents.nom_button}{' '}
        </a>
      </div>
      <div className="fr-grid-row">
        {isError ? (
          <>{cmsDocuments.documents_non_disponibles}</>
        ) : (
          <>
            {documents.map(document => (
              <>
                <Card
                  key={document.codeDocument}
                  border
                  enlargeLink
                  horizontal
                  linkProps={{
                    href: '#',
                    onClick: () => setDocumentToDownload(document),
                  }}
                  size="small"
                  title={document.titre}
                  titleAs="h3"
                  endDetail={`${document.type ? `${document.type.toUpperCase()}` : 'PDF'} – ${
                    document.poids
                  }`}
                  className="fr-col-12 fr-mb-md-3w fr-mb-2w documents"
                />
                {isLoadingDocumentRF && (
                  <div>
                    <Loader />
                  </div>
                )}
              </>
            ))}
          </>
        )}
      </div>
    </>
  );
};

export default Documents;
