import { FC, useEffect, useState } from 'react';

import { useGetDocumentAsileQuery } from 'redux/rtk/api/apiDocuments';
import { AppDocument } from 'models/types/api/entities';
import { downloadFile } from 'helpers/api/download';
import { skipToken } from '@reduxjs/toolkit/query/react';

const DownloadButton: FC<any> = ({ allocationData, cmsAllocation }) => {
  const [documentToDownload, setDocumentToDownload] = useState<AppDocument | null>(null);

  const { data: documentRF, isLoading } = useGetDocumentAsileQuery(
    documentToDownload ? { codeDocument: documentToDownload.codeDocument } : skipToken,
  );

  useEffect(() => {
    if (documentRF && documentToDownload) {
      downloadFile(
        documentRF.contenu[0].document,
        documentToDownload.titre,
        documentToDownload.type,
      );
    }
  }, [documentRF, documentToDownload]);

  return (
    <>
      <div className="fr-grid-row ">
        <button
          type="button"
          className="atestation-link fr-icon-arrow-down-line fr-btn--icon-right"
          onClick={() => {
            setDocumentToDownload(allocationData.attestationVersements);
          }}
        >
          {cmsAllocation?.dernier_versement?.telecharger_attestation_versements}
        </button>
        {isLoading && <div className="loader"></div>}
      </div>
      <br></br>
      <span className="download-infos">pdf</span>
    </>
  );
};

export default DownloadButton;
