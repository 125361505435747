/* eslint-disable prefer-object-spread/prefer-object-spread */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DemandeAsile } from 'models/types/api/demandeAsile';
import { ProcedureDemandeType } from 'models/types/api/procedures';
import { initialState } from './utils';
import { ASILE } from 'routes/path';

const asileSlice = createSlice({
  name: 'Asile',
  initialState,
  reducers: {
    setDemandeAsile: (state, action: PayloadAction<DemandeAsile>) => {
      state.demandeAsile.value = Object.assign(
        { type: ProcedureDemandeType.ASILE, path: ASILE },
        action.payload,
      );
    },
    setHasDemandeAsileBeenFetched: (state, action: PayloadAction<boolean>) => {
      state.demandeAsile.hasBeenFetched = action.payload;
    },
  },
});

export const { setDemandeAsile, setHasDemandeAsileBeenFetched } = asileSlice.actions;
export default asileSlice.reducer;
