import React from 'react';
import { useController } from 'react-hook-form';

import CustomCheckbox, {
  CustomCheckboxOptions,
} from 'components/Atoms/Forms/CustomCheckbox/CustomCheckbox';
import { computeIsCheckboxValid } from 'helpers/app/ComponentsStatusHelper';

export interface CustomCheckboxContainerProps {
  options: CustomCheckboxOptions[];
  name: string;
  control: any;
  onChange?: any;
  legend?: string;
  hintText?: string;
  key?: string;
  large?: boolean;
  customClass?: string;
}

const CustomCheckboxContainer: React.FC<CustomCheckboxContainerProps> = ({
  options,
  name,
  control,
  legend,
  hintText,
  onChange,
  large,
  customClass,
}) => {
  const { field, fieldState } = useController({
    name,
    control,
  });

  return (
    <div
      className={`${customClass ? customClass : 'fr-col-12 fr-mt-4v fr-mt-lg-0v fr-mt-md-0v'} ${
        legend ? '' : 'hide-legend'
      } ${large ? 'fr-col' : 'fr-col-lg-4 fr-col-md-4'} `}
    >
      <CustomCheckbox
        legend={legend}
        hintText={hintText}
        options={options}
        onChange={onChange}
        state={computeIsCheckboxValid(fieldState.isTouched, fieldState.error)}
        field={field}
      />
    </div>
  );
};

export default CustomCheckboxContainer;
