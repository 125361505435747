import { cmsSlice } from './cmsSlice';

const cmsDashboard = cmsSlice.injectEndpoints({
  endpoints: build => ({
    getDashboardCms: build.query<any, { locale: string; qs?: string }>({
      query: ({ locale, qs }) => ({
        url: `/tableau-de-bord?locale=${locale}&${qs ? qs : 'populate=*'}`,
      }),
    }),
    getRappelCms: build.query<any, { locale: string; qs?: string }>({
      query: ({ locale }) => ({
        url: `/rappel?locale=${locale}&populate=*`,
      }),
    }),
    getRFCmsTraductionApi: build.query<any, { locale: string; qs?: string }>({
      query: ({ locale, qs }) => ({
        url: `/traduction-api-regroupement-familial?locale=${locale}&${qs}&populate=*`,
      }),
    }),
  }),
});

export const { useGetDashboardCmsQuery, useGetRappelCmsQuery, useGetRFCmsTraductionApiQuery } =
  cmsDashboard;
