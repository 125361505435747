import { Factory } from 'miragejs';

export const suiviRf = Factory.extend({
  beneficiaires: [
    {
      lienFamilial: 'enfant',
      prenomBeneficiaire: 'JEAN',
      nomBeneficiaire: 'LEROY',
      dateNaissance: '2022-12-01',
      nationalite: 'Algérienne',
      paysResidence: 'Algérie',
    },
  ],
  numeroDossier: '47023000000008',
  adresseDemandeur: 'CHEMIN DES ECOLIER 47120 ST JEAN DE DURAS',
  adresseEnqueteDemandeur: '44 RUE BARGUE 75015 PARIS 15',
  directionTerritoriale: 'DT Bordeaux',
  titreSejourValiditeDateDebut: null,
  titreSejourValiditeDateFin: '2023-05-01',
  clefStatut: 'portail__receptionne',
  statut: 'receptionne',
  dateStatut: '2023-06-16',
  numeroAgdref: '7703023991',
  responsableStatut: 'OFII',
  documentsManquant: [],
  enqueteLogement: null,
  enqueteRessources: null,
  etapes: [
    {
      statut: 'en_cours',
      nom: 'receptionnee',
      numero: 1,
      date: '2023-06-16',
    },
    {
      statut: 'a_venir',
      nom: 'en_cours_instruction',
      numero: 2,
      date: null,
    },
    {
      statut: 'a_venir',
      nom: 'en_attente_decision',
      numero: 3,
      date: null,
    },
    {
      statut: 'a_venir',
      nom: 'decision',
      numero: 4,
      date: null,
    },
  ],
  dateEnvoiConsulat: null,
  typeDepot: 'portail',
  idProcedure: null,
  postalStatut: null,
  newDemande: false,
  shouldDisplayEnquete: true,
});
