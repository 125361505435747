import { CHOIX } from 'constants/global';
import { RESIDENCE_LIEU } from 'constants/regroupementFamiliale';
import { RFNG_CAT_MAPPING } from 'constants/upload';
import { getDocumentsByCategory } from 'pages/DemandeRF/Helpers/common';
import { Enfant } from 'models/types/api/rf';
import { EnfantDemandeForm } from 'models/types/app/demandeRf';

export const enfantApiToAppFormatter = (data: Enfant, documents: any) => {
  const enfant: EnfantDemandeForm = {
    rf_demande: data.rfDemande !== null ? (data.rfDemande ? CHOIX.OUI : CHOIX.NON) : undefined,
    enfant_etranger:
      data.vitEnFrance !== null
        ? data.vitEnFrance
          ? RESIDENCE_LIEU.FRANCE
          : RESIDENCE_LIEU.ETRANGER
        : '',
    sexe_enfant: data.etatCivil?.sexe ? data.etatCivil?.sexe : undefined,
    nom_naissance_enfant: data.etatCivil?.nom ? data.etatCivil?.nom : undefined,
    nom_usage_enfant: data.etatCivil?.nomUsage ? data.etatCivil?.nomUsage : undefined,
    prenoms_enfant: data.etatCivil?.prenoms ? data.etatCivil?.prenoms : undefined,
    date_naissance_enfant:
      data.etatCivil?.dateNaissance !== null ? data.etatCivil?.dateNaissance : undefined,
    pays_naissance_enfant: data.etatCivil?.paysNaissance
      ? data.etatCivil?.paysNaissance
      : undefined,
    ville_naissance_enfant: data.etatCivil?.villeNaissance
      ? data.etatCivil?.villeNaissance
      : undefined,
    nationalite_enfant: data.etatCivil?.nationalite ? data.etatCivil?.nationalite : undefined,
    acte_naissance_enfant_files: getDocumentsByCategory(
      RFNG_CAT_MAPPING.ACTE_NAISSANCE_ENFANT,
      documents,
    ),
    acte_naissance_enfant_traduit:
      data.etatCivil?.acteNaissanceTrad !== null ? data.etatCivil?.acteNaissanceTrad : undefined,
    acte_naissance_enfant_traduit_files: getDocumentsByCategory(
      RFNG_CAT_MAPPING.ACTE_NAISSANCE_ENFANT_TRAD,
      documents,
    ),
    filiation: data.filiation !== null ? data.filiation : undefined,
    kafala_algerien_files: getDocumentsByCategory(RFNG_CAT_MAPPING.KAFALA_ALGERIEN, documents),
    kafala_algerien_traduit: data.kafalaTrad !== null ? data.kafalaTrad : undefined,
    kafala_algerien_traduit_files: getDocumentsByCategory(
      RFNG_CAT_MAPPING.KAFALA_ALGERIEN_TRAD,
      documents,
    ),
    jugement_adoption_files: getDocumentsByCategory(RFNG_CAT_MAPPING.JUGEMENT_ADOPTION, documents),
    jugement_adoption_traduit: data.adoptionTrad !== null ? data.adoptionTrad : undefined,
    jugement_adoption_traduit_files: getDocumentsByCategory(
      RFNG_CAT_MAPPING.JUGEMENT_ADOPTION_TRAD,
      documents,
    ),
    lien_second_parent: data.lienSecondParent !== null ? data.lienSecondParent : undefined,
    livret_famille_files: getDocumentsByCategory(RFNG_CAT_MAPPING.LIVRET_FAMILLE, documents),
    jugement_droit_garde_enfants_files: getDocumentsByCategory(
      RFNG_CAT_MAPPING.JUGEMENT_AUTORITE_PARENTALE,
      documents,
    ),
    jugement_droit_garde_enfants_traduit:
      data.droitGardeTrad !== null ? data.droitGardeTrad : undefined,
    jugement_droit_garde_enfants_traduit_files: getDocumentsByCategory(
      RFNG_CAT_MAPPING.JUGEMENT_AUTORITE_PARENTALE_TRAD,
      documents,
    ),
    autre_parent_autorisation_venue_france_files: getDocumentsByCategory(
      RFNG_CAT_MAPPING.AUTRE_PARENT_AUTORISATION_VENUE_FRANCE,
      documents,
    ),
    autre_parent_autorisation_venue_france_traduit:
      data.autorisationVenuTrad !== null ? data.autorisationVenuTrad : undefined,
    autre_parent_autorisation_venue_france_traduit_files: getDocumentsByCategory(
      RFNG_CAT_MAPPING.AUTRE_PARENT_AUTORISATION_VENUE_FRANCE_TRAD,
      documents,
    ),
    acte_deces_files: getDocumentsByCategory(RFNG_CAT_MAPPING.ACTE_DECES_AUTRE_PARENT, documents),
    acte_deces_traduit: data.decesConjointTrad !== null ? data.decesConjointTrad : undefined,
    acte_deces_traduit_files: getDocumentsByCategory(
      RFNG_CAT_MAPPING.ACTE_DECES_AUTRE_PARENT_TRAD,
      documents,
    ),
    declaration_disparition_autre_parent_files: getDocumentsByCategory(
      RFNG_CAT_MAPPING.DECLARATION_ABANDON_ENFANT,
      documents,
    ),
    declaration_disparition_autre_parent_traduit:
      data.decesConjointTrad !== null ? data.decesConjointTrad : undefined,
    declaration_disparition_autre_parent_traduit_files: getDocumentsByCategory(
      RFNG_CAT_MAPPING.DECLARATION_ABANDON_ENFANT_TRAD,
      documents,
    ),
  };

  return enfant;
};
