import { ProcedureDemandeType } from 'models/types/api/procedures';
import { RfInfos } from 'models/types/api/rf';

export type RfInfosState = Readonly<RfInfos>;

export const initialState: RfInfosState = {
  idCerfaRfng: null,
  idRfng: null,
  numeroDossier: null,
  signatureDocumentLieu: null,
  statut: '',
  type: ProcedureDemandeType.DEMANDE_RF,
};
