export const cookiesMigrations = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  0: (previousVersionState: any): any => {
    return {
      registeredAt: previousVersionState.registeredAt,
      cookiesAcceptance: {
        facebookCookiesAccepted: previousVersionState.cookiesAcceptance.facebookCookiesAccepted,
        twitterCookiesAccepted: previousVersionState.cookiesAcceptance.twitterCookiesAccepted,
        youtubeCookiesAccepted: previousVersionState.cookiesAcceptance.youtubeCookiesAccepted,
        mixpanelCookiesAccepted: previousVersionState.cookiesAcceptance.mixpanelCookiesAccepted,
      },
    };
  },
};
