import { cmsSlice } from './cmsSlice';

const cmsSuiviRF = cmsSlice.injectEndpoints({
  endpoints: build => ({
    getSuiviRFCms: build.query<any, { qs?: string; locale: string }>({
      query: ({ locale }) => ({ url: `/rf-suivi?locale=${locale}&populate=*` }),
    }),
    getTraductionRFCms: build.query<any, { qs?: string; locale: string }>({
      query: ({ locale, qs }) => ({
        url: `/traduction-api-regroupement-familial?locale=${locale}&${qs}`,
      }),
    }),
  }),
});

export const { useGetSuiviRFCmsQuery, useGetTraductionRFCmsQuery } = cmsSuiviRF;
