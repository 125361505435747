import { FC, ReactNode } from 'react';

interface ThreeSlotElementProps {
  className: string;
  keyName: string;
  children: ReactNode;
}

const ThreeSlotElement: FC<ThreeSlotElementProps> = ({ className, children, keyName }) => {
  return (
    <div
      className={`fr-col-xs-4 fr-col-sm-12 fr-col-md-4 fr-col-lg-4 ${className}__container`}
      key={keyName}
    >
      {children}
    </div>
  );
};

export default ThreeSlotElement;
