/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDebounce } from 'react-use';

import { AddressSelectOption } from 'models/types/app/components';
import { useGetAddressQuery } from 'redux/rtk/address/addressSlice';
import Autocomplete from 'components/Atoms/Forms/Autocomplete/Autocomplete';

import { searchAdress } from './AutocompleteContainer.utils';

interface AutocompleteFromBANOProps {
  label: string | React.ReactNode;
  hintText?: string;
  stateRelatedMessage?: string;
  state: string;
  name: string;
  control: any;
  field: any;
  lang: string;
  disabled?: boolean;
  searchType: 'municipality' | undefined;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isOpen: boolean;
}

const AutocompleteFromBANO: React.FC<AutocompleteFromBANOProps> = ({
  label,
  hintText,
  stateRelatedMessage,
  state,
  name,
  control,
  field,
  disabled = false,
  searchType,
  setIsOpen,
  isOpen,
}) => {
  const [searchOn, setSearchOn] = useState<boolean>(false);
  const [addresses, setAddresses] = useState<any[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('aaa');
  const [val, setVal] = useState('');

  if (searchTerm.length < 3) {
    setSearchTerm('aaa'); // useState default don't work always on 1st render and throw useGetAddressQuery error
  }

  const { data: listAdresses } = useGetAddressQuery({
    type: searchType,
    text: searchTerm,
  });

  const onValueChange = (option: AddressSelectOption, inputValue: string) => {
    if ((inputValue !== undefined && inputValue !== '') || isOpen) {
      setSearchOn(true);
      setVal(inputValue);
      field.onChange(option);
    } else {
      setSearchOn(false);
      setVal(inputValue);
      field.onChange(undefined);
    }
  };

  if (val === '' && field.value && field.value !== '') {
    setVal(field.value.value);
  }

  useEffect(() => {
    searchAdress(listAdresses, searchOn, searchType, setAddresses);
  }, [searchTerm, searchOn]);

  const [] = useDebounce(
    () => {
      setSearchTerm(val);
    },
    200,
    [val],
  );

  return (
    listAdresses && (
      <Autocomplete
        label={label}
        hintText={hintText}
        stateRelatedMessage={stateRelatedMessage}
        name={name}
        state={state}
        field={field}
        control={control}
        onValueChange={onValueChange}
        labelIsDisplayedValue={true}
        value={val}
        options={addresses}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        disabled={disabled}
      />
    )
  );
};

export default AutocompleteFromBANO;
