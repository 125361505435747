import { ContactCmsPage } from 'models/types/cms/contact/types';

import { cmsSlice } from './cmsSlice';

const cmsContact = cmsSlice.injectEndpoints({
  endpoints: build => ({
    getContactCmsPage: build.query<ContactCmsPage, { qs: string; locale: string }>({
      query: ({ qs, locale }) => ({ url: `/contact?locale=${locale}&${qs}` }),
    }),
  }),
  overrideExisting: false,
});

export const { useGetContactCmsPageQuery } = cmsContact;
