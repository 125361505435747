/* eslint-disable react-hooks/rules-of-hooks */
import { proceduresQuery, proceduresSecondQuery } from 'pages/Home/Home.query';
import { useGetProceduresCmsCollectionQuery } from 'redux/rtk/cms/cmsCommon';

export const getProceduresContent = (lang: string) => {
  const {
    data: proceduresCms1,
    error: proceduresCmsError1,
    isLoading: isProceduresCmsLoading1,
  } = useGetProceduresCmsCollectionQuery({
    qs: proceduresQuery,
    locale: lang !== '' ? lang : 'fr',
  });

  const {
    data: proceduresCms2,
    error: proceduresCmsError2,
    isLoading: isProceduresCmsLoading2,
  } = useGetProceduresCmsCollectionQuery({
    qs: proceduresSecondQuery,
    locale: lang !== '' ? lang : 'fr',
  });

  const isProceduresCmsLoading = isProceduresCmsLoading1 || isProceduresCmsLoading2;
  const proceduresCmsError = proceduresCmsError1 || proceduresCmsError2;

  const allProceduresCms = [proceduresCms1, proceduresCms2];
  const areAllProceduresCmsFilled = allProceduresCms.every(
    procedureCms => procedureCms && procedureCms.data,
  );

  const proceduresCms = { data: [] } as any;

  if (areAllProceduresCmsFilled) {
    // eslint-disable-next-line
    proceduresCms1.data.forEach((_: any, index: number) => {
      proceduresCms.data[index] = {
        attributes: {
          ...proceduresCms1.data[index].attributes,
          image_principale: proceduresCms1.data[index].attributes.image_principale,
          contenu: proceduresCms2.data[index].attributes.contenu,
        },
        id: proceduresCms1.data[index].id,
      };
    });
  }

  return {
    proceduresCms,
    proceduresCmsError,
    isProceduresCmsLoading,
  };
};
