/* eslint-disable complexity */
import { FC } from 'react';
import { useSelector } from 'react-redux';
import Badge from '@codegouvfr/react-dsfr/Badge';
import Alert from '@codegouvfr/react-dsfr/Alert';
import Button from '@codegouvfr/react-dsfr/Button';

import Loader from 'components/Atoms/Loader/Loader';
import Document from 'pages/Documents/components/Document';
import AppBreadcrumb from 'components/Atoms/Breadcrumb/Breadcrumb';

import { useGetDocumentsAsileQuery } from 'redux/rtk/api/apiDocuments';
import { useGetGlobalUtilitaireCmsQuery } from 'redux/rtk/cms/cmsUtilitaire';
import { useGetAsilePageQuery, useGetRappelsCmsQuery } from 'redux/rtk/cms/cmsAsile';
import { useGetHebergementsQuery, useGetVersementsQuery } from 'redux/rtk/api/apiDna';
import { getCurrentLocale } from 'redux/locale/selectors';
import { ALLOCATION, DOCUMENTS } from 'routes/path';

import './EspaceAsile.scss';
import { convertDate } from 'helpers/app/dateHelper';

const EspaceAsile: FC = () => {
  const lang = useSelector(getCurrentLocale) as string;
  const { cmsAsile } = useGetAsilePageQuery(
    {
      locale: lang !== '' ? lang : 'fr',
    },
    {
      selectFromResult: ({ data: res }) => ({
        cmsAsile: {
          dernier_versement: res?.data.attributes.dernier_versement,
          documents: res?.data.attributes.documents,
          hebergement: res?.data.attributes.hebergement,
          titre_page: res?.data.attributes.titre_page,
        },
      }),
    },
  );

  const { data: asileDocumentsData, isLoading: isLoadingAsileDocuments } =
    useGetDocumentsAsileQuery();

  const { cmsRappel } = useGetRappelsCmsQuery(
    {
      locale: lang !== '' ? lang : 'fr',
    },
    {
      selectFromResult: ({ data: res }) => ({
        cmsRappel: {
          duree_notification_versement: res?.data.attributes.duree_notification_versement,
          duree_changement_statut_rf: res?.data.attributes.duree_changement_statut_rf,
          duree_changement_statut_reinsertion:
            res?.data.attributes.duree_changement_statut_reinsertion,
          texte_rappel_changement_statut_reinsertion:
            res?.data.attributes.texte_rappel_changement_statut_reinsertion,
          texte_rappel_statut_retour_voyage_planifie:
            res?.data.attributes.texte_rappel_statut_retour_voyage_planifie,
          texte_rappel_statut_retour_attente_reorganisation:
            res?.data.attributes.texte_rappel_statut_retour_attente_reorganisation,
          texte_rappel_statut_retour_clos: res?.data.attributes.texte_rappel_statut_retour_clos,
          titre: res?.data.attributes.titre,
          texte_rappel_dernier_versement: res?.data.attributes.texte_rappel_dernier_versement,
          aucun_rappel: res?.data.attributes.aucun_rappel,
          texte_rappel_changement_statut_rf: res?.data.attributes.texte_rappel_changement_statut_rf,
        },
      }),
    },
  );

  const {
    data: versementsData,
    error: versementsError,
    isLoading: isVersementsLoading,
  } = useGetVersementsQuery(undefined);

  const {
    data: hebergementsData,
    error: hebergementsError,
    isLoading: isHebergementsLoading,
  } = useGetHebergementsQuery();

  const {
    data: utilitaireData,
    isLoading: isUtilitaireLoading,
    error: utilitaireError,
  } = useGetGlobalUtilitaireCmsQuery({
    locale: lang !== '' ? lang : 'fr',
  });

  const documentData = asileDocumentsData;
  const versements = versementsData?.contenu.filter(el => el.statut === 'paye');
  const dernierVersement = versements && versements[0];

  if (versementsError || hebergementsError || utilitaireError) {
    console.log('une erreur est survenu:', versementsError, hebergementsError);
  }

  if (
    isVersementsLoading ||
    isHebergementsLoading ||
    isLoadingAsileDocuments ||
    isUtilitaireLoading
  ) {
    return <Loader />;
  }

  return (
    <main id="espace-asile">
      <div className="fr-container">
        <AppBreadcrumb />
        <h1 className="fr-mt-3w fr-mt-md-5w fr-mb-5w">{cmsAsile && cmsAsile.titre_page}</h1>
        <div className="fr-grid-row fr-grid-row--gutters fr-mb-5w fr-p-2w top-row">
          <div className="fr-col">
            {cmsAsile && cmsAsile.dernier_versement && (
              <>
                <h2 className="fr-h3">{cmsAsile.dernier_versement.titre}</h2>
                {versements && versements?.length > 0 && (
                  <div className="fr-container payments-card">
                    {dernierVersement ? (
                      <>
                        <div className="fr-grid-row">
                          <div className="fr-col payments-card__left-part">
                            <span className="payments-card__title">
                              {dernierVersement.statut === 'paye'
                                ? '+ ' + dernierVersement.montant + ' '
                                : '---- '}
                              €
                            </span>
                            <Badge
                              severity={dernierVersement.statut === 'paye' ? 'success' : 'error'}
                            >
                              {dernierVersement.statut === 'paye'
                                ? cmsAsile?.dernier_versement?.verse?.toUpperCase()
                                : cmsAsile?.dernier_versement?.erreur?.toUpperCase()}
                            </Badge>
                          </div>
                          <hr />
                          <div className="fr-col payments-card__right-part">
                            <p className="payments-card__line">
                              <span className="bolder">{cmsAsile.dernier_versement.periode}</span> :{' '}
                              {dernierVersement.periode}
                            </p>
                            <p className="payments-card__line">
                              <span className="bolder">{utilitaireData.data.attributes.date}</span>{' '}
                              : {convertDate(dernierVersement.dateVersement)}
                            </p>
                            <p className="payments-card__line">
                              <span className="bolder">
                                {cmsAsile.dernier_versement.nombre_jours}
                              </span>{' '}
                              : {dernierVersement.nbrJourCalcule}{' '}
                              {utilitaireData.data.attributes.jour}s
                            </p>
                          </div>
                          <a
                            href={ALLOCATION}
                            className="card-link fr-link fr-icon-arrow-right-line fr-link--icon-right"
                          >
                            {cmsAsile.dernier_versement.acceder_page}
                          </a>
                        </div>
                      </>
                    ) : (
                      <a
                        href={ALLOCATION}
                        className="card-link fr-link fr-icon-arrow-right-line fr-link--icon-right"
                      >
                        {cmsAsile.dernier_versement.acceder_page}
                      </a>
                    )}
                  </div>
                )}
                {(!versements || versements.length === 0) && (
                  <div className="fr-container no-payment-card">
                    <img src="/img/no-item-illustration.svg" />
                    <p> {cmsAsile.dernier_versement.aucun_versement_message}</p>
                  </div>
                )}
              </>
            )}
          </div>
          <div className="fr-col">
            {cmsRappel && (
              <>
                <h2 className="fr-h3">{cmsRappel.titre}</h2>
                <Alert
                  className="rappel-alert"
                  title={cmsAsile.hebergement.titre_encart_informations}
                  description={cmsAsile.hebergement.contenu_encart_informations}
                  severity="info"
                  small
                />
                {/*<div className="fr-p-3w no-reminder-card">
                    <img src="/img/no-item-illustration.svg" />
                    <p>Vous n'avez aucun rappel pour le moment</p>
                  </div>*/}
              </>
            )}
          </div>
        </div>
        <div className="fr-grid-row fr-grid-row--gutters fr-mb-5w fr-p-2w">
          <div className="fr-col documents-column">
            <div className="fr-grid-row title-line">
              {cmsAsile && cmsAsile.documents && (
                <>
                  <h2 className="fr-h3">{cmsAsile.documents.titre}</h2>
                  <a
                    className="fr-link fr-icon-arrow-right-line fr-link--icon-right fr-mb-1w fr-mb-md-3w fr-mt-0 hebergement-link"
                    href={DOCUMENTS}
                  >
                    {cmsAsile.documents.nom_button}
                  </a>
                </>
              )}
            </div>
            {documentData &&
              documentData.contenu.map(doc => {
                return <Document key={doc.codeDocument} document={doc} lang={lang} />;
              })}
          </div>
          <div className="fr-col hebergement-column">
            {cmsAsile && cmsAsile.hebergement && (
              <>
                <h2 className="fr-h3">{cmsAsile.hebergement.titre}</h2>
                {hebergementsData && (
                  <div className="fr-container hebergement-container">
                    <div className="fr-grid-row row-illustration">
                      <img className="fr-mt-5w fr-mb-4w" src="/img/image_Asile.png" />
                    </div>
                    <hr />
                    <div className="fr-grid-row fr-mb-4w fr-mt-4w fr-pl-4w fr-pr-4w">
                      <span
                        className="column-icon fr-icon-community-line"
                        aria-hidden="true"
                      ></span>

                      <div className="fr-col">
                        <p className="fr-mb-1w part-title">{cmsAsile.hebergement.nom_centre}</p>
                        <p>
                          {hebergementsData.contenu[0].nomStructure}{' '}
                          {hebergementsData.contenu[0].adresseStructure}
                        </p>
                        <Button
                          iconId="fr-icon-map-pin-2-line"
                          iconPosition="right"
                          onClick={() => {
                            window.open(
                              `https://www.google.com/maps/place/${hebergementsData.contenu[0].adresseStructure}`,
                              '_blank',
                              'location=yes',
                            );
                          }}
                          priority="tertiary"
                        >
                          {cmsAsile.hebergement.voir_sur_carte}
                        </Button>
                      </div>
                    </div>
                    <hr />
                    <div className="fr-grid-row fr-mb-4w fr-mt-4w fr-pl-4w fr-pr-4w">
                      <span className="column-icon fr-icon-phone-line" aria-hidden="true"></span>
                      <div className="fr-col">
                        <p className="fr-mb-1w part-title">
                          {cmsAsile.hebergement.telephone_centre}
                        </p>
                        <p>{hebergementsData.contenu[0].telStructure}</p>
                        <Button
                          iconId="fr-icon-phone-line"
                          iconPosition="right"
                          onClick={() => {
                            window.open(`tel:${hebergementsData.contenu[0].telStructure}`);
                          }}
                          priority="tertiary"
                        >
                          {cmsAsile.hebergement.appeler_centre}
                        </Button>
                      </div>
                    </div>
                    <hr />
                    <div className="fr-grid-row  fr-mb-4w fr-mt-4w fr-pl-4w fr-pr-4w">
                      <span className="column-icon fr-icon-home-4-line" aria-hidden="true"></span>
                      <div className="fr-col">
                        <p className="fr-mb-1w part-title">{cmsAsile.hebergement.statut_centre}</p>
                        <p>
                          {hebergementsData.contenu[0].statutHebergement === 'Domicilie'
                            ? cmsAsile.hebergement.domicilie_depuis
                            : cmsAsile.hebergement.heberge_depuis}{' '}
                          {convertDate(hebergementsData.contenu[0].dateHebergement)}
                          {}
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </main>
  );
};

export default EspaceAsile;
