/* eslint-disable complexity */
import React, { FC, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import { getCurrentLocale } from 'redux/locale/selectors';
import Loader from 'components/Atoms/Loader/Loader';
import { setArticles } from 'redux/pages';
import { getArticles } from 'redux/pages/selectors';

import CmsPage from 'pages/CmsPage/CmsPage';
import { useGetArticlesCmsCollectionQuery } from 'redux/rtk/cms/cmsCommon';
import {
  articlesQuery1,
  articlesQuery2,
  articlesQuery3,
  articlesQuery4,
} from 'pages/Home/Home.query';

const NewsCms: FC = () => {
  const lang = useSelector(getCurrentLocale) as string;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const articleStore = useSelector(getArticles);

  const {
    data: articlesCms1,
    error: articlesCmsError1,
    isLoading: isArticlesCmsLoading1,
  } = useGetArticlesCmsCollectionQuery({
    qs: articlesQuery1,
    locale: lang !== '' ? lang : 'fr',
  });

  const {
    data: articlesCms2,
    error: articlesCmsError2,
    isLoading: isArticlesCmsLoading2,
  } = useGetArticlesCmsCollectionQuery({
    qs: articlesQuery2,
    locale: lang !== '' ? lang : 'fr',
  });

  const {
    data: articlesCms3,
    error: articlesCmsError3,
    isLoading: isArticlesCmsLoading3,
  } = useGetArticlesCmsCollectionQuery({
    qs: articlesQuery3,
    locale: lang !== '' ? lang : 'fr',
  });

  const {
    data: articlesCms4,
    error: articlesCmsError4,
    isLoading: isArticlesCmsLoading4,
  } = useGetArticlesCmsCollectionQuery({
    qs: articlesQuery4,
    locale: lang !== '' ? lang : 'fr',
  });

  useEffect(() => {
    if (
      !articlesCmsError1 &&
      !articlesCmsError2 &&
      !articlesCmsError3 &&
      !articlesCmsError4 &&
      !isArticlesCmsLoading1 &&
      !isArticlesCmsLoading2 &&
      !isArticlesCmsLoading3 &&
      !isArticlesCmsLoading4
    ) {
      const articlesCms = { data: [] } as any;

      // eslint-disable-next-line
      articlesCms1.data.forEach((_: any, index: number) => {
        articlesCms.data[index] = {
          attributes: {
            ...articlesCms1.data[index].attributes,
            image_principale_petite: articlesCms2.data[index].attributes.image_principale_petite,
            contenu: articlesCms3.data[index].attributes.contenu,
            categories_article: articlesCms4.data[index].attributes.categories_article,
          },
          id: articlesCms1.data[index].id,
        };
      });

      dispatch(setArticles(articlesCms.data));
    }
  }, [
    articlesCmsError1,
    articlesCmsError2,
    articlesCmsError3,
    articlesCmsError4,
    isArticlesCmsLoading1,
    isArticlesCmsLoading2,
    isArticlesCmsLoading3,
    isArticlesCmsLoading4,
    dispatch,
  ]);

  if (
    isArticlesCmsLoading1 ||
    isArticlesCmsLoading2 ||
    isArticlesCmsLoading3 ||
    isArticlesCmsLoading4
  ) {
    return <Loader />;
  }

  if (articlesCmsError1 || articlesCmsError2 || articlesCmsError3 || articlesCmsError4) {
    navigate('/erreur');
  }

  return <CmsPage type="news" store={articleStore} lang={lang} />;
};

export default NewsCms;
