import React, { useEffect, useState } from 'react';

import { Geocode } from 'models/types/api/geocode';
import { AddressSelectOption } from 'models/types/app/components';

export function searchAdress(
  listAdresses: Geocode | undefined,
  searchOn: boolean,
  searchType: string | undefined,
  setAddresses: React.Dispatch<React.SetStateAction<any[]>>,
) {
  if (searchOn && listAdresses) {
    const cityCodeExclude = ['75056', '13055', '69123'];
    const filterCities = listAdresses.features.filter(
      (item: any) => !cityCodeExclude.includes(item.properties.citycode),
    );

    if (searchType === 'municipality') {
      setAddresses(
        filterCities.map((item: any): AddressSelectOption => {
          return {
            label: item.properties.label,
            value: item.properties.id,
            cityCode: item.properties.citycode,
            postCode: item.properties.postcode,
            city: item.properties.city,
            street: item.properties.street,
            houseNumber: item.properties.housenumber,
          };
        }),
      );
    } else {
      setAddresses(
        listAdresses.features.map((item: any): AddressSelectOption => {
          return {
            label: item.properties.label,
            value: item.properties.id,
            cityCode: item.properties.citycode,
            postCode: item.properties.postcode,
            city: item.properties.city,
            street: item.properties.street,
            houseNumber: item.properties.housenumber,
          };
        }),
      );
    }
  } else {
    return [];
  }
}

export function useOnSearch(payload: { label: string; value: string }[], value: string) {
  const [options, setOptions] = useState<{ label: string; value: string }[]>([]);
  function searchOption() {
    if (value && value !== '') {
      const filteredArray = payload.filter(option =>
        option.label.toUpperCase().includes(value.toUpperCase()),
      );
      const newOptionsValue: { label: string; value: string }[] = filteredArray;
      setOptions(newOptionsValue);
    }
  }

  useEffect(() => {
    searchOption();
  }, [value]);

  return options;
}
