import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Loader from 'components/Atoms/Loader/Loader';
import { getCurrentLocale } from 'redux/locale/selectors';
import { setAccueilEtIntegrationPage } from 'redux/pages';
import { useGetAccueilEtIntegrationCmsPageQuery } from 'redux/rtk/cms/cmsAccueilEtIntegration';
import { accueilEtIntegrationQuery1, accueilEtIntegrationQuery2 } from 'pages/Home/Home.query';
import { getAccueilEtIntegrationPage } from 'redux/pages/selectors';
import CmsPage from '../CmsPage';

const AccueilEtIntegration: FC<any> = ({}) => {
  const lang = useSelector(getCurrentLocale) as string;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const accueilEtIntegrationStore = useSelector(getAccueilEtIntegrationPage);

  const {
    data: accueilIntegrationCms1,
    error: accueilIntegrationCmsError1,
    isLoading: isAccueilIntegrationCmsLoading1,
  } = useGetAccueilEtIntegrationCmsPageQuery({
    qs: accueilEtIntegrationQuery1,
    locale: lang !== '' ? lang : 'fr',
  });

  const {
    data: accueilIntegrationCms2,
    error: accueilIntegrationError2,
    isLoading: isAccueilIntegrationCmsLoading2,
  } = useGetAccueilEtIntegrationCmsPageQuery({
    qs: accueilEtIntegrationQuery2,
    locale: lang !== '' ? lang : 'fr',
  });

  useEffect(() => {
    if (
      !accueilIntegrationCmsError1 &&
      !accueilIntegrationError2 &&
      !isAccueilIntegrationCmsLoading1 &&
      !isAccueilIntegrationCmsLoading2
    ) {
      const accueilIntegrationCms = {
        data: {
          id: accueilIntegrationCms1?.data?.id ?? '',
          attributes: {
            ...(accueilIntegrationCms1.data.attributes ?? {}),
            apprendre_francais: accueilIntegrationCms1.data.attributes.apprendre_francais ?? {},
            etapes_parcours: accueilIntegrationCms2.data.attributes.etapes_parcours ?? {},
          },
        },
      };

      dispatch(setAccueilEtIntegrationPage(accueilIntegrationCms));
    }
  }, [
    accueilIntegrationCmsError1,
    accueilIntegrationError2,
    isAccueilIntegrationCmsLoading1,
    isAccueilIntegrationCmsLoading2,
  ]);

  if (isAccueilIntegrationCmsLoading1 || isAccueilIntegrationCmsLoading2) {
    return <Loader />;
  }

  if (accueilIntegrationCmsError1 || accueilIntegrationError2) {
    navigate('/erreur');
  }

  return <CmsPage type="accueil_et_integration" store={accueilEtIntegrationStore} lang={lang} />;
};

export default AccueilEtIntegration;
