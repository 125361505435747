import { initialState as initialAccomodationState, AccomodationState } from './accomodation/utils';
import { initialState as initialCommitmentsState, CommitmentsState } from './commitment/utils';
import { initialState as initialFamilyState, FamilyState } from './family/utils';
import { initialState as initialIdentityState, IdentityState } from './identity/utils';
import { initialState as initialInfosState, RfInfosState } from './infos/utils';
import { initialState as initialRessourcesState, RessourcesState } from './ressources/utils';
import { RFDemandeSummaryState } from './rfSummary/utils';

export type DemandeRfState = Readonly<{
  id?: string | null;
  rfDemandeSummary: { contenu: RFDemandeSummaryState[]; total: number };
  demandeList: RfInfosState[];
  hasLoad: boolean;
  infos: RfInfosState;
  identity: IdentityState;
  family: FamilyState;
  accomodation: AccomodationState;
  ressources: RessourcesState;
  commitments: CommitmentsState;
  isDemandeHasBeenSubmitted: boolean;
}>;

export const initialState: DemandeRfState = {
  id: null,
  rfDemandeSummary: { contenu: [], total: 0 },
  demandeList: [initialInfosState],
  hasLoad: false,
  infos: initialInfosState,
  identity: initialIdentityState,
  family: initialFamilyState,
  accomodation: initialAccomodationState,
  ressources: initialRessourcesState,
  commitments: initialCommitmentsState,
  isDemandeHasBeenSubmitted: false,
};
