import { format, parse } from 'date-fns';

export const deserializeDate = (date: string, dateFormat: string): Date => {
  const deserializedDate = parse(date, dateFormat, new Date());

  if (isNaN(deserializedDate.getTime())) {
    throw new Error('Format de date non reconnu');
  }

  return deserializedDate;
};

export const deserializeNullableDate = (
  date: string | undefined,
  dateFormat: string,
): Date | null => {
  if (!date) {
    return null;
  }

  const deserializedDate = parse(date, dateFormat, new Date());

  return isNaN(deserializedDate.getTime()) ? null : deserializedDate;
};

export const formatNullableDate = (
  date: string | undefined | null,
  formatFrom: string,
  formatTo: string,
): string | null => {
  if (!date) {
    return null;
  }
  const deserializedDate = deserializeNullableDate(date, formatFrom);
  if (deserializedDate === null) {
    return null;
  }
  return format(deserializedDate, formatTo);
};
