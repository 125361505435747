import { Family } from 'models/types/api/rf';

export type FamilyState = Readonly<Family>;

export const initialState: FamilyState = {
  isValid: false,
  isUntouched: true,
  documents: [],
  conjoint: [],
  enfants: [],
  unions: [],
  adresses: [],
};
