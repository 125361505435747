/* eslint-disable complexity */
import { KEY_PATHS } from 'routes/path';
import { PagesTitle } from 'redux/pages/pageState.model';
import { RootState } from '../reducers';
import { specificPageNames } from 'constants/global';

export const getMenu = (state: RootState) => state.pages.shared.menu;
export const getHeader = (state: RootState) => state.pages.shared.header;
export const getFooter = (state: RootState) => state.pages.shared.footer;
export const getArticles = (state: RootState) => state.pages.shared.articles;
export const getProcedures = (state: RootState) => state.pages.shared.procedures;
export const getOnlineServices = (state: RootState) => state.pages.shared.services;
export const getLayoutBlocs = (state: RootState) => state.pages.shared.blocs;

export const getAccueilPage = (state: RootState) => state.pages.accueil;
export const getServicesPage = (state: RootState) => state.pages.services;
export const getArticlesPage = (state: RootState) => state.pages.articles;
export const getLocationPage = (state: RootState) => state.pages.location;
export const getProceduresPage = (state: RootState) => state.pages.procedures;
export const getAccueilEtIntegrationPage = (state: RootState) => state.pages.accueil_et_integration;
export const getAccountCreationPage = (state: RootState) => state.pages.creation_de_compte;
export const getMentionsPage = (state: RootState) => state.pages.mentions;
export const getRgpdPage = (state: RootState) => state.pages.rgpd;
export const getCookiePage = (state: RootState) => state.pages.cookie;
export const getAccessibilitePage = (state: RootState) => state.pages.accessibilite;
export const getAccountPage = (state: RootState) => state.pages.compte;
export const getDocumentsPage = (state: RootState) => state.pages.documents;

export const getDashboardPage = (state: RootState) => state.pages.dashboard;

export const getPagesTitles = (state: RootState) => state.pages.titrePages;

function getObjectKey(obj: any, value: string) {
  return (Object.keys(obj) as (keyof typeof obj)[]).find(key => obj[key].path === value);
}

export const getPageTitre = (path: string) => (state: RootState) => {
  type ObjectKey = keyof typeof state.pages;

  const arr = path.split('/');
  if (arr.length > 2) {
    type ObjectKeyShared = keyof typeof state.pages.shared;
    const key = getObjectKey(KEY_PATHS, `/${arr[1]}`) as ObjectKeyShared;
    if (
      key !== undefined &&
      state.pages.shared !== null &&
      state.pages.shared[key] !== null &&
      Array.isArray(state.pages.shared[key])
    ) {
      const arr2 = state.pages.shared[key] as Array<any>;
      const trouve = arr2.find((element: any) => element.id === arr[2]);
      if (trouve) {
        return trouve.title;
      }
    }
  }

  const key = getObjectKey(KEY_PATHS, path) as ObjectKey;

  if (
    typeof key === 'string' &&
    key !== undefined &&
    key !== 'titrePages' &&
    state.pages.titrePages !== undefined &&
    state.pages.titrePages[key as keyof PagesTitle] !== null &&
    state.pages.titrePages.hasOwnProperty(key as keyof PagesTitle)
  ) {
    return state.pages.titrePages[key as keyof PagesTitle];
  }

  if (manageSpecificCases(path, state)) {
    return manageSpecificCases(path, state);
  }

  if (
    key !== undefined &&
    key !== 'titrePages' &&
    state.pages[key] !== undefined &&
    state.pages[key].hasOwnProperty('titre')
  ) {
    return state.pages[key].titre;
  }
  return '';
};

const manageSpecificCases = (path: string, state: RootState) => {
  path = path.substring(1);
  let newKey = '';

  if (path.indexOf('-') > 0) {
    newKey = path.split('-')[0];
  }
  if (specificPageNames[newKey as keyof typeof specificPageNames]) {
    return state.pages.titrePages[
      specificPageNames[newKey as keyof typeof specificPageNames] as keyof PagesTitle
    ];
  }

  return false;
};
