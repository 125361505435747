import React, { FC, useMemo } from 'react';
import { Stepper } from '@codegouvfr/react-dsfr/Stepper';

import { RFDemande } from 'models/types/api/rf';
import { RFCmsTraductionApi } from 'models/types/cms/espaceRF/types';

import { isEtapeCourante } from '../helpers/utils';

const getStep = (
  demande: RFDemande,
  cmsTrad: RFCmsTraductionApi,
): {
  currentStep: number;
  currentTitle: string;
  nextTitle: string;
} => {
  const initialStep = {
    currentStep: 1,
    currentTitle: '',
    nextTitle: '',
  };

  let nextStep = null;
  const currentStep = demande.etapes.find(etape => isEtapeCourante(etape, demande.etapes));
  if (currentStep) {
    nextStep = demande.etapes.find(etape => etape.numero === currentStep?.numero + 1);
    return {
      currentStep: currentStep.numero,
      currentTitle: cmsTrad.clef_statuts[demande.clefStatut].statut,
      nextTitle: nextStep?.nom ? cmsTrad.nom_etapes[nextStep.nom] : '',
    };
  }
  return initialStep;
};

type Props = {
  demande: RFDemande;
  cmsTrad: RFCmsTraductionApi;
};
const Timeline: FC<Props> = ({ demande, cmsTrad }) => {
  const stepperData = useMemo(() => getStep(demande, cmsTrad), [demande, cmsTrad]);
  return (
    <>
      <Stepper
        className="fr-mb-3w fr-mb-md-5w"
        currentStep={stepperData.currentStep}
        nextTitle={stepperData.nextTitle}
        stepCount={4}
        title={stepperData.currentTitle}
      />
    </>
  );
};

export default Timeline;
