import { cmsSlice } from './cmsSlice';

const cmsAccueilEtIntegration = cmsSlice.injectEndpoints({
  endpoints: build => ({
    getAccueilEtIntegrationCmsPage: build.query<any, { qs: string; locale: string }>({
      query: ({ qs, locale }) => ({
        url: `/accueil-et-integration?locale=${locale}&${qs}`,
      }),
    }),
  }),
  overrideExisting: false,
});

export const { useGetAccueilEtIntegrationCmsPageQuery } = cmsAccueilEtIntegration;
