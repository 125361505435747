import { useEffect } from 'react';

import { useGetDirectionTerritorialesAuthenticatedQuery } from 'redux/rtk/api/apiCommon';
import Loader from 'components/Atoms/Loader/Loader';

import ResultLocation from './ResultLocation';

type Props = {
  skipConnected: boolean;
  setSkipConnected: (skip: boolean) => void;
};
const LocationAuthenticated = ({ skipConnected, setSkipConnected }: Props) => {
  const {
    data: dataResultsConnected,
    isError: isErrorConnected,
    isFetching: isFetchingConnected,
  } = useGetDirectionTerritorialesAuthenticatedQuery(undefined, {
    skip: skipConnected,
  });

  useEffect(() => {
    if (isErrorConnected) {
      setSkipConnected(true);
    }
  }, [isErrorConnected, setSkipConnected]);

  return (
    <>
      {isFetchingConnected ? (
        <Loader />
      ) : (
        <>
          {isErrorConnected ? (
            <>
              Nous n'avons pas trouvé la Direction Territoriale à laquelle votre compte est rattaché
            </>
          ) : (
            <>
              {dataResultsConnected && dataResultsConnected.contenu && (
                <div className="fr-grid-row">
                  {dataResultsConnected.contenu.map(el => {
                    return <ResultLocation key={el.adresseDT} el={el} />;
                  })}
                </div>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default LocationAuthenticated;
