import { cmsSlice } from './cmsSlice';

const cmsAccount = cmsSlice.injectEndpoints({
  endpoints: build => ({
    getAccountCms: build.query<any, { locale: string; qs?: string }>({
      query: ({ locale }) => ({ url: `/compte?locale=${locale}&populate=*` }),
    }),
  }),
});

export const { useGetAccountCmsQuery } = cmsAccount;
