import { FC } from 'react';
import { Card } from '@codegouvfr/react-dsfr/Card';

import PartContainer from 'components/Templates/PartContainer/PartContainer';
import ThreeSlotElement from 'components/Templates/ThreeSlotElement/ThreeSlotElement';

interface ThreeCardContainerProps {
  className: string;
  title: string;
  description?: string;
  link: {
    href: string;
    title: string;
  };
  cards: {
    key: string;
    title: string;
    description?: string;
    image: {
      src?: string;
      alt?: string;
    };
    link: {
      href: string;
      title?: string;
    };
  }[];
}

const ThreeCardContainer: FC<ThreeCardContainerProps> = ({
  className,
  title,
  link,
  description,
  cards,
}) => {
  const mustTargetBlankLink = className === 'services';
  return (
    <PartContainer
      title={title}
      link={link}
      description={description}
      className={`${className} card`}
    >
      {cards.map(card => {
        return (
          <ThreeSlotElement key={card.key} keyName={card.key} className="card">
            <Card
              classes={{
                imgTag: 'fr-responsive-img',
              }}
              title={card.title}
              desc={card.description}
              imageAlt={card.image.alt ? card.image.alt : ''}
              enlargeLink
              imageUrl={card.image.src ? card.image.src : ''}
              linkProps={{
                href: card.link?.href,
                target: mustTargetBlankLink ? '_blank' : '_self',
              }}
            />
          </ThreeSlotElement>
        );
      })}
    </PartContainer>
  );
};

export default ThreeCardContainer;
