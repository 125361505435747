/* eslint-disable max-lines */
/* eslint-disable max-params */
import {
  DemandeRfBloc1,
  DemandeRfErrors,
  DemandeRfGeneral,
  DemandeRfUtilitaire,
} from 'models/types/cms/demandeRF/type';
import {
  setActeNaissanceTrad,
  setMarieEnFrance,
  setSexe,
  setTitreDeSejourRenouvellement,
  setSituationMatrimonialTrad,
} from 'redux/demandeRf';
import { cmsEnrichedText } from 'components/Atoms/HtmlParsers/PageContentHtmlParser';
import { GENRE_API } from 'constants/genre';

import { CustomAlertProps } from 'components/Atoms/CustomAlert/CustomAlert';
import { RadioButtonContainerProps } from 'components/Molecules/Forms/RadioButtonContainer/RadioButtonContainer';
import { CustomCheckboxContainerProps } from 'components/Molecules/Forms/CustomCheckboxContainer/CustomCheckboxContainer';
import { UploadFilesParameters } from 'models/types/app/uploadFiles';

interface FileUploadMapping {
  upload: {
    id?: string;
    label: string;
    hint: string;
    state?: 'success' | 'error' | 'default';
    lang?: string;
    required?: boolean;
    stateRelatedMessage?: string;
    demandeId: string;
    infobulleContent?: any;
    formInfos: {
      key: string;
    };
    formControl: {
      control: any;
      setValue: any;
      getValues: any;
      trigger: any;
      errors: any;
      watch: any;
    };
    demandeRFUtilitairecms?: any;
    formToSubmit?: string;
    categorie: string;
    name: string;
    resource: string;
    resourceId?: string;
    isDispatch?: boolean;
    helpbox?: CustomAlertProps;
    className?: string;
  };
}

interface AlertsMapping {
  alert_top: CustomAlertProps;
  alert_maried: CustomAlertProps;
  alert_permit_expired: CustomAlertProps & {
    buttonProps: { children: string; onClick?: () => void; type?: any };
  };
  alert_is_minor: CustomAlertProps;
  alert_nationality: CustomAlertProps;
}

interface CheckboxMapping {
  french_birth_certificate: CustomCheckboxContainerProps;
  french_death_certificate: CustomCheckboxContainerProps;
  french_divorce_papers: CustomCheckboxContainerProps;
  french_mariage_certificate: CustomCheckboxContainerProps;
}

export class WhoAreYouMapping {
  static getGeneralMapping() {
    function alerts_fields(bloc1Cms: DemandeRfBloc1, globalCms?: DemandeRfGeneral): AlertsMapping {
      return {
        alert_top: {
          title: bloc1Cms.bulle_alerte_certains_titres_invalides.titre,
          description: bloc1Cms.bulle_alerte_certains_titres_invalides.contenu,
          linkText: bloc1Cms.bulle_alerte_certains_titres_invalides.bouton_ou_lien,
          link: bloc1Cms.bulle_alerte_certains_titres_invalides.lien,
          severity: 'warning',
          closable: false,
        },
        alert_maried: {
          title: bloc1Cms.bulle_alerte_mariage_civils_seuls.titre,
          description: bloc1Cms.bulle_alerte_mariage_civils_seuls.contenu,
          linkText: bloc1Cms.bulle_alerte_mariage_civils_seuls.bouton_ou_lien,
          link: bloc1Cms.bulle_alerte_mariage_civils_seuls.lien,
          severity: 'warning',
          closable: false,
        },
        alert_permit_expired: {
          title: bloc1Cms.bulle_aide_titre_sejour_est_expire.titre,
          buttonProps: {
            children: bloc1Cms.bulle_aide_titre_sejour_est_expire.bouton_ou_lien ?? '',
            type: 'button',
            onClick: () => {
              bloc1Cms.bulle_aide_titre_sejour_est_expire.lien &&
                window.open(bloc1Cms.bulle_aide_titre_sejour_est_expire.lien, '_blank');
            },
          },
          iconId: 'ri-information-line',
        },
        alert_is_minor: {
          title: bloc1Cms.usager_mineur_warning_box.titre,
          description: bloc1Cms.usager_mineur_warning_box.contenu,
          linkText: bloc1Cms.usager_mineur_warning_box.bouton_ou_lien,
          link: bloc1Cms.usager_mineur_warning_box.lien,
          severity: 'warning',
          closable: false,
        },
        alert_nationality: {
          title: bloc1Cms.bulle_aide_nationalite_non_eligible.titre,
          description: bloc1Cms.bulle_aide_nationalite_non_eligible.contenu,
          linkText: globalCms?.en_savoir_plus,
          link: globalCms?.en_savoir_plus,
          severity: 'warning',
          closable: false,
        },
      };
    }

    function form_fields(bloc1Cms: DemandeRfBloc1, control: any, lang?: string) {
      return {
        nom_naissance: {
          name: 'nom_naissance',
          control: control,
          label: bloc1Cms.nom_naissance.titre,
          hintText: bloc1Cms.nom_naissance.sous_titre,
          required: true,
        },
        nom_usage: {
          name: 'nom_usage',
          control: control,
          label: bloc1Cms.nom_usage.titre,
          hintText: bloc1Cms.nom_usage.sous_titre,
          required: false,
        },
        prenom: {
          name: 'prenom',
          control: control,
          label: bloc1Cms.prenom.titre,
          hintText: bloc1Cms.prenom.sous_titre,
          required: true,
        },
        date_naissance: {
          id: 'date_naissance',
          name: 'date_naissance',
          label: bloc1Cms.titre_aide_date_naissance,
          hintText: bloc1Cms.date_naissance.sous_titre,
          required: true,
          control: control,
        },
        pays_naissance: {
          name: 'pays_naissance',
          control: control,
          label: bloc1Cms.pays_naissance.titre,
          hintText: bloc1Cms.pays_naissance.sous_titre,
          required: true,
          lang: lang,
        },
        ville_naissance: {
          name: 'ville_naissance',
          control: control,
          label: bloc1Cms.ville_naissance.titre,
          hintText: bloc1Cms.ville_naissance.sous_titre,
          required: true,
        },
        nationalite: {
          name: 'nationalite',
          control: control,
          label: bloc1Cms.nationalite.titre,
          hintText: bloc1Cms.nationalite.sous_titre,
          required: true,
          lang: lang,
        },
        situation_familiale: {
          name: 'situation_matrimoniale',
          control: control,
          label: bloc1Cms.situation_familiale,
          required: true,
        },
        date_expiration_tj: {
          id: 'date_expiration_titre_sejour',
          name: 'date_expiration_titre_sejour',
          label: bloc1Cms.titre_date_expiration_titre_sejour.titre,
          hintText: bloc1Cms.titre_date_expiration_titre_sejour.sous_titre,
          required: true,
          control: control,
        },
        autre_nature_titre_sejour: {
          id: 'autre_nature_titre_sejour',
          name: 'autre_nature_titre_sejour',
          label: bloc1Cms.preciser_nature_titre_sejour,
          control: control,
        },
        autre_mention_titre_sejour: {
          id: 'autre_mention_titre_sejour',
          name: 'autre_mention_titre_sejour',
          label: bloc1Cms.preciser_mention_titre_sejour,
          control: control,
        },
        date_start_of_mariage: {
          id: 'date_start_of_mariage',
          name: 'date_start_of_mariage',
          label: bloc1Cms.mariage_depuis.titre,
          required: true,
          control: control,
        },
        date_start_of_concubinage: {
          id: 'date_start_of_concubinage',
          name: 'date_start_of_concubinage',
          label: bloc1Cms.concubinage_depuis.titre,
          required: true,
          control: control,
        },
        date_start_of_pacs: {
          id: 'date_start_of_pacs',
          name: 'date_start_of_pacs',
          label: bloc1Cms.pacse_depuis.titre,
          required: true,
          control: control,
        },
        date_start_of_divorce: {
          id: 'date_start_of_divorce',
          name: 'date_start_of_divorce',
          label: bloc1Cms.divorce_depuis.titre,
          required: true,
          control: control,
        },
      };
    }

    function checkbox_mapping(
      bloc1Cms: DemandeRfBloc1,
      globalCms: DemandeRfGeneral,
      formControls: {
        setValue: any;
        control: any;
      },
      dispatch: React.Dispatch<any>,
    ): CheckboxMapping {
      const { setValue, control } = formControls;
      return {
        french_birth_certificate: {
          control: control,
          key: 'french_birth_certificate',
          name: 'french_birth_certificate',
          options: [
            {
              label: bloc1Cms.acte_naissance_francais,
              hintText: globalCms.sous_titre_case_document_langue_origine,
              id: 'french_birth_certificate',
              nativeInputProps: {
                name: 'french_birth_certificate',
                checked: control._formValues.french_birth_certificate,
                value: control._formValues.french_birth_certificate,
                onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                  setValue('french_birth_certificate', e.target.checked);
                  dispatch(setActeNaissanceTrad(e.target.checked));
                },
              },
            },
          ],
          large: true,
        },
        french_death_certificate: {
          control: control,
          key: 'french_death_certificate',
          name: 'french_death_certificate',
          options: [
            {
              label: bloc1Cms.acte_deces_francais,
              hintText: globalCms.sous_titre_case_document_langue_origine,
              id: 'french_death_certificate',
              nativeInputProps: {
                name: 'french_death_certificate',
                checked: control._formValues.french_death_certificate,
                value: control._formValues.french_death_certificate,
                onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                  setValue('french_death_certificate', e.target.checked);
                  dispatch(setSituationMatrimonialTrad(e.target.checked));
                },
              },
            },
          ],
          large: true,
        },
        french_divorce_papers: {
          control: control,
          key: 'french_divorce_papers',
          name: 'french_divorce_papers',
          options: [
            {
              label: bloc1Cms.jugement_divorce_francais,
              hintText: globalCms.sous_titre_case_document_langue_origine,
              id: 'french_divorce_papers',
              nativeInputProps: {
                name: 'french_divorce_papers',
                checked: control._formValues.french_divorce_papers,
                value: control._formValues.french_divorce_papers,
                onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                  setValue('french_divorce_papers', e.target.checked);
                  dispatch(setSituationMatrimonialTrad(e.target.checked));
                },
              },
            },
          ],
          large: true,
        },
        french_mariage_certificate: {
          control: control,
          key: 'french_mariage_certificate',
          name: 'french_mariage_certificate',
          options: [
            {
              label: bloc1Cms.acte_mariage_francais,
              hintText: globalCms.sous_titre_case_document_langue_origine,
              id: 'french_mariage_certificate',
              nativeInputProps: {
                name: 'french_mariage_certificate',
                checked: control._formValues.french_mariage_certificate,
                value: control._formValues.french_mariage_certificate,
                onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                  setValue('french_mariage_certificate', e.target.checked);
                  dispatch(setSituationMatrimonialTrad(e.target.checked));
                },
              },
            },
          ],
          large: true,
        },
      };
    }

    function radio_ongoing_residence_permit_demand(
      bloc1Cms: DemandeRfBloc1,
      globalCms: DemandeRfGeneral,
      formControls: {
        setValue: any;
        control: any;
      },
      dispatch: any,
    ): RadioButtonContainerProps {
      const { setValue, control } = formControls;
      return {
        name: 'ongoing_residence_permit_demand',
        label: bloc1Cms.demande_renouvellement_titre_sejour_faite,
        orientation: 'horizontal',
        control: control,
        options: [
          {
            label: globalCms.oui,
            nativeInputProps: {
              value: 'oui',
              checked: control._formValues.ongoing_residence_permit_demand === 'oui',
              onChange: () => {
                setValue('ongoing_residence_permit_demand', 'oui');
                dispatch(setTitreDeSejourRenouvellement(true));
              },
            },
          },
          {
            label: globalCms.non,
            nativeInputProps: {
              value: 'non',
              checked: control._formValues.ongoing_residence_permit_demand === 'non',
              onChange: () => {
                setValue('ongoing_residence_permit_demand', 'non');
                dispatch(setTitreDeSejourRenouvellement(false));
              },
            },
          },
        ],
      };
    }

    function radio_place_of_mariage(
      bloc1Cms: DemandeRfBloc1,
      globalCms: DemandeRfGeneral,
      formControls: {
        setValue: any;
        control: any;
      },
      dispatch: any,
    ): RadioButtonContainerProps {
      const { setValue, control } = formControls;
      return {
        label: bloc1Cms.vous_vous_etes_marie,
        name: 'place_of_mariage',
        orientation: 'horizontal',
        control: control,
        options: [
          {
            label: globalCms.en_france,
            value: 'en_france',
            nativeInputProps: {
              value: 'en_france',
              checked: control._formValues.place_of_mariage === 'en_france',
              onChange: () => {
                setValue('place_of_mariage', 'en_france');
                dispatch(setMarieEnFrance(true));
              },
            },
          },
          {
            label: globalCms.a_letranger,
            value: 'etranger',
            nativeInputProps: {
              value: 'etranger',
              checked: control._formValues.place_of_mariage === 'etranger',
              onChange: () => {
                setValue('place_of_mariage', 'etranger');
                dispatch(setMarieEnFrance(false));
              },
            },
          },
        ],
      };
    }

    function radio_gender(
      bloc1Cms: DemandeRfBloc1,
      globalCms: DemandeRfGeneral,
      formControls: {
        setValue: any;
        control: any;
      },
      dispatch: any,
    ): RadioButtonContainerProps {
      const { setValue, control } = formControls;
      return {
        name: 'gender',
        label: bloc1Cms.sexe,
        orientation: 'horizontal',
        control: control,
        options: [
          {
            label: globalCms.femme,
            nativeInputProps: {
              value: GENRE_API.FEMME,
              checked: control._formValues.gender === GENRE_API.FEMME,
              onChange: () => {
                setValue('gender', GENRE_API.FEMME);
                dispatch(setSexe(GENRE_API.FEMME));
              },
            },
          },
          {
            label: globalCms.homme,
            nativeInputProps: {
              value: GENRE_API.HOMME,
              checked: control._formValues.gender === GENRE_API.HOMME,
              onChange: () => {
                setValue('gender', GENRE_API.HOMME);
                dispatch(setSexe(GENRE_API.HOMME));
              },
            },
          },
        ],
      };
    }

    return {
      alerts_fields,
      form_fields,
      radio_ongoing_residence_permit_demand,
      radio_place_of_mariage,
      radio_gender,
      checkbox_mapping,
    };
  }

  static getFileMapping() {
    function titre_sejour_files(
      bloc1Cms: DemandeRfBloc1,
      uploadFilesParameters: UploadFilesParameters,
      errorCms: DemandeRfErrors,
      lang: string,
    ): FileUploadMapping {
      return {
        upload: {
          id: 'infos-fichier-titre-sejour',
          name: 'titre_sejour_files',
          categorie: 'sejo_titre_sejour',
          label: bloc1Cms.fichier_titre_sejour.titre_fichier ?? '',
          hint: bloc1Cms.fichier_titre_sejour.infos_fichier ?? '',
          state: 'default',
          stateRelatedMessage: errorCms.erreur_generique_fichier,
          required: true,
          //isDispatch: false,
          ...uploadFilesParameters,
          resource: 'demande',
          resourceId: undefined,
          infobulleContent: bloc1Cms.infobulle_fichier_titre_sejour_rv,
          helpbox: {
            closable: false,
            description: bloc1Cms.fichier_titre_sejour.bulle_aide_fichier_contenu ?? '',
            severity: 'info',
            title: bloc1Cms.fichier_titre_sejour.bulle_aide_fichier_titre ?? '',
          },
          lang: lang,
          className: 'fr-col-12',
        },
      };
    }

    function acte_naissance_files(
      bloc1Cms: DemandeRfBloc1,
      uploadFilesParameters: UploadFilesParameters,
      errorCms: DemandeRfErrors,
      lang: string,
    ): FileUploadMapping {
      return {
        upload: {
          id: 'infos-acte-naissance',
          categorie: 'etci_acte_naissance',
          label: bloc1Cms.fichier_acte_naissance_langue_origine.titre_fichier ?? '',
          hint: bloc1Cms.fichier_acte_naissance_langue_origine.infos_fichier ?? '',
          state: 'default',
          stateRelatedMessage: errorCms.erreur_generique_fichier,
          required: true,
          infobulleContent: bloc1Cms.infobulle_fichier_acte_naissance,
          //isDispatch: false,
          ...uploadFilesParameters,
          name: 'acte_naissance_files',
          resource: 'demande',
          resourceId: undefined,
          helpbox: {
            title: bloc1Cms.fichier_acte_naissance_langue_origine.bulle_aide_fichier_titre,
            description: cmsEnrichedText(
              bloc1Cms.fichier_acte_naissance_langue_origine.bulle_aide_fichier_contenu,
            ),
            link: bloc1Cms.fichier_acte_naissance_langue_origine.bulle_aide_fichier_lien,
            linkText: bloc1Cms.fichier_acte_naissance_langue_origine.bulle_aide_fichier_texte_lien,
            closable: false,
            severity: 'info',
          },
          lang: lang,
          className: 'fr-col-12',
        },
      };
    }

    function acte_naissance_traduits_files(
      bloc1Cms: DemandeRfBloc1,
      uploadFilesParameters: UploadFilesParameters,
      errorCms: DemandeRfErrors,
      lang: string,
    ): FileUploadMapping {
      return {
        upload: {
          id: 'infos-acte-naissance-traduits',
          label: bloc1Cms.fichier_acte_naissance_fr.titre_fichier ?? '',
          hint: bloc1Cms.fichier_acte_naissance_fr.infos_fichier ?? '',
          state: 'default',
          stateRelatedMessage: errorCms.erreur_generique_fichier,
          required: true,
          //isDispatch: false,
          ...uploadFilesParameters,
          name: 'acte_naissance_traduits_files',
          categorie: 'etci_acte_naissance_trad',
          resource: 'demande',
          resourceId: undefined,
          helpbox: {
            closable: false,
            description: bloc1Cms.fichier_acte_naissance_fr.bulle_aide_fichier_contenu,
            severity: 'info',
            title: bloc1Cms.fichier_acte_naissance_fr.bulle_aide_fichier_titre,
          },
          lang: lang,
          className: 'fr-col-12',
        },
      };
    }

    function convention_pacs_files(
      bloc1Cms: DemandeRfBloc1,
      uploadFilesParameters: UploadFilesParameters,
      errorCms: DemandeRfErrors,
      lang: string,
    ): FileUploadMapping {
      return {
        upload: {
          id: 'infos-convention-pacs',
          label: bloc1Cms.fichier_convention_pacs.titre_fichier ?? '',
          hint: bloc1Cms.fichier_convention_pacs.infos_fichier ?? '',
          state: 'default',
          stateRelatedMessage: errorCms.erreur_generique_fichier,
          required: true,
          infobulleContent: bloc1Cms.infobulle_fichier_pacs,
          //isDispatch: false,
          ...uploadFilesParameters,
          name: 'convention_pacs_files',
          categorie: 'etci_pacs',
          resource: 'demande',
          resourceId: undefined,
          helpbox: {
            closable: false,
            description: bloc1Cms.fichier_convention_pacs.bulle_aide_fichier_contenu,
            severity: 'info',
            title: bloc1Cms.fichier_convention_pacs.bulle_aide_fichier_titre,
            linkText: bloc1Cms.fichier_convention_pacs.bulle_aide_fichier_texte_lien,
            link: bloc1Cms.fichier_convention_pacs.bulle_aide_fichier_lien,
          },
          lang: lang,
          className: 'fr-col-12',
        },
      };
    }

    function divorce_ruling_files(
      bloc1Cms: DemandeRfBloc1,
      uploadFilesParameters: UploadFilesParameters,
      errorCms: DemandeRfErrors,
      lang: string,
    ): FileUploadMapping {
      return {
        upload: {
          id: 'divorce_ruling_files',
          label: bloc1Cms.fichier_jugement_divorce.titre_fichier ?? '',
          hint: bloc1Cms.fichier_jugement_divorce.infos_fichier ?? '',
          state: 'default',
          stateRelatedMessage: errorCms.erreur_generique_fichier,
          required: true,
          infobulleContent: bloc1Cms.infobulle_fichier_jugement_divorce_langue_origine,
          //isDispatch: false,
          ...uploadFilesParameters,
          name: 'divorce_ruling_files',
          categorie: 'etci_jugement_divorce',
          resource: 'demande',
          resourceId: undefined,
          helpbox: {
            closable: false,
            description: bloc1Cms.fichier_jugement_divorce.bulle_aide_fichier_contenu,
            severity: 'info',
            title: bloc1Cms.fichier_jugement_divorce.bulle_aide_fichier_titre,
            linkText: bloc1Cms.fichier_jugement_divorce.bulle_aide_fichier_texte_lien,
            link: bloc1Cms.fichier_jugement_divorce.bulle_aide_fichier_lien,
          },
          lang: lang,
          className: 'fr-col-12',
        },
      };
    }

    function divorce_ruling_traduit_files(
      bloc1Cms: DemandeRfBloc1,
      uploadFilesParameters: UploadFilesParameters,
      errorCms: DemandeRfErrors,
      lang: string,
    ): FileUploadMapping {
      return {
        upload: {
          id: 'divorce_ruling_traduit_files',
          label: bloc1Cms.fichier_jugement_divorce_traduit.titre_fichier ?? '',
          hint: bloc1Cms.fichier_jugement_divorce_traduit.infos_fichier ?? '',
          state: 'default',
          stateRelatedMessage: errorCms.erreur_generique_fichier,
          required: true,
          //isDispatch: false,
          ...uploadFilesParameters,
          name: 'divorce_ruling_traduit_files',
          categorie: 'etci_jugement_divorce_trad',
          resource: 'demande',
          resourceId: undefined,
          helpbox: {
            closable: false,
            description: bloc1Cms.fichier_jugement_divorce_traduit.bulle_aide_fichier_contenu,
            severity: 'info',
            title: bloc1Cms.fichier_jugement_divorce_traduit.bulle_aide_fichier_titre,
            linkText: bloc1Cms.fichier_jugement_divorce_traduit.bulle_aide_fichier_texte_lien,
            link: bloc1Cms.fichier_jugement_divorce_traduit.bulle_aide_fichier_lien,
          },
          lang: lang,
          className: 'fr-col-12',
        },
      };
    }

    function acte_mariage_files(
      bloc1Cms: DemandeRfBloc1,
      uploadFilesParameters: UploadFilesParameters,
      errorCms: DemandeRfErrors,
      lang: string,
    ): FileUploadMapping {
      return {
        upload: {
          id: 'acte_mariage_files',
          label: bloc1Cms.fichier_acte_mariage.titre_fichier ?? '',
          hint: bloc1Cms.fichier_acte_mariage.infos_fichier ?? '',
          state: 'default',
          stateRelatedMessage: errorCms.erreur_generique_fichier,
          required: true,
          infobulleContent: bloc1Cms.infobulle_fichier_acte_mariage,
          //isDispatch: false,
          ...uploadFilesParameters,
          name: 'acte_mariage_files',
          categorie: 'etci_acte_mariage',
          resource: 'demande',
          resourceId: undefined,
          helpbox: {
            closable: false,
            description: bloc1Cms.fichier_acte_mariage.bulle_aide_fichier_contenu,
            severity: 'info',
            title: bloc1Cms.fichier_acte_mariage.bulle_aide_fichier_titre,
            linkText: bloc1Cms.fichier_acte_mariage.bulle_aide_fichier_texte_lien,
            link: bloc1Cms.fichier_acte_mariage.bulle_aide_fichier_lien,
          },
          lang: lang,
          className: 'fr-col-12',
        },
      };
    }

    function acte_mariage_traduits_files(
      bloc1Cms: DemandeRfBloc1,
      uploadFilesParameters: UploadFilesParameters,
      errorCms: DemandeRfErrors,
      lang: string,
    ): FileUploadMapping {
      return {
        upload: {
          id: 'acte_mariage_traduits_files',
          label: bloc1Cms.fichier_acte_mariage_traduit.titre_fichier ?? '',
          hint: bloc1Cms.fichier_acte_mariage_traduit.infos_fichier ?? '',
          state: 'default',
          stateRelatedMessage: errorCms.erreur_generique_fichier,
          required: true,
          //isDispatch: false,
          ...uploadFilesParameters,
          name: 'acte_mariage_traduits_files',
          categorie: 'etci_acte_mariage_trad',
          resource: 'demande',
          resourceId: undefined,
          helpbox: {
            closable: false,
            description: bloc1Cms.fichier_acte_mariage_traduit.bulle_aide_fichier_contenu,
            severity: 'info',
            title: bloc1Cms.fichier_acte_mariage_traduit.bulle_aide_fichier_titre,
            linkText: bloc1Cms.fichier_acte_mariage_traduit.bulle_aide_fichier_texte_lien,
            link: bloc1Cms.fichier_acte_mariage_traduit.bulle_aide_fichier_lien,
          },
          lang: lang,
          className: 'fr-col-12',
        },
      };
    }

    function acte_deces_conjoint_files(
      bloc1Cms: DemandeRfBloc1,
      uploadFilesParameters: UploadFilesParameters,
      errorCms: DemandeRfErrors,
      lang: string,
    ): FileUploadMapping {
      return {
        upload: {
          id: 'acte_deces_conjoint_files',
          label: bloc1Cms.fichier_acte_deces_conjoint.titre_fichier ?? '',
          hint: bloc1Cms.fichier_acte_deces_conjoint.infos_fichier ?? '',
          state: 'default',
          stateRelatedMessage: errorCms.erreur_generique_fichier,
          required: true,
          infobulleContent: bloc1Cms.infobulle_fichier_acte_deces_conjoint,
          //isDispatch: false,
          ...uploadFilesParameters,
          name: 'acte_deces_conjoint_files',
          categorie: 'etci_acte_deces_precedent_conjoint',
          resource: 'demande',
          resourceId: undefined,
          helpbox: {
            closable: false,
            description: bloc1Cms.fichier_acte_deces_conjoint.bulle_aide_fichier_contenu,
            severity: 'info',
            title: bloc1Cms.fichier_acte_deces_conjoint.bulle_aide_fichier_titre,
            linkText: bloc1Cms.fichier_acte_deces_conjoint.bulle_aide_fichier_texte_lien,
            link: bloc1Cms.fichier_acte_deces_conjoint.bulle_aide_fichier_lien,
          },
          lang: lang,
          className: 'fr-col-12',
        },
      };
    }

    function acte_deces_conjoint_traduits_files(
      bloc1Cms: DemandeRfBloc1,
      uploadFilesParameters: UploadFilesParameters,
      errorCms: DemandeRfErrors,
      lang: string,
    ): FileUploadMapping {
      return {
        upload: {
          id: 'acte_deces_conjoint_traduits_files',
          label: bloc1Cms.fichier_acte_deces_conjoint_traduit.titre_fichier ?? '',
          hint: bloc1Cms.fichier_acte_deces_conjoint_traduit.infos_fichier ?? '',
          state: 'default',
          stateRelatedMessage: errorCms.erreur_generique_fichier,
          required: true,
          //isDispatch: false,
          ...uploadFilesParameters,
          name: 'acte_deces_conjoint_traduits_files',
          categorie: 'etci_acte_deces_precedent_conjoint_trad',
          resource: 'demande',
          resourceId: undefined,
          helpbox: {
            closable: false,
            description: bloc1Cms.fichier_acte_deces_conjoint_traduit.bulle_aide_fichier_contenu,
            severity: 'info',
            title: bloc1Cms.fichier_acte_deces_conjoint_traduit.bulle_aide_fichier_titre,
            linkText: bloc1Cms.fichier_acte_deces_conjoint_traduit.bulle_aide_fichier_texte_lien,
            link: bloc1Cms.fichier_acte_deces_conjoint_traduit.bulle_aide_fichier_lien,
          },
          lang: lang,
          className: 'fr-col-12',
        },
      };
    }

    function recepisse_files(
      bloc1Cms: DemandeRfBloc1,
      uploadFilesParameters: UploadFilesParameters,
      errorCms: DemandeRfErrors,
      lang: string,
    ): FileUploadMapping {
      return {
        upload: {
          id: 'recepisse_files',
          label: bloc1Cms.fichier_recepisse_renouvellement.titre_fichier ?? '',
          hint: bloc1Cms.fichier_recepisse_renouvellement.infos_fichier ?? '',
          state: 'default',
          stateRelatedMessage: errorCms.erreur_generique_fichier,
          required: true,
          isDispatch: false,
          ...uploadFilesParameters,
          name: 'recepisse_files',
          categorie: 'sejo_titre_sejour_renouvellement_recepisse',
          resource: 'demande',
          resourceId: undefined,
          helpbox: {
            closable: false,
            description: bloc1Cms.fichier_recepisse_renouvellement.bulle_aide_fichier_contenu,
            severity: 'info',
            title: bloc1Cms.fichier_recepisse_renouvellement.bulle_aide_fichier_titre,
            linkText: bloc1Cms.fichier_recepisse_renouvellement.bulle_aide_fichier_texte_lien,
            link: bloc1Cms.fichier_recepisse_renouvellement.bulle_aide_fichier_lien,
          },
          lang: lang,
          className: 'fr-col-12',
        },
      };
    }

    return {
      titre_sejour_files,
      acte_naissance_files,
      acte_naissance_traduits_files,
      acte_mariage_files,
      acte_mariage_traduits_files,
      convention_pacs_files,
      acte_deces_conjoint_files,
      acte_deces_conjoint_traduits_files,
      divorce_ruling_files,
      divorce_ruling_traduit_files,
      recepisse_files,
    };
  }

  static getDropDownMapping() {
    function nature_titre_sejour(
      demande: any | undefined,
      bloc1Cms: DemandeRfBloc1,
      globalCms: DemandeRfGeneral,
      utilitaireCms: DemandeRfUtilitaire,
      control: any,
    ) {
      return {
        id: 'nature_titre_sejour',
        name: 'nature_titre_sejour',
        options: [
          {
            label: utilitaireCms.carte_de_sejour_temporaire,
            value: 'carte_de_sejour_temporaire',
          },
          {
            label: utilitaireCms.carte_de_sejour_pluriannuelle,
            value: 'carte_de_sejour_pluriannuelle',
          },
          {
            label: utilitaireCms.carte_de_resident,
            value: 'carte_de_resident',
          },
          {
            label: utilitaireCms.carte_de_resident_algerien,
            value: 'carte_de_resident_algerien',
          },
          {
            label: utilitaireCms.autre,
            value: 'autre',
          },
        ],
        ariaLabel: '',
        label: globalCms.nature_titre_sejour,
        hint: bloc1Cms.regarder_sur_titre_sejour,
        defaultValue: demande?.usager?.titreDeSejour
          ? demande.usager.titreDeSejour
          : bloc1Cms.option_defaut_menu_deroulant_nature_titre_sejour,
        control: control,
        nativeSelectProps: {},
      };
    }

    function mention_titre_sejour(
      demande: any | undefined,
      bloc1Cms: DemandeRfBloc1,
      globalCms: DemandeRfGeneral,
      utilitaireCms: DemandeRfUtilitaire,
      control: any,
    ) {
      return {
        id: 'mention_titre_sejour',
        name: 'mention_titre_sejour',
        options: [
          {
            label: utilitaireCms.vie_privee_et_familiale,
            value: 'vie_privee_et_familiale',
          },
          {
            label: utilitaireCms.salarie,
            value: 'salarie',
          },
          {
            label: utilitaireCms.commercant,
            value: 'commercant',
          },
          {
            label: utilitaireCms.etudiant,
            value: 'etudiant',
          },
          {
            label: utilitaireCms.visiteur,
            value: 'visiteur',
          },
          {
            label: utilitaireCms.autre,
            value: 'autre',
          },
        ],
        ariaLabel: '',
        label: globalCms.mention_titre_sejour,
        hint: bloc1Cms.regarder_sur_titre_sejour,
        defaultValue: demande?.usager?.titreDeSejourMention
          ? demande.usager.titreDeSejourMention
          : bloc1Cms.option_defaut_menu_deroulant_mention_titre_sejour,
        control: control,
        nativeSelectProps: {},
      };
    }

    function situation_matrimoniale(
      demande: any | undefined,
      bloc1Cms: DemandeRfBloc1,
      utilitaireCms: DemandeRfUtilitaire,
      control: any,
    ) {
      return {
        id: 'situation_matrimoniale',
        name: 'situation_matrimoniale',
        ariaLabel: '',
        label: bloc1Cms.situation_familiale,
        options: [
          {
            label: utilitaireCms.celibataire,
            value: 'celibataire',
          },
          {
            label: utilitaireCms.marie,
            value: 'marie',
          },
          {
            label: utilitaireCms.pacse,
            value: 'pacse',
          },
          {
            label: utilitaireCms.concubin,
            value: 'concubin',
          },
          {
            label: utilitaireCms.divorce,
            value: 'divorce',
          },
          {
            label: utilitaireCms.separe,
            value: 'separe',
          },
          {
            label: utilitaireCms.veuf,
            value: 'veuf',
          },
        ],
        defaultValue: demande?.usager?.situationMatrimonial
          ? demande.usager.situationMatrimonial
          : bloc1Cms.choisir_votre_situation,
        control: control,
        nativeSelectProps: {},
      };
    }

    return {
      nature_titre_sejour,
      mention_titre_sejour,
      situation_matrimoniale,
    };
  }
}
