import React from 'react';
import Input from '@codegouvfr/react-dsfr/Input';
import { cleanYearInADate } from 'helpers/app/ComponentsFormHelpers';

interface DateInputProps {
  label: string | React.ReactNode;
  hintText?: string;
  name?: string;
  control: any;
  state: string;
  field: any;
  stateRelatedMessage?: string;
  required?: boolean;
  customOnChange?: (value: any) => void;
}

const DateInput: React.FC<DateInputProps> = ({
  label,
  hintText,
  name,
  stateRelatedMessage,
  state,
  control,
  field,
  customOnChange,
}) => {
  return hintText ? (
    <Input
      label={label}
      hintText={hintText}
      state={state}
      name={name}
      control={control}
      stateRelatedMessage={stateRelatedMessage}
      nativeInputProps={{
        type: 'date',
        value: cleanYearInADate(field.value),
        onChange: e => {
          field.onChange(cleanYearInADate(e.target.value));
          if (customOnChange) {
            customOnChange(cleanYearInADate(e.target.value));
          }
        },
      }}
      {...field}
    />
  ) : (
    <Input
      label={label}
      control={control}
      nativeInputProps={{
        type: 'date',
        value: cleanYearInADate(field.value),
        onChange: e => {
          field.onChange(cleanYearInADate(e.target.value));
          if (customOnChange) {
            customOnChange(cleanYearInADate(e.target.value));
          }
        },
      }}
      name={name}
      state={state}
      stateRelatedMessage={stateRelatedMessage}
      {...field}
    />
  );
};

export default DateInput;
