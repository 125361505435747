import { CHOIX } from 'constants/global';
import { RFNG_CAT_MAPPING } from 'constants/upload';
import { RESIDENCE_LIEU } from 'constants/regroupementFamiliale';
import { Conjoint } from 'models/types/api/rf';
import { ConjointDemandeForm } from 'models/types/app/demandeRf';
import { getDocumentsByCategory } from 'pages/DemandeRF/Helpers/common';

export const conjointApiToAppFormatter = (data: Conjoint, documents: any) => {
  const conjoint: ConjointDemandeForm = {
    rf_demande: data.rfDemande !== null ? (data.rfDemande ? CHOIX.OUI : CHOIX.NON) : undefined,
    conjoint_etranger:
      data.vitEnFrance !== null
        ? data.vitEnFrance
          ? RESIDENCE_LIEU.FRANCE
          : RESIDENCE_LIEU.ETRANGER
        : '',
    sexe_conjoint: data.etatCivil?.sexe ? data.etatCivil?.sexe : undefined,
    nom_naissance_conjoint: data.etatCivil?.nom ? data.etatCivil?.nom : undefined,
    nom_usage_conjoint: data.etatCivil?.nomUsage ? data.etatCivil?.nomUsage : undefined,
    prenoms_conjoint: data.etatCivil?.prenoms ? data.etatCivil?.prenoms : undefined,
    pays_naissance_conjoint: data.etatCivil?.paysNaissance
      ? data.etatCivil?.paysNaissance
      : undefined,
    ville_naissance_conjoint: data.etatCivil?.villeNaissance
      ? data.etatCivil?.villeNaissance
      : undefined,
    titre_sejour_fr_conjoint:
      data.titreSejourFr !== null ? (data.titreSejourFr ? CHOIX.OUI : CHOIX.NON) : undefined,
    mariage_precedent_conjoint:
      data.dejaMarie !== null ? (data.dejaMarie ? CHOIX.OUI : CHOIX.NON) : undefined,
    conjoint_jugement_divorce_traduit: data.divorceTrad !== null ? data.divorceTrad : undefined,
    nationalite_conjoint: data.etatCivil?.nationalite ? data.etatCivil?.nationalite : undefined,
    acte_naissance_conjoint_traduit:
      data.etatCivil?.acteNaissanceTrad !== null ? data.etatCivil?.acteNaissanceTrad : undefined,
    date_naissance_conjoint:
      data.etatCivil?.dateNaissance !== null ? data.etatCivil?.dateNaissance : undefined,
    acte_naissance_conjoint_files: getDocumentsByCategory(
      RFNG_CAT_MAPPING.ACTE_NAISSANCE_CONJOINT,
      documents,
    ),
    acte_naissance_conjoint_traduit_files: getDocumentsByCategory(
      RFNG_CAT_MAPPING.ACTE_NAISSANCE_CONJOINT_TRAD,
      documents,
    ),
    conjoint_titre_identite_files: getDocumentsByCategory(
      RFNG_CAT_MAPPING.TITRE_IDENTITE_CONJOINT,
      documents,
    ),
    conjoint_titre_sejour_files: getDocumentsByCategory(
      RFNG_CAT_MAPPING.TITRE_SEJOUR_CONJOINT,
      documents,
    ),
    conjoint_jugement_divorce_files: getDocumentsByCategory(
      RFNG_CAT_MAPPING.JUGEMENT_DIVORCE_CONJOINT,
      documents,
    ),
    conjoint_jugement_divorce_traduit_files: getDocumentsByCategory(
      RFNG_CAT_MAPPING.JUGEMENT_DIVORCE_CONJOINT_TRAD,
      documents,
    ),
  };

  return conjoint;
};
