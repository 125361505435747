/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable complexity */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable max-lines */
import React, { useEffect, useState } from 'react';
import { useUpdDemandeMutation } from 'redux/rtk/api/apiRf';
import { useFieldArray } from 'react-hook-form';
import { DevTool } from '@hookform/devtools';
import { useDispatch, useSelector } from 'react-redux';

import CustomCheckboxContainer from 'components/Molecules/Forms/CustomCheckboxContainer/CustomCheckboxContainer';
import CallOutDocumentsInfos from 'components/Molecules/DemandeRf/CallOutDocumentsInfos/CallOutDocumentsInfos';
import SelectInputContainer from 'components/Molecules/Forms/SelectInputContainer/SelectInputContainer';
import RadioButtonContainer from 'components/Molecules/Forms/RadioButtonContainer/RadioButtonContainer';
import DateInputContainer from 'components/Molecules/Forms/DateInputContainer/DateInputContainer';
import TextInputContainer from 'components/Molecules/Forms/TextInputContainer/TextInputContainer';
import CommonRowContainer from 'components/Molecules/Forms/CommonRowContainer/CommonRowContainer';
import { handleArrayHydratation, handleFieldHydratation } from 'helpers/api/FormHydratation';
import { getRfCmsContents } from 'pages/DemandeRF/Helpers/Cms/getRfCmsContents';
import {
  replaceIdentityDocuments,
  setIdentityIsValid,
  setIdentityUntouched,
  setSituationMatrimonial,
  setTitreDeSejourFin,
} from 'redux/demandeRf';
import { useGetNationalitesNonEligiblesQuery } from 'redux/rtk/api/apiCommon';
import FormLayout from 'components/Molecules/DemandeRf/FormLayout/FormLayout';
import { isDateInThePast } from 'helpers/app/ComponentsFormHelpers';
import CustomAlert from 'components/Atoms/CustomAlert/CustomAlert';
import UploadFiles from 'components/Atoms/UploadFiles/UploadFiles';
import { IdentiteDemandeForm } from 'models/types/app/demandeRf';
import { getAgeisMinor } from 'pages/DemandeRF/Helpers/common';
import {
  getIdentiteFormToSubmit,
  getIdentiteDocuments,
  getIdentiteStore,
} from 'redux/demandeRf/selectors';
import {
  getDocumentsByCategory,
  manageDateValue,
  mapBoolFormToServer,
  mapFileIsInFrenchToForm,
  mapMarieEnFranceFormToServer,
  mapMarieEnFranceServerToFrom,
  mapSituationMatrimonialDateFormToServer,
  mapSituationMatrimonialOriginalFormToServer,
} from 'helpers/api/formSubmission';
import { createError, createSuccess } from 'redux/forms/slice';
import Loader from 'components/Atoms/Loader/Loader';
import IdentityPanel from './Panels/IdentityPanel';
import { submitForm } from 'redux/forms';
import { useFormTouched } from 'pages/DemandeRF/DemandeRF.utils';
import CallOut from '@codegouvfr/react-dsfr/CallOut';
import { cmsEnrichedText } from 'components/Atoms/HtmlParsers/PageContentHtmlParser';

import { getWhoAreYouValidationSchema } from './WhoAreYou.validationSchema';
import MatrimonialSituation from './Panels/MatrimonialSituation';
import { GetUploadFilesParameters } from './WhoAreYou.helper';
import { WhoAreYouMapping } from './WhoAreYou.mapping';
import GetWhoAreYouUseForm from './WhoAreYou.UseForm';
import { createModal } from '@codegouvfr/react-dsfr/Modal';

type WhoAreYouProps = {
  lang: string;
  isEligible: boolean;
  setIsEligible: React.Dispatch<React.SetStateAction<boolean>>;
  demandeId: string;
  saveFormProgress: boolean;
  setSaveFormProgress: React.Dispatch<React.SetStateAction<boolean>>;
  scrollToTablist: () => void;
};

const infobulleDocumentsModal = createModal({
  id: 'document-modal-who-are-you',
  isOpenedByDefault: false,
});

const WhoAreYou: React.FC<WhoAreYouProps> = ({
  lang,
  isEligible,
  setIsEligible,
  demandeId,
  saveFormProgress,
  setSaveFormProgress,
  scrollToTablist,
}) => {
  const [isMinor, setIsMinor] = useState<boolean>(false);
  const [demandeForm, setDemandeForm] = useState<IdentiteDemandeForm | null>(null);

  const dispatch = useDispatch();

  const [updateDemande, { data, isSuccess: isSuccessUpdated }] = useUpdDemandeMutation();

  const demandeIdentite = useSelector(getIdentiteStore);
  const identityFormToSubmit = useSelector(getIdentiteFormToSubmit);
  const documents = useSelector(getIdentiteDocuments);
  const { bloc1Cms, globalCms, utilitaireCms, errorCms } = getRfCmsContents(lang);

  const {
    data: dataNationalitesNonEligibles,
    error: nationalitesNonEligiblesError,
    isLoading: isNationalitesNonEligiblesLoading,
  } = useGetNationalitesNonEligiblesQuery();

  const forbiddenNationalites = () => {
    return dataNationalitesNonEligibles?.map((nationalite: any) => nationalite.value) || [];
  };

  const {
    getValues,
    setValue,
    trigger,
    watch,
    formState: { errors, isValid, touchedFields },
    control,
    handleSubmit,
  } = GetWhoAreYouUseForm(bloc1Cms, globalCms, errorCms, forbiddenNationalites());

  const formIsTouched = useFormTouched(touchedFields);

  const fieldArrayMapping = {
    acte_naissance_files: useFieldArray({
      control,
      name: 'acte_naissance_files',
    }),
    acte_naissance_traduits_files: useFieldArray({
      control,
      name: 'acte_naissance_traduits_files',
    }),
    titre_sejour_files: useFieldArray({
      control,
      name: 'titre_sejour_files',
    }),
    recepisse_files: useFieldArray({
      control,
      name: 'recepisse_files',
    }),
    acte_mariage_files: useFieldArray({
      control,
      name: 'acte_mariage_files',
    }),
    acte_mariage_traduits_files: useFieldArray({
      control,
      name: 'acte_mariage_traduits_files',
    }),
    acte_deces_conjoint_files: useFieldArray({
      control,
      name: 'acte_deces_conjoint_files',
    }),
    acte_deces_traduits_files: useFieldArray({
      control,
      name: 'acte_deces_traduits_files',
    }),
    convention_pacs_files: useFieldArray({
      control,
      name: 'convention_pacs_files',
    }),
    divorce_ruling_files: useFieldArray({
      control,
      name: 'divorce_ruling_files',
    }),
    divorce_ruling_traduit_files: useFieldArray({
      control,
      name: 'divorce_ruling_traduit_files',
    }),
  };

  const uploadFilesParameters = GetUploadFilesParameters(
    demandeId,
    control,
    setValue,
    getValues,
    trigger,
    errors,
    watch,
    utilitaireCms,
  );

  useEffect(() => {
    if (identityFormToSubmit) {
      onSubmitNoValidationAfterUpload();
      dispatch(submitForm('identite'));
    }
  }, [identityFormToSubmit]);

  const onSubmitNoValidationAfterUpload = () => {
    const values = control._formValues;
    delete values.validation;
    onSubmit(values);
  };

  useEffect(() => {
    if (isSuccessUpdated && data) {
      dispatch(replaceIdentityDocuments({ id: demandeId, documents: data.documents }));
    }
  }, [isSuccessUpdated, data]);
  const setValuesFromDemand = (formDemand: any) => {
    for (const key in formDemand) {
      if (Array.isArray(formDemand[key])) {
        if (formDemand[key].length > 0) {
          handleArrayHydratation(key, formDemand, fieldArrayMapping);
        }
      } else if (formDemand[key] !== null && formDemand[key] !== undefined) {
        handleFieldHydratation(
          key,
          formDemand,
          getWhoAreYouValidationSchema(bloc1Cms, globalCms, errorCms, forbiddenNationalites()),
          setValue,
        );
      }
    }
  };

  const onSubmit = async (values: any) => {
    const demandeForm = {
      id: null,
      statut: null,
      demandeLogements: null,
      usager: {
        id: null,
        numEtranger: null,
        etatCivil: {
          nom: values.nom_naissance,
          prenoms: values.prenom,
          sexe: values.gender,
          nomUsage: values.nom_usage !== undefined ? values.nom_usage : null,
          dateNaissance: manageDateValue(values.date_naissance),
          paysNaissance: values.pays_naissance,
          villeNaissance: values.ville_naissance,
          nationalite: values.nationalite,
          acteNaissanceTrad: values.french_birth_certificate,
        },
        titreDeSejour: values.nature_titre_sejour,
        titreDeSejourAutre:
          values.nature_titre_sejour === 'autre' ? values.autre_nature_titre_sejour : null,
        titreDeSejourMention: values.mention_titre_sejour,
        titreDeSejourMentionAutre:
          values.mention_titre_sejour === 'autre' ? values.autre_mention_titre_sejour : null,
        titreDeSejourFin: manageDateValue(values.date_expiration_titre_sejour),
        situationMatrimonialTrad: mapSituationMatrimonialOriginalFormToServer(
          values.situation_matrimoniale,
          values.french_mariage_certificate,
          values.french_divorce_papers,
          values.french_death_certificate,
        ),
        titreDeSejourRenouvellement: mapBoolFormToServer(values.ongoing_residence_permit_demand),
        situationMatrimonial:
          values.situation_matrimoniale !== undefined ? values.situation_matrimoniale : null,
        situationMatrimonialDate: mapSituationMatrimonialDateFormToServer(
          values.situation_matrimoniale,
          {
            dateMarie: values.date_start_of_mariage,
            datePacse: values.date_start_of_pacs,
            dateDivorce: values.date_start_of_divorce,
            dateConcubinage: values.date_start_of_concubinage,
          },
        ),
        marieEnFrance: mapMarieEnFranceFormToServer(values.place_of_mariage),
      },
    };

    let isError = false;

    try {
      const parameters = {
        body: demandeForm,
        demandeId: demandeId,
        isValidation: values.validation, //values.validation === 'true',
        etape: 'votre-identite',
      };

      await updateDemande(parameters);
      setSaveFormProgress(false);
    } catch (e: any) {
      isError = true;
      if (e.response.status === 400) {
        dispatch(createError(e.response.body.title));
      }
    }

    if (!isError) {
      if (values.validation === true) {
        dispatch(createSuccess(utilitaireCms.toast_etape_valide));
      } else {
        dispatch(createSuccess(utilitaireCms.toast_demande_enregistre));
      }
    }
  };

  const watchRadioRenewPermitValue = watch('ongoing_residence_permit_demand');
  const watchResidencePermitDate = watch('date_expiration_titre_sejour');
  const watchFrenchBirthCertificate = watch('french_birth_certificate');
  const watchResidencePermitMention = watch('mention_titre_sejour');
  const watchResidencePermitNature = watch('nature_titre_sejour');
  const watchMatrimonialStatus = watch('situation_matrimoniale');
  const watchDateNaissance = watch('date_naissance');
  const watchNationalite = watch('nationalite');

  useEffect(() => {
    dispatch(setIdentityUntouched(!formIsTouched));
  }, [formIsTouched]);

  useEffect(() => {
    dispatch(setIdentityIsValid(isValid));
  }, [isValid]);

  useEffect(() => {
    if (watchDateNaissance) {
      getAgeisMinor(watchDateNaissance) ? setIsMinor(true) : setIsMinor(false);
    }
  }, [watchDateNaissance]);
  useEffect(() => {
    if (watchDateNaissance) {
      getAgeisMinor(watchDateNaissance) ? setIsMinor(true) : setIsMinor(false);
    }
  }, [watchDateNaissance]);

  useEffect(() => {
    if (watchNationalite) {
      if (forbiddenNationalites()?.includes(watchNationalite)) {
        setIsEligible(false);
      } else {
        setIsEligible(true);
      }
    }
  }, [watchNationalite, setIsEligible]);

  useEffect(() => {
    if (watchRadioRenewPermitValue === 'non') {
      setIsEligible(false);
    } else {
      setIsEligible(true);
    }
  }, [watchRadioRenewPermitValue, setIsEligible]);

  useEffect(() => {
    if (demandeIdentite && documents) {
      setDemandeForm({
        nom_naissance: demandeIdentite.etatCivil.nom,
        nom_usage: demandeIdentite.etatCivil?.nomUsage,
        prenom: demandeIdentite.etatCivil?.prenoms,
        gender: demandeIdentite.etatCivil?.sexe,
        pays_naissance: demandeIdentite.etatCivil?.paysNaissance,
        ville_naissance: demandeIdentite.etatCivil?.villeNaissance,
        date_naissance: demandeIdentite.etatCivil?.dateNaissance,
        acte_naissance_files: getDocumentsByCategory('etci_acte_naissance', documents),
        french_birth_certificate: mapFileIsInFrenchToForm(
          demandeIdentite.etatCivil?.acteNaissanceTrad
            ? demandeIdentite.etatCivil?.acteNaissanceTrad
            : null,
        ),
        acte_naissance_traduits_files: getDocumentsByCategory(
          'etci_acte_naissance_trad',
          documents,
        ),
        nationalite: demandeIdentite.etatCivil?.nationalite,
        nature_titre_sejour: demandeIdentite.titreDeSejour,
        autre_nature_titre_sejour: demandeIdentite.titreDeSejourAutre,
        mention_titre_sejour: demandeIdentite.titreDeSejourMention,
        autre_mention_titre_sejour: demandeIdentite.titreDeSejourMentionAutre,
        titre_sejour_files: getDocumentsByCategory('sejo_titre_sejour', documents),
        recepisse_files: getDocumentsByCategory(
          'sejo_titre_sejour_renouvellement_recepisse',
          documents,
        ),
        date_expiration_titre_sejour: demandeIdentite.titreDeSejourFin,
        ongoing_residence_permit_demand: demandeIdentite.titreDeSejourRenouvellement
          ? 'oui'
          : undefined,
        situation_matrimoniale: demandeIdentite.situationMatrimonial
          ? demandeIdentite.situationMatrimonial
          : undefined,
        date_start_of_mariage:
          demandeIdentite.situationMatrimonial === 'marie'
            ? demandeIdentite.situationMatrimonialDate
            : null,
        date_start_of_pacs:
          demandeIdentite.situationMatrimonial === 'pacse'
            ? demandeIdentite.situationMatrimonialDate
            : null,
        date_start_of_concubinage:
          demandeIdentite.situationMatrimonial === 'concubin'
            ? demandeIdentite.situationMatrimonialDate
            : null,
        date_start_of_divorce:
          demandeIdentite.situationMatrimonial === 'divorce'
            ? demandeIdentite.situationMatrimonialDate
            : null,
        place_of_mariage: mapMarieEnFranceServerToFrom(demandeIdentite.marieEnFrance),
        acte_mariage_files: getDocumentsByCategory('etci_acte_mariage', documents),
        acte_mariage_traduits_files: getDocumentsByCategory('etci_acte_mariage_trad', documents),
        french_mariage_certificate:
          demandeIdentite.situationMatrimonial === 'marie'
            ? mapFileIsInFrenchToForm(
                demandeIdentite.situationMatrimonialTrad
                  ? demandeIdentite.situationMatrimonialTrad
                  : null,
              )
            : null,
        acte_deces_conjoint_files: getDocumentsByCategory(
          'etci_acte_deces_precedent_conjoint',
          documents,
        ),
        acte_deces_traduits_files: getDocumentsByCategory(
          'etci_acte_deces_precedent_conjoint_trad',
          documents,
        ),
        french_death_certificate:
          demandeIdentite.situationMatrimonial === 'veuf'
            ? mapFileIsInFrenchToForm(
                demandeIdentite.situationMatrimonialTrad
                  ? demandeIdentite.situationMatrimonialTrad
                  : null,
              )
            : null,
        convention_pacs_files: getDocumentsByCategory('etci_pacs', documents),
        divorce_ruling_files: getDocumentsByCategory('etci_jugement_divorce', documents),
        divorce_ruling_traduit_files: getDocumentsByCategory(
          'etci_jugement_divorce_trad',
          documents,
        ),
        french_divorce_papers:
          demandeIdentite.situationMatrimonial === 'divorce'
            ? mapFileIsInFrenchToForm(
                demandeIdentite.situationMatrimonialTrad
                  ? demandeIdentite.situationMatrimonialTrad
                  : null,
              )
            : null,
      });
    }
  }, [demandeIdentite, documents, data, watchMatrimonialStatus]);

  useEffect(() => {
    if (demandeForm) {
      setValuesFromDemand(demandeForm);
    }
  }, [demandeForm]);

  useEffect(() => {
    if (saveFormProgress) {
      onSubmit({ ...control._formValues, validation: false });
    }
  }, [saveFormProgress]);

  const expiredResidencePermit =
    watchResidencePermitDate && isDateInThePast(watchResidencePermitDate);

  const displayActeNaissanceFile =
    watchNationalite && !forbiddenNationalites()?.includes(watchNationalite) && !isMinor;

  const displayErrorNationalite =
    watchNationalite && forbiddenNationalites()?.includes(watchNationalite);

  if (!demandeIdentite || !documents || isNationalitesNonEligiblesLoading) {
    return <Loader />;
  }

  if (nationalitesNonEligiblesError) {
    return <div>Redirect page error</div>;
  }

  return (
    <>
      <DevTool control={control} />
      <FormLayout
        formIdentifier="who-are-you-form"
        title={bloc1Cms.completer_infos_perso}
        requiredInfos={true}
        onSubmit={onSubmit}
        lang={lang}
        control={control}
        handleSubmit={handleSubmit}
        trigger={trigger}
        isValid={isValid}
        scrollToTablist={scrollToTablist}
        errors={errors}
      >
        <div className="fr-col-12 fr-mt-md-2v fr-mt-lg-2v">
          <CustomAlert
            {...WhoAreYouMapping.getGeneralMapping().alerts_fields(bloc1Cms).alert_top}
          />
        </div>
        <CommonRowContainer
          legend={bloc1Cms.quel_titre_sejour}
          infobulleContent={bloc1Cms.infobulle_titre_sejour}
          lang={lang}
          children={[
            <SelectInputContainer
              {...WhoAreYouMapping.getDropDownMapping().nature_titre_sejour(
                'demande',
                bloc1Cms,
                globalCms,
                utilitaireCms,
                control,
              )}
            />,
            <SelectInputContainer
              {...WhoAreYouMapping.getDropDownMapping().mention_titre_sejour(
                'demande',
                bloc1Cms,
                globalCms,
                utilitaireCms,
                control,
              )}
            />,
          ]}
        />
        {(watchResidencePermitNature === 'autre' || watchResidencePermitMention === 'autre') && (
          <CommonRowContainer
            children={[
              watchResidencePermitNature === 'autre' ? (
                <TextInputContainer
                  {...WhoAreYouMapping.getGeneralMapping().form_fields(bloc1Cms, control)
                    .autre_nature_titre_sejour}
                />
              ) : (
                <div className="fr-col-12 fr-mt-0v fr-col-lg-4 fr-col-md-4"></div>
              ),
              watchResidencePermitMention === 'autre' ? (
                <TextInputContainer
                  {...WhoAreYouMapping.getGeneralMapping().form_fields(bloc1Cms, control)
                    .autre_mention_titre_sejour}
                />
              ) : (
                <></>
              ),
            ]}
          />
        )}

        <CommonRowContainer
          legend={bloc1Cms.titre_sejour_validite.titre}
          infobulleContent={bloc1Cms.infobulle_validite_titre_sejour}
          lang={lang}
          children={[
            <DateInputContainer
              {...WhoAreYouMapping.getGeneralMapping().form_fields(bloc1Cms, control)
                .date_expiration_tj}
              customOnChange={e => {
                dispatch(setTitreDeSejourFin(e));
              }}
            />,
          ]}
        />

        {expiredResidencePermit && (
          <CommonRowContainer
            children={[
              <RadioButtonContainer
                {...WhoAreYouMapping.getGeneralMapping().radio_ongoing_residence_permit_demand(
                  bloc1Cms,
                  globalCms,
                  {
                    setValue,
                    control,
                  },
                  dispatch,
                )}
              />,
            ]}
          />
        )}

        {watchRadioRenewPermitValue !== 'non' && (
          <>
            <CommonRowContainer
              className="fr-grid-row fr-col-12 fr-mt-6v fr-mt-lg-8v fr-mt-md-8v"
              children={[
                <CallOutDocumentsInfos
                  lang={lang}
                  infobulleDocumentsModal={infobulleDocumentsModal}
                />,
              ]}
            />
            {expiredResidencePermit ? (
              <>
                <CommonRowContainer
                  className="fr-grid-row fr-col-12 fr-mt-6v fr-mt-lg-8v fr-mt-md-8v"
                  children={[
                    <UploadFiles
                      {...WhoAreYouMapping.getFileMapping().titre_sejour_files(
                        bloc1Cms,
                        uploadFilesParameters,
                        errorCms,
                        lang,
                      ).upload}
                      fieldArray={fieldArrayMapping.titre_sejour_files}
                      // fieldHasError={getFieldState('titre_sejour_files').error}
                    />,
                  ]}
                />

                {watchRadioRenewPermitValue === 'oui' && (
                  <CommonRowContainer
                    className="fr-grid-row fr-col-12 fr-mt-6v fr-mt-lg-8v fr-mt-md-8v"
                    children={[
                      <UploadFiles
                        {...WhoAreYouMapping.getFileMapping().recepisse_files(
                          bloc1Cms,
                          uploadFilesParameters,
                          errorCms,
                          lang,
                        ).upload}
                        fieldArray={fieldArrayMapping.recepisse_files}
                        //fieldHasError={getFieldState('recepisse_files').error}
                      />,
                    ]}
                  />
                )}
              </>
            ) : (
              <CommonRowContainer
                className="fr-grid-row fr-col-12 fr-mt-6v fr-mt-lg-8v fr-mt-md-8v"
                children={[
                  <UploadFiles
                    {...WhoAreYouMapping.getFileMapping().titre_sejour_files(
                      bloc1Cms,
                      uploadFilesParameters,
                      errorCms,
                      lang,
                    ).upload}
                    fieldArray={fieldArrayMapping.titre_sejour_files}
                    //fieldHasError={getFieldState('titre_sejour_files').error}
                  />,
                ]}
              />
            )}
          </>
        )}

        {watchRadioRenewPermitValue !== 'non' ? (
          <>
            <div className="container fr-mt-6v fr-mt-lg-8v fr-mt-md-8v">
              <hr />
            </div>

            <IdentityPanel
              lang={lang}
              formControls={{
                setValue,
                control,
              }}
              isMinor={isMinor}
              dispatch={dispatch}
            />
            {displayErrorNationalite && (
              <div className="fr-col-12 fr-mt-6v fr-mt-md-8v fr-mt-lg-8v">
                <CustomAlert
                  {...WhoAreYouMapping.getGeneralMapping().alerts_fields(bloc1Cms)
                    .alert_nationality}
                />
              </div>
            )}
            {displayActeNaissanceFile && (
              <>
                <CommonRowContainer
                  className="fr-grid-row fr-col-12 fr-mt-6v fr-mt-lg-8v fr-mt-md-8v"
                  children={[
                    <UploadFiles
                      {...WhoAreYouMapping.getFileMapping().acte_naissance_files(
                        bloc1Cms,
                        uploadFilesParameters,
                        errorCms,
                        lang,
                      ).upload}
                      fieldArray={fieldArrayMapping.acte_naissance_files}
                      //fieldHasError={getFieldState('titre_sejour_files').error}
                    />,
                  ]}
                />
                <CustomCheckboxContainer
                  {...WhoAreYouMapping.getGeneralMapping().checkbox_mapping(
                    bloc1Cms,
                    globalCms,
                    {
                      control,
                      setValue,
                    },
                    dispatch,
                  ).french_birth_certificate}
                />
                {watchFrenchBirthCertificate && (
                  <>
                    <CommonRowContainer
                      className="fr-grid-row fr-col-12 fr-mt-6v fr-mt-lg-8v fr-mt-md-8v"
                      children={[
                        <UploadFiles
                          {...WhoAreYouMapping.getFileMapping().acte_naissance_traduits_files(
                            bloc1Cms,
                            uploadFilesParameters,
                            errorCms,
                            lang,
                          ).upload}
                          fieldArray={fieldArrayMapping.acte_naissance_traduits_files}
                          //fieldHasError={getFieldState('acte_naissance_traduits_files').error}
                        />,
                      ]}
                    />
                  </>
                )}
              </>
            )}
            {!isMinor && isEligible && !displayErrorNationalite && (
              <>
                <div className="container fr-mt-6v fr-mt-lg-8v fr-mt-md-8v">
                  <hr />
                </div>
                <CommonRowContainer
                  legend={bloc1Cms.quelle_situation_familiale}
                  infobulleContent={bloc1Cms.infobulle_situation_familiale}
                  lang={lang}
                  children={[
                    <SelectInputContainer
                      {...WhoAreYouMapping.getDropDownMapping().situation_matrimoniale(
                        'demande',
                        bloc1Cms,
                        utilitaireCms,
                        control,
                      )}
                      customOnChange={e => {
                        dispatch(setSituationMatrimonial(e));
                      }}
                    />,
                  ]}
                />

                <MatrimonialSituation
                  WatcherMatrimonialSituation={watchMatrimonialStatus}
                  lang={lang}
                  fieldArrayMapping={fieldArrayMapping}
                  uploadFilesParameters={uploadFilesParameters}
                  formControls={{
                    setValue,
                    control,
                  }}
                  dispatch={dispatch}
                  watch={watch}
                />
              </>
            )}
          </>
        ) : expiredResidencePermit && watchRadioRenewPermitValue === 'non' && !isMinor ? (
          <>
            <div className="fr-col-12 fr-mt-4v fr-mt-lg-6v fr-mt-md-6v">
              <CallOut
                className="callout-alert"
                {...WhoAreYouMapping.getGeneralMapping().alerts_fields(bloc1Cms)
                  .alert_permit_expired}
              >
                {cmsEnrichedText(bloc1Cms.bulle_aide_titre_sejour_est_expire.contenu)}
              </CallOut>
            </div>
          </>
        ) : null}
      </FormLayout>
    </>
  );
};

export default WhoAreYou;
