import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Card from '@codegouvfr/react-dsfr/Card';

import TwoCardContainer from 'components/Molecules/TwoCardContainer/TwoCardContainer';
import FourCardContainer from 'components/Molecules/FourCardContainer/FourCardContainer';
import { setArticles, setProcedurePage, setProcedures } from 'redux/pages';
import {
  getArticles,
  getLayoutBlocs,
  getProcedures,
  getProceduresPage,
} from 'redux/pages/selectors';
import { ARTICLES } from 'routes/path';
import Loader from 'components/Atoms/Loader/Loader';
import { getCurrentLocale } from 'redux/locale/selectors';
import { proceduresQuery } from 'pages/Home/Home.query';
import { useGetProceduresCmsCollectionQuery } from 'redux/rtk/cms/cmsCommon';
import { procedureQuery } from './Procedures.query';
import { useGetProcedureCmsPageQuery } from 'redux/rtk/cms/cmsProcedure';
import { generateSection } from 'helpers/app/pagesHelper';
import CollectionPageLayout from 'layouts/CollectionsPageLayout';
import { getNewsContent } from 'helpers/cms/getNewsContent';

import './Procedures.scss';

// eslint-disable-next-line complexity
const Procedures = () => {
  const lang = useSelector(getCurrentLocale) as string;
  const { articlesCms, articlesCmsError, isArticlesCmsLoading } = getNewsContent(lang);

  const {
    data: proceduresCms,
    error: proceduresCmsError,
    isLoading: isProceduresCmsLoading,
  } = useGetProceduresCmsCollectionQuery({
    qs: proceduresQuery,
    locale: lang !== '' ? lang : 'fr',
  });

  const {
    data: procedurePageCms,
    error: procedurePageCmsError,
    isLoading: isProcedurePageCmsLoading,
  } = useGetProcedureCmsPageQuery({
    qs: procedureQuery,
    locale: lang !== '' ? lang : 'fr',
  });

  const proceduresStore = useSelector(getProcedures);
  const articlesStore = useSelector(getArticles);
  const procedurePageStore = useSelector(getProceduresPage);
  const layoutBlocs = useSelector(getLayoutBlocs);
  const dispatch = useDispatch();

  useEffect(() => {
    if (proceduresCms) {
      dispatch(setProcedures(proceduresCms));
    }
  }, [proceduresCms, dispatch]);

  useEffect(() => {
    if (procedurePageCms) {
      dispatch(setProcedurePage(procedurePageCms));
    }
  }, [procedurePageCms, dispatch]);

  useEffect(() => {
    if (articlesCms.data && articlesCms.data.length > 1 && (articlesStore?.length ?? 0) <= 1) {
      dispatch(setArticles(articlesCms.data));
    }
  }, [articlesCms, dispatch, articlesStore]);

  if (isProceduresCmsLoading || isArticlesCmsLoading || isProcedurePageCmsLoading) {
    return <Loader />;
  }

  if (proceduresCmsError || articlesCmsError || procedurePageCmsError) {
    return <div>Redirect page error</div>;
  }

  if (!proceduresCms || !articlesCms) {
    return null;
  }

  return (
    <main>
      <CollectionPageLayout title={procedurePageStore.titre} className="procedures">
        {proceduresStore?.map(el => {
          return (
            <div
              key={el.id}
              className={`fr-col-xs-4 fr-col-sm-12 fr-col-md-4 fr-col-lg-4 procedures`}
            >
              <Card
                title={el.title}
                imageAlt={el.image.alt ? el.image.alt : ''}
                enlargeLink
                imageUrl={el.image.src ? el.image.src : ''}
                linkProps={{
                  href: el.link?.href,
                }}
              />
            </div>
          );
        })}
      </CollectionPageLayout>

      {procedurePageStore.integration && (
        <TwoCardContainer
          title={procedurePageStore.integration.titre}
          horizontal={true}
          className="integration"
          cards={[
            {
              key: 'parcours',
              title: procedurePageStore.integration.bloc_1.titre,
              description: procedurePageStore.integration.bloc_1.description,
              image: {
                src: '/img/Visuel_Parcours.png',
                alt: '',
              },
              link: {
                href: procedurePageStore.integration.bloc_1.lien,
              },
            },
            {
              key: 'apprendre',
              title: procedurePageStore.integration.bloc_2.titre,
              description: procedurePageStore.integration.bloc_2.description,
              image: {
                src: '/img/Visuel_ApprendreFrançais.png',
                alt: '',
              },
              link: {
                href: procedurePageStore.integration.bloc_2.lien,
              },
            },
          ]}
        />
      )}
      {layoutBlocs.articles && articlesStore && (
        <FourCardContainer
          className="news"
          title={layoutBlocs.articles.titre}
          link={{
            href: lang ? `${ARTICLES}` : ARTICLES,
            title: layoutBlocs.articles.bouton_voir_plus,
          }}
          cards={generateSection(articlesStore.slice(0, 4))}
        />
      )}
    </main>
  );
};

export default Procedures;
