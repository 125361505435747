import React, { FC } from 'react';

import { RFDocumentIncomplet } from 'models/types/api/rf';

type Props = {
  documentList: RFDocumentIncomplet[];
  headingText: string;
  description: string;
};
const DocumentsIncomplets: FC<Props> = ({ documentList, headingText, description }) => {
  return (
    <>
      <br />
      <br />
      <span className="bold">{headingText}</span>
      <p>{description}</p>
      <ul>
        {documentList.map(doc => (
          <>
            <li key={doc.nomDocument}>{doc.nomDocument}</li>
          </>
        ))}
      </ul>
    </>
  );
};

export default DocumentsIncomplets;
