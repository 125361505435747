import { STATUT_OPC } from 'constants/demandeAsile';
import { DemandeAsile } from 'models/types/api/demandeAsile';
import { ProcedureDemandeType } from 'models/types/api/procedures';

export type AsileState = Readonly<{
  demandeAsile: { value: DemandeAsile | null; hasBeenFetched: boolean };
}>;

export const initialState: AsileState = {
  demandeAsile: {
    value: {
      type: ProcedureDemandeType.ASILE,
      path: '',
      statutOPC: STATUT_OPC.ACCEPTEE,
      dateSignatureOPC: null,
      estEnCours: false,
      dateCloture: null,
    },
    hasBeenFetched: false,
  },
};
