import React from 'react';

import CommonRowContainer from 'components/Molecules/Forms/CommonRowContainer/CommonRowContainer';
import RadioButtonContainer from 'components/Molecules/Forms/RadioButtonContainer/RadioButtonContainer';
import TextInputContainer from 'components/Molecules/Forms/TextInputContainer/TextInputContainer';
import { getRfCmsContents } from 'pages/DemandeRF/Helpers/Cms/getRfCmsContents';

type ConjointInfoNomsProps = {
  lang: string;
  formFieldsMapping: any;
};
const ConjointInfoNoms: React.FC<ConjointInfoNomsProps> = ({ lang, formFieldsMapping }) => {
  const { bloc2Cms } = getRfCmsContents(lang);
  return (
    <>
      <CommonRowContainer
        legend={bloc2Cms.votre_conjoint_est}
        children={[<RadioButtonContainer {...formFieldsMapping.sexe_conjoint} large />]}
      />
      <CommonRowContainer
        legend={bloc2Cms.conjoint_sappel}
        lang={lang}
        children={[
          <TextInputContainer
            customClass="fr-col-12 fr-mt-4v fr-mt-lg-0v fr-mt-md-0v fr-col-sm-10"
            {...formFieldsMapping.nom_naissance_conjoint}
          />,
          <TextInputContainer
            customClass="fr-col-12 fr-mt-4v fr-mt-lg-0v fr-mt-md-0v fr-col-sm-10"
            {...formFieldsMapping.nom_usage_conjoint}
          />,
        ]}
      />
      <CommonRowContainer
        lang={lang}
        children={[
          <TextInputContainer
            customClass="fr-col-12 fr-mt-4v fr-mt-lg-0v fr-mt-md-0v fr-col-sm-10"
            {...formFieldsMapping.prenoms_conjoint}
          />,
        ]}
      />
    </>
  );
};

export default ConjointInfoNoms;
