import { User } from 'oidc-client-ts';

import getEnv from 'helpers/app/envHelper';

export enum KeycloakStatus {
  UNKNOWN = 'unknown',
  UP = 'up',
  DOWN = 'down',
}

export const keycloakConfig = {
  authority: `${getEnv('REACT_APP_KEYCLOAK_URL')}realms/${getEnv('REACT_APP_KEYCLOAK_REALM')}`,
  redirect_uri: window.location.origin,
  client_id: getEnv('REACT_APP_KEYCLOAK_CLIENT_ID') || '',
  onSigninCallback: (): void => {
    window.history.replaceState({}, document.title, window.location.pathname);
  },
};

export const getUser = (): User | null => {
  const authority = `${getEnv('REACT_APP_KEYCLOAK_URL')}realms/${getEnv(
    'REACT_APP_KEYCLOAK_REALM',
  )}`;
  const client_id = getEnv('REACT_APP_KEYCLOAK_CLIENT_ID') || '';
  const oidcStorage = sessionStorage.getItem(`oidc.user:${authority}:${client_id}`);
  if (!oidcStorage) {
    return null;
  }

  return User.fromStorageString(oidcStorage);
};

export const getPasswordModificationLink = (locale: string) => {
  const passwordModificationUrl = new URL(
    `${getEnv('REACT_APP_KEYCLOAK_URL')}realms/${getEnv('REACT_APP_REALM_NAME')}/protocol`,
  );
  passwordModificationUrl.pathname += '/openid-connect/auth';
  passwordModificationUrl.searchParams.append(
    'client_id',
    `${getEnv('REACT_APP_KEYCLOAK_CLIENT_ID')}`,
  );
  passwordModificationUrl.searchParams.append(
    'redirect_uri',
    window.location.origin + window.location.pathname,
  );
  passwordModificationUrl.searchParams.append('response_type', 'code');
  passwordModificationUrl.searchParams.append('scope', 'openid');
  passwordModificationUrl.searchParams.append('kc_action', 'UPDATE_PASSWORD');
  passwordModificationUrl.searchParams.append('temporary', 'false');
  passwordModificationUrl.searchParams.append('kc_locale', locale);
  return passwordModificationUrl.href;
};
