import { Conjoint, Enfant, Union } from 'models/types/api/rf';

export const createNewConjoint = (id: string): Conjoint => {
  return {
    id: id,
    documents: [],
    adresse: null,
    estValide: false,
    etatCivil: {
      dateNaissance: null,
      nationalite: null,
      nom: null,
      nomUsage: null,
      paysNaissance: null,
      prenoms: null,
      sexe: '',
      villeNaissance: null,
      acteNaissanceTrad: false,
    },
    idRfng: null,
    lienParente: '',
    rfDemande: false,
    vitEnFrance: false,
    dejaMarie: false,
    divorceOriginal: null,
    titreSejourFr: false,
    divorceTrad: null,
  };
};

export const createNewChild = (id: string): Enfant => {
  return {
    id: id,
    documents: [],
    adresse: null,
    estValide: false,
    etatCivil: {
      dateNaissance: null,
      nationalite: null,
      nom: null,
      nomUsage: null,
      paysNaissance: null,
      prenoms: null,
      sexe: '',
      villeNaissance: null,
      acteNaissanceTrad: false,
    },
    idRfng: null,
    lienParente: '',
    rfDemande: false,
    vitEnFrance: false,
    abandonOriginal: null,
    adoptionOriginal: null,
    decesConjointOriginal: null,
    droitGardeOriginal: null,
    filiation: null,
    kafalaOriginal: null,
    lienSecondParent: null,
  };
};

export const createNewUnion = (id: string): Union => {
  return {
    id: id,
    documents: [],
    estValide: false,
    dateDebut: '',
    dateFin: '',
    divorceOriginal: false,
    type: '',
  };
};
