import React, { ReactNode } from 'react';
import { fr } from '@codegouvfr/react-dsfr';
import Button from '@codegouvfr/react-dsfr/Button';

import { getRfCmsContents } from 'pages/DemandeRF/Helpers/Cms/getRfCmsContents';

import '../DemandeRf.scss';
import './FormModalLayout.style.scss';

interface FormModalLayoutProps {
  formIdentifier: string;
  lang: string;
  isValid: boolean;
  children: ReactNode;
  requiredInfos?: boolean;
  onSubmit?: any;
  control?: any;
  handleSubmit?: any;
}

const FormModalLayout: React.FC<FormModalLayoutProps> = ({
  formIdentifier,
  children,
  requiredInfos,
  onSubmit,
  control,
  lang,
  isValid,
  handleSubmit,
}) => {
  const { utilitaireCms, globalCms } = getRfCmsContents(lang);
  const onSubmitNoValidation = () => {
    onSubmit({ ...control._formValues, validation: false });
  };

  const onSubmitValidation = () => {
    handleSubmit(onSubmit)();
  };

  return (
    <>
      <form id={formIdentifier}>
        <div className="fr-container--fluid">
          {requiredInfos && (
            <div className="fr-grid-row fr-mb-2w required-infos">
              <p>{utilitaireCms.message_general_champs_obligatoire}</p>
            </div>
          )}
          {children}
        </div>
      </form>
      <div className="fr-modal__footer fr-modal__footer_no_button">
        <ul
          className={fr.cx(
            'fr-btns-group',
            'fr-btns-group--right',
            'fr-btns-group--inline-reverse',
            'fr-btns-group--inline-lg',
            'fr-btns-group--icon-left',
          )}
        >
          <li key={0}>
            <Button
              onClick={() => onSubmitValidation()}
              iconId="fr-icon-check-line"
              iconPosition="right"
              type="button"
              disabled={!isValid}
            >
              {globalCms.valider}
            </Button>
          </li>
          <li key={1}>
            <Button
              type="button"
              iconId="fr-icon-save-line"
              priority="secondary"
              onClick={() => onSubmitNoValidation()}
            >
              {globalCms.texte_bouton_enregistrer_demande_modales}
            </Button>
          </li>
        </ul>
      </div>
    </>
  );
};

export default FormModalLayout;
