import { RFClefStatut, RFDemande, RFEtape, RFEtapeStatut } from 'models/types/api/rf';

export const shouldDisplayEnquete = (demande: RFDemande) => demande.shouldDisplayEnquete;

export const shouldDisplayDocumentsManquants = (demande: RFDemande) =>
  demande.clefStatut === RFClefStatut.RECEPTIONNE_RECEPTIONNE_ATTENTE_DOCUMENTS_COMPLEMENTAIRES ||
  demande.clefStatut === RFClefStatut.PAS_ETAPE_DOSSIER_CLOTURE_DOSSIER_INCOMPLET;

export const isEtapeCourante = (etape: RFEtape, etapes: RFEtape[]): boolean =>
  etapes
    .filter(etape => etape.statut !== RFEtapeStatut.A_VENIR)
    .sort((a, b) => a.numero - b.numero)
    .pop() === etape;
