import { useMemo } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import { DemandeRfBloc1, DemandeRfGeneral, DemandeRfErrors } from 'models/types/cms/demandeRF/type';

import { getWhoAreYouValidationSchema } from './WhoAreYou.validationSchema';
import { IdentityDefaultValues } from './WhoAreYou.defaultValues';

export type CmsErrorProvider = {
  maxLengthErrorMessage: string;
  noDigitInvalidFormat: string;
  emailInvalidFormat: string;
  requiredFieldError: string;
  requiredFileError: string;
  fileGenericError: string;
  numeroEtrangerlengthError: string;
  numeroEtrangerFormatError: string;
  noSpecialCharError: string;
};

const GetWhoAreYouUseForm = (
  bloc1Cms: DemandeRfBloc1,
  globalCms: DemandeRfGeneral,
  erreursRfCms: DemandeRfErrors,
  forbiddenNationalites: string[],
) => {
  const IdentityValidationSchema = useMemo(
    () => getWhoAreYouValidationSchema(bloc1Cms, globalCms, erreursRfCms, forbiddenNationalites),
    [],
  );
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    getFieldState,
    trigger,
    watch,
    formState: { isValid, errors, touchedFields },
    control,
  } = useForm({
    resolver: yupResolver(IdentityValidationSchema),
    defaultValues: {
      ...IdentityDefaultValues,
    },
    mode: 'onChange',
  });

  return {
    handleSubmit,
    getValues,
    setValue,
    getFieldState,
    trigger,
    watch,
    formState: { isValid, errors, touchedFields },
    control,
    register,
  };
};

export default GetWhoAreYouUseForm;
