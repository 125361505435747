import { LIEN_PARENTE, RESIDENCE_LIEU } from 'constants/regroupementFamiliale';
import { mapBoolFormToServer } from 'helpers/api/formSubmission';
import { Enfant } from 'models/types/api/rf';
import { EnfantDemandeForm } from 'models/types/app/demandeRf';

export const enfantAppToApiFormatter = (
  data: EnfantDemandeForm,
  id: string | null,
  documents: any,
  isValid: boolean,
) => {
  const enfant: Enfant = {
    id: id,
    rfDemande: mapBoolFormToServer(data.rf_demande),
    vitEnFrance:
      data.enfant_etranger !== undefined ? data.enfant_etranger === RESIDENCE_LIEU.FRANCE : null,
    lienParente: LIEN_PARENTE.ENFANT,
    etatCivil: {
      nom: data.nom_naissance_enfant !== undefined ? data.nom_naissance_enfant : null,
      prenoms: data.prenoms_enfant !== undefined ? data.prenoms_enfant : null,
      sexe: data.sexe_enfant !== undefined ? data.sexe_enfant : null,
      nomUsage: data.nom_usage_enfant ? data.nom_usage_enfant : null,
      dateNaissance: data.date_naissance_enfant ? data.date_naissance_enfant : null,
      paysNaissance: data.pays_naissance_enfant ? data.pays_naissance_enfant : null,
      villeNaissance: data.ville_naissance_enfant ? data.ville_naissance_enfant : null,
      nationalite: data.nationalite_enfant ? data.nationalite_enfant : null,
      acteNaissanceTrad:
        data.acte_naissance_enfant_traduit !== undefined
          ? data.acte_naissance_enfant_traduit
          : null,
    },
    filiation: data.filiation !== undefined ? data.filiation : null,
    lienSecondParent: data.lien_second_parent !== undefined ? data.lien_second_parent : null,
    documents: documents,
    estValide: isValid,
  };

  return enfant;
};
