export enum STATUS {
  ERROR = 'error',
  VALID = 'success',
}

export const computeFieldStatus = (
  isTouched: boolean,
  isDirty: boolean,
  error: object | undefined,
) => {
  if (error) {
    return STATUS.ERROR;
  }

  if (isTouched && isDirty) {
    return STATUS.VALID;
  }
};

export const unauthaurizedInput = (evenKey: string) => {
  return (
    evenKey !== '+' &&
    evenKey !== '-' &&
    evenKey !== ' ' &&
    evenKey !== '32' &&
    evenKey !== 'Backspace' &&
    evenKey !== '(' &&
    evenKey !== ')' &&
    evenKey !== 'ArrowLeft' &&
    evenKey !== 'ArrowRight' &&
    evenKey !== 'Tab'
  );
};
