import React from 'react';
import Badge from '@codegouvfr/react-dsfr/Badge';
import { ButtonsGroup } from '@codegouvfr/react-dsfr/ButtonsGroup';
import Card from '@codegouvfr/react-dsfr/Card';

import Loader from 'components/Atoms/Loader/Loader';
import { DISPLAY_DATE_DDMMYYYY } from 'constants/time';
import { getRfCmsContents } from 'pages/DemandeRF/Helpers/Cms/getRfCmsContents';
import { Conjoint, Enfant } from 'models/types/api/rf';
import { useGetUsagerListePaysQuery } from 'redux/rtk/api/apiCommon';

import { badgeAppFormatter, dateAppFormatter } from '../helpers/familyDataFormatter';
import { searchPaysByCode } from '../helpers/familyUtils';

interface BeneficiaireTileInfoProps<T> {
  beneficiaire: T;
  setBeneficiaire: (beneficiaire: any) => void;
  delBeneficiaire: (obj: any) => void;
  demandeId: string;
  lang: string;
}

const BeneficiaireTileInfo: React.FC<BeneficiaireTileInfoProps<Conjoint | Enfant>> = ({
  beneficiaire,
  setBeneficiaire,
  delBeneficiaire,
  demandeId,
  lang,
}) => {
  const {
    data: listePays,
    isError: errorPays,
    isFetching: loadingPays,
  } = useGetUsagerListePaysQuery();

  const { globalCms } = getRfCmsContents(lang);

  const badgeInfo = badgeAppFormatter(beneficiaire, globalCms);
  if (errorPays) {
    return <div>Redirect page error</div>;
  }

  if (loadingPays) {
    return <Loader />;
  }
  return (
    <Card
      background
      border
      className="card-title"
      size="medium"
      title={`${beneficiaire.etatCivil?.nom} ${beneficiaire.etatCivil?.prenoms}`}
      titleAs="h3"
      start={<Badge severity={badgeInfo.color}>{badgeInfo.label.toUpperCase()}</Badge>}
      end={
        beneficiaire.rfDemande ? (
          <div>
            {beneficiaire.etatCivil?.dateNaissance ? (
              <>
                {globalCms.nee_le}{' '}
                {dateAppFormatter(beneficiaire.etatCivil?.dateNaissance, DISPLAY_DATE_DDMMYYYY)}
              </>
            ) : (
              ''
            )}
            <br />
            {beneficiaire.etatCivil?.villeNaissance
              ? `${beneficiaire.etatCivil?.villeNaissance} - `
              : ''}
            {beneficiaire.etatCivil?.paysNaissance && (
              <>{searchPaysByCode(listePays, beneficiaire.etatCivil?.paysNaissance, lang)}</>
            )}
          </div>
        ) : undefined
      }
      footer={
        <ButtonsGroup
          buttons={[
            {
              children: globalCms.modifier,
              iconId: 'fr-icon-edit-line',
              iconPosition: 'right',
              priority: 'secondary',
              type: 'button',
              onClick: () => setBeneficiaire(beneficiaire),
            },
            {
              children: '',
              type: 'button',
              iconId: 'fr-icon-delete-bin-line',
              onClick: () => delBeneficiaire({ demandeId: demandeId, id: beneficiaire.id }),
              priority: 'tertiary',
              className: 'icon-position',
            },
          ]}
          inlineLayoutWhen="always"
        />
      }
    />
  );
};

export default BeneficiaireTileInfo;
