import { RFNG_CAT_MAPPING } from 'constants/upload';
import { Union } from 'models/types/api/rf';
import { UnionDemandeForm } from 'models/types/app/demandeRf';
import { getDocumentsByCategory } from 'pages/DemandeRF/Helpers/common';

export const unionApiToAppFormatter = (data: Union, documents: any) => {
  const union: UnionDemandeForm = {
    union_precedente: data.type,
    union_debut: data.dateDebut,
    union_fin: data.dateFin,
    union_jugement_divorce_files: getDocumentsByCategory(
      RFNG_CAT_MAPPING.UNION_JUGEMENT_DIVORCE,
      documents,
    ),
    union_jugement_divorce_traduit: data.divorceTrad,
    union_jugement_divorce_traduit_files: getDocumentsByCategory(
      RFNG_CAT_MAPPING.UNION_JUGEMENT_DIVORCE_TRAD,
      documents,
    ),
    attestetation_dissolution_pacs_files: getDocumentsByCategory(
      RFNG_CAT_MAPPING.ATTESTATION_DISSOLUTION_PACS,
      documents,
    ),
  };

  return union;
};
