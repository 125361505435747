/* eslint-disable max-lines */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Card from '@codegouvfr/react-dsfr/Card';

import Loader from 'components/Atoms/Loader/Loader';
import AppBreadcrumb from 'components/Atoms/Breadcrumb/Breadcrumb';
import FourCardContainer from 'components/Molecules/FourCardContainer/FourCardContainer';
import Rappels from 'components/Molecules/Rappels/Rappels';
import UserProcedures from 'components/Molecules/UserProcedures/UserProcedures';
import CustomAlert from 'components/Atoms/CustomAlert/CustomAlert';
import { generateSection } from 'helpers/app/pagesHelper';
import { ProcedureDemandeType } from 'models/types/api/dashboard';
import { RFDemandeSummary } from 'models/types/api/rf';
import { useNewDemandeMutation } from 'redux/rtk/api/apiRf';
import { setArticles, setLayout } from 'redux/pages';
import { getArticles, getLayoutBlocs } from 'redux/pages/selectors';
import { getUser } from 'redux/user/selector';
import { setUser } from 'redux/user';
import { ARTICLES } from 'routes/path';
import {
  getListRFDemandeSummaryNonCloturees,
  isDemandeHasBeenSubmitted,
} from 'redux/demandeRf/selectors';
import { setDemandeAsile, setHasDemandeAsileBeenFetched } from 'redux/asile/slice';
import { setRFSummary, setListDemandeRF } from 'redux/demandeRf';
import { CMS_BASE_URL } from 'services/cmsClient';
import {
  getRFRappelsFromDemandesEnCours,
  // getReinsertionRappelsFromAllReinsertion,
} from 'services/rappels';
import { getCurrentLocale } from 'redux/locale/selectors';

import { getDashBoardContent } from './Dashboard.helper';
import './Dashboard.scss';

/* eslint-disable complexity */
const Dashboard = () => {
  const navigate = useNavigate();
  const lang = useSelector(getCurrentLocale) as string;
  const [demandeHasLoad, setDemandeHasLoad] = useState(true);

  //const isOpen = useIsModalOpen(demandeSubmittedModal);
  const [createDemande, { isLoading: isCreating }] = useNewDemandeMutation();
  const {
    userData,
    globalCms,
    rfCreation,
    // versements,
    layoutCms,
    articlesCms,
    dashboardCms,
    rappelCms,
    cmsRFTraductionAPI,
    demandesRf,
    demandesRfSummary,
    asileDerniere,
    fetchIsLoading,
    fetchHaveError,
  } = getDashBoardContent(lang);

  const dispatch = useDispatch();

  const articlesStore = useSelector(getArticles);
  const layoutBlocs = useSelector(getLayoutBlocs);
  const userStore = useSelector(getUser);
  const demandesRFEnCours = useSelector(getListRFDemandeSummaryNonCloturees).filter(
    (procedure: RFDemandeSummary): procedure is RFDemandeSummary =>
      procedure.type === ProcedureDemandeType.REGROUPEMENT_FAMILIAL &&
      procedure.clefStatut !== null &&
      procedure.statut !== null,
  );
  const isDemandeSubmitted = useSelector(isDemandeHasBeenSubmitted);
  // const dernierVersement = versements && versements.contenu[0];
  const getPageTitle = dashboardCms.bienvenue + ' ' + (userStore.prenom ?? '');

  const rappels = rappelCms && [
    // ...getRappelsFromDernierVersement(rappelCms.data.attributes, dernierVersement as Versement),
    ...(cmsRFTraductionAPI && demandesRFEnCours.length > 0
      ? getRFRappelsFromDemandesEnCours(
          rappelCms.data.attributes,
          demandesRFEnCours,
          cmsRFTraductionAPI,
        )
      : []),
    // ...getReinsertionRappelsFromAllReinsertion(rappelCms, demandesReinsertions),
    // ...getChangementStatutRetourRappel(rappelCms, retour),
  ];

  const onSubmit = async () => {
    const res = await createDemande(undefined);
    if (res) {
      if ('data' in res) {
        navigate(`/demande-regroupement-familial/${res.data.id}`);
      }
    }
  };

  useEffect(() => {
    if (asileDerniere !== undefined) {
      dispatch(setDemandeAsile(asileDerniere.contenu[0]));
      dispatch(setHasDemandeAsileBeenFetched(true));
    }
  }, [asileDerniere, dispatch]);

  useEffect(() => {
    if (demandesRf !== undefined) {
      dispatch(setListDemandeRF(demandesRf));
      setDemandeHasLoad(true);
    }
  }, [demandesRf, dispatch, fetchIsLoading]);

  useEffect(() => {
    if (demandesRfSummary) {
      dispatch(setRFSummary(demandesRfSummary));
    }
  }, [demandesRfSummary, dispatch]);

  useEffect(() => {
    if (articlesCms.data && articlesCms.data.length > 1 && (articlesStore?.length ?? 0) <= 1) {
      dispatch(setArticles(articlesCms.data));
    }
  }, [articlesCms, dispatch, articlesStore]);

  useEffect(() => {
    if (layoutCms) {
      dispatch(setLayout(layoutCms));
    }
  }, [layoutCms, dispatch]);

  useEffect(() => {
    if (userData) {
      dispatch(setUser(userData));
    }
  }, [userData, dispatch]);

  if (fetchHaveError) {
    console.log('une erreur est survenu:', fetchHaveError);
  }

  if (fetchIsLoading || isCreating || !dashboardCms || !rappelCms || !globalCms) {
    return <Loader />;
  }

  return (
    <>
      <main>
        <div className="fr-container">
          <AppBreadcrumb />
          <h1 className="fr-mt-6w fr-mt-md-5w fr-mb-6w">{getPageTitle}</h1>
          <div className="fr-grid-row fr-grid-row--gutters">
            <div className="fr-col-12 fr-col-md-6 fr-pr-md-5v">
              <h2>{rappelCms.data.attributes.titre}</h2>
              {isDemandeSubmitted ? (
                <CustomAlert
                  severity="info"
                  title="Information"
                  description={dashboardCms.text_demande_soumise}
                />
              ) : (
                <Rappels
                  rappelCms={rappelCms.data.attributes}
                  rappels={rappels}
                  globalCms={globalCms}
                  onSubmitRfBloc={onSubmit}
                  enableNewDemandeRf={rfCreation && rfCreation.demande_creation}
                  demandeHasLoad={demandeHasLoad}
                />
              )}
            </div>
            <div className="fr-col-12 fr-col-md-6 fr-pl-md-5v fr-mt-7v fr-mt-md-1v">
              <h2>{dashboardCms.procedures_en_cours.titre}</h2>
              <UserProcedures
                cmsComponent={dashboardCms.procedures_en_cours}
                cmsRFTraductionAPI={cmsRFTraductionAPI}
                dashboardCms={dashboardCms}
              />
            </div>
          </div>
          {dashboardCms && (
            <div className="fr-grid-row fr-mb-5w fr-p-2w fr-mt-6w fr-mt-md-7w">
              <div className="fr-col-lg-6">
                <h2>{dashboardCms.procedures.titre_bloc}</h2>
                <div className="container">
                  <Card
                    className="fr-card--no-border"
                    desc={dashboardCms.procedures.description}
                    enlargeLink
                    horizontal
                    imageUrl={`${CMS_BASE_URL}${dashboardCms.procedures_illustration.data.attributes.url}`}
                    imageAlt={dashboardCms.procedures_illustration.data.attributes.alternativeText}
                    linkProps={{
                      href: '/les-procedures',
                    }}
                    title={dashboardCms.procedures.titre}
                    titleAs="h3"
                  />
                </div>
              </div>
              <div className="fr-col-lg-6">
                <h2>{dashboardCms.services_en_ligne.titre_bloc}</h2>
                <div className="container">
                  <Card
                    className="fr-card--no-border"
                    desc={dashboardCms.services_en_ligne.description}
                    enlargeLink
                    horizontal
                    imageUrl={`${CMS_BASE_URL}${dashboardCms.services_illustration.data.attributes.url}`}
                    imageAlt={dashboardCms.services_illustration.data.attributes.alternativeText}
                    linkProps={{
                      href: '/les-services-en-ligne',
                    }}
                    title={dashboardCms.services_en_ligne.titre}
                    titleAs="h3"
                  />
                </div>
              </div>
            </div>
          )}
          {layoutBlocs.articles && articlesStore && (
            <FourCardContainer
              className="news"
              title={layoutBlocs.articles.titre}
              link={{
                href: ARTICLES,
                title: layoutBlocs.articles.bouton_voir_plus,
              }}
              cards={generateSection(articlesStore.slice(0, 4))}
            />
          )}
        </div>
      </main>
    </>
  );
};

export default Dashboard;
