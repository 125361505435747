import { Logement } from 'models/types/api/rf';

export type AccomodationState = Readonly<{
  isValid: boolean;
  isUntouched: boolean;
  accomodations: Logement[];
}>;

export const initialState: AccomodationState = {
  isValid: false,
  isUntouched: true,
  accomodations: [],
};
