import parse, { DOMNode, domToReact, Element } from 'html-react-parser';

const handleHtmlElement = (domNode: Element) => {
  let finalName = domNode.name;
  if (!domNode.next && domNode.name === 'p') {
    finalName = 'lastofpage-p';
  }

  switch (finalName) {
    case 'p':
      return <p>{domToReact(domNode.children)}</p>;
    case 'lastofpage-p':
      return <p className="fr-mb-2w">{domToReact(domNode.children)}</p>;
    default:
      return <>{domToReact(domNode.children)}</>;
  }
};

export const FormComponentsEnrichedText = (content: string) => {
  const options = {
    replace: (domNode: DOMNode) => {
      if (domNode instanceof Element) {
        return handleHtmlElement(domNode);
      }
    },
  };

  return parse(content, options);
};
