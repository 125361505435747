import { FC, useEffect, useState } from 'react';

import { getTopBannerContent } from 'pages/DemandeRF/Helpers/Cms/getTopBannerContent';
import { createModal } from '@codegouvfr/react-dsfr/Modal';
import { WidgetBaseElement, WidgetPageContenu } from 'models/types/cms/widgetPageContenu/types';
import CallOut from '@codegouvfr/react-dsfr/CallOut';
import WidgetFactory from 'helpers/cms/WidgetPageContenu/WidgetFactory';

export type TopBannerDemandeRFProps = {
  selectedTabId: string;
  cms: {
    bloc1Cms: any;
    bloc2Cms: any;
    bloc3Cms: any;
    bloc4Cms: any;
  };
};

const TopBannerDemandeRF: FC<TopBannerDemandeRFProps> = ({
  selectedTabId,
  cms: { bloc1Cms, bloc2Cms, bloc3Cms, bloc4Cms },
}) => {
  const [infobulleTitleValue, setInfobulleTitleValue] = useState<string>('');

  const topBannerModal = createModal({
    id: 'topBanner-modal',
    isOpenedByDefault: false,
  });

  const { bannerTitle, bannerContent, bannerLinkTitle, bannerLink, infobulleContent } =
    getTopBannerContent(selectedTabId, bloc1Cms, bloc2Cms, bloc3Cms, bloc4Cms);

  const isBannerEmpty = !bannerTitle && !bannerContent;

  let filteredCmsContent = [];

  if (infobulleContent) {
    filteredCmsContent = infobulleContent.filter(
      (widget: WidgetBaseElement) => !(widget.__component === WidgetPageContenu.TITRE_INFOBULLE),
    );
  }

  useEffect(() => {
    if (infobulleContent) {
      const infobulleTitle = infobulleContent.find(
        (widget: WidgetBaseElement) => widget.__component === WidgetPageContenu.TITRE_INFOBULLE,
      );

      if (infobulleTitle) {
        setInfobulleTitleValue(infobulleTitle.titre_infobulle);
      }
    }
  }, [infobulleContent]);

  const getLinkProps = (bannerLinkTitle: string, bannerLink: string) => {
    const bannerLinkExists = bannerLink && bannerLink.length > 1;
    const bannerLinkTitleExists = bannerLinkTitle && bannerLinkTitle.length > 1;

    if (bannerLinkTitleExists && bannerLinkExists) {
      return {
        children: bannerLinkTitle,
        onClick: () => {
          window.open(bannerLink, '_blank');
        },
      };
    }

    if (bannerLinkTitleExists && !bannerLinkExists) {
      return {
        children: bannerLinkTitle,
        onClick: () => {
          topBannerModal.open();
        },
      };
    }

    return undefined;
  };

  return (
    <>
      {!isBannerEmpty && (
        <>
          <div className="container fr-mb-5w">
            <CallOut
              buttonProps={getLinkProps(bannerLinkTitle, bannerLink)}
              iconId="ri-information-line"
              title={bannerTitle}
              className="fr-mb-0"
            >
              {bannerContent}
            </CallOut>
          </div>
          <topBannerModal.Component title={infobulleTitleValue}>
            {filteredCmsContent
              ? filteredCmsContent.map((widget: WidgetBaseElement) => (
                  <WidgetFactory key={`${widget.__component}-${widget.id}`} widget={widget} />
                ))
              : ''}
          </topBannerModal.Component>
        </>
      )}
    </>
  );
};

export default TopBannerDemandeRF;
