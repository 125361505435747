import { ApiData } from 'models/types/api/entities';
import { ApiVersement, Attributaire } from 'models/types/api/versement';

import { apiSlice } from './apiSlice';

const apiDna = apiSlice.injectEndpoints({
  endpoints: build => ({
    getVersements: build.query<ApiData<ApiVersement>, number | undefined>({
      query: nombre => ({
        url: `/api/private/versements?debut=0&avecDetails=1${nombre ? `&nombre=${nombre}` : ''}`,
      }),
    }),
    getAllocation: build.query<ApiData<any>, void>({
      query: () => ({
        url: `/api/private/allocations`,
      }),
    }),
    getHebergements: build.query<ApiData<any>, void>({
      query: () => ({
        url: `/api/private/hebergements`,
      }),
    }),
    getDerniere: build.query<ApiData<any>, void>({
      query: () => ({
        url: `/api/private/demandes-asile/derniere`,
      }),
    }),
    getAttributaire: build.query<ApiData<Attributaire>, void>({
      query: () => ({
        url: `/api/private/attributaire`,
      }),
    }),
  }),
  overrideExisting: false,
});
export const {
  useGetVersementsQuery,
  useGetAllocationQuery,
  useGetHebergementsQuery,
  useGetDerniereQuery,
  useGetAttributaireQuery,
} = apiDna;
