export enum RappelType {
  INFORMATION = 'info',
  URGENCE = 'warning',
}

export interface Rappel {
  type: RappelType;
  description:
    | {
        numeroDossier?: string;
        statut?: string;
        date?: string;
      }
    | string;
}

export interface RappelListItem extends Rappel {
  key: string;
  date: Date | null;
}
