import { FC } from 'react';

import { Breadcrumb } from '@codegouvfr/react-dsfr/Breadcrumb';

import { navigationThree, generateBreadcrumbArray } from 'helpers/app/navigationHelper';
import './Breadcrumb.scss';
import { useSelector } from 'react-redux';
import { getPagesTitles } from 'redux/pages/selectors';

const AppBreadcrumb: FC = () => {
  const titles = useSelector(getPagesTitles);
  const breadcrumbData = generateBreadcrumbArray(
    window.location.pathname.substring(1).split('/')[0].length === 2
      ? window.location.pathname.substring(1).split('/')[0]
      : '',
    window.location.pathname,
    navigationThree(titles),
    titles,
    [],
  );

  return (
    <Breadcrumb
      className="app-breadcrumb"
      currentPageLabel={breadcrumbData?.pageTitle}
      homeLinkProps={{
        href: '/',
      }}
      segments={breadcrumbData?.navArray ? breadcrumbData.navArray : []}
    />
  );
};

export default AppBreadcrumb;
