import { FC, ReactNode } from 'react';
import Card from '@codegouvfr/react-dsfr/Card';
import { Tag } from '@codegouvfr/react-dsfr/Tag';

interface FourCardContainerProps {
  className: string;
  title: string;
  link: {
    href: string;
    title: string;
  };
  cards: {
    key: string;
    title: string;
    badgeTitle?: string;
    description?: ReactNode;
    image: {
      src?: string;
      alt?: string;
    };
    link: {
      href: string;
      title?: string;
    };
    badgeType?: string;
  }[];
}

const FourCardContainer: FC<FourCardContainerProps> = ({ className, title, link, cards }) => {
  return (
    <div className={`fr-container fr-mb-4w fr-mb-md-7w ${className}`}>
      <div
        className={`fr-grid-row ${className}-container__desc news-title-line fr-mb-2w fr-mb-md-3w`}
      >
        <h2 className="fr-mb-2w fr-mb-md-5w fr-mr-4w">{title}</h2>
        <a
          className="fr-link fr-icon-arrow-right-line fr-link--icon-right fr-mb-1w fr-mb-md-3w fr-mt-0 "
          href={link.href}
        >
          {link.title}
        </a>
      </div>

      <div className="fr-grid-row fr-grid-row--gutters">
        {cards.map(card => {
          return (
            <div key={card.key} className={`fr-col-xs-12 fr-col-sm-12 fr-col-md-12 fr-col-lg-3`}>
              <Card
                detail={
                  card.badgeTitle ? (
                    <span className="fr-badges-group">
                      <Tag small>{card.badgeTitle}</Tag>
                    </span>
                  ) : undefined
                }
                title={card.title}
                desc={card.description}
                imageAlt={card.image.alt ? card.image.alt : ''}
                enlargeLink
                imageUrl={card.image.src ? card.image.src : ''}
                linkProps={{
                  href: card.link?.href,
                }}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default FourCardContainer;
