import qs from 'qs';

export const customFieldsQueryObject = {
  populate: {
    fields: [
      'titre',
      'texte',
      'contenu',
      'texte_bouton_precedent',
      'texte_bouton_recommencer',
      '__component',
    ],
    accordeons: {
      fields: ['label', 'titre', 'sous-titre', 'introduction'],
    },
    etapes: {
      fields: [
        'reference',
        'type',
        'description',
        'reference_suivante_si_oui',
        'reference_suivante_si_non',
        'aria_label_si_oui',
        'aria_label_si_non',
      ],
    },
    vignettes: {
      fields: ['titre', 'contenu'],
    },
    image: {
      fields: ['url', 'alternativeText'],
    },
  },
};

export const customFieldsQueryObject2 = {
  populate: {
    fields: [
      'titre',
      'texte',
      'contenu',
      'texte_bouton_precedent',
      'texte_bouton_recommencer',
      '__component',
    ],
    accordeons: {
      fields: ['label', 'titre', 'sous-titre', 'introduction'],
      populate: {
        lignes_accordeon: {
          fields: ['texte'],
          populate: {
            icone: {
              fields: ['url', 'alternativeText'],
            },
          },
        },
      },
    },
    vignettes: {
      fields: ['titre', 'contenu'],
    },
    image: {
      fields: ['url', 'alternativeText'],
    },
  },
};

export const ImageFieldQueryObject = {
  populate: {
    fields: ['ext', 'url', 'name', 'alternativeText', 'previewUrl'],
  },
};

export const accueilEtIntegrationQuery = qs.stringify({
  populate: {
    apprendre_francais: {
      ...customFieldsQueryObject2,
    },
    etapes_parcours: {
      ...customFieldsQueryObject2,
    },
  },
});

export const accueilEtIntegrationQuery1 = qs.stringify({
  populate: {
    apprendre_francais: {
      ...customFieldsQueryObject2,
    },
  },
});

export const accueilEtIntegrationQuery2 = qs.stringify({
  populate: {
    etapes_parcours: {
      ...customFieldsQueryObject2,
    },
  },
});

export const mentionsQuery = qs.stringify({
  populate: {
    fields: ['description_page'],
  },
});

export const rgpdQuery = qs.stringify({
  populate: {
    fields: ['description_page'],
  },
});

export const accessibiliteQuery = qs.stringify({
  populate: {
    fields: ['description_page'],
  },
});

export const cookieQuery = qs.stringify({
  populate: {
    fields: ['description_page_cookies'],
  },
});

export const accueilQuery = qs.stringify(
  {
    populate: {
      fields: ['titre', 'sous_titre', 'texte_bouton'],
      articles: {
        fields: ['titre', 'bouton_voir_plus'],
      },
      nos_services: {
        fields: ['titre'],
        populate: {
          bloc_1: {
            fields: ['titre', 'description'],
          },
          bloc_2: {
            fields: ['titre', 'description'],
          },
          bloc_3: {
            fields: ['titre', 'description'],
          },
        },
      },
      integration_france: {
        fields: ['titre'],
        populate: {
          bloc_1: {
            fields: ['titre', 'description', 'label_lien', 'lien'],
          },
          bloc_2: {
            fields: ['titre', 'description', 'label_lien', 'lien'],
          },
        },
      },
    },
  },
  {
    encodeValuesOnly: true,
  },
);

export const proceduresQuery = qs.stringify({
  populate: {
    fields: ['titre', 'lien_procedure', 'statut', 'position'],
    image_principale: {
      ...ImageFieldQueryObject,
    },
  },
});

export const proceduresSecondQuery = qs.stringify({
  populate: {
    contenu: {
      ...customFieldsQueryObject,
    },
  },
});

export const proceduresThirdQuery = qs.stringify({
  populate: {
    contenu: {
      ...customFieldsQueryObject2,
    },
  },
});

export const articlesQuery = qs.stringify({
  populate: {
    fields: ['statut', 'lien_article', 'position', 'titre', 'lien_article', 'description'],
    image_principale_petite: {
      ...ImageFieldQueryObject,
    },
    contenu: {
      ...customFieldsQueryObject,
    },
    categories_article: {
      populate: {
        fields: ['nom', 'slug'],
      },
    },
  },
});

export const articlesQuery1 = qs.stringify({
  populate: {
    fields: ['statut', 'lien_article', 'position', 'titre', 'lien_article', 'description'],
  },
});

export const articlesQuery2 = qs.stringify({
  populate: {
    image_principale_petite: {
      ...ImageFieldQueryObject,
    },
  },
});

export const articlesQuery3 = qs.stringify({
  populate: {
    contenu: {
      ...customFieldsQueryObject,
    },
  },
});

export const articlesQuery4 = qs.stringify({
  populate: {
    categories_article: {
      populate: {
        fields: ['nom', 'slug'],
      },
    },
  },
});

export const maintenanceQuery = qs.stringify({
  populate: {
    fields: [
      'mc',
      'mec',
      'dfm',
      'titre_service_indispo',
      'contenu_service_indispo',
      'sous_titre_info_service_indispo',
      'lien_fb',
      'lien_twitter',
      'lien_linkedin',
    ],
  },
});
