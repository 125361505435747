import React, { JSX } from 'react';
import { FrIconClassName, RiIconClassName } from '@codegouvfr/react-dsfr';

import { FormComponentsEnrichedText } from '../HtmlParsers/FormComponentHtmlParser';

export type CustomAlertProps = {
  title?: string;
  description?: string | JSX.Element | JSX.Element[];
  linkText?: string;
  link?: string;
  severity?: 'success' | 'warning' | 'info' | 'error' | string;
  closable?: boolean;
  small?: boolean;
  iconId?: FrIconClassName | RiIconClassName;
  customClass?: string;
};

const CustomAlert: React.FC<CustomAlertProps> = ({
  title,
  description,
  linkText,
  link,
  severity = 'warning',
  closable,
  small,
  customClass,
}) => {
  const closeAlert = (el: any) => {
    const alert = el.parentNode;
    alert.parentNode.removeChild(alert);
  };
  return (
    <div className={`fr-alert fr-alert--${severity} ${customClass ? customClass : ''}`}>
      {!small && title && <h3 className="fr-alert__title">{title}</h3>}
      {description && typeof description === 'string' && (
        <>{FormComponentsEnrichedText(description)}</>
      )}
      {description && typeof description !== 'string' && <>{description}</>}
      {link && linkText && (
        <a className="custom-a-on-alert" href={link} target="_blank" rel="noreferrer">
          {linkText}
        </a>
      )}
      {closable && (
        <button
          className="fr-btn--close fr-btn"
          title="Masquer le message"
          onClick={e => closeAlert(e.target)}
        >
          Masquer le message
        </button>
      )}
    </div>
  );
};

export default CustomAlert;
