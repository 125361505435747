// Need to use the React-specific entry point to import createApi
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import getEnv from 'helpers/app/envHelper';

const KEYCLOAK_URL = getEnv('REACT_APP_KEYCLOAK_URL') || '';
const KEYCLOAK_REALM = getEnv('REACT_APP_KEYCLOAK_REALM') || '';

export const keycloakSlice = createApi({
  reducerPath: 'kc',
  baseQuery: fetchBaseQuery({
    baseUrl: KEYCLOAK_URL,
  }),
  endpoints: builder => ({
    getAlive: builder.query<any, void>({
      query: () => ({
        url: `/realms/${KEYCLOAK_REALM}/.well-known/openid-configuration`,
        timeout: 7000,
      }),
    }),
  }),
});

export const { useGetAliveQuery } = keycloakSlice;
