import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { DemandeRfBloc2, DemandeRfErrors } from 'models/types/cms/demandeRF/type';

import { getUnionValidationSchema } from '../helpers/unionValidationSchema';
import { unionDefaultValues } from '../helpers/unionDefaultValues';

const UnionUseForm = (erreursRfCms: DemandeRfErrors, bloc2Cms: DemandeRfBloc2) => {
  const unionValidationSchema = useMemo(() => getUnionValidationSchema(erreursRfCms, bloc2Cms), []);

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    getFieldState,
    trigger,
    watch,
    formState,
    control,
  } = useForm({
    resolver: yupResolver(unionValidationSchema),
    defaultValues: {
      ...unionDefaultValues,
    },
    mode: 'all',
  });

  return {
    handleSubmit,
    getValues,
    setValue,
    getFieldState,
    trigger,
    watch,
    formState,
    control,
    register,
  };
};

export default UnionUseForm;
