import { useMemo } from 'react';
import { getResourcesValidationSchema } from './Resources.validationSchema';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { RessourcesDefaultValues } from './Resources.defaultValues';
import { DemandeRfBloc4, DemandeRfErrors } from 'models/types/cms/demandeRF/type';

const GetResourcesUseForm = (erreursRfCms: DemandeRfErrors, bloc4Cms: DemandeRfBloc4) => {
  const ResourcesValidationSchema = useMemo(
    () => getResourcesValidationSchema(erreursRfCms, bloc4Cms),
    [],
  );
  const {
    handleSubmit,
    getValues,
    setValue,
    getFieldState,
    trigger,
    watch,
    formState: { isValid, errors, touchedFields, dirtyFields },
    control,
    register,
  } = useForm({
    resolver: yupResolver(ResourcesValidationSchema),
    defaultValues: {
      ...RessourcesDefaultValues,
    },
    mode: 'onChange',
  });

  return {
    handleSubmit,
    getValues,
    setValue,
    getFieldState,
    trigger,
    watch,
    register,
    formState: { isValid, errors, touchedFields, dirtyFields },
    control,
  };
};

export default GetResourcesUseForm;
