import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import { getCurrentLocale } from 'redux/locale/selectors';
import Loader from 'components/Atoms/Loader/Loader';
import { setAccessibilitePage } from 'redux/pages';
import { getAccessibilitePage } from 'redux/pages/selectors';

import CmsPage from '../CmsPage';
import { useGetAccessibiliteCmsPageQuery } from 'redux/rtk/cms/cmsAccessibilite';

const Accessibilite: FC = () => {
  const lang = useSelector(getCurrentLocale) as string;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const accessibiliteStore = useSelector(getAccessibilitePage);

  const {
    data: accessibiliteCms,
    error: accessibiliteCmsError,
    isLoading: isAccessibiliteCmsLoading,
  } = useGetAccessibiliteCmsPageQuery({
    locale: lang !== '' ? lang : 'fr',
  });

  useEffect(() => {
    if (!isAccessibiliteCmsLoading && !accessibiliteCmsError) {
      dispatch(setAccessibilitePage(accessibiliteCms.data));
    }
  }, [accessibiliteCms, accessibiliteCmsError, isAccessibiliteCmsLoading, dispatch]);

  if (isAccessibiliteCmsLoading) {
    return <Loader />;
  }

  if (accessibiliteCmsError) {
    navigate('/erreur');
  }

  return <CmsPage type="accessibilite" store={accessibiliteStore} lang={lang} />;
};

export default Accessibilite;
