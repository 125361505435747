import React from 'react';
import Badge from '@codegouvfr/react-dsfr/Badge';
import { ButtonsGroup } from '@codegouvfr/react-dsfr/ButtonsGroup';
import Card from '@codegouvfr/react-dsfr/Card';

import { DISPLAY_DATE_YYYY } from 'constants/time';
import { UNION_TYPE } from 'constants/regroupementFamiliale';
import { Union } from 'models/types/api/rf';
import { getRfCmsContents } from 'pages/DemandeRF/Helpers/Cms/getRfCmsContents';

import { dateAppFormatter } from '../helpers/familyDataFormatter';

interface UnionTileInfoProps {
  union: Union;
  setUnion: (union: any) => void;
  delUnion: (obj: any) => void;
  demandeId: string;
  lang: string;
}

const UnionTileInfo: React.FC<UnionTileInfoProps> = ({
  union,
  setUnion,
  delUnion,
  demandeId,
  lang,
}) => {
  const { globalCms, bloc2Cms } = getRfCmsContents(lang);
  return (
    <Card
      background
      border
      className="card-title"
      size="medium"
      title={`${
        union.type === UNION_TYPE.MARIE
          ? bloc2Cms.marie
          : union.type === UNION_TYPE.PACSE
          ? bloc2Cms.pacse
          : ''
      } `}
      titleAs="h3"
      start={
        !union.estValide ? (
          <Badge severity="error">{globalCms.a_completer_badge_warning}</Badge>
        ) : null
      }
      end={
        <div>
          {union.dateDebut
            ? `${globalCms.de} ${dateAppFormatter(union.dateDebut, DISPLAY_DATE_YYYY)}`
            : ''}
          {union.dateFin
            ? ` ${globalCms.a} ${dateAppFormatter(union.dateFin, DISPLAY_DATE_YYYY)}`
            : ''}
        </div>
      }
      footer={
        <ButtonsGroup
          buttons={[
            {
              children: globalCms.modifier,
              iconId: 'fr-icon-edit-line',
              iconPosition: 'right',
              priority: 'secondary',
              type: 'button',
              onClick: () => setUnion(union),
            },
            {
              children: '',
              type: 'button',
              iconId: 'fr-icon-delete-bin-line',
              onClick: () => delUnion({ demandeId: demandeId, id: union.id, uuid: union.uuid }),
              priority: 'tertiary',
              className: 'icon-position',
            },
          ]}
          inlineLayoutWhen="always"
        />
      }
    />
  );
};

export default UnionTileInfo;
