import { cmsSlice } from './cmsSlice';

const cmsCookie = cmsSlice.injectEndpoints({
  endpoints: build => ({
    getCookieCmsPage: build.query<any, { qs?: string; locale: string }>({
      query: ({ locale }) => ({ url: `/cookie?locale=${locale}&populate=*` }),
    }),
  }),
});

export const { useGetCookieCmsPageQuery } = cmsCookie;
