import React from 'react';
import { useWatch } from 'react-hook-form';

import CommonRowContainer from 'components/Molecules/Forms/CommonRowContainer/CommonRowContainer';
import RadioButtonContainer from 'components/Molecules/Forms/RadioButtonContainer/RadioButtonContainer';
import { CHOIX } from 'constants/global';
import UploadFiles from 'components/Atoms/UploadFiles/UploadFiles';
import CustomCheckboxContainer from 'components/Molecules/Forms/CustomCheckboxContainer/CustomCheckboxContainer';
import CustomAlert from 'components/Atoms/CustomAlert/CustomAlert';
import { getRfCmsContents } from 'pages/DemandeRF/Helpers/Cms/getRfCmsContents';

import conjointMapping from '../helpers/conjointMapping';

type ConjointMariagePrecedentProps = {
  formFieldsMapping: any;
  filesMapping: any;
  fieldArrayMapping: any;
  isNew: string;
  control: any;
  lang: string;
};
const ConjointMariagePrecedent: React.FC<ConjointMariagePrecedentProps> = ({
  formFieldsMapping,
  filesMapping,
  fieldArrayMapping,
  isNew,
  control,
  lang,
}) => {
  const { bloc2Cms } = getRfCmsContents(lang);
  const conjointJugementDivorceTraduit = useWatch({
    control,
    name: 'conjoint_jugement_divorce_traduit',
  });

  const mariagePrecedentConjoint = useWatch({
    control,
    name: 'mariage_precedent_conjoint',
  });

  return (
    <>
      <CommonRowContainer
        children={[
          <RadioButtonContainer
            {...formFieldsMapping.mariage_precedent_conjoint}
            large
            orientation="horizontal"
          />,
        ]}
      />

      {mariagePrecedentConjoint === CHOIX.OUI && (
        <div className="fr-mt-2w fr-mt-md-3w">
          <CustomAlert
            {...conjointMapping.getGeneralMapping().getAlertsFields(bloc2Cms).alert_marie}
            customClass="fr-mb-2w fr-mb-md-3w"
          />
          <UploadFiles
            {...filesMapping.conjoint_jugement_divorce_files.upload}
            fieldArray={fieldArrayMapping.conjoint_jugement_divorce_files}
            isNew={isNew}
            lang={lang}
          />
          <CustomCheckboxContainer {...formFieldsMapping.conjoint_jugement_divorce_traduit} large />
          {conjointJugementDivorceTraduit && (
            <UploadFiles
              {...filesMapping.conjoint_jugement_divorce_traduit_files.upload}
              fieldArray={fieldArrayMapping.conjoint_jugement_divorce_traduit_files}
              isNew={isNew}
            />
          )}
        </div>
      )}
    </>
  );
};

export default ConjointMariagePrecedent;
