import Loader from 'components/Atoms/Loader/Loader';
import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { getCurrentLocale } from 'redux/locale/selectors';
import { setMentionsPage } from 'redux/pages';
import { getMentionsPage } from 'redux/pages/selectors';
import { useGetMentionsCmsPageQuery } from 'redux/rtk/cms/cmsMentions';
import CmsPage from '../CmsPage';

const MentionLegales: FC<any> = ({}) => {
  const lang = useSelector(getCurrentLocale) as string;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const mentionsStore = useSelector(getMentionsPage);

  const {
    data: mentionsCms,
    error: mentionsCmsError,
    isLoading: isMentionsCmsLoading,
  } = useGetMentionsCmsPageQuery({
    locale: lang !== '' ? lang : 'fr',
  });

  useEffect(() => {
    if (!mentionsCmsError && !isMentionsCmsLoading) {
      dispatch(setMentionsPage(mentionsCms.data));
    }
  }, [mentionsCmsError, isMentionsCmsLoading]);

  if (isMentionsCmsLoading) {
    return <Loader />;
  }

  if (mentionsCmsError) {
    navigate('/erreur');
  }

  return <CmsPage type="mentions_legales" store={mentionsStore} lang={lang} />;
};

export default MentionLegales;
