/* eslint-disable max-lines */
/* eslint-disable max-params */

import { cmsEnrichedText } from 'components/Atoms/HtmlParsers/PageContentHtmlParser';
import { CustomAlertProps } from 'components/Atoms/CustomAlert/CustomAlert';
import { GENRE_API } from 'constants/genre';
import { RESIDENCE_LIEU } from 'constants/regroupementFamiliale';
import { CHOIX } from 'constants/global';
import { RFNG_CAT_MAPPING } from 'constants/upload';
import { DemandeRfBloc2, DemandeRfGeneral } from 'models/types/cms/demandeRF/type';
import { FilesField, UploadFilesParameters } from 'models/types/app/uploadFiles';

interface AlertsMapping {
  alert_marie: CustomAlertProps;
}

export default class conjointMapping {
  static getGeneralMapping() {
    function getAlertsFields(bloc2Cms: DemandeRfBloc2): AlertsMapping {
      return {
        alert_marie: {
          title: bloc2Cms.bulle_alerte_mariage_civils_seul.titre,
          description: bloc2Cms.bulle_alerte_mariage_civils_seul.contenu
            ? cmsEnrichedText(bloc2Cms.bulle_alerte_mariage_civils_seul.contenu)
            : '',
          severity: 'warning',
          closable: false,
        },
      };
    }

    function getFormFields(
      cms: {
        bloc2Cms: DemandeRfBloc2;
        globalCms: DemandeRfGeneral;
      },
      formControls: {
        setValue: any;
        getValues: any;
        control: any;
        trigger: any;
      },
      lang?: string,
    ) {
      const { bloc2Cms, globalCms } = cms;
      const { setValue, control, trigger, getValues } = formControls;
      return {
        rf_demande: {
          name: 'rf_demande',
          label: bloc2Cms.sous_titre_renseigner_infos_conjoint,
          control: control,
          options: [
            {
              label: globalCms.oui,
              nativeInputProps: {
                value: CHOIX.OUI,
                checked: control._formValues.rf_demande === CHOIX.OUI,
                onChange: () => {
                  setValue('rf_demande', CHOIX.OUI);
                  trigger('rf_demande');
                },
              },
            },
            {
              label: globalCms.non,
              nativeInputProps: {
                value: CHOIX.NON,
                checked: control._formValues.rf_demande === CHOIX.NON,
                onChange: () => {
                  setValue('rf_demande', CHOIX.NON);
                  trigger('rf_demande');
                },
              },
            },
          ],
        },
        conjoint_etranger: {
          name: 'conjoint_etranger',
          label: bloc2Cms.label_lieu_residence,
          control: control,
          options: [
            {
              label: globalCms.a_letranger,
              nativeInputProps: {
                value: RESIDENCE_LIEU.ETRANGER,
                checked: control._formValues.conjoint_etranger === RESIDENCE_LIEU.ETRANGER,
                onChange: () => {
                  setValue('conjoint_etranger', RESIDENCE_LIEU.ETRANGER);
                  trigger('conjoint_etranger');
                },
              },
            },
            {
              label: globalCms.en_france,
              nativeInputProps: {
                value: RESIDENCE_LIEU.FRANCE,
                checked: control._formValues.conjoint_etranger === RESIDENCE_LIEU.FRANCE,
                onChange: () => {
                  setValue('conjoint_etranger', RESIDENCE_LIEU.FRANCE);
                  trigger('conjoint_etranger');
                },
              },
            },
          ],
        },
        nom_naissance_conjoint: {
          name: 'nom_naissance_conjoint',
          control: control,
          label: bloc2Cms.nom_naissance_conjoint,
          hintText: bloc2Cms.regardez_titre_identite_conjoint,
          required: true,
        },
        nom_usage_conjoint: {
          name: 'nom_usage_conjoint',
          control: control,
          label: bloc2Cms.nom_usage_conjoint,
          hintText: globalCms.info_non_obligatoire,
          required: false,
        },
        prenoms_conjoint: {
          name: 'prenoms_conjoint',
          control: control,
          label: bloc2Cms.prenom_conjoint,
          hintText: bloc2Cms.regardez_titre_identite_conjoint,
          required: true,
        },
        sexe_conjoint: {
          name: 'sexe_conjoint',
          label: bloc2Cms.label_sexe,
          orientation: 'horizontal',
          control: control,
          options: [
            {
              label: globalCms.femme,
              nativeInputProps: {
                value: GENRE_API.FEMME,
                checked: control._formValues.sexe_conjoint === GENRE_API.FEMME,
                onChange: () => {
                  setValue('sexe_conjoint', GENRE_API.FEMME);
                  trigger('sexe_conjoint');
                },
              },
            },
            {
              label: globalCms.homme,
              nativeInputProps: {
                value: GENRE_API.HOMME,
                checked: control._formValues.sexe_conjoint === GENRE_API.HOMME,
                onChange: () => {
                  setValue('sexe_conjoint', GENRE_API.HOMME);
                  trigger('sexe_conjoint');
                },
              },
            },
          ],
        },
        date_naissance_conjoint: {
          control: control,
          name: 'date_naissance_conjoint',
          label: bloc2Cms.date_naissance_conjoint,
          hintText: bloc2Cms.regardez_titre_identite_conjoint,
          required: true,
        },
        pays_naissance_conjoint: {
          name: 'pays_naissance_conjoint',
          control: control,
          label: bloc2Cms.pays_naissance_conjoint,
          hintText: bloc2Cms.regardez_titre_identite_conjoint,
          required: true,
          lang: lang,
        },
        ville_naissance_conjoint: {
          name: 'ville_naissance_conjoint',
          control: control,
          label: bloc2Cms.ville_naissance_conjoint,
          hintText: bloc2Cms.regardez_titre_identite_conjoint,
          required: true,
        },
        nationalite_conjoint: {
          name: 'nationalite_conjoint',
          control: control,
          label: bloc2Cms.label_titre_nationalite_conjoint,
          hintText: bloc2Cms.regardez_titre_identite_conjoint,
          required: true,
          lang: lang,
        },
        acte_naissance_conjoint_traduit: {
          name: 'acte_naissance_conjoint_traduit',
          key: 'acte_naissance_conjoint_traduit',
          options: [
            {
              label: globalCms.document_langue_origine,
              id: 'acte_naissance_conjoint_traduit',
              hintText: globalCms.sous_titre_case_document_langue_origine,
              nativeInputProps: {
                name: 'acte_naissance_conjoint_traduit',
                onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                  setValue('acte_naissance_conjoint_traduit', e.target.checked);
                  trigger();
                },
                checked: getValues('acte_naissance_conjoint_traduit'),
              },
            },
          ],
          control: control,
        },
        mariage_precedent_conjoint: {
          name: 'mariage_precedent_conjoint',
          label: bloc2Cms.conjoint_marie_auparavant,
          control: control,
          options: [
            {
              label: globalCms.oui,
              nativeInputProps: {
                value: CHOIX.OUI,
                checked: control._formValues.mariage_precedent_conjoint === CHOIX.OUI,
                onChange: () => {
                  setValue('mariage_precedent_conjoint', CHOIX.OUI);
                  trigger('mariage_precedent_conjoint');
                },
              },
            },
            {
              label: globalCms.non,
              nativeInputProps: {
                value: CHOIX.NON,
                checked: control._formValues.mariage_precedent_conjoint === CHOIX.NON,
                onChange: () => {
                  setValue('mariage_precedent_conjoint', CHOIX.NON);
                  trigger('mariage_precedent_conjoint');
                },
              },
            },
          ],
        },
        conjoint_jugement_divorce_traduit: {
          name: 'conjoint_jugement_divorce_traduit',
          key: 'conjoint_jugement_divorce_traduit',
          options: [
            {
              label: globalCms.document_langue_origine,
              id: 'conjoint_jugement_divorce_traduit',
              hintText: globalCms.sous_titre_case_document_langue_origine,
              nativeInputProps: {
                name: 'conjoint_jugement_divorce_traduit',
                onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                  setValue('conjoint_jugement_divorce_traduit', e.target.checked);
                  trigger();
                },
                checked: getValues('conjoint_jugement_divorce_traduit'),
              },
            },
          ],
          control: control,
        },
        titre_sejour_fr_conjoint: {
          name: 'titre_sejour_fr_conjoint',
          label: bloc2Cms.conjoint_possede_titre_sejour,
          control: control,
          options: [
            {
              label: globalCms.oui,
              nativeInputProps: {
                value: CHOIX.OUI,
                checked: control._formValues.titre_sejour_fr_conjoint === CHOIX.OUI,
                onChange: () => {
                  setValue('titre_sejour_fr_conjoint', CHOIX.OUI);
                  trigger('titre_sejour_fr_conjoint');
                },
              },
            },
            {
              label: globalCms.non,
              nativeInputProps: {
                value: CHOIX.NON,
                checked: control._formValues.titre_sejour_fr_conjoint === CHOIX.NON,
                onChange: () => {
                  setValue('titre_sejour_fr_conjoint', CHOIX.NON);
                  trigger('titre_sejour_fr_conjoint');
                },
              },
            },
          ],
        },
      };
    }

    function getFilesFields(
      bloc2Cms: DemandeRfBloc2,
      uploadFilesParameters: UploadFilesParameters,
      errorCms: any,
    ): FilesField {
      return {
        acte_naissance_conjoint_files: {
          upload: {
            id: 'infos-acte-naissance-conjoint',
            categorie: RFNG_CAT_MAPPING.ACTE_NAISSANCE_CONJOINT,
            label: bloc2Cms.fichier_acte_naissance_conjoint.titre_fichier ?? '',
            hint: bloc2Cms.fichier_acte_naissance_conjoint.infos_fichier
              ? cmsEnrichedText(bloc2Cms.fichier_acte_naissance_conjoint.infos_fichier)
              : '',
            state: 'default',
            stateRelatedMessage: errorCms.erreur_generique_fichier,
            required: true,
            ...uploadFilesParameters,
            name: 'acte_naissance_conjoint_files',
            resource: 'conjoint',
            resourceId: undefined,
            helpbox: {
              closable: false,
              description: bloc2Cms.fichier_acte_naissance_conjoint.bulle_aide_fichier_contenu
                ? cmsEnrichedText(
                    bloc2Cms.fichier_acte_naissance_conjoint.bulle_aide_fichier_contenu,
                  )
                : '',
              severity: 'info',
              title: bloc2Cms.fichier_acte_naissance_conjoint.bulle_aide_fichier_titre,
            },
            infobulleContent: bloc2Cms.infobulle_conjoint_fichier_acte_naissance,
            infobulleCustomModal: true,
          },
        },
        acte_naissance_conjoint_traduit_files: {
          upload: {
            id: 'infos-acte-naissance-conjoint-traduit',
            label: bloc2Cms.fichier_acte_naissance_conjoint_traduit.titre_fichier ?? '',
            hint: bloc2Cms.fichier_acte_naissance_conjoint_traduit.infos_fichier
              ? cmsEnrichedText(bloc2Cms.fichier_acte_naissance_conjoint_traduit.infos_fichier)
              : '',
            state: 'default',
            stateRelatedMessage: errorCms.erreur_generique_fichier,
            required: true,
            ...uploadFilesParameters,
            name: 'acte_naissance_conjoint_traduit_files',
            categorie: RFNG_CAT_MAPPING.ACTE_NAISSANCE_CONJOINT_TRAD,
            resource: 'conjoint',
            resourceId: undefined,
            helpbox: {
              title: bloc2Cms.fichier_acte_naissance_conjoint_traduit.bulle_aide_fichier_titre,
              description: bloc2Cms.fichier_acte_naissance_conjoint_traduit
                .bulle_aide_fichier_contenu
                ? cmsEnrichedText(
                    bloc2Cms.fichier_acte_naissance_conjoint_traduit.bulle_aide_fichier_contenu,
                  )
                : '',
              link: bloc2Cms.fichier_acte_naissance_conjoint_traduit.bulle_aide_fichier_lien,
              linkText:
                bloc2Cms.fichier_acte_naissance_conjoint_traduit.bulle_aide_fichier_texte_lien,
              closable: false,
              severity: 'info',
            },
          },
        },
        conjoint_titre_identite_files: {
          upload: {
            id: 'conjoint_titre_identite_files',
            label: bloc2Cms.fichier_titre_identite_conjoint.titre_fichier ?? '',
            hint: bloc2Cms.fichier_titre_identite_conjoint.infos_fichier
              ? cmsEnrichedText(bloc2Cms.fichier_titre_identite_conjoint.infos_fichier)
              : '',
            state: 'default',
            stateRelatedMessage: errorCms.erreur_generique_fichier,
            required: true,
            ...uploadFilesParameters,
            name: 'conjoint_titre_identite_files',
            categorie: RFNG_CAT_MAPPING.TITRE_IDENTITE_CONJOINT,
            resource: 'conjoint',
            resourceId: undefined,
            helpbox: {
              title: bloc2Cms.fichier_titre_identite_conjoint.bulle_aide_fichier_titre,
              description: bloc2Cms.fichier_titre_identite_conjoint.bulle_aide_fichier_contenu
                ? cmsEnrichedText(
                    bloc2Cms.fichier_titre_identite_conjoint.bulle_aide_fichier_contenu,
                  )
                : '',
              closable: true,
              severity: 'info',
            },
            infobulleContent: bloc2Cms.infobulle_conjoint_fichier_titre_identite_conjoint,
            infobulleCustomModal: true,
          },
        },
        conjoint_jugement_divorce_files: {
          upload: {
            id: 'conjoint_jugement_divorce_files',
            label: bloc2Cms.fichier_conjoint_jugement_divorce.titre_fichier ?? '',
            hint: bloc2Cms.fichier_conjoint_jugement_divorce.infos_fichier
              ? cmsEnrichedText(bloc2Cms.fichier_conjoint_jugement_divorce.infos_fichier)
              : '',
            state: 'default',
            stateRelatedMessage: errorCms.erreur_generique_fichier,
            required: true,
            ...uploadFilesParameters,
            name: 'conjoint_jugement_divorce_files',
            categorie: RFNG_CAT_MAPPING.JUGEMENT_DIVORCE_CONJOINT,
            resource: 'conjoint',
            resourceId: undefined,
            helpbox: {
              closable: false,
              description: bloc2Cms.fichier_conjoint_jugement_divorce.bulle_aide_fichier_contenu
                ? cmsEnrichedText(
                    bloc2Cms.fichier_conjoint_jugement_divorce.bulle_aide_fichier_contenu,
                  )
                : '',
              severity: 'info',
              title: bloc2Cms.fichier_conjoint_jugement_divorce.bulle_aide_fichier_titre,
            },
            infobulleContent: bloc2Cms.infobulle_conjoint_fichier_acte_divorce_fr,
            infobulleCustomModal: true,
          },
        },
        conjoint_jugement_divorce_traduit_files: {
          upload: {
            id: 'conjoint_jugement_divorce_traduit_files',
            label: bloc2Cms.fichier_conjoint_jugement_divorce_traduit.titre_fichier ?? '',
            hint: bloc2Cms.fichier_conjoint_jugement_divorce_traduit.infos_fichier
              ? cmsEnrichedText(bloc2Cms.fichier_conjoint_jugement_divorce_traduit.infos_fichier)
              : '',
            state: 'default',
            stateRelatedMessage: errorCms.erreur_generique_fichier,
            required: false,
            ...uploadFilesParameters,
            name: 'conjoint_jugement_divorce_traduit_files',
            categorie: RFNG_CAT_MAPPING.JUGEMENT_DIVORCE_CONJOINT_TRAD,
            resource: 'conjoint',
            resourceId: undefined,
            helpbox: {
              closable: false,
              description: bloc2Cms.fichier_conjoint_jugement_divorce_traduit
                .bulle_aide_fichier_contenu
                ? cmsEnrichedText(
                    bloc2Cms.fichier_conjoint_jugement_divorce_traduit.bulle_aide_fichier_contenu,
                  )
                : '',
              severity: 'info',
              title: bloc2Cms.fichier_conjoint_jugement_divorce_traduit.bulle_aide_fichier_titre,
            },
          },
        },
        conjoint_titre_sejour_files: {
          upload: {
            id: 'titre_sejour_conjoint_files',
            label: bloc2Cms.fichier_conjoint_titre_sejour.titre_fichier ?? '',
            hint: bloc2Cms.fichier_conjoint_titre_sejour.infos_fichier
              ? cmsEnrichedText(bloc2Cms.fichier_conjoint_titre_sejour.infos_fichier)
              : '',
            state: 'default',
            stateRelatedMessage: errorCms.erreur_generique_fichier,
            required: false,
            ...uploadFilesParameters,
            name: 'titre_sejour_conjoint_files',
            categorie: RFNG_CAT_MAPPING.TITRE_SEJOUR_CONJOINT,
            resource: 'conjoint',
            resourceId: undefined,
            helpbox: {
              closable: false,
              description: bloc2Cms.fichier_conjoint_titre_sejour.bulle_aide_fichier_contenu
                ? cmsEnrichedText(bloc2Cms.fichier_conjoint_titre_sejour.bulle_aide_fichier_contenu)
                : '',
              severity: 'info',
              title: bloc2Cms.fichier_conjoint_titre_sejour.bulle_aide_fichier_titre,
            },
            infobulleContent: bloc2Cms.infobulle_conjoint_fichier_titre_sejour,
            infobulleCustomModal: true,
          },
        },
      };
    }

    return { getAlertsFields, getFormFields, getFilesFields };
  }
}
