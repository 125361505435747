import { ImageCmsDto } from '../types';

export type WidgetBaseElement = {
  id: number;
  __component: string;
};

export type WidgetLigneAccordeonDto = {
  id: number;
  texte: string;
  icone: {
    data: {
      id: number;
      attributes: {
        url: string;
        alternativeText: string;
      };
    };
  };
};

export type WidgetAccordeonDto = {
  id: number;
  label: string;
  titre: string;
  sous_titre: string | null;
  introduction: string;
  lignes_accordeon: WidgetLigneAccordeonDto[];
};

export type WidgetEtapesEligibiliteDto = {
  id: number;
  reference: number;
  type: string;
  description: string;
  reference_suivante_si_oui: number;
  reference_suivante_si_non: number;
  aria_label_si_oui: string | null;
  aria_label_si_non: string | null;
};

export interface Eligibilite {
  resultat: string;
  texte_bouton_precedent: string;
  texte_bouton_recommencer: string;
  liste_pays_ressortissant_zone1: string;
  liste_pays_ressortissant_zone2: string;
  etapes: WidgetEtapesEligibiliteDto[];
}

export enum EtapeEligibiliteType {
  PREMIERE_QUESTION = 'premiere_question',
  QUESTION = 'question',
  ELIGIBLE = 'resultat_eligible',
  NON_ELIGIBLE = 'resultat_non_eligible',
  QUESTION_RESSORTISSANT_ZONE1 = 'question_ressortissant_zone1',
  QUESTION_RESSORTISSANT_ZONE2 = 'question_ressortissant_zone2',
}

export type WidgetIntroductionDto = WidgetBaseElement & {
  texte: string;
};

export type WidgetParagraphDto = WidgetBaseElement & {
  contenu: string;
};

export type WidgetGroupAccordeonDto = WidgetBaseElement & {
  titre: string;
  accordeons: WidgetAccordeonDto[];
};

export type WidgetEligibiliteDto = WidgetBaseElement & {
  texte_bouton_precedent: string;
  texte_bouton_recommencer: string;
  etapes: WidgetEtapesEligibiliteDto[];
};

export interface Illustration {
  image: ImageCmsDto;
}

export interface ImageWithAlt {
  image: ImageCmsDto;
  balise_alt?: string;
}

export interface Vignette {
  id: number;
  illustration: ImageCmsDto;
  titre: string;
  contenu: string;
}

export interface GroupeVignette {
  vignettes: Vignette[];
}

export interface YoutubeVideo {
  titre?: string;
  lien_video_youtube: string;
}
export type CmsDocument = ImageCmsDto;

export interface DocumentButton {
  label: string;
  document: CmsDocument;
}

export type WidgetGenericObject = WidgetBaseElement & {
  label?: string;
  titre?: string;
  texte?: string;
  anchor?: string;
  contenu?: string;
  texte_bouton_precedent?: string;
  texte_bouton_recommencer?: string;
  image?: ImageCmsDto;
  balise_alt?: string;
  accordeons?: WidgetAccordeonDto[];
  etapes?: WidgetEtapesEligibiliteDto[];
  lien_video_youtube?: string;
  document?: CmsDocument;
  titre_infobulle?: string;
};

export enum WidgetPageContenu {
  GROUPE_ACCORDEON = 'widget-page-contenu.groupe-accordeon',
  ELIGIBILITE = 'widget-page-contenu.eligibilite',
  ILLUSTRATION = 'widget-page-contenu.illustration',
  IMAGEWITHALT = 'widget-page-contenu.image_with_alt',
  INTRODUCTION = 'widget-page-contenu.introduction',
  PARAGRAPHE = 'widget-page-contenu.paragraphe',
  GROUPE_VIGNETTE = 'widget-page-contenu.groupe-vignette',
  YOUTUBE_VIDEO = 'widget-page-contenu.video',
  DOCUMENT_BUTTON = 'widget-page-contenu.document-button',
  TITRE_INFOBULLE = 'widget-page-contenu.titre-infobulle',
}

export enum TypePageContenu {
  ARTICLE = 'Article',
  PROCEDURE = 'Procedure',
  SERVICE_EN_LIGNE = 'Service en ligne',
}
