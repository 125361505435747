import { FC, useState, useEffect } from 'react';
import Card from '@codegouvfr/react-dsfr/Card';
import Button from '@codegouvfr/react-dsfr/Button';
import { ButtonsGroup } from '@codegouvfr/react-dsfr/ButtonsGroup';

import {
  Eligibilite as EligibiliteType,
  EtapeEligibiliteType,
  WidgetEtapesEligibiliteDto,
} from 'models/types/cms/widgetPageContenu/types';

interface SimulatorEligibilityProps {
  eligibilite: EligibiliteType;
}

const SimulatorEligibility: FC<SimulatorEligibilityProps> = ({ eligibilite }) => {
  const [currentEtapeReference, setCurrentEtapeReference] = useState<number | undefined>();
  const [passedEtapeReferences, setPassedEtapeReferences] = useState<number[]>([]);

  useEffect(() => {
    const premiereQuestion = Object.values(eligibilite.etapes).find(
      etape => etape.type === EtapeEligibiliteType.PREMIERE_QUESTION,
    );

    if (premiereQuestion) {
      setCurrentEtapeReference(premiereQuestion.reference);
    }
  }, [eligibilite]);

  const goToNextEtape = (nextEtapeReference: number) => {
    if (currentEtapeReference) {
      setPassedEtapeReferences(passedEtapeReferences.concat(currentEtapeReference));
      setCurrentEtapeReference(nextEtapeReference);
    }
  };

  const startAgain = () => {
    const [firstEtapeReference] = passedEtapeReferences;
    setCurrentEtapeReference(firstEtapeReference);
    setPassedEtapeReferences([]);
  };

  const getDescription = (isResult: boolean, currentEtape: WidgetEtapesEligibiliteDto) => {
    if (isResult) {
      return currentEtape.description;
    }

    if (currentEtape.type === EtapeEligibiliteType.QUESTION_RESSORTISSANT_ZONE1) {
      return eligibilite.liste_pays_ressortissant_zone1;
    }

    if (currentEtape.type === EtapeEligibiliteType.QUESTION_RESSORTISSANT_ZONE2) {
      return eligibilite.liste_pays_ressortissant_zone2;
    }
  };

  const currentEtape =
    currentEtapeReference &&
    eligibilite.etapes.find(etape => etape.reference === currentEtapeReference);

  if (!currentEtape) {
    return null;
  }

  const isResult =
    currentEtape.type === EtapeEligibiliteType.ELIGIBLE ||
    currentEtape.type === EtapeEligibiliteType.NON_ELIGIBLE;

  return (
    <div className="fr-col-12 fr-col-lg-4 fr-col-md-5 fr-mb-6v">
      <Card
        background
        border
        desc={getDescription(isResult, currentEtape)}
        footer={
          isResult ? (
            <Button
              className="fr-btn"
              onClick={startAgain}
              iconId="fr-icon-arrow-right-line"
              iconPosition="right"
            >
              {eligibilite.texte_bouton_recommencer}
            </Button>
          ) : (
            <>
              <ButtonsGroup
                alignment="left"
                buttons={[
                  {
                    children: currentEtape.aria_label_si_oui,
                    iconId: 'fr-icon-check-line',
                    iconPosition: 'right',
                    onClick: () => goToNextEtape(currentEtape.reference_suivante_si_oui),
                  },
                  {
                    children: currentEtape.aria_label_si_non,
                    iconId: 'fr-icon-close-line',
                    iconPosition: 'right',
                    onClick: () => goToNextEtape(currentEtape.reference_suivante_si_non),
                    priority: 'secondary',
                  },
                ]}
                buttonsIconPosition="left"
                buttonsSize="medium"
                inlineLayoutWhen="always"
              />
            </>
          )
        }
        size="medium"
        title={isResult ? eligibilite.resultat : currentEtape.description}
        titleAs="h5"
      />
    </div>
  );
};

export default SimulatorEligibility;
