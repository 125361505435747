export const enfantDefaultValues = {
  rf_demande: '',
  enfant_etranger: '',
  sexe_enfant: '',
  nom_naissance_enfant: '',
  prenoms_enfant: '',
  nom_usage_enfant: '',
  date_naissance_enfant: '',
  pays_naissance_enfant: '',
  ville_naissance_enfant: '',
  nationalite_enfant: '',
  acte_naissance_enfant_files: [],
  acte_naissance_enfant_traduit: false,
  acte_naissance_enfant_traduit_files: [],
  filiation: '',
  kafala_algerien_files: [],
  kafala_algerien_traduit: false,
  kafala_algerien_traduit_files: [],
  jugement_adoption_files: [],
  jugement_adoption_traduit: false,
  jugement_adoption_traduit_files: [],
  lien_second_parent: '',
  livret_famille_files: [],
  jugement_droit_garde_enfants_files: [],
  jugement_droit_garde_enfants_traduit: false,
  jugement_droit_garde_enfants_traduit_files: [],
  autre_parent_autorisation_venue_france_files: [],
  autre_parent_autorisation_venue_france_traduit: false,
  autre_parent_autorisation_venue_france_traduit_files: [],
  acte_deces_files: [],
  acte_deces_traduit: false,
  acte_deces_traduit_files: [],
  declaration_disparition_autre_parent_files: [],
  declaration_disparition_autre_parent_traduit: false,
  declaration_disparition_autre_parent_traduit_files: [],
};
