import { FormComponentsEnrichedText } from 'components/Atoms/HtmlParsers/FormComponentHtmlParser';
import { DemandeRfBloc5 } from 'models/types/cms/demandeRF/type';
import { API_BASE_URL } from 'constants/api';
import {
  setEngagementArt4211,
  setEngagementCertifieRenseignementsExact,
  setEngagementChangementSituation,
  setEngagementDeclareRfNonPolygamie,
  setEngagementRgpd,
  setEngagementSignatureDocument,
  setHaveNumFix,
} from 'redux/demandeRf';

export class CommitmentsMapping {
  static getGeneralMapping() {
    function getFormFields(
      bloc5Cms: DemandeRfBloc5,
      dispatch: any,
      formControls: {
        setValue: any;
        getValues: any;
        control: any;
        trigger: any;
      },
    ) {
      const { setValue, control, trigger, getValues } = formControls;

      return {
        certifie_info_exacts: {
          name: 'certifie_info_exacts',
          key: 'certifie_info_exacts',
          options: [
            {
              label: FormComponentsEnrichedText(bloc5Cms.checkbox_certifie_info_exacts),
              id: 'certifie_info_exacts',
              nativeInputProps: {
                name: 'certifie_info_exacts',
                onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                  setValue('certifie_info_exacts', e.target.checked);
                  trigger('certifie_info_exacts');
                  dispatch(setEngagementCertifieRenseignementsExact(e.target.checked));
                },
                checked: getValues('certifie_info_exacts'),
              },
            },
          ],

          control: control,
          required: true,
        },
        changements_a_ladministration: {
          name: 'changements_a_ladministration',
          key: 'changements_a_ladministration',
          options: [
            {
              label: FormComponentsEnrichedText(bloc5Cms.checkbox_changement_a_ladministration),
              id: 'changements_a_ladministration',
              nativeInputProps: {
                name: 'changements_a_ladministration',
                onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                  setValue('changements_a_ladministration', e.target.checked);
                  trigger('changements_a_ladministration');
                  dispatch(setEngagementChangementSituation(e.target.checked));
                },
                checked: getValues('changements_a_ladministration'),
              },
            },
          ],
          control: control,
          required: true,
        },
        certifie_non_polygamie: {
          name: 'certifie_non_polygamie',
          key: 'certifie_non_polygamie',
          options: [
            {
              label: FormComponentsEnrichedText(bloc5Cms.checkbox_certifie_non_polygamie),
              id: 'certifie_non_polygamie',
              nativeInputProps: {
                name: 'certifie_non_polygamie',
                onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                  setValue('certifie_non_polygamie', e.target.checked);
                  trigger('certifie_non_polygamie');
                  dispatch(setEngagementDeclareRfNonPolygamie(e.target.checked));
                },
                checked: getValues('certifie_non_polygamie'),
              },
            },
          ],
          control: control,
          required: true,
        },
        article_code_entree_sejour: {
          name: 'article_code_entree_sejour',
          key: 'article_code_entree_sejour',
          options: [
            {
              label: FormComponentsEnrichedText(bloc5Cms.checkbox_article_code_entree_sejour),
              id: 'article_code_entree_sejour',
              nativeInputProps: {
                name: 'article_code_entree_sejour',
                onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                  setValue('article_code_entree_sejour', e.target.checked);
                  trigger('article_code_entree_sejour');
                  dispatch(setEngagementArt4211(e.target.checked));
                },
                checked: getValues('article_code_entree_sejour'),
              },
            },
          ],
          control: control,
          required: true,
        },
        certifie_avoir_lu_ensemble_engagement: {
          name: 'certifie_avoir_lu_ensemble_engagement',
          key: 'certifie_avoir_lu_ensemble_engagement',
          options: [
            {
              label: FormComponentsEnrichedText(
                bloc5Cms.checkbox_certifie_avoir_lu_ensemble_engagement,
              ),
              id: 'certifie_avoir_lu_ensemble_engagement',
              nativeInputProps: {
                name: 'certifie_avoir_lu_ensemble_engagement',
                onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                  setValue('certifie_avoir_lu_ensemble_engagement', e.target.checked);
                  trigger('certifie_avoir_lu_ensemble_engagement');
                  dispatch(setEngagementSignatureDocument(e.target.checked));
                },
                checked: getValues('certifie_avoir_lu_ensemble_engagement'),
              },
            },
          ],
          control: control,
          required: true,
        },
        acceptation_rgpd: {
          name: 'acceptation_rgpd',
          key: 'acceptation_rgpd',
          options: [
            {
              label: FormComponentsEnrichedText(bloc5Cms.checkbox_acceptation_rgpd),
              id: 'acceptation_rgpd',
              nativeInputProps: {
                name: 'acceptation_rgpd',
                onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                  setValue('acceptation_rgpd', e.target.checked);
                  trigger('acceptation_rgpd');
                  dispatch(setEngagementRgpd(e.target.checked));
                },
                checked: getValues('acceptation_rgpd'),
              },
            },
          ],
          control: control,
          required: true,
        },
        have_num_fix: {
          name: 'checkbox_num_fixe',
          key: 'checkbox_num_fixe',
          options: [
            {
              label: bloc5Cms.checkbox_numero_fixe,
              id: 'checkbox_num_fixe',
              nativeInputProps: {
                name: 'checkbox_num_fixe',
                onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                  setValue('checkbox_num_fixe', e.target.checked);
                  trigger('checkbox_num_fixe');
                  dispatch(setHaveNumFix(e.target.checked));
                },
                checked: getValues('checkbox_num_fixe'),
              },
            },
          ],
          control: control,
          customClass: 'fr-mt-4v fr-mt-lg-0v fr-mt-md-0v cust-mb--4v',
          large: true,
        },
        num_portable: {
          name: 'num_portable',
          label: bloc5Cms.numero_portable.titre,
          hintText: bloc5Cms.numero_portable.sous_titre,
          control: control,
          required: true,
          phoneSettings: true,
          large: true,
          customClass: 'fr-mt-4v fr-mt-lg-0v fr-mt-md-0v',
        },
        num_fix: {
          name: 'num_fix',
          label: bloc5Cms.numero_fixe.titre,
          hintText: bloc5Cms.numero_fixe.sous_titre,
          control: control,
          phoneSettings: true,
          customClass: 'fr-col-12 fr-mt-4v fr-mt-lg-0v fr-mt-md-0v',
        },
        email: {
          name: 'email',
          label: bloc5Cms.adresse_email.titre,
          hintText: bloc5Cms.adresse_email.sous_titre,
          control: control,
          required: true,
          emailSettings: true,
        },
        download_rgpd: {
          customClass: 'fr-col-12 fr-mt-8v fr-col-lg-5 fr-col-md-6',
          label: bloc5Cms.tuile_rgpd_titre,
          details: bloc5Cms.tuile_rgpd_taille_fichier,
          href: API_BASE_URL + '/api/static/documents/ofii-rf-rgpd',
        },
      };
    }

    return { getFormFields };
  }
}
