export const MAX_FILE_SIZE = 20480; //20Mo

export enum ACCEPT_MIME_TYPE {
  JPG = 'image/jpeg',
  PDF = 'application/pdf',
  PNG = 'image/png',
}

export const ACCEPT_MIME_TYPE_LIST = [
  ACCEPT_MIME_TYPE.JPG,
  ACCEPT_MIME_TYPE.PDF,
  ACCEPT_MIME_TYPE.PNG,
];

export enum RFNG_CAT_MAPPING {
  LETTRE_EXPLICATIVE_RF_PARTIEL = 'rfpa_lettre_explication_rf_partiel',
  ACTE_NAISSANCE_CONJOINT = 'etci_acte_naissance_conjoint',
  ACTE_NAISSANCE_CONJOINT_TRAD = 'etci_acte_naissance_conjoint_trad',
  TITRE_IDENTITE_CONJOINT = 'etci_conjoint_titre_identite',
  TITRE_SEJOUR_CONJOINT = 'etci_conjoint_titre_sejour',
  JUGEMENT_DIVORCE_CONJOINT = 'etci_conjoi_jugement_divorce',
  JUGEMENT_DIVORCE_CONJOINT_TRAD = 'etci_conjoi_jugement_divorce_trad',
  ACTE_NAISSANCE_ENFANT = 'etci_acte_naissance_enfant',
  ACTE_NAISSANCE_ENFANT_TRAD = 'etci_acte_naissance_enfant_trad',
  KAFALA_ALGERIEN = 'etci_kafala_algerien',
  KAFALA_ALGERIEN_TRAD = 'etci_kafala_algerien_trad',
  JUGEMENT_ADOPTION = 'etci_jugement_adoption',
  JUGEMENT_ADOPTION_TRAD = 'etci_jugement_adoption_trad',
  LIVRET_FAMILLE = 'etci_livret_famille',
  JUGEMENT_AUTORITE_PARENTALE = 'etci_jugement_autorite_parentale',
  JUGEMENT_AUTORITE_PARENTALE_TRAD = 'etci_jugement_autorite_parentale_trad',
  AUTRE_PARENT_AUTORISATION_VENUE_FRANCE = 'etci_lettre_autre_parent_autorisation_venue_enfant',
  AUTRE_PARENT_AUTORISATION_VENUE_FRANCE_TRAD = 'etci_lettre_autre_parent_autorisation_venue_enfant_trad',
  ACTE_DECES_AUTRE_PARENT = 'etci_acte_deces_autre_parent',
  ACTE_DECES_AUTRE_PARENT_TRAD = 'etci_acte_deces_autre_parent_trad',
  DECLARATION_ABANDON_ENFANT = 'etci_declaration_abandon_enfant',
  DECLARATION_ABANDON_ENFANT_TRAD = 'etci_declaration_abandon_enfant_trad',
  UNION_JUGEMENT_DIVORCE = 'etci_union_jugement_divorce',
  UNION_JUGEMENT_DIVORCE_TRAD = 'etci_union_jugement_divorce_trad',
  ATTESTATION_DISSOLUTION_PACS = 'etci_attestation_dissolution_pacs',
}
