/* eslint-disable max-lines */
/* eslint-disable complexity */
/* eslint-disable react-hooks/rules-of-hooks */
import {
  useGetUtilitaireCmsQuery,
  useGetGlobalCmsQuery,
  useGetErrorCmsQuery,
  useGetRecapitulatifCmsQuery,
  useGetBloc1CmsQuery,
  useGetBloc1FormCmsQuery,
  useGetBloc2CmsQuery,
  useGetBloc2FormCmsQuery,
  useGetBloc3CmsQuery,
  useGetBloc3FormCmsQuery,
  useGetBloc4CmsQuery,
  useGetBloc4FormCmsQuery,
  useGetBloc5FormCmsQuery,
} from 'redux/rtk/cms/cmsDemandeRF';
import {
  utilitaireQuery,
  globalQuery,
  errorQuery,
  recapitulatifQuery,
  bloc1Query1,
  bloc1Query2,
  bloc1Query3,
  bloc1Query4,
  bloc1Query5,
  bloc1Query6,
  bloc1FormQuery1,
  bloc1FormQuery2,
  bloc1FormQuery3,
  bloc2Query,
  bloc2FormQuery,
  bloc3Query,
  bloc3FormQuery,
  bloc4Query,
  bloc4FormQuery,
  bloc5FormQuery,
} from 'pages/DemandeRF/Queries';
import {
  bloc3SecondQuery,
  bloc3ThirdQuery,
  bloc3FourthQuery,
  bloc3FifthQuery,
  bloc3SixthQuery,
} from 'pages/DemandeRF/Queries/SubQueries/bloc3.query';
import {
  bloc4ActivitiesFirstPartQuery,
  bloc4ActivitiesSecondPartQuery,
  bloc4InfobulleFirstQuery,
  bloc4InfobulleSecondQuery,
  bloc4InfobulleThirdQuery,
  bloc4InfobulleFourthQuery,
  bloc4InfobulleFifthQuery,
  bloc4InfobulleSixthQuery,
  bloc4InfobulleSeventhQuery,
  bloc4SecondQuery,
  bloc4ActivitiesThirdPartQuery,
} from 'pages/DemandeRF/Queries/SubQueries/bloc4.query';
import {
  bloc2SecondQuery,
  bloc2ThirdQuery,
  bloc2FourthQuery,
  bloc2FifthQuery,
  bloc2SixthQuery,
  bloc2SeventhQuery,
  bloc2FormSecondQuery,
  bloc2EighthQuery,
  bloc2NinethQuery,
  bloc2TenthQuery,
  bloc2EleventhQuery,
  bloc2TwelthQuery,
  bloc2TherteenthQuery,
  bloc2FourteethQuery,
  bloc2FifteenthQuery,
  bloc2SixteenthQuery,
  bloc2FormThridQuery,
  bloc2SeventeenthQuery,
} from '../../Queries/SubQueries/bloc2.query';

export const getRfCmsContents = (lang: string) => {
  const {
    data: errorCms,
    error: errorCmsError = false,
    isLoading: isErrorCmsLoading = true,
  } = useGetErrorCmsQuery({
    qs: errorQuery,
    locale: lang !== '' ? lang : 'fr',
  });

  const {
    data: utilitaireCms,
    error: utilitaireCmsError = false,
    isLoading: isUtilitaireCmsLoading = true,
  } = useGetUtilitaireCmsQuery({
    qs: utilitaireQuery,
    locale: lang !== '' ? lang : 'fr',
  });

  const {
    data: globalCms,
    error: globalCmsError = false,
    isLoading: isGlobalCmsLoading = true,
  } = useGetGlobalCmsQuery({
    qs: globalQuery,
    locale: lang !== '' ? lang : 'fr',
  });

  const {
    data: recapitulatifCms,
    error: recapitulatifCmsError = false,
    isLoading: isrecapitulatifCmsLoading = true,
  } = useGetRecapitulatifCmsQuery({
    qs: recapitulatifQuery,
    locale: lang !== '' ? lang : 'fr',
  });

  const {
    data: bloc1Cms1,
    error: bloc1CmsError1 = false,
    isLoading: isBloc1CmsLoading1 = true,
  } = useGetBloc1CmsQuery({
    qs: bloc1Query1,
    locale: lang !== '' ? lang : 'fr',
  });

  const {
    data: bloc1Cms2,
    error: bloc1CmsError2 = false,
    isLoading: isBloc1CmsLoading2 = true,
  } = useGetBloc1CmsQuery({
    qs: bloc1Query2,
    locale: lang !== '' ? lang : 'fr',
  });

  const {
    data: bloc1Cms3,
    error: bloc1CmsError3 = false,
    isLoading: isBloc1CmsLoading3 = true,
  } = useGetBloc1CmsQuery({
    qs: bloc1Query3,
    locale: lang !== '' ? lang : 'fr',
  });

  const {
    data: bloc1Cms4,
    error: bloc1CmsError4 = false,
    isLoading: isBloc1CmsLoading4 = true,
  } = useGetBloc1CmsQuery({
    qs: bloc1Query4,
    locale: lang !== '' ? lang : 'fr',
  });

  const {
    data: bloc1Cms5,
    error: bloc1CmsError5 = false,
    isLoading: isBloc1CmsLoading5 = true,
  } = useGetBloc1CmsQuery({
    qs: bloc1Query5,
    locale: lang !== '' ? lang : 'fr',
  });

  const {
    data: bloc1Cms6,
    error: bloc1CmsError6 = false,
    isLoading: isBloc1CmsLoading6 = true,
  } = useGetBloc1CmsQuery({
    qs: bloc1Query6,
    locale: lang !== '' ? lang : 'fr',
  });

  const {
    data: bloc1FormCms1,
    error: bloc1FormCmsError1 = false,
    isLoading: isBloc1FormCmsLoading1 = true,
  } = useGetBloc1FormCmsQuery({
    qs: bloc1FormQuery1,
    locale: lang !== '' ? lang : 'fr',
  });

  const {
    data: bloc1FormCms2,
    error: bloc1FormCmsError2 = false,
    isLoading: isBloc1FormCmsLoading2 = true,
  } = useGetBloc1FormCmsQuery({
    qs: bloc1FormQuery2,
    locale: lang !== '' ? lang : 'fr',
  });

  const {
    data: bloc1FormCms3,
    error: bloc1FormCmsError3 = false,
    isLoading: isBloc1FormCmsLoading3 = true,
  } = useGetBloc1FormCmsQuery({
    qs: bloc1FormQuery3,
    locale: lang !== '' ? lang : 'fr',
  });

  const {
    data: bloc2Cms,
    error: bloc2CmsError = false,
    isLoading: isBloc2CmsLoading = true,
  } = useGetBloc2CmsQuery({
    qs: bloc2Query,
    locale: lang !== '' ? lang : 'fr',
  });

  const {
    data: bloc2Cms2,
    error: bloc2Cms2Error = false,
    isLoading: isBloc2Cms2Loading = true,
  } = useGetBloc2CmsQuery({
    qs: bloc2SecondQuery,
    locale: lang !== '' ? lang : 'fr',
  });

  const {
    data: bloc2Cms3,
    error: bloc2Cms3Error = false,
    isLoading: isBloc2Cms3Loading = true,
  } = useGetBloc2CmsQuery({
    qs: bloc2ThirdQuery,
    locale: lang !== '' ? lang : 'fr',
  });

  const {
    data: bloc2Cms4,
    error: bloc2Cms4Error = false,
    isLoading: isBloc2Cms4Loading = true,
  } = useGetBloc2CmsQuery({
    qs: bloc2FourthQuery,
    locale: lang !== '' ? lang : 'fr',
  });

  const {
    data: bloc2Cms5,
    error: bloc2Cms5Error = false,
    isLoading: isBloc2Cms5Loading = true,
  } = useGetBloc2CmsQuery({
    qs: bloc2FifthQuery,
    locale: lang !== '' ? lang : 'fr',
  });

  const {
    data: bloc2Cms6,
    error: bloc2Cms6Error = false,
    isLoading: isBloc2Cms6Loading = true,
  } = useGetBloc2CmsQuery({
    qs: bloc2SixthQuery,
    locale: lang !== '' ? lang : 'fr',
  });

  const {
    data: bloc2Cms7,
    error: bloc2Cms7Error = false,
    isLoading: isBloc2Cms7Loading = true,
  } = useGetBloc2CmsQuery({
    qs: bloc2SeventhQuery,
    locale: lang !== '' ? lang : 'fr',
  });

  const {
    data: bloc2Cms8,
    error: bloc2Cms8Error = false,
    isLoading: isBloc2Cms8Loading = true,
  } = useGetBloc2CmsQuery({
    qs: bloc2EighthQuery,
    locale: lang !== '' ? lang : 'fr',
  });

  const {
    data: bloc2Cms9,
    error: bloc2Cms9Error = false,
    isLoading: isBloc2Cms9Loading = true,
  } = useGetBloc2CmsQuery({
    qs: bloc2NinethQuery,
    locale: lang !== '' ? lang : 'fr',
  });

  const {
    data: bloc2Cms10,
    error: bloc2Cms10Error = false,
    isLoading: isBloc2Cms10Loading = true,
  } = useGetBloc2CmsQuery({
    qs: bloc2TenthQuery,
    locale: lang !== '' ? lang : 'fr',
  });

  const {
    data: bloc2Cms11,
    error: bloc2Cms11Error = false,
    isLoading: isBloc2Cms11Loading = true,
  } = useGetBloc2CmsQuery({
    qs: bloc2EleventhQuery,
    locale: lang !== '' ? lang : 'fr',
  });

  const {
    data: bloc2Cms12,
    error: bloc2Cms12Error = false,
    isLoading: isBloc2Cms12Loading = true,
  } = useGetBloc2CmsQuery({
    qs: bloc2TwelthQuery,
    locale: lang !== '' ? lang : 'fr',
  });

  const {
    data: bloc2Cms13,
    error: bloc2Cms13Error = false,
    isLoading: isBloc2Cms13Loading = true,
  } = useGetBloc2CmsQuery({
    qs: bloc2TherteenthQuery,
    locale: lang !== '' ? lang : 'fr',
  });

  const {
    data: bloc2Cms14,
    error: bloc2Cms14Error = false,
    isLoading: isBloc2Cms14Loading = true,
  } = useGetBloc2CmsQuery({
    qs: bloc2FourteethQuery,
    locale: lang !== '' ? lang : 'fr',
  });
  const {
    data: bloc2Cms15,
    error: bloc2Cms15Error = false,
    isLoading: isBloc2Cms15Loading = true,
  } = useGetBloc2CmsQuery({
    qs: bloc2FifteenthQuery,
    locale: lang !== '' ? lang : 'fr',
  });
  const {
    data: bloc2Cms16,
    error: bloc2Cms16Error = false,
    isLoading: isBloc2Cms16Loading = true,
  } = useGetBloc2CmsQuery({
    qs: bloc2SixteenthQuery,
    locale: lang !== '' ? lang : 'fr',
  });
  const {
    data: bloc2Cms17,
    error: bloc2Cms17Error = false,
    isLoading: isBloc2Cms17Loading = true,
  } = useGetBloc2CmsQuery({
    qs: bloc2SeventeenthQuery,
    locale: lang !== '' ? lang : 'fr',
  });

  const {
    data: bloc2FormCms,
    error: bloc2FormCmsError = false,
    isLoading: isBloc2FormCmsLoading = true,
  } = useGetBloc2FormCmsQuery({
    qs: bloc2FormQuery,
    locale: lang !== '' ? lang : 'fr',
  });

  const {
    data: bloc2FormCms2,
    error: bloc2FormCms2Error = false,
    isLoading: isBloc2FormCms2Loading = true,
  } = useGetBloc2FormCmsQuery({
    qs: bloc2FormSecondQuery,
    locale: lang !== '' ? lang : 'fr',
  });

  const {
    data: bloc2FormCms3,
    error: bloc2FormCms3Error = false,
    isLoading: isBloc2FormCms3Loading = true,
  } = useGetBloc2FormCmsQuery({
    qs: bloc2FormThridQuery,
    locale: lang !== '' ? lang : 'fr',
  });

  const {
    data: bloc3Cms,
    error: bloc3CmsError = false,
    isLoading: isBloc3CmsLoading = true,
  } = useGetBloc3CmsQuery({
    qs: bloc3Query,
    locale: lang !== '' ? lang : 'fr',
  });

  const {
    data: bloc3Cms2,
    error: bloc3Cms2Error = false,
    isLoading: isBloc3Cms2Loading = true,
  } = useGetBloc3CmsQuery({
    qs: bloc3SecondQuery,
    locale: lang !== '' ? lang : 'fr',
  });

  const {
    data: bloc3Cms3,
    error: bloc3Cms3Error = false,
    isLoading: isBloc3Cms3Loading = true,
  } = useGetBloc3CmsQuery({
    qs: bloc3ThirdQuery,
    locale: lang !== '' ? lang : 'fr',
  });

  const {
    data: bloc3Cms4,
    error: bloc3Cms4Error = false,
    isLoading: isBloc3Cms4Loading = true,
  } = useGetBloc3CmsQuery({
    qs: bloc3FourthQuery,
    locale: lang !== '' ? lang : 'fr',
  });

  const {
    data: bloc3Cms5,
    error: bloc3Cms5Error = false,
    isLoading: isBloc3Cms5Loading = true,
  } = useGetBloc3CmsQuery({
    qs: bloc3FifthQuery,
    locale: lang !== '' ? lang : 'fr',
  });

  const {
    data: bloc3Cms6,
    error: bloc3Cms6Error = false,
    isLoading: isBloc3Cms6Loading = true,
  } = useGetBloc3CmsQuery({
    qs: bloc3SixthQuery,
    locale: lang !== '' ? lang : 'fr',
  });

  const {
    data: bloc3FormCms,
    error: bloc3FormCmsError = false,
    isLoading: isBloc3FormCmsLoading = true,
  } = useGetBloc3FormCmsQuery({
    qs: bloc3FormQuery,
    locale: lang !== '' ? lang : 'fr',
  });

  const {
    data: bloc4Cms,
    error: bloc4CmsError = false,
    isLoading: isBloc4CmsLoading = true,
  } = useGetBloc4CmsQuery({
    qs: bloc4Query,
    locale: lang !== '' ? lang : 'fr',
  });

  const {
    data: bloc4Cms2,
    error: bloc4Cms2Error = false,
    isLoading: isBloc4Cms2Loading = true,
  } = useGetBloc4CmsQuery({
    qs: bloc4SecondQuery,
    locale: lang !== '' ? lang : 'fr',
  });

  const {
    data: bloc4ActivitiesFirstPartCms,
    error: bloc4ActivitiesFirstPartCmsError = false,
    isLoading: isBloc4ActivitiesFirstPartCmsLoading = true,
  } = useGetBloc4CmsQuery({
    qs: bloc4ActivitiesFirstPartQuery,
    locale: lang !== '' ? lang : 'fr',
  });

  const {
    data: bloc4ActivitiesSecondPartCms,
    error: bloc4ActivitiesSecondPartCmsError = false,
    isLoading: isBloc4ActivitiesSecondPartCmsLoading = true,
  } = useGetBloc4CmsQuery({
    qs: bloc4ActivitiesSecondPartQuery,
    locale: lang !== '' ? lang : 'fr',
  });

  const {
    data: bloc4ActivitiesThirdPartCms,
    error: bloc4ActivitiesThirdPartCmsError = false,
    isLoading: isBloc4ActivitiesThirdPartCmsLoading = true,
  } = useGetBloc4CmsQuery({
    qs: bloc4ActivitiesThirdPartQuery,
    locale: lang !== '' ? lang : 'fr',
  });

  const {
    data: bloc4InfobullesFirstPartCms,
    error: bloc4InfobullesFirstPartCmsError = false,
    isLoading: isBloc4InfobullesFirstPartCmsLoading = true,
  } = useGetBloc4CmsQuery({
    qs: bloc4InfobulleFirstQuery,
    locale: lang !== '' ? lang : 'fr',
  });

  const {
    data: bloc4InfobullesSecondPartCms,
    error: bloc4InfobullesSecondPartCmsError = false,
    isLoading: isBloc4InfobullesSecondPartCmsLoading = true,
  } = useGetBloc4CmsQuery({
    qs: bloc4InfobulleSecondQuery,
    locale: lang !== '' ? lang : 'fr',
  });

  const {
    data: bloc4InfobullesThirdPartCms,
    error: bloc4InfobullesThirdPartCmsError = false,
    isLoading: isBloc4InfobullesThirdPartCmsLoading = true,
  } = useGetBloc4CmsQuery({
    qs: bloc4InfobulleThirdQuery,
    locale: lang !== '' ? lang : 'fr',
  });

  const {
    data: bloc4InfobullesFourthPartCms,
    error: bloc4InfobullesFourthPartCmsError = false,
    isLoading: isBloc4InfobullesFourthPartCmsLoading = true,
  } = useGetBloc4CmsQuery({
    qs: bloc4InfobulleFourthQuery,
    locale: lang !== '' ? lang : 'fr',
  });

  const {
    data: bloc4InfobullesFifthPartCms,
    error: bloc4InfobullesFifthPartCmsError = false,
    isLoading: isBloc4InfobullesFifthPartCmsLoading = true,
  } = useGetBloc4CmsQuery({
    qs: bloc4InfobulleFifthQuery,
    locale: lang !== '' ? lang : 'fr',
  });

  const {
    data: bloc4InfobullesSixthPartCms,
    error: bloc4InfobullesSixthPartCmsError = false,
    isLoading: isBloc4InfobullesSixthPartCmsLoading = true,
  } = useGetBloc4CmsQuery({
    qs: bloc4InfobulleSixthQuery,
    locale: lang !== '' ? lang : 'fr',
  });

  const {
    data: bloc4InfobullesSeventhPartCms,
    error: bloc4InfobullesSeventhPartCmsError = false,
    isLoading: isBloc4InfobullesSeventhPartCmsLoading = true,
  } = useGetBloc4CmsQuery({
    qs: bloc4InfobulleSeventhQuery,
    locale: lang !== '' ? lang : 'fr',
  });

  const {
    data: bloc4FormCms,
    error: bloc4FormCmsError = false,
    isLoading: isBloc4FormCmsLoading = true,
  } = useGetBloc4FormCmsQuery({
    qs: bloc4FormQuery,
    locale: lang !== '' ? lang : 'fr',
  });

  const {
    data: bloc5FormCms,
    error: bloc5FormCmsError = false,
    isLoading: isBloc5FormCmsLoading = true,
  } = useGetBloc5FormCmsQuery({
    qs: bloc5FormQuery,
    locale: lang !== '' ? lang : 'fr',
  });

  const isloading =
    isErrorCmsLoading ||
    isUtilitaireCmsLoading ||
    isGlobalCmsLoading ||
    isrecapitulatifCmsLoading ||
    isBloc1FormCmsLoading1 ||
    isBloc1FormCmsLoading2 ||
    isBloc1FormCmsLoading3 ||
    isBloc1CmsLoading1 ||
    isBloc1CmsLoading2 ||
    isBloc1CmsLoading3 ||
    isBloc1CmsLoading4 ||
    isBloc1CmsLoading5 ||
    isBloc1CmsLoading6 ||
    isBloc2FormCmsLoading ||
    isBloc2CmsLoading ||
    isBloc2Cms2Loading ||
    isBloc2Cms3Loading ||
    isBloc2Cms4Loading ||
    isBloc2Cms5Loading ||
    isBloc2Cms6Loading ||
    isBloc2Cms7Loading ||
    isBloc2Cms8Loading ||
    isBloc2Cms9Loading ||
    isBloc2Cms10Loading ||
    isBloc2Cms11Loading ||
    isBloc2Cms12Loading ||
    isBloc2Cms13Loading ||
    isBloc2Cms14Loading ||
    isBloc2Cms15Loading ||
    isBloc2Cms16Loading ||
    isBloc2Cms17Loading ||
    isBloc2FormCmsLoading ||
    isBloc2FormCms2Loading ||
    isBloc2FormCms3Loading ||
    isBloc3FormCmsLoading ||
    isBloc3Cms2Loading ||
    isBloc3CmsLoading ||
    isBloc3Cms3Loading ||
    isBloc3Cms4Loading ||
    isBloc3Cms5Loading ||
    isBloc3Cms6Loading ||
    isBloc4CmsLoading ||
    isBloc4Cms2Loading ||
    isBloc4ActivitiesFirstPartCmsLoading ||
    isBloc4ActivitiesSecondPartCmsLoading ||
    isBloc4ActivitiesThirdPartCmsLoading ||
    isBloc4InfobullesFirstPartCmsLoading ||
    isBloc4InfobullesSecondPartCmsLoading ||
    isBloc4InfobullesThirdPartCmsLoading ||
    isBloc4InfobullesFourthPartCmsLoading ||
    isBloc4InfobullesFifthPartCmsLoading ||
    isBloc4InfobullesSixthPartCmsLoading ||
    isBloc4InfobullesSeventhPartCmsLoading ||
    isBloc4FormCmsLoading ||
    isBloc5FormCmsLoading;

  const haveError =
    errorCmsError ||
    utilitaireCmsError ||
    globalCmsError ||
    recapitulatifCmsError ||
    bloc1CmsError1 ||
    bloc1CmsError2 ||
    bloc1CmsError3 ||
    bloc1CmsError4 ||
    bloc1CmsError5 ||
    bloc1CmsError6 ||
    bloc1FormCmsError1 ||
    bloc1FormCmsError2 ||
    bloc1FormCmsError3 ||
    bloc2CmsError ||
    bloc2Cms2Error ||
    bloc2Cms3Error ||
    bloc2Cms4Error ||
    bloc2Cms5Error ||
    bloc2Cms6Error ||
    bloc2Cms7Error ||
    bloc2Cms8Error ||
    bloc2Cms9Error ||
    bloc2Cms10Error ||
    bloc2Cms11Error ||
    bloc2Cms12Error ||
    bloc2Cms13Error ||
    bloc2Cms14Error ||
    bloc2Cms15Error ||
    bloc2Cms16Error ||
    bloc2Cms17Error ||
    bloc2FormCmsError ||
    bloc2FormCms2Error ||
    bloc2FormCms3Error ||
    bloc3CmsError ||
    bloc3Cms2Error ||
    bloc3Cms3Error ||
    bloc3Cms4Error ||
    bloc3Cms5Error ||
    bloc3Cms6Error ||
    bloc3FormCmsError ||
    bloc4CmsError ||
    bloc4Cms2Error ||
    bloc4ActivitiesFirstPartCmsError ||
    bloc4ActivitiesSecondPartCmsError ||
    bloc4ActivitiesThirdPartCmsError ||
    bloc4InfobullesFirstPartCmsError ||
    bloc4InfobullesSecondPartCmsError ||
    bloc4InfobullesThirdPartCmsError ||
    bloc4InfobullesFourthPartCmsError ||
    bloc4InfobullesFifthPartCmsError ||
    bloc4InfobullesSixthPartCmsError ||
    bloc4InfobullesSeventhPartCmsError ||
    bloc4FormCmsError ||
    bloc5FormCmsError;

  return {
    errorCms: errorCms?.data?.attributes ?? errorCms,
    utilitaireCms: utilitaireCms?.data?.attributes ?? utilitaireCms,
    globalCms: globalCms?.data?.attributes ?? globalCms,
    recapitulatifCms: recapitulatifCms,
    bloc1Cms: {
      ...(bloc1Cms1?.data?.attributes ?? bloc1Cms1),
      ...(bloc1Cms2?.data?.attributes ?? bloc1Cms2),
      ...(bloc1Cms3?.data?.attributes ?? bloc1Cms3),
      ...(bloc1Cms4?.data?.attributes ?? bloc1Cms4),
      ...(bloc1Cms5?.data?.attributes ?? bloc1Cms5),
      ...(bloc1Cms6?.data?.attributes ?? bloc1Cms6),
      ...(bloc1FormCms1?.data?.attributes ?? bloc1FormCms1),
      ...(bloc1FormCms2?.data?.attributes ?? bloc1FormCms2),
      ...(bloc1FormCms3?.data?.attributes ?? bloc1FormCms3),
    },
    bloc2Cms: {
      ...(bloc2Cms?.data?.attributes ?? bloc2Cms),
      ...(bloc2Cms2?.data?.attributes ?? bloc2Cms2),
      ...(bloc2Cms3?.data?.attributes ?? bloc2Cms3),
      ...(bloc2Cms4?.data?.attributes ?? bloc2Cms4),
      ...(bloc2Cms5?.data?.attributes ?? bloc2Cms5),
      ...(bloc2Cms6?.data?.attributes ?? bloc2Cms6),
      ...(bloc2Cms7?.data?.attributes ?? bloc2Cms7),
      ...(bloc2Cms8?.data?.attributes ?? bloc2Cms8),
      ...(bloc2Cms9?.data?.attributes ?? bloc2Cms9),
      ...(bloc2Cms10?.data?.attributes ?? bloc2Cms10),
      ...(bloc2Cms11?.data?.attributes ?? bloc2Cms11),
      ...(bloc2Cms12?.data?.attributes ?? bloc2Cms12),
      ...(bloc2Cms13?.data?.attributes ?? bloc2Cms13),
      ...(bloc2Cms14?.data?.attributes ?? bloc2Cms14),
      ...(bloc2Cms15?.data?.attributes ?? bloc2Cms15),
      ...(bloc2Cms16?.data?.attributes ?? bloc2Cms16),
      ...(bloc2Cms17?.data?.attributes ?? bloc2Cms17),
      ...(bloc2FormCms?.data?.attributes ?? bloc2FormCms),
      ...(bloc2FormCms2?.data?.attributes ?? bloc2FormCms2),
      ...(bloc2FormCms3?.data?.attributes ?? bloc2FormCms3),
    },
    bloc3Cms: {
      ...(bloc3Cms?.data?.attributes ?? bloc3Cms),
      ...(bloc3Cms2?.data?.attributes ?? bloc3Cms2),
      ...(bloc3Cms3?.data?.attributes ?? bloc3Cms3),
      ...(bloc3Cms4?.data?.attributes ?? bloc3Cms4),
      ...(bloc3Cms5?.data?.attributes ?? bloc3Cms5),
      ...(bloc3Cms6?.data?.attributes ?? bloc3Cms6),
      ...(bloc3FormCms?.data?.attributes ?? bloc3FormCms),
    },
    bloc4Cms: {
      ...(bloc4Cms?.data?.attributes ?? bloc4Cms),
      ...(bloc4Cms2?.data?.attributes ?? bloc4Cms2),
      ...(bloc4ActivitiesFirstPartCms?.data?.attributes ?? bloc4ActivitiesFirstPartCms),
      ...(bloc4ActivitiesSecondPartCms?.data?.attributes ?? bloc4ActivitiesSecondPartCms),
      ...(bloc4ActivitiesThirdPartCms?.data?.attributes ?? bloc4ActivitiesThirdPartCms),
      ...(bloc4InfobullesFirstPartCms?.data?.attributes ?? bloc4InfobullesFirstPartCms),
      ...(bloc4InfobullesSecondPartCms?.data?.attributes ?? bloc4InfobullesSecondPartCms),
      ...(bloc4InfobullesThirdPartCms?.data?.attributes ?? bloc4InfobullesThirdPartCms),
      ...(bloc4InfobullesFourthPartCms?.data?.attributes ?? bloc4InfobullesFourthPartCms),
      ...(bloc4InfobullesFifthPartCms?.data?.attributes ?? bloc4InfobullesFifthPartCms),
      ...(bloc4InfobullesSixthPartCms?.data?.attributes ?? bloc4InfobullesSixthPartCms),
      ...(bloc4InfobullesSeventhPartCms?.data?.attributes ?? bloc4InfobullesSeventhPartCms),
      ...(bloc4FormCms?.data?.attributes ?? bloc4FormCms),
    },
    bloc5Cms: {
      ...(bloc5FormCms?.data?.attributes ?? bloc5FormCms),
    },
    isCmsLoading: isloading,
    isCmsOnError: haveError,
  };
};
