import React, { FC } from 'react';
import { ModalProps, createModal } from '@codegouvfr/react-dsfr/Modal';
import Button from '@codegouvfr/react-dsfr/Button';

import CmsContentGenerator from '../CmsContentGenerator/CmsContentGenerator';

export type AppModalProps = {
  name: string;
  content: any;
};

const Infobulle: FC<AppModalProps> = ({ name, content }) => {
  const dynamicModal: {
    buttonProps: {
      /** Only for analytics, feel free to overwrite */
      id: string;
      'aria-controls': string;
      'data-fr-opened': boolean;
    };
    Component: (props: ModalProps) => React.JSX.Element;
    close: () => void;
    open: () => void;
    isOpenedByDefault: boolean;
    id: string;
  } = createModal({
    id: name.toLowerCase(),
    isOpenedByDefault: false,
  });

  const Modal = dynamicModal;

  if (!content?.titre) {
    return <></>;
  }

  return (
    <div
      className="fr-infobulle__container"
      onClick={e => {
        e.preventDefault();
      }}
    >
      <Modal.Component title={content.titre}>
        <CmsContentGenerator content={content} />
      </Modal.Component>
      <Button
        iconId="fr-icon-question-line"
        priority="tertiary no outline"
        {...dynamicModal.buttonProps}
        title="test"
        onClick={e => {
          e.preventDefault();
        }}
      />
    </div>
  );
};

export default Infobulle;
