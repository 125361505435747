import { cmsSlice } from './cmsSlice';

const cmsArticle = cmsSlice.injectEndpoints({
  endpoints: build => ({
    getArticlePage: build.query<any, { qs?: string; locale: string }>({
      query: ({ locale }) => ({ url: `/liste-article?locale=${locale}&populate=*` }),
    }),
  }),
});

export const { useGetArticlePageQuery } = cmsArticle;
