import { configureStore } from '@reduxjs/toolkit';
import { persistStore, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';

import { apiSlice } from './rtk/api/apiSlice';
import { keycloakSlice } from './rtk/keycloak/keycloakSlice';
import { cmsSlice } from './rtk/cms/cmsSlice';
import rootReducer from './reducers';
import { addressSlice } from './rtk/address/addressSlice';

export default function buildStore(preloadedState = {}) {
  const store = configureStore({
    // eslint-disable-next-line no-restricted-syntax
    devTools: process.env.NODE_ENV !== 'production',
    reducer: rootReducer,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }).concat(
        apiSlice?.middleware,
        cmsSlice?.middleware,
        keycloakSlice?.middleware,
        addressSlice?.middleware,
      ),
    preloadedState,
  });

  const persistor = persistStore(store);

  // eslint-disable-next-line no-restricted-syntax
  if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('./reducers', () => {
      import('./reducers').then(() => store.replaceReducer(rootReducer));
    });
  }

  return { store, persistor };
}
