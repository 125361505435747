export enum StatutVersement {
  VERSE = 'paye',
  ERREUR = 'erreur',
}

export enum StatutCmsNameKey {
  VERSE = 'verse',
  ERREUR = 'erreur',
}

export const STATUT_VERSEMENT_CMS_MAPPING = {
  [StatutVersement.VERSE]: StatutCmsNameKey.VERSE,
  [StatutVersement.ERREUR]: StatutCmsNameKey.ERREUR,
};
