import * as yup from 'yup';
import { checkInternationalPhoneNumber } from 'helpers/app/ComponentsFormHelpers';
import { FormHelper } from 'helpers/app/FormHelper';
import { EMAIL_REGEX } from 'helpers/app/regexHelper';
import {
  DemandeRfBloc5,
  DemandeRfErrors,
  DemandeRfUtilitaire,
} from 'models/types/cms/demandeRF/type';

export const getCommitmentsValidationSchema = (
  bloc5Cms: DemandeRfBloc5,
  erreursRfCms: DemandeRfErrors,
  utilitaireCms: DemandeRfUtilitaire,
) => {
  const formHelper = new FormHelper(erreursRfCms);

  return yup
    .object()
    .shape({
      certifie_info_exacts: formHelper
        .requiredBoolSchema()
        .oneOf([true], utilitaireCms.erreur_validation_cases),
      changements_a_ladministration: formHelper
        .requiredBoolSchema()
        .oneOf([true], utilitaireCms.erreur_validation_cases),
      certifie_non_polygamie: formHelper
        .requiredBoolSchema()
        .oneOf([true], utilitaireCms.erreur_validation_cases),
      article_code_entree_sejour: formHelper
        .requiredBoolSchema()
        .oneOf([true], utilitaireCms.erreur_validation_cases),
      certifie_avoir_lu_ensemble_engagement: formHelper
        .requiredBoolSchema()
        .oneOf([true], utilitaireCms.erreur_validation_cases),
      acceptation_rgpd: formHelper
        .requiredBoolSchema()
        .oneOf([true], utilitaireCms.erreur_validation_cases),
      num_portable: formHelper
        .stringSchema(false)
        .when('checkbox_num_fixe', ([checkboxNumFixe]) => {
          return checkboxNumFixe === false
            ? formHelper
                .stringSchema(true, bloc5Cms.numero_portable.titre)
                .test(utilitaireCms.format_telephone, erreursRfCms.erreur_telephone, tel => {
                  return tel ? checkInternationalPhoneNumber(tel) : false;
                })
            : formHelper.stringSchema(false);
        }),

      email: formHelper
        .stringSchema(true, bloc5Cms.adresse_email.titre)
        .test(utilitaireCms.format_email, erreursRfCms.erreur_email, email => {
          if (email) {
            return EMAIL_REGEX.test(email);
          }
          return false;
        }),
      num_fix: formHelper.stringSchema(false).when('checkbox_num_fixe', ([checkboxNumFixe]) => {
        return checkboxNumFixe === true
          ? formHelper
              .stringSchema(true, bloc5Cms.numero_fixe.titre)
              .test(utilitaireCms.format_telephone, erreursRfCms.erreur_telephone, tel => {
                return tel ? checkInternationalPhoneNumber(tel) : false;
              })
          : formHelper.stringSchema(false);
      }),
      checkbox_num_fixe: yup.boolean().notRequired(),
      //   validation: yup.string().notRequired(),
      //   [HONEYPOT_NAME]: yup.string(),
    })
    .defined();
};
