import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
//import { Alert } from '@codegouvfr/react-dsfr/Alert';

import { Logement } from 'models/types/api/rf';
import { AccomodationDemandeForm } from 'models/types/app/demandeRf';

import {
  getAccomodationFormToSubmit,
  getDemandeId,
  getListDocumentsByLogement,
  getLogementsStore,
} from 'redux/demandeRf/selectors';
import { createError, createSuccess, submitForm } from 'redux/forms';
import { useUpdDemandeMutation } from 'redux/rtk/api/apiRf';

import {
  addressFieldNeedHydratation,
  handleArrayHydratation,
  handleFieldHydratation,
} from 'helpers/api/FormHydratation';

import CustomAlert from 'components/Atoms/CustomAlert/CustomAlert';
import FormLayout from 'components/Molecules/DemandeRf/FormLayout/FormLayout';
import CommonRowContainer from 'components/Molecules/Forms/CommonRowContainer/CommonRowContainer';
import RadioButtonContainer from 'components/Molecules/Forms/RadioButtonContainer/RadioButtonContainer';

import { getRfCmsContents } from 'pages/DemandeRF/Helpers/Cms/getRfCmsContents';

import {
  GetFieldArrayMapping,
  filterLogement,
  generateBanoAutreForSubmit,
  generateBanoPrincipalForSubmit,
  getDocumentsByCategory,
  handleLogementData,
} from './Accomodation.helper';
import GetAccomodationUseForm from './Accomodation.UseForm';
import { AccomodationMapping } from './Accomodation.mapping';
import { getAccomodationValidationSchema } from './Accomodation.validationSchema';

import AddressPanel from './AdressePanel/AdressePanel';
import AccomodationSituationPanel from './AccomodationSituationPanel/AccomodationSituationPanel';

import 'pages/DemandeRF/DemandeRF.scss';
import './Accomodation.scss';
import { useFormTouched } from 'pages/DemandeRF/DemandeRF.utils';
import {
  setAccomodationUntouched,
  setAccomodationValidation,
  updateAccomodation,
} from 'redux/demandeRf';
import { formatAccomodationStoreFromDemande } from 'helpers/api/apiToStore';

type AccomodationProps = {
  lang: string;
  saveFormProgress: boolean;
  setSaveFormProgress: React.Dispatch<React.SetStateAction<boolean>>;
  scrollToTablist: () => void;
};

const Accomodation: React.FC<AccomodationProps> = ({
  lang,
  saveFormProgress,
  setSaveFormProgress,
  scrollToTablist,
}) => {
  const { bloc3Cms, utilitaireCms, globalCms, errorCms } = getRfCmsContents(lang);

  const debugAccomodation: any = {
    all: false,
    currentAccomodationSituation: false,
    currentAccomodationAdresse: false,
    futurAccomodationSituation: false,
    futurAccomodationadresse: false,
    loadingData: {
      apiData: false,
      cmsData: false,
      formDataSetting: false,
      formValuesAfterLoad: false,
    },
    changeData: {
      effects: false,
    },
    submitData: {
      formValues: false,
      sentData: false,
    },
  };

  const demandeId = useSelector(getDemandeId);
  const demandeLogements = useSelector(getLogementsStore);
  const dispatch = useDispatch();

  const [updateDemande, { isLoading, isError, data, isSuccess }] = useUpdDemandeMutation();

  const [demandeForm, setDemandeForm] = useState<AccomodationDemandeForm | null>(null);
  const [logementActuelValue, setLogementActuelValue] = useState<'non' | 'oui' | undefined>(
    undefined,
  );

  const logements = demandeLogements.accomodations;
  const logementPrincipal = filterLogement(logements, true);
  const logementAutre = filterLogement(logements, false);
  const makeListDocumentsByLogementPrincipal = useMemo(
    () => getListDocumentsByLogement(logementPrincipal?.id ? logementPrincipal?.id : null),
    [logementPrincipal?.id],
  );
  const documentsLogementPrincipal = useSelector(makeListDocumentsByLogementPrincipal);

  // const [futurAccomodationSituation, setFuturAccomodationSituation] = useState('');

  const makeListDocumentsByLogementAutre = useMemo(
    () => getListDocumentsByLogement(logementAutre?.id ? logementAutre?.id : null),
    [logementAutre?.id],
  );
  const documentsLogementAutre = useSelector(makeListDocumentsByLogementAutre);
  const accommodationFormToSubmit = useSelector(getAccomodationFormToSubmit);

  const {
    // handleSubmit,
    getValues,
    setValue,
    //getFieldState,
    trigger,
    watch,
    formState: { errors, isValid, touchedFields },
    control,
    handleSubmit,
  } = GetAccomodationUseForm(errorCms, bloc3Cms, utilitaireCms);

  const formIsTouched = useFormTouched(touchedFields);

  const logementFamilleWatcher = watch('logement_famille');
  const fieldArrayMapping = GetFieldArrayMapping(control);

  const filesFieldMapping = {
    ...AccomodationMapping.getGeneralMapping().get_files_fields(bloc3Cms, fieldArrayMapping),
  };

  const currentAccomodationMapping = {
    ...AccomodationMapping.getGeneralMapping().current_form_fields(
      bloc3Cms,
      utilitaireCms,
      globalCms,
      false,
      logementPrincipal,
      control,
    ),
  };

  const futurAccomodationMapping = {
    ...AccomodationMapping.getGeneralMapping().futur_form_fields(
      bloc3Cms,
      utilitaireCms,
      false,
      logementAutre,
      control,
    ),
  };

  const onAccomodationSituationChange = (value: string, key: string) => {
    const values = { ...control._formValues };
    values[key] = value;
    onSubmit({ ...values, validation: false });
  };

  const setValuesFromDemand = (formDemand: any) => {
    const onDemandeSetValues: any = {};

    for (const key in formDemand) {
      if (
        key !== 'adresse_postale' &&
        key !== 'adresse_postale_futur_logement' &&
        key !== 'ville_ou_commune' &&
        key !== 'ville_ou_commune_futur_logement' &&
        formDemand[key] !== null &&
        formDemand[key] !== undefined
      ) {
        if (Array.isArray(formDemand[key]) && formDemand[key].length > 0) {
          handleArrayHydratation(key, formDemand, fieldArrayMapping);

          if (debugAccomodation.all || debugAccomodation.loadingData.formDataSetting) {
            onDemandeSetValues[key] = formDemand[key];
          }
        } else if (!Array.isArray(formDemand[key])) {
          handleFieldHydratation(
            key,
            formDemand,
            getAccomodationValidationSchema(errorCms, bloc3Cms, utilitaireCms),
            setValue,
          );

          if (debugAccomodation.all || debugAccomodation.loadingData.formDataSetting) {
            onDemandeSetValues[key] = formDemand[key];
          }
        }
      } else if (
        formDemand[key] !== null &&
        formDemand[key] !== undefined &&
        addressFieldNeedHydratation(formDemand[key])
      ) {
        handleFieldHydratation(
          key,
          formDemand,
          getAccomodationValidationSchema(errorCms, bloc3Cms, utilitaireCms),
          setValue,
        );

        if (debugAccomodation.all || debugAccomodation.loadingData.formDataSetting) {
          onDemandeSetValues[key] = formDemand[key];
        }
      }
    }

    if (debugAccomodation.all || debugAccomodation.loadingData.formDataSetting) {
      console.log('setValuesFromDemand: result ', onDemandeSetValues);
    }
  };

  const generateDemande = (values: any, logementPrincipal: any): Logement[] => {
    let logementAutreDemande: Logement | undefined = undefined;
    const banoPrincipal = generateBanoPrincipalForSubmit(values);

    const logementPrincipalDemande: Logement = {
      id: logementPrincipal?.uuid ? null : logementPrincipal?.id ? logementPrincipal?.id : null,
      principale: true,
      situation: values.situation_logement,
      hebergeur: values.nom_hebergeur !== undefined ? values.nom_hebergeur : null,
      typeHebergeur: values.type_hebergeur !== undefined ? values.type_hebergeur : null,
      logementDispoDate: null,
      documents: documentsLogementPrincipal,
      adresseNonTrouvee:
        values.adresse_non_trouvee !== undefined ? values.adresse_non_trouvee : null,
      adresse: {
        numero: values.numero_de_voie !== undefined ? values.numero_de_voie : null,
        adresse1: values.nom_de_rue !== undefined ? values.nom_de_rue : null,
        adresse2: values.complement_adresse !== undefined ? values.complement_adresse : null,
        ville:
          values.ville_ou_commune !== undefined && values.ville_ou_commune.city !== undefined
            ? values.ville_ou_commune.city
            : undefined,
        codePostal: values.code_postal !== undefined ? values.code_postal : null,
        pays: 'FRA',
        digicode: values.numero_digicode !== undefined ? values.numero_digicode : null,
        interphone: values.nom_interphone !== undefined ? values.nom_interphone : null,
        bano: banoPrincipal,
        email: '',
        province: '',
        telephone: '',
      },
    };

    if (debugAccomodation.all || debugAccomodation.submitData.sentData) {
      console.log('generateDemande: banoPrincipal ', banoPrincipal);
      console.log('generateDemande: logementPrincipalDemande ', logementPrincipalDemande);
      console.log('generateDemande: hasFuturLogement ', values.logement_famille);
    }

    if (values.logement_famille === 'non') {
      const banoAutre = generateBanoAutreForSubmit(values);

      logementAutreDemande = {
        id: logements[1]?.uuid ? null : logements[1]?.id ? logements[1]?.id : null,
        principale: false,
        situation:
          values.situation_futur_logement !== undefined ? values.situation_futur_logement : null,
        hebergeur: values.nom_futur_hebergeur !== undefined ? values.nom_futur_hebergeur : null,
        typeHebergeur:
          values.type_futur_hebergement !== undefined ? values.type_futur_hebergement : null,
        logementDispoDate: values.entree_futur_logement,
        documents: documentsLogementAutre,
        adresseNonTrouvee:
          values.adresse_futur_logement_non_trouvee !== undefined
            ? values.adresse_futur_logement_non_trouvee
            : null,
        adresse: {
          numero:
            values.numero_de_voie_futur_logement !== undefined
              ? values.numero_de_voie_futur_logement
              : null,
          adresse1:
            values.nom_de_rue_futur_logement !== undefined
              ? values.nom_de_rue_futur_logement
              : null,
          adresse2:
            values.complement_adresse_futur_logement !== undefined
              ? values.complement_adresse_futur_logement
              : null,
          ville: null,
          codePostal:
            values.code_postal_futur_logement !== undefined
              ? values.code_postal_futur_logement
              : null,
          pays: 'FRA',
          digicode:
            values.numero_digicode_futur_logement !== undefined
              ? values.numero_digicode_futur_logement
              : null,
          interphone:
            values.nom_interphone_futur_logement !== undefined
              ? values.nom_interphone_futur_logement
              : null,
          bano: banoAutre,
          email: '',
          province: '',
          telephone: '',
        },
      };

      if (debugAccomodation.all || debugAccomodation.submitData.sentData) {
        console.log('generateDemande: banoAutre', banoAutre);
        console.log('generateDemande: logementAutreDemande ', logementAutreDemande);
      }
    }

    if (debugAccomodation.all || debugAccomodation.submitData.sentData) {
      console.log(
        'generateDemande: FunctionReturn ',
        logementAutreDemande
          ? [logementPrincipalDemande, logementAutreDemande]
          : [logementPrincipalDemande],
      );
    }

    return logementAutreDemande
      ? [logementPrincipalDemande, logementAutreDemande]
      : [logementPrincipalDemande];
  };

  const onSubmitNoValidationAfterUpload = () => {
    const values = control._formValues;
    delete values.validation;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (debugAccomodation.all || debugAccomodation.submitData.formValues) {
      console.log('onSubmitNoValidationAfterUpload: Submit ', values);
    }
    onSubmit(values);
  };

  const onSubmit = async (values: any) => {
    // if (values[HONEYPOT_NAME] !== '') {
    //   return;
    // }

    if (debugAccomodation.all || debugAccomodation.submitData.formValues) {
      console.log('onSubmit: parameters ', values);
      console.log('onSubmit: formValues ', getValues());
      console.log('onSubmit: formErrors ', errors);
      console.log('onSubmit: fieldArrays ', fieldArrayMapping);
    }

    const demandeLogements: Logement[] = generateDemande(values, logementPrincipal);

    let isError = false;

    const demandeForm = {
      id: null,
      demandeLogements: demandeLogements,
    };
    //let response: Demande;
    try {
      const parameters = {
        body: demandeForm,
        demandeId: demandeId,
        isValidation: values.validation,
        etape: 'votre-logement',
      };

      const response = updateDemande(parameters);
      setSaveFormProgress(false);

      if (debugAccomodation.all || debugAccomodation.submitData.sentData) {
        console.log('onSubmit: call body ', demandeForm);
        console.log('onSubmit: call response ', response);
        console.log('onSubmit: isError ', isError);
      }

      //setSaveFormProgress(false);
      //dispatch(setDemande(response));
    } catch (e: any) {
      isError = true;
      if (e.response.status === 400) {
        dispatch(createError(e.response.body.title));
      }
    }
    if (!isError) {
      if (values.validation === true) {
        dispatch(createSuccess(utilitaireCms.toast_etape_valide));
      } else {
        dispatch(createSuccess(utilitaireCms.toast_demande_enregistre));
      }
    }
  };

  useEffect(() => {
    dispatch(setAccomodationUntouched(!formIsTouched));
  }, [formIsTouched]);

  useEffect(() => {
    dispatch(setAccomodationValidation(isValid));
  }, [isValid]);

  useEffect(() => {
    if (isSuccess && data) {
      const accomodation = formatAccomodationStoreFromDemande(data);
      accomodation.accomodations.forEach(acc => {
        dispatch(updateAccomodation(acc));
      });
    }

    if (debugAccomodation.all || debugAccomodation.loadingData.cmsData) {
      console.log('Effect: cmsCallHandler ', isLoading, isError, data);
    }
  }, [data, isSuccess]);

  useEffect(() => {
    if (logementPrincipal) {
      //&& documentsLogementPrincipal
      // const [anneeEntreeFutureLog, moisEntreeFutureLog, jourEntreeFutureLog] = mapDateServerToForm(
      //   logementAutre?.logementDispoDate,
      // );

      const {
        adresse: adressePostalePrincipale,
        adresseNonTrouvee: adressePostaleNonTrouveePrincipale,
      } = handleLogementData(logementPrincipal);

      const { adresse: adressePostaleAutre, adresseNonTrouvee: adressePostaleNonTrouveeAutre } =
        handleLogementData(logementAutre);

      if (debugAccomodation.all || debugAccomodation.changeData.effects) {
        console.log('Effect: logements', logements);
        console.log('Effect: logements - Principale', logementPrincipal);
        console.log('Effect: logements - Autre', logementAutre);
        console.log(
          'Effect: logements - Adresse Principale',
          adressePostalePrincipale,
          adressePostaleNonTrouveePrincipale,
        );
        console.log(
          'Effect: logements - Adresse Autre',
          adressePostaleAutre,
          adressePostaleNonTrouveeAutre,
        );
      }

      setDemandeForm({
        situation_logement:
          logementPrincipal.situation !== null ? logementPrincipal?.situation : undefined,
        type_hebergeur:
          logementPrincipal.typeHebergeur !== null ? logementPrincipal?.typeHebergeur : undefined,
        nom_hebergeur:
          logementPrincipal.hebergeur !== null ? logementPrincipal?.hebergeur : undefined,
        adresse_postale: adressePostalePrincipale,
        adresse_non_trouvee: logementPrincipal?.adresseNonTrouvee
          ? logementPrincipal?.adresseNonTrouvee
          : null,
        numero_de_voie:
          logementPrincipal?.adresse?.numero !== null
            ? logementPrincipal?.adresse?.numero
            : undefined,
        nom_de_rue:
          logementPrincipal?.adresse?.adresse1 !== null
            ? logementPrincipal?.adresse?.adresse1
            : undefined,
        ville_ou_commune: adressePostaleNonTrouveePrincipale,
        code_postal:
          logementPrincipal?.adresse?.codePostal !== null
            ? logementPrincipal?.adresse?.codePostal
            : undefined,
        complement_adresse:
          logementPrincipal?.adresse?.adresse2 !== null
            ? logementPrincipal?.adresse?.adresse2
            : undefined,
        numero_digicode:
          logementPrincipal?.adresse?.digicode !== null
            ? logementPrincipal?.adresse?.digicode
            : undefined,
        nom_interphone:
          logementPrincipal?.adresse?.interphone !== null
            ? logementPrincipal?.adresse?.interphone
            : undefined,
        bail_complet_files: getDocumentsByCategory('loge_bail_complet', documentsLogementPrincipal),
        quittance_loyer_files: getDocumentsByCategory(
          'loge_quittance_loyer',
          documentsLogementPrincipal,
        ),
        attestation_domicile_employeur_files: getDocumentsByCategory(
          'loge_attestation_domicile_employeur',
          documentsLogementPrincipal,
        ),
        attestation_mise_disposition_logement_files: getDocumentsByCategory(
          'loge_attestation_mise_disposition_logement',
          documentsLogementPrincipal,
        ),
        bail_quittance_hebergeant_files: getDocumentsByCategory(
          'loge_bail_quittance_hebergeant',
          documentsLogementPrincipal,
        ),
        engagement_sous_location_files: getDocumentsByCategory(
          'loge_engagement_sous_location',
          documentsLogementPrincipal,
        ),
        justificatif_acquisition_futur_logement_files: getDocumentsByCategory(
          'loge_justificatif_acquisition_futur_logement',
          documentsLogementPrincipal,
        ),
        justificatif_domicile_files: getDocumentsByCategory(
          'loge_justificatif_domicile',
          documentsLogementPrincipal,
        ),
        justificatif_hebergeant_files: getDocumentsByCategory(
          'loge_justificatif_hebergeant',
          documentsLogementPrincipal,
        ),
        promesse_location_files: getDocumentsByCategory(
          'loge_promesse_location',
          documentsLogementPrincipal,
        ),
        attestation_domicile_hebergeant_files: getDocumentsByCategory(
          'loge_attestation_domicile_hebergeant',
          documentsLogementPrincipal,
        ),
        attestation_hebergement_employeur_files: getDocumentsByCategory(
          'loge_attestation_domicile_employeur',
          documentsLogementPrincipal,
        ),
        acte_propriete_files: getDocumentsByCategory(
          'loge_acte_propriete',
          documentsLogementPrincipal,
        ),
        bail_complet_autre_files: [],
        quittance_loyer_autre_files: getDocumentsByCategory(
          'loge_quittance_loyer',
          documentsLogementAutre,
        ),
        attestation_domicile_employeur_autre_files: getDocumentsByCategory(
          'loge_attestation_domicile_employeur',
          documentsLogementAutre,
        ),
        attestation_mise_disposition_logement_autre_files: getDocumentsByCategory(
          'loge_attestation_mise_disposition_logement',
          documentsLogementAutre,
        ),
        bail_quittance_hebergeant_autre_files: getDocumentsByCategory(
          'loge_bail_quittance_hebergeant',
          documentsLogementAutre,
        ),
        engagement_sous_location_autre_files: getDocumentsByCategory(
          'loge_engagement_sous_location',
          documentsLogementAutre,
        ),
        justificatif_acquisition_futur_logement_autre_files: getDocumentsByCategory(
          'loge_justificatif_acquisition_futur_logement',
          documentsLogementAutre,
        ),
        justificatif_domicile_autre_files: getDocumentsByCategory(
          'loge_justificatif_domicile',
          documentsLogementAutre,
        ),
        justificatif_hebergeant_autre_files: getDocumentsByCategory(
          'loge_justificatif_hebergeant',
          documentsLogementAutre,
        ),
        promesse_location_autre_files: getDocumentsByCategory(
          'loge_promesse_location',
          documentsLogementAutre,
        ),
        attestation_domicile_hebergeant_autre_files: getDocumentsByCategory(
          'loge_attestation_domicile_hebergeant',
          documentsLogementAutre,
        ),
        attestation_hebergement_employeur_autre_files: getDocumentsByCategory(
          'loge_attestation_domicile_employeur',
          documentsLogementAutre,
        ),
        acte_propriete_autre_files: getDocumentsByCategory(
          'loge_acte_propriete',
          documentsLogementAutre,
        ),
        logement_famille: logementAutre ? 'non' : 'oui',
        situation_futur_logement:
          logementAutre?.situation !== null ? logementAutre?.situation : undefined,
        type_futur_hebergement:
          logementAutre?.typeHebergeur !== null ? logementAutre?.typeHebergeur : undefined,
        nom_futur_hebergeur:
          logementAutre?.hebergeur !== null ? logementAutre?.hebergeur : undefined,
        entree_futur_logement: logementAutre?.logementDispoDate
          ? logementAutre?.logementDispoDate
          : undefined,
        adresse_postale_futur_logement: adressePostaleAutre,
        adresse_futur_logement_non_trouvee: logementAutre?.adresseNonTrouvee
          ? logementAutre?.adresseNonTrouvee
          : null,
        numero_de_voie_futur_logement:
          logementAutre?.adresse?.numero !== null ? logementAutre?.adresse?.numero : undefined,
        nom_de_rue_futur_logement:
          logementAutre?.adresse?.adresse1 !== null ? logementAutre?.adresse?.adresse1 : undefined,
        code_postal_futur_logement:
          logementAutre?.adresse?.codePostal !== null
            ? logementAutre?.adresse?.codePostal
            : undefined,
        ville_ou_commune_futur_logement: adressePostaleNonTrouveeAutre
          ? adressePostaleNonTrouveeAutre
          : null,
        complement_adresse_futur_logement:
          logementAutre?.adresse?.adresse2 !== null ? logementAutre?.adresse?.adresse2 : undefined,
        numero_digicode_futur_logement:
          logementAutre?.adresse?.digicode !== null ? logementAutre?.adresse?.digicode : undefined,
        nom_interphone_futur_logement:
          logementAutre?.adresse?.interphone !== null
            ? logementAutre?.adresse?.interphone
            : undefined,
        validation: 'true',
      });
    }
  }, [logements, logementPrincipal, logementAutre]);

  useEffect(() => {
    if (demandeForm) {
      if (debugAccomodation.all || debugAccomodation.changeData.effects) {
        console.log('Effect: demandeForm', demandeForm);
      }

      setValuesFromDemand(demandeForm);
    }
  }, [demandeForm]);

  useEffect(() => {
    if (
      logementActuelValue !== logementFamilleWatcher &&
      (logementFamilleWatcher === 'non' || logementFamilleWatcher === 'oui')
    ) {
      if (debugAccomodation.all || debugAccomodation.changeData.effects) {
        console.log('Effect: logementFamilleWatcher', logementFamilleWatcher);
      }

      setLogementActuelValue(logementFamilleWatcher);
    }
  }, [logementFamilleWatcher]);

  useEffect(() => {
    if (accommodationFormToSubmit) {
      onSubmitNoValidationAfterUpload();
      dispatch(submitForm('accomodation'));
    }
  }, [accommodationFormToSubmit]);

  useEffect(() => {
    if (saveFormProgress) {
      onSubmit({ ...control._formValues, validation: false });
    }
  }, [saveFormProgress]);

  return (
    <FormLayout
      formIdentifier="accomodation-form"
      title={bloc3Cms?.titre_principal}
      requiredInfos={true}
      lang={lang}
      onSubmit={onSubmit}
      control={control}
      handleSubmit={handleSubmit}
      trigger={trigger}
      isValid={isValid}
      errors={errors}
      scrollToTablist={scrollToTablist}
    >
      <AddressPanel
        accomodationMapping={currentAccomodationMapping}
        control={control}
        errors={errors}
        lang={lang}
        setValue={setValue}
        watch={watch}
        trigger={trigger}
        debugAccomodation={debugAccomodation}
      />
      <div className="container fr-mt-8v">
        <hr />
      </div>
      {filesFieldMapping && (
        <AccomodationSituationPanel
          accomodationMapping={currentAccomodationMapping}
          demandeId={demandeId}
          accomodationId={logementPrincipal?.id}
          filesMapping={filesFieldMapping.current}
          errors={errors}
          bloc3Cms={bloc3Cms}
          formControl={{ watch, control, setValue, getValues, trigger, errors }}
          isFutur={false}
          debugAccomodation={debugAccomodation}
          lang={lang}
          onSituationChange={(value: any) => {
            onAccomodationSituationChange(value, 'situation_logement');
          }}
        />
      )}
      <div className="container fr-mt-8v">
        <hr />
      </div>
      <div className="fr-col fr-mt-8v">
        <CustomAlert
          customClass="fr-mb-6v"
          description={bloc3Cms?.bulle_aide_adresse_logement_a_visiter?.contenu}
          severity="warning"
          title={bloc3Cms?.bulle_aide_adresse_logement_a_visiter?.titre}
        />
      </div>
      <CommonRowContainer>
        <RadioButtonContainer
          {...AccomodationMapping.getGeneralMapping().family_accomodation_field(
            bloc3Cms,
            setLogementActuelValue,
            logementActuelValue,
            control,
            trigger,
          )}
          orientation="horizontal"
        />
        <></>
      </CommonRowContainer>
      {logementActuelValue === 'non' && (
        <>
          <AddressPanel
            accomodationMapping={futurAccomodationMapping}
            control={control}
            errors={errors}
            lang={lang}
            setValue={setValue}
            watch={watch}
            trigger={trigger}
            debugAccomodation={debugAccomodation}
            isFutur={true}
          />

          <div className="container fr-mt-8v">
            <hr />
          </div>

          {filesFieldMapping && (
            <AccomodationSituationPanel
              accomodationMapping={futurAccomodationMapping}
              demandeId={demandeId}
              accomodationId={logementAutre?.id}
              filesMapping={filesFieldMapping.autre}
              formControl={{ watch, control, setValue, getValues, trigger, errors }}
              isFutur={true}
              errors={errors}
              bloc3Cms={bloc3Cms}
              debugAccomodation={debugAccomodation}
              lang={lang}
              onSituationChange={(value: any) => {
                onAccomodationSituationChange(value, 'situation_futur_logement');
              }}
            />
          )}
        </>
      )}
    </FormLayout>
  );
};

export default Accomodation;
