/* eslint-disable max-params */
import * as yup from 'yup';

import { CHOIX } from 'constants/global';
import { HONEYPOT_NAME } from 'constants/formulaire';
import { FILIATION, LIEN_AUTRE_PARENT } from 'constants/regroupementFamiliale';
import { FormHelper } from 'helpers/app/FormHelper';
import { isDateInThePast } from 'helpers/app/ComponentsFormHelpers';
import {
  DemandeRfBloc2,
  DemandeRfErrors,
  DemandeRfUtilitaire,
} from 'models/types/cms/demandeRF/type';
import { getAgeisMinor } from 'pages/DemandeRF/Helpers/common';

export const getEnfantValidationSchema = (
  errorsRfCms: DemandeRfErrors,
  bloc2Cms: DemandeRfBloc2,
  utilitaireCms: DemandeRfUtilitaire,
  nationalitesNonEligibles: any,
) => {
  const formHelper = new FormHelper(errorsRfCms);
  return yup.object().shape({
    rf_demande: formHelper.stringSchema(true),
    enfant_etranger: formHelper.stringSchema(true),
    sexe_enfant: formHelper.stringSchema(true),
    nom_naissance_enfant: formHelper.stringSchema(true),
    nom_usage_enfant: formHelper.stringSchema(),
    prenoms_enfant: formHelper.stringSchema(true),
    date_naissance_enfant: yup
      .string()
      .typeError(errorsRfCms.erreur_date_naissance)
      .when('rf_demande', {
        is: (value: string) => value === CHOIX.OUI,
        then: () => {
          return formHelper
            .stringSchema(true, bloc2Cms.date_naissance_enfant)
            .test('dateMustBeInPast', utilitaireCms.pas_de_date_posterieure, value => {
              return !!(value && isDateInThePast(value));
            })
            .test('mustBeMinor', errorsRfCms.non_eligible_rf, value => {
              return !!(value && getAgeisMinor(value));
            });
        },
        otherwise: () => formHelper.stringSchema(),
      }),
    acte_naissance_enfant_files: formHelper.requiredFileSchema(),
    acte_naissance_enfant_traduit: yup.boolean(),
    acte_naissance_enfant_traduit_files: yup.array().when('acte_naissance_enfant_traduit', {
      is: true,
      then: () => formHelper.requiredFileSchema(),
      otherwise: () => formHelper.notRequiredFileSchema(),
    }),
    pays_naissance_enfant: yup
      .string()
      .notRequired()
      .when('rf_demande', {
        is: (value: string) => value === CHOIX.OUI,
        then: () => formHelper.stringSchema(true),
      }),
    ville_naissance_enfant: yup
      .string()
      .notRequired()
      .when('rf_demande', {
        is: (value: string) => value === CHOIX.OUI,
        then: () => formHelper.stringSchema(true),
      }),
    nationalite_enfant: yup.string().when('rf_demande', {
      is: (value: string) => value === CHOIX.OUI,
      then: schema =>
        schema
          .required(errorsRfCms.champ_requis)
          .notOneOf(nationalitesNonEligibles, errorsRfCms.non_eligible_rf),
      otherwise: () => formHelper.stringSchema(),
    }),
    filiation: yup
      .string()
      .notRequired()
      .when('rf_demande', {
        is: (value: string) => value === CHOIX.OUI,
        then: () => formHelper.stringSchema(true),
      }),
    kafala_algerien_files: yup.array().when('filiation', {
      is: (value: string) => value && value === FILIATION.KAFALA,
      then: () => formHelper.requiredFileSchema(),
      otherwise: () => formHelper.notRequiredFileSchema(),
    }),
    kafala_algerien_traduit: yup.boolean(),
    kafala_algerien_traduit_files: yup.array().when('kafala_algerien_traduit', {
      is: true,
      then: () => formHelper.requiredFileSchema(),
      otherwise: () => formHelper.notRequiredFileSchema(),
    }),
    jugement_adoption_files: yup.array().when('filiation', {
      is: (value: string) => value && value === FILIATION.ADOPTIVE,
      then: () => formHelper.requiredFileSchema(),
      otherwise: () => formHelper.notRequiredFileSchema(),
    }),
    jugement_adoption_traduit: yup.boolean(),
    jugement_adoption_traduit_files: yup.array().when('jugement_adoption_traduit', {
      is: true,
      then: () => formHelper.requiredFileSchema(),
      otherwise: () => formHelper.notRequiredFileSchema(),
    }),
    lien_second_parent: yup
      .string()
      .notRequired()
      .when('rf_demande', {
        is: (value: string) => value === CHOIX.OUI,
        then: () => formHelper.stringSchema(true),
      }),
    livret_famille_files: yup.array().when('rf_demande', {
      is: (value: string) => value === CHOIX.OUI,
      then: schema =>
        schema.when('lien_second_parent', {
          is: (value: string) => value && value === LIEN_AUTRE_PARENT.CONJOINT_ACTUEL,
          then: () => formHelper.requiredFileSchema(),
          otherwise: () => formHelper.notRequiredFileSchema(),
        }),
    }),
    jugement_droit_garde_enfants_files: yup.array().when('rf_demande', {
      is: (value: string) => value === CHOIX.OUI,
      then: schema =>
        schema.when('lien_second_parent', {
          is: (value: string) =>
            value &&
            [LIEN_AUTRE_PARENT.DIVORCE.toString(), LIEN_AUTRE_PARENT.SEPARE.toString()].includes(
              value,
            ),
          then: () => formHelper.requiredFileSchema(),
          otherwise: () => formHelper.notRequiredFileSchema(),
        }),
    }),
    jugement_droit_garde_enfants_traduit: yup.boolean(),
    jugement_droit_garde_enfants_traduit_files: yup
      .array()
      .when('jugement_droit_garde_enfants_traduit', {
        is: true,
        then: () => formHelper.requiredFileSchema(),
        otherwise: () => formHelper.notRequiredFileSchema(),
      }),
    autre_parent_autorisation_venue_france_files: yup.array().when('rf_demande', {
      is: (value: string) => value === CHOIX.OUI,
      then: schema =>
        schema.when('lien_second_parent', {
          is: (value: string) =>
            value &&
            [LIEN_AUTRE_PARENT.DIVORCE.toString(), LIEN_AUTRE_PARENT.SEPARE.toString()].includes(
              value,
            ),
          then: () => formHelper.requiredFileSchema(),
          otherwise: () => formHelper.notRequiredFileSchema(),
        }),
    }),
    autre_parent_autorisation_venue_france_traduit: yup.boolean(),
    autre_parent_autorisation_venue_france_traduit_files: yup
      .array()
      .when('autre_parent_autorisation_venue_france_traduit', {
        is: true,
        then: () => formHelper.requiredFileSchema(),
        otherwise: () => formHelper.notRequiredFileSchema(),
      }),
    declaration_disparition_autre_parent_files: yup.array().when('rf_demande', {
      is: (value: string) => value === CHOIX.OUI,
      then: schema =>
        schema.when('lien_second_parent', {
          is: (value: string) => value && value === LIEN_AUTRE_PARENT.DISPARU,
          then: () => formHelper.requiredFileSchema(),
          otherwise: () => formHelper.notRequiredFileSchema(),
        }),
    }),
    declaration_disparition_autre_parent_traduit: yup.boolean(),
    declaration_disparition_autre_parent_traduit_files: yup
      .array()
      .when('declaration_disparition_autre_parent_traduit', {
        is: true,
        then: () => formHelper.requiredFileSchema(),
        otherwise: () => formHelper.notRequiredFileSchema(),
      }),
    acte_deces_files: yup.array().when('rf_demande', {
      is: (value: string) => value === CHOIX.OUI,
      then: schema =>
        schema.when('lien_second_parent', {
          is: (value: string) => value && value === LIEN_AUTRE_PARENT.DECEDE,
          then: () => formHelper.requiredFileSchema(),
          otherwise: () => formHelper.notRequiredFileSchema(),
        }),
    }),
    acte_deces_traduit: yup.boolean(),
    acte_deces_traduit_files: yup.array().when('acte_deces_traduit', {
      is: true,
      then: () => formHelper.requiredFileSchema(),
      otherwise: () => formHelper.notRequiredFileSchema(),
    }),
    [HONEYPOT_NAME]: yup.string(),
  });
};
