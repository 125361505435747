import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { getCurrentLocale } from 'redux/locale/selectors';
//import { getProcedureTypeList } from 'redux/selectors';
//import { hasListRFDemandeSummaryBeenFetched } from 'redux/RegroupementFamilial/selectors';
//import { hasDemandeAsileBeenFetched } from 'redux/Asile/selectors';
//import { hasRetourAndReinsertionBeenFetched } from 'redux/RetourReinsertion/selectors';
import { useAuth } from 'react-oidc-context';

export enum ProcedureType {
  ASILE = 'ASILE',
  REGROUPEMENT_FAMILIAL = 'REGROUPEMENT_FAMILIAL',
  RETOUR_REINSERTION = 'RETOUR_REINSERTION',
}

enum CustomDimensionIds {
  UTILISATEUR_CONNECTE = 1,
  LANGUE = 2,
  LISTE_PROCEDURES = 3,
}

enum UtilisateurConnecte {
  CONNECTE = 'connecte',
  NON_CONNECTE = 'non_connecte',
}

export const useDefaultCustomDimensions = () => {
  const auth = useAuth();
  const utilisateurConnecte = auth.isAuthenticated
    ? UtilisateurConnecte.CONNECTE
    : UtilisateurConnecte.NON_CONNECTE;

  const currentLocale = useSelector(getCurrentLocale);
  //const procedureList = useSelector(getProcedureTypeList);
  const procedureList: ProcedureType[] = [];
  //const hasAsileBeenFetched = useSelector(hasDemandeAsileBeenFetched);
  //const hasRFBeenFetched = useSelector(hasListRFDemandeSummaryBeenFetched);
  //const hasRRIBeenFetched = useSelector(hasRetourAndReinsertionBeenFetched);
  const hasAsileBeenFetched = true;
  const hasRFBeenFetched = true;
  const hasRRIBeenFetched = true;

  const defaultCustomDimensions = useMemo(() => {
    return [
      {
        id: CustomDimensionIds.UTILISATEUR_CONNECTE,
        value: utilisateurConnecte,
      },
      {
        id: CustomDimensionIds.LANGUE,
        value: currentLocale,
      },
      {
        id: CustomDimensionIds.LISTE_PROCEDURES,
        value: procedureList.join().toLowerCase(),
      },
    ];
  }, [utilisateurConnecte, currentLocale, procedureList]);

  return {
    defaultCustomDimensions,
    areDefaultCustomDimensionsReady: hasRFBeenFetched && hasAsileBeenFetched && hasRRIBeenFetched,
  };
};
