import { FC, useEffect, useState } from 'react';
import { ModalProps, createModal } from '@codegouvfr/react-dsfr/Modal';
import Button from '@codegouvfr/react-dsfr/Button';

import WidgetFactory from 'helpers/cms/WidgetPageContenu/WidgetFactory';
import { WidgetBaseElement, WidgetPageContenu } from 'models/types/cms/widgetPageContenu/types';
import { getRfCmsContents } from 'pages/DemandeRF/Helpers/Cms/getRfCmsContents';

export type InfobulleProps = {
  cmsContent: any | string;
  infobulleName: string;
  lang: string;
  btnTitle?: string;
};

const Infobulle: FC<InfobulleProps> = ({ cmsContent, infobulleName, lang, btnTitle }) => {
  const [infobulleTitleValue, setInfobulleTitleValue] = useState<string>('');

  const { utilitaireCms } = getRfCmsContents(lang);

  const infobulleModal: {
    buttonProps: {
      /** Only for analytics, feel free to overwrite */
      id: string;
      'aria-controls': string;
      'data-fr-opened': boolean;
    };
    Component: (props: ModalProps) => React.JSX.Element;
    close: () => void;
    open: () => void;
    isOpenedByDefault: boolean;
    id: string;
  } = createModal({
    id: `infobulle-${infobulleName.toLowerCase()}`,
    isOpenedByDefault: false,
  });

  const filteredCmsContent = cmsContent.filter(
    (widget: WidgetBaseElement) => !(widget.__component === WidgetPageContenu.TITRE_INFOBULLE),
  );

  useEffect(() => {
    const infobulleTitle = cmsContent.find(
      (widget: WidgetBaseElement) => widget.__component === WidgetPageContenu.TITRE_INFOBULLE,
    );

    if (infobulleTitle) {
      setInfobulleTitleValue(infobulleTitle.titre_infobulle);
    }
  }, [cmsContent]);

  return (
    <>
      <infobulleModal.Component
        title={infobulleTitleValue}
        className="fr-icon-arrow-right-line style-stop-propagation"
      >
        {filteredCmsContent.map((widget: WidgetBaseElement) => (
          <WidgetFactory key={`${widget.__component}-${widget.id}`} widget={widget} />
        ))}
      </infobulleModal.Component>
      <Button
        type="button"
        iconId="fr-icon-question-line"
        priority="tertiary no outline"
        title={btnTitle ?? utilitaireCms?.clic_pour_en_savoir_plus}
        aria-label={btnTitle ?? utilitaireCms?.clic_pour_en_savoir_plus}
        onClick={() => infobulleModal.open()}
        className="custom-btn-icon-padding"
      />
    </>
  );
};

export default Infobulle;
