import { Dispatch, SetStateAction } from 'react';
import { TusHooksUploadOptions } from 'use-tus';
import { DetailedError, HttpRequest, HttpResponse } from 'tus-js-client';

import { RegisteredLocales } from 'constants/locales';

export const getUploadOptions = (
  setPercentage: Dispatch<SetStateAction<number>>,
  setIsProgess: Dispatch<SetStateAction<boolean>>,
  setErrorMsg: Dispatch<SetStateAction<string | null>>,
  handleUploadSuccess: (id: string, originalFileName: string) => void,
  lang: RegisteredLocales,
): TusHooksUploadOptions => {
  return {
    retryDelays: [0, 1000, 3000, 5000],
    onProgress(bytesSent: number, bytesTotal: number) {
      setPercentage(parseInt(((bytesSent / bytesTotal) * 100).toFixed(0)));
    },
    onSuccess() {
      setPercentage(100);
      setIsProgess(false);
    },
    // eslint-disable-next-line no-unused-vars
    onAfterResponse(req: HttpRequest, res: HttpResponse) {
      if (res.getStatus() === 204 && res.getHeader('DocumentId') !== null) {
        handleUploadSuccess(res.getHeader('DocumentId'), res.getHeader('OriginalFileName'));
      }
    },
    onError(error: Error | DetailedError) {
      if (error instanceof DetailedError) {
        if (error.originalResponse?.getStatus() === 413) {
          setErrorMsg(error.originalResponse?.getBody());
        } else if (error.originalResponse?.getStatus() === 400) {
          const obj = JSON.parse(error.originalResponse?.getBody());
          const errorMessage = JSON.parse(obj.title)[lang];
          setIsProgess(false);
          setErrorMsg(errorMessage);
        }
      }
    },
    onShouldRetry: function (err: any) {
      const status = err.originalResponse ? err.originalResponse.getStatus() : 0;
      // Do not retry if the status is a 400.
      if (status === 400 || status === 403) {
        return false;
      }
      // For any other status code, we retry.
      return true;
    },
  };
};
