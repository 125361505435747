/* eslint-disable complexity */
import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useAuth } from 'react-oidc-context';
import { useSelector } from 'react-redux';

import { getCurrentLocale } from 'redux/locale/selectors';
import Loader from 'components/Atoms/Loader/Loader';
import { useNewContactMutation } from 'redux/rtk/api/apiCommon';
import { useGetUserQuery } from 'redux/rtk/api/apiUser';
import { useGetUsagerCmsPageQuery } from 'redux/rtk/cms/cmsCommon';
import { useGetContactCmsPageQuery } from 'redux/rtk/cms/cmsContact';
import { getRfCmsContents } from 'pages/DemandeRF/Helpers/Cms/getRfCmsContents';

import ContactForm from './components/ContactForm';
import { cmsContactQuery1, cmsContactQuery2 } from './cmsContact.query';
import './Contact.scss';

const Contact: FC = () => {
  const [skip, setSkip] = useState<boolean>(true);
  const auth = useAuth();
  const lang = useSelector(getCurrentLocale) as string;
  const { errorCms } = getRfCmsContents(lang);
  const [newContact, { isSuccess }] = useNewContactMutation();
  const navigate = useNavigate();

  const {
    data: cmsContact1,
    error: contactCmsError1 = false,
    isLoading: isContactCmsLoading1 = true,
  } = useGetContactCmsPageQuery({
    qs: cmsContactQuery1,
    locale: lang !== '' ? lang : 'fr',
  });

  const {
    data: cmsContact2,
    error: contactCmsError2 = false,
    isLoading: isContactCmsLoading2 = true,
  } = useGetContactCmsPageQuery({
    qs: cmsContactQuery2,
    locale: lang !== '' ? lang : 'fr',
  });

  let cmsContact = undefined;

  if (cmsContact1?.data.attributes && cmsContact2?.data.attributes) {
    cmsContact = {
      ...(cmsContact1.data.attributes ?? {}),
      ...(cmsContact2.data.attributes ?? {}),
      formulaire_contact: {
        ...cmsContact1.data.attributes.formulaire_contact,
        ...cmsContact2.data.attributes.formulaire_contact,
      },
    };
  }

  const isLoadingCmsContactData = isContactCmsLoading1 || isContactCmsLoading2;

  const { isLoading: isLoadingCmsUsagerData } = useGetUsagerCmsPageQuery({
    locale: lang !== '' ? lang : 'fr',
  });
  const { cmsUsager } = useGetUsagerCmsPageQuery(
    {
      locale: lang !== '' ? lang : 'fr',
    },
    {
      selectFromResult: ({ data }) => ({
        cmsUsager: data?.data.attributes,
      }),
    },
  );
  const { data: dataUser, isLoading: isLoadingUser } = useGetUserQuery(undefined, {
    skip: skip,
  });

  useEffect(() => {
    if (auth.isAuthenticated) {
      setSkip(false);
    }
  }, [auth.isAuthenticated, setSkip]);

  if (
    !cmsContact ||
    !cmsUsager ||
    isLoadingUser ||
    isLoadingCmsContactData ||
    isLoadingCmsUsagerData ||
    !cmsContact
  )
    return <Loader />;

  if (contactCmsError1 || contactCmsError2) {
    navigate('/erreur');
  }

  return (
    <ContactForm
      cmsContact={cmsContact}
      cmsUsager={cmsUsager}
      errorCms={errorCms}
      dataUser={dataUser}
      isSuccess={isSuccess}
      newContact={newContact}
      auth={auth}
      lang={lang}
    />
  );
};
export default Contact;
