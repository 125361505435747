import {
  formatAccomodationStoreFromDemande,
  formatCommitmentsStoreFromDemande,
  formatFamilyStoreFromDemande,
  formatIdentityStoreFromDemande,
  formatInfosStoreFromDemande,
  formatRessourcesStoreFromDemande,
} from 'helpers/api/apiToStore';
import { Demande } from 'models/types/api/rf';

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { DemandeRfState } from 'redux/demandeRf/utils';
import { RootState } from 'redux/reducers';

export const formatDemandeForManagement = (demandeId: string, demande: Demande): DemandeRfState => {
  return {
    id: demandeId,
    hasLoad: false,
    rfDemandeSummary: { contenu: [], total: 1 },
    demandeList: [demande],
    infos: formatInfosStoreFromDemande(demande),
    identity: formatIdentityStoreFromDemande(demande),
    family: formatFamilyStoreFromDemande(demande),
    accomodation: formatAccomodationStoreFromDemande(demande),
    ressources: formatRessourcesStoreFromDemande(demande),
    commitments: formatCommitmentsStoreFromDemande(demande),
    isDemandeHasBeenSubmitted: false,
  };
};

export function useFormTouched(touchFields: any) {
  const [isTouched, setIsTouched] = useState(false);

  if (isTouched && Object.keys(touchFields).length === 0) {
    setIsTouched(false);
  } else if (!isTouched && Object.keys(touchFields).length > 0) {
    setIsTouched(true);
  }

  return isTouched;
}

export function useResourcesFormIsTouch(touchFields: any, touchedFieldIsNewActivities: boolean) {
  const [isTouched, setIsTouched] = useState(false);

  if (isTouched && Object.keys(touchFields).length === 0) {
    setIsTouched(false);
  } else if (!isTouched && Object.keys(touchFields).length > 0 && touchedFieldIsNewActivities) {
    setIsTouched(true);
  }

  return isTouched;
}

export function useTabStatus(
  validationSelector: (state: RootState) => boolean,
  untouchedSelector: (state: RootState) => boolean,
) {
  const [status, setStatus] = useState('untouched');
  const isValid = useSelector(validationSelector);
  const isUntouched = useSelector(untouchedSelector);

  function getStatus() {
    if (!isValid && !isUntouched) {
      setStatus('warning');
    } else if (isValid && !isUntouched) {
      setStatus('valid');
    } else {
      setStatus('untouched');
    }
  }

  useEffect(() => {
    getStatus();
  }, [isUntouched, isValid]);

  return status;
}
