export const HelpBoxQuery = {
  fields: ['titre', 'contenu', 'bouton_ou_lien', 'lien'],
};

export const FileInfosQuery = {
  fields: [
    'titre_fichier',
    'infos_fichier',
    'bulle_aide_fichier_titre',
    'bulle_aide_fichier_contenu',
    'bulle_aide_fichier_texte_lien',
    'bulle_aide_fichier_lien',
  ],
};

export const InfobulleQuery = {
  populate: '*',
};

export const TitleSubTitleQuery = {
  fields: ['titre', 'sous_titre'],
};
