import { useMatomo } from '@jonkoops/matomo-tracker-react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { getKeycloakStatus } from 'redux/keycloak/selectors';
import { useDefaultCustomDimensions } from 'services/tracking/useCustomDimensions';
import { KeycloakStatus } from 'services/authentication';
import { ACCUEIL_DIRECT } from 'routes/path';

export const usePageVue = (path: string) => {
  const { trackPageView } = useMatomo();
  const keycloakStatus = useSelector(getKeycloakStatus);
  const { defaultCustomDimensions, areDefaultCustomDimensionsReady } = useDefaultCustomDimensions();

  useEffect(() => {
    // Since we have a redirect from / to /accueil, this prevents events from being sent twice
    if (
      path !== ACCUEIL_DIRECT &&
      keycloakStatus !== KeycloakStatus.UNKNOWN &&
      areDefaultCustomDimensionsReady
    ) {
      // matomo s'occupe de récupérer l'url de la page vue par lui même
      trackPageView({
        customDimensions: defaultCustomDimensions,
      });
    }
    // On retire les defaultCustomDimensions des dépendances, car on ne veut pas envoyer de page vue
    // à chaque fois qu'elles se re-rendent
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path, trackPageView, keycloakStatus, areDefaultCustomDimensionsReady]);
};
