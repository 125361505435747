export const etape4_decision__decision_favorable_prefecture_sans_instruction = {
  beneficiaires: [
    {
      lienFamilial: 'enfant',
      prenomBeneficiaire: 'Benef',
      nomBeneficiaire: 'TEST INSTR',
      dateNaissance: '2023-01-01',
      nationalite: 'Indienne',
      paysResidence: 'Inde',
    },
  ],
  numeroDossier: '43024000000045',
  adresseDemandeur: 'ALLEE DE BOMPART 43000 POLIGNAC',
  adresseEnqueteDemandeur: null,
  directionTerritoriale: 'DT Clermont-Ferrand',
  titreSejourValiditeDateDebut: '2023-01-01',
  titreSejourValiditeDateFin: '2033-01-01',
  clefStatut: 'decision__favorable',
  statut: 'favorable',
  dateStatut: '2024-02-13',
  numeroAgdref: '7703023979',
  responsableStatut: 'Pr\u00e9fecture de la Haute-Loire',
  documentsManquant: [],
  enqueteLogement: {
    statut: 'terminee',
    date: '2024-02-12',
  },
  enqueteRessources: {
    statut: 'terminee',
    motif: null,
    date: '2024-02-12',
  },
  etapes: [
    {
      statut: 'termine',
      nom: 'receptionnee',
      numero: 1,
      date: null,
    },
    {
      statut: 'termine',
      nom: 'en_cours_instruction',
      numero: 2,
      date: null,
    },
    {
      statut: 'termine',
      nom: 'en_attente_decision',
      numero: 3,
      date: null,
    },
    {
      statut: 'termine',
      nom: 'decision',
      numero: 4,
      date: '2024-02-13',
    },
  ],
  dateEnvoiConsulat: null,
  typeDepot: 'portail',
  idProcedure: '1',
  postalStatut: 'dossier_cloture',
  newDemande: true,
  shouldDisplayEnquete: false,
};
