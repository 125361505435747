import Tag from '@codegouvfr/react-dsfr/Tag';
import { FC } from 'react';

export type CategorySelectorProps = {
  elements: string[];
  selectedCategory: string | null;
  callback: (value: string) => void;
};

const CategorySelector: FC<CategorySelectorProps> = ({ elements, callback, selectedCategory }) => {
  return (
    <div className="fr-category-selector fr-mb-5w fr-mt-6w">
      <div className="fr-grid-row fr-grid-row--gutters fr-pl-2w">
        {elements.map(el => {
          if (el === selectedCategory) {
            return (
              <Tag
                className="fr-ml-1w"
                key={el}
                nativeButtonProps={{
                  onClick: () => {
                    callback(el);
                  },
                }}
                pressed
              >
                {el}
              </Tag>
            );
          } else {
            return (
              <Tag
                className="fr-ml-1w"
                key={el}
                nativeButtonProps={{
                  onClick: () => {
                    callback(el);
                  },
                }}
              >
                {el}
              </Tag>
            );
          }
        })}
      </div>
    </div>
  );
};

export default CategorySelector;
