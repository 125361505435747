import React from 'react';

import CustomDownload from 'components/Atoms/Forms/CustomDownload/CustomDownload';

interface CustomDownloadContainerProps {
  label: string;
  details: string;
  href: string;
  customClass?: string;
}

const CustomDownloadContainer: React.FC<CustomDownloadContainerProps> = ({
  label,
  details,
  href,
  customClass,
}) => {
  return (
    <div
      className={
        customClass
          ? customClass
          : 'fr-col-12 fr-mt-4v fr-mt-lg-0v fr-mt-md-0v fr-col-lg-4 fr-col-md-4'
      }
    >
      <CustomDownload label={label} details={details} href={href} />
    </div>
  );
};

export default CustomDownloadContainer;
