import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { getPageTitre } from 'redux/pages/selectors';

const useDocumentTitle = (path: string): void => {
  const intl = useIntl();
  const titrePageCourante = useSelector(getPageTitre(path));
  const baseDocumentTitle = intl.formatMessage({ id: 'base-document-title' });

  document.title = `${titrePageCourante ? `${titrePageCourante} - ` : ``}${baseDocumentTitle}`;
};

export default useDocumentTitle;
