import type { AlertProps } from '@codegouvfr/react-dsfr/Alert';
import { format } from 'date-fns';

import { Beneficiaire } from 'models/types/api/rf';
import { DemandeRfGeneral } from 'models/types/cms/demandeRF/type';

export const badgeAppFormatter = (
  beneficiaire: Beneficiaire,
  globalCms: DemandeRfGeneral,
): {
  color: AlertProps.Severity | 'new';
  label: string;
} => {
  if (beneficiaire.rfDemande) {
    if (beneficiaire.estValide) {
      return { color: 'success', label: globalCms.beneficiaire };
    } else {
      return { color: 'warning', label: globalCms.a_completer_badge_warning };
    }
  } else {
    if (beneficiaire.estValide) {
      return { color: 'info', label: globalCms.non_beneficiaire };
    } else {
      return { color: 'warning', label: globalCms.a_completer_badge_warning };
    }
  }
};

export const dateAppFormatter = (dateStr: string, dateFormat: string): any => {
  return format(Date.parse(dateStr), dateFormat);
};
