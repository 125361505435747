import React, { ReactNode } from 'react';
import { useStyles } from 'tss-react/dsfr';

import Infobulle from 'components/Molecules/Infobulle/Infobulle';

import '../Forms.scss';
import InfobulleForCustomModal from '../../Infobulle/InfobulleForCustomModal';

interface CommonRowContainerProps {
  children: ReactNode[];
  className?: string;
  prefixClassName?: string;
  legend?: string;
  infobulleContent?: any;
  lang?: string;
  noSpacing?: boolean;
  infobulleCustomModal?: boolean;
}

const CommonRowContainer: React.FC<CommonRowContainerProps> = ({
  children,
  className,
  prefixClassName,
  legend,
  infobulleContent,
  lang,
  noSpacing,
  infobulleCustomModal,
}) => {
  const defaultClass = 'fr-grid-row fr-col-12 fr-mt-0v';
  const { cx } = useStyles();
  return (
    <>
      {legend && (
        <div className="fr-col-12 fr-mt-md-4w fr-mt-3w">
          <legend className={cx('bold flex-element', prefixClassName)}>
            {legend}
            {infobulleContent && lang !== undefined && (
              <>
                {infobulleCustomModal ? (
                  <InfobulleForCustomModal
                    cmsContent={infobulleContent}
                    infobulleName={(Math.random() + 1).toString(36).substring(7)}
                    lang={lang}
                  />
                ) : (
                  <Infobulle
                    cmsContent={infobulleContent}
                    infobulleName={(Math.random() + 1).toString(36).substring(7)}
                    lang={lang}
                  />
                )}
              </>
            )}
          </legend>
        </div>
      )}
      <div className={className ? className : defaultClass}>
        {children[0]}
        {children[1] && (
          <>
            {!noSpacing && <div className="bloc-x-spacing"></div>}
            {children[1]}
          </>
        )}
      </div>
    </>
  );
};

export default CommonRowContainer;
