import { Demande, Union } from 'models/types/api/rf';

export const identityFilesKeys = [
  'sejo_titre_sejour_renouvellement_recepisse',
  'sejo_titre_sejour',
  'etci_pacs',
  'etci_acte_deces_precedent_conjoint',
  'etci_acte_deces_precedent_conjoint_trad',
  'etci_jugement_divorce',
  'etci_jugement_divorce_trad',
  'etci_acte_mariage',
  'etci_acte_mariage_trad',
  'etci_acte_naissance',
  'etci_acte_naissance_trad',
];

//dans Family il manque documents: []
export const familyFilesKeys = ['rfpa_lettre_explication_rf_partiel'];

export const ressourcesFilesKeys = [
  'ress_dernier_avis_imposition',
  'ress_attestation_paiement_aah_asi',
  'ress_decision_attribution_aah_asi',
  'ress_justificatif_vers_presta_social_algerien',
  'ress_btp_justificatif_conges_payes',
  'ress_ressources_du_conjoint',
  'ress_autre_document_origine_revenu',
  'ress_dernier_avis_imposition',
  'ress_secu_social_indemnite_jour',
  'ress_pension_alimentaires_ou_decision_justice',
];

export const getDocumentsByFormFromDemande = (demande: Demande, keys: string[]) => {
  return demande.documents.filter(el => el.categorie && keys.includes(el.categorie));
};

export const formatInfosStoreFromDemande = (demande: Demande) => {
  return {
    idCerfaRfng: demande.idCerfaRfng,
    idRfng: demande.idRfng,
    numeroDossier: demande.numeroDossier,
    signatureDocumentLieu: demande.signatureDocumentLieu,
    statut: demande.statut,
  };
};

export const formatIdentityStoreFromDemande = (demande: Demande) => {
  return {
    isValid: false, //Todo handle validation form
    isUntouched: true,
    documents: getDocumentsByFormFromDemande(demande, identityFilesKeys),
    etatCivil: {
      nom: demande?.usager?.etatCivil?.nom,
      nomUsage: demande?.usager?.etatCivil?.nomUsage,
      prenoms: demande?.usager?.etatCivil?.prenoms,
      sexe: demande?.usager?.etatCivil?.sexe,
      dateNaissance: demande?.usager?.etatCivil?.dateNaissance,
      nationalite: demande?.usager?.etatCivil?.nationalite,
      paysNaissance: demande?.usager?.etatCivil?.paysNaissance,
      villeNaissance: demande?.usager?.etatCivil?.villeNaissance,
      acteNaissanceTrad: demande?.usager?.etatCivil?.acteNaissanceTrad,
    },
    situationMatrimonial: demande?.usager?.situationMatrimonial,
    situationMatrimonialDate: demande?.usager?.situationMatrimonialDate,
    situationMatrimonialOriginal: demande?.usager?.situationMatrimonialOriginal,
    situationMatrimonialTrad: demande?.usager?.situationMatrimonialTrad,
    titreDeSejour: demande?.usager?.titreDeSejour,
    titreDeSejourAutre: demande?.usager?.titreDeSejourAutre,
    titreDeSejourFin: demande?.usager?.titreDeSejourFin,
    titreDeSejourMention: demande?.usager?.titreDeSejourMention,
    titreDeSejourMentionAutre: demande?.usager?.titreDeSejourMentionAutre,
    titreDeSejourRenouvellement: demande?.usager?.titreDeSejourRenouvellement,
    marieEnFrance: demande?.usager?.marieEnFrance,
  };
};

export const formatFamilyStoreFromDemande = (demande: Demande) => {
  return {
    isValid: false,
    isUntouched: true,
    documents: getDocumentsByFormFromDemande(demande, familyFilesKeys),
    conjoint: demande.demandeConjoints ? demande.demandeConjoints : [],
    enfants: demande.demandeEnfants ? demande.demandeEnfants : [],
    unions: demande.demandeUnions ? formatUnionList(demande.demandeUnions) : [],
    adresses: demande.demandeAdresses ? demande.demandeAdresses : [],
  };
};

const formatUnionList = (unions: Union[]) => {
  return unions.map(union => {
    return {
      ...union,
      dateDebut: union.dateDebut ? union.dateDebut.slice(0, 4) : undefined,
      dateFin: union.dateFin ? union.dateFin.slice(0, 4) : undefined,
    };
  });
};

export const formatAccomodationStoreFromDemande = (demande: Demande) => {
  return {
    isValid: false,
    isUntouched: true,
    //documents: []
    accomodations: demande.demandeLogements ? demande.demandeLogements : [],
  };
};

export const formatRessourcesStoreFromDemande = (demande: Demande) => {
  return {
    isValid: false,
    isUntouched: true,
    activites: demande.demandeResources ? demande.demandeResources : [],
    documents: getDocumentsByFormFromDemande(demande, ressourcesFilesKeys),
    arretTravail12DernierMois: demande.arretTravail12DernierMois,
    autresRessources: demande.autresRessources,
    beneficiaireAahOuAsi: demande.beneficiaireAahOuAsi,
    declarerConjointRessources: demande.declarerConjointRessources,
    pensionAlimentaire: demande.pensionAlimentaire,
    travailDansBtp: demande.travailDansBtp,
  };
};

export const formatCommitmentsStoreFromDemande = (demande: Demande) => {
  return {
    isValid: false,
    isUntouched: true,
    engagementArt4211: demande.engagementArt4211,
    engagementCertifieRenseignementsExact: demande.engagementCertifieRenseignementsExact,
    engagementChangementSituation: demande.engagementChangementSituation,
    engagementDeclareRfNonPolygamie: demande.engagementDeclareRfNonPolygamie,
    engagementRgpd: demande.engagementRgpd,
    engagementSignatureDocument: demande.engagementSignatureDocument,
    telFixe: demande?.usager?.telFixe,
    telPortable: demande?.usager?.telPortable,
    haveNumFix: demande?.usager?.telFixe ? true : false,
    email: demande?.usager?.email,
  };
};
