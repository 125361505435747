import { RFClefStatut, RFDemandeSummary, RFStatut } from 'models/types/api/rf';
import { RFCmsTraductionApi } from 'models/types/cms/rf/types';

interface statutType {
  badgeText: string;
  badgeColor: 'success' | 'info' | 'warning' | 'error';
}

const isDossierCloture = (demandeRF: RFDemandeSummary) => {
  return (
    demandeRF.clefStatut === RFClefStatut.PAS_ETAPE_DOSSIER_CLOTURE_DEMANDE_INTERRESSE ||
    demandeRF.clefStatut ===
      RFClefStatut.PAS_ETAPE_DOSSIER_CLOTURE_ACQUISITION_NATIONNALITE_FRANCAISE ||
    demandeRF.clefStatut === RFClefStatut.PAS_ETAPE_DOSSIER_CLOTURE_DEMENAGEMENT_DEPARTEMENT ||
    demandeRF.clefStatut === RFClefStatut.PAS_ETAPE_DOSSIER_CLOTURE_DT_NON_COMPETENTE ||
    demandeRF.clefStatut === RFClefStatut.PAS_ETAPE_DOSSIER_CLOTURE_DOSSIER_INCOMPLET ||
    demandeRF.clefStatut === RFClefStatut.PAS_ETAPE_DOSSIER_CLOTURE ||
    demandeRF.clefStatut === RFClefStatut.PAS_ETAPE_DOSSIER_CLOTURE_DOSSIER_IRRECEVABLE
  );
};

const wordingDossierCloture = (cmsTraductionApi: RFCmsTraductionApi) => {
  const text = cmsTraductionApi.clef_statuts.pas_etape__dossier_cloture__demande_interresse.statut;
  return typeof text === 'string' ? text.toUpperCase() : '';
};

const defineBadgeColor = (demandeRF: RFDemandeSummary) => {
  if (demandeRF.statut === RFStatut.FAVORABLE) {
    return 'success';
  }

  if (
    demandeRF.statut === RFStatut.RECEPTIONNE_ATTENTE_DOCUMENTS_COMPLEMENTAIRES ||
    demandeRF.statut === RFStatut.FAVORABLE_RELANCE_PAIEMENT
  ) {
    return 'warning';
  }
  return 'info';
};

export const getStatus = (
  demandeRF: RFDemandeSummary,
  cmsTraductionApi: RFCmsTraductionApi,
): statutType => {
  if (
    isDossierCloture(demandeRF) &&
    demandeRF.statut === RFStatut.DOSSIER_CLOTURE &&
    demandeRF.clefStatut
  ) {
    return {
      badgeText: wordingDossierCloture(cmsTraductionApi),
      badgeColor: defineBadgeColor(demandeRF),
    };
  }

  if (demandeRF.clefStatut) {
    return {
      badgeText: cmsTraductionApi.clef_statuts[demandeRF.clefStatut].statut.toUpperCase(),
      badgeColor: defineBadgeColor(demandeRF),
    };
  }

  return {
    badgeText: cmsTraductionApi.demande_rf_etapes.en_cours_de_traitement,
    badgeColor: 'info',
  };
};
