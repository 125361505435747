/* eslint-disable max-lines */
/* eslint-disable complexity */
/* eslint-disable react-hooks/rules-of-hooks */
import {
  articlesQuery1,
  articlesQuery2,
  articlesQuery3,
  articlesQuery4,
} from 'pages/Home/Home.query';
import { useGetArticlesCmsCollectionQuery } from 'redux/rtk/cms/cmsCommon';

export const getNewsContent = (lang: string) => {
  const {
    data: articlesCms1,
    error: articlesCmsError1,
    isLoading: isArticlesCmsLoading1,
  } = useGetArticlesCmsCollectionQuery({
    qs: articlesQuery1,
    locale: lang !== '' ? lang : 'fr',
  });

  const {
    data: articlesCms2,
    error: articlesCmsError2,
    isLoading: isArticlesCmsLoading2,
  } = useGetArticlesCmsCollectionQuery({
    qs: articlesQuery2,
    locale: lang !== '' ? lang : 'fr',
  });

  const {
    data: articlesCms3,
    error: articlesCmsError3,
    isLoading: isArticlesCmsLoading3,
  } = useGetArticlesCmsCollectionQuery({
    qs: articlesQuery3,
    locale: lang !== '' ? lang : 'fr',
  });

  const {
    data: articlesCms4,
    error: articlesCmsError4,
    isLoading: isArticlesCmsLoading4,
  } = useGetArticlesCmsCollectionQuery({
    qs: articlesQuery4,
    locale: lang !== '' ? lang : 'fr',
  });

  const allArticlesCms = [articlesCms1, articlesCms2, articlesCms3, articlesCms4];
  const areAllArticlesCmsFilled = allArticlesCms.every(articleCms => articleCms && articleCms.data);

  const articlesCms = { data: [] } as any;

  if (areAllArticlesCmsFilled) {
    // eslint-disable-next-line
    articlesCms1.data.forEach((_: any, index: number) => {
      articlesCms.data[index] = {
        attributes: {
          ...articlesCms1.data[index].attributes,
          image_principale_petite: articlesCms2.data[index].attributes.image_principale_petite,
          contenu: articlesCms3.data[index].attributes.contenu,
          categories_article: articlesCms4.data[index].attributes.categories_article,
        },
        id: articlesCms1.data[index].id,
      };
    });
  }

  const articlesCmsError =
    articlesCmsError1 || articlesCmsError2 || articlesCmsError3 || articlesCmsError4;

  const isArticlesCmsLoading =
    isArticlesCmsLoading1 ||
    isArticlesCmsLoading2 ||
    isArticlesCmsLoading3 ||
    isArticlesCmsLoading4;

  return {
    articlesCms,
    articlesCmsError,
    isArticlesCmsLoading,
  };
};
