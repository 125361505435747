import React from 'react';
import { useLocation } from 'react-router';

import useDocumentTitle from 'services/useDocumentTitle';
import { usePageVue } from 'services/tracking/usePageVue';

const AppRoute = ({ children }: { children: React.JSX.Element }) => {
  const location = useLocation();
  usePageVue(location.pathname);
  useDocumentTitle(location.pathname);
  return children;
};

export default AppRoute;
