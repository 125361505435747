/* eslint-disable max-params */
import * as yup from 'yup';

import { FormHelper } from 'helpers/app/FormHelper';
import {
  PARENTHESIS_EXIST_REGEX,
  PHONE_REGEX,
  NON_DIGIT_REGEX,
  PARENTHESIS_REGEX,
  EMAIL_REGEX,
} from 'helpers/app/regexHelper';
import { DemandeRfErrors } from 'models/types/cms/demandeRF/errors/type';
import { DemandeRfBloc2, DemandeRfUtilitaire } from 'models/types/cms/demandeRF/type';

const checkInternationalPhoneNumber = (number: string): boolean => {
  return PARENTHESIS_EXIST_REGEX.test(number)
    ? PHONE_REGEX.test(number.replace(NON_DIGIT_REGEX, '')) && PARENTHESIS_REGEX.test(number)
    : PHONE_REGEX.test(number.replace(NON_DIGIT_REGEX, ''));
};
export const getAddressValidationSchema = (
  errorsRfCms: DemandeRfErrors,
  utilitaireCms: DemandeRfUtilitaire,
  bloc2Cms: DemandeRfBloc2,
) => {
  const formHelper = new FormHelper(errorsRfCms);
  return yup
    .object({
      choix_adresse: formHelper.stringSchema(true),
      numero_de_voie: formHelper.stringSchema().max(10, errorsRfCms.erreur_texte_trop_long),
      nom_de_rue: formHelper.stringSchema(true, bloc2Cms.nom_rue),
      code_postal: formHelper.stringSchema().max(10, errorsRfCms.erreur_texte_trop_long),
      localite: formHelper.stringSchema(true, bloc2Cms.localite),
      province: formHelper.stringSchema(),
      pays: formHelper.stringSchema(true, bloc2Cms.pays),
      complement_adresse: formHelper.stringSchema(),
      adresse_email: formHelper
        .stringSchema()
        .test(utilitaireCms.format_email, errorsRfCms.erreur_email, email => {
          if (email && email !== '') {
            return EMAIL_REGEX.test(email);
          } else {
            return true;
          }
        }),
      telephone_adresse: formHelper
        .stringSchema()
        .test('telephone_adresse', utilitaireCms.format_telephone, tel => {
          if (tel && tel !== '') {
            return checkInternationalPhoneNumber(tel);
          }
          return true;
        }),
    })
    .defined();
};
