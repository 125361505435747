import { format } from 'date-fns';

import { Versement } from 'models/types/api/versement';
import { RappelsCms } from 'models/types/api/rappels';
import { RappelListItem, RappelType } from 'constants/rappels';
import { StatutVersement as StatutVersementType } from 'constants/payment';
import { DISPLAY_DATE_FORMAT } from 'constants/time';
import { getDurationSinceDateInDays } from 'services/time';
// import { getEtapeCouranteIndex } from 'services/procedure-etape';
import { RFDemandeSummary, RFStatut } from 'models/types/api/rf';
// import { RRIReinsertion, RRIRetour, RRIRetourStatut } from 'models/types/api/rri';
import { RFCmsTraductionApi } from 'models/types/cms/rf/types';

const shouldDisplayRappel = (date: Date, duration: number): boolean => {
  const daysSinceDate = getDurationSinceDateInDays(date);
  return daysSinceDate >= 0 && daysSinceDate < duration; // Change to daysSinceDate < duration WHEN SETUP IS FINISH
};

// ASILE
export const getRappelsFromDernierVersement = (
  cmsComponent: RappelsCms,
  dernierVersement?: Versement,
): RappelListItem[] => {
  if (
    !dernierVersement ||
    dernierVersement.statut !== StatutVersementType.VERSE ||
    !dernierVersement.dateVersement
  ) {
    return [];
  }

  if (
    shouldDisplayRappel(dernierVersement.dateVersement, cmsComponent.duree_notification_versement)
  ) {
    return [
      {
        key: 'dernier-versement',
        type: RappelType.INFORMATION,
        description: `${cmsComponent.texte_rappel_dernier_versement} ${format(
          new Date(dernierVersement.dateVersement),
          DISPLAY_DATE_FORMAT,
        )}`,
        date: dernierVersement.dateVersement,
      },
    ];
  }
  return [];
};

// REGROUPEMENT FAMILIAL
export const getRFRappelsFromDemandesEnCours = (
  cmsComponent: RappelsCms,
  demandesEnCours: RFDemandeSummary[],
  cmsRFTraductionAPI: RFCmsTraductionApi,
): RappelListItem[] =>
  demandesEnCours
    .filter(demande => demande.statut !== RFStatut.EN_COURS_DE_DEPOT)
    .filter(
      demande =>
        demande.dateStatut &&
        shouldDisplayRappel(demande.dateStatut, cmsComponent.duree_changement_statut_rf),
    )
    .map(demande => ({
      key: `statut-rf-${demande.numeroDossier}`,
      type: RappelType.INFORMATION,
      description:
        (cmsComponent.texte_rappel_changement_statut_rf,
        {
          numeroDossier: demande.numeroDossier || undefined,
          statut:
            demande.clefStatut && cmsRFTraductionAPI.clef_statuts[demande.clefStatut]?.statut
              ? cmsRFTraductionAPI.clef_statuts[demande.clefStatut].statut ?? ''
              : '',
          date: demande.dateStatut ? format(new Date(demande.dateStatut), DISPLAY_DATE_FORMAT) : '',
        }),
      date: demande.dateStatut,
    }));

// // RETOUR
// export const getChangementStatutRetourRappel = (
//   cmsComponent: RappelsCms,
//   retour: RRIRetour | null,
// ) => {
//   if (!retour) {
//     return [];
//   }

//   if (RRIRetourStatut.CLOS === retour.statut) {
//     return [
//       {
//         key: `rri-statut-${retour.statut}`,
//         type: RappelType.INFORMATION,
//         description: cmsEnrichedText(cmsComponent.texte_rappel_statut_retour_clos),
//         date: null,
//       },
//     ];
//   }

//   if (RRIRetourStatut.VOYAGE_PLANIFIE === retour.statut) {
//     return [
//       {
//         key: `rri-statut-${retour.statut}`,
//         type: RappelType.INFORMATION,
//         description: cmsEnrichedText(cmsComponent.texte_rappel_statut_retour_voyage_planifie),
//         date: null,
//       },
//     ];
//   }

//   if (retour.reorganisationVoyage) {
//     return [
//       {
//         key: `rri-statut-reorganisation-voyage`,
//         type: RappelType.INFORMATION,
//         description: cmsEnrichedText(
//           cmsComponent.texte_rappel_statut_retour_attente_reorganisation,
//         ),
//         date: null,
//       },
//     ];
//   }

//   return [];
// };

// // REINSERTION
// export const getReinsertionRappelsFromAllReinsertion = (
//   cmsComponent: RappelsCms,
//   reinsertions: RRIReinsertion[],
// ): RappelListItem[] => {
//   const reinsertionsAndRappelDate = reinsertions.map(reinsertion => {
//     const etapeCouranteIndex = getEtapeCouranteIndex(reinsertion.etapes);
//     const rappelsDate = [
//       null,
//       reinsertion.niveauAide.dateCommandeEtude,
//       reinsertion.niveauAide.decision?.dateDecision,
//     ];

//     return { reinsertion, rappelDate: rappelsDate[etapeCouranteIndex] };
//   });

//   const reinsertionsWithRappelToDisplay = reinsertionsAndRappelDate.filter(
//     ({ rappelDate }) =>
//       rappelDate &&
//       shouldDisplayRappel(rappelDate, cmsComponent.duree_changement_statut_reinsertion),
//   );

//   return reinsertionsWithRappelToDisplay.map(({ reinsertion, rappelDate }) => ({
//     key: `statut-rf-${reinsertion.path}`,
//     type: RappelType.INFORMATION,
//     description:
//       // <FormattedEnrichedCMSMessage
//       //   translatedText={cmsComponent.texte_rappel_changement_statut_reinsertion}
//       //   values={{ dateDeChangement: format(rappelDate as Date, DISPLAY_DATE_FORMAT) }}
//       // />
//       cmsEnrichedText(cmsComponent.texte_rappel_changement_statut_reinsertion),
//     date: rappelDate as Date,
//   }));
// };
