export const etape1_portail__en_cours_de_depot_en_cours_de_depot = {
  beneficiaires: [
    {
      lienFamilial: 'enfant',
      prenomBeneficiaire: 'JEAN',
      nomBeneficiaire: 'LEROY',
      dateNaissance: '2010-01-01',
      nationalite: 'Algérienne',
      paysResidence: 'Algérie',
    },
  ],
  numeroDossier: '47023000000010',
  adresseDemandeur: 'Chemin des Ecoliers 47120 ST JEAN DE DURAS',
  adresseEnqueteDemandeur: null,
  directionTerritoriale: 'DT Bordeaux',
  titreSejourValiditeDateDebut: null,
  titreSejourValiditeDateFin: '2023-05-01',
  clefStatut: 'portail__en_cours_de_depot',
  statut: 'en_cours_de_depot',
  dateStatut: '2023-08-07',
  numeroAgdref: '7703023991',
  responsableStatut: 'OFII',
  documentsManquant: [],
  enqueteLogement: null,
  enqueteRessources: null,
  etapes: [
    {
      statut: 'en_cours',
      nom: 'receptionnee',
      numero: 1,
      date: '2023-08-07',
    },
    {
      statut: 'a_venir',
      nom: 'en_cours_instruction',
      numero: 2,
      date: null,
    },
    {
      statut: 'a_venir',
      nom: 'en_attente_decision',
      numero: 3,
      date: null,
    },
    {
      statut: 'a_venir',
      nom: 'decision',
      numero: 4,
      date: null,
    },
  ],
  dateEnvoiConsulat: null,
  typeDepot: 'portail',
  idProcedure: null,
  postalStatut: null,
  newDemande: false,
  shouldDisplayEnquete: false,
};
