/* eslint-disable complexity */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Card from '@codegouvfr/react-dsfr/Card';
import { Tag } from '@codegouvfr/react-dsfr/Tag';

import { setArticles } from 'redux/pages';
import { getArticles } from 'redux/pages/selectors';
import { getNewsContent } from 'helpers/cms/getNewsContent';
import CategorySelector from 'components/Molecules/CategorySelector/CategorySelector';
import { ArticlesCollectionElement } from 'models/types/app/collections';
import Loader from 'components/Atoms/Loader/Loader';
import AppBreadcrumb from 'components/Atoms/Breadcrumb/Breadcrumb';
import { useGetArticlePageQuery } from 'redux/rtk/cms/cmsArticles';
import { getCurrentLocale } from 'redux/locale/selectors';

const News = () => {
  const lang = useSelector(getCurrentLocale) as string;

  const { articlesCms, articlesCmsError, isArticlesCmsLoading } = getNewsContent(lang);

  const {
    data: articlesPageCms,
    error: articlesPageCmsError,
    isLoading: isArticlesPageCmsLoading,
  } = useGetArticlePageQuery({
    locale: lang !== '' ? lang : 'fr',
  });

  const articlesStore = useSelector(getArticles);
  const dispatch = useDispatch();

  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const [displayedCategory, setDisplayedCategory] = useState<ArticlesCollectionElement[] | null>(
    null,
  );

  useEffect(() => {
    if (articlesCms.data && articlesCms.data.length > 1 && (articlesStore?.length ?? 0) <= 1) {
      dispatch(setArticles(articlesCms.data));
    }
  }, [articlesCms]);

  useEffect(() => {
    if (articlesStore && selectedCategory) {
      setDisplayedCategory(articlesStore.filter(el => el.badgeTitle === selectedCategory));
    } else if (articlesStore) {
      setDisplayedCategory(articlesStore);
    }
  }, [selectedCategory, articlesStore]);

  const getCategories = () => {
    const categories: string[] = [];

    articlesStore?.forEach(el => {
      if (el.badgeTitle && !categories.includes(el.badgeTitle)) {
        categories.push(el.badgeTitle);
      }
    });
    return categories;
  };

  if (isArticlesCmsLoading || isArticlesCmsLoading || isArticlesPageCmsLoading) {
    return <Loader />;
  }

  if (articlesCmsError || articlesCmsError || articlesPageCmsError) {
    return <div>Redirect page error</div>;
  }

  if (!articlesCms) {
    return null;
  }

  return (
    <main>
      <div className={`fr-container fr-mb-5w`}>
        <AppBreadcrumb />
        <h1 className="fr-mt-6w fr-mt-md-7w fr-mb-5w">{articlesPageCms.tous_les_articles}</h1>
        {articlesStore && (
          <CategorySelector
            elements={getCategories()}
            callback={(id: string) => {
              if (selectedCategory !== id) {
                setSelectedCategory(id);
              } else {
                setSelectedCategory(null);
              }
            }}
            selectedCategory={selectedCategory}
          />
        )}
        {displayedCategory && (
          <div className="fr-grid-row fr-grid-row--gutters">
            {displayedCategory.map(el => {
              return (
                <div
                  className={`fr-col-xs-4 fr-col-sm-12 fr-col-md-4 fr-col-lg-4 news-card`}
                  key={el.id}
                >
                  <Card
                    detail={
                      el.badgeTitle ? (
                        <div className="fr-badges-group">
                          <Tag className={`fr-tag${el.badgeType}`} small>
                            {el.badgeTitle}
                          </Tag>
                        </div>
                      ) : undefined
                    }
                    title={el.title}
                    imageAlt={el.image.alt ? el.image.alt : ''}
                    enlargeLink
                    imageUrl={el.image.src ? el.image.src : ''}
                    desc={el.description}
                    linkProps={{
                      href: el.link.href,
                    }}
                  />
                </div>
              );
            })}
          </div>
        )}
      </div>
    </main>
  );
};

export default News;
