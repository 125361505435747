import React, { useEffect } from 'react';
import { createModal } from '@codegouvfr/react-dsfr/Modal';
import { useSelector } from 'react-redux';

import { cmsEnrichedText } from 'components/Atoms/HtmlParsers/PageContentHtmlParser';
import { isDemandeHasBeenSubmitted } from 'redux/demandeRf/selectors';
import { useGetDashboardCmsQuery } from 'redux/rtk/cms/cmsDashboard';

const demandeSubmittedModal = createModal({
  id: 'demande-submitted',
  isOpenedByDefault: false,
});

type Props = {
  lang: string;
};

const DemandeRfSubmittedModal: React.FC<Props> = ({ lang }) => {
  const isDemandeSubmitted = useSelector(isDemandeHasBeenSubmitted);
  const { data: dashboardCmsBase, isLoading: isDashboardCmsLoading } = useGetDashboardCmsQuery({
    locale: lang !== '' ? lang : 'fr',
  });

  const modalElement = document.getElementById(demandeSubmittedModal.id);
  useEffect(() => {
    if (isDemandeSubmitted && modalElement) {
      demandeSubmittedModal.open();
    }
  }, [isDemandeSubmitted, modalElement]);

  if (isDashboardCmsLoading) return null;
  if (!dashboardCmsBase) return null;

  return (
    <demandeSubmittedModal.Component
      title={dashboardCmsBase.data.attributes.titre_modale_confirmation_envoi_demande_rf}
      iconId="fr-icon-check-line"
    >
      <div>
        {cmsEnrichedText(
          dashboardCmsBase.data.attributes.content_modale_confirmation_envoi_demande_rf,
        )}
        <a
          href={dashboardCmsBase.data.attributes.bouton_je_donne_mon_avis_lien}
          target="_blank"
          rel="noreferrer"
          className="mon-avis"
        >
          <img
            src={dashboardCmsBase.data.attributes.bouton_je_donne_mon_avis_lien_image}
            alt={dashboardCmsBase.data.attributes.bouton_je_donne_mon_avis_alt}
          />
        </a>
      </div>
    </demandeSubmittedModal.Component>
  );
};

export default DemandeRfSubmittedModal;
