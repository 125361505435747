import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Input from '@codegouvfr/react-dsfr/Input';
import Button from '@codegouvfr/react-dsfr/Button';
import { Highlight } from '@codegouvfr/react-dsfr/Highlight';

import { useGetUserQuery } from 'redux/rtk/api/apiUser';
import { setAccountPage } from 'redux/pages';
import { useGetAccountCmsQuery } from 'redux/rtk/cms/cmsAccount';
import { getCurrentLocale } from 'redux/locale/selectors';
import { setUser } from 'redux/user';
import { getUser } from 'redux/user/selector';
import { getAccountPage } from 'redux/pages/selectors';
import { getPasswordModificationLink } from 'services/authentication';
import Loader from 'components/Atoms/Loader/Loader';
import AppBreadcrumb from 'components/Atoms/Breadcrumb/Breadcrumb';

import './Compte.scss';

/* eslint-disable complexity */
const Compte: FC = () => {
  const dispatch = useDispatch();
  const lang = useSelector(getCurrentLocale) as string;
  const accountPage = useSelector(getAccountPage);

  const {
    data: accountCms,
    error: accountCmsError,
    isLoading: isAccountCmsLoading,
  } = useGetAccountCmsQuery({
    locale: lang !== '' ? lang : 'fr',
  });

  const { data: userData, error: userError, isLoading: isUserLoading } = useGetUserQuery();

  const userStore = useSelector(getUser);

  const redirectToPasswordModification = (locale: string) => {
    window.location.href = getPasswordModificationLink(locale);
  };

  useEffect(() => {
    if (accountCms) {
      dispatch(setAccountPage(accountCms.data));
    }
  }, [accountCms, dispatch]);

  useEffect(() => {
    if (userData) {
      dispatch(setUser(userData));
    }
  }, [userData, dispatch]);

  if (isAccountCmsLoading || isUserLoading) {
    return <Loader />;
  }

  if (accountCmsError || userError) {
    return <div>Redirect page error</div>;
  }

  if (!accountCms) {
    return null;
  }

  return (
    <main id="account">
      <div className={`fr-container`}>
        <div>
          <AppBreadcrumb />
          <h1 className="fr-mt-6w fr-mt-md-7w fr-mb-5w">{accountPage.titre}</h1>
        </div>
        {accountPage && (
          <div className="container-fields fr-pt-3w fr-p-md-5w fr-mb-3w fr-mb-md-7w">
            {accountPage && userStore.numEtranger && (
              <Highlight size="lg">
                {accountPage.identity_labels.identifier + ' : '}
                <span className="stranger_number">{userStore.numEtranger}</span>
              </Highlight>
            )}
            <div className="fr-mt-5w">
              <h2 className="fr-h3">{accountPage.identity_labels.titre}</h2>
              <div className={`fr-grid-row account-page-section`}>
                <Input
                  className="account-page-input"
                  label={accountPage.identity_labels.birthname}
                  disabled={true}
                  nativeInputProps={{
                    value: userStore.nom_naissance ? userStore.nom_naissance : '',
                  }}
                />
                <Input
                  className="account-page-input"
                  label={accountPage.identity_labels.firstname}
                  disabled={true}
                  nativeInputProps={{
                    value: userStore.prenom ? userStore.prenom : '',
                  }}
                />
                <Input
                  className="account-page-input"
                  label={accountPage.identity_labels.lastname}
                  disabled={true}
                  nativeInputProps={{
                    value: userStore.nom_usage ? userStore.nom_usage : '',
                  }}
                />
                <Input
                  className="account-page-input"
                  label={accountPage.identity_labels.gender}
                  disabled={true}
                  nativeInputProps={{
                    value: userStore.sexe ? userStore.sexe : '',
                  }}
                />
                <Input
                  className="account-page-input"
                  label={accountPage.identity_labels.birthdate}
                  disabled={true}
                  nativeInputProps={{
                    value: userStore.date_naissance ? userStore.date_naissance : '',
                  }}
                />
                <Input
                  className="account-page-input"
                  label={accountPage.identity_labels.birth_state}
                  disabled={true}
                  nativeInputProps={{
                    value: userStore.pays_naissance ? userStore.pays_naissance : '',
                  }}
                />
                <Input
                  className="account-page-input"
                  label={accountPage.identity_labels.nationality}
                  disabled={true}
                  nativeInputProps={{
                    value: userStore.nationalite ? userStore.nationalite : '',
                  }}
                />
                <Input
                  className="account-page-input"
                  label={accountPage.identity_labels.date_of_entry}
                  disabled={true}
                  nativeInputProps={{
                    value: userStore.date_entree_en_france ? userStore.date_entree_en_france : '',
                  }}
                />
                <Input
                  className="account-page-input"
                  label={accountPage.identity_labels.family_situation}
                  disabled={true}
                  nativeInputProps={{
                    value: userStore.situation_familiale ? userStore.situation_familiale : '',
                  }}
                />
              </div>
            </div>
            <hr className="fr-hidden fr-unhidden-md fr-mb-5w fr-mt-5w" />
            <div className="fr-mt-5w fr-mt-md-0">
              <h2 className="fr-h3">{accountPage.contact_detail_labels.titre}</h2>
              <div className={`fr-grid-row account-page-section`}>
                <Input
                  className="account-page-input"
                  label={accountPage.contact_detail_labels.email}
                  disabled={true}
                  nativeInputProps={{
                    value: userStore.adresse_mail ? userStore.adresse_mail : '',
                  }}
                />
                <Input
                  className="account-page-input"
                  label={accountPage.contact_detail_labels.phone}
                  disabled={true}
                  nativeInputProps={{
                    value: userStore.telephone ? userStore.telephone : '',
                  }}
                />
              </div>
            </div>
            <hr className="fr-hidden fr-unhidden-md fr-mb-5w fr-mt-5w" />
            <div className="fr-mb-5w fr-mb-md-0 fr-mt-5w fr-mt-md-0">
              <h2 className="fr-h3">{accountPage.password_change_label.titre}</h2>
              <div className={`fr-grid-row account-page`}>
                <Input
                  className="account-page-input"
                  label={accountPage.password_change_label.button_text}
                  disabled={true}
                  nativeInputProps={{
                    value: 'Abcdefghijklmno',
                    type: 'password',
                  }}
                />
                <Button
                  className="password_change_button fr-mt-1w"
                  onClick={() => redirectToPasswordModification(lang)}
                >
                  {accountPage.password_change_label.button_text
                    ? accountPage.password_change_label.button_text
                    : ''}
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </main>
  );
};

export default Compte;
