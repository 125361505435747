import { FC } from 'react';

interface BannerProps {
  title: string | undefined;
  description: string | undefined;
  actionButton: {
    title: string | undefined;
    href: string | undefined;
  };
}

const Banner: FC<BannerProps> = ({ title, description, actionButton }) => {
  return (
    <div className="fr-container-fluid ofii_banner fr-mb-6w fr-mb-md-7w">
      <div className="fr-container">
        <div className="fr-grid-row fr-grid-row--gutters">
          <div className="fr-col-lg-7">
            <h1 className="fr-mt-3w fr-mt-md-5w fr-mb-5w">{title}</h1>
            <p className="fr-mb-3w">{description}</p>
            <p className="fr-mb-5w">
              <a
                className="fr-btn fr-btn--secondary fr-btn--icon-right fr-icon-arrow-right-line"
                href={actionButton.href}
              >
                {actionButton.title}
              </a>
            </p>
          </div>
          <div className="fr-col-12 fr-col-lg-5 fr-pl-0 fr-pl-md-4w fr-pb-0">
            <figure className="fr-content-media fr-my-0">
              <div className="fr-content-media__img">
                <img alt="" src="img/landing-image.jpg" className="moz-compatibility" />
              </div>
            </figure>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
