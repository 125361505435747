import React, { FC } from 'react';

import { RFDocumentManquant } from 'models/types/api/rf';

type Props = {
  documentList: RFDocumentManquant[];
  headingText: string;
};
const DocumentsManquants: FC<Props> = ({ documentList, headingText }) => {
  return (
    <>
      <br />
      <br />
      <span className="bold">{headingText}</span>
      <ul>
        {documentList.map(doc => (
          <>
            <li key={doc.nomDocument}>{doc.nomDocument}</li>
          </>
        ))}
      </ul>
    </>
  );
};

export default DocumentsManquants;
