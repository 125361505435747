import { FC } from 'react';

interface ImageProps {
  src: string;
  ariaHidden: boolean;
  alt?: string;
  className?: string;
}

const AppImage: FC<ImageProps> = ({ className, src, ariaHidden, alt }) => {
  return <img className={className} aria-hidden={ariaHidden} src={src} alt={alt} />;
};

export default AppImage;
