import { FC, Fragment } from 'react';
import { Control, useController } from 'react-hook-form';
import Select, { SelectProps } from '@codegouvfr/react-dsfr/Select';

import { computeFieldStatus } from 'helpers/app/fieldsHelper';
import { WidgetGenericObject } from 'models/types/cms/widgetPageContenu/types';

import Infobulle from '../Infobulle/Infobulle';

interface ArrayOption {
  label: string;
  value: string;
}

export type CommonSelectProps = SelectProps & {
  name: string;
  options: ArrayOption[];
  control: Control<any>;
  defaultValue: string;
  id: string;
  required?: boolean;
  infobulle?: WidgetGenericObject[] | null;
};

const CommonSelect: FC<CommonSelectProps> = props => {
  const {
    field: { onChange, onBlur, value, name, ref },
    fieldState: { isTouched, isDirty, error },
  } = useController({
    control: props.control,
    name: props.name,
  });

  return (
    <Select
      hint={props.hint}
      disabled={props.disabled}
      state={computeFieldStatus(isTouched, isDirty, error)}
      stateRelatedMessage={error?.message}
      label={
        <>
          {props.label}
          {props.required && <span className="fr-required-indicator"> *</span>}
          {props.infobulle && <Infobulle name={props.id} content={props.infobulle} />}
        </>
      }
      nativeSelectProps={{
        id: props.id,
        name: name,
        onChange: onChange,
        onBlur: onBlur,
        value: value || '',
        ref: ref,
        autoComplete: props?.nativeSelectProps?.autoComplete,
      }}
    >
      <Fragment key=".0">
        <option value="" disabled hidden>
          {props.defaultValue}
        </option>
        {props.options.map(({ label, value }) => (
          <option value={value} key={value}>
            {label}
          </option>
        ))}
      </Fragment>
    </Select>
  );
};

export default CommonSelect;
