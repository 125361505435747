export const noDigitCharacters =
  /^[a-zA-ZáàâäåãæçéèêëíìîïñóòôöõøœšúùûüýÿžÁÀÂÄÅÃÆÇÉÈÊËÍÌÎÏÑÓÒÔÖÕØŒŠÚÙÛÜÝŸŽ]{1}[a-zA-ZáàâäåãæçéèêëíìîïñóòôöõøœšúùûüýÿžÁÀÂÄÅÃÆÇÉÈÊËÍÌÎÏÑÓÒÔÖÕØŒŠÚÙÛÜÝŸŽ '-]*[a-zA-ZáàâäåãæçéèêëíìîïñóòôöõøœšúùûüýÿžÁÀÂÄÅÃÆÇÉÈÊËÍÌÎÏÑÓÒÔÖÕØŒŠÚÙÛÜÝŸŽ]{1}$/;

export const validNumeroEtranger = /^\d+$/;

export const NO_SPECIAL_CHAR_REGEX = new RegExp(/^[_A-z0-9]*((-|\s)*[_A-z0-9])*$/);

export const PARENTHESIS_REGEX = new RegExp(/^(?:[^(]*\([^(]*\)[^(]*)?$/);

export const PARENTHESIS_EXIST_REGEX = new RegExp(/\(|\)/);

export const PHONE_REGEX = new RegExp(/^[-.\s\d(+)]{10,23}$/);

export const NON_DIGIT_REGEX = new RegExp(/[^0-9]/g);

export const EMAIL_REGEX = new RegExp(
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
);
