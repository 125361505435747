import qs from 'qs';
import { InfobulleQuery } from '../DemandeRF/Queries/common.query';

export const cmsContactQuery1 = qs.stringify(
  {
    populate: {
      fields: ['description'],
      infobulle_numero_etranger: InfobulleQuery,
      formulaire_contact: {
        populate: {
          choix_objet_demande_access_portail: {
            populate: {
              fields: ['texte'],
            },
          },
          choix_objet_demande_rf: {
            populate: {
              fields: ['texte'],
            },
          },
          choix_objet_demande_asile: {
            populate: {
              fields: ['texte'],
            },
          },
          fields: [
            'titre',
            'avertissement_champs_obligatoires',
            'label_civilite',
            'hint_civilite',
            'label_prenom',
            'hint_prenom',
            'label_nom',
            'hint_nom',
            'label_email',
            'hint_email',
          ],
        },
      },
    },
  },
  {
    encodeValuesOnly: true,
  },
);

export const cmsContactQuery2 = qs.stringify(
  {
    populate: {
      formulaire_contact: {
        populate: {
          fields: [
            'label_objet_demande',
            'label_objet_demande_access_portail',
            'label_objet_demande_rf',
            'label_objet_demande_asile',
            'label_detail',
            'champ_detail_valeur_par_defaut',
            'texte_bouton_formulaire',
            'message_succes_titre',
            'message_succes_description',
            'message_erreur_titre',
            'message_erreur_description',
            'email_invalide',
            'erreur_champ_obligatoire',
            'succes_retour_accueil',
            'avertissement_protection_donnees',
            'lien_politique_confidentialite',
            'erreur_detail_trop_long',
            'message_succes_final',
            'bouton_retour_accueil',
            'label_numero_etranger',
            'hint_numero_etranger',
          ],
        },
      },
    },
  },
  {
    encodeValuesOnly: true,
  },
);
