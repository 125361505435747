/* eslint-disable complexity */
/* eslint-disable max-lines */
import React, { useEffect, useMemo, useState } from 'react';
import { useFieldArray, useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import CallOut from '@codegouvfr/react-dsfr/CallOut';

import CommonRowContainer from 'components/Molecules/Forms/CommonRowContainer/CommonRowContainer';
import FormModalLayout from 'components/Molecules/DemandeRf/FormModalLayout/FormModalLayout';
import UploadFiles from 'components/Atoms/UploadFiles/UploadFiles';
import RadioButtonContainer from 'components/Molecules/Forms/RadioButtonContainer/RadioButtonContainer';
import { cmsEnrichedText } from 'components/Atoms/HtmlParsers/PageContentHtmlParser';
import { RESIDENCE_LIEU } from 'constants/regroupementFamiliale';
import { CHOIX } from 'constants/global';
import { handleArrayHydratation, handleFieldHydratation } from 'helpers/api/FormHydratation';
import { Conjoint as ConjointType } from 'models/types/api/rf';
import { ConjointDemandeForm } from 'models/types/app/demandeRf';
import { getRfCmsContents } from 'pages/DemandeRF/Helpers/Cms/getRfCmsContents';
import { flatArray } from 'pages/DemandeRF/Helpers/common';
import { useNewConjointMutation, useUpdConjointMutation } from 'redux/rtk/api/apiRf';
import { updConjointStore } from 'redux/demandeRf';
import { getListDocumentsByConjoint } from 'redux/demandeRf/selectors';
import { createError, createSuccess, submitForm } from 'redux/forms';
import { getConjointFormToSubmit } from 'redux/forms/selectors';
import { useGetNationalitesNonEligiblesQuery } from 'redux/rtk/api/apiCommon';

import ConjointUseForm from './components/ConjointUseForm';
import ConjointInfoNoms from './components/ConjointInfoNoms';
import ConjointInfoNaissance from './components/ConjointInfoNaissance';
import ConjointMariagePrecedent from './components/ConjointMariagePrecedent';
import { getConjointValidationSchema } from './helpers/conjointValidationSchema';
import { conjointAppToApiFormatter } from './helpers/conjointAppToApiFormatter';
import { getUploadFilesParameters } from './helpers/conjointUploadFiles';
import conjointMapping from './helpers/conjointMapping';
import { conjointApiToAppFormatter } from './helpers/conjointApiToAppFormatter';

type ConjointProps = {
  lang: string;
  demandeId: string;
  conjointModal: any;
  conjointInit: ConjointType;
};

const Conjoint: React.FC<ConjointProps> = ({ lang, demandeId, conjointInit, conjointModal }) => {
  const [conjoint, setConjoint] = useState<ConjointType>(conjointInit);
  const [forceModalOpen, setForceModalOpen] = useState<boolean>(false);
  const { bloc2Cms, globalCms, errorCms, utilitaireCms } = getRfCmsContents(lang);
  const dispatch = useDispatch();
  const [newConjoint, { data: dataCreated, isSuccess: isSuccessCreated, isError: isErrorCreated }] =
    useNewConjointMutation();
  const [updConjoint, { data: dataUpdated, isSuccess: isSuccessUpdated, isError: isErrorUpdated }] =
    useUpdConjointMutation();
  const { data: nationalitesNonEligiblesData } = useGetNationalitesNonEligiblesQuery();

  const [conjointForm, setConjointForm] = useState<ConjointDemandeForm | null>(null);
  const makeListDocumentsByConjoint = useMemo(
    () => getListDocumentsByConjoint(conjoint.id),
    [conjoint.id],
  );
  const documents = useSelector(makeListDocumentsByConjoint);

  const {
    handleSubmit,
    getValues,
    setValue,
    trigger,
    watch,
    formState, //isValid, errors, touchedFields
    control,
  } = ConjointUseForm(errorCms, utilitaireCms, flatArray(nationalitesNonEligiblesData));

  const rFDemande = useWatch({
    control,
    name: 'rf_demande',
  });
  const conjointEtranger = useWatch({
    control,
    name: 'conjoint_etranger',
  });
  const titreSejourFrConjoint = useWatch({
    control,
    name: 'titre_sejour_fr_conjoint',
  });

  const errors = formState.errors;
  const isValid = formState.isValid;

  const uploadFilesParameter = getUploadFilesParameters(
    demandeId ? demandeId : '',
    conjoint?.id ? conjoint.id : undefined,
    {
      control,
      setValue,
      getValues,
      trigger,
      errors,
      watch,
    },
    utilitaireCms,
  );

  const filesMapping = conjointMapping
    .getGeneralMapping()
    .getFilesFields(bloc2Cms, uploadFilesParameter, errorCms);

  const formFieldsMapping = conjointMapping.getGeneralMapping().getFormFields(
    { bloc2Cms, globalCms },
    {
      setValue,
      getValues,
      control,
      trigger,
    },
    lang,
  );

  const fieldArrayMapping = {
    acte_naissance_conjoint_files: useFieldArray({
      control,
      name: 'acte_naissance_conjoint_files',
    }),
    acte_naissance_conjoint_traduit_files: useFieldArray({
      control,
      name: 'acte_naissance_conjoint_traduit_files',
    }),
    conjoint_titre_identite_files: useFieldArray({
      control,
      name: 'conjoint_titre_identite_files',
    }),
    conjoint_jugement_divorce_files: useFieldArray({
      control,
      name: 'conjoint_jugement_divorce_files',
    }),
    conjoint_jugement_divorce_traduit_files: useFieldArray({
      control,
      name: 'conjoint_jugement_divorce_traduit_files',
    }),
    conjoint_titre_sejour_files: useFieldArray({
      control,
      name: 'conjoint_titre_sejour_files',
    }),
  };

  const onSubmit = async (data: any, forceModal?: boolean) => {
    const conjointId = conjoint?.uuid ? null : conjoint?.id ? conjoint?.id : null;
    const conjointApi = conjointAppToApiFormatter(data, conjointId, documents, isValid);
    if (forceModal !== undefined) {
      setForceModalOpen(forceModal);
    } else {
      setForceModalOpen(false);
    }
    if (conjointId) {
      await updConjoint({
        demandeId: demandeId,
        body: conjointApi,
      });
    } else {
      await newConjoint({
        demandeId: demandeId,
        body: conjointApi,
      });
    }
  };

  useEffect(() => {
    if (isSuccessCreated && dataCreated) {
      dispatch(updConjointStore({ ...dataCreated, ...{ uuid: conjoint?.uuid } }));
      setConjoint(dataCreated);
      if (!forceModalOpen) {
        conjointModal.close();
      }
      dispatch(createSuccess(bloc2Cms.conjoint_ajoute));
    }
  }, [isSuccessCreated, dataCreated]);

  useEffect(() => {
    if (isSuccessUpdated && dataUpdated) {
      dispatch(updConjointStore(dataUpdated));
      if (!forceModalOpen) {
        conjointModal.close();
      }
      dispatch(createSuccess(bloc2Cms.conjoint_modifie));
    }
  }, [isSuccessUpdated, dataUpdated]);

  useEffect(() => {
    if (isErrorCreated || isErrorUpdated) {
      dispatch(
        createError(isErrorCreated ? errorCms.erreur_enregistrement : errorCms.erreur_modification),
      );
    }
  }, [isErrorCreated, isErrorUpdated]);

  useEffect(() => {
    if (conjoint && documents) {
      setConjointForm(conjointApiToAppFormatter(conjoint, documents));
    }
  }, [conjoint]);

  const setValuesFromDemand = (formDemand: any) => {
    for (const key in formDemand) {
      if (Array.isArray(formDemand[key])) {
        if (formDemand[key].length > 0) {
          handleArrayHydratation(key, formDemand, fieldArrayMapping);
        }
      } else if (formDemand[key] !== null && formDemand[key] !== undefined) {
        handleFieldHydratation(
          key,
          formDemand,
          getConjointValidationSchema(errorCms, utilitaireCms, nationalitesNonEligiblesData),
          setValue,
        );
      }
    }
  };

  useEffect(() => {
    if (conjointForm) {
      setValuesFromDemand(conjointForm);
    }
  }, [conjointForm]);

  const conjointFormToSubmit = useSelector(getConjointFormToSubmit);
  useEffect(() => {
    if (conjointFormToSubmit) {
      onSubmitNoValidationAfterUpload();
      dispatch(submitForm('conjoint'));
    }
  }, [conjointFormToSubmit]);

  const onSubmitNoValidationAfterUpload = () => {
    const values = control._formValues;
    delete values.validation;
    onSubmit(values, true);
  };

  return (
    <FormModalLayout
      formIdentifier="conjoint-form"
      requiredInfos={true}
      onSubmit={onSubmit}
      handleSubmit={handleSubmit}
      lang={lang}
      control={control}
      isValid={isValid}
    >
      <CommonRowContainer
        children={[
          <RadioButtonContainer {...formFieldsMapping.rf_demande} large orientation="horizontal" />,
        ]}
      />
      <CommonRowContainer
        legend={bloc2Cms.conjoint_vit}
        children={[
          <RadioButtonContainer
            {...formFieldsMapping.conjoint_etranger}
            large
            orientation="horizontal"
          />,
        ]}
      />
      <div className="container fr-mt-6v fr-mt-lg-8v fr-mt-md-8v">
        <hr />
      </div>

      {rFDemande === CHOIX.OUI && (
        <>
          {conjointEtranger === RESIDENCE_LIEU.ETRANGER && (
            <>
              <ConjointInfoNoms lang={lang} formFieldsMapping={formFieldsMapping} />
              <ConjointInfoNaissance
                lang={lang}
                fieldArrayMapping={fieldArrayMapping}
                filesMapping={filesMapping}
                formFieldsMapping={formFieldsMapping}
                isNew={conjoint?.uuid ? 'oui' : 'non'}
                control={control}
                formState={formState}
                nationalitesNonEligibles={flatArray(nationalitesNonEligiblesData)}
              />
              {formState.errors.date_naissance_conjoint?.type !== 'mustBeMajor' &&
                formState.errors.nationalite_conjoint?.type !== 'notOneOf' && (
                  <ConjointMariagePrecedent
                    fieldArrayMapping={fieldArrayMapping}
                    filesMapping={filesMapping}
                    formFieldsMapping={formFieldsMapping}
                    isNew={conjoint?.uuid ? 'oui' : 'non'}
                    control={control}
                    lang={lang}
                  />
                )}
            </>
          )}
          {conjointEtranger === RESIDENCE_LIEU.FRANCE && (
            <>
              <CommonRowContainer
                children={[
                  <RadioButtonContainer
                    {...formFieldsMapping.titre_sejour_fr_conjoint}
                    large
                    orientation="horizontal"
                  />,
                ]}
              />
              {titreSejourFrConjoint === CHOIX.OUI && (
                <>
                  <ConjointInfoNoms lang={lang} formFieldsMapping={formFieldsMapping} />
                  <ConjointInfoNaissance
                    lang={lang}
                    fieldArrayMapping={fieldArrayMapping}
                    filesMapping={filesMapping}
                    formFieldsMapping={formFieldsMapping}
                    isNew={conjoint?.uuid ? 'oui' : 'non'}
                    control={control}
                    formState={formState}
                    nationalitesNonEligibles={flatArray(nationalitesNonEligiblesData)}
                  />
                  <UploadFiles
                    {...filesMapping.conjoint_titre_sejour_files.upload}
                    fieldArray={fieldArrayMapping.conjoint_titre_sejour_files}
                    isNew={conjoint?.uuid ? 'oui' : 'non'}
                    lang={lang}
                  />
                </>
              )}
              {titreSejourFrConjoint === CHOIX.NON && (
                <CallOut
                  iconId="fr-icon-info-line"
                  title={bloc2Cms.bulle_aide_ineligible_conjoint_francais.titre}
                  className="callout-alert fr-mt-2w"
                >
                  {cmsEnrichedText(bloc2Cms.bulle_aide_ineligible_conjoint_francais.contenu)}
                </CallOut>
              )}
            </>
          )}
        </>
      )}
      {rFDemande === CHOIX.NON && (
        <>
          <ConjointInfoNoms lang={lang} formFieldsMapping={formFieldsMapping} />
          <div className="fr-mt-4w">
            <UploadFiles
              {...filesMapping.conjoint_titre_identite_files.upload}
              fieldArray={fieldArrayMapping.conjoint_titre_identite_files}
              isNew={conjoint?.uuid ? 'oui' : 'non'}
              lang={lang}
            />
          </div>
        </>
      )}
    </FormModalLayout>
  );
};

export default Conjoint;
