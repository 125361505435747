import { useSelector } from 'react-redux';
import { addConsentManagementTranslations } from '@codegouvfr/react-dsfr/consentManagement/ConsentBannerAndConsentManagement/translation';

import { PageContentHtmlParserFunction } from 'components/Atoms/HtmlParsers/PageContentHtmlParser';
import { getCookiePage } from 'redux/pages/selectors';
import { COOKIE } from 'routes/path';
import { adjustCookieLink } from './ManageState.helper';

export type ConsentTranslationProps = {
  lang: string;
};

export function ConsentTranslation({ lang }: ConsentTranslationProps) {
  const cookieStore = useSelector(getCookiePage);

  addConsentManagementTranslations({
    lang: lang,
    messages: {
      'all services pref': cookieStore.preference_tous_services,
      'personal data cookies': cookieStore.preference_tous_services,
      'accept all': cookieStore.bouton_tout_accepter,
      'accept all - title': cookieStore.alt_bouton_tout_accepter,
      'refuse all': cookieStore.bouton_tout_refuser,
      'refuse all - title': cookieStore.alt_bouton_tout_refuser,
      accept: cookieStore.accepter,
      refuse: cookieStore.refuser,
      'confirm choices': cookieStore.confirmer_choix,
      'about cookies': () => cookieStore.a_propos_des_cookies_sur + ' ' + cookieStore.url_site,
      'welcome message': () => {
        return (
          <>
            {PageContentHtmlParserFunction(
              adjustCookieLink(cookieStore.personnalisation_description),
            )}
          </>
        );
      },
      customize: cookieStore.bouton_personnaliser,
      'customize cookies - title': cookieStore.personnalisation_titre,
      'consent modal title': cookieStore.paneau_gestion_cookies,
      'cookies management': cookieStore.titre,
      'personal data': cookieStore.donnees_personnelles,
      'preferences for all services': () => {
        return (
          <>
            {cookieStore.preference_tous_services}
            <br />
            <a href={COOKIE}>{cookieStore.donnee_personnelles_et_cookies}</a>
          </>
        );
      },
      'mandatory cookies': cookieStore.cookies_essentiels_titre,
      'mandatory cookies - description': cookieStore.cookies_essentiels_description,
    },
  });

  return <></>;
}
