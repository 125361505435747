export const derniereAsile = {
  total: null,
  contenu: [
    {
      statutOPC: 'acceptee',
      dateSignatureOPC: '2020-03-11',
      estEnCours: true,
      dateCloture: null,
    },
  ],
};
