import { Accomodation } from 'models/types/api/rf';

export const createNewAccomodation = (id: string): Accomodation => {
  return {
    id: id,
    documents: [],
    adresse: {
      adresse1: '',
      adresse2: null,
      bano: {
        adresse: null,
        codePostal: null,
        codeVille: null,
        numero: null,
        ville: null,
      },
      codePostal: '',
      digicode: null,
      email: '',
      interphone: null,
      numero: '',
      pays: null,
      province: null,
      telephone: null,
      ville: null,
    },
    adresseNonTrouvee: false,
    hebergeur: null,
    logementDispoDate: null,
    principale: false,
    situation: null,
    type: null,
    typeHebergeur: null,
  };
};
