// Need to use the React-specific entry point to import createApi
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { CMS_BASE_URL } from 'services/cmsClient';

export const cmsSlice = createApi({
  reducerPath: 'cms',
  refetchOnMountOrArgChange: 5,
  baseQuery: fetchBaseQuery({
    baseUrl: CMS_BASE_URL + '/api',
  }),
  endpoints: () => ({}),
});
