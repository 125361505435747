import { IntlShape } from 'react-intl';
import { keys } from 'radash';

import { RegisteredLocales } from 'constants/locales';

type Message = string | NestedDictionary;

interface NestedDictionary {
  [x: string]: Message;
}

interface FlattenedDictionary {
  [x: string]: string;
}

export const getCurrentLocale = (intl: IntlShape): RegisteredLocales => {
  return (intl.locale as RegisteredLocales) || RegisteredLocales.FR;
};

export const flattenMessages = (
  nestedMessages: NestedDictionary,
  prefix = '',
): FlattenedDictionary =>
  keys(nestedMessages).reduce((messages: FlattenedDictionary, key) => {
    const value = nestedMessages[key];
    const prefixedKey = prefix ? `${prefix}.${key}` : key;

    if (typeof value === 'string') {
      messages[prefixedKey] = value;
    } else {
      Object.assign(messages, flattenMessages(value, prefixedKey));
    }

    return messages;
  }, {});

/**
 * This function is used on the IntlProvider to hide a specific error message
 *
 * @param error
 */
export const onError = (error: Error) => {
  // eslint-disable-next-line no-restricted-syntax
  if (process.env.NODE_ENV === 'production') {
    return;
  }

  if (error.message.includes('UNEXISTING_ID') || error.name.includes('UNEXISTING_ID')) {
    return;
  }

  console.error(error);
};
