/* eslint-disable @typescript-eslint/no-explicit-any */
import { STATUS } from 'constants/formulaire';

export const asErrors = (error: any) => {
  return error != null && error !== undefined && !!error?.message;
};

export const computeIsValid = (isTouched: boolean, isDirty: boolean, error: any) => {
  return isTouched && isDirty && !asErrors(error);
};

export const computeIsValidDsfr = (isTouched: boolean, isDirty: boolean, error: any) => {
  return asErrors(error)
    ? 'error'
    : computeIsValid(isTouched, isDirty, error)
    ? 'success'
    : 'default';

  // return isTouched && isDirty && !asErrors(error)
  //   ? 'success'
  //   : isTouched && error
  //   ? 'error'
  //   : 'default';
};

export const computeIsCheckboxValid = (isTouched: boolean, error: any) => {
  return isTouched && !asErrors(error) ? 'success' : asErrors(error) ? 'error' : 'default';
  //return isTouched && !asErrors(error) ? 'success' : isTouched && error ? 'error' : 'default';
};

export const computeFieldStatus = (
  disabled: boolean,
  isTouched: boolean,
  isDirty: boolean,
  error: any,
) => {
  if (disabled) {
    return STATUS.DISABLED;
  }

  if (asErrors(error)) {
    return STATUS.ERROR;
  }

  if (isTouched && isDirty) {
    return STATUS.VALID;
  }

  return STATUS.UNTOUCHED;
};
