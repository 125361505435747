import { PagesTitle } from 'redux/pages/pageState.model';

export type ThreeBranch = {
  path: string;
  titleKey: string;
  childrens?: ThreeBranch[];
};

export const navigationThree = (titles: PagesTitle): ThreeBranch => {
  return {
    titleKey: titles.accueil ? titles.accueil : '',
    path: '/',
    childrens: [
      {
        titleKey: titles.tableau_de_bord ? titles.tableau_de_bord : '',
        path: '/tableau-de-bord',
        childrens: [
          {
            titleKey: titles.regroupement_familial ? titles.regroupement_familial : '',
            path: '/demande-regroupement-familial/:number',
          },
          {
            path: '/espace-asile',
            titleKey: titles.asile ? titles.asile : '',
            childrens: [
              {
                path: '/espace-asile/mon-allocation',
                titleKey: titles.allocation ? titles.allocation : '',
              },
            ],
          },
          {
            titleKey: titles.regroupement_familial ? titles.regroupement_familial : '',
            path: '/espace-regroupement-familial/:numeroDossier/dossier',
          },
        ],
      },
      {
        titleKey: titles.compte ? titles.compte : '',
        path: '/compte',
      },
      {
        titleKey: titles.procedures ? titles.procedures : '',
        path: '/les-procedures',
        childrens: [
          {
            titleKey: titles.procedure ? titles.procedure : '',
            path: '/les-procedures/:lien',
          },
        ],
      },
      {
        titleKey: titles.articles ? titles.articles : '',
        path: '/articles',
        childrens: [
          {
            titleKey: titles.article ? titles.article : '',
            path: '/article/:lien',
          },
        ],
      },
      {
        path: '/les-services-en-ligne',
        titleKey: titles.services_en_ligne ? titles.services_en_ligne : '',
      },
      {
        path: '/tableau-de-bord',
        titleKey: titles.tableau_de_bord ? titles.tableau_de_bord : '',
      },
      {
        path: '/creation-compte',
        titleKey: titles.creation_compte ? titles.creation_compte : '',
      },
      {
        path: '/localisation',
        titleKey: titles.localisation ? titles.localisation : '',
      },
      {
        path: '/contact',
        titleKey: titles.contact ? titles.contact : '',
      },
      {
        path: '/mentions-legales',
        titleKey: titles.mentions_legales ? titles.mentions_legales : '',
      },
      {
        path: '/accessibilite',
        titleKey: titles.accessibilite ? titles.accessibilite : '',
      },
      {
        path: '/plan-du-site',
        titleKey: titles.plan_site ? titles.plan_site : '',
      },
      {
        path: '/cookie',
        titleKey: titles.cookies ? titles.cookies : '',
      },
      {
        path: '/rgpd',
        titleKey: titles.rgpd ? titles.rgpd : '',
      },
      {
        path: '/accueil-et-integration',
        titleKey: titles.accueil_et_integration ? titles.accueil_et_integration : '',
      },
      {
        path: '/mes-documents',
        titleKey: titles.documents ? titles.documents : '',
      },
    ],
  };
};

const generateBreadcrumbForSuiviRf = (
  three: ThreeBranch,
  titles: PagesTitle,
  navigationArray: {
    label: React.ReactNode;
    linkProps: {
      href: string;
    };
  }[],
): { pageTitle: string; navArray: any[] } => {
  const baseUrl = '';
  const dashboardElement = three.childrens?.find(el => el.titleKey === titles.tableau_de_bord);

  if (dashboardElement) {
    navigationArray.push({
      label: dashboardElement.titleKey,
      linkProps: {
        href: baseUrl + dashboardElement.path,
      },
    });
  }

  const element = dashboardElement?.childrens?.find(
    el => el.titleKey === titles.regroupement_familial,
  );

  return {
    pageTitle: element?.titleKey ? element.titleKey : '',
    navArray: navigationArray,
  };
};

const generateBreadCrumbForAsile = (
  three: ThreeBranch,
  titles: PagesTitle,
  path: string,
  navigationArray: {
    label: React.ReactNode;
    linkProps: {
      href: string;
    };
  }[],
): { pageTitle: string; navArray: any[] } => {
  const baseUrl = '';
  const dashboardElement = three.childrens?.find(el => el.titleKey === titles.tableau_de_bord);

  if (dashboardElement) {
    navigationArray.push({
      label: dashboardElement.titleKey,
      linkProps: {
        href: baseUrl + dashboardElement.path,
      },
    });
  }

  const element = dashboardElement?.childrens?.find(el => el.titleKey === titles.asile);
  if (path.includes('mon-allocation')) {
    navigationArray.push({
      label: element?.titleKey ? element.titleKey : '',
      linkProps: {
        href: element?.path ? baseUrl + element?.path : '',
      },
    });

    return {
      pageTitle: element && element.childrens ? element?.childrens[0].titleKey : '',
      navArray: navigationArray,
    };
  } else {
    return {
      pageTitle: element?.titleKey ? element.titleKey : '',
      navArray: navigationArray,
    };
  }
};

const generateBreadcrumbForRf = (
  three: ThreeBranch,
  titles: PagesTitle,
  navigationArray: {
    label: React.ReactNode;
    linkProps: {
      href: string;
    };
  }[],
): { pageTitle: string; navArray: any[] } => {
  const baseUrl = '';
  const dashboardElement = three.childrens?.find(el => el.titleKey === titles.tableau_de_bord);

  if (dashboardElement) {
    navigationArray.push({
      label: dashboardElement.titleKey,
      linkProps: {
        href: baseUrl + dashboardElement.path,
      },
    });
  }

  const currentElement = dashboardElement?.childrens?.find(
    el => el.titleKey === titles.regroupement_familial,
  );

  return {
    pageTitle: currentElement ? currentElement.titleKey : '',
    navArray: navigationArray,
  };
};

export const generateBreadcrumbArray = (
  currentLanguage: string,
  path: string,
  three: ThreeBranch,
  titles: PagesTitle,
  navigationArray: {
    label: React.ReactNode;
    linkProps: {
      href: string;
    };
  }[],
): { pageTitle: string; navArray: any[] } => {
  const baseUrl = '';
  const isRootPage = path === `/${currentLanguage}`;
  let returnedValue: { pageTitle: string; navArray: any[] } = {
    pageTitle: '',
    navArray: [],
  };

  if (isRootPage && three.titleKey === titles.accueil) {
    returnedValue = {
      pageTitle: titles.accueil,
      navArray: [],
    };
  } else if (path.includes('demande-regroupement-familial') && three.titleKey === titles.accueil) {
    return generateBreadcrumbForRf(three, titles, navigationArray);
  } else if (path.includes('espace-asile')) {
    return generateBreadCrumbForAsile(three, titles, path, navigationArray);
  } else if (path.includes('espace-regroupement-familial')) {
    return generateBreadcrumbForSuiviRf(three, titles, navigationArray);
  } else if (!isRootPage && three.childrens) {
    three?.childrens.forEach(el => {
      if (el.path.includes(':')) {
        returnedValue = {
          pageTitle: el.titleKey,
          navArray: navigationArray,
        };
      } else if (path.includes(baseUrl + el.path) && path !== baseUrl + el.path) {
        navigationArray.push({
          label: el.titleKey,
          linkProps: {
            href: baseUrl + el.path,
          },
        });
        returnedValue = generateBreadcrumbArray(currentLanguage, path, el, titles, navigationArray);
      } else if (path.includes(baseUrl + el.path) && path === baseUrl + el.path) {
        returnedValue = {
          pageTitle: el.titleKey,
          navArray: navigationArray,
        };
      }
    });
  } else {
    returnedValue = {
      pageTitle: three.titleKey,
      navArray: navigationArray,
    };
  }

  return returnedValue;
};
