import qs from 'qs';
import { FileInfosQuery, HelpBoxQuery, InfobulleQuery } from '../common.query';

export const bloc3Query = qs.stringify({
  populate: {
    fichiers_bail_complet: FileInfosQuery,
    fichiers_derniere_quittance_loyer: FileInfosQuery,
    fichiers_justificatif_identite_hebergeant: FileInfosQuery,
  },
});

export const bloc3SecondQuery = qs.stringify({
  populate: {
    fichiers_attestation_hebergement_employeur: FileInfosQuery,
    fichiers_acte_propriete: FileInfosQuery,
    fichiers_promesse_location: FileInfosQuery,
  },
});

export const bloc3ThirdQuery = qs.stringify({
  populate: {
    fichiers_attestation_futur_mise_a_dispo: FileInfosQuery,
    fichiers_bail_complet_hebergeant: FileInfosQuery,
    fichiers_attestation_domicile_hebergeant: FileInfosQuery,
  },
});

export const bloc3FourthQuery = qs.stringify({
  populate: {
    fichiers_attestation_domicile_employeur: FileInfosQuery,
    fichiers_attestation_mise_a_disposition_logement: FileInfosQuery,
    fichiers_engagement_sous_location: FileInfosQuery,
  },
});

export const bloc3FifthQuery = qs.stringify({
  populate: {
    fichiers_bail_nom_hebergeant: FileInfosQuery,
    fichiers_justificatif_acquisition_futur_logement: FileInfosQuery,
    fichiers_justificatif_domicile_moins_trois_mois: FileInfosQuery,
  },
});

export const bloc3SixthQuery = qs.stringify({
  populate: {
    infobulle_vous_residez_en_tant_que: InfobulleQuery,
    infobulle_par: InfobulleQuery,
    infobulle_vous_habitez_au: InfobulleQuery,
    infobulle_vous_habitez_au_futur: InfobulleQuery,
    infobulle_date_mise_a_dispo_logement: InfobulleQuery,
    infobulle_fichier_justif_domicile_moins_trois_mois: InfobulleQuery,
    infobulle_fichier_bail_complet: InfobulleQuery,
    infobulle_fichier_derniere_quittante_loyer: InfobulleQuery,
    infobulle_fichier_engagement_sous_location: InfobulleQuery,
    infobulle_fichier_acte_propriete: InfobulleQuery,
    infobulle_fichier_justif_identite_hebergeant: InfobulleQuery,
    infobulle_fichier_attestation_domicile_par_hebergeant: InfobulleQuery,
    infobulle_fichier_attestation_mise_a_dispo_logement: InfobulleQuery,
    infobulle_fichier_attestation_employer_accord_logement: InfobulleQuery,
    infobulle_fichier_promesse_location: InfobulleQuery,
    infobulle_fichier_justificatif_acquisition_logement_futur: InfobulleQuery,
  },
});

export const bloc3FormQuery = qs.stringify({
  populate: {
    fields: ['titre_principal'],
    bulle_aide_adresse_logement_a_visiter: HelpBoxQuery,
    principale_helpbox_top: HelpBoxQuery,
  },
});
