import qs from 'qs';

import {
  HelpBoxQuery,
  TitleSubTitleQuery,
  FileInfosQuery,
  InfobulleQuery,
} from 'pages/DemandeRF/Queries/common.query';

export const bloc1Query1 = qs.stringify({
  populate: {
    button_infobulle_en_savoir_plus: InfobulleQuery,
    fichier_convention_pacs: FileInfosQuery,
    fichier_acte_mariage: FileInfosQuery,
    fichier_acte_mariage_traduit: FileInfosQuery,
  },
});

export const bloc1Query2 = qs.stringify({
  populate: {
    fichier_acte_deces_conjoint: FileInfosQuery,
    fichier_acte_deces_conjoint_traduit: FileInfosQuery,
    fichier_jugement_divorce: FileInfosQuery,
  },
});

export const bloc1Query3 = qs.stringify({
  populate: {
    fichier_titre_sejour: FileInfosQuery,
    fichier_recepisse_renouvellement: FileInfosQuery,
    infobulle_titre_sejour: InfobulleQuery,
    infobulle_fichier_jugement_divorce_langue_origine: InfobulleQuery,
    infobulle_fichier_acte_deces_conjoint: InfobulleQuery,
  },
});

export const bloc1Query4 = qs.stringify({
  populate: {
    infobulle_validite_titre_sejour: InfobulleQuery,
    infobulle_fichier_titre_sejour_rv: InfobulleQuery,
    infobulle_vous_etes: InfobulleQuery,
    infobulle_vous_vous_appelez: InfobulleQuery,
    infobulle_vous_etes_nee_le: InfobulleQuery,
  },
});

export const bloc1Query5 = qs.stringify({
  populate: {
    infobulle_vous_etes_de_nationalite: InfobulleQuery,
    infobulle_fichier_acte_naissance: InfobulleQuery,
    infobulle_situation_familiale: InfobulleQuery,
    infobulle_fichier_acte_mariage: InfobulleQuery,
    infobulle_fichier_pacs: InfobulleQuery,
  },
});

export const bloc1Query6 = qs.stringify({
  populate: {
    fichier_jugement_divorce_traduit: FileInfosQuery,
    fichier_acte_naissance_fr: FileInfosQuery,
    fichier_acte_naissance_langue_origine: FileInfosQuery,
  },
});

export const bloc1FormQuery1 = qs.stringify({
  populate: {
    fields: ['titre_principal'],
    principale_helpbox_top: HelpBoxQuery,
    bulle_alerte_certains_titres_invalides: HelpBoxQuery,
    titre_sejour_validite: TitleSubTitleQuery,
    titre_date_expiration_titre_sejour: TitleSubTitleQuery,
    nom_naissance: TitleSubTitleQuery,
    nom_usage: TitleSubTitleQuery,
  },
});

export const bloc1FormQuery2 = qs.stringify({
  populate: {
    prenom: TitleSubTitleQuery,
    nationalite: TitleSubTitleQuery,
    date_naissance: TitleSubTitleQuery,
    ville_naissance: TitleSubTitleQuery,
    pays_naissance: TitleSubTitleQuery,
    bulle_aide_comment_deposer_document: HelpBoxQuery,
    bulle_alerte_mariage_civils_seuls: HelpBoxQuery,
    bulle_aide_titre_sejour_est_expire: HelpBoxQuery,
  },
});

export const bloc1FormQuery3 = qs.stringify({
  populate: {
    bulle_aide_nationalite_non_eligible: HelpBoxQuery,
    usager_mineur_warning_box: HelpBoxQuery,
    acte_naissance_traduit: HelpBoxQuery,
    mariage_depuis: TitleSubTitleQuery,
    pacse_depuis: TitleSubTitleQuery,
    divorce_depuis: TitleSubTitleQuery,
    concubinage_depuis: TitleSubTitleQuery,
  },
});
