import { FieldArrayList, UploadFilesParameters } from 'models/types/app/uploadFiles';
import {
  DemandeRfBloc4,
  DemandeRfGeneral,
  DemandeRfUtilitaire,
} from 'models/types/cms/demandeRF/type';

export class ResourcesMapping {
  static getGeneralMapping() {
    function getformFields(
      cms: {
        bloc4Cms: DemandeRfBloc4;
        globalCms: DemandeRfGeneral;
      },
      formControls: {
        setValue: any;
        control: any;
        trigger: any;
      },
      lang: string,
    ) {
      const { setValue, control, trigger } = formControls;
      const { globalCms, bloc4Cms } = cms;

      return {
        travail_dans_btp: {
          name: 'travail_dans_btp',
          label: '',
          options: [
            {
              label: globalCms.oui,
              id: 'travail_dans_btp_oui',
              nativeInputProps: {
                value: 'oui',
                checked: control._formValues.travail_dans_btp === 'oui',
                onChange: () => {
                  setValue('travail_dans_btp', 'oui');
                  trigger('travail_dans_btp');
                },
              },
            },
            {
              label: globalCms.non,
              id: 'travail_dans_btp_non',
              nativeInputProps: {
                value: 'non',
                checked: control._formValues.travail_dans_btp === 'non',
                onChange: () => {
                  setValue('travail_dans_btp', 'non');
                  trigger('travail_dans_btp');
                },
              },
            },
          ],
          required: true,
          control,
          lang: lang,
        },
        arret_travail_annee_passee: {
          name: 'arret_travail_12_dernier_mois',
          label: '',
          options: [
            {
              label: globalCms.oui,

              id: 'arret_travail_12_dernier_mois_oui',
              nativeInputProps: {
                value: 'oui',
                checked: control._formValues.arret_travail_12_dernier_mois === 'oui',
                onChange: () => {
                  setValue('arret_travail_12_dernier_mois', 'oui');
                  trigger('arret_travail_12_dernier_mois');
                },
              },
            },
            {
              label: globalCms.non,

              id: 'arret_travail_12_dernier_mois_non',
              nativeInputProps: {
                value: 'non',
                checked: control._formValues.arret_travail_12_dernier_mois === 'non',
                onChange: () => {
                  setValue('arret_travail_12_dernier_mois', 'non');
                  trigger('arret_travail_12_dernier_mois');
                },
              },
            },
          ],
          required: true,
          control,
          lang: lang,
        },
        autres_ressources: {
          name: 'autres_ressources',
          label: '',
          options: [
            {
              label: globalCms.oui,
              id: 'autres_ressources_oui',
              nativeInputProps: {
                value: 'oui',
                checked: control._formValues.autres_ressources === 'oui',
                onChange: () => {
                  setValue('autres_ressources', 'oui');
                  trigger('autres_ressources');
                },
              },
            },
            {
              label: globalCms.non,
              id: 'autres_ressources_non',
              nativeInputProps: {
                value: 'non',
                checked: control._formValues.autres_ressources === 'non',
                onChange: () => {
                  setValue('autres_ressources', 'non');
                  trigger('autres_ressources');
                },
              },
            },
          ],
          required: true,
          control,
          lang: lang,
        },
        pension_alimentaire: {
          name: 'pension_alimentaire',
          label: '',
          options: [
            {
              label: globalCms.oui,
              id: 'pension_alimentaire_oui',
              nativeInputProps: {
                value: 'oui',
                checked: control._formValues.pension_alimentaire === 'oui',
                onChange: () => {
                  setValue('pension_alimentaire', 'oui');
                  trigger('pension_alimentaire');
                },
              },
            },
            {
              label: globalCms.non,
              id: 'pension_alimentaire_non',
              nativeInputProps: {
                value: 'non',
                checked: control._formValues.pension_alimentaire === 'non',
                onChange: () => {
                  setValue('pension_alimentaire', 'non');
                  trigger('pension_alimentaire');
                },
              },
            },
          ],
          required: true,
          control,
          lang: lang,
        },
        declarer_conjoint_ressources: {
          name: 'declarer_conjoint_ressources',
          label: '',
          hintText: bloc4Cms.declarer_conjoint_ressources_sous_titre,
          options: [
            {
              label: globalCms.oui,

              id: 'declarer_conjoint_ressources_oui',
              nativeInputProps: {
                value: 'oui',
                checked: control._formValues.declarer_conjoint_ressources === 'oui',
                onChange: () => {
                  setValue('declarer_conjoint_ressources', 'oui');
                  trigger('declarer_conjoint_ressources');
                },
              },
            },
            {
              label: globalCms.non,

              id: 'declarer_conjoint_ressources_non',
              nativeInputProps: {
                value: 'non',
                checked: control._formValues.declarer_conjoint_ressources === 'non',
                onChange: () => {
                  setValue('declarer_conjoint_ressources', 'non');
                  trigger('declarer_conjoint_ressources');
                },
              },
            },
          ],
          required: true,
          control,
          lang: lang,
        },
        nouvelle_activite: {
          name: 'nouvelle_activite',
          label: '',
          options: [
            {
              label: globalCms.oui,
              //id: 'nouvelle_activite_oui',
              nativeInputProps: {
                value: 'oui',
                checked: control._formValues.nouvelle_activite === 'oui',
                onChange: () => {
                  setValue('nouvelle_activite', 'oui');
                  trigger('nouvelle_activite');
                },
              },
            },
            {
              label: globalCms.non,
              //id: 'nouvelle_activite_non',
              nativeInputProps: {
                value: 'non',
                checked: control._formValues.nouvelle_activite === 'non',
                onChange: () => {
                  setValue('nouvelle_activite', 'non');
                  trigger('nouvelle_activite');
                },
              },
            },
          ],
          //helperText: bloc4Cms.titre_selection_activites_pro,
          control,
          lang: lang,
        },
        situation_professionnelle: {},
        situation_professionnelle_ulterieure: {},
        ajouter_une_situation: {},
        quelle_situation_professionnelle_actuelle: {},
        titre_beneficiaire_aah_asi: {
          name: 'beneficiaire_aah_ou_asi',
          label: '',
          options: [
            {
              label: globalCms.oui,
              id: 'beneficiaire_aah_ou_asi_oui',
              nativeInputProps: {
                value: 'oui',
                checked: control._formValues.beneficiaire_aah_ou_asi === 'oui',
                onChange: () => {
                  setValue('beneficiaire_aah_ou_asi', 'oui');
                  trigger('beneficiaire_aah_ou_asi');
                },
              },
            },
            {
              label: globalCms.non,

              id: 'beneficiaire_aah_ou_asi_non',
              nativeInputProps: {
                value: 'non',
                checked: control._formValues.beneficiaire_aah_ou_asi === 'non',
                onChange: () => {
                  setValue('beneficiaire_aah_ou_asi', 'non');
                  trigger('beneficiaire_aah_ou_asi');
                },
              },
            },
          ],
          control,
          lang: lang,
        },
      };
    }

    function getActivities(
      cms: {
        bloc4Cms: DemandeRfBloc4;
        globalCms: DemandeRfGeneral;
        demandeUtilitaire: DemandeRfUtilitaire;
      },
      index: number,
      control: any,
    ) {
      const { bloc4Cms, demandeUtilitaire, globalCms } = cms;
      return {
        name: `activities.${index}.activite`,
        label:
          index > 0
            ? bloc4Cms.situation_professionnelle_ulterieure
            : bloc4Cms.situation_professionnelle,
        defaultValue: demandeUtilitaire.choisir_option,
        hint: bloc4Cms.titre_selection_activites_pro,
        options: [
          {
            label: globalCms.option_activite_salarie_cdi,
            value: 'activite_salarie_en_cdi',
          },
          {
            label: globalCms.option_activite_salarie_cdd,
            value: 'activite_salarie_en_cdd',
          },
          {
            label: globalCms.option_activite_salarie_interim,
            value: 'activite_salarie_en_interim',
          },
          {
            label: globalCms.option_activite_demandeur_emploi,
            value: 'activite_demandeur_d_emploi',
          },
          {
            label: globalCms.option_activite_artisan,
            value: 'activite_artisan',
          },
          {
            label: globalCms.option_activite_commercant,
            value: 'activite_commercant',
          },
          {
            label: globalCms.option_activite_liberal,
            value: 'activite_profession_liberale',
          },
          {
            label: globalCms.option_activite_auto_entrepreneur,
            value: 'activite_auto_entrepreneur',
          },
          {
            label: globalCms.option_activite_retraite,
            value: 'activite_retraite',
          },
          {
            label: globalCms.option_activite_invalide,
            value: 'activite_invalide',
          },
          {
            label: globalCms.option_activite_sans_activite,
            value: 'activite_sans_activite',
          },
        ],
        required: false,
        control: control,
      };
    }

    function get_files_fields(
      bloc4Cms: DemandeRfBloc4,
      uploadFilesParameters: UploadFilesParameters,
      lang: string,
      //fieldArrayMapping: FieldArrayList,
    ) {
      return {
        ress_dernier_avis_imposition_files: {
          upload: {
            name: 'ress_dernier_avis_imposition_files',
            categorie: 'ress_dernier_avis_imposition',
            //fieldArray: fieldArrayMapping.ress_dernier_avis_imposition_files,
            label: bloc4Cms.fichier_dernier_avis_impot.titre_fichier ?? '',
            hint: bloc4Cms.fichier_dernier_avis_impot.infos_fichier ?? '',
            helpbox: {
              title: bloc4Cms.fichier_dernier_avis_impot.bulle_aide_fichier_titre ?? '',
              description: bloc4Cms.fichier_dernier_avis_impot.bulle_aide_fichier_contenu ?? '',
              closable: false,
              severity: 'info',
            },
            state: 'default',
            stateRelatedMessage: "Text de validation / d'explication de l'erreur",
            // required: true,
            ...uploadFilesParameters,
            resource: 'demande',
            resourceId: undefined,
            infobulleContent: bloc4Cms.infobulle_fichier_dernier_avis_impot,
            lang: lang,
          },
        },
        ress_justificatif_vers_presta_social_algerien_files: {
          upload: {
            name: 'ress_justificatif_vers_presta_social_algerien_files',
            categorie: 'ress_justificatif_vers_presta_social_algerien',
            //fieldArray: fieldArrayMapping.ress_justificatif_vers_presta_social_algerien_files,
            label: bloc4Cms.fichier_justificatif_presta_social_algerien.titre_fichier,
            hint: bloc4Cms.fichier_justificatif_presta_social_algerien.infos_fichier,
            helpbox: {
              title: bloc4Cms.fichier_justificatif_presta_social_algerien.bulle_aide_fichier_titre,
              description:
                bloc4Cms.fichier_justificatif_presta_social_algerien.bulle_aide_fichier_contenu ??
                '',
              closable: false,
              severity: 'info',
            },
            state: 'default',
            stateRelatedMessage: "Text de validation / d'explication de l'erreur",
            // required: true,
            ...uploadFilesParameters,
            resource: 'demande',
            resourceId: undefined,
            infobulleContent: bloc4Cms.infobulle_fichier_versement_prestations_sociales,
            lang: lang,
          },
        },
        ress_btp_justificatif_conges_payes_files: {
          upload: {
            name: 'ress_btp_justificatif_conges_payes_files',
            categorie: 'ress_btp_justificatif_conges_payes',
            //fieldArray: fieldArrayMapping.ress_btp_justificatif_conges_payes_files,
            label: bloc4Cms.fichier_btp_justif_conges_payes.titre_fichier ?? '',
            hint: bloc4Cms.fichier_btp_justif_conges_payes.infos_fichier ?? '',
            helpbox: {
              title: bloc4Cms.fichier_btp_justif_conges_payes.bulle_aide_fichier_titre ?? '',
              description:
                bloc4Cms.fichier_btp_justif_conges_payes.bulle_aide_fichier_contenu ?? '',
              closable: false,
              severity: 'info',
            },
            state: 'default',
            stateRelatedMessage: "Text de validation / d'explication de l'erreur",
            // required: true,
            ...uploadFilesParameters,
            resource: 'demande',
            resourceId: undefined,
            infobulleContent: bloc4Cms.infobulle_fichier_btp_justificatif_versement_conges_payes,
            lang: lang,
          },
        },
        ress_secu_social_indemnite_jour_files: {
          upload: {
            name: 'ress_secu_social_indemnite_jour_files',
            categorie: 'ress_secu_social_indemnite_jour',
            //fieldArray: fieldArrayMapping.ess_secu_social_indemnite_jour_files,
            label: bloc4Cms.fichier_secu_social_indemnite_jour.titre_fichier ?? '',
            hint: bloc4Cms.fichier_secu_social_indemnite_jour.infos_fichier ?? '',
            helpbox: {
              title: bloc4Cms.fichier_secu_social_indemnite_jour.bulle_aide_fichier_titre ?? '',
              description:
                bloc4Cms.fichier_secu_social_indemnite_jour.bulle_aide_fichier_contenu ?? '',
              closable: false,
              severity: 'info',
            },
            state: 'default',
            stateRelatedMessage: "Text de validation / d'explication de l'erreur",
            // required: true,
            ...uploadFilesParameters,
            resource: 'demande',
            resourceId: undefined,
            infobulleContent:
              bloc4Cms.infobulle_fichier_justificatif_versement_are_secu_arret_travail,
            lang: lang,
          },
        },
        ress_decision_attribution_aah_asi_files: {
          upload: {
            name: 'ress_decision_attribution_aah_asi_files',
            categorie: 'ress_decision_attribution_aah_asi',
            //fieldArray: fieldArrayMapping.ress_decision_attribution_aah_asi_files,
            label: bloc4Cms.fichier_justif_attribution_aah_asi.titre_fichier ?? '',
            hint: bloc4Cms.fichier_justif_attribution_aah_asi.infos_fichier ?? '',
            helpbox: {
              title: bloc4Cms.fichier_justif_attribution_aah_asi.bulle_aide_fichier_titre ?? '',
              description:
                bloc4Cms.fichier_justif_attribution_aah_asi.bulle_aide_fichier_contenu ?? '',
              closable: false,
              severity: 'info',
            },
            state: 'default',
            stateRelatedMessage: "Text de validation / d'explication de l'erreur",
            // required: true,
            ...uploadFilesParameters,
            resource: 'demande',
            resourceId: undefined,
            infobulleContent: bloc4Cms.infobulle_fichier_invalide_decision_attribution,
            lang: lang,
          },
        },
        ress_attestation_paiement_aah_asi_files: {
          upload: {
            name: 'ress_attestation_paiement_aah_asi_files',
            categorie: 'ress_attestation_paiement_aah_asi',
            //fieldArray: fieldArrayMapping.ress_attestation_paiement_aah_asi_files,
            label: bloc4Cms.fichier_justif_paiement_aah_asi.titre_fichier ?? '',
            hint: bloc4Cms.fichier_justif_paiement_aah_asi.infos_fichier ?? '',
            helpbox: {
              title: bloc4Cms.fichier_justif_paiement_aah_asi.bulle_aide_fichier_titre,
              description: bloc4Cms.fichier_justif_paiement_aah_asi.bulle_aide_fichier_contenu,
              closable: false,
              severity: 'info',
            },
            state: 'default',
            stateRelatedMessage: "Text de validation / d'explication de l'erreur",
            // required: true,
            ...uploadFilesParameters,
            resource: 'demande',
            resourceId: undefined,
            infobulleContent: bloc4Cms.infobulle_fichier_attestation_paiement_aah_asi,
            lang: lang,
          },
        },
        ress_autre_document_origine_revenu_files: {
          upload: {
            name: 'ress_autre_document_origine_revenu_files',
            categorie: 'ress_autre_document_origine_revenu',
            //fieldArray: fieldArrayMapping.ress_autre_document_origine_revenu_files,
            label: bloc4Cms.fichier_releve_compte_origine_revenus.titre_fichier ?? '',
            hint: bloc4Cms.fichier_releve_compte_origine_revenus.infos_fichier ?? '',
            helpbox: {
              title: bloc4Cms.fichier_releve_compte_origine_revenus.bulle_aide_fichier_titre ?? '',
              description:
                bloc4Cms.fichier_releve_compte_origine_revenus.bulle_aide_fichier_contenu ?? '',
              closable: false,
              severity: 'info',
            },
            state: 'default',
            stateRelatedMessage: "Text de validation / d'explication de l'erreur",
            // required: true,
            ...uploadFilesParameters,
            resource: 'demande',
            resourceId: undefined,
            infobulleContent:
              bloc4Cms.infobulle_fichier_justificatif_autres_ressources_ou_ressource_conjoint,
            lang: lang,
          },
        },
        ress_pension_alimentaires_ou_decision_justice_files: {
          upload: {
            name: 'ress_pension_alimentaires_ou_decision_justice_files',
            categorie: 'ress_pension_alimentaires_ou_decision_justice',
            //fieldArray: fieldArrayMapping.ress_pension_alimentaires_ou_decision_justice_files,
            label: bloc4Cms.fichier_justif_pension_alimentaire.titre_fichier ?? '',
            hint: bloc4Cms.fichier_justif_pension_alimentaire.infos_fichier ?? '',
            helpbox: {
              title: bloc4Cms.fichier_justif_pension_alimentaire.bulle_aide_fichier_titre ?? '',
              description:
                bloc4Cms.fichier_justif_pension_alimentaire.bulle_aide_fichier_contenu ?? '',
              closable: false,
              severity: 'info',
            },
            state: 'default',
            stateRelatedMessage: "Text de validation / d'explication de l'erreur",
            // required: true,
            ...uploadFilesParameters,
            resource: 'demande',
            resourceId: undefined,
            lang: lang,
          },
        },
        ress_releve_compte_origine_revenus_files: {
          upload: {
            name: 'ress_releve_compte_origine_revenus_files',
            categorie: 'ress_ressources_du_conjoint',
            //fieldArray: fieldArrayMapping.ress_releve_compte_origine_revenus_files,
            label: bloc4Cms.fichier_releve_compte_origine_revenus.titre_fichier ?? '',
            hint: bloc4Cms.fichier_releve_compte_origine_revenus.infos_fichier ?? '',
            helpbox: {
              title: bloc4Cms.fichier_releve_compte_origine_revenus.bulle_aide_fichier_titre ?? '',
              description:
                bloc4Cms.fichier_releve_compte_origine_revenus.bulle_aide_fichier_contenu ?? '',
              closable: false,
              severity: 'info',
            },
            state: 'default',
            stateRelatedMessage: "Text de validation / d'explication de l'erreur",
            // required: true,
            ...uploadFilesParameters,
            resource: 'demande',
            resourceId: undefined,
            infobulleContent:
              bloc4Cms.infobulle_fichier_justificatif_autres_ressources_ou_ressource_conjoint,
            lang: lang,
          },
        },
      };
    }

    function get_activity_files_field(
      bloc4Cms: DemandeRfBloc4,
      uploadFilesParameters: UploadFilesParameters,
      fieldArrayMapping: FieldArrayList,
      lang: string,
      names: {
        ressSalCdiBulletinSalaireFilesName: string;
        ressSalCddBulletinSalaireFilesName: string;
        ressSalIntCertificatTravailFilesName: string;
        ressSalCddCertificatTravailFilesName: string;
        ressSalCdiCertificatTravailFilesName: string;
        ressCommerExtraitInscriptionRcsFilesName: string;
        ressAutentDeclarationActiviteCfeFilesName: string;
        ressProlibExtraitInscriptionSirenFilesName: string;
        ressArtisaExtraitInscriptionMetiersFilesName: string;
        ressSalCddContratAttestationTravailFilesName: string;
        ressSalIntContratAttestationTravailFilesName: string;
        ressSalCdiContratAttestationTravailFilesName: string;
        ressInvaliAvisVersementRetraiteInvalideFilesName: string;
        ressRetraiAvisVersementRetraiteInvalideFilesName: string;
        ressAutentLivreRecettesAttestationRevenuFilesName: string;
        ressDemEmpReleveSituationDroitsVersementsPeFilesName: string;
        ressInvaliAttributionPensionRetraiteInvaliditeFilesName: string;
        ressRetraiAttributionPensionRetraiteInvaliditeFilesName: string;
        ressArtisaBilanComptableResultatAttestationRevenuFilesName: string;
        ressProlibBilanComptableResultatAttestationRevenuFilesName: string;
        ressCommerBilanComptableResultatAttestationRevenuFilesName: string;
      },
    ) {
      return {
        ressSalCdiContratAttestationTravailFilesFields: {
          upload: {
            name: names.ressSalCdiContratAttestationTravailFilesName,
            categorie: 'ress_salcdi_contrat_attestation_travail',
            fieldArray: fieldArrayMapping.ressSalCdiContratAttestationTravailFilesFields,
            label: bloc4Cms.fichier_contrat_attestation_travail.titre_fichier,
            hint: bloc4Cms.fichier_contrat_attestation_travail.infos_fichier,
            helpbox: {
              title: bloc4Cms.fichier_contrat_attestation_travail.bulle_aide_fichier_titre,
              description: bloc4Cms.fichier_contrat_attestation_travail.bulle_aide_fichier_contenu,
              closable: false,
              severity: 'info',
            },
            state: 'default',
            stateRelatedMessage: "Text de validation / d'explication de l'erreur",
            // required: true,
            ...uploadFilesParameters,
            resource: 'demande',
            resourceId: undefined,
            infobulleContent: bloc4Cms.infobulle_fichier_attestation_travail_employeur,
            lang: lang,
          },
        },
        ressSalCdiBulletinSalaireFilesFields: {
          upload: {
            name: names.ressSalCdiBulletinSalaireFilesName,
            categorie: 'ress_salcdi_bulletin_salaire',
            fieldArray: fieldArrayMapping.ressSalCdiBulletinSalaireFilesFields,
            label: bloc4Cms.fichier_bulletin_salaire_cdi.titre_fichier,
            hint: bloc4Cms.fichier_bulletin_salaire_cdi.infos_fichier,
            helpbox: {
              title: bloc4Cms.fichier_bulletin_salaire_cdi.bulle_aide_fichier_titre,
              description: bloc4Cms.fichier_bulletin_salaire_cdi.bulle_aide_fichier_contenu,
              closable: false,
              severity: 'info',
            },
            state: 'default',
            stateRelatedMessage: "Text de validation / d'explication de l'erreur",
            // required: true,
            ...uploadFilesParameters,
            resource: 'demande',
            resourceId: undefined,
            infobulleContent: bloc4Cms.infobulle_fichier_bulletins_salaire_cdi,
            lang: lang,
          },
        },
        ressSalCdiCertificatTravailFilesFields: {
          upload: {
            name: names.ressSalCdiCertificatTravailFilesName,
            categorie: 'ress_salcdi_certificat_travail',
            fieldArray: fieldArrayMapping.ressSalCdiCertificatTravailFilesFields,
            label: bloc4Cms.fichier_certificat_travail_cdi.titre_fichier,
            hint: bloc4Cms.fichier_certificat_travail_cdi.infos_fichier,
            helpbox: {
              title: bloc4Cms.fichier_certificat_travail_cdi.bulle_aide_fichier_titre,
              description: bloc4Cms.fichier_certificat_travail_cdi.bulle_aide_fichier_contenu,
              closable: false,
              severity: 'info',
            },
            state: 'default',
            stateRelatedMessage: "Text de validation / d'explication de l'erreur",
            // required: true,
            ...uploadFilesParameters,
            resource: 'demande',
            resourceId: undefined,
            infobulleContent: bloc4Cms.infobulle_fichier_certificat_travail,
            lang: lang,
          },
        },
        ressSalIntContratAttestationTravailFilesFields: {
          upload: {
            name: names.ressSalIntContratAttestationTravailFilesName,
            categorie: 'ress_salint_contrat_attestation_travail',
            fieldArray: fieldArrayMapping.ressSalIntContratAttestationTravailFilesFields,
            label: bloc4Cms.fichier_contrat_attestation_travail_interim.titre_fichier,
            hint: bloc4Cms.fichier_contrat_attestation_travail_interim.infos_fichier,
            helpbox: {
              title: bloc4Cms.fichier_contrat_attestation_travail_interim.bulle_aide_fichier_titre,
              description:
                bloc4Cms.fichier_contrat_attestation_travail_interim.bulle_aide_fichier_contenu,
              closable: false,
              severity: 'info',
            },
            state: 'default',
            stateRelatedMessage: "Text de validation / d'explication de l'erreur",
            // required: true,
            ...uploadFilesParameters,
            resource: 'demande',
            resourceId: undefined,
            infobulleContent: bloc4Cms.infobulle_fichier_attestation_travail_employeur,
            lang: lang,
          },
        },
        ressSalIntCertificatTravailFilesFields: {
          upload: {
            name: names.ressSalIntCertificatTravailFilesName,
            categorie: 'ress_salint_certificat_travail',
            fieldArray: fieldArrayMapping.ressSalIntCertificatTravailFilesFields,
            label: bloc4Cms.fichier_certificat_travail_interim.titre_fichier,
            hint: bloc4Cms.fichier_certificat_travail_interim.infos_fichier,
            helpbox: {
              title: bloc4Cms.fichier_certificat_travail_interim.bulle_aide_fichier_titre,
              description: bloc4Cms.fichier_certificat_travail_interim.bulle_aide_fichier_contenu,
              closable: false,
              severity: 'info',
            },
            state: 'default',
            stateRelatedMessage: "Text de validation / d'explication de l'erreur",
            // required: true,
            ...uploadFilesParameters,
            resource: 'demande',
            resourceId: undefined,
            infobulleContent: bloc4Cms.infobulle_fichier_certificat_travail,
            lang: lang,
          },
        },
        ressSalCddContratAttestationTravailFilesFields: {
          upload: {
            name: names.ressSalCddContratAttestationTravailFilesName,
            categorie: 'ress_salcdd_contrat_attestation_travail',
            fieldArray: fieldArrayMapping.ressSalCddContratAttestationTravailFilesFields,
            label: bloc4Cms.fichier_contrat_attestation_travail_cdd.titre_fichier,
            hint: bloc4Cms.fichier_contrat_attestation_travail_cdd.infos_fichier,
            helpbox: {
              title: bloc4Cms.fichier_contrat_attestation_travail_cdd.bulle_aide_fichier_titre,
              description:
                bloc4Cms.fichier_contrat_attestation_travail_cdd.bulle_aide_fichier_contenu,
              closable: false,
              severity: 'info',
            },
            state: 'default',
            stateRelatedMessage: "Text de validation / d'explication de l'erreur",
            // required: true,
            ...uploadFilesParameters,
            resource: 'demande',
            resourceId: undefined,
            infobulleContent: bloc4Cms.infobulle_fichier_attestation_travail_employeur,
            lang: lang,
          },
        },
        ressSalCddCertificatTravailFilesFields: {
          upload: {
            name: names.ressSalCddCertificatTravailFilesName,
            categorie: 'ress_salcdd_certificat_travail',
            fieldArray: fieldArrayMapping.ressSalCddCertificatTravailFilesFields,
            label: bloc4Cms.fichier_certificat_travail_cdd.titre_fichier,
            hint: bloc4Cms.fichier_certificat_travail_cdd.infos_fichier,
            helpbox: {
              title: bloc4Cms.fichier_certificat_travail_cdd.bulle_aide_fichier_titre,
              description: bloc4Cms.fichier_certificat_travail_cdd.bulle_aide_fichier_contenu,
              closable: false,
              severity: 'info',
            },
            state: 'default',
            stateRelatedMessage: "Text de validation / d'explication de l'erreur",
            // required: true,
            ...uploadFilesParameters,
            resource: 'demande',
            resourceId: undefined,
            infobulleContent: bloc4Cms.infobulle_fichier_certificat_travail,
            lang: lang,
          },
        },
        ressSalCddBulletinSalaireFilesFields: {
          upload: {
            name: names.ressSalCddBulletinSalaireFilesName,
            categorie: 'ress_salcdd_bulletin_salaire',
            fieldArray: fieldArrayMapping.ressSalCddBulletinSalaireFilesFields,
            label: bloc4Cms.fichier_bulletin_salaire_cdd.titre_fichier,
            hint: bloc4Cms.fichier_bulletin_salaire_cdd.infos_fichier,
            helpbox: {
              title: bloc4Cms.fichier_bulletin_salaire_cdd.bulle_aide_fichier_titre,
              description: bloc4Cms.fichier_bulletin_salaire_cdd.bulle_aide_fichier_contenu,
              closable: false,
              severity: 'info',
            },
            state: 'default',
            stateRelatedMessage: "Text de validation / d'explication de l'erreur",
            // required: true,
            ...uploadFilesParameters,
            resource: 'demande',
            resourceId: undefined,
            infobulleContent: bloc4Cms.infobulle_fichier_bulletins_salaire_cdd,
            lang: lang,
          },
        },
        ressArtisaExtraitInscriptionMetiersFilesFields: {
          upload: {
            name: names.ressArtisaExtraitInscriptionMetiersFilesName,
            categorie: 'ress_artisa_extrait_inscription_metiers',
            fieldArray: fieldArrayMapping.ressArtisaExtraitInscriptionMetiersFilesFields,
            label: bloc4Cms.fichier_artisan_extrait_inscription_metier.titre_fichier,
            hint: bloc4Cms.fichier_artisan_extrait_inscription_metier.infos_fichier,
            helpbox: {
              title: bloc4Cms.fichier_artisan_extrait_inscription_metier.bulle_aide_fichier_titre,
              description:
                bloc4Cms.fichier_artisan_extrait_inscription_metier.bulle_aide_fichier_contenu,
              closable: false,
              severity: 'info',
            },
            state: 'default',
            stateRelatedMessage: "Text de validation / d'explication de l'erreur",
            // required: true,
            ...uploadFilesParameters,
            resource: 'demande',
            resourceId: undefined,
            infobulleContent: bloc4Cms.infobulle_fichier_artisan_extrait_repertoire_metier,
            lang: lang,
          },
        },
        ressArtisaBilanComptableResultatAttestationRevenuFilesFields: {
          upload: {
            name: names.ressArtisaBilanComptableResultatAttestationRevenuFilesName,
            categorie: 'ress_artisa_bilan_comptable_resultat_attestation_revenu',
            fieldArray:
              fieldArrayMapping.ressArtisaBilanComptableResultatAttestationRevenuFilesFields,
            label: bloc4Cms.fichier_artisan_bilan_comptable.titre_fichier,
            hint: bloc4Cms.fichier_artisan_bilan_comptable.infos_fichier,
            helpbox: {
              title: bloc4Cms.fichier_artisan_bilan_comptable.bulle_aide_fichier_titre,
              description: bloc4Cms.fichier_artisan_bilan_comptable.bulle_aide_fichier_contenu,
              closable: false,
              severity: 'info',
            },
            state: 'default',
            stateRelatedMessage: "Text de validation / d'explication de l'erreur",
            // required: true,
            ...uploadFilesParameters,
            resource: 'demande',
            resourceId: undefined,
            infobulleContent: bloc4Cms.infobulle_fichier_artisan_bilan,
            lang: lang,
          },
        },
        ressDemEmpReleveSituationDroitsVersementsPeFilesFields: {
          upload: {
            name: names.ressDemEmpReleveSituationDroitsVersementsPeFilesName,
            categorie: 'ress_dememp_releve_situation_droits_versements_pe',
            fieldArray: fieldArrayMapping.ressDemEmpReleveSituationDroitsVersementsPeFilesFields,
            label: bloc4Cms.fichier_chomage_releve_situation_droits.titre_fichier,
            hint: bloc4Cms.fichier_chomage_releve_situation_droits.infos_fichier,
            helpbox: {
              title: bloc4Cms.fichier_chomage_releve_situation_droits.bulle_aide_fichier_titre,
              description:
                bloc4Cms.fichier_chomage_releve_situation_droits.bulle_aide_fichier_contenu,
              closable: false,
              severity: 'info',
            },
            state: 'default',
            stateRelatedMessage: "Text de validation / d'explication de l'erreur",
            // required: true,
            ...uploadFilesParameters,
            resource: 'demande',
            resourceId: undefined,
            infobulleContent:
              bloc4Cms.infobulle_fichier_releve_situation_et_versements_demandeur_emploi,
            lang: lang,
          },
        },
        ressProlibExtraitInscriptionSirenFilesFields: {
          upload: {
            name: names.ressProlibExtraitInscriptionSirenFilesName,
            categorie: 'ress_prolib_extrait_inscription_siren',
            fieldArray: fieldArrayMapping.ressProlibExtraitInscriptionSirenFilesFields,
            label: bloc4Cms.fichier_liberal_extrait_inscription_siren.titre_fichier,
            hint: bloc4Cms.fichier_liberal_extrait_inscription_siren.infos_fichier,
            helpbox: {
              title: bloc4Cms.fichier_liberal_extrait_inscription_siren.bulle_aide_fichier_titre,
              description:
                bloc4Cms.fichier_liberal_extrait_inscription_siren.bulle_aide_fichier_contenu,
              closable: false,
              severity: 'info',
            },
            state: 'default',
            stateRelatedMessage: "Text de validation / d'explication de l'erreur",
            // required: true,
            ...uploadFilesParameters,
            resource: 'demande',
            resourceId: undefined,
            infobulleContent: bloc4Cms.infobulle_fichier_liberal_extrait_sirene,
            lang: lang,
          },
        },
        ressProlibBilanComptableResultatAttestationRevenuFilesFields: {
          upload: {
            name: names.ressProlibBilanComptableResultatAttestationRevenuFilesName,
            categorie: 'ress_prolib_bilan_comptable_resultat_attestation_revenu',
            fieldArray:
              fieldArrayMapping.ressProlibBilanComptableResultatAttestationRevenuFilesFields,
            label: bloc4Cms.fichier_liberal_bilan_comptable.titre_fichier,
            hint: bloc4Cms.fichier_liberal_bilan_comptable.infos_fichier,
            helpbox: {
              title: bloc4Cms.fichier_liberal_bilan_comptable.bulle_aide_fichier_titre,
              description: bloc4Cms.fichier_liberal_bilan_comptable.bulle_aide_fichier_contenu,
              closable: false,
              severity: 'info',
            },
            state: 'default',
            stateRelatedMessage: "Text de validation / d'explication de l'erreur",
            // required: true,
            ...uploadFilesParameters,
            resource: 'demande',
            resourceId: undefined,
            infobulleContent: bloc4Cms.infobulle_fichier_liberal_bilan,
            lang: lang,
          },
        },
        ressCommerExtraitInscriptionRcsFilesFields: {
          upload: {
            name: names.ressCommerExtraitInscriptionRcsFilesName,
            categorie: 'ress_commer_extrait_inscription_rcs',
            fieldArray: fieldArrayMapping.ressCommerExtraitInscriptionRcsFilesFields,
            label: bloc4Cms.fichier_commercant_extrait_inscription_rcs.titre_fichier,
            hint: bloc4Cms.fichier_commercant_extrait_inscription_rcs.infos_fichier,
            helpbox: {
              title: bloc4Cms.fichier_commercant_extrait_inscription_rcs.bulle_aide_fichier_titre,
              description:
                bloc4Cms.fichier_commercant_extrait_inscription_rcs.bulle_aide_fichier_contenu,
              closable: false,
              severity: 'info',
            },
            state: 'default',
            stateRelatedMessage: "Text de validation / d'explication de l'erreur",
            // required: true,
            ...uploadFilesParameters,
            resource: 'demande',
            resourceId: undefined,
            infobulleContent: bloc4Cms.infobulle_fichier_commercant_registre_commerce_rcs,
            lang: lang,
          },
        },
        ressCommerBilanComptableResultatAttestationRevenuFilesFields: {
          upload: {
            name: names.ressCommerBilanComptableResultatAttestationRevenuFilesName,
            categorie: 'ress_commer_bilan_comptable_resultat_attestation_revenu',
            fieldArray:
              fieldArrayMapping.ressCommerBilanComptableResultatAttestationRevenuFilesFields,
            label: bloc4Cms.fichier_commercant_bilan_comptable.titre_fichier,
            hint: bloc4Cms.fichier_commercant_bilan_comptable.infos_fichier,
            helpbox: {
              title: bloc4Cms.fichier_commercant_bilan_comptable.bulle_aide_fichier_titre,
              description: bloc4Cms.fichier_commercant_bilan_comptable.bulle_aide_fichier_contenu,
              closable: false,
              severity: 'info',
            },
            state: 'default',
            stateRelatedMessage: "Text de validation / d'explication de l'erreur",
            // required: true,
            ...uploadFilesParameters,
            resource: 'demande',
            resourceId: undefined,
            infobulleContent: bloc4Cms.infobulle_fichier_commercant_bilan,
            lang: lang,
          },
        },
        ressAutentDeclarationActiviteCfeFilesFields: {
          upload: {
            name: names.ressAutentDeclarationActiviteCfeFilesName,
            categorie: 'ress_autent_declaration_activite_cfe',
            fieldArray: fieldArrayMapping.ressAutentDeclarationActiviteCfeFilesFields,
            label: bloc4Cms.fichier_autoent_declaration_activite.titre_fichier,
            hint: bloc4Cms.fichier_autoent_declaration_activite.infos_fichier,
            helpbox: {
              title: bloc4Cms.fichier_autoent_declaration_activite.bulle_aide_fichier_titre,
              description: bloc4Cms.fichier_autoent_declaration_activite.bulle_aide_fichier_contenu,
              closable: false,
              severity: 'info',
            },
            state: 'default',
            stateRelatedMessage: "Text de validation / d'explication de l'erreur",
            // required: true,
            ...uploadFilesParameters,
            resource: 'demande',
            resourceId: undefined,
            infobulleContent: bloc4Cms.infobulle_fichier_autoent_cfe,
            lang: lang,
          },
        },
        ressAutentLivreRecettesAttestationRevenuFilesFields: {
          upload: {
            name: names.ressAutentLivreRecettesAttestationRevenuFilesName,
            categorie: 'ress_autent_livre_recettes_attestation_revenu',
            fieldArray: fieldArrayMapping.ressAutentLivreRecettesAttestationRevenuFilesFields,
            label: bloc4Cms.fichier_autoent_livre_recettes.titre_fichier,
            hint: bloc4Cms.fichier_autoent_livre_recettes.infos_fichier,
            helpbox: {
              title: bloc4Cms.fichier_autoent_livre_recettes.bulle_aide_fichier_titre,
              description: bloc4Cms.fichier_autoent_livre_recettes.bulle_aide_fichier_contenu,
              closable: false,
              severity: 'info',
            },
            state: 'default',
            stateRelatedMessage: "Text de validation / d'explication de l'erreur",
            // required: true,
            ...uploadFilesParameters,
            resource: 'demande',
            resourceId: undefined,
            infobulleContent: bloc4Cms.infobulle_fichier_autoent_livre_recette,
            lang: lang,
          },
        },
        ressInvaliAttributionPensionRetraiteInvaliditeFilesFields: {
          upload: {
            name: names.ressInvaliAttributionPensionRetraiteInvaliditeFilesName,
            categorie: 'ress_invali_attribution_pension_retraite_invalidite',
            fieldArray: fieldArrayMapping.ressInvaliAttributionPensionRetraiteInvaliditeFilesFields,
            label: bloc4Cms.fichier_invalide_attribution_pension.titre_fichier,
            hint: bloc4Cms.fichier_invalide_attribution_pension.infos_fichier,
            helpbox: {
              title: bloc4Cms.fichier_invalide_attribution_pension.bulle_aide_fichier_titre,
              description: bloc4Cms.fichier_invalide_attribution_pension.bulle_aide_fichier_contenu,
              closable: false,
              severity: 'info',
            },
            state: 'default',
            stateRelatedMessage: "Text de validation / d'explication de l'erreur",
            // required: true,
            ...uploadFilesParameters,
            resource: 'demande',
            resourceId: undefined,
            infobulleContent: bloc4Cms.infobulle_fichier_retraite_invalide_decision_attribution,
            lang: lang,
          },
        },
        ressInvaliAvisVersementRetraiteInvalideFilesFields: {
          upload: {
            name: names.ressInvaliAvisVersementRetraiteInvalideFilesName,
            categorie: 'ress_invali_avis_versement_retraite_invalide',
            fieldArray: fieldArrayMapping.ressInvaliAvisVersementRetraiteInvalideFilesFields,
            label: bloc4Cms.fichier_invalide_avis_versement_pension.titre_fichier,
            hint: bloc4Cms.fichier_invalide_avis_versement_pension.infos_fichier,
            helpbox: {
              title: bloc4Cms.fichier_invalide_avis_versement_pension.bulle_aide_fichier_titre,
              description:
                bloc4Cms.fichier_invalide_avis_versement_pension.bulle_aide_fichier_contenu,
              closable: false,
              severity: 'info',
            },
            state: 'default',
            stateRelatedMessage: "Text de validation / d'explication de l'erreur",
            // required: true,
            ...uploadFilesParameters,
            resource: 'demande',
            resourceId: undefined,
            infobulleContent: bloc4Cms.infobulle_fichier_invalide_avis_versement,
            lang: lang,
          },
        },
        ressRetraiAttributionPensionRetraiteInvaliditeFilesFields: {
          upload: {
            name: names.ressRetraiAttributionPensionRetraiteInvaliditeFilesName,
            categorie: 'ress_retrai_attribution_pension_retraite_invalidite',
            fieldArray: fieldArrayMapping.ressRetraiAttributionPensionRetraiteInvaliditeFilesFields,
            label: bloc4Cms.fichier_retraite_invalidite_attribution_pension.titre_fichier,
            hint: bloc4Cms.fichier_retraite_invalidite_attribution_pension.infos_fichier,
            helpbox: {
              title:
                bloc4Cms.fichier_retraite_invalidite_attribution_pension.bulle_aide_fichier_titre,
              description:
                bloc4Cms.fichier_retraite_invalidite_attribution_pension.bulle_aide_fichier_contenu,
              closable: false,
              severity: 'info',
            },
            state: 'default',
            stateRelatedMessage: "Text de validation / d'explication de l'erreur",
            // required: true,
            ...uploadFilesParameters,
            resource: 'demande',
            resourceId: undefined,
            infobulleContent: bloc4Cms.infobulle_fichier_retraite_invalide_decision_attribution,
            lang: lang,
          },
        },
        ressRetraiAvisVersementRetraiteInvalideFilesFields: {
          upload: {
            name: names.ressRetraiAvisVersementRetraiteInvalideFilesName,
            categorie: 'ress_retrai_avis_versement_retraite_invalide',
            fieldArray: fieldArrayMapping.ressRetraiAvisVersementRetraiteInvalideFilesFields,
            label: bloc4Cms.fichier_retraite_invalidite_avis_versement_pension.titre_fichier,
            hint: bloc4Cms.fichier_retraite_invalidite_avis_versement_pension.infos_fichier,
            helpbox: {
              title:
                bloc4Cms.fichier_retraite_invalidite_avis_versement_pension
                  .bulle_aide_fichier_titre,
              description:
                bloc4Cms.fichier_retraite_invalidite_avis_versement_pension
                  .bulle_aide_fichier_contenu,
              closable: false,
              severity: 'info',
            },
            state: 'default',
            stateRelatedMessage: "Text de validation / d'explication de l'erreur",
            // required: true,
            ...uploadFilesParameters,
            resource: 'demande',
            resourceId: undefined,
            infobulleContent: bloc4Cms.infobulle_fichier_invalide_avis_versement,
            lang: lang,
          },
        },
      };
    }

    return {
      getActivities,
      getformFields,
      get_files_fields,
      get_activity_files_field,
    };
  }
}
