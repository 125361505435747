import { Union } from 'models/types/api/rf';
import { UnionDemandeForm } from 'models/types/app/demandeRf';

export const unionAppToApiFormatter = (
  data: UnionDemandeForm,
  id: string | null,
  documents: any,
  isValid: boolean,
) => {
  const union: Union = {
    id: id,
    type: data.union_precedente,
    dateDebut: data.union_debut ? `${data.union_debut}-01-01` : undefined,
    dateFin: data.union_fin ? `${data.union_fin}-01-01` : undefined,
    divorceTrad: data.union_jugement_divorce_traduit,
    documents: documents,
    estValide: isValid,
  };

  return union;
};
