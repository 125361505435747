import React from 'react';

import './ProgressBar.scss';

interface Props {
  progress: number;
}

const ProgressBar: React.FC<Props> = ({ progress }) => {
  return (
    <div className="progress-bar">
      <div className="progress-bar__container">
        <div className="progress-bar__sub-container">
          <div className="progress-bar__progress" style={{ width: `${progress}%` }} />
        </div>
      </div>
      <span className="progress-bar__percentage">{progress}%</span>
    </div>
  );
};

export default ProgressBar;
