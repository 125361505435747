import React, { FC } from 'react';
import Badge from '@codegouvfr/react-dsfr/Badge';

import { API_DATE_FORMAT, DISPLAY_DATE_DDMMYYYY } from 'constants/time';
import { RFClefStatut, RFDemande, RFStatut } from 'models/types/api/rf';
import { RFCmsTraductionApi } from 'models/types/cms/espaceRF/types';
import { formatNullableDate } from 'services/date';

import { shouldDisplayDocumentsManquants } from '../helpers/utils';

type Props = {
  demande: RFDemande;
  cmsTrad: RFCmsTraductionApi;
};

const StatutAvancement: FC<Props> = ({ demande, cmsTrad }) => {
  return (
    <>
      {shouldDisplayDocumentsManquants(demande) ||
      (demande.clefStatut === RFClefStatut.DECISION_FAVORABLE_RELANCE_PAIEMENT &&
        demande.statut === RFStatut.FAVORABLE_RELANCE_PAIEMENT) ||
      (demande.clefStatut === RFClefStatut.DECISION_FAVORABLE_ATTENTE_PAIEMENT &&
        demande.statut === RFStatut.FAVORABLE_ATTENTE_PAIEMENT) ? (
        <>
          <Badge severity="warning" className="fr-mb-3w">
            {cmsTrad.dossier_en_attente_votre_part}
          </Badge>
        </>
      ) : (
        <>
          {demande.clefStatut === RFClefStatut.DECISION_FAVORABLE &&
          demande.statut === RFStatut.FAVORABLE ? (
            <Badge severity="success" className="fr-mb-3w">
              {cmsTrad.dossier_termine}
            </Badge>
          ) : (
            <>
              {demande.clefStatut === RFClefStatut.DECISION_DEFAVORABLE &&
              demande.statut === RFStatut.DEFAVORABLE ? (
                <Badge severity="error" className="fr-mb-3w">
                  {cmsTrad.dossier_refuse}
                </Badge>
              ) : (
                <Badge severity="info" className="fr-mb-3w">
                  <>
                    {cmsTrad.dossier_en_cours_depuis}{' '}
                    {formatNullableDate(demande.dateStatut, API_DATE_FORMAT, DISPLAY_DATE_DDMMYYYY)}
                  </>
                </Badge>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default StatutAvancement;
