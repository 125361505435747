export enum MyDocumentRfCategory {
  CATEGORIE_RFNG_AT_DEPOT = 'at_depot',
  CATEGORIE_RFNG_LET_ANNUL = 'let_annul',
  CATEGORIE_RFNG_LET_IRR = 'let_irr',
  CATEGORIE_RFNG_LET_COMPL = 'let_compl',
  CATEGORIE_RFNG_LOGMT = 'logmt',
  CATEGORIE_RFNG_RESS = 'ress',
  CATEGORIE_RFNG_RF_PARTIEL = 'rf_partiel',
  CATEGORIE_RFNG_ECIVIL = 'ecivil',
  CATEGORIE_RFNG_SEJOUR = 'sejour',
  CATEGORIE_RFNG_CERFA_DP = 'cerfa_dp',
}

export enum DocumentRfCategoryCmsNameKey {
  CATEGORIE_RFNG_AT_DEPOT_NAME_KEY = 'categorie_rfng_at_depot',
  CATEGORIE_RFNG_LET_ANNUL_NAME_KEY = 'categorie_rfng_let_annul',
  CATEGORIE_RFNG_LET_IRR_NAME_KEY = 'categorie_rfng_let_irr',
  CATEGORIE_RFNG_LET_COMPL_NAME_KEY = 'categorie_rfng_let_compl',
  CATEGORIE_RFNG_LOGMT_NAME_KEY = 'categorie_rfng_logmt',
  CATEGORIE_RFNG_RESS_NAME_KEY = 'categorie_rfng_ress',
  CATEGORIE_RFNG_RF_PARTIEL_NAME_KEY = 'categorie_rfng_rf_partiel',
  CATEGORIE_RFNG_ECIVIL_NAME_KEY = 'categorie_rfng_ecivil',
  CATEGORIE_RFNG_SEJOUR_NAME_KEY = 'categorie_rfng_sejour',
  CATEGORIE_RFNG_CERFA_DP_NAME_KEY = 'categorie_rfng_cerfa_dp',
}

export const DOCUMENT_RF_NAME_BY_DOCUMENT_CATEGORY = {
  [MyDocumentRfCategory.CATEGORIE_RFNG_AT_DEPOT]:
    DocumentRfCategoryCmsNameKey.CATEGORIE_RFNG_AT_DEPOT_NAME_KEY,
  [MyDocumentRfCategory.CATEGORIE_RFNG_LET_ANNUL]:
    DocumentRfCategoryCmsNameKey.CATEGORIE_RFNG_LET_ANNUL_NAME_KEY,
  [MyDocumentRfCategory.CATEGORIE_RFNG_LET_IRR]:
    DocumentRfCategoryCmsNameKey.CATEGORIE_RFNG_LET_IRR_NAME_KEY,
  [MyDocumentRfCategory.CATEGORIE_RFNG_LET_COMPL]:
    DocumentRfCategoryCmsNameKey.CATEGORIE_RFNG_LET_COMPL_NAME_KEY,
  [MyDocumentRfCategory.CATEGORIE_RFNG_LOGMT]:
    DocumentRfCategoryCmsNameKey.CATEGORIE_RFNG_LOGMT_NAME_KEY,
  [MyDocumentRfCategory.CATEGORIE_RFNG_RESS]:
    DocumentRfCategoryCmsNameKey.CATEGORIE_RFNG_RESS_NAME_KEY,
  [MyDocumentRfCategory.CATEGORIE_RFNG_RF_PARTIEL]:
    DocumentRfCategoryCmsNameKey.CATEGORIE_RFNG_RF_PARTIEL_NAME_KEY,
  [MyDocumentRfCategory.CATEGORIE_RFNG_ECIVIL]:
    DocumentRfCategoryCmsNameKey.CATEGORIE_RFNG_ECIVIL_NAME_KEY,
  [MyDocumentRfCategory.CATEGORIE_RFNG_SEJOUR]:
    DocumentRfCategoryCmsNameKey.CATEGORIE_RFNG_SEJOUR_NAME_KEY,
  [MyDocumentRfCategory.CATEGORIE_RFNG_CERFA_DP]:
    DocumentRfCategoryCmsNameKey.CATEGORIE_RFNG_CERFA_DP_NAME_KEY,
};

export enum STATUT_DOCUMENT {
  START = 'start',
  UPLOADED = 'uploaded',
  FAILED_SCAN = 'failed_scan',
  SCANNED = 'scanned',
  INFECTED = 'infected',
  FAILED_MOVE_TO_BUCKET = 'failed_move_to_bucket',
  PENDING = 'pending',
}
