import { Commitments } from 'models/types/api/rf';

export type CommitmentsState = Readonly<Commitments>;

export const initialState: CommitmentsState = {
  isValid: false,
  isUntouched: true,
  engagementArt4211: null,
  engagementCertifieRenseignementsExact: null,
  engagementChangementSituation: null,
  engagementDeclareRfNonPolygamie: null,
  engagementRgpd: null,
  engagementSignatureDocument: null,
  haveNumFix: false,
  telFixe: null,
  telPortable: null,
  email: null,
};
