import parse, { DOMNode, domToReact, Element } from 'html-react-parser';

const handleHtmlElement = (domNode: Element) => {
  switch (domNode.name) {
    case 'p':
      return <>{domToReact(domNode.children)}</>;
    default:
      return <>{domToReact(domNode.children)}</>;
  }
};

export const CardContentHtmlParserFunction = (content: string) => {
  const options = {
    replace: (domNode: DOMNode) => {
      if (domNode instanceof Element) {
        return handleHtmlElement(domNode);
      }
    },
  };

  return parse(content, options);
};
