/* eslint-disable max-lines */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { generatePath } from 'react-router';

import {
  Accomodation,
  Conjoint,
  Enfant,
  Ressource,
  Union,
  Document,
  Demande,
  BeneficiaireAdresse,
} from 'models/types/api/rf';
import { getDocumentsByFormFromDemande, ressourcesFilesKeys } from 'helpers/api/apiToStore';
import { ProcedureDemandeType } from 'models/types/api/procedures';

import { createNewAccomodation } from './accomodation/accomodation.helper';
import { createNewChild, createNewConjoint, createNewUnion } from './family/family.helper';
import { DemandeRfState, initialState } from './utils';

import { REGROUPEMENT_FAMILIAL } from 'routes/path';

const demandeRfSlice = createSlice({
  name: 'DemandeRF',
  initialState,
  reducers: {
    setDemande: (state, action: PayloadAction<DemandeRfState>) => {
      state.id = action.payload.id;
      state.infos = action.payload.infos;
      state.identity = action.payload.identity;
      state.family = action.payload.family;
      state.accomodation = action.payload.accomodation;
      state.ressources = action.payload.ressources;
      state.commitments = action.payload.commitments;
      state.hasLoad = true;
    },
    setRFSummary: (state, action: PayloadAction<DemandeRfState['rfDemandeSummary']>) => {
      state.rfDemandeSummary.contenu = action.payload.contenu.map(summary => {
        return {
          ...summary,
          type: ProcedureDemandeType.REGROUPEMENT_FAMILIAL,
          path: generatePath(REGROUPEMENT_FAMILIAL, {
            numeroDossier: summary.numeroDossier,
          }),
        };
      });
    },
    setListDemandeRF: (state, action: PayloadAction<DemandeRfState['demandeList']>) => {
      state.demandeList = action.payload.map(demande => {
        return {
          ...demande,
          type: ProcedureDemandeType.DEMANDE_RF,
        };
      });
    },
    //Infos
    setIdCerfaRfng: (state, action: PayloadAction<string>) => {
      state.infos.idCerfaRfng = action.payload;
    },
    clearIdCerfaRfng: state => {
      state.infos.idCerfaRfng = null;
    },
    setIdRfng: (state, action: PayloadAction<string>) => {
      state.infos.idRfng = action.payload;
    },
    clearIdRfng: state => {
      state.infos.idRfng = null;
    },
    setNumeroDossier: (state, action: PayloadAction<string>) => {
      state.infos.numeroDossier = action.payload;
    },
    clearNumeroDossier: state => {
      state.infos.numeroDossier = null;
    },
    setSignatureDocumentLieu: (state, action: PayloadAction<boolean>) => {
      state.infos.signatureDocumentLieu = action.payload;
    },
    clearSignatureDocumentLieu: state => {
      state.infos.signatureDocumentLieu = null;
    },
    setStatut: (state, action: PayloadAction<string>) => {
      state.infos.statut = action.payload;
    },
    clearStatut: state => {
      state.infos.statut = '';
    },
    //Identity
    setIdentityUntouched: (state, action: PayloadAction<boolean>) => {
      state.identity.isUntouched = action.payload;
    },
    setIdentityIsValid: (state, action: PayloadAction<boolean>) => {
      state.identity.isValid = action.payload;
    },
    clearIdentityIsValid: state => {
      state.identity.isValid = false;
    },
    replaceIdentityDocuments: (
      state,
      action: PayloadAction<{
        id: string;
        documents: Document[];
      }>,
    ) => {
      state.identity.documents = [...action.payload.documents];
    },
    setIdentityDocuments: (
      state,
      action: PayloadAction<{
        id: string;
        documents: Document[];
      }>,
    ) => {
      state.identity.documents = [...state.identity.documents, ...action.payload.documents];
    },
    delIdentityDocument: (state, action: PayloadAction<{ id: string; documentId: string }>) => {
      state.identity.documents = state.identity.documents.filter((element: Document) => {
        return element.id !== action.payload.documentId;
      });
    },
    clearIdentityDocuments: state => {
      state.identity.documents = [];
    },
    /*setActeNaissanceOriginal: (state, action: PayloadAction<boolean>) => {
      state.identity.etatCivil.acteNaissanceOriginal = action.payload;
    },
    clearActeNaissanceOriginal: state => {
      state.identity.etatCivil.acteNaissanceOriginal = false;
    },*/
    setDateNaissance: (state, action: PayloadAction<string>) => {
      state.identity.etatCivil.dateNaissance = action.payload;
    },
    clearDateNaissance: state => {
      state.identity.etatCivil.dateNaissance = null;
    },
    setActeNaissanceTrad: (state, action: PayloadAction<boolean>) => {
      state.identity.etatCivil.acteNaissanceTrad = action.payload;
    },
    clearActeNaissanceTrad: state => {
      state.identity.etatCivil.acteNaissanceTrad = null;
    },
    setNationalite: (state, action: PayloadAction<string>) => {
      state.identity.etatCivil.nationalite = action.payload;
    },
    clearNationalite: state => {
      state.identity.etatCivil.nationalite = null;
    },
    setNom: (state, action: PayloadAction<string>) => {
      state.identity.etatCivil.nom = action.payload;
    },
    clearNom: state => {
      state.identity.etatCivil.nom = null;
    },
    setNomUsage: (state, action: PayloadAction<string>) => {
      state.identity.etatCivil.nomUsage = action.payload;
    },
    clearNomUsage: state => {
      state.identity.etatCivil.nomUsage = null;
    },
    setPaysNaissance: (state, action: PayloadAction<string>) => {
      state.identity.etatCivil.paysNaissance = action.payload;
    },
    clearPaysNaissance: state => {
      state.identity.etatCivil.paysNaissance = null;
    },
    setPrenoms: (state, action: PayloadAction<string>) => {
      state.identity.etatCivil.prenoms = action.payload;
    },
    clearPrenoms: state => {
      state.identity.etatCivil.prenoms = null;
    },
    setSexe: (state, action: PayloadAction<string>) => {
      state.identity.etatCivil.sexe = action.payload;
    },
    clearSexe: state => {
      state.identity.etatCivil.sexe = '';
    },
    setVilleNaissance: (state, action: PayloadAction<string>) => {
      state.identity.etatCivil.villeNaissance = action.payload;
    },
    clearVilleNaissance: state => {
      state.identity.etatCivil.villeNaissance = null;
    },
    setMarieEnFrance: (state, action: PayloadAction<boolean>) => {
      state.identity.marieEnFrance = action.payload;
    },
    clearMarieEnFrance: state => {
      state.identity.marieEnFrance = null;
    },
    setSituationMatrimonial: (state, action: PayloadAction<string>) => {
      state.identity.situationMatrimonial = action.payload;
    },
    clearSituationMatrimonial: state => {
      state.identity.situationMatrimonial = null;
    },
    setSituationMatrimonialDate: (state, action: PayloadAction<string>) => {
      state.identity.situationMatrimonialDate = action.payload;
    },
    clearSituationMatrimonialDate: state => {
      state.identity.situationMatrimonialDate = null;
    },
    setSituationMatrimonialOriginal: (state, action: PayloadAction<string>) => {
      state.identity.situationMatrimonial = action.payload;
    },
    setSituationMatrimonialTrad: (state, action: PayloadAction<boolean>) => {
      state.identity.situationMatrimonialTrad = action.payload;
    },
    clearSituationMatrimonialOriginal: state => {
      state.identity.situationMatrimonial = null;
    },
    setTitreDeSejour: (state, action: PayloadAction<string>) => {
      state.identity.titreDeSejour = action.payload;
    },
    clearTitreDeSejour: state => {
      state.identity.titreDeSejour = null;
    },
    setTitreDeSejourAutre: (state, action: PayloadAction<string>) => {
      state.identity.titreDeSejourAutre = action.payload;
    },
    clearTitreDeSejourAutre: state => {
      state.identity.titreDeSejourAutre = null;
    },
    setTitreDeSejourFin: (state, action: PayloadAction<string>) => {
      state.identity.titreDeSejourFin = action.payload;
    },
    clearTitreDeSejourFin: state => {
      state.identity.titreDeSejourFin = null;
    },
    setTitreDeSejourMention: (state, action: PayloadAction<string>) => {
      state.identity.titreDeSejourMention = action.payload;
    },
    clearTitreDeSejourMention: state => {
      state.identity.titreDeSejourMention = null;
    },
    setTitreDeSejourMentionAutre: (state, action: PayloadAction<string>) => {
      state.identity.titreDeSejourMentionAutre = action.payload;
    },
    clearTitreDeSejourMentionAutre: state => {
      state.identity.titreDeSejourMentionAutre = null;
    },
    setTitreDeSejourRenouvellement: (state, action: PayloadAction<boolean>) => {
      state.identity.titreDeSejourRenouvellement = action.payload;
    },
    clearTitreDeSejourRenouvellement: state => {
      state.identity.titreDeSejourRenouvellement = null;
    },
    //Family
    setFamilyUntouched: (state, action: PayloadAction<boolean>) => {
      state.family.isUntouched = action.payload;
    },
    setFamilyValidation: (state, action: PayloadAction<boolean>) => {
      state.family.isValid = action.payload;
    },
    setFamilyDocuments: (
      state,
      action: PayloadAction<{
        id: string;
        documents: Document[];
      }>,
    ) => {
      state.family.documents = [...state.family.documents, ...action.payload.documents];
    },
    replaceFamilyDocuments: (
      state,
      action: PayloadAction<{
        id: string;
        documents: Document[];
      }>,
    ) => {
      state.family.documents = [...action.payload.documents];
    },
    delFamilyDocument: (state, action: PayloadAction<{ id: string; documentId: string }>) => {
      state.family.documents = state.family.documents.filter((element: Document) => {
        return element.id !== action.payload.documentId;
      });
    },
    updAddressesStore: (state, action: PayloadAction<BeneficiaireAdresse>) => {
      let trouve = false;
      const newArray = state.family.adresses?.map(address => {
        if (address.id === action.payload.id) {
          trouve = true;
          return { ...address, ...action.payload };
        }
        return address;
      });

      if (!trouve && state.family.adresses) {
        //const newArray = state.family.adresses?.slice();
        state.family.adresses = [...state.family.adresses, ...[action.payload]];
      } else {
        state.family.adresses = newArray;
      }
    },
    createConjoint: (state, action: PayloadAction<string>) => {
      state.family.conjoint.push(createNewConjoint(action.payload));
    },
    setConjointStore: (state, action: PayloadAction<Conjoint>) => {
      const newArray = state.family.conjoint.slice();
      newArray.splice(state.family.conjoint.length, 0, action.payload);
      state.family.conjoint = newArray;
    },
    updConjointStore: (state, action: PayloadAction<Conjoint>) => {
      const newArray = state.family.conjoint.map(conjoint => {
        if (action.payload.uuid) {
          if (conjoint.id !== action.payload.uuid) {
            return { ...conjoint };
          }
          const newConjoint = {
            ...conjoint,
            ...action.payload,
          };
          delete newConjoint.uuid;
          return newConjoint;
        } else {
          if (conjoint.id !== action.payload.id) {
            return { ...conjoint };
          }
          return {
            ...conjoint,
            ...action.payload,
          };
        }
      });
      state.family.conjoint = newArray;
    },
    delConjointStore: (state, action: PayloadAction<string>) => {
      const newArray = state.family.conjoint.slice();
      state.family.conjoint = newArray.filter(item => item.id !== action.payload);
    },
    // removeConjoint: (state, action: PayloadAction<string>) => {
    //TODO
    // },
    clearConjoints: state => {
      state.family.conjoint = [];
    },
    setConjointDocuments: (state, action: PayloadAction<Conjoint>) => {
      state.family.conjoint.map(conjoint => {
        if (conjoint.id === action.payload.id) {
          conjoint.documents = [...conjoint.documents, ...action.payload.documents];
        }
        return conjoint;
      });
    },
    delConjointDocuments: (state, action: PayloadAction<{ id: string; documentId: string }>) => {
      state.family.conjoint.map(conjoint => {
        if (conjoint.id === action.payload.id) {
          conjoint.documents = conjoint.documents.filter((element: Document) => {
            return element.id !== action.payload.documentId;
          });
        }
        return conjoint;
      });
    },
    setEnfantStore: (state, action: PayloadAction<Enfant>) => {
      const newArray = state.family.enfants.slice();
      newArray.splice(state.family.enfants.length, 0, action.payload);
      state.family.enfants = newArray;
    },
    updEnfantStore: (state, action: PayloadAction<Enfant>) => {
      const newArray = state.family.enfants.map(enfant => {
        if (action.payload.uuid) {
          if (enfant.id !== action.payload.uuid) {
            return { ...enfant };
          }
          const newEnfant = {
            ...enfant,
            ...action.payload,
          };
          delete newEnfant.uuid;
          return newEnfant;
        } else {
          if (enfant.id !== action.payload.id) {
            return { ...enfant };
          }
          return {
            ...enfant,
            ...action.payload,
          };
        }
      });
      state.family.enfants = newArray;
    },
    delEnfantStore: (state, action: PayloadAction<string>) => {
      const newArray = state.family.enfants.slice();
      state.family.enfants = newArray.filter(item => item.id !== action.payload);
    },
    createEnfant: (state, action: PayloadAction<string>) => {
      state.family.enfants.push(createNewChild(action.payload));
    },
    clearEnfants: state => {
      state.family.enfants = [];
    },
    setEnfantDocuments: (state, action: PayloadAction<Enfant>) => {
      state.family.enfants.map(enfant => {
        if (enfant.id === action.payload.id) {
          enfant.documents = [...enfant.documents, ...action.payload.documents];
        }
        return enfant;
      });
    },
    delEnfantDocuments: (state, action: PayloadAction<{ id: string; documentId: string }>) => {
      state.family.enfants.map(enfant => {
        if (enfant.id === action.payload.id) {
          enfant.documents = enfant.documents.filter((element: Document) => {
            return element.id !== action.payload.documentId;
          });
        }
        return enfant;
      });
    },
    createUnion: (state, action: PayloadAction<string>) => {
      state.family.unions.push(createNewUnion(action.payload));
    },
    //removeUnion: (state, action: PayloadAction<string>) => {
    //TODO
    //},
    clearUnions: state => {
      state.family.unions = [];
    },
    setUnionStore: (state, action: PayloadAction<Union>) => {
      const newArray = state.family.unions.slice();
      newArray.splice(state.family.unions.length, 0, action.payload);
      state.family.unions = newArray;
    },
    updUnionStore: (state, action: PayloadAction<Union>) => {
      const newArray = state.family.unions.map(union => {
        if (action.payload.uuid) {
          if (union.id !== action.payload.uuid) {
            return { ...union };
          }
          const newUnion = {
            ...union,
            ...action.payload,
          };
          delete newUnion.uuid;
          return newUnion;
        } else {
          if (union.id !== action.payload.id) {
            return { ...union };
          }
          return {
            ...union,
            ...action.payload,
          };
        }
      });
      state.family.unions = newArray;
    },
    delUnionStore: (state, action: PayloadAction<string>) => {
      const newArray = state.family.unions.slice();
      state.family.unions = newArray.filter(item => item.id !== action.payload);
    },
    setUnionDocuments: (state, action: PayloadAction<Union>) => {
      state.family.unions.map(union => {
        if (union.id === action.payload.id) {
          union.documents = [...union.documents, ...action.payload.documents];
        }
        return union;
      });
    },
    delUnionDocuments: (state, action: PayloadAction<{ id: string; documentId: string }>) => {
      state.family.unions.map(union => {
        if (union.id === action.payload.id) {
          union.documents = union.documents.filter((element: Document) => {
            return element.id !== action.payload.documentId;
          });
        }
        return union;
      });
    },
    //Accomodation
    createAccomodation: (state, action: PayloadAction<string>) => {
      state.accomodation.accomodations.push(createNewAccomodation(action.payload));
    },
    setAccomodationUntouched: (state, action: PayloadAction<boolean>) => {
      state.accomodation.isUntouched = action.payload;
    },
    setAccomodationValidation: (state, action: PayloadAction<boolean>) => {
      state.accomodation.isValid = action.payload;
    },
    setAccomodation: (state, action: PayloadAction<Accomodation>) => {
      if (state.accomodation?.accomodations) {
        const newAccomodationArray = state.accomodation.accomodations.slice();
        newAccomodationArray.splice(state.accomodation.accomodations.length, 0, action.payload);
        state.accomodation.accomodations = newAccomodationArray;
      }
    },
    updateAccomodation: (state, action: PayloadAction<Accomodation>) => {
      if (state.accomodation?.accomodations) {
        if (
          action.payload.id &&
          state.accomodation?.accomodations.some(
            accomodation => accomodation.id === action.payload.id,
          )
        ) {
          const newAccomodationArray = state.accomodation?.accomodations.map(accomodation => {
            if (accomodation.id !== action.payload.id) {
              return { ...accomodation };
            }

            const newAccomodation = {
              ...accomodation,
              ...action.payload,
            };

            return newAccomodation;
          });
          state.accomodation.accomodations = newAccomodationArray;
        } else {
          const newAccomodationArray = state.accomodation?.accomodations.map(accomodation => {
            return { ...accomodation };
          });
          newAccomodationArray.push(action.payload);
          state.accomodation.accomodations = newAccomodationArray;
        }
      }
    },
    removeAccomodation: (state, action: PayloadAction<string>) => {
      if (state.accomodation?.accomodations) {
        const newAccomodationArray = state.accomodation.accomodations.slice();
        state.accomodation.accomodations = newAccomodationArray.filter(
          accomodation => accomodation.id !== action.payload,
        );
      }
    },
    clearAccomodations: state => {
      state.accomodation.accomodations = [];
    },
    /*clearAccomodation: state => {
      state = initialState;
    },*/
    setAccomodationDocuments: (state, action: PayloadAction<Accomodation>) => {
      state.accomodation.accomodations.map(accomodation => {
        if (accomodation.id === action.payload.id) {
          accomodation.documents = [...accomodation.documents, ...action.payload.documents];
        }
        return accomodation;
      });
    },
    delAccomodationDocuments: (
      state,
      action: PayloadAction<{
        id: string;
        documentId: string;
      }>,
    ) => {
      state.accomodation.accomodations.map(logement => {
        if (logement.id === action.payload.id) {
          logement.documents = logement.documents.filter((element: Document) => {
            return element.id !== action.payload.documentId;
          });
        }
        return logement;
      });
    },
    //Ressources
    setRessourcesUntouched: (state, action: PayloadAction<boolean>) => {
      state.ressources.isUntouched = action.payload;
    },
    setRessourcesValidation: (state, action: PayloadAction<boolean>) => {
      state.ressources.isValid = action.payload;
    },
    setActivites: (state, action: PayloadAction<Ressource>) => {
      if (state.ressources.activites) {
        const newArray = state.ressources.activites.slice();
        newArray.splice(state.ressources.activites.length, 0, action.payload);
        state.ressources.activites = newArray;
      }
    },
    updateActivites: (state, action: PayloadAction<Ressource>) => {
      if (state.ressources?.activites) {
        const newArray = state.ressources.activites.map(ressource => {
          if (ressource.id !== action.payload.uuid) {
            return { ...ressource };
          }
          return {
            ...ressource,
            ...action.payload,
          };
        });
        state.ressources.activites = newArray;
      }
    },
    removeActivites: (state, action: PayloadAction<number>) => {
      //TODO
      if (state.ressources?.activites) {
        const newArray = state.ressources.activites.slice();
        newArray.splice(action.payload, 1);
        state.ressources.activites = newArray;
      }
    },
    clearActivites: state => {
      state.ressources.activites = [];
    },
    setActiviteDocuments: (state, action: PayloadAction<Omit<Ressource, 'activite'>>) => {
      state.ressources.activites.map(activite => {
        if (activite.id === action.payload.id) {
          if (activite.documents && action.payload.documents) {
            activite.documents = [...activite.documents, ...action.payload.documents];
          }
        }
        return activite;
      });
    },
    delActiviteDocuments: (state, action: PayloadAction<{ id: string; documentId: string }>) => {
      state.ressources.activites.map(activite => {
        if (activite.id === action.payload.id) {
          activite.documents = activite.documents?.filter((element: Document) => {
            return element.id !== action.payload.documentId;
          });
        }
        return activite;
      });
    },
    updResourcesField: (state, action: PayloadAction<{ key: string; value: boolean }>) => {
      switch (action.payload.key) {
        case 'travailDansBtp':
          state.ressources.travailDansBtp = action.payload.value;
          break;
        case 'arretTravail12DernierMois':
          state.ressources.arretTravail12DernierMois = action.payload.value;
          break;
        case 'beneficiaireAahOuAsi':
          state.ressources.beneficiaireAahOuAsi = action.payload.value;
          break;
        case 'autresRessources':
          state.ressources.autresRessources = action.payload.value;
          break;
        case 'pensionAlimentaire':
          state.ressources.pensionAlimentaire = action.payload.value;
          break;
        case 'declarerConjointRessources':
          state.ressources.declarerConjointRessources = action.payload.value;
          break;
        default:
          break;
      }
    },
    updResourcesStore: (state, action: PayloadAction<Demande>) => {
      const newResourcesState = {
        isValid: state.ressources.isValid,
        isUntouched: state.ressources.isUntouched,
        activites: action.payload.demandeResources ? action.payload.demandeResources : [],
        documents: getDocumentsByFormFromDemande(action.payload, ressourcesFilesKeys),
        arretTravail12DernierMois: action.payload.arretTravail12DernierMois,
        autresRessources: action.payload.autresRessources,
        beneficiaireAahOuAsi: action.payload.beneficiaireAahOuAsi,
        declarerConjointRessources: action.payload.declarerConjointRessources,
        pensionAlimentaire: action.payload.pensionAlimentaire,
        travailDansBtp: action.payload.travailDansBtp,
      };
      state.ressources = newResourcesState;
    },
    setArretTravail12DernierMois: (state, action: PayloadAction<boolean>) => {
      state.ressources.arretTravail12DernierMois = action.payload;
    },
    clearArretTravail12DernierMois: state => {
      state.ressources.arretTravail12DernierMois = null;
    },
    setAutresRessources: (state, action: PayloadAction<boolean>) => {
      state.ressources.autresRessources = action.payload;
    },
    clearAutresRessources: state => {
      state.ressources.autresRessources = null;
    },
    setBeneficiaireAahOuAsi: (state, action: PayloadAction<boolean>) => {
      state.ressources.beneficiaireAahOuAsi = action.payload;
    },
    clearBeneficiaireAahOuAsi: state => {
      state.ressources.beneficiaireAahOuAsi = null;
    },
    setDeclarerConjointRessources: (state, action: PayloadAction<boolean>) => {
      state.ressources.declarerConjointRessources = action.payload;
    },
    clearDeclarerConjointRessources: state => {
      state.ressources.declarerConjointRessources = null;
    },
    setRessourcesDocuments: (
      state,
      action: PayloadAction<{ id: string; documents: Document[] }>,
    ) => {
      state.ressources.documents = action.payload.documents;
    },
    delRessourcesDocuments: (state, action: PayloadAction<{ id: string; documentId: string }>) => {
      if (state.id === action.payload.id) {
        state.ressources.documents = state.ressources.documents.filter((element: Document) => {
          return element.id !== action.payload.documentId;
        });
      }
    },
    //removeDocuments: (state, action: PayloadAction<string>) => {
    //TODO
    //},
    clearRessourcesDocuments: state => {
      state.ressources.documents = [];
    },
    setPensionAlimentaire: (state, action: PayloadAction<boolean>) => {
      state.ressources.pensionAlimentaire = action.payload;
    },
    clearPensionAlimentaire: state => {
      state.ressources.pensionAlimentaire = null;
    },
    setTravailDansBtp: (state, action: PayloadAction<boolean>) => {
      state.ressources.travailDansBtp = action.payload;
    },
    clearTravailDansBtp: state => {
      state.ressources.travailDansBtp = null;
    },
    /*clearRessource: state => {
      state = initialState;
    },*/
    //Commitment
    setCommitmentsUntouched: (state, action: PayloadAction<boolean>) => {
      state.commitments.isUntouched = action.payload;
    },
    setCommitmentsEmail: (state, action: PayloadAction<string>) => {
      state.commitments.email = action.payload;
    },
    setCommitmentsValidation: (state, action: PayloadAction<boolean>) => {
      state.commitments.isValid = action.payload;
    },
    setEngagementArt4211: (state, action: PayloadAction<boolean>) => {
      state.commitments.engagementArt4211 = action.payload;
    },
    clearEngagementArt4211: state => {
      state.commitments.engagementArt4211 = null;
    },
    setEngagementCertifieRenseignementsExact: (state, action: PayloadAction<boolean>) => {
      state.commitments.engagementCertifieRenseignementsExact = action.payload;
    },
    clearEngagementCertifieRenseignementsExact: state => {
      state.commitments.engagementCertifieRenseignementsExact = null;
    },
    setEngagementChangementSituation: (state, action: PayloadAction<boolean>) => {
      state.commitments.engagementChangementSituation = action.payload;
    },
    clearEngagementChangementSituation: state => {
      state.commitments.engagementChangementSituation = null;
    },
    setEngagementDeclareRfNonPolygamie: (state, action: PayloadAction<boolean>) => {
      state.commitments.engagementDeclareRfNonPolygamie = action.payload;
    },
    clearEngagementDeclareRfNonPolygamie: state => {
      state.commitments.engagementDeclareRfNonPolygamie = null;
    },
    setEngagementRgpd: (state, action: PayloadAction<boolean>) => {
      state.commitments.engagementRgpd = action.payload;
    },
    setHaveNumFix: (state, action: PayloadAction<boolean>) => {
      state.commitments.haveNumFix = action.payload;
    },
    clearEngagementRgpd: state => {
      state.commitments.engagementRgpd = null;
    },
    setEngagementSignatureDocument: (state, action: PayloadAction<boolean>) => {
      state.commitments.engagementSignatureDocument = action.payload;
    },
    clearEngagementSignatureDocument: state => {
      state.commitments.engagementSignatureDocument = null;
    },
    setTelFixe: (state, action: PayloadAction<string>) => {
      state.commitments.telFixe = action.payload;
    },
    clearTelFixe: state => {
      state.commitments.telFixe = null;
    },
    setTelPortable: (state, action: PayloadAction<string>) => {
      state.commitments.telPortable = action.payload;
    },
    clearTelPortable: state => {
      state.commitments.telPortable = null;
    },
    setDemandeSubmitted: (state, action: PayloadAction<boolean>) => {
      state.isDemandeHasBeenSubmitted = action.payload;
    },
  },
});

export const {
  setDemande,
  setRFSummary,
  setListDemandeRF,
  setIdCerfaRfng,
  setIdRfng,
  setNumeroDossier,
  setSignatureDocumentLieu,
  setStatut,
  clearIdCerfaRfng,
  clearIdRfng,
  clearNumeroDossier,
  clearSignatureDocumentLieu,
  clearStatut,
  //clearRfInfos,
  //Identity
  setIdentityUntouched,
  setIdentityIsValid,
  setIdentityDocuments,
  replaceIdentityDocuments,
  delIdentityDocument,
  //setActeNaissanceOriginal,
  setDateNaissance,
  setActeNaissanceTrad,
  setNationalite,
  setNom,
  setNomUsage,
  setPaysNaissance,
  setPrenoms,
  setSexe,
  setVilleNaissance,
  setMarieEnFrance,
  setSituationMatrimonial,
  setSituationMatrimonialDate,
  setSituationMatrimonialTrad,
  setSituationMatrimonialOriginal,
  setTitreDeSejour,
  setTitreDeSejourAutre,
  setTitreDeSejourFin,
  setTitreDeSejourMention,
  setTitreDeSejourMentionAutre,
  setTitreDeSejourRenouvellement,
  clearIdentityIsValid,
  clearIdentityDocuments,
  //clearActeNaissanceOriginal,
  clearDateNaissance,
  clearActeNaissanceTrad,
  clearNationalite,
  clearNom,
  clearNomUsage,
  clearPaysNaissance,
  clearPrenoms,
  clearSexe,
  clearVilleNaissance,
  clearMarieEnFrance,
  clearSituationMatrimonial,
  clearSituationMatrimonialDate,
  clearSituationMatrimonialOriginal,
  clearTitreDeSejour,
  clearTitreDeSejourAutre,
  clearTitreDeSejourFin,
  clearTitreDeSejourMention,
  clearTitreDeSejourMentionAutre,
  clearTitreDeSejourRenouvellement,
  //clearIdentity,
  //Family
  setFamilyUntouched,
  setFamilyValidation,
  setFamilyDocuments,
  replaceFamilyDocuments,
  delFamilyDocument,
  updAddressesStore,
  createConjoint,
  setConjointStore,
  updConjointStore,
  delConjointStore,
  createEnfant,
  setEnfantStore,
  updEnfantStore,
  delEnfantStore,
  createUnion,
  //removeConjoint,
  //removeEnfant,
  //removeUnion,
  clearConjoints,
  setConjointDocuments,
  delConjointDocuments,
  clearEnfants,
  setEnfantDocuments,
  delEnfantDocuments,
  clearUnions,
  setUnionStore,
  updUnionStore,
  delUnionStore,
  setUnionDocuments,
  delUnionDocuments,
  // Accomodation
  setAccomodationUntouched,
  setAccomodationValidation,
  setAccomodation,
  updateAccomodation,
  removeAccomodation,
  clearAccomodations,
  setAccomodationDocuments,
  delAccomodationDocuments,
  //Ressources
  setRessourcesUntouched,
  setRessourcesValidation,
  setActivites,
  delActiviteDocuments,
  setArretTravail12DernierMois,
  setAutresRessources,
  setBeneficiaireAahOuAsi,
  setDeclarerConjointRessources,
  setRessourcesDocuments,
  delRessourcesDocuments,
  setPensionAlimentaire,
  setTravailDansBtp,
  updateActivites,
  removeActivites,
  updResourcesStore,
  updResourcesField,
  //removeDocuments,
  clearActivites,
  setActiviteDocuments,
  clearArretTravail12DernierMois,
  clearAutresRessources,
  clearBeneficiaireAahOuAsi,
  clearDeclarerConjointRessources,
  clearRessourcesDocuments,
  clearPensionAlimentaire,
  clearTravailDansBtp,
  //Commitments
  setCommitmentsUntouched,
  setCommitmentsValidation,
  setEngagementArt4211,
  setHaveNumFix,
  setEngagementCertifieRenseignementsExact,
  setEngagementChangementSituation,
  setEngagementDeclareRfNonPolygamie,
  setEngagementRgpd,
  setEngagementSignatureDocument,
  setTelFixe,
  setTelPortable,
  clearEngagementArt4211,
  clearEngagementCertifieRenseignementsExact,
  clearEngagementChangementSituation,
  clearEngagementDeclareRfNonPolygamie,
  clearEngagementRgpd,
  clearEngagementSignatureDocument,
  clearTelFixe,
  clearTelPortable,
  setDemandeSubmitted,
  setCommitmentsEmail,
} = demandeRfSlice.actions;

export default demandeRfSlice.reducer;
