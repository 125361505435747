export enum RESIDENCE_LIEU {
  ETRANGER = 'etranger',
  FRANCE = 'en_france',
}

export enum LIEN_PARENTE {
  EPOUX = 'epoux',
  ENFANT = 'enfant',
}

export enum FILIATION {
  LEGITIME = 'legitime',
  ADOPTIVE = 'adoptive',
  KAFALA = 'kafala',
}

export enum LIEN_AUTRE_PARENT {
  CONJOINT_ACTUEL = 'conjoint_actuel',
  DIVORCE = 'divorce',
  SEPARE = 'separe',
  DECEDE = 'decede',
  DISPARU = 'disparu',
  AUCUN = 'aucun',
}

export enum UNION_TYPE {
  MARIE = 'marie',
  PACSE = 'pacse',
}

export enum FAMILY_TYPE {
  CONJOINT = 'conjoint',
  ENFANT = 'enfant',
  UNION = 'union',
}

export enum STATUT_DEMANDE {
  START = 'start',
  DRAFT = 'draft',
  READY = 'ready',
  PENDING = 'pending',
  SUBMITTED = 'submitted',
  ERROR_PROCESSING = 'error_processing',
}
