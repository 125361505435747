import { saveAs } from 'file-saver';

const base64toBlob = (b64Data: string, contentType: string, sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: contentType });
};

export const downloadFile = (response: string, filename: string, type?: string) => {
  let contentType = 'application/pdf';
  switch (type) {
    case 'png':
      contentType = `image/png`;
      break;
    case 'jpeg':
    case 'jpg':
      contentType = `image/jpeg`;
      break;
  }
  const blob = base64toBlob(response, contentType);
  saveAs(blob, filename);
};
