import React, { useRef, useEffect, useState } from 'react';
import { fr } from '@codegouvfr/react-dsfr';

import './CustomModal.style.scss';

interface Props {
  id: string;
  isOpen: boolean;
  hasCloseBtn?: boolean;
  onClose?: () => void;
  children: React.ReactNode;
}

const CustomModal: React.FC<Props> = ({ id, isOpen, hasCloseBtn = true, onClose, children }) => {
  const [isModalOpen, setModalOpen] = useState(isOpen);
  const modalRef = useRef<HTMLDialogElement | null>(null);
  const handleCloseModal = () => {
    if (onClose) {
      onClose();
    }
    setModalOpen(false);
  };
  const handleKeyDown = (event: React.KeyboardEvent<HTMLDialogElement>) => {
    if (event.key === 'Escape') {
      handleCloseModal();
    }
  };

  useEffect(() => {
    setModalOpen(isOpen);
  }, [isOpen]);

  useEffect(() => {
    const modalElement = modalRef.current;
    if (modalElement) {
      if (isModalOpen) {
        modalElement.showModal();
      } else {
        modalElement.close();
      }
    }
  }, [isModalOpen]);

  return (
    <dialog
      ref={modalRef}
      onKeyDown={handleKeyDown}
      className="custom-modal"
      id={id}
      onClick={handleCloseModal}
    >
      <div
        className="fr-modal__body"
        onClick={e => {
          e.stopPropagation();
        }}
      >
        <div className="fr-modal__header">
          {hasCloseBtn && (
            <button
              className={fr.cx('fr-btn--close', 'fr-btn')}
              onClick={handleCloseModal}
              type="button"
            >
              fermer
            </button>
          )}
        </div>
        <div className="fr-modal__content">{children}</div>
      </div>
    </dialog>
  );
};

export default CustomModal;
