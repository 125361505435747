export const ACCUEIL = '/';
export const ACCUEIL_DIRECT = '/accueil';
export const PROCEDURES = '/les-procedures';
export const PROCEDURE = '/les-procedures/:lien';
export const SERVICES_EN_LIGNE = '/les-services-en-ligne';
export const ACCUEIL_ET_INTEGRATION = '/accueil-et-integration';
export const ARTICLES = '/articles';
export const ARTICLE = '/articles/:lien';
export const LOCALISATION = '/localisation';
export const CREATION_DE_COMPTE = '/creation-compte';
export const MENTION_LEGALES = '/mentions-legales';
export const RGPD = '/rgpd';
export const ACCESSIBILITY = '/accessibilite';
export const AUTH_CHECK = '/auth/check';
export const TABLEAU_DE_BORD = '/tableau-de-bord';
export const CONTACT = '/contact';
export const COOKIE = '/cookie';
export const PLAN_DU_SITE = '/plan-du-site';
export const MAINTENANCE = '/maintenance';
export const ASILE = '/espace-asile';
export const ALLOCATION = '/espace-asile/mon-allocation';
export const DOCUMENTS = '/mes-documents';
export const REGROUPEMENT_FAMILIAL = '/espace-regroupement-familial/:numeroDossier/dossier';

export const COMPTE = '/compte';
export const DEMANDE_RF = '/demande-regroupement-familial';
export const SUIVI_RF = '/espace-regroupement-familial/:numeroDossier/dossier';
export const ERROR_AUTH = '/erreur-authentification';
export const ERROR_SERVICE = '/erreur-service';
export const ERROR = '/erreur';

export const KEY_PATHS = {
  accueil: {
    path: ACCUEIL,
  },
  procedures: {
    path: PROCEDURES,
  },
  procedure: {
    path: PROCEDURE,
  },
  services: {
    path: SERVICES_EN_LIGNE,
  },
  accueil_et_integration: {
    path: ACCUEIL_ET_INTEGRATION,
  },
  articles: {
    path: ARTICLES,
  },
  article: {
    path: ARTICLE,
  },
  location: {
    path: LOCALISATION,
  },
  creation_de_compte: {
    path: CREATION_DE_COMPTE,
  },
  mentions: {
    path: MENTION_LEGALES,
  },
  rgpd: {
    path: RGPD,
  },
  accessibilite: {
    path: ACCESSIBILITY,
  },
  dashboard: {
    path: TABLEAU_DE_BORD,
  },
  contact: {
    path: CONTACT,
  },
  cookie: {
    path: COOKIE,
  },
  plan_du_site: {
    path: PLAN_DU_SITE,
  },
  maintenance: {
    path: MAINTENANCE,
  },
  asile: {
    path: ASILE,
  },
  allocation: {
    path: ALLOCATION,
  },
  compte: {
    path: COMPTE,
  },
  demande_rf: {
    path: DEMANDE_RF,
  },
  suivi_rf: {
    path: SUIVI_RF,
  },
  erreur_auth: {
    path: ERROR_AUTH,
  },
  erreur_service: {
    path: ERROR_SERVICE,
  },
  erreur: {
    path: ERROR,
  },
};
