import { FC, ReactNode } from 'react';

interface TwoSlotElementProps {
  className: string;
  children: ReactNode;
}

const TwoSlotElement: FC<TwoSlotElementProps> = ({ className, children }) => {
  return <div className={`fr-col-lg-6  ${className}__container`}>{children}</div>;
};

export default TwoSlotElement;
