import {
  AccountCreationPage,
  AccueilEtIntegrationPage,
  AccueilPage,
  LocationPage,
  MentionsPage,
  PageBase,
  RgpdPage,
  CookiePage,
  AccessibilitePage,
  ProceduresPage,
  AccountPage,
  DashboardPage,
} from 'models/types/app/pages';
import { ArticlesCmsPage } from 'models/types/cms/articles/types';
import { AccueilEtIntegrationCmsPage } from 'models/types/cms/accueilEtIntegration/type';
import { AccueilCmsDto } from 'models/types/cms/accueil/types';
import { LocationCmsDto } from 'models/types/cms/location/types';
import { CreationCompteCmsPage } from 'models/types/cms/creationDeCompte/type';
import { MentionsCmsPage } from 'models/types/cms/mentions/types';
import { RgpdCmsPage } from 'models/types/cms/rgpd/types';
import { CookieCmsPage } from 'models/types/cms/cookie/types';
import { AccessibiliteCmsPage } from 'models/types/cms/accessibilite/types';
import { CompteCmsPage } from 'models/types/cms/compte/type';
import { DashboardCmsPage } from 'models/types/cms/dashboard/type';

export const formatAccueilFromApiToApp = (data: AccueilCmsDto): AccueilPage => {
  const attributes = data.data.attributes;
  return {
    titre: attributes?.titre,
    banner: {
      titre: attributes?.titre,
      sous_titre: attributes?.sous_titre,
      texte_button: attributes?.texte_bouton,
    },
    services: {
      titre: attributes.nos_services.titre,
      bloc_1: {
        titre: attributes.nos_services.bloc_1?.titre,
        description: attributes.nos_services.bloc_1?.description,
      },
      bloc_2: {
        titre: attributes.nos_services.bloc_2?.titre,
        description: attributes.nos_services.bloc_2?.description,
      },
      bloc_3: {
        titre: attributes.nos_services.bloc_3?.titre,
        description: attributes.nos_services.bloc_3?.description,
      },
    },
    integration: {
      titre: attributes.integration_france.titre,
      bloc_1: {
        titre: attributes.integration_france.bloc_1?.titre,
        description: attributes.integration_france.bloc_1?.description,
        lien: attributes.integration_france.bloc_1?.lien,
      },
      bloc_2: {
        titre: attributes.integration_france.bloc_2?.titre,
        description: attributes.integration_france.bloc_2?.description,
        lien: attributes.integration_france.bloc_2?.lien,
      },
    },
  };
};

export const formatArticlesPageDataFromApiToApp = (data: ArticlesCmsPage): PageBase => {
  return {
    titre: data.titre,
  };
};

export const formatLocationPageFromApi = (res: LocationCmsDto): LocationPage => {
  return {
    titre: '',
    description_form: res.data.attributes.description_avec_formulaire,
    description_whithout_form: res.data.attributes.description_sans_formulaire,
    zipCode_label: res.data.attributes.label_code_postal,
    results: res.data.attributes.resultat,
    button: res.data.attributes.texte_bouton,
    map_link: res.data.attributes.texte_voir_sur_maps,
    call: res.data.attributes.texte_appeler_direction_territoriale,
    closed: res.data.attributes.ferme,
    errors: {
      dt: res.data.attributes.erreur_direction_territoriale_non_trouvee,
      zipCode: res.data.attributes.code_postal_invalide,
      title: res.data.attributes.message_erreur_titre,
      other: res.data.attributes.autre_erreur,
    },
    erreur_direction_territoriale_non_trouvee:
      res.data.attributes.erreur_direction_territoriale_non_trouvee,
    description_sans_formulaire: res.data.attributes.description_sans_formulaire,
    non_rattache_dt: res.data.attributes.non_rattache_dt,
    titre_coordonnees: res.data.attributes.titre_coordonnees,
    dt_sur_la_carte: res.data.attributes.dt_sur_la_carte,
    horaires: res.data.attributes.horaires,
  };
};

export const formatAccueilEtIntegrationPage = (
  res: AccueilEtIntegrationCmsPage,
): AccueilEtIntegrationPage => {
  return {
    id: 'accueil-et-integration',
    titre: 'Accueil et Intégration',
    contenu: res.data.attributes
      ? [...res.data.attributes.etapes_parcours, ...res.data.attributes.apprendre_francais]
      : [],
  };
};

export const formatAccountCreationPage = (page: CreationCompteCmsPage): AccountCreationPage => {
  return {
    id: page.data.id,
    titre: page.data.attributes.titre,
    sous_titre: page.data.attributes.sous_titre,
    disclaimer: page.data.attributes.disclaimer,
    label_prenom_1: page.data.attributes.label_prenom_1,
    label_prenom_2: page.data.attributes.label_prenom_2,
    label_prenom_3: page.data.attributes.label_prenom_3,
    label_nom_1: page.data.attributes.label_nom_1,
    label_nom_2: page.data.attributes.label_nom_2,
    label_nom_3: page.data.attributes.label_nom_3,
    label_identifiant: page.data.attributes.label_identifiant,
    label_identifiant_info_bulle_titre: page.data.attributes.label_identifiant_info_bulle_titre,
    label_identifiant_info_bulle_texte: page.data.attributes.label_identifiant_info_bulle_texte,
    label_email: page.data.attributes.label_email,
    label_email_info_bulle_titre: page.data.attributes.label_email_info_bulle_titre,
    label_email_info_bulle_texte: page.data.attributes.label_email_info_bulle_texte,
    erreur_champ_obligatoire: page.data.attributes.erreur_champ_obligatoire,
    erreur_champ_invalide: page.data.attributes.erreur_champ_invalide,
    erreur_taille_maximum: page.data.attributes.erreur_taille_maximum,
    bouton_envoi_formulaire: page.data.attributes.bouton_envoi_formulaire,
    label_pays_naissance: page.data.attributes.label_pays_naissance,
    label_nationalite: page.data.attributes.label_nationalite,
    label_date_naissance: page.data.attributes.label_date_naissance,
    label_annee: page.data.attributes.label_annee,
    label_mois: page.data.attributes.label_mois,
    label_jour: page.data.attributes.label_jour,
    sous_titre_champs: page.data.attributes.sous_titre_champs,
    bouton_ajouter_prenom: page.data.attributes.bouton_ajouter_prenom,
    bouton_ajouter_nom: page.data.attributes.bouton_ajouter_nom,
    erreur_date: page.data.attributes.erreur_date,
    erreur_email: page.data.attributes.erreur_email,
    erreur_longueur_numero_etranger: page.data.attributes.erreur_longueur_numero_etranger,
    resultat_compte_cree: page.data.attributes.resultat_compte_cree,
    resultat_compte_existant: page.data.attributes.resultat_compte_existant,
    resultat_erreur_generique: page.data.attributes.resultat_erreur_generique,
    titre_date_naissance: page.data.attributes.titre_date_naissance,
    titre_infobulle_identifiant: page.data.attributes.titre_infobulle_identifiant,
    infobulle_identifiant_image_tj_apres_2020:
      page.data.attributes.infobulle_identifiant_image_tj_apres_2020,
    infobulle_identifiant_image_tj_avant_2020:
      page.data.attributes.infobulle_identifiant_image_tj_avant_2020,
    infobulle_identifiant_image_tj_avant_2011:
      page.data.attributes.infobulle_identifiant_image_tj_avant_2011,
    infobulle_identifiant_image_visa: page.data.attributes.infobulle_identifiant_image_visa,
    infobulle_texte_identifiant_image_tj_avant_2011:
      page.data.attributes.infobulle_texte_identifiant_image_tj_avant_2011,
    infobulle_texte_identifiant_image_tj_avant_2020:
      page.data.attributes.infobulle_texte_identifiant_image_tj_avant_2020,
    infobulle_texte_identifiant_image_tj_apres_2020:
      page.data.attributes.infobulle_texte_identifiant_image_tj_apres_2020,
    infobulle_texte_identifiant_image_visa:
      page.data.attributes.infobulle_texte_identifiant_image_visa,
    infobulle_date_naissance: page.data.attributes.infobulle_date_naissance,
    infobulle_nationalite: page.data.attributes.infobulle_nationalite,
    infobulle_pays_naissance: page.data.attributes.infobulle_pays_naissance,
    infobulle_prenom: page.data.attributes.infobulle_prenom,
    infobulle_nom: page.data.attributes.infobulle_nom,
    vous_etes_nee_le: page.data.attributes.vous_etes_nee_le,
    titre_etape_1: page.data.attributes.titre_etape_1,
    titre_etape_2: page.data.attributes.titre_etape_2,
    format_attendu_prenom: page.data.attributes.format_attendu_prenom,
    format_attendu_nom: page.data.attributes.format_attendu_nom,
    format_attendu_identifiant: page.data.attributes.format_attendu_identifiant,
    format_attendu_date: page.data.attributes.format_attendu_date,
    format_attendu_email: page.data.attributes.format_attendu_email,
    titre_bulle_aide: page.data.attributes.titre_bulle_aide,
    contenu_bulle_aide: page.data.attributes.contenu_bulle_aide,
    txt_bouton_bulle_aide: page.data.attributes.txt_bouton_bulle_aide,
    bouton_precedent: page.data.attributes.bouton_precedent,
    bouton_suivant: page.data.attributes.bouton_suivant,
    sous_titre_etape_1: page.data.attributes.sous_titre_etape_1,
    sous_titre_sub_etape_1: page.data.attributes.sous_titre_sub_etape_1,
    sous_titre_etape_2: page.data.attributes.sous_titre_etape_2,
    infobulle_bulle_aide: page.data.attributes.infobulle_bulle_aide,
    erreur_dois_etre_majeur: page.data.attributes.erreur_dois_etre_majeur,
    erreur_date_futur: page.data.attributes.erreur_date_futur,
    bouton_retour_accueil: page.data.attributes.bouton_retour_accueil,
    bouton_retour_page_login: page.data.attributes.bouton_retour_page_login,
    message_success_sous_bulle_aide: page.data.attributes.message_success_sous_bulle_aide,
    bouton_infobulle_arialabel: page.data.attributes.bouton_infobulle_arialabel,
    titre_creation_success: page.data.attributes.titre_creation_success,
    titre_creation_error: page.data.attributes.titre_creation_error,
  };
};

export const formatMentionsPage = (data: MentionsCmsPage): MentionsPage => {
  return {
    id: 'mentions-legales',
    titre: data.attributes?.titre,
    description_page: data.attributes.description_page,
  };
};

export const formatRgpdPage = (data: RgpdCmsPage): RgpdPage => {
  return {
    id: 'rgpd',
    titre: data.attributes?.titre_page,
    description_page: data.attributes.description_page,
  };
};

export const formatCookiePage = (data: CookieCmsPage): CookiePage => {
  return {
    id: 'cookie',
    description_page: data.attributes.description_page_cookies,
    personnalisation_description: data.attributes.personnalisation_description,
    cookies_essentiels_titre: data.attributes.cookies_essentiels_titre,
    cookies_essentiels_description: data.attributes.cookies_essentiels_description,
    cookies_reseaux_sociaux_titre: data.attributes.cookies_reseaux_sociaux_titre,
    cookies_reseaux_sociaux_description: data.attributes.cookies_reseaux_sociaux_description,
    lien_cookie_matomo: data.attributes.lien_cookie_matomo,
    lien_cookie_facebook: data.attributes.lien_cookie_facebook,
    lien_cookie_twitter: data.attributes.lien_cookie_twitter,
    preference_tous_services: data.attributes.preference_tous_services,
    bouton_tout_accepter: data.attributes.bouton_tout_accepter,
    alt_bouton_tout_accepter: data.attributes.alt_bouton_tout_accepter,
    bouton_tout_refuser: data.attributes.bouton_tout_refuser,
    alt_bouton_tout_refuser: data.attributes.alt_bouton_tout_refuser,
    accepter: data.attributes.accepter,
    refuser: data.attributes.refuser,
    confirmer_choix: data.attributes.confirmer_choix,
    a_propos_des_cookies_sur: data.attributes.a_propos_des_cookies_sur,
    bouton_personnaliser: data.attributes.bouton_personnaliser,
    personnalisation_titre: data.attributes.personnalisation_titre,
    paneau_gestion_cookies: data.attributes.paneau_gestion_cookies,
    titre: data.attributes.titre,
    donnees_personnelles: data.attributes.donnees_personnelles,
    donnee_personnelles_et_cookies: data.attributes.donnee_personnelles_et_cookies,
    url_site: data.attributes.url_site,
  };
};

export const formatAccessibilitePage = (data: AccessibiliteCmsPage): AccessibilitePage => {
  return {
    id: 'accessibilite',
    titre: data.attributes?.titre,
    description_page: data.attributes.description_page,
  };
};

export const formatProcedurePage = (res: any): ProceduresPage => {
  const attributes = res.data.attributes;

  return {
    titre: '',
    actualites: null,
    integration: {
      titre: attributes.integration_france.titre,
      bloc_1: {
        titre: attributes.integration_france.bloc_1?.titre,
        description: attributes.integration_france.bloc_1?.description,
        lien: attributes.integration_france.bloc_1?.lien,
      },
      bloc_2: {
        titre: attributes.integration_france.bloc_2?.titre,
        description: attributes.integration_france.bloc_2?.description,
        lien: attributes.integration_france.bloc_2?.lien,
      },
    },
  };
};

export const formatAccountPage = (res: CompteCmsPage): AccountPage => {
  return {
    titre: '',
    contact_detail_labels: {
      titre: res.attributes.coordonnees_de_contact.titre,
      email: res.attributes.coordonnees_de_contact.email_label,
      phone: res.attributes.coordonnees_de_contact.telephone_label,
    },
    identity_labels: {
      titre: res.attributes.etat_civil.titre,
      identifier: res.attributes.etat_civil.identifiant_label,
      birthname: res.attributes.etat_civil.nom_naissance_label,
      firstname: res.attributes.etat_civil.prenom_label,
      lastname: res.attributes.etat_civil.nom_usage_label,
      gender: res.attributes.etat_civil.sexe_label,
      birthdate: res.attributes.etat_civil.date_naissance_label,
      birth_state: res.attributes.etat_civil.pays_naissance_label,
      nationality: res.attributes.etat_civil.nationalite_label,
      date_of_entry: res.attributes.etat_civil.date_entree_france_label,
      family_situation: res.attributes.etat_civil.situation_familiale_label,
    },
    password_change_label: {
      titre: res.attributes.modification_mot_de_passe.titre,
      button_text: res.attributes.modification_mot_de_passe.texte_bouton,
      description: res.attributes.modification_mot_de_passe.description,
    },
  };
};

export const formatDashboardPage = (res: DashboardCmsPage): DashboardPage => {
  return {
    titre: '',
    content_modale_confirmation_envoi_demande_rf:
      res.data.attributes.content_modale_confirmation_envoi_demande_rf,
    text_demande_soumise: res.data.attributes.text_demande_soumise,
    titre_modale_confirmation_envoi_demande_rf:
      res.data.attributes.titre_modale_confirmation_envoi_demande_rf,
    bouton_retour_modale: res.data.attributes.bouton_retour_modale,
    bouton_je_donne_mon_avis_alt: res.data.attributes.bouton_je_donne_mon_avis_alt,
    bouton_je_donne_mon_avis_lien: res.data.attributes.bouton_je_donne_mon_avis_lien,
    bouton_je_donne_mon_avis_lien_image: res.data.attributes.bouton_je_donne_mon_avis_lien_image,
    procedures_en_cours: {
      titre: res.data.attributes.procedures_en_cours.titre,
      texte_aucune_procedure: res.data.attributes.procedures_en_cours.texte_aucune_procedure,
      titre_en_cours: res.data.attributes.procedures_en_cours.titre_en_cours,
      titre_termines: res.data.attributes.procedures_en_cours.titre_termines,
    },
    procedures: {
      titre_bloc: res.data.attributes.procedures.titre_bloc,
      titre: res.data.attributes.procedures.titre,
      description: res.data.attributes.procedures.description,
      texte_lien: res.data.attributes.procedures.texte_lien,
      illustration: res.data.attributes.procedures_illustration.data.attributes.url,
      illustration_alt: res.data.attributes.procedures_illustration.data.attributes.alternativeText,
    },
    services_en_ligne: {
      titre_bloc: res.data.attributes.services_en_ligne.titre_bloc,
      titre: res.data.attributes.services_en_ligne.titre,
      description: res.data.attributes.services_en_ligne.description,
      texte_lien: res.data.attributes.services_en_ligne.texte_lien,
      illustration: res.data.attributes.services_illustration.data.attributes.url,
      illustration_alt: res.data.attributes.services_illustration.data.attributes.alternativeText,
    },
  };
};
