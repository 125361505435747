/* eslint-disable complexity */
import React, { FC, useEffect, useState } from 'react';
import Button from '@codegouvfr/react-dsfr/Button';

import TextInputContainer from 'components/Molecules/Forms/TextInputContainer/TextInputContainer';
import DateInputContainer from 'components/Molecules/Forms/DateInputContainer/DateInputContainer';
import Infobulle from 'components/Molecules/Infobulle/Infobulle';
import { AccountCreationPage } from 'models/types/app/pages';
import Loader from 'components/Atoms/Loader/Loader';
import {
  useGetUsagerListePaysQuery,
  useGetUsagerListeNationalitesQuery,
} from 'redux/rtk/api/apiCommon';
import AutocompleteContainer from 'components/Molecules/Forms/AutocompleteContainer/AutocompleteContainer';

interface FormulaireStep2Props {
  setStep: React.Dispatch<React.SetStateAction<number>>;
  control: any;
  getFieldState: any;
  accountCreationPage: AccountCreationPage;
  lang: string;
}

const FormulaireStep2: FC<FormulaireStep2Props> = ({
  setStep,
  control,
  getFieldState,
  accountCreationPage,
  lang,
}) => {
  const [prenomState, setPrenomState] = useState<number>(1);
  const [prenomLimit, setPrenomLimit] = useState<boolean>(false);

  const {
    data: listePays,
    isError: errorPays,
    isFetching: loadingPays,
  } = useGetUsagerListePaysQuery();

  const {
    data: listeNationalite,
    isError: errorNationalite,
    isFetching: loadingNationalite,
  } = useGetUsagerListeNationalitesQuery();

  const addPrenom = () => {
    if (prenomState < 3) {
      setPrenomState(prenomState + 1);
    }
  };

  const removePrenom = () => {
    if (prenomState > 1) {
      setPrenomState(prenomState - 1);
    }
  };

  useEffect(() => {
    prenomState === 3 ? setPrenomLimit(true) : setPrenomLimit(false);
  }, [prenomState]);

  if (loadingPays || loadingNationalite) {
    return <Loader />;
  }

  if (!accountCreationPage || errorPays || errorNationalite) {
    return <div>Redirect page error...</div>;
  }

  return (
    <>
      <h5>{accountCreationPage.sous_titre_etape_2}</h5>
      <p className="fr-text--xs fr-mt-6v">{accountCreationPage.disclaimer}</p>
      <TextInputContainer
        name="nom_naissance"
        control={control}
        label={
          <span className="label-infobulle">
            {accountCreationPage.label_nom_1}
            <Infobulle
              cmsContent={accountCreationPage.infobulle_nom}
              infobulleName={accountCreationPage.label_nom_1}
              lang={lang}
              btnTitle={accountCreationPage.bouton_infobulle_arialabel}
            />
          </span>
        }
        hintText={accountCreationPage.format_attendu_nom}
        customClass="fr-col-12 fr-mt-4v"
        stateRelatedMessage={
          getFieldState('nom_naissance')?.error?.message ??
          accountCreationPage.erreur_champ_invalide
        }
      />
      <TextInputContainer
        name="prenom"
        control={control}
        label={
          <span className="label-infobulle">
            {accountCreationPage.label_prenom_1}
            <Infobulle
              cmsContent={accountCreationPage.infobulle_prenom}
              infobulleName={accountCreationPage.label_prenom_1}
              lang={lang}
              btnTitle={accountCreationPage.bouton_infobulle_arialabel}
            />
          </span>
        }
        hintText={accountCreationPage.format_attendu_prenom}
        customClass="fr-col-12 fr-mt-4v"
        stateRelatedMessage={getFieldState('prenom')?.error?.message ?? 'Default error'}
      />

      <div className={`${prenomState >= 2 ? 'prenom-supp-field' : 'hidden'}`}>
        <div className="fr-container--fluid fr-col-12">
          <div className="fr-grid-row new-name-with-bin">
            <TextInputContainer
              name="prenom2"
              control={control}
              label={accountCreationPage.label_prenom_2}
              hintText={accountCreationPage.format_attendu_prenom}
              customClass="fr-mt-4v new-name"
            />
            <div className="btn-bin">
              <button
                className="fr-btn fr-icon-delete-line fr-btn--tertiary"
                data-fr-js-button-actionee="true"
                onClick={() => {
                  removePrenom();
                }}
                type="button"
              >
                {' '}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className={`${prenomLimit ? 'prenom-supp-field' : 'hidden'}`}>
        <div className="fr-container--fluid fr-col-12">
          <div className="fr-grid-row new-name-with-bin">
            <TextInputContainer
              name="prenom3"
              control={control}
              label={accountCreationPage.label_prenom_3}
              hintText={accountCreationPage.format_attendu_prenom}
              customClass="fr-mt-4v new-name"
            />
            <div className="btn-bin">
              <button
                className="fr-btn fr-icon-delete-line fr-btn--tertiary"
                data-fr-js-button-actionee="true"
                onClick={() => {
                  removePrenom();
                }}
                type="button"
              >
                {' '}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className={`${prenomLimit ? 'hidden' : 'fr-col-12 fr-mt-4v'}`}>
        <Button
          className="btn-ajout-prenom"
          iconId="fr-icon-add-line"
          iconPosition="left"
          type="button"
          onClick={() => {
            addPrenom();
          }}
          priority="tertiary"
        >
          {accountCreationPage.bouton_ajouter_prenom}
        </Button>
      </div>

      <DateInputContainer
        name="date_naissance"
        label={
          <span className="label-infobulle">
            {accountCreationPage.label_date_naissance}
            <Infobulle
              cmsContent={accountCreationPage.infobulle_date_naissance}
              infobulleName={accountCreationPage.label_date_naissance}
              lang={lang}
              btnTitle={accountCreationPage.bouton_infobulle_arialabel}
            />
          </span>
        }
        hintText={accountCreationPage.format_attendu_date}
        control={control}
        customClass="fr-col-12 fr-col-lg-7 fr-col-md-7"
        stateRelatedMessage={
          getFieldState('date_naissance')?.error?.message ??
          accountCreationPage.erreur_champ_invalide
        }
      />

      <div className="fr-col-12">
        <AutocompleteContainer
          name="pays_naissance"
          control={control}
          label={accountCreationPage.label_pays_naissance}
          stateRelatedMessage={
            getFieldState('pays_naissance')?.error?.message ??
            accountCreationPage.erreur_champ_invalide
          }
          customClass="fr-col-12 fr-mt-4v"
          payload={listePays}
          lang={lang}
          source="API"
        />
      </div>

      <div className="fr-col-12">
        <AutocompleteContainer
          name="nationalite"
          control={control}
          label={
            <span className="label-infobulle">
              {accountCreationPage.label_nationalite}
              <Infobulle
                cmsContent={accountCreationPage.infobulle_nationalite}
                infobulleName={accountCreationPage.label_nationalite}
                lang={lang}
                btnTitle={accountCreationPage.bouton_infobulle_arialabel}
              />
            </span>
          }
          stateRelatedMessage={
            getFieldState('nationalite')?.error?.message ??
            accountCreationPage.erreur_champ_invalide
          }
          customClass="fr-col-12 fr-mt-4v"
          payload={listeNationalite}
          lang={lang}
          source="API"
        />
      </div>

      <TextInputContainer
        name="adresse_mail"
        control={control}
        label={accountCreationPage.label_email}
        hintText={accountCreationPage.erreur_email ?? undefined}
        customClass="fr-col-12 fr-mt-4v"
        stateRelatedMessage={
          getFieldState('adresse_mail')?.error?.message ?? accountCreationPage.erreur_champ_invalide
        }
        emailSettings={true}
      />
      <div className="fr-mt-6v controls-buttons">
        <Button
          className="mobile-large-button btn-precedent"
          iconId="fr-icon-arrow-left-line"
          iconPosition="left"
          type="button"
          onClick={() => {
            setStep(1);
          }}
          priority="secondary"
        >
          {accountCreationPage.bouton_precedent}
        </Button>
        <Button
          className="mobile-large-button next-button"
          iconId="fr-icon-check-line"
          iconPosition="right"
          type="submit"
          priority="primary"
        >
          {accountCreationPage.bouton_envoi_formulaire}
        </Button>
      </div>
    </>
  );
};
export default FormulaireStep2;
