import * as yup from 'yup';
import { FormHelper } from 'helpers/app/FormHelper';

import { DemandeRfBloc4, DemandeRfErrors } from 'models/types/cms/demandeRF/type';
//import { Ressource } from 'models/types/api/rf';

export interface Ressource {
  id?: string | null;
  uuid?: string | null;
  activite: string | null;
  estActuel?: boolean;
  //documents?: Document[];
  ress_artisa_bilan_comptable_resultat_attestation_revenu_files?: Document[];
  ress_artisa_extrait_inscription_metiers_files?: Document[];
  ress_autent_declaration_activite_cfe_files?: Document[];
  ress_autent_livre_recettes_attestation_revenu_files?: Document[];
  ress_commer_bilan_comptable_resultat_attestation_revenu_files?: Document[];
  ress_commer_extrait_inscription_rcs_files?: Document[];
  ress_dememp_releve_situation_droits_versements_pe_files?: Document[];
  ress_invali_attribution_pension_retraite_invalidite_files?: Document[];
  ress_invali_avis_versement_retraite_invalide_files?: Document[];
  ress_prolib_bilan_comptable_resultat_attestation_revenu_files?: Document[];
  ress_prolib_extrait_inscription_siren_files?: Document[];
  ress_retrai_attribution_pension_retraite_invalidite_files?: Document[];
  ress_retrai_avis_versement_retraite_invalide_files?: Document[];
  ress_salcdd_bulletin_salaire_files?: Document[];
  ress_salcdd_certificat_travail_files?: Document[];
  ress_salcdd_contrat_attestation_travail_files?: Document[];
  ress_salcdi_bulletin_salaire_files?: Document[];
  ress_salcdi_certificat_travail_files?: Document[];
  ress_salcdi_contrat_attestation_travail_files?: Document[];
  ress_salint_certificat_travail_files?: Document[];
  ress_salint_contrat_attestation_travail_files?: Document[];
  isNew?: string | null;
  sort?: number | null;
}

export const getResourcesValidationSchema = (
  errorsRfCms: DemandeRfErrors,
  bloc4Cms: DemandeRfBloc4,
) => {
  const formHelper = new FormHelper(errorsRfCms);

  return yup
    .object({
      activities: yup.array<Ressource[]>(
        yup.object({
          activite: formHelper.stringSchema(true, bloc4Cms.situation_professionnelle),
          estActuel: formHelper.requiredBoolSchema(),
          isNew: yup.string().notRequired(),
          ress_salcdi_contrat_attestation_travail_files: yup
            .mixed()
            .when(['activite', 'estActuel'], ([activite, estActuel]) => {
              if (activite === 'activite_salarie_en_cdi' && estActuel) {
                return formHelper.requiredFileSchema();
              }

              return formHelper.notRequiredFileSchema();
              // is: (value: string, estActuel: boolean) =>
              //   value && value === 'activite_salarie_en_cdi' && estActuel,
              // then: FileSchemaValidation(cmsContentUtilitaire, intl),
            }),
          ress_salcdi_bulletin_salaire_files: yup.mixed().when('activite', ([activite]) => {
            if (activite === 'activite_salarie_en_cdi') {
              return formHelper.requiredFileSchema();
            }

            return formHelper.notRequiredFileSchema();
            // is: (value: string) => value && value === 'activite_salarie_en_cdi',
            // then: FileSchemaValidation(cmsContentUtilitaire, intl),
          }),
          ress_salcdi_certificat_travail_files: yup
            .mixed()
            .when(['activite', 'estActuel'], ([activite, estActuel]) => {
              if (activite === 'activite_salarie_en_cdi' && estActuel) {
                return formHelper.requiredFileSchema();
              }

              return formHelper.notRequiredFileSchema();
              // is: (value: string, estActuel: boolean) =>
              //   value && value === 'activite_salarie_en_cdi' && estActuel,
              // then: FileSchemaValidation(cmsContentUtilitaire, intl),
            }),

          ress_salint_contrat_attestation_travail_files: yup
            .mixed()
            .when('activite', ([activite]) => {
              if (activite === 'activite_salarie_en_interim') {
                return formHelper.requiredFileSchema();
              }

              return formHelper.notRequiredFileSchema();
              // is: (value: string) => value && value === 'activite_salarie_en_interim',
              // then: FileSchemaValidation(cmsContentUtilitaire, intl),
            }),
          ress_salint_certificat_travail_files: yup.mixed().when('activite', ([activite]) => {
            if (activite === 'activite_salarie_en_interim') {
              return formHelper.requiredFileSchema();
            }

            return formHelper.notRequiredFileSchema();
            // is: (value: string) => value && value === 'activite_salarie_en_interim',
            // then: FileSchemaValidation(cmsContentUtilitaire, intl),
          }),

          ress_salcdd_contrat_attestation_travail_files: yup
            .mixed()
            .when(['activite', 'estActuel'], ([activite, estActuel]) => {
              if (activite === 'activite_salarie_en_cdd' && estActuel) {
                return formHelper.requiredFileSchema();
              }

              return formHelper.notRequiredFileSchema();
              // is: (value: string, estActuel: boolean) =>
              //   value && value === 'activite_salarie_en_cdd' && estActuel,
              // then: FileSchemaValidation(cmsContentUtilitaire, intl),
            }),
          ress_salcdd_certificat_travail_files: yup
            .mixed()
            .when(['activite', 'estActuel'], ([activite, estActuel]) => {
              if (activite === 'activite_salarie_en_cdd' && estActuel) {
                return formHelper.requiredFileSchema();
              }

              return formHelper.notRequiredFileSchema();
              // is: (value: string, estActuel: boolean) =>
              //   value && value === 'activite_salarie_en_cdd' && estActuel,
              // then: FileSchemaValidation(cmsContentUtilitaire, intl),
            }),
          ress_salcdd_bulletin_salaire_files: yup.mixed().when('activite', ([activite]) => {
            if (activite === 'activite_salarie_en_cdd') {
              return formHelper.requiredFileSchema();
            }

            return formHelper.notRequiredFileSchema();
            // is: (value: string) => value && value === 'activite_salarie_en_cdd',
            // then: FileSchemaValidation(cmsContentUtilitaire, intl),
          }),

          ress_artisa_extrait_inscription_metiers_files: yup
            .mixed()
            .when('activite', ([activite]) => {
              if (activite === 'activite_artisan') {
                return formHelper.requiredFileSchema();
              }

              return formHelper.notRequiredFileSchema();
              // is: (value: string) => value && value === 'activite_artisan',
              // then: FileSchemaValidation(cmsContentUtilitaire, intl),
            }),
          ress_artisa_bilan_comptable_resultat_attestation_revenu_files: yup
            .mixed()
            .when('activite', ([activite]) => {
              if (activite === 'activite_artisan') {
                return formHelper.requiredFileSchema();
              }

              return formHelper.notRequiredFileSchema();
              // is: (value: string) => value && value === 'activite_artisan',
              // then: FileSchemaValidation(cmsContentUtilitaire, intl),
            }),

          ress_dememp_releve_situation_droits_versements_pe_files: yup
            .mixed()
            .when('activite', ([activite]) => {
              if (activite === 'activite_demandeur_d_emploi') {
                return formHelper.requiredFileSchema();
              }

              return formHelper.notRequiredFileSchema();
              // is: (value: string) => value && value === 'activite_demandeur_d_emploi',
              // then: FileSchemaValidation(cmsContentUtilitaire, intl),
            }),
          ress_prolib_extrait_inscription_siren_files: yup
            .mixed()
            .when('activite', ([activite]) => {
              if (activite === 'activite_profession_liberale') {
                return formHelper.requiredFileSchema();
              }

              return formHelper.notRequiredFileSchema();
              // is: (value: string) => value && value === 'activite_profession_liberale',
              // then: FileSchemaValidation(cmsContentUtilitaire, intl),
            }),
          ress_prolib_bilan_comptable_resultat_attestation_revenu_files: yup
            .mixed()
            .when('activite', ([activite]) => {
              if (activite === 'activite_profession_liberale') {
                return formHelper.requiredFileSchema();
              }

              return formHelper.notRequiredFileSchema();
              // is: (value: string) => value && value === 'activite_profession_liberale',
              // then: FileSchemaValidation(cmsContentUtilitaire, intl),
            }),

          ress_commer_extrait_inscription_rcs_files: yup.mixed().when('activite', ([activite]) => {
            if (activite === 'activite_commercant') {
              return formHelper.requiredFileSchema();
            }

            return formHelper.notRequiredFileSchema();
            // is: (value: string) => value && value === 'activite_commercant',
            // then: FileSchemaValidation(cmsContentUtilitaire, intl),
          }),
          ress_commer_bilan_comptable_resultat_attestation_revenu_files: yup
            .mixed()
            .when('activite', ([activite]) => {
              if (activite === 'activite_commercant') {
                return formHelper.requiredFileSchema();
              }

              return formHelper.notRequiredFileSchema();
              // is: (value: string) => value && value === 'activite_commercant',
              // then: FileSchemaValidation(cmsContentUtilitaire, intl),
            }),

          ress_autent_declaration_activite_cfe_files: yup.mixed().when('activite', ([activite]) => {
            if (activite === 'activite_auto_entrepreneur') {
              return formHelper.requiredFileSchema();
            }

            return formHelper.notRequiredFileSchema();
            // is: (value: string) => value && value === 'activite_auto_entrepreneur',
            // then: FileSchemaValidation(cmsContentUtilitaire, intl),
          }),
          ress_autent_livre_recettes_attestation_revenu_files: yup
            .mixed()
            .when('activite', ([activite]) => {
              if (activite === 'activite_auto_entrepreneur') {
                return formHelper.requiredFileSchema();
              }

              return formHelper.notRequiredFileSchema();
              // is: (value: string) => value && value === 'activite_auto_entrepreneur',
              // then: FileSchemaValidation(cmsContentUtilitaire, intl),
            }),

          ress_invali_attribution_pension_retraite_invalidite_files: yup
            .mixed()
            .when('activite', ([activite]) => {
              if (activite === 'activite_invalide') {
                return formHelper.requiredFileSchema();
              }

              return formHelper.notRequiredFileSchema();
              // is: (value: string) => value && value === 'activite_invalide',
              // then: FileSchemaValidation(cmsContentUtilitaire, intl),
            }),
          ress_invali_avis_versement_retraite_invalide_files: yup
            .mixed()
            .when('activite', ([activite]) => {
              if (activite === 'activite_invalide') {
                return formHelper.requiredFileSchema();
              }

              return formHelper.notRequiredFileSchema();
              // is: (value: string) => value && value === 'activite_invalide',
              // then: FileSchemaValidation(cmsContentUtilitaire, intl),
            }),

          ress_retrai_attribution_pension_retraite_invalidite_files: yup
            .mixed()
            .when('activite', ([activite]) => {
              if (activite === 'activite_retraite') {
                return formHelper.requiredFileSchema();
              }

              return formHelper.notRequiredFileSchema();
              // is: (value: string) => value && value === 'activite_retraite',
              // then: FileSchemaValidation(cmsContentUtilitaire, intl),
            }),
          ress_retrai_avis_versement_retraite_invalide_files: yup
            .mixed()
            .when('activite', ([activite]) => {
              if (activite === 'activite_retraite') {
                return formHelper.requiredFileSchema();
              }

              return formHelper.notRequiredFileSchema();
              // is: (value: string) => value && value === 'activite_retraite',
              // then: FileSchemaValidation(cmsContentUtilitaire, intl),
            }),
        }),
      ),
      arret_travail_12_dernier_mois: formHelper.stringSchema(
        true,
        bloc4Cms.arret_travail_annee_passee,
      ),
      autres_ressources: formHelper.stringSchema(true, bloc4Cms.autres_ressources),
      beneficiaire_aah_ou_asi: formHelper.stringSchema(true, bloc4Cms.titre_beneficiaire_aah_asi),
      declarer_conjoint_ressources: formHelper.stringSchema(
        true,
        bloc4Cms.declarer_conjoint_ressources,
      ),
      nouvelle_activite: formHelper.stringSchema(true, bloc4Cms.nouvelle_activite),
      pension_alimentaire: yup.string().when('autres_ressources', ([autresRessources]) => {
        return autresRessources === 'oui'
          ? formHelper.stringSchema(true, bloc4Cms.pension_alimentaire)
          : formHelper.stringSchema(false);
      }),
      ress_dernier_avis_imposition_files: formHelper.requiredFileSchema(),
      travail_dans_btp: formHelper.stringSchema(true, bloc4Cms.travail_dans_btp),
      ress_justificatif_vers_presta_social_algerien_files: yup
        .array()
        .when('autres_ressources', ([autresRessources]) => {
          if (autresRessources === 'oui') {
            return formHelper.requiredFileSchema();
          }
          return formHelper.notRequiredFileSchema();
        }),
      ress_btp_justificatif_conges_payes_files: yup
        .array()
        .when('travail_dans_btp', ([travailDansBtp]) => {
          return travailDansBtp === 'oui'
            ? formHelper.requiredFileSchema()
            : formHelper.notRequiredFileSchema();
        }),
      ress_secu_social_indemnite_jour_files: yup
        .array()
        .when('arret_travail_12_dernier_mois', ([arretTravail12DernierMois]) => {
          if (arretTravail12DernierMois === 'oui') {
            return formHelper.requiredFileSchema();
          }
          return formHelper.notRequiredFileSchema();
        }),
      ress_decision_attribution_aah_asi_files: yup
        .array()
        .when('beneficiaire_aah_ou_asi', ([beneficiaireAahOuAsi]) => {
          if (beneficiaireAahOuAsi === 'oui') {
            return formHelper.requiredFileSchema();
          }
          return formHelper.notRequiredFileSchema();
        }),
      ress_attestation_paiement_aah_asi_files: yup
        .array()
        .when('beneficiaire_aah_ou_asi', ([beneficiaireAahOuAsi]) => {
          if (beneficiaireAahOuAsi === 'oui') {
            return formHelper.requiredFileSchema();
          }
          return formHelper.notRequiredFileSchema();
        }),
      ress_autre_document_origine_revenu_files: yup
        .array()
        .when('autres_ressources', ([autresRessources]) => {
          if (autresRessources === 'oui') {
            return formHelper.requiredFileSchema();
          }
          return formHelper.notRequiredFileSchema();
        }),
      ress_pension_alimentaires_ou_decision_justice_files: yup
        .array()
        .when('pension_alimentaire', ([pensionAlimentaire]) => {
          if (pensionAlimentaire === 'oui') {
            return formHelper.requiredFileSchema();
          }
          return formHelper.notRequiredFileSchema();
        }),
      ress_releve_compte_origine_revenus_files: yup
        .array()
        .when('declarer_conjoint_ressources', ([declarerConjointRessources]) => {
          if (declarerConjointRessources === 'oui') {
            return formHelper.requiredFileSchema();
          }
          return formHelper.notRequiredFileSchema();
        }),
      validation: yup.string().notRequired(),
      //[HONEYPOT_NAME]: yup.string(),
    })
    .defined();
};
