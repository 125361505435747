/* eslint-disable max-params */
import * as yup from 'yup';

import { FormHelper } from 'helpers/app/FormHelper';
import { DemandeRfBloc1, DemandeRfGeneral, DemandeRfErrors } from 'models/types/cms/demandeRF/type';
import { HONEYPOT_NAME } from 'constants/formulaire';
import { isDateInThePast, isPossibleDate } from 'helpers/app/ComponentsFormHelpers';
import { getAgeisMinor } from 'pages/DemandeRF/Helpers/common';

export const getWhoAreYouValidationSchema = (
  bloc1Cms: DemandeRfBloc1,
  globalCms: DemandeRfGeneral,
  errorsRfCms: DemandeRfErrors,
  forbiddenNationalites: string[],
) => {
  const formHelper = new FormHelper(errorsRfCms);
  return yup.object().shape({
    gender: formHelper.stringSchema(true, bloc1Cms.vous_etes),
    nom_naissance: formHelper.stringSchema(true, bloc1Cms.nom_naissance.titre),
    nom_usage: yup.string().nullable().notRequired(),
    prenom: formHelper.stringSchema(true, bloc1Cms.prenom.titre),
    date_naissance: formHelper
      .stringSchema(true)
      .test('dateMustBeInPast', bloc1Cms.erreur_date_naissance_passe, value => {
        return value && isDateInThePast(value) ? true : false;
      })
      .test('mustBeMajor', errorsRfCms.non_eligible_rf, value => {
        return value && !getAgeisMinor(value) ? true : false;
      }),
    pays_naissance: formHelper.stringSchema(true, bloc1Cms.pays_naissance.titre),
    ville_naissance: formHelper.stringSchema(true, bloc1Cms.ville_naissance.titre),
    nationalite: formHelper
      .stringSchema(true, bloc1Cms.nationalite.titre)
      .notOneOf(forbiddenNationalites, errorsRfCms.non_eligible_rf),
    acte_naissance_traduits_files: yup
      .array()
      .when(['french_birth_certificate'], ([french_birth_certificate]) => {
        return french_birth_certificate
          ? formHelper.requiredFileSchema()
          : formHelper.notRequiredFileSchema();
      }),
    french_birth_certificate: formHelper.requiredBoolSchema(bloc1Cms.acte_naissance_francais),
    french_mariage_certificate: yup
      .boolean()
      .when(
        ['situation_matrimoniale', 'place_of_mariage'],
        ([situation_matrimoniale, place_of_mariage]) => {
          return situation_matrimoniale === 'marie' && place_of_mariage === 'etranger'
            ? formHelper.requiredBoolSchema(bloc1Cms.acte_mariage_francais)
            : yup.boolean().nullable().notRequired();
        },
      ),
    acte_naissance_files: formHelper.requiredFileSchema(),
    nature_titre_sejour: formHelper.stringSchema(true, bloc1Cms.preciser_nature_titre_sejour),
    mention_titre_sejour: formHelper.stringSchema(true, globalCms.mention_titre_sejour),
    situation_matrimoniale: formHelper.stringSchema(true, bloc1Cms.situation_familiale),
    place_of_mariage: formHelper.conditionalStringSchema(
      'situation_matrimoniale',
      'marie',
      bloc1Cms.vous_vous_etes_marie,
    ),
    date_start_of_pacs: yup
      .string()
      .when(['situation_matrimoniale'], ([situation_matrimoniale]) => {
        return situation_matrimoniale === 'pacse'
          ? formHelper
              .stringSchema(true, errorsRfCms.erreur_date_generique)
              .test('dateMustBeInPast', errorsRfCms.erreur_date_generique, value => {
                return value && isDateInThePast(value) ? true : false;
              })
              .test('bothConditionsTrue', errorsRfCms.erreur_date_generique, (value, context) => {
                const isSituationMarie = context.parent.situation_matrimoniale === 'pacse';
                const isDateInPast = value && isDateInThePast(value) ? true : false;
                return isSituationMarie && isDateInPast;
              })
          : yup.string().notRequired();
      }),
    date_start_of_concubinage: yup
      .string()
      .when(['situation_matrimoniale'], ([situation_matrimoniale]) => {
        return situation_matrimoniale === 'concubin'
          ? formHelper
              .stringSchema(true, errorsRfCms.erreur_date_generique)
              .test('dateMustBeInPast', errorsRfCms.erreur_date_generique, value => {
                return value && isDateInThePast(value) ? true : false;
              })
              .test('bothConditionsTrue', errorsRfCms.erreur_date_generique, (value, context) => {
                const isSituationMarie = context.parent.situation_matrimoniale === 'concubin';
                const isDateInPast = value && isDateInThePast(value) ? true : false;
                return isSituationMarie && isDateInPast;
              })
          : yup.string().notRequired();
      }),
    date_start_of_mariage: yup
      .string()
      .when(['situation_matrimoniale'], ([situation_matrimoniale]) => {
        return situation_matrimoniale === 'marie'
          ? formHelper
              .stringSchema(true, errorsRfCms.erreur_date_generique)
              .test('dateMustBeInPast', errorsRfCms.erreur_date_generique, value => {
                return value && isDateInThePast(value) ? true : false;
              })
              .test('bothConditionsTrue', errorsRfCms.erreur_date_generique, (value, context) => {
                const isSituationMarie = context.parent.situation_matrimoniale === 'marie';
                const isDateInPast = value && isDateInThePast(value) ? true : false;
                return isSituationMarie && isDateInPast;
              })
          : yup.string().notRequired();
      }),
    date_start_of_divorce: yup
      .string()
      .when(['situation_matrimoniale'], ([situation_matrimoniale]) => {
        return situation_matrimoniale === 'divorce'
          ? formHelper
              .stringSchema(true, errorsRfCms.erreur_date_generique)
              .test('dateMustBeInPast', errorsRfCms.erreur_date_generique, value => {
                return value && isDateInThePast(value) ? true : false;
              })
              .test('bothConditionsTrue', errorsRfCms.erreur_date_generique, (value, context) => {
                const isSituationMarie = context.parent.situation_matrimoniale === 'divorce';
                const isDateInPast = value && isDateInThePast(value) ? true : false;
                return isSituationMarie && isDateInPast;
              })
          : yup.string().notRequired();
      }),
    autre_nature_titre_sejour: formHelper.conditionalStringSchema('nature_titre_sejour', 'autre'),
    autre_mention_titre_sejour: formHelper.conditionalStringSchema('mention_titre_sejour', 'autre'),
    date_expiration_titre_sejour: formHelper
      .stringSchema(true)
      .test('isPossibleDate', errorsRfCms.erreur_date_annee, value => {
        return value && isPossibleDate(value, '2000') ? true : false;
      }),
    ongoing_residence_permit_demand: formHelper
      .stringSchema(false)
      .when(['date_expiration_titre_sejour'], ([date_expiration_titre_sejour]) => {
        return isDateInThePast(date_expiration_titre_sejour)
          ? formHelper.stringSchema(true, bloc1Cms.demande_renouvellement_titre_sejour_faite)
          : formHelper.stringSchema(false);
      }),
    titre_sejour_files: formHelper.requiredFileSchema(),
    recepisse_files: yup
      .array()
      .when(['ongoing_residence_permit_demand'], ([ongoing_residence_permit_demand]) => {
        return ongoing_residence_permit_demand === 'oui'
          ? formHelper.requiredFileSchema()
          : formHelper.notRequiredFileSchema();
      }),
    acte_mariage_files: yup.array().when(['situation_matrimoniale'], ([situation_matrimoniale]) => {
      return situation_matrimoniale === 'marie'
        ? formHelper.requiredFileSchema()
        : formHelper.notRequiredFileSchema();
    }),
    acte_mariage_traduits_files: yup
      .array()
      .when(
        ['situation_matrimoniale', 'place_of_mariage', 'french_mariage_certificate'],
        ([situation_matrimoniale, place_of_mariage, french_mariage_certificate]) => {
          return situation_matrimoniale === 'marie' &&
            place_of_mariage === 'etranger' &&
            french_mariage_certificate
            ? formHelper.requiredFileSchema()
            : formHelper.notRequiredFileSchema();
        },
      ),
    acte_deces_conjoint_files: yup
      .array()
      .when(['situation_matrimoniale'], ([situation_matrimoniale]) => {
        return situation_matrimoniale === 'veuf'
          ? formHelper.requiredFileSchema()
          : formHelper.notRequiredFileSchema();
      }),
    acte_deces_traduits_files: yup
      .array()
      .when(
        ['situation_matrimoniale', 'french_death_certificate'],
        ([situation_matrimoniale, french_death_certificate]) => {
          return situation_matrimoniale === 'veuf' && french_death_certificate
            ? formHelper.requiredFileSchema()
            : formHelper.notRequiredFileSchema();
        },
      ),
    french_death_certificate: yup
      .boolean()
      .when(
        ['situation_matrimoniale', 'place_of_mariage'],
        ([situation_matrimoniale, place_of_mariage]) => {
          return situation_matrimoniale === 'veuf' && place_of_mariage === 'etranger'
            ? formHelper.requiredBoolSchema()
            : yup.boolean().nullable().notRequired();
        },
      ),
    convention_pacs_files: yup
      .array()
      .when(['situation_matrimoniale'], ([situation_matrimoniale]) => {
        return situation_matrimoniale === 'pacse'
          ? formHelper.requiredFileSchema()
          : formHelper.notRequiredFileSchema();
      }),
    french_divorce_papers: yup
      .boolean()
      .when(
        ['situation_matrimoniale', 'place_of_mariage'],
        ([situation_matrimoniale, place_of_mariage]) => {
          return situation_matrimoniale === 'divorce' && place_of_mariage === 'etranger'
            ? formHelper.requiredBoolSchema()
            : yup.boolean().nullable().notRequired();
        },
      ),
    divorce_ruling_files: yup
      .array()
      .when(['situation_matrimoniale'], ([situation_matrimoniale]) => {
        return situation_matrimoniale === 'divorce'
          ? formHelper.requiredFileSchema()
          : formHelper.notRequiredFileSchema();
      }),
    divorce_ruling_traduit_files: yup
      .array()
      .when(
        ['situation_matrimoniale', 'french_divorce_papers'],
        ([situation_matrimoniale, french_divorce_papers]) => {
          return situation_matrimoniale === 'divorce' && french_divorce_papers
            ? formHelper.requiredFileSchema()
            : formHelper.notRequiredFileSchema();
        },
      ),
    [HONEYPOT_NAME]: yup.string(),
  });
};
