import { RegisteredLocales } from 'constants/locales';

export const loadLocaleFromStorage = (): RegisteredLocales | null => {
  const storedData = localStorage.getItem('persist:currentLocale');
  try {
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      return parsedData.currentLocale || RegisteredLocales.FR;
    }
  } catch (error) {
    console.error('Failed to parse locale from storage:', error);
  }
  return RegisteredLocales.FR;
};

export const saveLocaleToStorage = (locale: string): void => {
  const dataToStore = JSON.stringify({ currentLocale: locale });
  localStorage.setItem('persist:currentLocale', dataToStore);
};
