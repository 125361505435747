import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import { getCurrentLocale } from 'redux/locale/selectors';
import Loader from 'components/Atoms/Loader/Loader';
import { setRgpdPage } from 'redux/pages';
import { useGetCookieCmsPageQuery } from 'redux/rtk/cms/cmsCookie';
import { cookieQuery } from 'pages/Home/Home.query';
import { getCookiePage } from 'redux/pages/selectors';

import CmsPage from 'pages/CmsPage/CmsPage';

const Cookie: FC = () => {
  const lang = useSelector(getCurrentLocale) as string;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cookieStore = useSelector(getCookiePage);

  const {
    data: cookieCms,
    error: cookieCmsError,
    isLoading: isCookieCmsLoading,
  } = useGetCookieCmsPageQuery({
    qs: cookieQuery,
    locale: lang !== '' ? lang : 'fr',
  });

  useEffect(() => {
    if (!isCookieCmsLoading && !cookieCmsError) {
      dispatch(setRgpdPage(cookieCms.data));
    }
  }, [cookieCms, cookieCmsError, isCookieCmsLoading, dispatch]);

  if (isCookieCmsLoading) {
    return <Loader />;
  }

  if (cookieCmsError) {
    navigate('/erreur');
  }

  return <CmsPage type="cookie" store={cookieStore} lang={lang} />;
};

export default Cookie;
