/* eslint-disable no-unused-vars */
import { createConsentManagement } from '@codegouvfr/react-dsfr/consentManagement';
import { COOKIE } from 'routes/path';

import { ConsentCookieList } from 'components/Molecules/ConsentManagement/ConsentCookieList';

export const {
  ConsentBannerAndConsentManagement,
  FooterConsentManagementItem,
  FooterPersonalDataPolicyItem,
  useConsent,
} = createConsentManagement({
  finalityDescription: ({ lang }): any => ConsentCookieList(lang),
  personalDataPolicyLinkProps: {
    href: COOKIE,
  },
  consentCallback: async ({ finalityConsent, finalityConsent_prev }) => {
    return;
  },
});
