import { FC } from 'react';

import ThreeSlotContainer from 'components/Templates/PartContainer/PartContainer';
import ThreeSlotElement from 'components/Templates/ThreeSlotElement/ThreeSlotElement';
import Tile from '@codegouvfr/react-dsfr/Tile';

interface TilesContainerProps {
  className: string;
  title: string;
  description?: string;
  border?: boolean;
  tiles: {
    key: string;
    title: string;
    description: string;
    image: {
      src: string;
      alt: string;
    };
    link: {
      href: string;
      title?: string;
    };
  }[];
}

const TilesContainer: FC<TilesContainerProps> = ({ className, title, description, tiles }) => {
  return (
    <ThreeSlotContainer
      title={title}
      description={description}
      className={`${className} tile home-tile`}
    >
      {tiles.map(tile => {
        return (
          <ThreeSlotElement key={tile.key} keyName={tile.key} className="tile">
            <Tile
              className={'home-service-tile ' + className}
              desc={tile.description}
              linkProps={{}}
              title={
                <div className="tile-img-title">
                  <img
                    className="fr-responsive-img"
                    src={tile.image.src}
                    aria-hidden={true}
                    alt={tile.image.alt}
                  />
                  <span>{tile.title}</span>
                </div>
              }
              enlargeLink={false}
            />
          </ThreeSlotElement>
        );
      })}
    </ThreeSlotContainer>
  );
};

export default TilesContainer;
