/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getMaintenanceState } from 'redux/maintenance/selectors';
import { setMaintenance } from 'redux/maintenance';
import { useGetMaintenanceCmsPageQuery } from 'redux/rtk/cms/cmsMaintenance';
import { maintenanceQuery } from 'pages/Home/Home.query';

const useFetchMaintenance = (lang: string) => {
  const dispatch = useDispatch();
  const maintenanceState = useSelector(getMaintenanceState);

  const { data: cms } = maintenanceQuery
    ? useGetMaintenanceCmsPageQuery({
        qs: maintenanceQuery,
        locale: lang !== '' ? lang : 'fr',
      })
    : { data: null };

  useEffect(() => {
    dispatch(setMaintenance(maintenanceState));
  }, [dispatch, maintenanceState]);

  return cms ? cms : null;
};

export default useFetchMaintenance;
