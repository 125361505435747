import React from 'react';
import { ButtonsGroup } from '@codegouvfr/react-dsfr/ButtonsGroup';

import './Maintenance.scss';

type MaintenanceProps = {
  payload: {
    mc: boolean;
    mec: boolean;
    dfm: boolean;
    titre_service_indispo: string;
    contenu_service_indispo: string;
    sous_titre_info_service_indispo: string;
    lien_fb: string;
    lien_twitter: string;
    lien_linkedin: string;
  };
};

const Maintenance: React.FC<MaintenanceProps> = ({ payload }) => {
  return (
    <main role="main" id="content" className="maintenance">
      <div className="fr-container maintenance-container fr-col-12 fr-col-sm-12">
        <div className="fr-grid-row fr-grid-row--gutters fr-grid-row--center fr-grid-row--middle">
          <div>
            <div className="fr-grid-row fr-grid-row--gutters fr-grid-row--center fr-grid-row--middle">
              <div className="fr-col-12 fr-col-lg-8 fr-col-sm-12">
                <h1>{payload.titre_service_indispo}</h1>
                <p className="fr-text--lead">{payload.contenu_service_indispo}</p>
                <p className="fr-text--sm">{payload.sous_titre_info_service_indispo}</p>
                <div className="container fr-col-12 fr-col-sm-12">
                  <ButtonsGroup
                    alignment="left"
                    buttons={[
                      {
                        children: 'Facebook',
                        iconId: 'fr-icon-facebook-circle-line',
                        linkProps: {
                          href: payload.lien_fb,
                        },
                        priority: 'tertiary',
                      },
                      {
                        children: 'Twitter',
                        iconId: 'fr-icon-twitter-line',
                        linkProps: {
                          href: payload.lien_twitter,
                        },
                        priority: 'tertiary',
                      },
                      {
                        children: 'LinkedIn',
                        iconId: 'fr-icon-linkedin-box-line',
                        linkProps: {
                          href: payload.lien_linkedin,
                        },
                        priority: 'tertiary',
                      },
                    ]}
                    buttonsEquisized
                    buttonsIconPosition="left"
                    buttonsSize="small"
                    inlineLayoutWhen="always"
                  />
                </div>
              </div>
              <div className="fr-grid-row fr-grid-row--gutters fr-grid-row--center fr-grid-row--middle fr-col-12 fr-col-lg-4 fr-col-sm-4">
                <div className="fr-col-6 fr-col-lg-12 fr-col-sm-12">
                  <img src="/img/services-down.svg" className="fr-responsive-img" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="fr-container fr-mb-8v marianne-icon">
          <div className="fr-grid-row fr-grid-row--center fr-grid-row--bottom">
            <div>
              <img src="/img/Marianne.svg" alt="logo marianne" />
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Maintenance;
