import { cmsSlice } from './cmsSlice';

const cmsUtilitaire = cmsSlice.injectEndpoints({
  endpoints: build => ({
    getGlobalUtilitaireCms: build.query<any, { qs?: string; locale: string }>({
      query: ({ locale }) => ({ url: `/utilitaire?locale=${locale}&populate=*` }),
    }),
  }),
});

export const { useGetGlobalUtilitaireCmsQuery } = cmsUtilitaire;
