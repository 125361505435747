import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth } from 'react-oidc-context';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '@codegouvfr/react-dsfr/Button';
import * as yup from 'yup';

import { Input as CustomInput } from 'components/Atoms/Input';
import { useGetDirectionTerritorialesAnonymousQuery } from 'redux/rtk/api/apiCommon';
import { useGetLocalisationCmsPageQuery } from 'redux/rtk/cms/cmsLocation';
import { setLocationPage } from 'redux/pages';
import { getLocationPage } from 'redux/pages/selectors';
import Loader from 'components/Atoms/Loader/Loader';
import { getValidationSchema } from './Location.helpers';
import { getCurrentLocale } from 'redux/locale/selectors';

import './Location.scss';
import ResultLocation from './components/ResultLocation';
import AppBreadcrumb from 'components/Atoms/Breadcrumb/Breadcrumb';
import LocationAuthenticated from './components/LocationAuthenticated';

export type LocationValues = yup.InferType<ReturnType<typeof getValidationSchema>>;
// eslint-disable-next-line complexity
const Location = () => {
  const auth = useAuth();
  const lang = useSelector(getCurrentLocale) as string;
  const [skip, setSkip] = useState<boolean>(true);
  const [formValue, setFormValue] = useState<number>();
  const [skipConnected, setSkipConnected] = useState<boolean>(false);
  const [displayAuthResult, setDisplayAuthResult] = useState<boolean>(auth.isAuthenticated);
  const {
    data: locationPageCms,
    error: locationPageCmsError,
    isLoading: isLocationPageCmsLoading,
  } = useGetLocalisationCmsPageQuery({ locale: lang !== '' ? lang : 'fr' });
  const {
    data: dataResults,
    isError,
    isFetching,
  } = useGetDirectionTerritorialesAnonymousQuery(formValue ? formValue.toString() : '', { skip });

  const formId = 'localisation-1813';
  const { handleSubmit, control, setError } = useForm({
    resolver: yupResolver(getValidationSchema(locationPageCms?.data?.attributes)),
    mode: 'onChange',
  });

  const onSubmit: SubmitHandler<LocationValues> = data => {
    setFormValue(data.zipCode);
    setDisplayAuthResult(false);
    setSkip(false);
  };
  const dispatch = useDispatch();
  const locationStore = useSelector(getLocationPage);

  useEffect(() => {
    if (locationPageCms) {
      dispatch(setLocationPage(locationPageCms));
    }
  }, [dispatch, locationPageCms]);

  if (isError) {
    setError('zipCode', {
      type: 'custom',
      message: locationStore.erreur_direction_territoriale_non_trouvee,
    });
  }

  if (isLocationPageCmsLoading) {
    return <Loader />;
  }

  if (locationPageCmsError) {
    return <div>Redirect page error</div>;
  }

  if (!locationPageCms) {
    return null;
  }

  return (
    <main>
      <div className="fr-container location">
        <AppBreadcrumb />
        <h1 className="fr-mt-6w fr-mt-md-7w fr-mb-3w fr-mb-md-5w">{locationStore.titre}</h1>
        {displayAuthResult ? (
          <p>{locationStore.description_sans_formulaire}</p>
        ) : (
          <p>{locationStore.description_form}</p>
        )}
        <form id={formId} onSubmit={handleSubmit(onSubmit)}>
          <div className="fr-grid-row fr-mb-5w location-form">
            <div className="fr-col-lg-2">
              <CustomInput
                id="zipCode"
                name="zipCode"
                defaultValue=""
                control={control}
                label={locationStore.zipCode_label}
                required={false}
                nativeInputProps={{
                  type: 'number',
                }}
              />
            </div>
            <div className="fr-col-lg-1 fr-mt-4w">
              <Button nativeButtonProps={{ type: 'submit' }}>{locationStore.button}</Button>
            </div>
          </div>
        </form>
        {auth.isAuthenticated && displayAuthResult && (
          <LocationAuthenticated
            skipConnected={skipConnected}
            setSkipConnected={setSkipConnected}
          />
        )}
        <>
          {isFetching ? (
            <Loader />
          ) : (
            <>
              {isError ? (
                <></>
              ) : (
                <>
                  {dataResults && dataResults.contenu && (
                    <div className="fr-grid-row fr-grid-row--gutters ">
                      {dataResults.contenu.map(el => {
                        return <ResultLocation key={el.adresseDT + el.telephoneDT} el={el} />;
                      })}
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </>
      </div>
    </main>
  );
};

export default Location;
