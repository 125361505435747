/* eslint-disable max-lines */
/* eslint-disable max-params */
import { DemandeRfBloc2, DemandeRfGeneral } from 'models/types/cms/demandeRF/type';

export default class addressMapping {
  static getGeneralMapping() {
    function getFormFields(
      cms: {
        bloc2Cms: DemandeRfBloc2;
        globalCms: DemandeRfGeneral;
      },
      formControls: {
        setValue: any;
        getValues: any;
        control: any;
        trigger: any;
      },
      lang?: string,
    ) {
      const { bloc2Cms, globalCms } = cms;

      const { control } = formControls;
      return {
        choix_adresse: {
          name: 'choix_adresse',
          label: '',
          control: control,
          options: [],
        },
        numero_de_voie: {
          name: 'numero_de_voie',
          control: control,
          label: bloc2Cms.numero_voie,
          hintText: globalCms.info_non_obligatoire,
          required: false,
        },
        nom_de_rue: {
          name: 'nom_de_rue',
          control: control,
          label: bloc2Cms.nom_rue,
          required: true,
        },
        complement_adresse: {
          name: 'complement_adresse',
          control: control,
          label: bloc2Cms.complement_adresse.titre,
          hintText: bloc2Cms.complement_adresse.sous_titre
            ? bloc2Cms.complement_adresse.sous_titre
            : '',
          required: false,
        },
        code_postal: {
          name: 'code_postal',
          control: control,
          label: bloc2Cms.code_postal,
          hintText: globalCms.info_non_obligatoire,
          required: false,
        },
        localite: {
          name: 'localite',
          control: control,
          label: bloc2Cms.localite,
          required: true,
        },
        province: {
          name: 'province',
          control: control,
          label: bloc2Cms.province,
          hintText: globalCms.info_non_obligatoire,
          required: false,
        },
        pays: {
          name: 'pays',
          control: control,
          label: bloc2Cms.pays,
          required: true,
          lang: lang,
        },
        adresse_email: {
          name: 'adresse_email',
          control: control,
          label: bloc2Cms.adresse_email.titre,
          hintText: bloc2Cms.adresse_email.sous_titre ? bloc2Cms.adresse_email.sous_titre : '',
          required: false,
          emailSettings: true,
        },
        telephone_adresse: {
          name: 'telephone_adresse',
          control: control,
          label: bloc2Cms.numero_telephone.titre,
          phoneSettings: true,
          hintText: bloc2Cms.numero_telephone.sous_titre
            ? bloc2Cms.numero_telephone.sous_titre
            : '',
          required: false,
        },
      };
    }

    return { getFormFields };
  }
}
