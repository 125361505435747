import { cmsSlice } from './cmsSlice';

const cmsMaintenance = cmsSlice.injectEndpoints({
  endpoints: build => ({
    getMaintenanceCmsPage: build.query<any, { qs?: string; locale: string }>({
      query: ({ locale }) => ({
        url: `/maintenance?locale=${locale}&populate=*`,
      }),
    }),
  }),
});

export const { useGetMaintenanceCmsPageQuery } = cmsMaintenance;
