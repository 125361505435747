import { RootState } from 'redux/reducers';
import { KeycloakStatus } from 'services/authentication';

export const isKeycloakAlive = (state: RootState) =>
  state.keycloak.keycloakStatus === KeycloakStatus.UP;

export const getKeycloakStatus = (state: RootState) => state.keycloak.keycloakStatus;

export const getKeycloakStatusNotificationSent = (state: RootState) =>
  state.keycloak.keycloakStatusNotificationSent;
