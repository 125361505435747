import React from 'react';

import { isFeatureActive } from 'services/featureToggling/isFeatureActive';
import { Features } from 'services/featureToggling/features';
import { Demande } from 'models/types/api/rf';
import { RFCmsTraductionApi } from 'models/types/cms/rf/types';
import ProcedureTile from 'components/Molecules/ProcedureTile/ProcedureTile';
import { DEMANDE_RF } from 'routes/path';
import { ProcedureRFCmsComponent } from 'models/types/cms/dashboard/type';

interface Props {
  cmsComponent: ProcedureRFCmsComponent;
  demandeRF: Demande;
  cmsTraductionApi: RFCmsTraductionApi;
}

const DemandeRFProcedure: React.FC<Props> = ({ cmsComponent, demandeRF, cmsTraductionApi }) => {
  return (
    <>
      {isFeatureActive(Features.REGROUPEMENT_FAMILIAL) && (
        <>
          <ProcedureTile
            imageUrl="/img/dashboard/vignette-rf.png"
            linkProps={{
              href: DEMANDE_RF + '/' + demandeRF.id,
            }}
            title={cmsComponent.titre}
            badgeText={cmsTraductionApi.demande_rf_etapes.a_completer}
            badgeColor="warning"
          />
        </>
      )}
    </>
  );
};

export default DemandeRFProcedure;
