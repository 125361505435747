export const AccomodationDefaultValues = {
  situation_logement: '',
  adresse_postale: undefined,
  complement_adresse: '',
  numero_digicode: undefined,
  nom_interphone: '',
  logement_famille: '',
  adresse_non_trouvee: false,
  numero_de_voie: undefined,
  nom_de_rue: '',
  code_postal: undefined,
  ville_ou_commune: undefined,
  justificatif_domicile_files: [],
  bail_complet_files: [],
  quittance_loyer_files: [],
  situation_futur_logement: '',
  day_entree_futur_logement: undefined,
  month_entree_futur_logement: undefined,
  year_entree_futur_logement: undefined,
  adresse_postale_futur_logement: undefined,
  adresse_futur_logement_non_trouvee: false,
  ville_ou_commune_futur_logement: undefined,
  numero_digicode_futur_logement: undefined,
  promesse_location_files: [],
  engagement_sous_location_files: [],
  type_hebergeur: '',
  nom_hebergeur: '',
  attestation_hebergement_employeur_files: [],
  acte_propriete_files: [],
  type_futur_hebergement: '',
  nom_futur_hebergeur: '',
  validation: 'true',
  justificatif_hebergeant_files: [],
  attestation_domicile_hebergeant_files: [],
  attestation_mise_disposition_logement_files: [],
  attestation_domicile_employeur_files: [],
  bail_quittance_hebergeant_files: [],
  justificatif_acquisition_futur_logement_files: [],
  promesse_location_autre_files: [],
  attestation_mise_disposition_logement_autre_files: [],
  engagement_sous_location_autre_files: [],
  justificatif_hebergeant_autre_files: [],
  attestation_domicile_hebergeant_autre_files: [],
  justificatif_acquisition_futur_logement_autre_files: [],
  bail_quittance_hebergeant_autre_files: [],
  quittance_loyer_autre_files: [],
  attestation_domicile_employeur_autre_files: [],
  bail_complet_autre_files: [],
  justificatif_domicile_autre_files: [],
  attestation_hebergement_employeur_autre_files: [],
  acte_propriete_autre_files: [],
};
