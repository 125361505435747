import { useEffect, useState } from 'react';
import { FieldValues, UseFormReset } from 'react-hook-form';
import { RadioButtons } from '@codegouvfr/react-dsfr/RadioButtons';

import { AdresseBeneficiaire } from 'models/types/api/beneficiaires';

interface AddressChoiceProps {
  addresses: AdresseBeneficiaire[];
  cms: any;
  reset: UseFormReset<FieldValues>;
  initVal?: string;
}

export const AUTRE_ADRESSE = 'autre_adresse';

export default function AddressChoice({ addresses, cms, reset, initVal }: AddressChoiceProps) {
  const [val, setVal] = useState<string | undefined>(initVal);

  useEffect(() => {
    if (addresses.length === 0 || initVal === undefined) {
      setVal(AUTRE_ADRESSE);
      reset({
        choix_adresse: AUTRE_ADRESSE,
        numero_de_voie: '',
        nom_de_rue: '',
        code_postal: '',
        localite: '',
        province: '',
        pays: '',
        complement_adresse: '',
        adresse_email: '',
        telephone_adresse: '',
      });
    }
  }, [addresses, initVal]);

  return (
    <RadioButtons
      legend={cms.radio_choix_adresse_enfant}
      state={val ? 'success' : 'default'}
      className={'address-radio'}
      options={addresses
        .map(address => ({
          label: `${address.adresse?.numero ? address.adresse?.numero : ''} ${
            address.adresse?.adresse1
          } ${address.adresse?.codePostal ? address.adresse?.codePostal : ''} ${
            address.adresse?.ville
          } ${address.adresse?.email ? address.adresse?.email : ''} ${
            address.adresse?.telephone ? address.adresse?.telephone : ''
          }`,
          nativeInputProps: {
            checked: val === address.id,
            onChange: () => {
              setVal(address.id ? address.id : undefined);
              reset({
                choix_adresse: address?.id ? address?.id : '',
                numero_de_voie: address.adresse?.numero ? address.adresse.numero : '',
                nom_de_rue: address.adresse?.adresse1 ? address.adresse.adresse1 : '',
                code_postal: address.adresse?.codePostal ? address.adresse.codePostal : '',
                localite: address.adresse?.ville ? address.adresse.ville : '',
                province: address.adresse?.province ? address.adresse.province : '',
                pays: address.adresse?.pays ? address.adresse.pays : '',
                complement_adresse: address.adresse?.adresse2 ? address.adresse.adresse2 : '',
                adresse_email: address.adresse?.email ? address.adresse.email : '',
                telephone_adresse: address.adresse?.telephone ? address.adresse.telephone : '',
              });
            },
          },
        }))
        .concat(
          addresses.length > 0
            ? [
                {
                  label: cms.autre_adresse,
                  nativeInputProps: {
                    checked: val === AUTRE_ADRESSE,
                    onChange: () => {
                      setVal(AUTRE_ADRESSE);
                      reset({
                        choix_adresse: AUTRE_ADRESSE,
                        numero_de_voie: '',
                        nom_de_rue: '',
                        code_postal: '',
                        localite: '',
                        province: '',
                        pays: '',
                        complement_adresse: '',
                        adresse_email: '',
                        telephone_adresse: '',
                      });
                    },
                  },
                },
              ]
            : [],
        )}
    />
  );
}
