import React from 'react';
import { useController } from 'react-hook-form';

import TextInput from 'components/Atoms/Forms/TextInput/TextInput';
import { computeIsValidDsfr } from 'helpers/app/ComponentsStatusHelper';

interface TextInputContainerProps {
  label: string | React.ReactNode;
  hintText?: string;
  stateRelatedMessage?: string;
  required?: boolean;
  name: string;
  control: any;
  customClass?: string;
  ref?: any;
  onChange?: (e: React.ChangeEvent<HTMLInputElement> | string) => void;
  type?: string;
  phoneSettings?: boolean;
  emailSettings?: boolean;
}

const TextInputContainer: React.FC<TextInputContainerProps> = React.forwardRef(
  (
    {
      label,
      hintText,
      stateRelatedMessage,
      name,
      control,
      customClass,
      onChange,
      type = 'text',
      phoneSettings,
      emailSettings,
    },
    ref,
  ) => {
    const { field, fieldState } = useController({
      name,
      control,
    });
    return (
      <div
        className={
          customClass
            ? customClass
            : 'fr-col-12 fr-mt-4v fr-mt-lg-0v fr-mt-md-0v fr-col-lg-4 fr-col-md-4'
        }
      >
        <TextInput
          label={label}
          hintText={hintText}
          stateRelatedMessage={fieldState.error?.message ?? stateRelatedMessage}
          name={name}
          state={computeIsValidDsfr(fieldState.isTouched, fieldState.isDirty, fieldState.error)}
          field={field}
          control={control}
          ref={ref}
          onChange={onChange}
          type={type}
          phoneSettings={phoneSettings}
          emailSettings={emailSettings}
        />
      </div>
    );
  },
);

export default TextInputContainer;
