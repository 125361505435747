import { cmsSlice } from './cmsSlice';

const cmsMentions = cmsSlice.injectEndpoints({
  endpoints: build => ({
    getMentionsCmsPage: build.query<any, { locale: string }>({
      query: ({ locale }) => ({ url: `/mentions-legale?locale=${locale}&populate=*` }),
    }),
  }),
});

export const { useGetMentionsCmsPageQuery } = cmsMentions;
