import { cmsSlice } from './cmsSlice';
import { OnlineServicesCmsDto } from 'models/types/cms/servicesEnLigne/types';
import { LayoutCmsDto } from 'models/types/cms/layout/types';
import { UsagerCmsPage } from '../../../models/types/cms/usager/types';

const cmsCommon = cmsSlice.injectEndpoints({
  endpoints: build => ({
    getProceduresCmsCollection: build.query<any, { qs: string; locale: string }>({
      query: ({ qs, locale }) => ({
        url: `/procedures?locale=${locale}&${qs}`,
      }),
    }),
    getArticlesCmsCollection: build.query<any, { qs: string; locale: string }>({
      query: ({ qs, locale }) => ({ url: `/articles?locale=${locale}&${qs}` }),
    }),
    getArticleCategoriesCmsCollection: build.query<{ data: any }, { qs?: string; locale: string }>({
      query: ({ locale }) => ({
        url: `/categories-articles?locale=${locale}&populate=*`,
      }),
    }),
    getOnlineServicesCmsCollection: build.query<
      { data: OnlineServicesCmsDto[] },
      { qs?: string; locale: string }
    >({
      query: ({ locale }) => ({
        url: `/services-en-lignes?locale=${locale}&populate=*`,
      }),
    }),
    getLayoutCmsPage: build.query<LayoutCmsDto, { qs?: string; locale: string }>({
      query: ({ locale }) => ({ url: `/layout?locale=${locale}&populate=*` }),
    }),
    getPageTitle: build.query<any, { qs?: string; locale: string }>({
      query: ({ locale }) => ({
        url: `/titres-page?locale=${locale}&populate=*`,
      }),
    }),
    getUsagerCmsPage: build.query<UsagerCmsPage, { qs?: string; locale: string }>({
      query: ({ locale }) => ({
        url: `/traduction-api-usager?locale=${locale}&populate=*`,
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetProceduresCmsCollectionQuery,
  useGetArticlesCmsCollectionQuery,
  useGetOnlineServicesCmsCollectionQuery,
  useGetLayoutCmsPageQuery,
  useGetArticleCategoriesCmsCollectionQuery,
  useGetPageTitleQuery,
  useGetUsagerCmsPageQuery,
} = cmsCommon;
