import React from 'react';
import { useWatch } from 'react-hook-form';
import CallOut from '@codegouvfr/react-dsfr/CallOut';

import DateInputContainer from 'components/Molecules/Forms/DateInputContainer/DateInputContainer';
import CommonRowContainer from 'components/Molecules/Forms/CommonRowContainer/CommonRowContainer';
import AutocompleteContainer from 'components/Molecules/Forms/AutocompleteContainer/AutocompleteContainer';
import TextInputContainer from 'components/Molecules/Forms/TextInputContainer/TextInputContainer';
import Loader from 'components/Atoms/Loader/Loader';
import UploadFiles from 'components/Atoms/UploadFiles/UploadFiles';
import CustomCheckboxContainer from 'components/Molecules/Forms/CustomCheckboxContainer/CustomCheckboxContainer';
import { getRfCmsContents } from 'pages/DemandeRF/Helpers/Cms/getRfCmsContents';
import {
  useGetUsagerListeNationalitesQuery,
  useGetUsagerListePaysQuery,
} from 'redux/rtk/api/apiCommon';

type ConjointInfoNaissanceProps = {
  lang: string;
  fieldArrayMapping: any;
  filesMapping: any;
  isNew: string;
  formFieldsMapping: any;
  control: any;
  formState: any;
  nationalitesNonEligibles: Array<string>;
};

const ConjointInfoNaissance: React.FC<ConjointInfoNaissanceProps> = ({
  lang,
  fieldArrayMapping,
  filesMapping,
  isNew,
  formFieldsMapping,
  control,
  formState,
  nationalitesNonEligibles,
}) => {
  const {
    data: listePays,
    isError: errorPays,
    isFetching: loadingPays,
  } = useGetUsagerListePaysQuery();

  const {
    data: listeNationalite,
    isError: errorNationalite,
    isFetching: loadingNationalite,
  } = useGetUsagerListeNationalitesQuery();

  const acteNaissanceConjointTraduit = useWatch({
    control,
    name: 'acte_naissance_conjoint_traduit',
  });
  const nationaliteConjoint = useWatch({
    control,
    name: 'nationalite_conjoint',
  });

  if (errorPays || errorNationalite) {
    return <div>Redirect page error</div>;
  }

  if (loadingPays || loadingNationalite) {
    return <Loader />;
  }
  const { bloc2Cms } = getRfCmsContents(lang);

  return (
    <>
      <CommonRowContainer
        legend={bloc2Cms.titre_date_naissance_conjoint}
        lang={lang}
        children={[
          <DateInputContainer
            customClass="fr-col-12 fr-mt-4v fr-mt-lg-0v fr-mt-md-0v fr-col-sm-10"
            {...formFieldsMapping.date_naissance_conjoint}
          />,
        ]}
      />
      {formState.errors.date_naissance_conjoint &&
      formState.errors.date_naissance_conjoint.type === 'mustBeMajor' ? (
        <CallOut
          iconId="fr-icon-info-line"
          title={bloc2Cms.bulle_aide_age.titre}
          className="callout-alert fr-mt-2w"
        >
          {bloc2Cms.bulle_aide_age.contenu}
        </CallOut>
      ) : (
        <>
          <CommonRowContainer
            children={[
              <AutocompleteContainer
                {...formFieldsMapping.pays_naissance_conjoint}
                payload={listePays}
                source="API"
                customClass="fr-col-12 fr-mt-4v fr-mt-lg-0v fr-mt-md-0v fr-col-sm-10"
              />,
              <TextInputContainer
                customClass="fr-col-12 fr-mt-4v fr-mt-lg-0v fr-mt-md-0v fr-col-sm-10"
                {...formFieldsMapping.ville_naissance_conjoint}
              />,
            ]}
          />
          <CommonRowContainer
            legend={bloc2Cms.quelle_nationalite_conjoint}
            infobulleContent={bloc2Cms.infobulle_vous_etes_de_nationalite}
            infobulleCustomModal
            lang={lang}
            children={[
              <AutocompleteContainer
                {...formFieldsMapping.nationalite_conjoint}
                payload={listeNationalite}
                source="API"
                customClass="fr-col-12 fr-mt-4v fr-mt-lg-0v fr-mt-md-0v fr-col-sm-10"
              />,
            ]}
          />
          {nationaliteConjoint && nationalitesNonEligibles.includes(nationaliteConjoint) ? (
            <>
              {nationaliteConjoint === 'FRA' ? (
                <CallOut
                  iconId="fr-icon-info-line"
                  title={bloc2Cms.bulle_aide_nationalite_fr.titre}
                  className="callout-alert fr-mt-2w"
                >
                  {bloc2Cms.bulle_aide_nationalite_fr.contenu}
                </CallOut>
              ) : (
                <CallOut
                  iconId="fr-icon-info-line"
                  title={bloc2Cms.bulle_aide_nationalite_eu.titre}
                  className="callout-alert fr-mt-2w"
                >
                  title={bloc2Cms.bulle_aide_nationalite_eu.contenu}
                </CallOut>
              )}
            </>
          ) : (
            <>
              <div className="container fr-my-6v">
                <hr />
              </div>
              <UploadFiles
                {...filesMapping.acte_naissance_conjoint_files.upload}
                fieldArray={fieldArrayMapping.acte_naissance_conjoint_files}
                isNew={isNew}
                lang={lang}
              />
              <CustomCheckboxContainer
                {...formFieldsMapping.acte_naissance_conjoint_traduit}
                large
              />
              {acteNaissanceConjointTraduit && (
                <UploadFiles
                  {...filesMapping.acte_naissance_conjoint_traduit_files.upload}
                  fieldArray={fieldArrayMapping.acte_naissance_conjoint_traduit_files}
                  isNew={isNew}
                />
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default ConjointInfoNaissance;
