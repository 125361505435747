import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  DemandeRfBloc2,
  DemandeRfErrors,
  DemandeRfUtilitaire,
} from 'models/types/cms/demandeRF/type';

import { getEnfantValidationSchema } from '../helpers/enfantValidationSchema';
import { enfantDefaultValues } from '../helpers/enfantDefaultValues';

const EnfantUseForm = (
  erreursRfCms: DemandeRfErrors,
  bloc2Cms: DemandeRfBloc2,
  utilitaireCms: DemandeRfUtilitaire,
  nationalitesNonEligibles: Array<string>,
) => {
  const enfantValidationSchema = useMemo(
    () =>
      getEnfantValidationSchema(erreursRfCms, bloc2Cms, utilitaireCms, nationalitesNonEligibles),
    [erreursRfCms, nationalitesNonEligibles],
  );

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    getFieldState,
    trigger,
    watch,
    formState,
    control,
  } = useForm({
    resolver: yupResolver(enfantValidationSchema),
    defaultValues: {
      ...enfantDefaultValues,
    },
    mode: 'all',
  });

  return {
    handleSubmit,
    getValues,
    setValue,
    getFieldState,
    trigger,
    watch,
    formState,
    control,
    register,
  };
};

export default EnfantUseForm;
