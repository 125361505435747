import { FC } from 'react';
import { useSelector } from 'react-redux';

import AppBreadcrumb from 'components/Atoms/Breadcrumb/Breadcrumb';
import Loader from 'components/Atoms/Loader/Loader';
import { AppDocument } from 'models/types/api/entities';
import { getDocumentsPage } from 'redux/pages/selectors';
import { useGetDocumentsCmsQuery } from 'redux/rtk/cms/cmsDocuments';
import { useGetAllDocumentsRfQuery, useGetDocumentsAsileQuery } from 'redux/rtk/api/apiDocuments';
import { getCurrentLocale } from 'redux/locale/selectors';
import { CMS_BASE_URL } from 'services/cmsClient';

import Document from './components/Document';
import './Documents.scss';

const Documents: FC = () => {
  const lang = useSelector(getCurrentLocale) as string;
  const documentsPage = useSelector(getDocumentsPage);
  const { data: asileDocumentsData, isLoading: isLoadingAsileDocuments } =
    useGetDocumentsAsileQuery();
  const { data: rfDocumentsData, isLoading: isLoadingRfDocuments } = useGetAllDocumentsRfQuery();
  const { cmsDocuments } = useGetDocumentsCmsQuery(
    {
      locale: lang !== '' ? lang : 'fr',
    },
    {
      selectFromResult: ({ data: res }) => ({
        cmsDocuments: {
          tout_mes_documents: res?.data.attributes.tout_mes_documents,
          tout_mes_documents_asile: res?.data.attributes.tout_mes_documents_asile,
          tous_mes_documents_rf: res?.data.attributes.tous_mes_documents_rf,
          tous_mes_documents_rri: res?.data.attributes.tous_mes_documents_rri,
          tout: res?.data.attributes.tout,
          asile: res?.data.attributes.asile,
          regroupement_familial: res?.data.attributes.regroupement_familial,
          retour_reinsertion: res?.data.attributes.retour_reinsertion,
          message_aucun_documents: res?.data.attributes.message_aucun_documents,
          nom_badge_asile: res?.data.attributes.nom_badge_asile,
          nom_badge_rf: res?.data.attributes.nom_badge_rf,
          nom_badge_rri: res?.data.attributes.nom_badge_rri,
          img_aucun_document: res?.data.attributes.img_aucun_document?.data?.attributes.url,
          nombre_documents: res?.data.attributes.nombre_documents,
        },
      }),
    },
  );

  let documents: AppDocument[] = [];

  if (isLoadingAsileDocuments || isLoadingRfDocuments) {
    return <Loader />;
  }

  if (asileDocumentsData?.total) {
    documents = [...documents, ...asileDocumentsData.contenu];
  }
  if (rfDocumentsData?.total) {
    documents = [...documents, ...rfDocumentsData.contenu];
  }

  return (
    <main id="documents">
      <div className="fr-container">
        <AppBreadcrumb />
        <h1 className="fr-mt-3w fr-mt-md-5w fr-mb-5w">{documentsPage.titre}</h1>
        {documents.length > 0 && (
          <p>{cmsDocuments.nombre_documents.replace('{nbr-documents}', documents.length)}</p>
        )}
        <div className="fr-col fr-p-5w fr-mb-7w">
          {documents.length > 0 &&
            documents.map((doc: AppDocument) => {
              return <Document key={doc.codeDocument} document={doc} lang={lang} />;
            })}
          {documents.length === 0 && (
            <>
              <div className="fr-mb-7w fr-grid-row fr-grid-row--center">
                <img
                  className="img-aucun-documents "
                  src={`${CMS_BASE_URL}${cmsDocuments.img_aucun_document}`}
                  alt=""
                />
              </div>
              <p className="message-aucun-documents fr-grid-row fr-grid-row--center">
                {cmsDocuments.message_aucun_documents}
              </p>
            </>
          )}
        </div>
      </div>
    </main>
  );
};

export default Documents;
