import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { saveLocaleToStorage, loadLocaleFromStorage } from 'helpers/app/locale';
import { RegisteredLocales } from 'constants/locales';

export type LocaleState = Readonly<{
  currentLocale: RegisteredLocales;
}>;

const initialState: LocaleState = {
  currentLocale: loadLocaleFromStorage() || RegisteredLocales.FR,
};

const localeSlice = createSlice({
  name: 'Locale',
  initialState,
  reducers: {
    setCurrentLocale: (state, action: PayloadAction<RegisteredLocales>) => {
      state.currentLocale = action.payload;
      saveLocaleToStorage(action.payload);
    },
  },
});

export const { setCurrentLocale } = localeSlice.actions;
export default localeSlice.reducer;
