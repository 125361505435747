import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { formatUser } from 'helpers/api/userFormatter';

import { User } from 'models/types/app/user';

export type UserState = Readonly<{ user: User }>;

const initialState: UserState = {
  user: {
    numEtranger: null,
    nom_naissance: null,
    nom_usage: null,
    prenom: null,
    sexe: null,
    date_naissance: null,
    pays_naissance: null,
    nationalite: null,
    situation_familiale: null,
    adresse_mail: null,
    telephone: null,
    date_entree_en_france: null,
    date_entree_france: null,
  },
};

const userSlice = createSlice({
  name: 'User',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<any>) => {
      state.user = formatUser(action.payload);
    },
  },
});

export const { setUser } = userSlice.actions;

export default userSlice.reducer;
