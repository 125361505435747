import { Factory } from 'miragejs';

export const demandeRf = Factory.extend({
  id: '73374f1d-e362-45c8-b800-0d5b292647d0',
  statut: 'start',
  numeroDossier: null,
  idRfng: null,
  idCerfaRfng: null,
  usager: {
    id: '706037ed-b6b3-4913-8385-53b88a4c46c8',
    numEtranger: '7703023991',
    etatCivil: {
      nom: null,
      prenoms: null,
      sexe: null,
      nomUsage: null,
      dateNaissance: null,
      paysNaissance: null,
      villeNaissance: null,
      nationalite: null,
      acteNaissanceOriginal: null,
      acteNaissanceTrad: null,
    },
    titreDeSejour: null,
    titreDeSejourAutre: null,
    titreDeSejourMention: null,
    titreDeSejourMentionAutre: null,
    titreDeSejourFin: null,
    titreDeSejourRenouvellement: null,
    situationMatrimonial: null,
    situationMatrimonialDate: null,
    marieEnFrance: null,
    situationMatrimonialOriginal: null,
    situationMatrimonialTrad: null,
    email: null,
    telPortable: null,
    telFixe: null,
  },
  demandeLogements: [],
  demandeConjoints: [],
  demandeEnfants: [],
  demandeResources: [],
  documents: [],
  demandeUnions: [],
  demandeAdresses: [],
  travailDansBtp: null,
  arretTravail12DernierMois: null,
  beneficiaireAahOuAsi: null,
  autresRessources: null,
  pensionAlimentaire: null,
  declarerConjointRessources: null,
  signatureDocumentLieu: null,
  engagementCertifieRenseignementsExact: null,
  engagementChangementSituation: null,
  engagementDeclareRfNonPolygamie: null,
  engagementArt4211: null,
  engagementSignatureDocument: null,
  engagementRgpd: null,
});
