/* eslint-disable no-useless-concat */
/* eslint-disable risxss/catch-potential-xss-react */
import { Dispatch, FC, ReactNode, SetStateAction } from 'react';
import { fr } from '@codegouvfr/react-dsfr';
import { Accordion } from '@codegouvfr/react-dsfr/Accordion';

import { WidgetGenericObject } from 'models/types/cms/widgetPageContenu/types';
import { Eligibilite as EligibiliteType } from 'models/types/cms/widgetPageContenu/types';

import { PageContentHtmlParserFunction } from '../HtmlParsers/PageContentHtmlParser';
import { CMS_BASE_URL } from 'services/cmsClient';
import SimulatorEligibility from 'components/Molecules/SimulatorEligibility/SimulatorEligibility';

export type CmsContentGeneratorProps = {
  content: WidgetGenericObject[];
  setElement?: Dispatch<SetStateAction<HTMLDivElement | null>>;
  accIntegrationType?: boolean;
};

const CmsContentGenerator: FC<CmsContentGeneratorProps> = (props: CmsContentGeneratorProps) => {
  function removeTags(str: string) {
    str = str.toString();

    // Regular expression to identify HTML tags in
    // the input string. Replacing the identified
    // HTML tag with a null string.
    return str.replace(/(<([^>]+)>)/gi, '');
  }

  const generateAccordion = (
    content: WidgetGenericObject,
    accIntegrationType?: boolean,
    isApprendreFrancais?: boolean,
  ) => {
    const isFirstChild = content.id === 1 ? '' : 'fr-pt-10v';
    return (
      <div
        ref={content.anchor || isApprendreFrancais ? props.setElement : null}
        className={
          fr.cx('fr-accordions-group') +
          ' fr-mb-6v' +
          ` ${accIntegrationType ? 'accueil-integration-accordion' : ''}` +
          ` ${isFirstChild}`
        }
        id={content.anchor}
      >
        {content.titre && <h2 className="fr-h3">{content.titre}</h2>}
        {content.accordeons &&
          content.accordeons.map(acc => {
            return (
              <Accordion label={`${acc.titre}`} key={acc.titre}>
                {PageContentHtmlParserFunction(acc.introduction || '')}
                {acc.lignes_accordeon.length > 1 ? (
                  <ul className={accIntegrationType ? 'without-list-style' : ''}>
                    {acc.lignes_accordeon.map(ligne => {
                      const iconUrl = getIconUrl(ligne);
                      return (
                        <li
                          key={removeTags(ligne.texte)}
                          className={accIntegrationType ? 'li-with-img' : ''}
                        >
                          {iconUrl && accIntegrationType && (
                            <img className="icon-in-list" src={iconUrl} alt="" aria-hidden="true" />
                          )}
                          <span>{PageContentHtmlParserFunction(ligne.texte)}</span>
                        </li>
                      );
                    })}
                  </ul>
                ) : (
                  acc.lignes_accordeon.map(ligne => {
                    return PageContentHtmlParserFunction(ligne.texte);
                  })
                )}
              </Accordion>
            );
          })}
      </div>
    );
  };

  const generateContent = (content: WidgetGenericObject[], accIntegrationType?: boolean) => {
    const nodes: ReactNode[] = [];
    let isApprendreFrancais = false;

    content.forEach((el, id, array) => {
      if (Object.is(array.length - 1, id)) {
        isApprendreFrancais = true;
      }

      switch (el.__component) {
        case 'widget-page-contenu.introduction':
          nodes.push(
            <h2 key={el.id} className="fr-mt-10v">
              {el?.texte}
            </h2>,
          );
          break;
        case 'widget-page-contenu.paragraphe':
          nodes.push(PageContentHtmlParserFunction(el?.contenu || '', true));
          break;
        case 'widget-page-contenu.groupe-accordeon':
          nodes.push(generateAccordion(el, accIntegrationType, isApprendreFrancais));
          break;
        case 'widget-page-contenu.eligibilite':
          nodes.push(
            <SimulatorEligibility key={el.id} eligibilite={el as unknown as EligibiliteType} />,
          );
          break;
        case 'widget-page-contenu.illustration':
          if (el.image) {
            nodes.push(<img src={`${CMS_BASE_URL}${el?.image.data.attributes.url}`} alt="" />);
          }
          break;
        case 'widget-page-contenu.image_with_alt':
          if (el.image) {
            nodes.push(
              <img
                src={`${CMS_BASE_URL}${el?.image.data.attributes.url}`}
                alt={el?.balise_alt}
                className="content-img fr-mt-6v fr-mb-4v"
              />,
            );
          }
          break;
        default:
          nodes.push(PageContentHtmlParserFunction(el?.contenu || '', true));
          break;
      }
    });
    return nodes;
  };

  return (
    <div id="cms-content">
      {props.content ? generateContent(props.content, props.accIntegrationType) : ''}
    </div>
  );
};

const getIconUrl = (ligne: any) => {
  if (ligne.icone.data && ligne.icone.data.attributes && ligne.icone.data.attributes.url !== null) {
    return `${CMS_BASE_URL}${ligne.icone.data.attributes.url}`;
  }
  return false;
};

export default CmsContentGenerator;
