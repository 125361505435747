import qs from 'qs';

export const procedureQuery = qs.stringify({
  populate: {
    integration_france: {
      fields: ['titre'],
      populate: {
        bloc_1: {
          fields: ['titre', 'description', 'label_lien', 'lien'],
        },
        bloc_2: {
          fields: ['titre', 'description', 'label_lien', 'lien'],
        },
      },
    },
  },
});
