/* eslint-disable complexity */
/* eslint-disable max-lines */
import { v4 as uuidv4 } from 'uuid';
import { useFieldArray } from 'react-hook-form';
import Button from '@codegouvfr/react-dsfr/Button';

import {
  delActiviteDocuments,
  removeActivites,
  setActiviteDocuments,
  setActivites,
  updateActivites,
} from 'redux/demandeRf';

import UploadFiles from 'components/Atoms/UploadFiles/UploadFiles';
import CommonRowContainer from 'components/Molecules/Forms/CommonRowContainer/CommonRowContainer';
import SelectInputContainer from 'components/Molecules/Forms/SelectInputContainer/SelectInputContainer';
import RadioButtonContainer from 'components/Molecules/Forms/RadioButtonContainer/RadioButtonContainer';

import { ResourcesMapping } from '../Resources.mapping';

interface ResourcesSituationProps {
  index: number;
  dispatch: any;
  demandeId?: string | null;
  lang: string;
  cms: {
    bloc4Cms: any;
    globalCms: any;
    utilitaireCms: any;
  };
  formControls: {
    control: any;
    setValue: any;
    getValues: any;
    register: any;
    trigger: any;
    errors: any;
    watch: any;
    dirtyFields: any;
  };
  filesControls: {
    remove: any;
    append: any;
    //uploadFilesParameters: any;
    field: any;
    total: number;
  };
  resourcesDebug: any;
}

const RessourceSituation = ({
  index,
  dispatch,
  demandeId,
  cms,
  filesControls,
  formControls,
  resourcesDebug,
  lang,
}: ResourcesSituationProps) => {
  const { bloc4Cms, globalCms, utilitaireCms } = cms;
  const { dirtyFields, control, register, trigger, setValue, getValues, errors, watch } =
    formControls;
  const { field, remove, append, total } = filesControls;
  const fileStateArray = dirtyFields.activities;

  const uploadFilesParameters: any = {
    demandeId: demandeId ? demandeId : '',
    formInfos: {
      key: 'ressource',
      id: field.uuid,
      deleteFunction: delActiviteDocuments,
      setFunction: setActiviteDocuments,
    },
    formControl: {
      control: control,
      setValue: setValue,
      getValues: getValues,
      trigger: trigger,
      errors: errors,
      watch: watch,
    },
    demandeResourceId: field.uuid,
    demandeRFUtilitairecms: utilitaireCms,
    formToSubmit: 'ressources',
  };

  const filesName = {
    ressSalCdiContratAttestationTravailFilesName: `activities.${index}.ress_salcdi_contrat_attestation_travail_files`,
    ressSalCdiBulletinSalaireFilesName: `activities.${index}.ress_salcdi_bulletin_salaire_files`,
    ressSalCdiCertificatTravailFilesName: `activities.${index}.ress_salcdi_certificat_travail_files`,
    ressSalIntContratAttestationTravailFilesName: `activities.${index}.ress_salint_contrat_attestation_travail_files`,
    ressSalIntCertificatTravailFilesName: `activities.${index}.ress_salint_certificat_travail_files`,
    ressSalCddContratAttestationTravailFilesName: `activities.${index}.ress_salcdd_contrat_attestation_travail_files`,
    ressSalCddCertificatTravailFilesName: `activities.${index}.ress_salcdd_certificat_travail_files`,
    ressSalCddBulletinSalaireFilesName: `activities.${index}.ress_salcdd_bulletin_salaire_files`,
    ressArtisaExtraitInscriptionMetiersFilesName: `activities.${index}.ress_artisa_extrait_inscription_metiers_files`,
    ressArtisaBilanComptableResultatAttestationRevenuFilesName: `activities.${index}.ress_artisa_bilan_comptable_resultat_attestation_revenu_files`,
    ressDemEmpReleveSituationDroitsVersementsPeFilesName: `activities.${index}.ress_dememp_releve_situation_droits_versements_pe_files`,
    ressProlibExtraitInscriptionSirenFilesName: `activities.${index}.ress_prolib_extrait_inscription_siren_files`,
    ressProlibBilanComptableResultatAttestationRevenuFilesName: `activities.${index}.ress_prolib_bilan_comptable_resultat_attestation_revenu_files`,
    ressCommerExtraitInscriptionRcsFilesName: `activities.${index}.ress_commer_extrait_inscription_rcs_files`,
    ressCommerBilanComptableResultatAttestationRevenuFilesName: `activities.${index}.ress_commer_bilan_comptable_resultat_attestation_revenu_files`,
    ressAutentDeclarationActiviteCfeFilesName: `activities.${index}.ress_autent_declaration_activite_cfe_files`,
    ressAutentLivreRecettesAttestationRevenuFilesName: `activities.${index}.ress_autent_livre_recettes_attestation_revenu_files`,
    ressInvaliAttributionPensionRetraiteInvaliditeFilesName: `activities.${index}.ress_invali_attribution_pension_retraite_invalidite_files`,
    ressInvaliAvisVersementRetraiteInvalideFilesName: `activities.${index}.ress_invali_avis_versement_retraite_invalide_files`,
    ressRetraiAttributionPensionRetraiteInvaliditeFilesName: `activities.${index}.ress_retrai_attribution_pension_retraite_invalidite_files`,
    ressRetraiAvisVersementRetraiteInvalideFilesName: `activities.${index}.ress_retrai_avis_versement_retraite_invalide_files`,
  };
  // const ressSalCdiContratAttestationTravailFilesName = `ressources.${index}.ress_salcdi_contrat_attestation_travail_files`;
  // const ressSalCdiBulletinSalaireFilesName = `ressources.${index}.ress_salcdi_bulletin_salaire_files`;
  // const ressSalCdiCertificatTravailFilesName = `ressources.${index}.ress_salcdi_certificat_travail_files`;
  // const ressSalIntContratAttestationTravailFilesName = `ressources.${index}.ress_salint_contrat_attestation_travail_files`;
  // const ressSalIntCertificatTravailFilesName = `ressources.${index}.ress_salint_certificat_travail_files`;
  // const ressSalCddContratAttestationTravailFilesName = `ressources.${index}.ress_salcdd_contrat_attestation_travail_files`;
  // const ressSalCddCertificatTravailFilesName = `ressources.${index}.ress_salcdd_certificat_travail_files`;
  // const ressSalCddBulletinSalaireFilesName = `ressources.${index}.ress_salcdd_bulletin_salaire_files`;
  // const ressArtisaExtraitInscriptionMetiersFilesName = `ressources.${index}.ress_artisa_extrait_inscription_metiers_files`;
  // const ressArtisaBilanComptableResultatAttestationRevenuFilesName = `ressources.${index}.ress_artisa_bilan_comptable_resultat_attestation_revenu_files`;
  // const ressDemEmpReleveSituationDroitsVersementsPeFilesName = `ressources.${index}.ress_dememp_releve_situation_droits_versements_pe_files`;
  // const ressProlibExtraitInscriptionSirenFilesName = `ressources.${index}.ress_prolib_extrait_inscription_siren_files`;
  // const ressProlibBilanComptableResultatAttestationRevenuFilesName = `ressources.${index}.ress_prolib_bilan_comptable_resultat_attestation_revenu_files`;
  // const ressCommerExtraitInscriptionRcsFilesName = `ressources.${index}.ress_commer_extrait_inscription_rcs_files`;
  // const ressCommerBilanComptableResultatAttestationRevenuFilesName = `ressources.${index}.ress_commer_bilan_comptable_resultat_attestation_revenu_files`;
  // const ressAutentDeclarationActiviteCfeFilesName = `ressources.${index}.ress_autent_declaration_activite_cfe_files`;
  // const ressAutentLivreRecettesAttestationRevenuFilesName = `ressources.${index}.ress_autent_livre_recettes_attestation_revenu_files`;
  // const ressInvaliAttributionPensionRetraiteInvaliditeFilesName = `ressources.${index}.ress_invali_attribution_pension_retraite_invalidite_files`;
  // const ressInvaliAvisVersementRetraiteInvalideFilesName = `ressources.${index}.ress_invali_avis_versement_retraite_invalide_files`;
  // const ressRetraiAttributionPensionRetraiteInvaliditeFilesName = `ressources.${index}.ress_retrai_attribution_pension_retraite_invalidite_files`;
  // const ressRetraiAvisVersementRetraiteInvalideFilesName = `ressources.${index}.ress_retrai_avis_versement_retraite_invalide_files`;

  const situationFieldArrayMapping = {
    ressSalCdiContratAttestationTravailFilesFields: useFieldArray({
      control,
      name: filesName.ressSalCdiContratAttestationTravailFilesName,
    }),
    ressSalCdiBulletinSalaireFilesFields: useFieldArray({
      control,
      name: filesName.ressSalCdiBulletinSalaireFilesName,
    }),
    ressSalCdiCertificatTravailFilesFields: useFieldArray({
      control,
      name: filesName.ressSalCdiCertificatTravailFilesName,
    }),
    ressSalIntContratAttestationTravailFilesFields: useFieldArray({
      control,
      name: filesName.ressSalIntContratAttestationTravailFilesName,
    }),
    ressSalIntCertificatTravailFilesFields: useFieldArray({
      control,
      name: filesName.ressSalIntCertificatTravailFilesName,
    }),
    ressSalCddContratAttestationTravailFilesFields: useFieldArray({
      control,
      name: filesName.ressSalCddContratAttestationTravailFilesName,
    }),
    ressSalCddCertificatTravailFilesFields: useFieldArray({
      control,
      name: filesName.ressSalCddCertificatTravailFilesName,
    }),
    ressSalCddBulletinSalaireFilesFields: useFieldArray({
      control,
      name: filesName.ressSalCddBulletinSalaireFilesName,
    }),
    ressArtisaExtraitInscriptionMetiersFilesFields: useFieldArray({
      control,
      name: filesName.ressArtisaExtraitInscriptionMetiersFilesName,
    }),
    ressArtisaBilanComptableResultatAttestationRevenuFilesFields: useFieldArray({
      control,
      name: filesName.ressArtisaBilanComptableResultatAttestationRevenuFilesName,
    }),
    ressDemEmpReleveSituationDroitsVersementsPeFilesFields: useFieldArray({
      control,
      name: filesName.ressDemEmpReleveSituationDroitsVersementsPeFilesName,
    }),
    ressProlibExtraitInscriptionSirenFilesFields: useFieldArray({
      control,
      name: filesName.ressProlibExtraitInscriptionSirenFilesName,
    }),
    ressProlibBilanComptableResultatAttestationRevenuFilesFields: useFieldArray({
      control,
      name: filesName.ressProlibBilanComptableResultatAttestationRevenuFilesName,
    }),
    ressCommerExtraitInscriptionRcsFilesFields: useFieldArray({
      control,
      name: filesName.ressCommerExtraitInscriptionRcsFilesName,
    }),
    ressCommerBilanComptableResultatAttestationRevenuFilesFields: useFieldArray({
      control,
      name: filesName.ressCommerBilanComptableResultatAttestationRevenuFilesName,
    }),
    ressAutentDeclarationActiviteCfeFilesFields: useFieldArray({
      control,
      name: filesName.ressAutentDeclarationActiviteCfeFilesName,
    }),
    ressAutentLivreRecettesAttestationRevenuFilesFields: useFieldArray({
      control,
      name: filesName.ressAutentLivreRecettesAttestationRevenuFilesName,
    }),
    ressInvaliAttributionPensionRetraiteInvaliditeFilesFields: useFieldArray({
      control,
      name: filesName.ressInvaliAttributionPensionRetraiteInvaliditeFilesName,
    }),
    ressInvaliAvisVersementRetraiteInvalideFilesFields: useFieldArray({
      control,
      name: filesName.ressInvaliAvisVersementRetraiteInvalideFilesName,
    }),
    ressRetraiAttributionPensionRetraiteInvaliditeFilesFields: useFieldArray({
      control,
      name: filesName.ressRetraiAttributionPensionRetraiteInvaliditeFilesName,
    }),
    ressRetraiAvisVersementRetraiteInvalideFilesFields: useFieldArray({
      control,
      name: filesName.ressRetraiAvisVersementRetraiteInvalideFilesName,
    }),
  };

  const customOnChange = (value: any) => {
    if (field.activite !== value) {
      for (const property in situationFieldArrayMapping) {
        situationFieldArrayMapping[property as keyof typeof situationFieldArrayMapping].replace([]);
      }
      console.log(field.uuid);
      dispatch(
        updateActivites({
          activite: value,
          uuid: field.uuid,
          documents: [],
        }),
      );
    } else {
      dispatch(updateActivites({ activite: value, uuid: field.uuid }));
    }
  };

  const checkStateOfActiviteFields = (file: string, activitieIndex: number) => {
    const splitFileName = file.split('.');
    const fileName = splitFileName[splitFileName.length - 1];
    if (fileStateArray[activitieIndex][fileName].length > 0) {
      return false;
    }
    return true;
  };

  const filesMapping = ResourcesMapping.getGeneralMapping().get_activity_files_field(
    bloc4Cms,
    uploadFilesParameters,
    situationFieldArrayMapping,
    lang,
    filesName,
  );

  // {
  //   ressSalCdiContratAttestationTravailFilesName,
  //   ressSalCdiBulletinSalaireFilesName,
  //   ressSalCdiCertificatTravailFilesName,
  //   ressSalIntContratAttestationTravailFilesName,
  //   ressSalIntCertificatTravailFilesName,
  //   ressSalCddContratAttestationTravailFilesName,
  //   ressSalCddCertificatTravailFilesName,
  //   ressSalCddBulletinSalaireFilesName,
  //   ressArtisaExtraitInscriptionMetiersFilesName,
  //   ressArtisaBilanComptableResultatAttestationRevenuFilesName,
  //   ressDemEmpReleveSituationDroitsVersementsPeFilesName,
  //   ressProlibExtraitInscriptionSirenFilesName,
  //   ressProlibBilanComptableResultatAttestationRevenuFilesName,
  //   ressCommerExtraitInscriptionRcsFilesName,
  //   ressCommerBilanComptableResultatAttestationRevenuFilesName,
  //   ressAutentDeclarationActiviteCfeFilesName,
  //   ressAutentLivreRecettesAttestationRevenuFilesName,
  //   ressInvaliAttributionPensionRetraiteInvaliditeFilesName,
  //   ressInvaliAvisVersementRetraiteInvalideFilesName,
  //   ressRetraiAttributionPensionRetraiteInvaliditeFilesName,
  //   ressRetraiAvisVersementRetraiteInvalideFilesName,
  // },

  if (resourcesDebug.all || resourcesDebug.resourcesSituation) {
    console.log(field);
  }

  return (
    <>
      <CommonRowContainer
        legend={index === 0 ? bloc4Cms.quelle_situation_professionnelle_actuelle : undefined}
        infobulleContent={
          index > 0
            ? cms.bloc4Cms.infobulle_titre_autres_profession_a_declarer
            : cms.bloc4Cms.infobulle_titre_quelle_situation_professionnelle
        }
        lang={lang}
        noSpacing={true}
      >
        <SelectInputContainer
          {...ResourcesMapping.getGeneralMapping().getActivities(
            { bloc4Cms, globalCms, demandeUtilitaire: utilitaireCms },
            index,
            control,
          )}
          customClass={
            index > 0
              ? 'fr-col-10 fr-mt-8v fr-mt-lg-0v fr-mt-md-0v fr-col-lg-4 fr-col-md-4'
              : undefined
          }
          customOnChange={customOnChange}
        />

        {index > 0 && (
          <Button
            iconId="fr-icon-delete-bin-line"
            className="delete-resources-button"
            priority="tertiary no outline"
            type="button"
            children=""
            onClick={() => {
              remove(index);
              dispatch(removeActivites(index));
            }}
          ></Button>
        )}
      </CommonRowContainer>
      <input {...register(`activities.${index}.uuid` as const)} type="hidden" />
      <input {...register(`activities.${index}.isNew` as const)} type="hidden" />
      <input {...register(`activities.${index}.sort` as const)} type="hidden" />

      {index === 0 && (
        <div key={`${field.activite}`}>
          {field.activite === 'activite_salarie_en_cdi' && (
            <>
              <CommonRowContainer
                className="fr-grid-row fr-col-12 fr-mt-8v"
                children={[
                  <UploadFiles
                    {...filesMapping.ressSalCdiContratAttestationTravailFilesFields.upload}
                    checkStateOfActiviteFields={checkStateOfActiviteFields(
                      filesMapping.ressSalCdiContratAttestationTravailFilesFields.upload.name,
                      index,
                    )}
                  />,
                ]}
              />
              <CommonRowContainer
                className="fr-grid-row fr-col-12 fr-mt-8v"
                children={[
                  <UploadFiles
                    {...filesMapping.ressSalCdiCertificatTravailFilesFields.upload}
                    checkStateOfActiviteFields={checkStateOfActiviteFields(
                      filesMapping.ressSalCdiCertificatTravailFilesFields.upload.name,
                      index,
                    )}
                  />,
                ]}
              />
              <CommonRowContainer
                className="fr-grid-row fr-col-12 fr-mt-8v"
                children={[
                  <UploadFiles
                    {...filesMapping.ressSalCdiBulletinSalaireFilesFields.upload}
                    checkStateOfActiviteFields={checkStateOfActiviteFields(
                      filesMapping.ressSalCdiBulletinSalaireFilesFields.upload.name,
                      index,
                    )}
                  />,
                ]}
              />
            </>
          )}
          {field.activite === 'activite_salarie_en_interim' && (
            <>
              <CommonRowContainer
                className="fr-grid-row fr-col-12 fr-mt-8v"
                children={[
                  <UploadFiles
                    {...filesMapping.ressSalIntContratAttestationTravailFilesFields.upload}
                    checkStateOfActiviteFields={checkStateOfActiviteFields(
                      filesMapping.ressSalIntContratAttestationTravailFilesFields.upload.name,
                      index,
                    )}
                  />,
                ]}
              />
              <CommonRowContainer
                className="fr-grid-row fr-col-12 fr-mt-8v"
                children={[
                  <UploadFiles
                    {...filesMapping.ressSalIntCertificatTravailFilesFields.upload}
                    checkStateOfActiviteFields={checkStateOfActiviteFields(
                      filesMapping.ressSalIntCertificatTravailFilesFields.upload.name,
                      index,
                    )}
                  />,
                ]}
              />
            </>
          )}
          {field.activite === 'activite_salarie_en_cdd' && (
            <>
              <CommonRowContainer
                className="fr-grid-row fr-col-12 fr-mt-8v"
                children={[
                  <UploadFiles
                    {...filesMapping.ressSalCddContratAttestationTravailFilesFields.upload}
                    checkStateOfActiviteFields={checkStateOfActiviteFields(
                      filesMapping.ressSalCddContratAttestationTravailFilesFields.upload.name,
                      index,
                    )}
                  />,
                ]}
              />
              <CommonRowContainer
                className="fr-grid-row fr-col-12 fr-mt-8v"
                children={[
                  <UploadFiles
                    {...filesMapping.ressSalCddCertificatTravailFilesFields.upload}
                    checkStateOfActiviteFields={checkStateOfActiviteFields(
                      filesMapping.ressSalCddCertificatTravailFilesFields.upload.name,
                      index,
                    )}
                  />,
                ]}
              />
              <CommonRowContainer
                className="fr-grid-row fr-col-12 fr-mt-8v"
                children={[
                  <UploadFiles
                    {...filesMapping.ressSalCddBulletinSalaireFilesFields.upload}
                    checkStateOfActiviteFields={checkStateOfActiviteFields(
                      filesMapping.ressSalCddBulletinSalaireFilesFields.upload.name,
                      index,
                    )}
                  />,
                ]}
              />
            </>
          )}
          {field.activite === 'activite_artisan' && (
            <>
              <CommonRowContainer
                className="fr-grid-row fr-col-12 fr-mt-8v"
                children={[
                  <UploadFiles
                    {...filesMapping.ressArtisaExtraitInscriptionMetiersFilesFields.upload}
                    checkStateOfActiviteFields={checkStateOfActiviteFields(
                      filesMapping.ressArtisaExtraitInscriptionMetiersFilesFields.upload.name,
                      index,
                    )}
                  />,
                ]}
              />
              <CommonRowContainer
                className="fr-grid-row fr-col-12 fr-mt-8v"
                children={[
                  <UploadFiles
                    {...filesMapping.ressArtisaBilanComptableResultatAttestationRevenuFilesFields
                      .upload}
                    checkStateOfActiviteFields={checkStateOfActiviteFields(
                      filesMapping.ressArtisaBilanComptableResultatAttestationRevenuFilesFields
                        .upload.name,
                      index,
                    )}
                  />,
                ]}
              />
            </>
          )}
          {field.activite === 'activite_demandeur_d_emploi' && (
            <CommonRowContainer
              className="fr-grid-row fr-col-12 fr-mt-8v"
              children={[
                <UploadFiles
                  {...filesMapping.ressDemEmpReleveSituationDroitsVersementsPeFilesFields.upload}
                  checkStateOfActiviteFields={checkStateOfActiviteFields(
                    filesMapping.ressDemEmpReleveSituationDroitsVersementsPeFilesFields.upload.name,
                    index,
                  )}
                />,
              ]}
            />
          )}
          {field.activite === 'activite_profession_liberale' && (
            <>
              <CommonRowContainer
                className="fr-grid-row fr-col-12 fr-mt-8v"
                children={[
                  <UploadFiles
                    {...filesMapping.ressProlibExtraitInscriptionSirenFilesFields.upload}
                    checkStateOfActiviteFields={checkStateOfActiviteFields(
                      filesMapping.ressProlibExtraitInscriptionSirenFilesFields.upload.name,
                      index,
                    )}
                  />,
                ]}
              />
              <CommonRowContainer
                className="fr-grid-row fr-col-12 fr-mt-8v"
                children={[
                  <UploadFiles
                    {...filesMapping.ressProlibBilanComptableResultatAttestationRevenuFilesFields
                      .upload}
                    checkStateOfActiviteFields={checkStateOfActiviteFields(
                      filesMapping.ressProlibBilanComptableResultatAttestationRevenuFilesFields
                        .upload.name,
                      index,
                    )}
                  />,
                ]}
              />
            </>
          )}
          {field.activite === 'activite_commercant' && (
            <>
              <CommonRowContainer
                className="fr-grid-row fr-col-12 fr-mt-8v"
                children={[
                  <UploadFiles
                    {...filesMapping.ressCommerExtraitInscriptionRcsFilesFields.upload}
                    checkStateOfActiviteFields={checkStateOfActiviteFields(
                      filesMapping.ressCommerExtraitInscriptionRcsFilesFields.upload.name,
                      index,
                    )}
                  />,
                ]}
              />
              <CommonRowContainer
                className="fr-grid-row fr-col-12 fr-mt-8v"
                children={[
                  <UploadFiles
                    {...filesMapping.ressCommerBilanComptableResultatAttestationRevenuFilesFields
                      .upload}
                    checkStateOfActiviteFields={checkStateOfActiviteFields(
                      filesMapping.ressCommerBilanComptableResultatAttestationRevenuFilesFields
                        .upload.name,
                      index,
                    )}
                  />,
                ]}
              />
            </>
          )}
          {field.activite === 'activite_auto_entrepreneur' && (
            <>
              <CommonRowContainer
                className="fr-grid-row fr-col-12 fr-mt-8v"
                children={[
                  <UploadFiles
                    {...filesMapping.ressAutentDeclarationActiviteCfeFilesFields.upload}
                    checkStateOfActiviteFields={checkStateOfActiviteFields(
                      filesMapping.ressAutentDeclarationActiviteCfeFilesFields.upload.name,
                      index,
                    )}
                  />,
                ]}
              />
              <CommonRowContainer
                className="fr-grid-row fr-col-12 fr-mt-8v"
                children={[
                  <UploadFiles
                    {...filesMapping.ressAutentLivreRecettesAttestationRevenuFilesFields.upload}
                    checkStateOfActiviteFields={checkStateOfActiviteFields(
                      filesMapping.ressAutentLivreRecettesAttestationRevenuFilesFields.upload.name,
                      index,
                    )}
                  />,
                ]}
              />
            </>
          )}
          {field.activite === 'activite_invalide' && (
            <>
              <CommonRowContainer
                className="fr-grid-row fr-col-12 fr-mt-8v"
                children={[
                  <UploadFiles
                    {...filesMapping.ressInvaliAttributionPensionRetraiteInvaliditeFilesFields
                      .upload}
                    checkStateOfActiviteFields={checkStateOfActiviteFields(
                      filesMapping.ressInvaliAttributionPensionRetraiteInvaliditeFilesFields.upload
                        .name,
                      index,
                    )}
                  />,
                ]}
              />
              <CommonRowContainer
                className="fr-grid-row fr-col-12 fr-mt-8v"
                children={[
                  <UploadFiles
                    {...filesMapping.ressInvaliAvisVersementRetraiteInvalideFilesFields.upload}
                    checkStateOfActiviteFields={checkStateOfActiviteFields(
                      filesMapping.ressInvaliAvisVersementRetraiteInvalideFilesFields.upload.name,
                      index,
                    )}
                  />,
                ]}
              />
            </>
          )}
          {field.activite === 'activite_retraite' && (
            <>
              <CommonRowContainer
                className="fr-grid-row fr-col-12 fr-mt-8v"
                children={[
                  <UploadFiles
                    {...filesMapping.ressRetraiAvisVersementRetraiteInvalideFilesFields.upload}
                    checkStateOfActiviteFields={checkStateOfActiviteFields(
                      filesMapping.ressRetraiAvisVersementRetraiteInvalideFilesFields.upload.name,
                      index,
                    )}
                  />,
                ]}
              />
              <CommonRowContainer
                className="fr-grid-row fr-col-12 fr-mt-8v"
                children={[
                  <UploadFiles
                    {...filesMapping.ressRetraiAttributionPensionRetraiteInvaliditeFilesFields
                      .upload}
                    checkStateOfActiviteFields={checkStateOfActiviteFields(
                      filesMapping.ressRetraiAttributionPensionRetraiteInvaliditeFilesFields.upload
                        .name,
                      index,
                    )}
                  />,
                ]}
              />
            </>
          )}
        </div>
      )}
      {index === 0 && (
        <CommonRowContainer
          legend={bloc4Cms.nouvelle_activite}
          lang={lang}
          infobulleContent={bloc4Cms.infobulle_titre_autres_profession_a_declarer}
          prefixClassName="radio-group-container"
          children={[
            <RadioButtonContainer
              large={true}
              {...ResourcesMapping.getGeneralMapping().getformFields(
                { bloc4Cms, globalCms },
                { setValue, control, trigger },
                lang,
              ).nouvelle_activite}
              orientation="horizontal"
              className="fr-col-12 fr-mt-0v fr-mt-lg-0v fr-mt-md-0v fr-col-lg-12 fr-col-md-12"
            />,
          ]}
        />
      )}
      {index > 0 && (
        <>
          <div key={`${field.activite}`}>
            {field.activite === 'activite_salarie_en_cdi' && (
              <CommonRowContainer
                className="fr-grid-row fr-col-12 fr-mt-8v"
                children={[
                  <UploadFiles
                    {...filesMapping.ressSalCdiBulletinSalaireFilesFields.upload}
                    checkStateOfActiviteFields={checkStateOfActiviteFields(
                      filesMapping.ressSalCdiBulletinSalaireFilesFields.upload.name,
                      index,
                    )}
                  />,
                ]}
              />
            )}
            {field.activite === 'activite_salarie_en_cdd' && (
              <CommonRowContainer
                className="fr-grid-row fr-col-12 fr-mt-8v"
                children={[
                  <UploadFiles
                    {...filesMapping.ressSalCddBulletinSalaireFilesFields.upload}
                    checkStateOfActiviteFields={checkStateOfActiviteFields(
                      filesMapping.ressSalCddBulletinSalaireFilesFields.upload.name,
                      index,
                    )}
                  />,
                ]}
              />
            )}
            {field.activite === 'activite_salarie_en_interim' && (
              <>
                <CommonRowContainer
                  className="fr-grid-row fr-col-12 fr-mt-8v"
                  children={[
                    <UploadFiles
                      {...filesMapping.ressSalIntContratAttestationTravailFilesFields.upload}
                      checkStateOfActiviteFields={checkStateOfActiviteFields(
                        filesMapping.ressSalIntContratAttestationTravailFilesFields.upload.name,
                        index,
                      )}
                    />,
                  ]}
                />
                <CommonRowContainer
                  className="fr-grid-row fr-col-12 fr-mt-8v"
                  children={[
                    <UploadFiles
                      {...filesMapping.ressSalIntCertificatTravailFilesFields.upload}
                      checkStateOfActiviteFields={checkStateOfActiviteFields(
                        filesMapping.ressSalIntCertificatTravailFilesFields.upload.name,
                        index,
                      )}
                    />,
                  ]}
                />
              </>
            )}
            {field.activite === 'activite_demandeur_d_emploi' && (
              <>
                <CommonRowContainer
                  className="fr-grid-row fr-col-12 fr-mt-8v"
                  children={[
                    <UploadFiles
                      {...filesMapping.ressDemEmpReleveSituationDroitsVersementsPeFilesFields
                        .upload}
                      checkStateOfActiviteFields={checkStateOfActiviteFields(
                        filesMapping.ressDemEmpReleveSituationDroitsVersementsPeFilesFields.upload
                          .name,
                        index,
                      )}
                    />,
                  ]}
                />
              </>
            )}
            {field.activite === 'activite_artisan' && (
              <>
                <CommonRowContainer
                  className="fr-grid-row fr-col-12 fr-mt-8v"
                  children={[
                    <UploadFiles
                      {...filesMapping.ressArtisaExtraitInscriptionMetiersFilesFields.upload}
                      checkStateOfActiviteFields={checkStateOfActiviteFields(
                        filesMapping.ressArtisaExtraitInscriptionMetiersFilesFields.upload.name,
                        index,
                      )}
                    />,
                  ]}
                />
                <CommonRowContainer
                  className="fr-grid-row fr-col-12 fr-mt-8v"
                  children={[
                    <UploadFiles
                      {...filesMapping.ressArtisaBilanComptableResultatAttestationRevenuFilesFields
                        .upload}
                      checkStateOfActiviteFields={checkStateOfActiviteFields(
                        filesMapping.ressArtisaBilanComptableResultatAttestationRevenuFilesFields
                          .upload.name,
                        index,
                      )}
                    />,
                  ]}
                />
              </>
            )}
            {field.activite === 'activite_commercant' && (
              <>
                <CommonRowContainer
                  className="fr-grid-row fr-col-12 fr-mt-8v"
                  children={[
                    <UploadFiles
                      {...filesMapping.ressCommerExtraitInscriptionRcsFilesFields.upload}
                      checkStateOfActiviteFields={checkStateOfActiviteFields(
                        filesMapping.ressCommerExtraitInscriptionRcsFilesFields.upload.name,
                        index,
                      )}
                    />,
                  ]}
                />
                <CommonRowContainer
                  className="fr-grid-row fr-col-12 fr-mt-8v"
                  children={[
                    <UploadFiles
                      {...filesMapping.ressCommerBilanComptableResultatAttestationRevenuFilesFields
                        .upload}
                      checkStateOfActiviteFields={checkStateOfActiviteFields(
                        filesMapping.ressCommerBilanComptableResultatAttestationRevenuFilesFields
                          .upload.name,
                        index,
                      )}
                    />,
                  ]}
                />
              </>
            )}
            {field.activite === 'activite_profession_liberale' && (
              <>
                <CommonRowContainer
                  className="fr-grid-row fr-col-12 fr-mt-8v"
                  children={[
                    <UploadFiles
                      {...filesMapping.ressProlibExtraitInscriptionSirenFilesFields.upload}
                      checkStateOfActiviteFields={checkStateOfActiviteFields(
                        filesMapping.ressProlibExtraitInscriptionSirenFilesFields.upload.name,
                        index,
                      )}
                    />,
                  ]}
                />
                <CommonRowContainer
                  className="fr-grid-row fr-col-12 fr-mt-8v"
                  children={[
                    <UploadFiles
                      {...filesMapping.ressProlibBilanComptableResultatAttestationRevenuFilesFields
                        .upload}
                      checkStateOfActiviteFields={checkStateOfActiviteFields(
                        filesMapping.ressProlibBilanComptableResultatAttestationRevenuFilesFields
                          .upload.name,
                        index,
                      )}
                    />,
                  ]}
                />
              </>
            )}
            {field.activite === 'activite_auto_entrepreneur' && (
              <>
                <CommonRowContainer
                  className="fr-grid-row fr-col-12 fr-mt-8v"
                  children={[
                    <UploadFiles
                      {...filesMapping.ressAutentDeclarationActiviteCfeFilesFields.upload}
                      checkStateOfActiviteFields={checkStateOfActiviteFields(
                        filesMapping.ressAutentDeclarationActiviteCfeFilesFields.upload.name,
                        index,
                      )}
                    />,
                  ]}
                />
                <CommonRowContainer
                  className="fr-grid-row fr-col-12 fr-mt-8v"
                  children={[
                    <UploadFiles
                      {...filesMapping.ressAutentLivreRecettesAttestationRevenuFilesFields.upload}
                      checkStateOfActiviteFields={checkStateOfActiviteFields(
                        filesMapping.ressAutentLivreRecettesAttestationRevenuFilesFields.upload
                          .name,
                        index,
                      )}
                    />,
                  ]}
                />
              </>
            )}
            {field.activite === 'activite_retraite' && (
              <>
                <CommonRowContainer
                  className="fr-grid-row fr-col-12 fr-mt-8v"
                  children={[
                    <UploadFiles
                      {...filesMapping.ressRetraiAttributionPensionRetraiteInvaliditeFilesFields
                        .upload}
                      checkStateOfActiviteFields={checkStateOfActiviteFields(
                        filesMapping.ressRetraiAttributionPensionRetraiteInvaliditeFilesFields
                          .upload.name,
                        index,
                      )}
                    />,
                  ]}
                />
                <CommonRowContainer
                  className="fr-grid-row fr-col-12 fr-mt-8v"
                  children={[
                    <UploadFiles
                      {...filesMapping.ressRetraiAvisVersementRetraiteInvalideFilesFields.upload}
                      checkStateOfActiviteFields={checkStateOfActiviteFields(
                        filesMapping.ressRetraiAvisVersementRetraiteInvalideFilesFields.upload.name,
                        index,
                      )}
                    />,
                  ]}
                />
              </>
            )}
            {field.activite === 'activite_invalide' && (
              <>
                <CommonRowContainer
                  className="fr-grid-row fr-col-12 fr-mt-8v"
                  children={[
                    <UploadFiles
                      {...filesMapping.ressInvaliAttributionPensionRetraiteInvaliditeFilesFields
                        .upload}
                      checkStateOfActiviteFields={checkStateOfActiviteFields(
                        filesMapping.ressInvaliAttributionPensionRetraiteInvaliditeFilesFields
                          .upload.name,
                        index,
                      )}
                    />,
                  ]}
                />
                <CommonRowContainer
                  className="fr-grid-row fr-col-12 fr-mt-8v"
                  children={[
                    <UploadFiles
                      {...filesMapping.ressInvaliAvisVersementRetraiteInvalideFilesFields.upload}
                      checkStateOfActiviteFields={checkStateOfActiviteFields(
                        filesMapping.ressInvaliAvisVersementRetraiteInvalideFilesFields.upload.name,
                        index,
                      )}
                    />,
                  ]}
                />
              </>
            )}
            {field.activite === 'activite_sans_activite' && <></>}
          </div>
          {total > 0 && index === total - 1 && getValues(`activities.${index}.activite`) !== '' && (
            <Button
              iconId="fr-icon-add-line"
              type="button"
              onClick={() => {
                const uuid = uuidv4();
                append({
                  activite: '',
                  uuid: uuid,
                  isNew: 'oui',
                  sort: total + 1,
                });
                const newRessource = {
                  activite: '',
                  id: uuid,
                  documents: [],
                  sort: total + 1,
                };
                dispatch(setActivites(newRessource));
              }}
              priority="secondary"
              className="fr-mt-4w"
            >
              {bloc4Cms.ajouter_une_situation}
            </Button>
          )}
        </>
      )}
    </>
  );
};

export default RessourceSituation;
