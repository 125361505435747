import { cmsEnrichedText } from 'components/Atoms/HtmlParsers/PageContentHtmlParser';

export const getTopBannerContent = (
  id: string,
  bloc1Cms: any,
  bloc2Cms: any,
  bloc3Cms: any,
  bloc4Cms: any,
) => {
  switch (id) {
    case '0':
      return {
        bannerTitle: bloc1Cms.principale_helpbox_top.titre,
        bannerContent: cmsEnrichedText(bloc1Cms.principale_helpbox_top.contenu),
        bannerLinkTitle: bloc1Cms.principale_helpbox_top.bouton_ou_lien,
        bannerLink: bloc1Cms.principale_helpbox_top.lien,
        infobulleContent: bloc1Cms.button_infobulle_en_savoir_plus,
      };
    case '1':
      return {
        bannerTitle: bloc2Cms.principale_helpbox_top.titre,
        bannerContent: cmsEnrichedText(bloc2Cms.principale_helpbox_top.contenu),
        bannerLinkTitle: bloc2Cms.principale_helpbox_top.bouton_ou_lien,
        bannerLink: bloc2Cms.principale_helpbox_top.lien,
      };
    case '2':
      return {
        bannerTitle: bloc3Cms.principale_helpbox_top.titre,
        bannerContent: cmsEnrichedText(bloc3Cms.principale_helpbox_top.contenu),
        bannerLinkTitle: bloc3Cms.principale_helpbox_top.bouton_ou_lien,
        bannerLink: bloc3Cms.principale_helpbox_top.lien,
      };
    case '3':
      return {
        bannerTitle: bloc4Cms.principale_helpbox_top.titre,
        bannerContent: cmsEnrichedText(bloc4Cms.principale_helpbox_top.contenu),
        bannerLinkTitle: bloc4Cms.principale_helpbox_top.bouton_ou_lien,
        bannerLink: bloc4Cms.principale_helpbox_top.lien,
        infobulleContent: bloc4Cms.button_infobulle_en_savoir_plus,
      };
    case '4':
      return {
        bannerTitle: false,
        bannerContent: false,
        bannerLink: false,
      };
    default:
      return {
        bannerTitle: bloc1Cms.principale_helpbox_top.titre,
        bannerContent: cmsEnrichedText(bloc1Cms.principale_helpbox_top.contenu),
        bannerLinkTitle: bloc1Cms.principale_helpbox_top.bouton_ou_lien,
        bannerLink: bloc1Cms.principale_helpbox_top.lien,
      };
  }
};
