import React from 'react';
import { useController } from 'react-hook-form';

import DateInput from 'components/Atoms/Forms/DateInput/DateInput';
import { computeIsValidDsfr } from 'helpers/app/ComponentsStatusHelper';

interface DateInputContainerProps {
  label: string | React.ReactNode;
  hintText?: string;
  stateRelatedMessage?: string;
  name: string;
  control: any;
  customClass?: string;
  customOnChange?: (value: any) => void;
}

const DateInputContainer: React.FC<DateInputContainerProps> = ({
  label,
  hintText,
  stateRelatedMessage,
  name,
  control,
  customClass,
  customOnChange,
}) => {
  const { field, fieldState } = useController({
    name,
    control,
  });
  return (
    <div className="fr-grid-row fr-col-12 fr-mt-4v">
      <div className={customClass ? customClass : 'fr-col-12 fr-col-lg-4 fr-col-md-4'}>
        <DateInput
          label={label}
          hintText={hintText}
          name={name}
          control={control}
          stateRelatedMessage={fieldState.error?.message ?? stateRelatedMessage}
          state={computeIsValidDsfr(fieldState.isTouched, fieldState.isDirty, fieldState.error)}
          field={field}
          customOnChange={customOnChange}
        />
      </div>
    </div>
  );
};

export default DateInputContainer;
