import { Features } from './features';
import { Envs } from 'constants/env';
import getEnv from 'services/env';

const isFeatureActivated: {
  [key in Features]: boolean | { [key in Envs]: boolean };
} = {
  [Features.SITUATION_FAMILIALE]: false,
  [Features.REGROUPEMENT_FAMILIAL]: {
    [Envs.LOCAL]: true,
    [Envs.R7]: true,
    [Envs.INT]: true,
    [Envs.PP]: true,
    [Envs.PROD]: true,
  },
  [Features.PROCEDURE_REGROUPEMENT_FAMILIAL]: {
    [Envs.LOCAL]: true,
    [Envs.R7]: true,
    [Envs.INT]: true,
    [Envs.PP]: true,
    [Envs.PROD]: true,
  },
  [Features.RETOUR_REINSERTION]: {
    [Envs.LOCAL]: true,
    [Envs.R7]: true,
    [Envs.INT]: true,
    [Envs.PP]: false,
    [Envs.PROD]: false,
  },
  [Features.CREATION_COMPTE]: {
    [Envs.LOCAL]: true,
    [Envs.R7]: true,
    [Envs.INT]: true,
    [Envs.PP]: true,
    [Envs.PROD]: true,
  },
  [Features.WIDGET_ZENDESK]: {
    [Envs.LOCAL]: true,
    [Envs.R7]: true,
    [Envs.INT]: false,
    [Envs.PP]: false,
    [Envs.PROD]: false,
  },
  [Features.KEYCLOAK_VERSION_22]: {
    [Envs.LOCAL]: true,
    [Envs.R7]: true,
    [Envs.INT]: false,
    [Envs.PP]: false,
    [Envs.PROD]: false,
  },
};

export const isFeatureActive = (feature: Features): boolean => {
  if (typeof isFeatureActivated[feature] === 'object') {
    const envObject = isFeatureActivated[feature] as { [key in Envs]: boolean };
    if (!getEnv('REACT_APP_ENV')) {
      return false;
    }
    return envObject[getEnv('REACT_APP_ENV') as Envs];
  }

  return isFeatureActivated[feature] as boolean;
};
