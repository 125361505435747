/* eslint-disable react/no-array-index-key */
import { CMSlistsofListType } from 'models/types/cms/demandeRF/type';
import { CMS_BASE_URL } from 'services/cmsClient';

import 'components/Molecules/DemandeRf/CallOutDocumentsInfos/CallOutDocumentsInfos.scss';

export const ListCMSWidgetDocumentsInfo = (
  CMSlistsofList: CMSlistsofListType[],
  validInstructions: boolean,
) => {
  // We guess that 1st element of table is "Valid"
  const validInstructionsTitle = CMSlistsofList[0].liste[0]?.titre_liste;

  const list = CMSlistsofList[0].liste
    .filter((element: CMSlistsofListType['liste'][0]) =>
      validInstructions
        ? element.titre_liste === validInstructionsTitle
        : element.titre_liste !== validInstructionsTitle,
    )
    .map((element: CMSlistsofListType['liste'][0]) => element.ligne);

  const resultList = list.map((instruction: React.ReactNode, index: number) => {
    return <li key={index}>{instruction}</li>;
  });

  const invalidInstructionsTitle = CMSlistsofList[0].liste.find(
    (element: CMSlistsofListType['liste'][0]) => element.titre_liste !== validInstructionsTitle,
  );

  const instructionTitle = validInstructions
    ? validInstructionsTitle
    : invalidInstructionsTitle?.titre_liste;

  return (
    <div
      className={
        validInstructions
          ? 'fr-text-default--success callout-instructions'
          : 'fr-text-default--error callout-instructions'
      }
    >
      <div>
        <span
          className={validInstructions ? 'fr-icon-checkbox-fill' : 'fr-icon-close-circle-fill'}
        ></span>
        <span className="text-of-instructions">{instructionTitle}</span>
      </div>
      <ul className="ul-callout">{resultList}</ul>
    </div>
  );
};

export const ValidInvalidCallOutGenerator = (CMSlistsofList: CMSlistsofListType[]) => {
  const validInstructions = ListCMSWidgetDocumentsInfo(CMSlistsofList, true);
  const invalidInstructions = ListCMSWidgetDocumentsInfo(CMSlistsofList, false);
  return (
    <div>
      {validInstructions}
      {invalidInstructions}
    </div>
  );
};

export const ListCMSWidgetDocumentsInfoInfobulle = (
  CMSlistsofList: CMSlistsofListType[],
  CMSlistsofListInfobulle: CMSlistsofListType[],
  lastListElement: string,
) => {
  // We guess that 1st element of table is "Valid"
  const validInstructionsTitle = CMSlistsofList[0].liste[0]?.titre_liste;

  const list = manageCMSlistsofListReturn(CMSlistsofList, validInstructionsTitle);
  const listOfInfobulleComponents = managelistOfInfobulleComponentsReturn(CMSlistsofListInfobulle);

  const mergedLists = list.flatMap((value, index) =>
    index >= 1
      ? [value, listOfInfobulleComponents[index - 1]]
      : [value, listOfInfobulleComponents[index]],
  );

  mergedLists[mergedLists.length - 1] = {
    ...list[list.length - 1],
    ...listOfInfobulleComponents[listOfInfobulleComponents.length - 1],
  };

  return manageInfobulleContentResult(
    mergedLists,
    validInstructionsTitle,
    CMSlistsofList,
    lastListElement,
  );
};

const manageCMSlistsofListReturn = (
  CMSlistsofList: CMSlistsofListType[],
  validInstructionsTitle: string[],
) => {
  return CMSlistsofList[0].liste.map((element: CMSlistsofListType['liste'][0]) => {
    return {
      isValidInstruction: element.titre_liste === validInstructionsTitle ? true : false,
      content: element.ligne,
    };
  });
};

const managelistOfInfobulleComponentsReturn = (CMSlistsofListInfobulle: CMSlistsofListType[]) => {
  return CMSlistsofListInfobulle.map((element: any) => {
    if (element.contenu) {
      return {
        type: 'text',
        content: element.contenu,
      };
    }

    return {
      type: 'img',
      content: {
        url: element.image.data.attributes.url,
        alt: element.balise_alt,
      },
    };
  });
};

const manageInfobulleContentResult = (
  mergedLists: any,
  validInstructionsTitle: string[],
  CMSlistsofList: CMSlistsofListType[],
  lastListElement: string,
) => {
  const invalidInstructionsTitle = CMSlistsofList[0].liste.find(
    (element: CMSlistsofListType['liste'][0]) => element.titre_liste !== validInstructionsTitle,
  );

  const invalidIndexBegin = mergedLists.findIndex(
    (element: any) => element.isValidInstruction === false,
  );

  const construct = mergedLists.map((element: any, index: number) => {
    if (index === 1 || index >= invalidIndexBegin) {
      if (element.type === 'img') {
        return (
          <img
            key={index}
            src={CMS_BASE_URL + element.content.url}
            alt={element.content.alt}
            className="img-callout-margin"
          />
        );
      }
      return (
        <>
          {index === invalidIndexBegin && (
            <div className="fr-icon-close-circle-fill fr-text-default--error title-callout-margin">
              <span className="text-of-instructions">{invalidInstructionsTitle?.titre_liste}</span>
            </div>
          )}
          <li
            className={
              element.isValidInstruction ? 'fr-text-default--success' : 'fr-text-default--error'
            }
            key={index}
          >
            {element.content}
          </li>
        </>
      );
    }
  });
  return (
    <ul className="callout-how-upload">
      <div className="fr-icon-checkbox-fill fr-text-default--success">
        <span className="text-of-instructions">{validInstructionsTitle}</span>
      </div>

      {construct}
      <li className="fr-text-default--error">{lastListElement}</li>
    </ul>
  );
};
