import {
  NON_DIGIT_REGEX,
  PARENTHESIS_EXIST_REGEX,
  PARENTHESIS_REGEX,
  PHONE_REGEX,
} from './regexHelper';

export function isDateInThePast(date: string) {
  const passedInDate = new Date(date);
  const today = new Date();
  return passedInDate < today;
}

export function isPossibleDate(date: string, minYear = '1900', maxYear = '2050') {
  const year = date.split('-')[0];
  return year >= minYear && year <= maxYear;
}

export function cleanYearInADate(date: string) {
  if (!date) return date;
  if (!date.includes('-')) return date;
  const year = date.split('-')[0];

  if (year.length > 4) {
    return year.substring(year.length - 4) + date.substring(4);
  }

  return date;
}

export const checkInternationalPhoneNumber = (number: string): boolean => {
  return PARENTHESIS_EXIST_REGEX.test(number)
    ? PHONE_REGEX.test(number.replace(NON_DIGIT_REGEX, '')) && PARENTHESIS_REGEX.test(number)
    : PHONE_REGEX.test(number.replace(NON_DIGIT_REGEX, ''));
};
