/* eslint-disable max-params */
import { UploadFilesParameters } from '../../../../models/types/app/uploadFiles';
import { delIdentityDocument, setIdentityDocuments } from '../../../../redux/demandeRf';

export const GetUploadFilesParameters = (
  demandeId: string,
  control: any,
  setValue: any,
  getValues: any,
  trigger: any,
  errors: any,
  watch: any,
  cmsContentUtilitaire: any,
): UploadFilesParameters => {
  return {
    demandeId: demandeId ?? '',
    formInfos: {
      key: 'identite',
      deleteFunction: delIdentityDocument,
      setFunction: setIdentityDocuments,
    },
    formControl: {
      control: control,
      setValue: setValue,
      getValues: getValues,
      trigger: trigger,
      errors: errors,
      watch: watch,
    },
    demandeRFUtilitairecms: cmsContentUtilitaire,
    formToSubmit: 'identite',
  };
};
