import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Card from '@codegouvfr/react-dsfr/Card';

import { getNewsContent } from 'helpers/cms/getNewsContent';
import { useGetOnlineServicesCmsCollectionQuery } from 'redux/rtk/cms/cmsCommon';
import { getCurrentLocale } from 'redux/locale/selectors';
import CollectionPageLayout from 'layouts/CollectionsPageLayout';
import FourCardContainer from 'components/Molecules/FourCardContainer/FourCardContainer';
import { setArticles, setServices } from 'redux/pages';
import { getArticles, getOnlineServices, getServicesPage } from 'redux/pages/selectors';
import { ARTICLES } from 'routes/path';
import Loader from 'components/Atoms/Loader/Loader';

const Services = () => {
  const lang = useSelector(getCurrentLocale) as string;
  const { articlesCms, articlesCmsError, isArticlesCmsLoading } = getNewsContent(lang);

  const {
    data: onlineServicesCms,
    error: onlineServicesCmsError,
    isLoading: isOnlineServicesCmsLoading,
  } = useGetOnlineServicesCmsCollectionQuery({
    locale: lang !== '' ? lang : 'fr',
  });

  const servicesPageStore = useSelector(getServicesPage);
  const articlesStore = useSelector(getArticles);
  const onlineServicesStore = useSelector(getOnlineServices);

  const dispatch = useDispatch();

  useEffect(() => {
    if (articlesCms.data && articlesCms.data.length > 1 && (articlesStore?.length ?? 0) <= 1) {
      dispatch(setArticles(articlesCms.data));
    }
  }, [articlesCms, dispatch, articlesStore]);

  useEffect(() => {
    if (onlineServicesCms) {
      dispatch(setServices(onlineServicesCms.data));
    }
  }, [onlineServicesCms, dispatch]);

  if (isArticlesCmsLoading || isOnlineServicesCmsLoading) {
    return <Loader />;
  }

  if (articlesCmsError || onlineServicesCmsError) {
    return <div>Redirect page error</div>;
  }

  return (
    <main>
      <CollectionPageLayout title={servicesPageStore.titre} className="services">
        {onlineServicesStore &&
          onlineServicesStore.map(el => {
            return (
              <div
                key={el.title}
                className={`fr-col-xs-4 fr-col-sm-12 fr-col-md-4 fr-col-lg-4 services-card`}
              >
                <Card
                  title={el.title}
                  imageAlt={el.image.alt ? el.image.alt : ''}
                  enlargeLink
                  imageUrl={el.image.src ? el.image.src : ''}
                  desc={el.description}
                  linkProps={{
                    href: el.link?.href,
                    target: '_blank',
                  }}
                />
              </div>
            );
          })}
      </CollectionPageLayout>
      {servicesPageStore.actualites && articlesStore && (
        <FourCardContainer
          className="news"
          title={servicesPageStore.actualites.titre}
          link={{
            href: ARTICLES,
            title: servicesPageStore.actualites.texte_lien,
          }}
          cards={articlesStore}
        />
      )}
    </main>
  );
};

export default Services;
