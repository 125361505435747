import {
  Accomodation,
  Conjoint,
  DocumentReformat,
  Enfant,
  Ressource,
  Union,
  Document,
} from 'models/types/api/rf';
import { formInfosProps } from 'models/types/app/uploadFiles';

export const isNewFunc = (currentValue: DocumentReformat): boolean => {
  return currentValue?.document?.id === null;
};

export const isError = (currentValue: DocumentReformat): boolean =>
  currentValue?.document?.statut === 'infected';

export const getDifference = (array1: Document[], array2: DocumentReformat[]): Document[] => {
  return array1.filter(object1 => {
    return !array2.some(object2 => {
      return object1.id === object2.document?.id;
    });
  });
};

export const hasError = (errors: any, name: string, index: number): string | undefined => {
  if (!errors) return;
  if (errors.ressources) {
    return errors.ressources[index] && errors.ressources[index][name]
      ? errors.ressources[index][name]
      : undefined;
  }
  return;
};

export const buildObjectFromForm = (
  formInfos: formInfosProps,
  demandeId: string,
  newDocument: Document,
):
  | Conjoint
  | Enfant
  | Union
  | Accomodation
  | Omit<Ressource, 'activite'>
  | { id: string; documents: Document[] } => {
  const document = {
    id: formInfos.id,
    documents: [newDocument],
  };
  switch (formInfos.key) {
    case 'conjoint':
      return document as Conjoint;
    case 'enfant':
      return document as Enfant;
    case 'union':
      return document as Union;
    case 'accomodation':
      return document as Accomodation;
    case 'ressource':
      return document as Omit<Ressource, 'activite'>;
    default:
      return {
        id: demandeId,
        documents: [newDocument],
      };
  }
};

export const getFilterKey = (resource: string): string => {
  switch (resource) {
    case 'conjoint':
      return 'demandeConjointId';
    case 'enfant':
      return 'demandeEnfantId';
    case 'union':
      return 'demandeUnionId';
    case 'logement':
      return 'demandeLogementId';
    case 'ressource':
      return 'demandeResourceId';
    default:
      return 'demandeId';
  }
};
