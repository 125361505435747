import { FC, useEffect, useState } from 'react';
import { skipToken } from '@reduxjs/toolkit/query/react';

import Loader from 'components/Atoms/Loader/Loader';
import { ProcedureType } from 'constants/procedure';
import { downloadFile } from 'helpers/api/download';
import { AppDocument } from 'models/types/api/entities';
import {
  useGetDocumentAsileQuery,
  useGetDocumentRfQuery,
  useGetDocumentRriQuery,
} from 'redux/rtk/api/apiDocuments';

type DocumentProps = {
  document: AppDocument;
  lang: string;
};

const Document: FC<DocumentProps> = ({ document: doc, lang }) => {
  const [documentToDownload, setDocumentToDownload] = useState<AppDocument | null>(null);

  const {
    data: documentRF,
    isLoading: isLoadingDocumentRF,
    isError: isErrorDocumentRF,
    error: errorDocumentRF,
  } = useGetDocumentRfQuery(
    documentToDownload && documentToDownload.procedure === ProcedureType.REGROUPEMENT_FAMILIAL
      ? documentToDownload.codeDocument
      : skipToken,
  );

  const {
    data: documentAsile,
    isLoading: isLoadingDocumentAsile,
    isError: isErrorDocumentAsile,
    error: errorDocumentAsile,
  } = useGetDocumentAsileQuery(
    documentToDownload && documentToDownload.procedure === ProcedureType.ASILE
      ? { codeDocument: documentToDownload.codeDocument, locale: lang !== '' ? lang : 'fr' }
      : skipToken,
  );

  const {
    data: documentRRI,
    isLoading: isLoadingDocumentRRI,
    isError: isErrorDocumentRRI,
    error: errorDocumentRRI,
  } = useGetDocumentRriQuery(
    documentToDownload && documentToDownload.procedure === ProcedureType.RETOUR_REINSERTION
      ? documentToDownload.codeDocument
      : skipToken,
  );

  useEffect(() => {
    if (documentRF && documentToDownload) {
      downloadFile(
        documentRF.contenu[0].document,
        documentToDownload.titre,
        documentToDownload.type,
      );
    }
  }, [documentRF, documentToDownload]);

  useEffect(() => {
    if (documentAsile && documentToDownload) {
      downloadFile(
        documentAsile.contenu[0].document,
        documentToDownload.titre,
        documentToDownload.type,
      );
    }
  }, [documentAsile, documentToDownload]);

  useEffect(() => {
    if (documentRRI && documentToDownload) {
      downloadFile(
        documentRRI.contenu[0].document,
        documentToDownload.titre,
        documentToDownload.type,
      );
    }
  }, [documentRRI, documentToDownload]);

  if (isErrorDocumentRF && errorDocumentRF) {
    console.error(errorDocumentRF);
    return <div>error </div>;
  }
  if (isErrorDocumentAsile && errorDocumentAsile) {
    console.error(errorDocumentAsile);
    return <div>error </div>;
  }
  if (isErrorDocumentRRI && errorDocumentRRI) {
    console.error(errorDocumentRRI);
    return <div>error </div>;
  }

  return (
    <div
      onClick={() => setDocumentToDownload(doc)}
      className="fr-card fr-enlarge-link fr-card--download fr-mb-3w"
    >
      <div className="fr-card__body">
        <div className="fr-card__content">
          <h3 className="fr-card__title">
            <a href={'#'}>{doc.titre}</a>
          </h3>
          <div className="fr-card__end">
            <p className="fr-card__detail">
              {doc.type ? `${doc.type.toUpperCase()} - ` : 'PDF - '}
              {doc.poids}
            </p>
            {isLoadingDocumentRF ||
              isLoadingDocumentAsile ||
              (isLoadingDocumentRRI && (
                <div>
                  <Loader />
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Document;
