import { cmsSlice } from './cmsSlice';

const cmsDocuments = cmsSlice.injectEndpoints({
  endpoints: build => ({
    getDocumentsCms: build.query<any, { locale: string; qs?: string }>({
      query: ({ locale }) => ({
        url: `/mes-document?locale=${locale}&populate=*`,
      }),
    }),
  }),
});

export const { useGetDocumentsCmsQuery } = cmsDocuments;
