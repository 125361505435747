import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type CookiesAcceptanceType = {
  facebookCookiesAccepted: boolean;
  twitterCookiesAccepted: boolean;
  youtubeCookiesAccepted: boolean;
  mixpanelCookiesAccepted: boolean;
};

export type CookiesState = Readonly<{
  registeredAt: number | null;
  cookiesAcceptance: CookiesAcceptanceType;
  hasUserRequestedCookiesPersonalization: boolean;
  shouldReloadPage: boolean;
}>;

const initialState: CookiesState = {
  registeredAt: null,
  cookiesAcceptance: {
    facebookCookiesAccepted: false,
    twitterCookiesAccepted: false,
    youtubeCookiesAccepted: false,
    mixpanelCookiesAccepted: true,
  },
  hasUserRequestedCookiesPersonalization: false,
  shouldReloadPage: false,
};

const cookiesSlice = createSlice({
  name: 'Cookies',
  initialState,
  reducers: {
    setCookies: (state, action: PayloadAction<CookiesAcceptanceType>) => {
      state.registeredAt = new Date().getTime();
      const cookieKeyList = Object.keys(state.cookiesAcceptance) as (keyof CookiesAcceptanceType)[];
      cookieKeyList.forEach(cookieKey => {
        state.cookiesAcceptance[cookieKey] = Boolean(action.payload[cookieKey]);
      });
      state.shouldReloadPage = true;
      state.hasUserRequestedCookiesPersonalization = false;
    },
    setAllCookiesValue: (state, action: PayloadAction<boolean>) => {
      state.registeredAt = new Date().getTime();
      const cookieKeyList = Object.keys(state.cookiesAcceptance) as (keyof CookiesAcceptanceType)[];
      cookieKeyList.forEach(cookieKey => {
        state.cookiesAcceptance[cookieKey] = action.payload;
      });
      state.shouldReloadPage = true;
      state.hasUserRequestedCookiesPersonalization = false;
    },
    requestCookiesPersonalization: state => {
      state.hasUserRequestedCookiesPersonalization = true;
    },
  },
});

export const { setCookies, setAllCookiesValue, requestCookiesPersonalization } =
  cookiesSlice.actions;
export default cookiesSlice.reducer;
