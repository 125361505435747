import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { getAccomodationValidationSchema } from './Accomodation.validationSchema';
import { AccomodationDefaultValues } from './Accomodation.defaultValues';
import {
  DemandeRfBloc3,
  DemandeRfErrors,
  DemandeRfUtilitaire,
} from 'models/types/cms/demandeRF/type';

const GetAccomodationUseForm = (
  erreursRfCms: DemandeRfErrors,
  bloc3Cms: DemandeRfBloc3,
  demandeUtilitaire: DemandeRfUtilitaire,
) => {
  const AccomodationValidationSchema = useMemo(
    () => getAccomodationValidationSchema(erreursRfCms, bloc3Cms, demandeUtilitaire),
    [],
  );

  const {
    handleSubmit,
    getValues,
    setValue,
    getFieldState,
    trigger,
    watch,
    setError,
    formState: { isValid, errors, touchedFields },
    control,
  } = useForm({
    resolver: yupResolver(AccomodationValidationSchema),
    defaultValues: {
      ...AccomodationDefaultValues,
      // [HONEYPOT_NAME]: '',
    },
    mode: 'onChange',
  });

  return {
    handleSubmit,
    getValues,
    setValue,
    getFieldState,
    trigger,
    watch,
    setError,
    formState: { isValid, errors, touchedFields },
    control,
  };
};

export default GetAccomodationUseForm;
