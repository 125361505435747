import React from 'react';
import CallOut from '@codegouvfr/react-dsfr/CallOut';
import { createModal } from '@codegouvfr/react-dsfr/Modal';

import { getRfCmsContents } from 'pages/DemandeRF/Helpers/Cms/getRfCmsContents';
import { ValidInvalidCallOutGenerator } from 'helpers/cms/componentsHelper';

import InfobulleCallOutDocumentsInfos from 'components/Molecules/DemandeRf/CallOutDocumentsInfos/InfobulleCallOutDocumentsInfos';
interface CallOutDocumentsInfosProps {
  lang: string;
  infobulleDocumentsModal: ReturnType<typeof createModal>;
}

const CallOutDocumentsInfos: React.FC<CallOutDocumentsInfosProps> = ({
  lang,
  infobulleDocumentsModal,
}) => {
  const { globalCms, bloc1Cms } = getRfCmsContents(lang);

  return (
    <>
      <CallOut
        buttonProps={{
          children: globalCms.en_savoir_plus,
          type: 'button',
          onClick: () => infobulleDocumentsModal.open(),
        }}
        className="fr-col-12"
        iconId="ri-information-line"
        title={bloc1Cms.bulle_aide_comment_deposer_document.titre}
      >
        {bloc1Cms.bulle_aide_comment_deposer_document.contenu}
        {ValidInvalidCallOutGenerator(globalCms.bulle_comment_deposer_document_a_faire_pas_faire)}
      </CallOut>
      <infobulleDocumentsModal.Component title={bloc1Cms.bulle_aide_comment_deposer_document.titre}>
        <InfobulleCallOutDocumentsInfos lang={lang} />
      </infobulleDocumentsModal.Component>
    </>
  );
};

export default CallOutDocumentsInfos;
