/* eslint-disable complexity */
import parse, { DOMNode, domToReact, Element } from 'html-react-parser';

const handleHtmlElement = (domNode: Element, isParagraph?: boolean) => {
  let finalName = domNode.name;
  if (!domNode.next && isParagraph) {
    finalName = 'lastofpage-p';
  }
  if (domNode.name === 'figure') {
    manageTableStyle(domNode);
  }
  switch (finalName) {
    case 'ul':
      return <ul className="fr-ml-4v">{domToReact(domNode.children)}</ul>;
    case 'td':
      return <td className="custom-tab-style">{domToReact(domNode.children)}</td>;
    case 'h5':
      return <h5>{domToReact(domNode.children)}</h5>;
    case 'h4':
      return <h4>{domToReact(domNode.children)}</h4>;
    case 'h3':
      return <h3>{domToReact(domNode.children)}</h3>;
    case 'h2':
      return <h2 className="fr-mt-10v">{domToReact(domNode.children)}</h2>;
    case 'p':
      return <p>{domToReact(domNode.children)}</p>;
    case 'lastofpage-p':
      return <p className="fr-mb-0">{domToReact(domNode.children)}</p>;
    default:
      return <>{domToReact(domNode.children)}</>;
  }
};

const manageTableStyle = (domNode: Element) => {
  if (domNode.children[0] instanceof Element) {
    const firstChild = domNode.children[0] as Element;
    if (firstChild.children[0] instanceof Element) {
      const secondChild = firstChild.children[0] as Element;
      if (secondChild.children !== undefined) {
        Object.keys(secondChild.children as Element[]).forEach((key: string) => {
          const thirdChild = secondChild.children[
            key as keyof typeof secondChild.children
          ] as Element;
          if (thirdChild.children !== undefined) {
            Object.keys(thirdChild.children).forEach((key2: string) => {
              const fourthChild = thirdChild.children[
                key2 as keyof typeof thirdChild.children
              ] as Element;
              if (fourthChild.attribs !== undefined) {
                fourthChild.attribs.class = 'custom-tab-style';
              }
            });
          }
        });
      }
    }
  }
  return <>{domToReact(domNode.children)}</>;
};

export const PageContentHtmlParserFunction = (content: string, isParagraph?: boolean) => {
  const options = {
    replace: (domNode: DOMNode) => {
      if (domNode instanceof Element) {
        return handleHtmlElement(domNode, isParagraph);
      }
    },
  };

  return parse(content, options);
};

export const cmsEnrichedText = (content: string) => {
  if (!content) {
    return '';
  }
  const options = {
    replace: (domNode: DOMNode) => {
      if (domNode instanceof Element) {
        return handleHtmlElement(domNode, false);
      }
    },
  };

  return parse(content, options);
};
