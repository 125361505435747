import { CardProps } from '@codegouvfr/react-dsfr/Card';
import { FC } from 'react';

type CardWithoutLinkProps = Omit<CardProps, 'linkProps'> & {
  withBorder?: boolean;
};

const CardWithoutLink: FC<CardWithoutLinkProps> = ({ title, desc, className, imageUrl }) => {
  return (
    <div className={'fr-card ' + className}>
      <div className="fr-card__body">
        <div className="fr-card__content">
          <h3 className="fr-card__title">{title}</h3>
          <p className="fr-card__desc">{desc}</p>
          <div className="fr-card__start" />
          <div className="fr-card__end" />
        </div>
      </div>
      {imageUrl && (
        <div className="fr-card__header">
          <div className="fr-card__img">
            <img className="fr-responsive-img" src={imageUrl} aria-hidden={true} alt="" />
          </div>
        </div>
      )}
    </div>
  );
};

export default CardWithoutLink;
