import { RootState } from 'redux/reducers';
import { STATUT_OPC } from 'constants/demandeAsile';

export const shouldDisplayEspaceAsile = (statutDemande: STATUT_OPC) =>
  statutDemande === STATUT_OPC.ACCEPTEE;

export const getDemandeAsileEnCours = (state: RootState) =>
  null !== state.asile.demandeAsile.value &&
  shouldDisplayEspaceAsile(state.asile.demandeAsile.value.statutOPC)
    ? [state.asile.demandeAsile.value]
    : [];

export const getDemandeAsileTerminee = (state: RootState) =>
  null !== state.asile.demandeAsile.value &&
  !shouldDisplayEspaceAsile(state.asile.demandeAsile.value.statutOPC)
    ? [state.asile.demandeAsile.value]
    : [];

export const hasDemandeAsileBeenFetched = (state: RootState) =>
  state.asile.demandeAsile.hasBeenFetched;

export const hasAsile = (state: RootState): boolean => Boolean(state.asile.demandeAsile.value);
