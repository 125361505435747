/* eslint-disable max-params */
import * as yup from 'yup';

import { HONEYPOT_NAME } from 'constants/formulaire';
import { UNION_TYPE } from 'constants/regroupementFamiliale';
import { FormHelper } from 'helpers/app/FormHelper';
import { DemandeRfBloc2, DemandeRfErrors } from 'models/types/cms/demandeRF/type';

export const getUnionValidationSchema = (
  errorsRfCms: DemandeRfErrors,
  bloc2Cms: DemandeRfBloc2,
) => {
  const formHelper = new FormHelper(errorsRfCms);
  return yup.object().shape({
    union_precedente: yup.string().required(bloc2Cms.declarer_union_precedente),
    union_debut: yup
      .number()
      .typeError(errorsRfCms.corriger_annee)
      .required(errorsRfCms.erreur_date_annee)
      .min(1900, errorsRfCms.erreur_date_annee)
      .max(new Date().getFullYear(), errorsRfCms.erreur_date_annee),
    union_fin: yup
      .number()
      .typeError(errorsRfCms.corriger_annee)
      .required(errorsRfCms.erreur_date_annee)
      .min(1900, errorsRfCms.erreur_date_annee)
      .max(new Date().getFullYear(), errorsRfCms.erreur_date_annee)
      .test('bad_range', errorsRfCms.erreur_annee_debut_annee_fin, (value, context) => {
        if (value) {
          return value >= context.parent.union_debut;
        } else {
          return false;
        }
      }),
    union_jugement_divorce_files: yup.array().when('union_precedente', {
      is: (value: string) => value === UNION_TYPE.MARIE,
      then: () => formHelper.requiredFileSchema(),
      otherwise: () => formHelper.notRequiredFileSchema(),
    }),
    union_jugement_divorce_traduit: yup.boolean(),
    union_jugement_divorce_traduit_files: yup.array().when('union_jugement_divorce_traduit', {
      is: true,
      then: () => formHelper.requiredFileSchema(),
      otherwise: () => formHelper.notRequiredFileSchema(),
    }),
    attestetation_dissolution_pacs_files: yup.array().when('union_precedente', {
      is: (value: string) => value === UNION_TYPE.PACSE,
      then: () => formHelper.requiredFileSchema(),
      otherwise: () => formHelper.notRequiredFileSchema(),
    }),
    [HONEYPOT_NAME]: yup.string(),
  });
};
