/* eslint-disable complexity */
import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { Tabs } from '@codegouvfr/react-dsfr/Tabs';

import Loader from 'components/Atoms/Loader/Loader';
import AppBreadcrumb from 'components/Atoms/Breadcrumb/Breadcrumb';
import TopBannerDemandeRF from 'components/Molecules/TopBannerDemandeRF/TopBannerDemandeRF';
import { setDemande } from 'redux/demandeRf';
import { useGetDemandeQuery } from 'redux/rtk/api/apiRf';
import { useGetNationalitesNonEligiblesQuery } from 'redux/rtk/api/apiCommon';
import {
  getAccomodationIsUntouched,
  getAccomodationValidation,
  getCommitmentsIsUntouched,
  getCommitmentsValidation,
  getFamilyIsUntouched,
  getFamilyValidation,
  getIdentityIsUntouched,
  getIdentityValidation,
  getRessourcesIsUntouched,
  getRessourcesValidation,
} from 'redux/demandeRf/selectors';
import { getCurrentLocale } from 'redux/locale/selectors';

import { getTheTabs } from './Helpers/formManager';
import { formatDemandeForManagement, useTabStatus } from './DemandeRF.utils';
import { getRfCmsContents } from './Helpers/Cms/getRfCmsContents';
import './DemandeRF.scss';
import Button from '@codegouvfr/react-dsfr/Button';

const DemandeRF: FC = () => {
  const [selectedTabId, setSelectedTabId] = useState('0');
  const [isEligible, setIsEligible] = useState<boolean>(true);
  const [saveWhoAreYouProgress, setSaveWhoAreYouProgress] = useState<boolean>(false);
  const [saveFamilyProgress, setSaveFamilyProgress] = useState<boolean>(false);
  const [saveAccomodationProgress, setSaveAccomodationProgress] = useState<boolean>(false);
  const [saveRessourcesProgress, setSaveRessourcesProgress] = useState<boolean>(false);
  const [saveCommitmentsProgress, setSaveCommitmentsProgress] = useState<boolean>(false);

  const navigate = useNavigate();
  const lang = useSelector(getCurrentLocale) as string;
  const { demandeId } = useParams<{ demandeId: string }>();
  const dispatch = useDispatch();

  if (!demandeId)
    throw new Error(
      'This component should not be rendered outside a route with an demandeId param',
    );
  const { error: nationalitesNonEligiblesError, isLoading: isNationalitesNonEligiblesLoading } =
    useGetNationalitesNonEligiblesQuery();
  const { bloc1Cms, bloc2Cms, bloc3Cms, bloc4Cms, globalCms, isCmsLoading, isCmsOnError } =
    getRfCmsContents(lang);

  const {
    data: demande,
    isLoading: isDemandeLoading,
    isError: isErrorDemande,
  } = useGetDemandeQuery(demandeId);

  const identityStatus = useTabStatus(getIdentityValidation, getIdentityIsUntouched);
  const familyStatus = useTabStatus(getFamilyValidation, getFamilyIsUntouched);
  const accomodationStatus = useTabStatus(getAccomodationValidation, getAccomodationIsUntouched);
  const ressourcesStatus = useTabStatus(getRessourcesValidation, getRessourcesIsUntouched);
  const commitmentStatus = useTabStatus(getCommitmentsValidation, getCommitmentsIsUntouched);

  const tabsStatus = {
    IdentityStatus: identityStatus,
    FamilyStatus: familyStatus,
    AccomodationStatus: accomodationStatus,
    ResourcesStatus: ressourcesStatus,
    CommitmentStatus: commitmentStatus,
  };

  const scrollToTablist = () => {
    const tabList = document.querySelector('[role="tablist"]') as HTMLElement;

    window.scrollTo({ top: tabList?.offsetTop });
  };

  const onSaveClick = () => {
    switch (selectedTabId) {
      case '0':
        setSaveWhoAreYouProgress(true);
        break;
      case '1':
        setSaveFamilyProgress(true);
        break;
      case '2':
        setSaveAccomodationProgress(true);
        break;
      case '3':
        setSaveRessourcesProgress(true);
        break;
      case '4':
        setSaveCommitmentsProgress(true);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (demande) {
      dispatch(setDemande(formatDemandeForManagement(demandeId, demande)));
    }
  }, [demande]);

  if (isCmsLoading || !bloc1Cms || isDemandeLoading || isNationalitesNonEligiblesLoading) {
    return <Loader />;
  }

  if (isCmsOnError || nationalitesNonEligiblesError || isErrorDemande) {
    navigate('/erreur');
  }

  return (
    <main>
      <div className="fr-container demande-rf">
        <AppBreadcrumb />
        <h1 className="fr-mt-3w fr-mt-md-5w fr-mb-5w">{globalCms.titre_principal_demande_rf}</h1>
        <TopBannerDemandeRF
          selectedTabId={selectedTabId}
          cms={{ bloc1Cms, bloc2Cms, bloc3Cms, bloc4Cms }}
        />
        <Tabs
          tabs={getTheTabs({
            globalCms,
            lang,
            isEligible,
            setIsEligible,
            demandeId,
            tabsStatus,
            scrollToTablist,
            saveFormBehavior: {
              whoAreYou: {
                saveFormProgress: saveWhoAreYouProgress,
                setSaveFormProgress: setSaveWhoAreYouProgress,
              },
              family: {
                saveFormProgress: saveFamilyProgress,
                setSaveFormProgress: setSaveFamilyProgress,
              },
              accomodation: {
                saveFormProgress: saveAccomodationProgress,
                setSaveFormProgress: setSaveAccomodationProgress,
              },
              ressources: {
                saveFormProgress: saveRessourcesProgress,
                setSaveFormProgress: setSaveRessourcesProgress,
              },
              commitments: {
                saveFormProgress: saveCommitmentsProgress,
                setSaveFormProgress: setSaveCommitmentsProgress,
              },
            },
          })}
          onTabChange={(params: { tabIndex: number }) => {
            setSelectedTabId(params.tabIndex.toString());
          }}
        />
        <div className="fr-container fr-pt-2w fr-pb-2w sticky-container">
          <div className="fr-grid-row fr-mt-3w">
            <div className="fr-col">
              <div className="fr-grid-row fr-grid-row--left">
                <Button
                  className="mobile-large-button save-button"
                  iconId="fr-icon-save-line"
                  iconPosition="left"
                  type="button"
                  onClick={() => {
                    onSaveClick();
                  }}
                  priority="secondary"
                >
                  {globalCms.texte_bouton_enregistrer_demande}
                </Button>
              </div>
            </div>
            <div className="fr-col">
              <div className="fr-grid-row fr-grid-row--right fr-mt-2w go-top-container ">
                <a
                  href="#"
                  className="fr-link fr-link--icon-left fr-icon-arrow-up-line go-top-link"
                  onClick={e => {
                    e.preventDefault();
                    scrollToTablist();
                  }}
                >
                  {globalCms.retour_haut_de_page}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default DemandeRF;
