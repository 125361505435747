import { ACCEPT_MIME_TYPE } from 'constants/upload';

export const convertMimeTypeToExtension = (mimeType: string): string | null => {
  switch (mimeType) {
    case ACCEPT_MIME_TYPE.JPG:
      return 'jpg';
    case ACCEPT_MIME_TYPE.PDF:
      return 'pdf';
    case ACCEPT_MIME_TYPE.PNG:
      return 'png';
  }
  return null;
};
