import { FC, ReactNode } from 'react';

interface PartsContainerProps {
  className: string;
  title: string;
  description?: string;
  link?: {
    href: string;
    title: string;
  };
  children: ReactNode;
}

const PartContainer: FC<PartsContainerProps> = ({
  className,
  title,
  link,
  description,
  children,
}) => {
  return (
    <div className={`fr-container fr-mb-4w fr-mb-md-7w ${className}`}>
      <h2 className={`fr-mt-6w fr-mt-md-7w ${description ? 'fr-mb-5w' : 'fr-mb-3w'}`}>{title}</h2>
      {(description || link) && (
        <div className={`fr-grid-row ${className}-container__desc description-line fr-mb-md-3w`}>
          {description && (
            <p className="part-desc fr-mt-md-0 fr-mb-2w fr-mb-md-4w">{description}</p>
          )}
          {link && (
            <a
              className="fr-link fr-icon-arrow-right-line fr-link--icon-right fr-mb-3w fr-mb-md-4w fr-mt-0 "
              href={link.href}
            >
              {link.title}
            </a>
          )}
        </div>
      )}
      <div className="fr-grid-row fr-grid-row--gutters">{children}</div>
    </div>
  );
};

export default PartContainer;
