export const dataDocumentsAsile = {
  total: 6,
  contenu: [
    {
      titre: 'Notification à se présenter à un hébergement',
      codeDocument: '7703023985-notification_presentation_hebergement-pdf',
      categorie: 'notification_presentation_hebergement',
      priorite: 1,
      poids: '200 Ko',
    },
    {
      titre: 'Notification à se présenter en SPADA',
      codeDocument: '7703023985-notification_presentation_spada-pdf',
      categorie: 'notification_presentation_spada',
      priorite: 1,
      poids: '200 Ko',
    },
    {
      titre: "Attestation de versement de l'allocation pour demandeur d'asile",
      codeDocument: '7703023985-attestation_versement_allocation_demandeur_asile-pdf',
      categorie: 'attestation_versement_allocation_demandeur_asile',
      priorite: 8,
      poids: '200 Ko',
    },
    {
      titre: "Attestation d'hèbergement pour demandeur d'asile",
      codeDocument: '7703023985-attestation_hebergement_demandeur_asile-pdf',
      categorie: 'attestation_hebergement_demandeur_asile',
      priorite: 9,
      poids: '200 Ko',
    },
    {
      titre: 'Guide-du-demandeur-asile_nov2015.pdf',
      codeDocument: 'ofii-asile-1',
      categorie: 'livret_demandeur_asile',
      priorite: 5,
      poids: '6 Mo',
    },
    {
      titre: 'Notice-utilisation-de-la-carte-ADA.pdf',
      codeDocument: 'ofii-asile-2',
      categorie: 'notice_utilisation_carte_ada',
      priorite: 3,
      poids: '700 Ko',
    },
  ],
};

export const dataDocumentsRf = {
  total: 11,
  contenu: [
    {
      type: 'pdf',
      titre: '20231011-053035-etci-acte-naissance-conjoint-652631e417893',
      codeDocument: 3993,
      categorie: 'ECivil',
      poids: '299.79Ko',
    },
    {
      type: 'pdf',
      titre: '20231011-053135-loge-bail-complet-652632028c9e9',
      codeDocument: 3994,
      categorie: 'Logmt',
      poids: '299.79Ko',
    },
    {
      type: 'pdf',
      titre: '20231011-053235-loge-quittance-loyer-6526320606bc7',
      codeDocument: 3995,
      categorie: 'Logmt',
      poids: '299.79Ko',
    },
    {
      type: 'pdf',
      titre: '20231011-053335-loge-justificatif-domicile-65263208dfec5',
      codeDocument: 3996,
      categorie: 'Logmt',
      poids: '299.79Ko',
    },
    {
      type: 'pdf',
      titre: '20231011-053435-ress-salcdi-contrat-attestation-travail-6526322588278',
      codeDocument: 3997,
      categorie: 'Ress',
      poids: '299.79Ko',
    },
    {
      type: 'pdf',
      titre: '20231011-053535-ress-salcdi-certificat-travail-65263228adef5',
      codeDocument: 3998,
      categorie: 'Ress',
      poids: '299.79Ko',
    },
    {
      type: 'pdf',
      titre: '20231011-053636-ress-salcdi-bulletin-salaire-6526322bca80f',
      codeDocument: 3999,
      categorie: 'Ress',
      poids: '299.79Ko',
    },
    {
      type: 'png',
      titre: '20231011-053736-etci-acte-naissance-652543ca4965a',
      codeDocument: 4001,
      categorie: 'ECivil',
      poids: '69.68Ko',
    },
    {
      type: 'pdf',
      titre: '20231011-053836-sejo-titre-sejour-6526316b3c43c',
      codeDocument: 4002,
      categorie: 'S\u00e9jour',
      poids: '299.79Ko',
    },
    {
      type: 'pdf',
      titre: '20231011-053936-ress-dernier-avis-imposition-6526321f19c30',
      codeDocument: 4003,
      categorie: 'Ress',
      poids: '299.79Ko',
    },
    {
      type: 'pdf',
      titre: '20231011-053636-cerf_demande_rf-652634642ff52',
      codeDocument: 4000,
      categorie: 'CERFA_portail',
      poids: '132.06Ko',
    },
  ],
};

export const dataDocumentsRri = {
  total: 0,
  contenu: [],
};
