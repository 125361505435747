/* eslint-disable max-lines */
import React from 'react';

import CustomAlert from 'components/Atoms/CustomAlert/CustomAlert';
import CommonRowContainer from 'components/Molecules/Forms/CommonRowContainer/CommonRowContainer';
import DateInputContainer from 'components/Molecules/Forms/DateInputContainer/DateInputContainer';
import AutocompleteContainer from 'components/Molecules/Forms/AutocompleteContainer/AutocompleteContainer';
import RadioButtonContainer from 'components/Molecules/Forms/RadioButtonContainer/RadioButtonContainer';
import TextInputContainer from 'components/Molecules/Forms/TextInputContainer/TextInputContainer';
import { getRfCmsContents } from 'pages/DemandeRF/Helpers/Cms/getRfCmsContents';
import Loader from 'components/Atoms/Loader/Loader';
import {
  useGetUsagerListePaysQuery,
  useGetUsagerListeNationalitesQuery,
} from 'redux/rtk/api/apiCommon';
import { setDateNaissance } from 'redux/demandeRf';

import { WhoAreYouMapping } from '../WhoAreYou.mapping';

type IdentityPanelProps = {
  isMinor: boolean;
  lang: string;
  formControls: {
    setValue: any;
    control: any;
  };
  dispatch: any;
};

const IdentityPanel: React.FC<IdentityPanelProps> = ({
  isMinor,
  lang,
  formControls: { setValue, control },
  dispatch,
}) => {
  const { bloc1Cms, globalCms } = getRfCmsContents(lang);

  const {
    data: listePays,
    isError: errorPays,
    isFetching: loadingPays,
  } = useGetUsagerListePaysQuery();

  const {
    data: listeNationalite,
    isError: errorNationalite,
    isFetching: loadingNationalite,
  } = useGetUsagerListeNationalitesQuery();

  if (errorPays || errorNationalite) {
    return <div>Redirect page error</div>;
  }

  if (loadingPays || loadingNationalite) {
    return <Loader />;
  }

  return (
    <>
      <CommonRowContainer
        legend={bloc1Cms.vous_etes}
        infobulleContent={bloc1Cms.infobulle_vous_etes}
        lang={lang}
        children={[
          <RadioButtonContainer
            {...WhoAreYouMapping.getGeneralMapping().radio_gender(
              bloc1Cms,
              globalCms,
              {
                setValue,
                control,
              },
              dispatch,
            )}
          />,
        ]}
      />
      <CommonRowContainer
        legend={bloc1Cms.vous_appelez}
        infobulleContent={bloc1Cms.infobulle_vous_vous_appelez}
        lang={lang}
        children={[
          <TextInputContainer
            {...WhoAreYouMapping.getGeneralMapping().form_fields(bloc1Cms, control).nom_naissance}
          />,
          <TextInputContainer
            {...WhoAreYouMapping.getGeneralMapping().form_fields(bloc1Cms, control).nom_usage}
          />,
        ]}
      />
      <CommonRowContainer
        children={[
          <TextInputContainer
            {...WhoAreYouMapping.getGeneralMapping().form_fields(bloc1Cms, control).prenom}
          />,
        ]}
      />
      <CommonRowContainer
        legend={bloc1Cms.date_naissance.titre}
        infobulleContent={bloc1Cms.infobulle_vous_etes_nee_le}
        lang={lang}
        children={[
          <DateInputContainer
            {...WhoAreYouMapping.getGeneralMapping().form_fields(bloc1Cms, control).date_naissance}
            customOnChange={e => {
              dispatch(setDateNaissance(e));
            }}
          />,
        ]}
      />
      {isMinor ? (
        <div className="fr-col-12 fr-mt-md-2v fr-mt-lg-2v">
          <CustomAlert
            {...WhoAreYouMapping.getGeneralMapping().alerts_fields(bloc1Cms).alert_is_minor}
          />
        </div>
      ) : (
        <>
          <CommonRowContainer
            children={[
              <AutocompleteContainer
                {...WhoAreYouMapping.getGeneralMapping().form_fields(bloc1Cms, control, lang)
                  .pays_naissance}
                payload={listePays}
                source="API"
              />,

              <TextInputContainer
                {...WhoAreYouMapping.getGeneralMapping().form_fields(bloc1Cms, control)
                  .ville_naissance}
              />,
            ]}
          />
          <CommonRowContainer
            legend={bloc1Cms.titre_nationalite}
            infobulleContent={bloc1Cms.infobulle_vous_etes_de_nationalite}
            lang={lang}
            children={[
              <AutocompleteContainer
                {...WhoAreYouMapping.getGeneralMapping().form_fields(bloc1Cms, control, lang)
                  .nationalite}
                payload={listeNationalite}
                source="API"
              />,
            ]}
          />
        </>
      )}
    </>
  );
};

export default IdentityPanel;
