/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable complexity */
import {
  dashboardProceduresEnCoursQuery1,
  dashboardProceduresEnCoursQuery2,
  traductionApiRfQuery1,
  traductionApiRfQuery2,
  traductionApiRfQuery3,
  traductionApiRfQuery4,
  traductionApiRfQuery5,
  traductionApiRfQuery6,
  traductionApiRfQuery7,
  traductionApiRfQuery8,
} from './Dashboard.query';
import { useGetUserQuery } from 'redux/rtk/api/apiUser';
import {
  useGetDashboardCmsQuery,
  useGetRFCmsTraductionApiQuery,
  useGetRappelCmsQuery,
} from 'redux/rtk/cms/cmsDashboard';
import { useGetLayoutCmsPageQuery } from 'redux/rtk/cms/cmsCommon';
import {
  useGetDemandeRfsQuery,
  useGetDemandeRfCreationQuery,
  useGetDemandeRfSummaryQuery,
} from 'redux/rtk/api/apiRf';
import { getRfCmsContents } from 'pages/DemandeRF/Helpers/Cms/getRfCmsContents';
import { useGetDerniereQuery } from 'redux/rtk/api/apiDna';
import { useGetVersementsQuery } from 'redux/rtk/api/apiDna';
import { getNewsContent } from 'helpers/cms/getNewsContent';

export const getDashBoardContent = (lang: string) => {
  const { data: userData, error: userError, isLoading: isUserLoading } = useGetUserQuery();
  const { globalCms } = getRfCmsContents(lang);
  const { articlesCms, articlesCmsError, isArticlesCmsLoading } = getNewsContent(lang);

  const {
    data: rfCreation,
    error: rfCreationError,
    isLoading: isRfCreationLoading,
  } = useGetDemandeRfCreationQuery();

  const {
    data: versements,
    error: versementsError,
    isLoading: isVersementsLoading,
  } = useGetVersementsQuery(undefined);

  const {
    data: layoutCms,
    error: layoutCmsError,
    isLoading: isLayoutCmsLoading,
  } = useGetLayoutCmsPageQuery({
    locale: lang !== '' ? lang : 'fr',
  });

  const {
    data: dashboardCmsBase,
    error: dashboardCmsError,
    isLoading: isDashboardCmsLoading,
  } = useGetDashboardCmsQuery({
    locale: lang !== '' ? lang : 'fr',
  });

  const {
    data: dashboardCmsProceduresEnCours1,
    error: dashboardCmsProceduresEnCoursError1,
    isLoading: isDashboardCmsProceduresEnCoursLoading1,
  } = useGetDashboardCmsQuery({
    locale: lang !== '' ? lang : 'fr',
    qs: dashboardProceduresEnCoursQuery1,
  });

  const {
    data: dashboardCmsProceduresEnCours2,
    error: dashboardCmsProceduresEnCoursError2,
    isLoading: isDashboardCmsProceduresEnCoursLoading2,
  } = useGetDashboardCmsQuery({
    locale: lang !== '' ? lang : 'fr',
    qs: dashboardProceduresEnCoursQuery2,
  });

  const {
    data: rappelCms,
    error: rappelCmsError,
    isLoading: isRappelCmsLoading,
  } = useGetRappelCmsQuery({
    locale: lang !== '' ? lang : 'fr',
  });

  const {
    data: cmsRFTraductionAPI1,
    error: cmsRFTraductionAPI1Error,
    isLoading: isCmsRFTraductionAPI1Loading,
  } = useGetRFCmsTraductionApiQuery({
    locale: lang !== '' ? lang : 'fr',
    qs: traductionApiRfQuery1,
  });

  const {
    data: cmsRFTraductionAPI2,
    error: cmsRFTraductionAPI2Error,
    isLoading: isCmsRFTraductionAPI2Loading,
  } = useGetRFCmsTraductionApiQuery({
    locale: lang !== '' ? lang : 'fr',
    qs: traductionApiRfQuery2,
  });

  const {
    data: cmsRFTraductionAPI3,
    error: cmsRFTraductionAPI3Error,
    isLoading: isCmsRFTraductionAPI3Loading,
  } = useGetRFCmsTraductionApiQuery({
    locale: lang !== '' ? lang : 'fr',
    qs: traductionApiRfQuery3,
  });

  const {
    data: cmsRFTraductionAPI4,
    error: cmsRFTraductionAPI4Error,
    isLoading: isCmsRFTraductionAPI4Loading,
  } = useGetRFCmsTraductionApiQuery({
    locale: lang !== '' ? lang : 'fr',
    qs: traductionApiRfQuery4,
  });

  const {
    data: cmsRFTraductionAPI5,
    error: cmsRFTraductionAPI5Error,
    isLoading: isCmsRFTraductionAPI5Loading,
  } = useGetRFCmsTraductionApiQuery({
    locale: lang !== '' ? lang : 'fr',
    qs: traductionApiRfQuery5,
  });

  const {
    data: cmsRFTraductionAPI6,
    error: cmsRFTraductionAPI6Error,
    isLoading: isCmsRFTraductionAPI6Loading,
  } = useGetRFCmsTraductionApiQuery({
    locale: lang !== '' ? lang : 'fr',
    qs: traductionApiRfQuery6,
  });

  const {
    data: cmsRFTraductionAPI7,
    error: cmsRFTraductionAPI7Error,
    isLoading: isCmsRFTraductionAPI7Loading,
  } = useGetRFCmsTraductionApiQuery({
    locale: lang !== '' ? lang : 'fr',
    qs: traductionApiRfQuery7,
  });

  const {
    data: cmsRFTraductionAPI8,
    error: cmsRFTraductionAPI8Error,
    isLoading: isCmsRFTraductionAPI8Loading,
  } = useGetRFCmsTraductionApiQuery({
    locale: lang !== '' ? lang : 'fr',
    qs: traductionApiRfQuery8,
  });

  const {
    data: demandesRf,
    error: demandesRfErrors,
    isLoading: isDemandesRfLoading,
  } = useGetDemandeRfsQuery({});

  // const {
  //   data: fetchRriDossier,
  //   error: fetchRriDossierErrors,
  //   isLoading: isFetchRriDossierLoading,
  // } = useGetRriDossierQuery();
  // Extract : demandesReinsertions & retour from this call

  const {
    data: demandesRfSummary,
    error: demandesRfSummaryErrors,
    isLoading: isDemandesRfSummaryLoading,
  } = useGetDemandeRfSummaryQuery();

  const {
    data: asileDerniere,
    error: asileDerniereError,
    isLoading: isAsileDerniereLoading,
  } = useGetDerniereQuery();

  const cmsRFTraductionAPI = {
    ...cmsRFTraductionAPI1?.data?.attributes,
    clef_statuts: {
      ...cmsRFTraductionAPI2?.data?.attributes.clef_statuts,
      ...cmsRFTraductionAPI3?.data?.attributes.clef_statuts,
      ...cmsRFTraductionAPI4?.data?.attributes.clef_statuts,
      ...cmsRFTraductionAPI5?.data?.attributes.clef_statuts,
      ...cmsRFTraductionAPI6?.data?.attributes.clef_statuts,
      ...cmsRFTraductionAPI7?.data?.attributes.clef_statuts,
      ...cmsRFTraductionAPI8?.data?.attributes.clef_statuts,
    },
  };

  const dashboardCms = {
    ...dashboardCmsBase?.data?.attributes,
    ...dashboardCmsProceduresEnCours1?.data?.attributes,
    ...dashboardCmsProceduresEnCours2?.data?.attributes,
    procedures_en_cours: {
      ...dashboardCmsProceduresEnCours1?.data?.attributes.procedures_en_cours,
      ...dashboardCmsProceduresEnCours2?.data?.attributes.procedures_en_cours,
    },
  };

  const fetchHaveError =
    userError ||
    rfCreationError ||
    versementsError ||
    layoutCmsError ||
    articlesCmsError ||
    dashboardCmsError ||
    dashboardCmsProceduresEnCoursError1 ||
    dashboardCmsProceduresEnCoursError2 ||
    rappelCmsError ||
    cmsRFTraductionAPI1Error ||
    cmsRFTraductionAPI2Error ||
    cmsRFTraductionAPI3Error ||
    cmsRFTraductionAPI4Error ||
    cmsRFTraductionAPI5Error ||
    cmsRFTraductionAPI6Error ||
    cmsRFTraductionAPI7Error ||
    cmsRFTraductionAPI8Error ||
    demandesRfErrors ||
    demandesRfSummaryErrors ||
    asileDerniereError;

  const fetchIsLoading =
    isUserLoading ||
    isRfCreationLoading ||
    isVersementsLoading ||
    isLayoutCmsLoading ||
    isArticlesCmsLoading ||
    isDashboardCmsLoading ||
    isDashboardCmsProceduresEnCoursLoading1 ||
    isDashboardCmsProceduresEnCoursLoading2 ||
    isRappelCmsLoading ||
    isCmsRFTraductionAPI1Loading ||
    isCmsRFTraductionAPI2Loading ||
    isCmsRFTraductionAPI3Loading ||
    isCmsRFTraductionAPI4Loading ||
    isCmsRFTraductionAPI5Loading ||
    isCmsRFTraductionAPI6Loading ||
    isCmsRFTraductionAPI7Loading ||
    isCmsRFTraductionAPI8Loading ||
    isDemandesRfLoading ||
    isDemandesRfSummaryLoading ||
    isAsileDerniereLoading;

  return {
    userData,
    globalCms,
    rfCreation,
    versements,
    layoutCms,
    articlesCms,
    dashboardCms,
    rappelCms,
    cmsRFTraductionAPI,
    demandesRf,
    demandesRfSummary,
    asileDerniere,
    fetchIsLoading,
    fetchHaveError,
  };
};
