import React from 'react';
import { Download } from '@codegouvfr/react-dsfr/Download';

interface CustomDownloadProps {
  label: string | React.ReactNode;
  details: string;
  href: string;
}

const CustomDownload: React.FC<CustomDownloadProps> = ({ label, details, href }) => {
  return (
    <Download
      details={details}
      label={label}
      linkProps={{
        href: href,
        target: '_blank',
      }}
    />
  );
};

export default CustomDownload;
