import qs from 'qs';

export const globalQuery = qs.stringify({
  populate: {
    fields: ['titre_principal_demande_rf'],
    bulle_comment_deposer_document_a_faire_pas_faire: {
      populate: '*',
    },
    infobulle_encart_comment_envoyer_un_document: {
      populate: '*',
    },
    confirmation_suppression_titre: {
      populate: '*',
    },
    annee: {
      populate: '*',
    },
  },
});
