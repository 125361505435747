import { Logement } from 'models/types/api/rf';
import { FieldArrayList, UploadFilesParameters } from 'models/types/app/uploadFiles';
import { useFieldArray } from 'react-hook-form';
import { delAccomodationDocuments, setAccomodationDocuments } from 'redux/demandeRf';

export const filterLogement = (
  logements: Logement[] | undefined,
  principale: boolean,
): Logement | null => {
  if (logements !== undefined) {
    const arr: Logement[] | undefined = logements?.filter(function (obj) {
      if (principale) {
        return obj.principale;
      } else {
        return !obj.principale;
      }
    });
    return arr !== undefined && arr.length > 0 ? arr[0] : null;
  }
  return null;
};

export const generateBanoPrincipalForSubmit = (values: any) => {
  if (values.adresse_non_trouvee) {
    return {
      numero: '',
      adresse: '',
      codePostal: '',
      ville:
        values.ville_ou_commune !== undefined && values.ville_ou_commune !== null
          ? values.ville_ou_commune.city !== undefined
            ? values.ville_ou_commune.city
            : ''
          : '',
      codeVille:
        values.ville_ou_commune !== undefined && values.ville_ou_commune !== null
          ? values.ville_ou_commune.cityCode !== undefined
            ? values.ville_ou_commune.cityCode
            : null
          : null,
    };
  } else {
    return {
      numero:
        values.adresse_postale !== undefined && values.adresse_postale !== null
          ? values.adresse_postale.houseNumber !== undefined
            ? values.adresse_postale.houseNumber
            : ''
          : '',
      adresse:
        values.adresse_postale !== undefined && values.adresse_postale !== null
          ? values.adresse_postale.street !== undefined
            ? values.adresse_postale.street
            : ''
          : '',
      codePostal:
        values.adresse_postale !== undefined && values.adresse_postale !== null
          ? values.adresse_postale.postCode !== undefined
            ? values.adresse_postale.postCode
            : ''
          : '',
      ville:
        values.adresse_postale !== undefined && values.adresse_postale !== null
          ? values.adresse_postale.city !== undefined
            ? values.adresse_postale.city
            : ''
          : '',
      codeVille:
        values.adresse_postale !== undefined && values.adresse_postale !== null
          ? values.adresse_postale.cityCode !== undefined
            ? values.adresse_postale.cityCode
            : null
          : null,
    };
  }
};

export const generateBanoAutreForSubmit = (values: any) => {
  if (values.adresse_futur_logement_non_trouvee) {
    return {
      numero: '',
      adresse: '',
      codePostal: '',
      ville:
        values.ville_ou_commune_futur_logement !== undefined &&
        values.ville_ou_commune_futur_logement !== null
          ? values.ville_ou_commune_futur_logement.city !== undefined
            ? values.ville_ou_commune_futur_logement.city
            : ''
          : '',
      codeVille:
        values.ville_ou_commune_futur_logement !== undefined &&
        values.ville_ou_commune_futur_logement !== null
          ? values.ville_ou_commune_futur_logement.cityCode !== undefined
            ? values.ville_ou_commune_futur_logement.cityCode
            : null
          : null,
    };
  } else {
    return {
      numero:
        values.adresse_postale_futur_logement !== undefined &&
        values.adresse_postale_futur_logement !== null
          ? values.adresse_postale_futur_logement.houseNumber !== undefined
            ? values.adresse_postale_futur_logement.houseNumber
            : ''
          : '',
      adresse:
        values.adresse_postale_futur_logement !== undefined &&
        values.adresse_postale_futur_logement !== null
          ? values.adresse_postale_futur_logement.street !== undefined
            ? values.adresse_postale_futur_logement.street
            : ''
          : '',
      codePostal:
        values.adresse_postale_futur_logement !== undefined &&
        values.adresse_postale_futur_logement !== null
          ? values.adresse_postale_futur_logement.postCode !== undefined
            ? values.adresse_postale_futur_logement.postCode
            : ''
          : '',
      ville:
        values.adresse_postale_futur_logement !== undefined &&
        values.adresse_postale_futur_logement !== null
          ? values.adresse_postale_futur_logement.city !== undefined
            ? values.adresse_postale_futur_logement.city
            : ''
          : '',
      codeVille:
        values.adresse_postale_futur_logement !== undefined &&
        values.adresse_postale_futur_logement !== null
          ? values.adresse_postale_futur_logement.cityCode !== undefined
            ? values.adresse_postale_futur_logement.cityCode
            : null
          : null,
    };
  }
};

export const handleLogementData = (logement: any) => {
  const formattedLogement: { adresse: any; adresseNonTrouvee: any } = {
    adresse: undefined,
    adresseNonTrouvee: undefined,
  };

  if (logement && logement.adresseNonTrouvee === true) {
    formattedLogement.adresse = {
      houseNumber: ' ',
      street: '',
      postCode: '',
      city: '',
      cityCode: '',
      label: '',
      value: '',
    };
    formattedLogement.adresseNonTrouvee = {
      houseNumber: ' ',
      street: '',
      postCode: '',
      city: logement.adresse?.bano?.ville !== null ? logement.adresse?.bano?.ville : undefined,
      cityCode:
        logement.adresse?.bano?.codeVille !== null ? logement.adresse?.bano?.codeVille : undefined,
      label: `${logement.adresse?.bano?.ville}`,
      value: `${logement.adresse?.bano?.ville}`,
    };
  } else if (logement) {
    formattedLogement.adresse = {
      houseNumber:
        logement.adresse?.bano?.numero !== null ? logement.adresse?.bano?.numero : undefined,
      street:
        logement.adresse?.bano?.adresse !== null ? logement.adresse?.bano?.adresse : undefined,
      postCode:
        logement.adresse?.bano?.codePostal !== null
          ? logement.adresse?.bano?.codePostal
          : undefined,
      city: logement.adresse?.bano?.ville !== null ? logement.adresse?.bano?.ville : undefined,
      cityCode:
        logement.adresse?.bano?.codeVille !== null ? logement.adresse?.bano?.codeVille : undefined,
      label: `${logement.adresse?.bano?.numero} ${logement.adresse?.bano?.adresse} ${logement.adresse?.bano?.codePostal} ${logement.adresse?.bano?.ville}`,
      value: `${logement.adresse?.bano?.numero} ${logement.adresse?.bano?.adresse} ${logement.adresse?.bano?.codePostal} ${logement.adresse?.bano?.ville}`,
    };
    formattedLogement.adresseNonTrouvee = {
      houseNumber: ' ',
      street: '',
      postCode: '',
      city: '',
      cityCode: '',
      label: '',
      value: '',
    };
  }

  return formattedLogement;
};

export const GetUploadFilesParameters = (
  demandeId: string,
  id: string | undefined,
  formControl: {
    control: any;
    setValue: any;
    getValues: any;
    trigger: any;
    errors: any;
    watch: any;
  },
  cmsContentUtilitaire: any,
): UploadFilesParameters => {
  const { control, setValue, getValues, trigger, errors, watch } = formControl;
  return {
    demandeId: demandeId,
    formInfos: {
      key: 'accomodation',
      id: id,
      deleteFunction: delAccomodationDocuments,
      setFunction: setAccomodationDocuments,
    },
    formControl: {
      control: control,
      setValue: setValue,
      getValues: getValues,
      trigger: trigger,
      errors: errors,
      watch: watch,
    },
    demandeRFUtilitairecms: cmsContentUtilitaire,
    formToSubmit: 'accomodation',
  };
};

export const GetFieldArrayMapping = (control: any): FieldArrayList => {
  return {
    bail_complet_files: useFieldArray({
      control,
      name: 'bail_complet_files',
    }),
    quittance_loyer_files: useFieldArray({
      control,
      name: 'quittance_loyer_files',
    }),
    attestation_domicile_employeur_files: useFieldArray({
      control,
      name: 'attestation_domicile_employeur_files',
    }),
    attestation_mise_disposition_logement_files: useFieldArray({
      control,
      name: 'attestation_mise_disposition_logement_files',
    }),
    bail_quittance_hebergeant_files: useFieldArray({
      control,
      name: 'bail_quittance_hebergeant_files',
    }),
    engagement_sous_location_files: useFieldArray({
      control,
      name: 'engagement_sous_location_files',
    }),
    justificatif_acquisition_futur_logement_files: useFieldArray({
      control,
      name: 'justificatif_acquisition_futur_logement_files',
    }),
    justificatif_domicile_files: useFieldArray({
      control,
      name: 'justificatif_domicile_files',
    }),
    justificatif_hebergeant_files: useFieldArray({
      control,
      name: 'justificatif_hebergeant_files',
    }),
    promesse_location_files: useFieldArray({
      control,
      name: 'promesse_location_files',
    }),
    attestation_domicile_hebergeant_files: useFieldArray({
      control,
      name: 'attestation_domicile_hebergeant_files',
    }),
    attestation_hebergement_employeur_files: useFieldArray({
      control,
      name: 'attestation_hebergement_employeur_files',
    }),
    acte_propriete_files: useFieldArray({
      control,
      name: 'acte_propriete_files',
    }),
    bail_complet_autre_files: useFieldArray({
      control,
      name: 'bail_complet_autre_files',
    }),
    quittance_loyer_autre_files: useFieldArray({
      control,
      name: 'quittance_loyer_autre_files',
    }),
    attestation_domicile_employeur_autre_files: useFieldArray({
      control,
      name: 'attestation_domicile_employeur_autre_files',
    }),
    attestation_mise_disposition_logement_autre_files: useFieldArray({
      control,
      name: 'attestation_mise_disposition_logement_autre_files',
    }),
    bail_quittance_hebergeant_autre_files: useFieldArray({
      control,
      name: 'bail_quittance_hebergeant_autre_files',
    }),
    engagement_sous_location_autre_files: useFieldArray({
      control,
      name: 'engagement_sous_location_autre_files',
    }),
    justificatif_acquisition_futur_logement_autre_files: useFieldArray({
      control,
      name: 'justificatif_acquisition_futur_logement_autre_files',
    }),
    justificatif_domicile_autre_files: useFieldArray({
      control,
      name: 'justificatif_domicile_autre_files',
    }),
    justificatif_hebergeant_autre_files: useFieldArray({
      control,
      name: 'justificatif_hebergeant_autre_files',
    }),
    promesse_location_autre_files: useFieldArray({
      control,
      name: 'promesse_location_autre_files',
    }),
    attestation_domicile_hebergeant_autre_files: useFieldArray({
      control,
      name: 'attestation_domicile_hebergeant_autre_files',
    }),
    attestation_hebergement_employeur_autre_files: useFieldArray({
      control,
      name: 'attestation_hebergement_employeur_autre_files',
    }),
    acte_propriete_autre_files: useFieldArray({
      control,
      name: 'acte_propriete_autre_files',
    }),
  };
};

export const adresseKey = 'bano' || 'adresse2' || 'digicode' || 'interphone';
export const adresseNotFoundKey = 'numero' || 'adresse1' || 'codePostal' || 'ville';

export const getDocumentsByCategory = (category: string, documents: any[]) => {
  if (Array.isArray(documents)) {
    const documentsByCategories =
      documents.filter(
        obj =>
          obj.categorie === category &&
          obj.statut &&
          !['deleted_pending', 'deleted'].includes(obj.statut),
      ).length > 0
        ? documents.filter(
            obj =>
              obj.categorie === category &&
              obj.statut &&
              !['deleted_pending', 'deleted'].includes(obj.statut),
          )
        : [];
    return documentsByCategories.map(doc => ({ id: null, document: doc }));
  } else {
    return [];
  }
};
