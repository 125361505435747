import React from 'react';
import { useWatch } from 'react-hook-form';

import CommonRowContainer from 'components/Molecules/Forms/CommonRowContainer/CommonRowContainer';
import UploadFiles from 'components/Atoms/UploadFiles/UploadFiles';
import SelectInputContainer from 'components/Molecules/Forms/SelectInputContainer/SelectInputContainer';
import CustomCheckboxContainer from 'components/Molecules/Forms/CustomCheckboxContainer/CustomCheckboxContainer';
import { FILIATION } from 'constants/regroupementFamiliale';
import { getRfCmsContents } from 'pages/DemandeRF/Helpers/Cms/getRfCmsContents';

type EnfantFiliationProps = {
  lang: string;
  fieldArrayMapping: any;
  filesMapping: any;
  isNew: string;
  formFieldsMapping: any;
  control: any;
};

const EnfantFiliation: React.FC<EnfantFiliationProps> = ({
  lang,
  fieldArrayMapping,
  filesMapping,
  isNew,
  formFieldsMapping,
  control,
}) => {
  const { bloc2Cms, globalCms } = getRfCmsContents(lang);

  const filiation = useWatch({
    control,
    name: 'filiation',
  });

  const kafalaAlgerienTraduit = useWatch({
    control,
    name: 'kafala_algerien_traduit',
  });
  const jugementAdoptionTraduit = useWatch({
    control,
    name: 'jugement_adoption_traduit',
  });

  return (
    <>
      <div className="container fr-my-6v">
        <hr />
      </div>
      <CommonRowContainer
        legend={bloc2Cms.votre_lien_avec_enfant}
        infobulleContent={bloc2Cms.infobulle_selecteur_lien_avec_vous}
        infobulleCustomModal
        lang={lang}
        children={[
          <SelectInputContainer
            {...formFieldsMapping.filiation}
            defaultValue={globalCms.choisir_option_defaut}
            customClass="fr-col-12 fr-mt-4v fr-mt-lg-0v fr-mt-md-0v fr-col-sm-10"
          />,
        ]}
      />

      {filiation === FILIATION.KAFALA && (
        <>
          <UploadFiles
            {...filesMapping.kafala_algerien_files.upload}
            fieldArray={fieldArrayMapping.kafala_algerien_files}
            isNew={isNew}
            lang={lang}
            className="fr-mt-2w"
          />
          <CustomCheckboxContainer {...formFieldsMapping.kafala_algerien_traduit} large />
          {kafalaAlgerienTraduit && (
            <UploadFiles
              {...filesMapping.kafala_algerien_traduit_files.upload}
              fieldArray={fieldArrayMapping.kafala_algerien_traduit_files}
              isNew={isNew}
              lang={lang}
              className="fr-mt-2w"
            />
          )}
        </>
      )}

      {filiation === FILIATION.ADOPTIVE && (
        <>
          <UploadFiles
            {...filesMapping.jugement_adoption_files.upload}
            fieldArray={fieldArrayMapping.jugement_adoption_files}
            isNew={isNew}
            lang={lang}
            className="fr-mt-2w"
          />
          <CustomCheckboxContainer {...formFieldsMapping.jugement_adoption_traduit} large />
          {jugementAdoptionTraduit && (
            <UploadFiles
              {...filesMapping.jugement_adoption_traduit_files.upload}
              fieldArray={fieldArrayMapping.jugement_adoption_traduit_files}
              isNew={isNew}
              lang={lang}
              className="fr-mt-2w"
            />
          )}
        </>
      )}
    </>
  );
};

export default EnfantFiliation;
