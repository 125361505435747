import React, { useEffect, useState } from 'react';
import Button from '@codegouvfr/react-dsfr/Button';
import { createModal } from '@codegouvfr/react-dsfr/Modal';
import { useIsModalOpen } from '@codegouvfr/react-dsfr/Modal/useIsModalOpen';
import { useSelector, useDispatch } from 'react-redux';
import { useFieldArray } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';

import FormLayout from 'components/Molecules/DemandeRf/FormLayout/FormLayout';
import CustomAlert from 'components/Atoms/CustomAlert/CustomAlert';
import CommonRowContainer from 'components/Molecules/Forms/CommonRowContainer/CommonRowContainer';
import UploadFiles from 'components/Atoms/UploadFiles/UploadFiles';
import { FAMILY_TYPE, LIEN_PARENTE } from 'constants/regroupementFamiliale';
import { handleArrayHydratation, handleFieldHydratation } from 'helpers/api/FormHydratation';
import {
  Beneficiaire,
  Conjoint as ConjointType,
  Enfant as EnfantType,
  Union as UnionType,
} from 'models/types/api/rf';
import { FamilyDemandeForm } from 'models/types/app/demandeRf';
import 'pages/DemandeRF/DemandeRF.scss';
import { getRfCmsContents } from 'pages/DemandeRF/Helpers/Cms/getRfCmsContents';
import {
  getFamilyDocuments,
  getFamilyFormToSubmit,
  getListConjoints,
  getListEnfants,
  getListUnions,
} from 'redux/demandeRf/selectors';
import {
  useDelConjointMutation,
  useDelEnfantMutation,
  useDelUnionMutation,
  useUpdDemandeMutation,
} from 'redux/rtk/api/apiRf';
import {
  delConjointStore,
  delEnfantStore,
  delUnionStore,
  replaceFamilyDocuments,
  setConjointStore,
  setEnfantStore,
  setFamilyUntouched,
  setFamilyValidation,
  setUnionStore,
} from 'redux/demandeRf';
import { submitForm } from 'redux/forms';

import './Family.style.scss';
import FamilyUseForm from './components/FamilyUseForm';
import BeneficiaireTileInfo from './components/BeneficiaireTileInfo';
import BeneficiaireTileAddress from './components/BeneficiaireTileAddress';
import UnionTileInfo from './components/UnionTileInfo';
import { familyMapping } from './helpers/familyMapping';
import { getUploadFilesParameters } from './helpers/familyUploadFiles';
import { familyApiToAppFormatter } from './helpers/familyApiToAppFormatter';
import { getFamilyValidationSchema } from './helpers/familyValidationSchema';
import Conjoint from './Conjoint/Conjoint';
import Enfant from './Enfant/Enfant';
import Address from './Address/Address';
import Union from './Union/Union';

type FamilyProps = {
  lang: string;
  demandeId: string;
  saveFormProgress: boolean;
  setSaveFormProgress: React.Dispatch<React.SetStateAction<boolean>>;
  scrollToTablist: () => void;
};

const conjointModal = createModal({
  id: 'conjoint-modal',
  isOpenedByDefault: false,
});
const conjointAddressModal = createModal({
  id: 'conjoint-address-modal',
  isOpenedByDefault: false,
});
const enfantModal = createModal({
  id: 'enfant-modal',
  isOpenedByDefault: false,
});
const enfantAddressModal = createModal({
  id: 'enfant-address-modal',
  isOpenedByDefault: false,
});
const unionModal = createModal({
  id: 'union-modal',
  isOpenedByDefault: false,
});
const confirmRemoveModal = createModal({
  id: 'confirm-remove-modal',
  isOpenedByDefault: false,
});

// eslint-disable-next-line max-params
function useCombinedValidation(
  conjointFormValid: boolean,
  enfantFormValid: boolean,
  unionFormValid: boolean,
  beneficiaireRfDemandeAddress: boolean,
  beneficiaireRfDemande: boolean,
): boolean {
  return (
    conjointFormValid &&
    enfantFormValid &&
    unionFormValid &&
    beneficiaireRfDemandeAddress &&
    beneficiaireRfDemande
  );
}

const Family: React.FC<FamilyProps> = ({
  lang,
  demandeId,
  saveFormProgress,
  setSaveFormProgress,
  scrollToTablist,
}) => {
  const { bloc2Cms, errorCms, utilitaireCms, globalCms } = getRfCmsContents(lang);
  const dispatch = useDispatch();
  const conjoints = useSelector(getListConjoints);
  const enfants = useSelector(getListEnfants);
  const unions = useSelector(getListUnions);
  const [familyForm, setFamilyForm] = useState<FamilyDemandeForm | null>(null);
  const [conjointData, setConjointData] = useState<ConjointType | undefined>(undefined);
  const [enfantData, setEnfantData] = useState<EnfantType | undefined>(undefined);
  const [unionData, setUnionData] = useState<UnionType | undefined>(undefined);
  const [beneficiaire, setBeneficiaire] = useState<Beneficiaire | undefined>(undefined);
  const [objectToRemove, setObjectToRemove] = useState<{
    type: string;
    demandeId: string;
    id: string;
    uuid?: string;
  } | null>(null);
  const [delConjoint] = useDelConjointMutation();
  const [delEnfant] = useDelEnfantMutation();
  const [delUnion] = useDelUnionMutation();
  const {
    getValues,
    setValue,
    trigger,
    watch,
    formState: { errors, isValid },
    control,
    handleSubmit,
  } = FamilyUseForm(errorCms);

  const uploadFilesParameter = getUploadFilesParameters(
    demandeId ? demandeId : '',
    undefined,
    {
      control,
      setValue,
      getValues,
      trigger,
      errors,
      watch,
    },
    utilitaireCms,
  );
  const filesMapping = familyMapping
    .getGeneralMapping()
    .getFilesFields(bloc2Cms, uploadFilesParameter, errorCms);

  const fieldArrayMapping = {
    lettre_explication_rf_files: useFieldArray({
      control,
      name: 'lettre_explication_rf_files',
    }),
  };
  const [updDemande, { data: dataUpdated, isSuccess: isSuccessUpdated }] = useUpdDemandeMutation();

  const onSubmit = async (values: any) => {
    await updDemande({
      body: {},
      etape: 'votre-famille',
      demandeId: demandeId,
      isValidation: values.validation,
    });

    setSaveFormProgress(false);
  };

  const documents = useSelector(getFamilyDocuments);
  useEffect(() => {
    if (documents) {
      setFamilyForm(familyApiToAppFormatter(documents));
    }
  }, [documents]);

  const setValuesFromDemand = (formDemand: any) => {
    for (const key in formDemand) {
      if (Array.isArray(formDemand[key])) {
        if (formDemand[key].length > 0) {
          handleArrayHydratation(key, formDemand, fieldArrayMapping);
        }
      } else if (formDemand[key] !== null && formDemand[key] !== undefined) {
        handleFieldHydratation(key, formDemand, getFamilyValidationSchema(errorCms), setValue);
      }
    }
  };

  useEffect(() => {
    if (familyForm) {
      setValuesFromDemand(familyForm);
    }
  }, [familyForm]);

  const familyFormToSubmit = useSelector(getFamilyFormToSubmit);
  useEffect(() => {
    if (familyFormToSubmit) {
      onSubmitNoValidationAfterUpload();
      dispatch(submitForm('family'));
    }
  }, [familyFormToSubmit]);

  useEffect(() => {
    if (isSuccessUpdated && dataUpdated) {
      dispatch(replaceFamilyDocuments({ id: demandeId, documents: dataUpdated.documents }));
    }
  }, [isSuccessUpdated, dataUpdated]);

  const onSubmitNoValidationAfterUpload = () => {
    onSubmit({ validation: false });
  };

  useIsModalOpen(conjointAddressModal, {
    onConceal: () => setBeneficiaire(undefined),
  });
  useIsModalOpen(conjointModal, {
    onConceal: () => setConjointData(undefined),
  });
  useEffect(() => {
    if (conjointData) {
      conjointModal.open();
    }
  }, [conjointData]);

  useIsModalOpen(enfantAddressModal, {
    onConceal: () => setBeneficiaire(undefined),
  });
  useIsModalOpen(enfantModal, {
    onConceal: () => setEnfantData(undefined),
  });
  useEffect(() => {
    if (enfantData) {
      enfantModal.open();
    }
  }, [enfantData]);

  useIsModalOpen(unionModal, {
    onConceal: () => setUnionData(undefined),
  });
  useEffect(() => {
    if (unionData) {
      unionModal.open();
    }
  }, [unionData]);

  const newConjoint = () => {
    const uuid = uuidv4();
    const newConjoint: ConjointType = {
      id: uuid,
      uuid: uuid,
      documents: [],
      lienParente: LIEN_PARENTE.EPOUX,
      dejaMarie: null,
      titreSejourFr: null,
      divorceTrad: null,
      divorceOriginal: null,
      rfDemande: null,
      vitEnFrance: null,
    };
    dispatch(setConjointStore(newConjoint));
    setConjointData(newConjoint);
    if (conjointData) {
      conjointModal.open();
    }
  };

  const removeConjoint = ({
    demandeId,
    id,
    uuid,
  }: {
    demandeId: string;
    id: string;
    uuid?: string;
  }) => {
    setObjectToRemove({
      type: FAMILY_TYPE.CONJOINT,
      demandeId: demandeId,
      id: id,
      uuid: uuid,
    });
    confirmRemoveModal.open();
  };

  const newEnfant = () => {
    const uuid = uuidv4();
    const newEnfant: EnfantType = {
      id: uuid,
      uuid: uuid,
      documents: [],
      rfDemande: null,
      vitEnFrance: null,
    };
    dispatch(setEnfantStore(newEnfant));
    setEnfantData(newEnfant);
    if (enfantData) {
      enfantModal.open();
    }
  };

  const removeEnfant = ({
    demandeId,
    id,
    uuid,
  }: {
    demandeId: string;
    id: string;
    uuid?: string;
  }) => {
    setObjectToRemove({
      type: FAMILY_TYPE.ENFANT,
      demandeId: demandeId,
      id: id,
      uuid: uuid,
    });
    confirmRemoveModal.open();
  };

  const newUnion = () => {
    const uuid = uuidv4();
    const newUnion: UnionType = {
      id: uuid,
      uuid: uuid,
      documents: [],
    };
    dispatch(setUnionStore(newUnion));
    setUnionData(newUnion);
    if (unionData) {
      unionModal.open();
    }
  };

  const removeObject = () => {
    if (objectToRemove) {
      const { type, demandeId, id, uuid } = objectToRemove;
      switch (type) {
        case FAMILY_TYPE.CONJOINT:
          dispatch(delConjointStore(id));
          if (!uuid) {
            delConjoint({ demandeId, id });
          }
          break;
        case FAMILY_TYPE.ENFANT:
          dispatch(delEnfantStore(id));
          if (!uuid) {
            delEnfant({ demandeId, id });
          }
          break;
        case FAMILY_TYPE.UNION:
          dispatch(delUnionStore(id));
          if (!uuid) {
            delUnion({ demandeId, id });
          }
          break;
      }
      setObjectToRemove(null);
    }
  };
  const removeUnion = ({
    demandeId,
    id,
    uuid,
  }: {
    demandeId: string;
    id: string;
    uuid?: string;
  }) => {
    setObjectToRemove({
      type: FAMILY_TYPE.UNION,
      demandeId: demandeId,
      id: id,
      uuid: uuid,
    });
    confirmRemoveModal.open();
  };

  const estValide = (obj: Beneficiaire) => {
    if (obj.hasOwnProperty('estValide')) {
      return obj.estValide === false;
    } else {
      return false;
    }
  };

  const rfDemandeAddress = (obj: Beneficiaire) => {
    if (!obj.hasOwnProperty('rfDemande')) {
      return true;
    }
    if (obj.rfDemande === false) {
      return true;
    }
    return obj.rfDemande === true && obj.adresse !== null;
  };
  const beneficiaires = [...conjoints, ...enfants];

  const globalValidation = useCombinedValidation(
    conjoints.length > 0 ? !conjoints.some(estValide) : true,
    enfants.length > 0 ? !enfants.some(estValide) : true,
    unions.length > 0 ? !unions.some(estValide) : true,
    beneficiaires.length > 0 ? beneficiaires.every(rfDemandeAddress) : false,
    beneficiaires.length > 0 ? beneficiaires.some(beneficiaire => beneficiaire.rfDemande) : false,
  );

  let enfantsSorted: EnfantType[] = [];
  if (enfants.length > 0) {
    enfantsSorted = enfants.slice().sort((a, b) => Number(b.rfDemande) - Number(a.rfDemande));
  }

  useEffect(() => {
    const isUntouched = conjoints.length === 0 && enfants.length === 0 && unions.length === 0;
    dispatch(setFamilyUntouched(isUntouched));
  }, [conjoints, enfants, unions]);

  useEffect(() => {
    dispatch(setFamilyValidation(globalValidation));
  }, [globalValidation]);

  useEffect(() => {
    if (saveFormProgress) {
      onSubmit({ ...control._formValues, validation: false });
    }
  }, [saveFormProgress]);

  return (
    <>
      <FormLayout
        formIdentifier="family-form"
        title={bloc2Cms.titre_principal}
        requiredInfos={true}
        lang={lang}
        onSubmit={onSubmit}
        control={control}
        handleSubmit={handleSubmit}
        trigger={trigger}
        isValid={isValid}
        scrollToTablist={scrollToTablist}
        errors={errors}
      >
        <div className="fr-col-12 fr-mt-md-1w">
          <CustomAlert {...familyMapping.getGeneralMapping().getAlertsFields(bloc2Cms).alert_top} />
        </div>

        <CommonRowContainer
          legend={bloc2Cms.qui_est_votre_conjoint}
          infobulleContent={bloc2Cms.infobulle_base_qui_est_votre_conjoint}
          lang={lang}
          prefixClassName="fr-icon-user-heart-line flex-element-4"
          children={[<p className="fr-mt-2v">{bloc2Cms.infos_si_conjoint}</p>]}
        />

        {conjoints.filter(conjoint => !conjoint.uuid).length > 0 ? (
          <div className="fr-container--fluid">
            <div className="fr-grid-row fr-grid-row--gutters">
              {conjoints
                .filter(conjoint => !conjoint.uuid)
                .map(conjoint => (
                  <div className="fr-col-md-4 fr-col-12">
                    <BeneficiaireTileInfo
                      key={`beneficiaire-tile-info-${conjoint.id}`}
                      beneficiaire={conjoint}
                      setBeneficiaire={setConjointData}
                      delBeneficiaire={removeConjoint}
                      demandeId={demandeId}
                      lang={lang}
                    />
                    {conjoint.rfDemande && (
                      <BeneficiaireTileAddress
                        key={`beneficiaire-tile-address-${conjoint.id}`}
                        beneficiaire={conjoint}
                        type={FAMILY_TYPE.CONJOINT}
                        lang={lang}
                        onSetAddress={() => {
                          setBeneficiaire(conjoint);
                          conjointAddressModal.open();
                        }}
                      />
                    )}
                  </div>
                ))}
            </div>
          </div>
        ) : (
          <>
            <Button
              type="button"
              iconId="fr-icon-add-line"
              priority="secondary"
              onClick={() => newConjoint()}
            >
              {bloc2Cms.completer_les_informations}
            </Button>
          </>
        )}

        <div className="container fr-my-6v fr-my-lg-8v fr-my-md-8v">
          <hr />
        </div>

        <CommonRowContainer
          legend={bloc2Cms.qui_sont_enfants}
          infobulleContent={bloc2Cms.infobulle_base_qui_sont_vos_enfants}
          lang={lang}
          prefixClassName="fr-icon-parent-line flex-element-4"
          children={[<p className="fr-mt-2v">{bloc2Cms.completer_infos_si_enfant}</p>]}
        />
        {enfantsSorted.filter(enfant => !enfant.uuid).length > 0 && (
          <div className="fr-container--fluid">
            <div className="fr-grid-row fr-grid-row--gutters">
              {enfantsSorted
                .filter(enfant => !enfant.uuid)
                .map(enfant => (
                  <div className="fr-col-md-4 fr-col-12">
                    <BeneficiaireTileInfo
                      key={`beneficiaire-tile-info-${enfant.id}`}
                      beneficiaire={enfant}
                      setBeneficiaire={setEnfantData}
                      delBeneficiaire={removeEnfant}
                      demandeId={demandeId}
                      lang={lang}
                    />
                    {enfant.rfDemande && (
                      <BeneficiaireTileAddress
                        key={`beneficiaire-tile-address-${enfant.id}`}
                        beneficiaire={enfant}
                        type={FAMILY_TYPE.ENFANT}
                        lang={lang}
                        onSetAddress={() => {
                          setBeneficiaire(enfant);
                          enfantAddressModal.open();
                        }}
                      />
                    )}
                  </div>
                ))}
            </div>
          </div>
        )}
        <Button
          type="button"
          iconId="fr-icon-add-line"
          priority="secondary"
          className="fr-mt-md-3w fr-mb-md-4w fr-mt-2w fr-mb-3w"
          onClick={() => newEnfant()}
        >
          {bloc2Cms.btn_ajouter_enfant}
        </Button>

        <UploadFiles
          {...filesMapping.lettre_explication_rf_files.upload}
          fieldArray={fieldArrayMapping.lettre_explication_rf_files}
          isNew={'non'}
          lang={lang}
        />
        <div className="container fr-my-3w fr-my-md-4w">
          <hr />
        </div>

        <CommonRowContainer
          legend={bloc2Cms.deja_marie}
          infobulleContent={bloc2Cms.infobulle_base_avez_vous_ete_marie}
          lang={lang}
          prefixClassName="fr-icon-group-line flex-element-4"
          children={[<p className="fr-mt-2v">{bloc2Cms.declarer_precedentes_unions}</p>]}
        />
        {unions.filter(union => !union.uuid).length > 0 && (
          <div className="fr-container--fluid">
            <div className="fr-grid-row fr-grid-row--gutters">
              {unions
                .filter(union => !union.uuid)
                .map(union => (
                  <div className="fr-col-md-4 fr-col-12">
                    <UnionTileInfo
                      key={`union-tile-info-${union.id}`}
                      union={union}
                      setUnion={setUnionData}
                      delUnion={removeUnion}
                      demandeId={demandeId}
                      lang={lang}
                    />
                  </div>
                ))}
            </div>
          </div>
        )}
        <Button
          type={'button'}
          iconId="fr-icon-add-line"
          onClick={() => newUnion()}
          priority="secondary"
          className="fr-mt-md-3w fr-mb-md-4w fr-mt-2w fr-mb-3w"
        >
          {bloc2Cms.btn_ajouter_union}
        </Button>
      </FormLayout>

      <conjointModal.Component
        title={bloc2Cms.titre_renseigner_infos_conjoint}
        iconId="fr-icon-user-heart-line"
        className="family-modal"
      >
        {conjointData && (
          <Conjoint
            lang={lang}
            demandeId={demandeId}
            conjointInit={conjointData}
            conjointModal={conjointModal}
          />
        )}
      </conjointModal.Component>
      <conjointAddressModal.Component
        title={bloc2Cms.adresse_du_conjoint}
        iconId="fr-icon-home-4-line"
        className="family-modal"
      >
        {beneficiaire && (
          <Address
            beneficiaire={beneficiaire}
            demandeId={demandeId}
            addressModal={conjointAddressModal}
            type="conjoint"
            lang={lang}
          />
        )}
      </conjointAddressModal.Component>
      <enfantModal.Component
        title={bloc2Cms.titre_renseigner_infos_enfants}
        iconId="fr-icon-parent-line"
        className="family-modal"
      >
        {enfantData && (
          <Enfant
            lang={lang}
            demandeId={demandeId}
            enfantInit={enfantData}
            enfantModal={enfantModal}
          />
        )}
      </enfantModal.Component>
      <enfantAddressModal.Component
        title={bloc2Cms.adresse_enfant}
        iconId="fr-icon-home-4-line"
        className="family-modal"
      >
        {beneficiaire && (
          <Address
            beneficiaire={beneficiaire}
            demandeId={demandeId}
            addressModal={enfantAddressModal}
            type="enfant"
            lang={lang}
          />
        )}
      </enfantAddressModal.Component>
      <unionModal.Component
        title={bloc2Cms.declarer_union_precedente}
        iconId="fr-icon-group-line"
        className="family-modal"
      >
        {unionData && (
          <Union lang={lang} demandeId={demandeId} unionInit={unionData} unionModal={unionModal} />
        )}
      </unionModal.Component>
      <confirmRemoveModal.Component
        title={globalCms.confirmation_suppression_titre.titre}
        iconId="fr-icon-arrow-right-line"
        className="modal-content"
        buttons={[
          {
            doClosesModal: true,
            children: 'Non',
          },
          {
            iconId: 'ri-check-line',
            onClick: () => removeObject(),
            children: 'Oui',
          },
        ]}
      >
        <p>{globalCms.confirmation_suppression_titre.sous_titre}</p>
      </confirmRemoveModal.Component>
    </>
  );
};

export default Family;
