/* eslint-disable complexity */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Button from '@codegouvfr/react-dsfr/Button';

import { Document } from 'models/types/api/rf';
import { DocumentReformatClass } from 'models/class/DocumentReformat.class';
import { UploadFilesProps } from 'models/types/app/uploadFiles';
import { submitForm } from 'redux/forms';
import { getRfCmsContents } from 'pages/DemandeRF/Helpers/Cms/getRfCmsContents';

import CustomAlert from '../CustomAlert/CustomAlert';
import Uploader from './Uploader/Uploader';
import { buildObjectFromForm, isError, isNewFunc } from './uploadFiles.utils';
import { FormComponentsEnrichedText } from '../HtmlParsers/FormComponentHtmlParser';
import Infobulle from 'components/Molecules/Infobulle/Infobulle';
import InfobulleForCustomModal from '../../Molecules/Infobulle/InfobulleForCustomModal';
import { useGetUtilitaireCmsQuery } from 'redux/rtk/cms/cmsDemandeRF';
import { utilitaireQuery } from 'pages/DemandeRF/Queries';

const UploadFiles: FC<UploadFilesProps> = ({
  name,
  categorie,
  demandeId,
  label,
  lang = 'fr',
  hint,
  formInfos,
  formControl,
  formToSubmit,
  fieldArray,
  resource,
  isNew = 'non',
  fieldHasError,
  helpbox,
  stateRelatedMessage,
  defaultValue,
  className,
  infobulleContent,
  infobulleCustomModal,
  checkStateOfActiviteFields = false,
}) => {
  const [disableAddFile, setDisableAddFile] = useState<boolean>(false);
  const [fieldError, setFieldError] = useState<boolean>(false);
  const { errorCms } = getRfCmsContents(lang);
  const { data: utilitaireData } = useGetUtilitaireCmsQuery({
    qs: utilitaireQuery,
    locale: lang !== '' ? lang : 'fr',
  });

  const dispatch = useDispatch();

  const onSuccess = (newDocument: Document) => {
    setDisableAddFile(false);
    if (fieldArray.fields && newDocument) {
      if (formInfos.setFunction) {
        dispatch(formInfos.setFunction(buildObjectFromForm(formInfos, demandeId, newDocument)));
      }
    }
    if (formToSubmit) {
      dispatch(submitForm(formToSubmit));
    }

    formControl.trigger(name);
  };

  useEffect(() => {
    if (fieldArray.fields.length !== 0) {
      setDisableAddFile(fieldArray.fields.some(isNewFunc));
    } else {
      setDisableAddFile(true);
    }

    if (fieldArray.fields.some(isError) || fieldHasError) {
      setFieldError(true);
    } else {
      setFieldError(false);
    }
  }, [fieldArray.fields, fieldHasError]);

  useEffect(() => {
    if (formControl.errors[name] !== undefined) {
      setFieldError(true);
    } else {
      setFieldError(false);
    }
  });

  return (
    <div
      className={`upload-files ${className} ${
        checkStateOfActiviteFields || fieldError ? 'file-specific-error' : ''
      }`}
    >
      <div className="fr-grid-row">
        <span className={`fr-label fr-mb-4v ${fieldError ? 'fr-text-default--error' : ''}`}>
          {label}{' '}
        </span>
        {infobulleContent && lang !== undefined && (
          <>
            {infobulleCustomModal ? (
              <InfobulleForCustomModal
                cmsContent={infobulleContent}
                infobulleName={(Math.random() + 1).toString(36).substring(7)}
                lang={lang}
              />
            ) : (
              <Infobulle
                cmsContent={infobulleContent}
                infobulleName={(Math.random() + 1).toString(36).substring(7)}
                lang={lang}
              />
            )}
          </>
        )}
      </div>
      <span className="fr-hint-text fr-mb-4v">
        {typeof hint === 'string' ? FormComponentsEnrichedText(hint) : hint}
      </span>

      {helpbox && <CustomAlert {...helpbox} />}
      <div>
        {fieldArray?.fields.map((item: any, i: number) => (
          <Uploader
            key={item.id}
            item={item}
            index={i}
            name={name}
            categorie={categorie}
            demandeId={demandeId}
            formInfos={formInfos}
            formControl={formControl}
            isNew={isNew}
            onSuccess={onSuccess}
            fieldArray={fieldArray}
            resource={resource}
            stateRelatedMessage={fieldError ? fieldHasError : stateRelatedMessage}
            defaultValue={defaultValue}
            cmsContentUtilitaire={utilitaireData}
            errorCms={errorCms}
          />
        ))}
        {fieldArray.fields.length === 0 && (
          <Uploader
            index={0}
            name={name}
            categorie={categorie}
            demandeId={demandeId}
            formInfos={formInfos}
            formControl={formControl}
            isNew={isNew}
            onSuccess={onSuccess}
            fieldArray={fieldArray}
            resource={resource}
            stateRelatedMessage={fieldError ? fieldHasError : stateRelatedMessage}
            defaultValue={defaultValue}
            cmsContentUtilitaire={utilitaireData}
            errorCms={errorCms}
          />
        )}
      </div>

      {!disableAddFile && (
        <Button
          iconId="fr-icon-add-line"
          type="button"
          size="small"
          onClick={() => {
            fieldArray.append(new DocumentReformatClass(categorie));
          }}
          priority="tertiary"
          className="fr-mt-2w"
        >
          {utilitaireData?.data?.attributes?.ajouter_un_fichier}
        </Button>
      )}

      {fieldError && (
        <span className="fr-error-text">
          {formControl.errors[name]?.message ?? errorCms.erreur_generique_fichier}
        </span>
      )}
      {checkStateOfActiviteFields && (
        <span id="input-:ron:-desc-error" className="fr-error-text error-file-field">
          {errorCms.erreur_generique_fichier}
        </span>
      )}
    </div>
  );
};
export default UploadFiles;
