import React, { useEffect, useMemo, useState } from 'react';
import { useFieldArray, useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import CommonRowContainer from 'components/Molecules/Forms/CommonRowContainer/CommonRowContainer';
import FormModalLayout from 'components/Molecules/DemandeRf/FormModalLayout/FormModalLayout';
import UploadFiles from 'components/Atoms/UploadFiles/UploadFiles';
import SelectInputContainer from 'components/Molecules/Forms/SelectInputContainer/SelectInputContainer';
import CustomCheckboxContainer from 'components/Molecules/Forms/CustomCheckboxContainer/CustomCheckboxContainer';
import CustomAlert from 'components/Atoms/CustomAlert/CustomAlert';
import TextInputContainer from 'components/Molecules/Forms/TextInputContainer/TextInputContainer';
import { UNION_TYPE } from 'constants/regroupementFamiliale';
import { handleArrayHydratation, handleFieldHydratation } from 'helpers/api/FormHydratation';
import { Union as UnionType } from 'models/types/api/rf';
import { UnionDemandeForm } from 'models/types/app/demandeRf';
import { getRfCmsContents } from 'pages/DemandeRF/Helpers/Cms/getRfCmsContents';
import { useNewUnionMutation, useUpdUnionMutation } from 'redux/rtk/api/apiRf';
import { updUnionStore } from 'redux/demandeRf';
import { getListDocumentsByUnion } from 'redux/demandeRf/selectors';
import { createError, createSuccess, submitForm } from 'redux/forms';
import { getUnionFormToSubmit } from 'redux/forms/selectors';

import UnionUseForm from './components/UnionUseForm';
import { unionAppToApiFormatter } from './helpers/unionAppToApiFormatter';
import { getUploadFilesParameters } from './helpers/unionUploadFiles';
import { unionApiToAppFormatter } from './helpers/unionApiToAppFormatter';
import { getUnionValidationSchema } from './helpers/unionValidationSchema';
import unionMapping from './helpers/unionMapping';

type UnionProps = {
  lang: string;
  demandeId: string;
  unionModal: any;
  unionInit: UnionType;
};

const Union: React.FC<UnionProps> = ({ lang, demandeId, unionInit, unionModal }) => {
  const [union, setUnion] = useState<UnionType>(unionInit);
  const [forceModalOpen, setForceModalOpen] = useState<boolean>(false);
  const { bloc2Cms, globalCms, errorCms, utilitaireCms } = getRfCmsContents(lang);
  const dispatch = useDispatch();
  const [newUnion, { data: dataCreated, isSuccess: isSuccessCreated, isError: isErrorCreated }] =
    useNewUnionMutation();
  const [updUnion, { data: dataUpdated, isSuccess: isSuccessUpdated, isError: isErrorUpdated }] =
    useUpdUnionMutation();
  const [unionForm, setUnionForm] = useState<UnionDemandeForm | null>(null);

  const makeListDocumentsByUnion = useMemo(() => getListDocumentsByUnion(union.id), [union.id]);
  const documents = useSelector(makeListDocumentsByUnion);

  const {
    handleSubmit,
    getValues,
    setValue,
    trigger,
    watch,
    formState, //isValid, errors, touchedFields
    control,
  } = UnionUseForm(errorCms, bloc2Cms);

  const unionPrecedente = useWatch({
    control,
    name: 'union_precedente',
  });
  const unionJugementDivorceTraduit = useWatch({
    control,
    name: 'union_jugement_divorce_traduit',
  });

  const errors = formState.errors;
  const isValid = formState.isValid;

  const uploadFilesParameter = getUploadFilesParameters(
    demandeId ? demandeId : '',
    union?.id ? union.id : undefined,
    {
      control,
      setValue,
      getValues,
      trigger,
      errors,
      watch,
    },
    utilitaireCms,
  );

  const filesMapping = unionMapping
    .getGeneralMapping()
    .getFilesFields(bloc2Cms, uploadFilesParameter, errorCms);

  const formFieldsMapping = unionMapping.getGeneralMapping().getFormFields(
    { bloc2Cms, globalCms },
    {
      setValue,
      getValues,
      control,
      trigger,
    },
  );

  const fieldArrayMapping = {
    union_jugement_divorce_files: useFieldArray({
      control,
      name: 'union_jugement_divorce_files',
    }),
    union_jugement_divorce_traduit_files: useFieldArray({
      control,
      name: 'union_jugement_divorce_traduit_files',
    }),
    attestetation_dissolution_pacs_files: useFieldArray({
      control,
      name: 'attestetation_dissolution_pacs_files',
    }),
  };

  const onSubmit = async (data: any, forceModal?: boolean) => {
    const unionId = union?.uuid ? null : union?.id ? union?.id : null;
    const unionApi = unionAppToApiFormatter(data, unionId, documents, isValid);
    if (forceModal !== undefined) {
      setForceModalOpen(forceModal);
    } else {
      setForceModalOpen(false);
    }
    if (unionId) {
      await updUnion({
        demandeId: demandeId,
        body: unionApi,
      });
    } else {
      await newUnion({
        demandeId: demandeId,
        body: unionApi,
      });
    }
  };

  useEffect(() => {
    if (isSuccessCreated && dataCreated) {
      const dataCreatedFormatted = {
        ...dataCreated,
        dateDebut: dataCreated.dateDebut ? dataCreated.dateDebut.slice(0, 4) : undefined,
        dateFin: dataCreated.dateFin ? dataCreated.dateFin.slice(0, 4) : undefined,
      };
      dispatch(updUnionStore({ ...dataCreatedFormatted, ...{ uuid: union?.uuid } }));
      setUnion(dataCreatedFormatted);
      if (!forceModalOpen) {
        unionModal.close();
      }
      dispatch(createSuccess(bloc2Cms.union_ajoutee));
    }
  }, [isSuccessCreated, dataCreated]);

  useEffect(() => {
    if (isSuccessUpdated && dataUpdated) {
      const dataUpdatedFormatted = {
        ...dataUpdated,
        dateDebut: dataUpdated.dateDebut ? dataUpdated.dateDebut.slice(0, 4) : undefined,
        dateFin: dataUpdated.dateFin ? dataUpdated.dateFin.slice(0, 4) : undefined,
      };
      dispatch(updUnionStore(dataUpdatedFormatted));
      if (!forceModalOpen) {
        unionModal.close();
      }
      dispatch(createSuccess(bloc2Cms.union_modifie));
    }
  }, [isSuccessUpdated, dataUpdated]);

  useEffect(() => {
    if (isErrorCreated || isErrorUpdated) {
      dispatch(
        createError(isErrorCreated ? errorCms.erreur_enregistrement : errorCms.erreur_modification),
      );
    }
  }, [isErrorCreated, isErrorUpdated]);

  useEffect(() => {
    if (union && documents) {
      setUnionForm(unionApiToAppFormatter(union, documents));
    }
  }, [union]);

  const setValuesFromDemand = (formDemand: any) => {
    for (const key in formDemand) {
      if (Array.isArray(formDemand[key])) {
        if (formDemand[key].length > 0) {
          handleArrayHydratation(key, formDemand, fieldArrayMapping);
        }
      } else if (formDemand[key] !== null && formDemand[key] !== undefined) {
        handleFieldHydratation(
          key,
          formDemand,
          getUnionValidationSchema(errorCms, bloc2Cms),
          setValue,
        );
      }
    }
  };

  useEffect(() => {
    if (unionForm) {
      setValuesFromDemand(unionForm);
    }
  }, [unionForm]);

  const unionFormToSubmit = useSelector(getUnionFormToSubmit);
  useEffect(() => {
    if (unionFormToSubmit) {
      onSubmitNoValidationAfterUpload();
      dispatch(submitForm('union'));
    }
  }, [unionFormToSubmit]);

  const onSubmitNoValidationAfterUpload = () => {
    const values = control._formValues;
    delete values.validation;
    onSubmit(values, true);
  };

  return (
    <FormModalLayout
      formIdentifier="union-form"
      requiredInfos={true}
      onSubmit={onSubmit}
      handleSubmit={handleSubmit}
      lang={lang}
      control={control}
      isValid={isValid}
    >
      <CommonRowContainer
        legend={bloc2Cms.vous_etiez}
        //infobulleContent={bloc2Cms.infobulle_vous_etes_nee_le}
        lang={lang}
        children={[
          <SelectInputContainer
            {...formFieldsMapping.union_precedente}
            customClass="fr-col-12 fr-mt-4v fr-mt-lg-0v fr-mt-md-0v fr-col-sm-10"
            defaultValue={globalCms.choisir_option_defaut}
          />,
        ]}
      />
      {unionPrecedente === UNION_TYPE.MARIE && (
        <div className="fr-col-12 fr-mt-md-4v">
          <CustomAlert
            {...unionMapping.getGeneralMapping().getAlertsFields(bloc2Cms).alert_marie}
          />
        </div>
      )}

      <CommonRowContainer
        className="fr-mt-0v union-date"
        lang={lang}
        noSpacing
        children={[
          <TextInputContainer
            customClass="fr-mt-4v fr-mt-lg-0v fr-mt-md-0v"
            {...formFieldsMapping.union_debut}
          />,
          <TextInputContainer
            customClass="fr-mt-4v fr-mt-lg-0v fr-mt-md-0v"
            {...formFieldsMapping.union_fin}
          />,
        ]}
      />

      {unionPrecedente === UNION_TYPE.MARIE && (
        <>
          <UploadFiles
            {...filesMapping.union_jugement_divorce_files.upload}
            fieldArray={fieldArrayMapping.union_jugement_divorce_files}
            isNew={union?.uuid ? 'oui' : 'non'}
            lang={lang}
            className="fr-mt-2w fr-mt-md-3w"
          />
          <CustomCheckboxContainer {...formFieldsMapping.union_jugement_divorce_traduit} large />
          {unionJugementDivorceTraduit && (
            <UploadFiles
              {...filesMapping.union_jugement_divorce_traduit_files.upload}
              fieldArray={fieldArrayMapping.union_jugement_divorce_traduit_files}
              isNew={union?.uuid ? 'oui' : 'non'}
              className="fr-mt-2w fr-mt-md-3w"
            />
          )}
        </>
      )}

      {unionPrecedente === UNION_TYPE.PACSE && (
        <>
          <UploadFiles
            {...filesMapping.attestetation_dissolution_pacs_files.upload}
            fieldArray={fieldArrayMapping.attestetation_dissolution_pacs_files}
            isNew={union?.uuid ? 'oui' : 'non'}
            lang={lang}
            className="fr-mt-2w fr-mt-md-3w"
          />
        </>
      )}
    </FormModalLayout>
  );
};

export default Union;
