import { User } from 'models/types/api/user';

import { apiSlice } from './apiSlice';

const apiUser = apiSlice.injectEndpoints({
  endpoints: build => ({
    getUser: build.query<User, void>({
      query: () => ({ url: '/api/private/usagers' }),
    }),
    newUser: build.mutation({
      query: body => ({
        url: `/api/usager/create`,
        method: 'POST',
        body,
      }),
    }),
  }),
  overrideExisting: false,
});

export const { useGetUserQuery, useNewUserMutation } = apiUser;
