import { FC, useEffect } from 'react';

import UploadFiles from 'components/Atoms/UploadFiles/UploadFiles';
import CommonRowContainer from 'components/Molecules/Forms/CommonRowContainer/CommonRowContainer';
import TextInputContainer from 'components/Molecules/Forms/TextInputContainer/TextInputContainer';
import DateInputContainer from 'components/Molecules/Forms/DateInputContainer/DateInputContainer';
import SelectInputContainer from 'components/Molecules/Forms/SelectInputContainer/SelectInputContainer';
import CallOutDocumentsInfos from 'components/Molecules/DemandeRf/CallOutDocumentsInfos/CallOutDocumentsInfos';

import { AccomodationMapping } from '../Accomodation.mapping';
import { GetUploadFilesParameters } from '../Accomodation.helper';
import { createModal } from '@codegouvfr/react-dsfr/Modal';

type AccomodationSituationProps = {
  accomodationMapping: any;
  demandeId?: string | null;
  accomodationId?: string | null;
  filesMapping: any;
  errors: any;
  formControl: {
    watch: any;
    control: any;
    setValue: any;
    getValues: any;
    trigger: any;
    errors: any;
  };
  isFutur: boolean;
  bloc3Cms: any;
  debugAccomodation: any;
  lang: string;
  onSituationChange: any;
};
const infobulleDocumentsModal = createModal({
  id: 'document-modal-accomodation',
  isOpenedByDefault: false,
});

const AccomodationSituationPanel: FC<AccomodationSituationProps> = ({
  accomodationMapping,
  demandeId,
  accomodationId,
  formControl,
  filesMapping,
  isFutur = false,
  bloc3Cms,
  debugAccomodation,
  lang,
  onSituationChange,
}) => {
  const { control, setValue, getValues, trigger, errors, watch } = formControl;
  const uploadFilesParameters = GetUploadFilesParameters(
    demandeId ? demandeId : '',
    accomodationId ? accomodationId : undefined,
    {
      control,
      setValue,
      getValues,
      trigger,
      errors,
      watch,
    },
    'CMS_UTILITAIRE => TO REPLACE',
  );

  const typeHebergeur = formControl.watch(isFutur ? 'type_futur_hebergement' : 'type_hebergeur');
  const situationLogement = formControl.watch(
    isFutur ? 'situation_futur_logement' : 'situation_logement',
  );

  const onHostIdentityChoose = () => {
    return <TextInputContainer {...accomodationMapping.nom_hebergeur} />;
  };

  const getHostIdentityOnFreeSituationChoose = () => {
    return (
      <>
        {!isFutur && (
          <CommonRowContainer
            className="fr-grid-row fr-col-12 fr-mt-8v"
            children={[
              <UploadFiles
                lang={lang}
                {...filesMapping.bail_quittance_hebergeant_files.upload}
                {...uploadFilesParameters}
              />,
            ]}
          />
        )}
        <CommonRowContainer
          legend={bloc3Cms.par}
          lang={lang}
          infobulleContent={bloc3Cms.infobulle_par}
        >
          <SelectInputContainer {...accomodationMapping.type_hebergeur} />
          {typeHebergeur !== 'employeur' && onHostIdentityChoose()}
        </CommonRowContainer>
      </>
    );
  };

  const getForCurrentRental = (filesMapping: any) => {
    //location

    return (
      <>
        {
          //helpbox
        }
        <CommonRowContainer
          className="fr-grid-row fr-col-12 fr-mt-8v"
          children={[
            <UploadFiles
              lang={lang}
              {...filesMapping.bail_complet_files.upload}
              {...uploadFilesParameters}
            />,
          ]}
        />
        <CommonRowContainer
          className="fr-grid-row fr-col-12 fr-mt-8v"
          children={[
            <UploadFiles
              lang={lang}
              {...filesMapping.quittance_loyer_files.upload}
              {...uploadFilesParameters}
            />,
          ]}
        />
        <CommonRowContainer
          className="fr-grid-row fr-col-12 fr-mt-8v"
          children={[
            <UploadFiles
              lang={lang}
              {...filesMapping.justificatif_domicile_files.upload}
              {...uploadFilesParameters}
            />,
          ]}
        />
      </>
    );
  };

  const getForCurrentSublease = (filesMapping: any) => {
    //sous-location
    return (
      <>
        {
          //helpbox
        }
        <CommonRowContainer
          className="fr-grid-row fr-col-12 fr-mt-8v"
          children={[
            <UploadFiles
              lang={lang}
              {...filesMapping.engagement_sous_location_files.upload}
              {...uploadFilesParameters}
            />,
          ]}
        />
        <CommonRowContainer
          className="fr-grid-row fr-col-12 fr-mt-8v"
          children={[
            <UploadFiles
              lang={lang}
              {...filesMapping.justificatif_domicile_files.upload}
              {...uploadFilesParameters}
            />,
          ]}
        />
      </>
    );
  };

  const getForCurrentOwner = (filesMapping: any) => {
    return (
      <>
        {
          //helpbox
        }

        <CommonRowContainer
          className="fr-grid-row fr-col-12 fr-mt-8v"
          children={[
            <UploadFiles
              lang={lang}
              {...filesMapping.acte_propriete_files.upload}
              {...uploadFilesParameters}
            />,
          ]}
        />
        <CommonRowContainer
          className="fr-grid-row fr-col-12 fr-mt-8v"
          children={[
            <UploadFiles
              lang={lang}
              {...filesMapping.justificatif_domicile_files.upload}
              {...uploadFilesParameters}
            />,
          ]}
        />
      </>
    );
  };

  const getForCurrentFreeHosting = (filesMapping: any, typeHebergeur: string) => {
    if (typeHebergeur === 'particulier') {
      return (
        <>
          <CommonRowContainer
            className="fr-grid-row fr-col-12 fr-mt-8v"
            children={[
              <UploadFiles
                lang={lang}
                {...filesMapping.justificatif_hebergeant_files.upload}
                {...uploadFilesParameters}
              />,
            ]}
          />
          <CommonRowContainer
            className="fr-grid-row fr-col-12 fr-mt-8v"
            children={[
              <UploadFiles
                lang={lang}
                {...filesMapping.attestation_domicile_hebergeant_files.upload}
                {...uploadFilesParameters}
              />,
            ]}
          />
        </>
      );
    } else if (typeHebergeur === 'employeur') {
      return (
        <>
          <CommonRowContainer
            className="fr-grid-row fr-col-12 fr-mt-8v"
            children={[
              <UploadFiles
                lang={lang}
                {...filesMapping.attestation_domicile_employeur_files.upload}
                {...uploadFilesParameters}
              />,
            ]}
          />
          <CommonRowContainer
            className="fr-grid-row fr-col-12 fr-mt-8v"
            children={[
              <UploadFiles
                lang={lang}
                {...filesMapping.justificatif_domicile_files.upload}
                {...uploadFilesParameters}
              />,
            ]}
          />
        </>
      );
    } else if (typeHebergeur === 'autre') {
      return (
        <>
          <CommonRowContainer
            className="fr-grid-row fr-col-12 fr-mt-8v"
            children={[
              <UploadFiles
                lang={lang}
                {...filesMapping.justificatif_domicile_files.upload}
                {...uploadFilesParameters}
              />,
            ]}
          />
        </>
      );
    }
  };

  const getDocumentsFieldForCurrent = (
    situationLogement: string,
    typeHebergeur: string,
    filesMapping: any,
  ) => {
    return (
      <>
        {situationLogement === 'locataire' && getForCurrentRental(filesMapping)}
        {situationLogement === 'sous_locataire' && getForCurrentSublease(filesMapping)}
        {situationLogement === 'proprietaire' && getForCurrentOwner(filesMapping)}
        {situationLogement === 'gratuit' && getForCurrentFreeHosting(filesMapping, typeHebergeur)}
      </>
    );
  };

  const getForFuturRental = (filesMapping: any) => {
    //location
    return (
      <>
        <CommonRowContainer
          className="fr-grid-row fr-col-12 fr-mt-8v"
          children={[
            <UploadFiles
              lang={lang}
              {...filesMapping.promesse_location_files.upload}
              {...uploadFilesParameters}
            />,
          ]}
        />
        <CommonRowContainer
          className="fr-grid-row fr-col-12 fr-mt-8v"
          children={[
            <UploadFiles
              lang={lang}
              {...filesMapping.attestation_mise_disposition_logement_autre_files.upload}
              {...uploadFilesParameters}
            />,
          ]}
        />
      </>
    );
  };

  const getForFuturSublease = (filesMapping: any) => {
    return (
      <>
        <CommonRowContainer
          className="fr-grid-row fr-col-12 fr-mt-8v"
          children={[
            <UploadFiles
              lang={lang}
              {...filesMapping.engagement_sous_location_autre_files.upload}
              {...uploadFilesParameters}
            />,
          ]}
        />
        <CommonRowContainer
          className="fr-grid-row fr-col-12 fr-mt-8v"
          children={[
            <UploadFiles
              lang={lang}
              {...filesMapping.attestation_mise_disposition_logement_autre_files.upload}
              {...uploadFilesParameters}
            />,
          ]}
        />
      </>
    );
  };

  const getForFuturOwner = (filesMapping: any) => {
    return (
      <>
        <CommonRowContainer
          className="fr-grid-row fr-col-12 fr-mt-8v"
          children={[
            <UploadFiles
              lang={lang}
              {...filesMapping.justificatif_acquisition_futur_logement_files.upload}
              {...uploadFilesParameters}
            />,
          ]}
        />
        <CommonRowContainer
          className="fr-grid-row fr-col-12 fr-mt-8v"
          children={[
            <UploadFiles
              lang={lang}
              {...filesMapping.attestation_mise_disposition_logement_autre_files.upload}
              {...uploadFilesParameters}
            />,
          ]}
        />
      </>
    );
  };

  const getForFuturFreeHosting = (filesMapping: any, typeHebergeur: string) => {
    if (typeHebergeur === 'particulier') {
      return (
        <>
          <CommonRowContainer
            className="fr-grid-row fr-col-12 fr-mt-8v"
            children={[
              <UploadFiles
                lang={lang}
                {...filesMapping.bail_quittance_hebergeant_autre_files.upload}
                {...uploadFilesParameters}
              />,
            ]}
          />

          <CommonRowContainer
            className="fr-grid-row fr-col-12 fr-mt-8v"
            children={[
              <UploadFiles
                lang={lang}
                {...filesMapping.justificatif_hebergeant_autre_files.upload}
                {...uploadFilesParameters}
              />,
            ]}
          />

          <CommonRowContainer
            className="fr-grid-row fr-col-12 fr-mt-8v"
            children={[
              <UploadFiles
                lang={lang}
                {...filesMapping.attestation_domicile_hebergeant_autre_files.upload}
                {...uploadFilesParameters}
              />,
            ]}
          />

          <CommonRowContainer
            className="fr-grid-row fr-col-12 fr-mt-8v"
            children={[
              <UploadFiles
                lang={lang}
                {...filesMapping.attestation_mise_disposition_logement_autre_files.upload}
                {...uploadFilesParameters}
              />,
            ]}
          />
        </>
      );
    } else if (typeHebergeur === 'employeur') {
      return (
        <>
          <CommonRowContainer
            className="fr-grid-row fr-col-12 fr-mt-8v"
            children={[
              <UploadFiles
                lang={lang}
                {...filesMapping.attestation_hebergement_employeur_autre_files.upload}
                {...uploadFilesParameters}
              />,
            ]}
          />
          <CommonRowContainer
            className="fr-grid-row fr-col-12 fr-mt-8v"
            children={[
              <UploadFiles
                lang={lang}
                {...filesMapping.attestation_mise_disposition_logement_autre_files.upload}
                {...uploadFilesParameters}
              />,
            ]}
          />
        </>
      );
    } else if (typeHebergeur === 'autre') {
      return (
        <>
          <CommonRowContainer
            className="fr-grid-row fr-col-12 fr-mt-8v"
            children={[
              <UploadFiles
                lang={lang}
                {...filesMapping.attestation_mise_disposition_logement_autre_files.upload}
                {...uploadFilesParameters}
              />,
            ]}
          />
        </>
      );
    }
  };

  const getDocumentsFieldForFutur = (
    situationLogement: string,
    typeHebergeur: string,
    filesMapping: any,
  ) => {
    return (
      <>
        {situationLogement === 'locataire' && getForFuturRental(filesMapping)}
        {situationLogement === 'sous_locataire' && getForFuturSublease(filesMapping)}
        {situationLogement === 'proprietaire' && getForFuturOwner(filesMapping)}
        {situationLogement === 'gratuit' && getForFuturFreeHosting(filesMapping, typeHebergeur)}
      </>
    );
  };

  useEffect(() => {
    if (
      debugAccomodation.all ||
      (isFutur
        ? debugAccomodation.futurAccomodationSituation
        : debugAccomodation.currentAccomodationSituation)
    ) {
      console.log(
        `${
          isFutur ? 'Futur' : 'Current'
        }AccomodationSituationPanel - typeHebergeur/situationLogement`,
        typeHebergeur,
        situationLogement,
      );
    }
  }, [typeHebergeur, situationLogement]);

  return (
    <>
      {isFutur && (
        <CommonRowContainer
          legend={bloc3Cms.date_dispo_logement}
          lang={lang}
          infobulleContent={bloc3Cms.infobulle_date_mise_a_dispo_logement}
        >
          <DateInputContainer
            {...AccomodationMapping.getGeneralMapping().entree_futur_logement(
              bloc3Cms,
              formControl.control,
            )}
          />
          <></>
        </CommonRowContainer>
      )}
      <CommonRowContainer
        legend={isFutur ? bloc3Cms.vous_residerez_en_tant_que : bloc3Cms.vous_residez_en_tant_que}
        lang={lang}
        infobulleContent={bloc3Cms.infobulle_vous_residez_en_tant_que}
      >
        <SelectInputContainer
          {...accomodationMapping.situation_logement}
          customOnChange={onSituationChange}
        />
        <></>
      </CommonRowContainer>
      {situationLogement !== '' && !isFutur && (
        <CommonRowContainer
          className="fr-grid-row fr-col-12 fr-mt-6v fr-mt-lg-8v fr-mt-md-8v"
          children={[
            <CallOutDocumentsInfos lang={lang} infobulleDocumentsModal={infobulleDocumentsModal} />,
          ]}
        />
      )}
      {situationLogement === 'gratuit' && getHostIdentityOnFreeSituationChoose()}
      {situationLogement !== '' && isFutur
        ? getDocumentsFieldForFutur(situationLogement, typeHebergeur, filesMapping)
        : getDocumentsFieldForCurrent(situationLogement, typeHebergeur, filesMapping)}
    </>
  );
};

export default AccomodationSituationPanel;
