import { ReactNode } from 'react';
import { FrIconClassName, RiIconClassName } from '@codegouvfr/react-dsfr';

import WhoAreYou from 'pages/DemandeRF/Forms/WhoAreYou/WhoAreYou';
import Accomodation from 'pages/DemandeRF/Forms/Accomodation/Accomodation';
import Family from 'pages/DemandeRF/Forms/Family/Family';
import Commitments from 'pages/DemandeRF/Forms/Commitments/Commitments';
import { DemandeRfGeneral } from 'models/types/cms/demandeRF/type';
import Resources from '../Forms/Resources/Resources';

type UnControlledTabs = {
  label: ReactNode;
  iconId?: FrIconClassName | RiIconClassName;
  isDefault?: boolean;
  content: ReactNode;
}[];

type getTheTabsType = {
  globalCms: DemandeRfGeneral;
  lang: string;
  demandeId: string;
  isEligible: boolean;
  setIsEligible: React.Dispatch<React.SetStateAction<boolean>>;
  tabsStatus: {
    IdentityStatus: string;
    FamilyStatus: string;
    AccomodationStatus: string;
    ResourcesStatus: string;
    CommitmentStatus: string;
  };
  scrollToTablist: () => void;
  saveFormBehavior: {
    whoAreYou: {
      saveFormProgress: boolean;
      setSaveFormProgress: React.Dispatch<React.SetStateAction<boolean>>;
    };
    family: {
      saveFormProgress: boolean;
      setSaveFormProgress: React.Dispatch<React.SetStateAction<boolean>>;
    };
    accomodation: {
      saveFormProgress: boolean;
      setSaveFormProgress: React.Dispatch<React.SetStateAction<boolean>>;
    };
    ressources: {
      saveFormProgress: boolean;
      setSaveFormProgress: React.Dispatch<React.SetStateAction<boolean>>;
    };
    commitments: {
      saveFormProgress: boolean;
      setSaveFormProgress: React.Dispatch<React.SetStateAction<boolean>>;
    };
  };
};

const getIconId = (status: string) => {
  return status === 'warning'
    ? 'fr-icon-alert-line'
    : status === 'valid'
    ? 'fr-icon-checkbox-circle-line'
    : undefined;
};

export const getTheTabs = ({
  globalCms,
  lang,
  isEligible,
  setIsEligible,
  demandeId,
  tabsStatus,
  scrollToTablist,
  saveFormBehavior,
}: getTheTabsType): UnControlledTabs => [
  {
    isDefault: true,
    label: globalCms.titre_bloc_1,
    iconId: getIconId(tabsStatus.IdentityStatus),
    content: (
      <WhoAreYou
        lang={lang}
        isEligible={isEligible}
        setIsEligible={setIsEligible}
        demandeId={demandeId}
        scrollToTablist={scrollToTablist}
        saveFormProgress={saveFormBehavior.whoAreYou.saveFormProgress}
        setSaveFormProgress={saveFormBehavior.whoAreYou.setSaveFormProgress}
      />
    ),
  },
  {
    label: globalCms.titre_bloc_2,
    iconId: getIconId(tabsStatus.FamilyStatus),
    content: (
      <Family
        lang={lang}
        demandeId={demandeId}
        scrollToTablist={scrollToTablist}
        saveFormProgress={saveFormBehavior.family.saveFormProgress}
        setSaveFormProgress={saveFormBehavior.family.setSaveFormProgress}
      />
    ),
  },
  {
    label: globalCms.titre_bloc_3,
    iconId: getIconId(tabsStatus.AccomodationStatus),
    content: (
      <Accomodation
        lang={lang}
        scrollToTablist={scrollToTablist}
        saveFormProgress={saveFormBehavior.accomodation.saveFormProgress}
        setSaveFormProgress={saveFormBehavior.accomodation.setSaveFormProgress}
      />
    ),
  },
  {
    label: globalCms.titre_bloc_4,
    iconId: getIconId(tabsStatus.ResourcesStatus),
    content: (
      <Resources
        lang={lang}
        scrollToTablist={scrollToTablist}
        saveFormProgress={saveFormBehavior.ressources.saveFormProgress}
        setSaveFormProgress={saveFormBehavior.ressources.setSaveFormProgress}
      />
    ),
  },
  {
    label: globalCms.titre_bloc_5,
    iconId: getIconId(tabsStatus.CommitmentStatus),
    content: (
      <Commitments
        lang={lang}
        scrollToTablist={scrollToTablist}
        saveFormProgress={saveFormBehavior.commitments.saveFormProgress}
        setSaveFormProgress={saveFormBehavior.commitments.setSaveFormProgress}
        tabsStatus={tabsStatus}
      />
    ),
  },
];
