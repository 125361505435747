import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { MaintenanceCms } from 'models/types/cms/maintenance/types';

export type MaintenanceState = Readonly<MaintenanceCms>;

const initialState: MaintenanceState = {
  id: null,
  attributes: {
    mc: false,
    mec: false,
    dfm: null,
  },
};

const maintenanceSlice = createSlice({
  name: 'Maintenance',
  initialState,
  reducers: {
    setMaintenance: (state, action: PayloadAction<MaintenanceCms>) => {
      state.attributes.mc = action.payload
        ? action.payload.attributes.mc
        : initialState.attributes.mc;
      state.attributes.mec = action.payload
        ? action.payload.attributes.mec
        : initialState.attributes.mec;
      state.attributes.dfm = action.payload
        ? action.payload.attributes.dfm
        : initialState.attributes.dfm;
    },
  },
});

export const { setMaintenance } = maintenanceSlice.actions;
export default maintenanceSlice.reducer;
