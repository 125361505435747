import { cmsSlice } from './cmsSlice';

const cmsAsile = cmsSlice.injectEndpoints({
  endpoints: build => ({
    getAsilePage: build.query<any, { qs?: string; locale: string }>({
      query: ({ locale }) => ({ url: `/asile?locale=${locale}&populate=*` }),
    }),
    getRappelsCms: build.query<any, { qs?: string; locale: string }>({
      query: ({ locale }) => ({ url: `/rappel?locale=${locale}&populate=*` }),
    }),
    getAllocationCms: build.query<any, { qs?: string; locale: string }>({
      query: ({ qs, locale }) => ({ url: `/mon-allocation?locale=${locale}&${qs}` }),
    }),
  }),
});

export const { useGetAsilePageQuery, useGetRappelsCmsQuery, useGetAllocationCmsQuery } = cmsAsile;
