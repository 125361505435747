import { cmsSlice } from './cmsSlice';

const cmsRgpd = cmsSlice.injectEndpoints({
  endpoints: build => ({
    getRgpdCmsPage: build.query<any, { qs?: string; locale: string }>({
      query: ({ locale }) => ({ url: `/rgpd?locale=${locale}&populate=*` }),
    }),
  }),
});

export const { useGetRgpdCmsPageQuery } = cmsRgpd;
