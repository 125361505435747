import React, { FC, useRef } from 'react';
import Button from '@codegouvfr/react-dsfr/Button';
import { CallOut } from '@codegouvfr/react-dsfr/CallOut';
import { createModal } from '@codegouvfr/react-dsfr/Modal';

import TextInputContainer from 'components/Molecules/Forms/TextInputContainer/TextInputContainer';
import { AccountCreationPage } from 'models/types/app/pages';

import InfobulleIdentifiant from './../Components/InfobulleIdentifiant';

import '../CreationCompte.scss';

interface FormulaireStep1Props {
  setStep: React.Dispatch<React.SetStateAction<number>>;
  control: any;
  trigger: any;
  accountCreationPage: AccountCreationPage;
  getFieldState: any;
}

const FormulaireStep1: FC<FormulaireStep1Props> = ({
  setStep,
  control,
  trigger,
  accountCreationPage,
  getFieldState,
}) => {
  const creationCompteModal = createModal({
    id: 'creation-compte-modal',
    isOpenedByDefault: false,
  });

  const inputReference = useRef(null);
  const submitButton = useRef(null);

  const manualTrigger = () => {
    trigger('identifiant');
    if (inputReference.current && submitButton.current) {
      (submitButton.current as HTMLButtonElement).focus();
      (inputReference.current as HTMLInputElement).focus();
    }
  };

  const nextStepManagement = () => {
    trigger('identifiant');
    if (
      getFieldState('identifiant').invalid === false &&
      getFieldState('identifiant').isDirty === true
    ) {
      setStep(2);
    } else {
      if (inputReference.current && submitButton.current) {
        (inputReference.current as HTMLInputElement).focus();
        (submitButton.current as HTMLButtonElement).focus();
      }
    }
  };

  if (!accountCreationPage) {
    return <div>Redirect page error...</div>;
  }

  return (
    <>
      <h5>{accountCreationPage.sous_titre_etape_1}</h5>
      <p>{accountCreationPage.sous_titre_sub_etape_1}</p>

      <CallOut
        buttonProps={{
          children: accountCreationPage.txt_bouton_bulle_aide,
          type: 'button',
          onClick: () => creationCompteModal.open(),
        }}
        iconId="ri-information-line"
        title={accountCreationPage.titre_bulle_aide}
      >
        {accountCreationPage.contenu_bulle_aide}
      </CallOut>

      <p className="fr-text--xs fr-mt-4v fr-mt-md-6v fr-mb-4v">{accountCreationPage.disclaimer}</p>
      <div className="fr-col-12">
        <TextInputContainer
          name="identifiant"
          control={control}
          label={accountCreationPage.label_identifiant}
          hintText={accountCreationPage.format_attendu_identifiant}
          customClass="fr-col-12"
          stateRelatedMessage={
            getFieldState('identifiant')?.error?.message ??
            accountCreationPage.erreur_champ_invalide
          }
          onChange={() => {
            manualTrigger();
          }}
          ref={inputReference}
        />
      </div>

      <div className="fr-mt-6v controls-buttons">
        <Button
          className="mobile-large-button next-button"
          iconId="fr-icon-arrow-right-line"
          iconPosition="right"
          type="button"
          onClick={() => {
            nextStepManagement();
          }}
          priority="primary"
          ref={submitButton}
        >
          {accountCreationPage.bouton_suivant}
        </Button>
      </div>

      <creationCompteModal.Component title={accountCreationPage.titre_infobulle_identifiant}>
        <InfobulleIdentifiant accountCreationPage={accountCreationPage} />
      </creationCompteModal.Component>
    </>
  );
};
export default FormulaireStep1;
