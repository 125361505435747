import { FC } from 'react';
import { useDebounce } from 'react-use';
import { useSelector, useDispatch } from 'react-redux';

import { getNotifications } from 'redux/forms/selectors';
import { STATUS } from 'constants/formulaire';
import { disableError, disableSuccess, clearError, clearSuccess } from 'redux/forms/slice';

const Toast: FC = function () {
  const notifications = useSelector(getNotifications);
  const dispatch = useDispatch();

  const [] = useDebounce(
    () => {
      if (
        Array.isArray(notifications) &&
        notifications.length > 0 &&
        notifications[0].state === 'in'
      ) {
        notifications[0].status === STATUS.ERROR
          ? dispatch(disableError(notifications[0].content))
          : dispatch(disableSuccess(notifications[0].content));
      } else if (
        Array.isArray(notifications) &&
        notifications.length > 0 &&
        notifications[0].state === 'out'
      ) {
        if (notifications.length > 1 && notifications[1].state === 'in') {
          notifications[1].status === STATUS.ERROR
            ? dispatch(disableError(notifications[1].content))
            : dispatch(disableSuccess(notifications[1].content));
        }
        notifications[0].status === STATUS.ERROR
          ? dispatch(clearError(notifications[0].content))
          : dispatch(clearSuccess(notifications[0].content));
      }
    },
    15000,
    [notifications],
  );

  const isDuplicate = (content: string) => {
    return notifications.some(notification => notification.content === content);
  };

  return (
    <div id="toaster" className="toast-container">
      {notifications.map(notification => {
        const isValid = notification.status === STATUS.VALID;
        if (isDuplicate(notification.content) && notification.state === 'out') {
          return <></>;
        }
        return (
          <div className={`toast-${isValid ? 'valid' : 'invalid'} slide-${notification.state}`}>
            <div className={`toast-content-${isValid ? 'valid' : 'invalid'}`}>
              <div
                className={`${
                  isValid
                    ? 'fr-icon-checkbox-fill icon-left-toaster-valid'
                    : 'fr-icon-alert-fill icon-left-toaster-invalid'
                } `}
              ></div>
              <div className="toast-body">{notification.content}</div>
              <button
                type="button"
                className="fr-icon-close-line icon-close-toaster"
                data-dismiss="toast"
                aria-label="Close"
                onClick={() => {
                  dispatch(
                    isValid ? clearSuccess(notification.content) : clearError(notification.content),
                  );
                }}
              >
                {' '}
              </button>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Toast;
