import { AUTRE_ADRESSE } from '../components/AddressChoice';

export const addressDefaultValues = {
  choix_adresse: AUTRE_ADRESSE,
  numero_de_voie: '',
  nom_de_rue: '',
  code_postal: '',
  localite: '',
  province: '',
  pays: '',
  complement_adresse: '',
  adresse_email: '',
  telephone_adresse: '',
};
