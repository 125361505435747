import { RootState } from 'redux/reducers';
import { CookiesAcceptanceType } from './slice';

// 6 months in milliseconds
const COOKIES_VALIDITY_PERIOD = 182 * 24 * 3600 * 1000;

export const hasConsentExpired = (registeredAt: number): boolean =>
  registeredAt + COOKIES_VALIDITY_PERIOD < new Date().getTime();

export const isAtLeastOneCookieAcceptanceNotSet = (cookiesAcceptance: CookiesAcceptanceType) =>
  Object.values(cookiesAcceptance).some(cookieAcceptance => cookieAcceptance === null);

export const getShouldAskConsent = (state: RootState): boolean => {
  return (
    !state.cookies.registeredAt ||
    hasConsentExpired(state.cookies.registeredAt) ||
    isAtLeastOneCookieAcceptanceNotSet(state.cookies.cookiesAcceptance) ||
    state.cookies.hasUserRequestedCookiesPersonalization
  );
};

export const getCookiesAcceptance = (state: RootState) => {
  return state.cookies.cookiesAcceptance;
};

export const getMatomoCookiesAcceptance = (state: RootState) => {
  return state.cookies.cookiesAcceptance.mixpanelCookiesAccepted;
};

export const getFacebookCookiesAcceptance = (state: RootState) => {
  return state.cookies.cookiesAcceptance.facebookCookiesAccepted;
};

export const getTwitterCookiesAcceptance = (state: RootState) => {
  return state.cookies.cookiesAcceptance.twitterCookiesAccepted;
};

export const getYoutubeCookiesAcceptance = (state: RootState) => {
  return state.cookies.cookiesAcceptance.youtubeCookiesAccepted;
};

export const getShouldReloadPage = (state: RootState) => {
  return state.cookies.shouldReloadPage;
};
