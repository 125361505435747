import { Document } from 'models/types/api/rf';
import { ApiData, ApiDocumentContent, AppDocument } from 'models/types/api/entities';

import { apiSlice } from './apiSlice';
import { ProcedureType } from '../../../constants/procedure';

const apiDocuments = apiSlice.injectEndpoints({
  endpoints: build => ({
    getDocumentsAsile: build.query<ApiData<AppDocument>, void>({
      query: () => ({ url: '/api/private/documents-asile' }),
      transformResponse: (response: ApiData<AppDocument>) => {
        return {
          contenu: response.contenu.map(document => ({
            ...document,
            procedure: ProcedureType.ASILE,
          })),
          total: response.total,
        };
      },
    }),
    getDocumentAsile: build.query<
      ApiData<ApiDocumentContent>,
      {
        codeDocument: string;
        locale?: string;
      }
    >({
      query: ({ codeDocument, locale }) => ({
        url: `/api/private/document-asile?codeDocument=${codeDocument}&langue=${
          locale ? locale : 'fr'
        }`,
      }),
    }),
    getDocumentsRri: build.query<ApiData<AppDocument>, void>({
      query: () => ({ url: `/api/private/rri/documents` }),
      transformResponse: (response: ApiData<AppDocument>) => {
        return {
          contenu: response.contenu.map(document => ({
            ...document,
            procedure: ProcedureType.RETOUR_REINSERTION,
          })),
          total: response.total,
        };
      },
    }),
    getDocumentRri: build.query<ApiData<ApiDocumentContent>, string>({
      query: codeDocument => ({ url: `/api/private/rri/document?codeDocument=${codeDocument}` }),
    }),
    getDocumentsRf: build.query<ApiData<AppDocument>, string>({
      query: numeroDossier => ({ url: `/api/private/documents-rf?numeroDossier=${numeroDossier}` }),
    }),
    getAllDocumentsRf: build.query<ApiData<AppDocument>, void>({
      query: () => ({ url: `/api/private/tous-documents-rf` }),
      transformResponse: (response: ApiData<AppDocument>) => {
        return {
          contenu: response.contenu.map(document => ({
            ...document,
            procedure: ProcedureType.REGROUPEMENT_FAMILIAL,
          })),
          total: response.total,
        };
      },
    }),
    getDocumentRf: build.query<ApiData<ApiDocumentContent>, string>({
      query: codeDocument => ({ url: `/api/private/document-rf?codeDocument=${codeDocument}` }),
    }),
    getDocumentsDemandeRf: build.query<
      Document[],
      {
        demandeId: string;
        param: string;
      }
    >({
      query: ({ demandeId, param }) => ({
        url: `/api/private/regroupement-familial/preparation-demandes/${demandeId}/documents${param}`,
      }),
    }),
    delDocument: build.mutation<void, { demandeId: string; documentId: string }>({
      query({ demandeId, documentId }) {
        return {
          url: `/api/private/regroupement-familial/preparation-demandes/${demandeId}/documents/${documentId}`,
          method: 'DELETE',
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetDocumentsAsileQuery,
  useGetDocumentAsileQuery,
  useGetDocumentsRfQuery,
  useGetDocumentsRriQuery,
  useGetDocumentRriQuery,
  useGetDocumentsDemandeRfQuery,
  useDelDocumentMutation,
  useGetAllDocumentsRfQuery,
  useGetDocumentRfQuery,
} = apiDocuments;
