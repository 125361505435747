export const IdentityDefaultValues = {
  gender: '',
  nom_naissance: '',
  nom_usage: '',
  prenom: '',
  date_naissance: '',
  pays_naissance: '',
  ville_naissance: '',
  nationalite: '',
  acte_naissance_files: [],
  acte_naissance_traduits_files: [],
  french_birth_certificate: false,
  nature_titre_sejour: '',
  mention_titre_sejour: '',
  autre_nature_titre_sejour: '',
  autre_mention_titre_sejour: '',
  date_expiration_titre_sejour: '',
  ongoing_residence_permit_demand: '',
  titre_sejour_files: [],
  recepisse_files: [],
  situation_matrimoniale: '',
  date_start_of_mariage: '',
  place_of_mariage: '',
  acte_mariage_files: [],
  french_mariage_certificate: false,
  acte_mariage_traduits_files: [],
  acte_deces_conjoint_files: [],
  french_death_certificate: false,
  acte_deces_traduits_files: [],
  date_start_of_pacs: '',
  convention_pacs_files: [],
  divorce_ruling_files: [],
  french_divorce_papers: false,
  divorce_ruling_traduit_files: [],
  validation: 'true',
  date_start_of_concubinage: '',
  date_start_of_divorce: '',
};
