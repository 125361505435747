import React, { FC } from 'react';

import './Loader.scss';

export type LoaderProps = {
  fullScreen?: displayType;
};

type displayType = 'fullscreen';

const Loader: FC<LoaderProps> = ({ fullScreen }) => {
  return (
    <>
      <div style={{ minHeight: '100vh' }}></div>
      <div className={fullScreen ? `loader-wrapper-${fullScreen}` : 'loader-wrapper'}>
        <div className="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </>
  );
};

export default Loader;
