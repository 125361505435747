import { RootState } from 'redux/reducers';

export const getWhoAreYouFormToSubmit = (state: RootState) => state.forms.submitForm.whoAreYou;
export const getConjointFormToSubmit = (state: RootState) => state.forms.submitForm.family.conjoint;
export const getEnfantFormToSubmit = (state: RootState) => state.forms.submitForm.family.enfant;
export const getUnionFormToSubmit = (state: RootState) => state.forms.submitForm.family.union;
export const getAccomodationFormToSubmit = (state: RootState) =>
  state.forms.submitForm.accomodation;
export const getRessourcesFormToSubmit = (state: RootState) => state.forms.submitForm.ressources;

export const getNotifications = (state: RootState) => state.forms.notifications;
