export const conjointDefaultValues = {
  rf_demande: '',
  conjoint_etranger: '',
  sexe_conjoint: '',
  nom_naissance_conjoint: '',
  prenoms_conjoint: '',
  nom_usage_conjoint: '',
  acte_naissance_conjoint_files: [],
  french_birth_certificate_conjoint: false,
  acte_naissance_conjoint_traduit_files: [],
  date_naissance_conjoint: '',
  pays_naissance_conjoint: '',
  ville_naissance_conjoint: '',
  mariage_precedent_conjoint: '',
  titre_sejour_fr_conjoint: '',
  nationalite_conjoint: '',
  conjoint_titre_identite_files: [],
  conjoint_jugement_divorce_files: [],
  conjoint_jugement_divorce_traduit: false,
  conjoint_jugement_divorce_traduit_files: [],
};
