import { Ressources } from 'models/types/api/rf';

export type RessourcesState = Readonly<Ressources>;

export const initialState: RessourcesState = {
  isValid: false,
  isUntouched: true,
  activites: [],
  arretTravail12DernierMois: null,
  autresRessources: null,
  beneficiaireAahOuAsi: null,
  declarerConjointRessources: null,
  documents: [],
  pensionAlimentaire: null,
  travailDansBtp: null,
};
