export const getAgeisMinor = (watchDateNaissance: string): boolean => {
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);
  const birthDate = new Date(watchDateNaissance);

  if (birthDate > yesterday) {
    return true;
  }

  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();

  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }

  return age < 18;
};

export const mapDateFormToServer = (
  annee: number | null | undefined,
  mois: number | null | undefined,
  jour: number | null | undefined,
): string | null => {
  if (annee !== undefined && mois !== undefined && jour !== undefined) {
    return `${annee}-${mois}-${jour}`;
  }
  return null;
};

export const mapDateServerToForm = (
  dateStr: string | null | undefined,
): Array<number | undefined> => {
  if (dateStr !== undefined && dateStr !== null) {
    const arr = dateStr.split('-');
    return [parseInt(arr[0]), parseInt(arr[1]), parseInt(arr[2])];
  }
  return [undefined, undefined, undefined];
};

export const getDocumentsByCategory = (category: string, documents: any[]) => {
  const documentsByCategories =
    documents.filter(
      obj =>
        obj.categorie === category &&
        obj.statut &&
        !['deleted_pending', 'deleted'].includes(obj.statut),
    ).length > 0
      ? documents.filter(
          obj =>
            obj.categorie === category &&
            obj.statut &&
            !['deleted_pending', 'deleted'].includes(obj.statut),
        )
      : [];

  return documentsByCategories.map(doc => ({ id: null, document: doc }));
};

export const flatArray = (arr: any): Array<string> => {
  return arr.map((data: any) => data.value);
};
