import { RootState } from 'redux/reducers';
import { Document, RFStatut } from 'models/types/api/rf';
import { STATUT_DEMANDE } from '../../constants/regroupementFamiliale';

export const getDemandeId = (state: RootState) => state.rfDemande.id;

export const getAllListRFDemandeSummary = (state: RootState) =>
  state.rfDemande.rfDemandeSummary.contenu;

const isRFDemandeSummaryDecisionRecue = (statut: RFStatut | null): boolean =>
  statut !== null && (statut === RFStatut.FAVORABLE || statut === RFStatut.DEFAVORABLE);

const isRFDemandeSummaryTerminee = (statut: RFStatut | null): boolean =>
  statut !== null &&
  (isRFDemandeSummaryDecisionRecue(statut) || statut === RFStatut.DOSSIER_CLOTURE);

const isRFDemandeSummaryEnCoursDeDepot = (statut: RFStatut | null): boolean =>
  statut === RFStatut.EN_COURS_DE_DEPOT;

export const isDemandeHasBeenSubmitted = (state: RootState): boolean =>
  state.rfDemande.isDemandeHasBeenSubmitted;
const isRFDemandeSummaryAttenteRetPref = (statut: RFStatut | null): boolean =>
  statut === RFStatut.DECISION_ATTENTE_RETOUR_PREFECTURE;

export const getListRFDemandeEnCoursSummary = (state: RootState) =>
  (state.rfDemande.rfDemandeSummary.contenu || []).filter(
    rfDemandeSummary =>
      !isRFDemandeSummaryTerminee(rfDemandeSummary.statut) &&
      !isRFDemandeSummaryEnCoursDeDepot(rfDemandeSummary.statut) &&
      !isRFDemandeSummaryAttenteRetPref(rfDemandeSummary.statut),
  );

export const getListRFDemandeSummaryNonCloturees = (state: RootState) =>
  (state.rfDemande.rfDemandeSummary.contenu || []).filter(
    rfDemandeSummary => rfDemandeSummary.statut !== RFStatut.DOSSIER_CLOTURE,
  );

export const getListRFDemandeTermineeSummary = (state: RootState) =>
  (state.rfDemande.rfDemandeSummary.contenu || []).filter(rfDemandeSummary =>
    isRFDemandeSummaryTerminee(rfDemandeSummary.statut),
  );

export const getListDocumentsByLogement = (logementId: string | null) => (state: RootState) => {
  if (!logementId) {
    return [];
  }
  const logement = state.rfDemande.accomodation.accomodations?.find(
    element => element.id === logementId,
  );
  if (logement) {
    return logement.documents.map((document: Document) => {
      const obj = { uuid: document.id };
      return { ...document, ...obj };
    });
  } else {
    return [];
  }
};

export const getIdentiteDocuments = (state: RootState) => state.rfDemande.identity.documents || [];

export const getListLogements = (state: RootState) =>
  state.rfDemande.accomodation.accomodations || [];

export const getIdentiteStore = (state: RootState) => state.rfDemande.identity;

export const getLogementsStore = (state: RootState) => state.rfDemande.accomodation;

export const getRessourcesStore = (state: RootState) => state.rfDemande.ressources;

export const getListActivities = (state: RootState) => state.rfDemande.ressources.activites;

export const getCommitmentStore = (state: RootState) => state.rfDemande.commitments;

export const getIdentiteFormToSubmit = (state: RootState) => state.forms.submitForm.whoAreYou;

export const getAccomodationFormToSubmit = (state: RootState) =>
  state.forms.submitForm.accomodation;

export const getRessourcesFormToSubmit = (state: RootState) => state.forms.submitForm.ressources;

export const getDemandeHasLoad = (state: RootState) => state.rfDemande.hasLoad;
export const getFamilyFormToSubmit = (state: RootState) => state.forms.submitForm.family.family;
export const getFamilyDocuments = (state: RootState) => state.rfDemande.family.documents || [];
export const getListConjoints = (state: RootState) => state.rfDemande.family.conjoint || [];

export const getListDocumentsByConjoint = (conjointId: string | null) => (state: RootState) => {
  if (!conjointId) {
    return [];
  }
  const conjoint = state.rfDemande.family.conjoint?.find(element => element.id === conjointId);
  if (conjoint) {
    return conjoint.documents.map((document: Document) => document);
  } else {
    return [];
  }
};

export const getListAddress = (state: RootState) => state.rfDemande.family.adresses || [];

export const getListDocumentsByEnfant = (enfantId: string | null) => (state: RootState) => {
  if (!enfantId) {
    return [];
  }
  const enfant = state.rfDemande.family.enfants?.find(element => element.id === enfantId);
  if (enfant) {
    return enfant.documents.map((document: Document) => document);
  } else {
    return [];
  }
};

export const getListEnfants = (state: RootState) => state.rfDemande.family.enfants || [];

export const getListDocumentsByUnion = (unionId: string | null) => (state: RootState) => {
  if (!unionId) {
    return [];
  }
  const union = state.rfDemande.family.unions?.find(element => element.id === unionId);
  if (union) {
    return union.documents.map((document: Document) => document);
  } else {
    return [];
  }
};

export const getListUnions = (state: RootState) => state.rfDemande.family.unions || [];

export const getListDemandeRFEnCours = (state: RootState) =>
  (state.rfDemande.demandeList || []).filter(
    rfDemande =>
      rfDemande.statut !== null &&
      (rfDemande.statut === STATUT_DEMANDE.START ||
        rfDemande.statut === STATUT_DEMANDE.DRAFT ||
        rfDemande.statut === STATUT_DEMANDE.READY),
  );

export const getListDemandeRFPending = (state: RootState) =>
  (state.rfDemande.demandeList || []).filter(
    rfDemande =>
      rfDemande.statut === STATUT_DEMANDE.PENDING ||
      rfDemande.statut === STATUT_DEMANDE.ERROR_PROCESSING,
  );

// export const getListDemandeRFTerminees = (state: RootState) =>
//   (state.rfDemande.rfDemandeSummary || []).filter(
//     rfDemande =>
//       rfDemande.statut !== 'start' || rfDemande.statut !== 'draft' || rfDemande.statut !== 'ready',
//   );

export const getIdentityIsUntouched = (state: RootState) => state.rfDemande.identity.isUntouched;
export const getFamilyIsUntouched = (state: RootState) => state.rfDemande.family.isUntouched;
export const getAccomodationIsUntouched = (state: RootState) =>
  state.rfDemande.accomodation.isUntouched;
export const getRessourcesIsUntouched = (state: RootState) =>
  state.rfDemande.ressources.isUntouched;
export const getCommitmentsIsUntouched = (state: RootState) =>
  state.rfDemande.commitments.isUntouched;

export const getIdentityValidation = (state: RootState) => state.rfDemande.identity.isValid;
export const getFamilyValidation = (state: RootState) => state.rfDemande.family.isValid;
export const getAccomodationValidation = (state: RootState) => state.rfDemande.accomodation.isValid;
export const getRessourcesValidation = (state: RootState) => state.rfDemande.ressources.isValid;
export const getCommitmentsValidation = (state: RootState) => state.rfDemande.commitments.isValid;
